import { Component } from '../../../Components/Base/Component';
import { Utilities } from '../../../Tools/utilities';

export class ResizingManager extends Component {
    constructor() {
        super();
    }

    resizeWidth(dimensions) {
        let width = dimensions.width;
        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);
        this.parts.cabinet.panelBottom.mesh.scaling.x =
            width / (panelBottomDimensions.width / this.parts.cabinet.panelBottom.mesh.scaling.x);

        const panelTopDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelTop.mesh);
        this.parts.cabinet.panelTop.mesh.scaling.x =
            width / (panelTopDimensions.width / this.parts.cabinet.panelTop.mesh.scaling.x);

        const skirtingDimensions = Utilities.getMeshDimensions(this.parts.skirting.mesh);
        this.parts.skirting.mesh.scaling.x = width / (skirtingDimensions.width / this.parts.skirting.mesh.scaling.x);

        const door1Dimensions = Utilities.getMeshDimensions(this.parts.door1.mesh);
        this.parts.door1.mesh.scaling.x = width / (door1Dimensions.width / this.parts.door1.mesh.scaling.x);
    }

    resizeCounterTopBlockInWidth(dimensions) {
        let width = dimensions.width;

        const countertopDimensions = Utilities.getMeshDimensions(this.parts.countertop.countertopBase.mesh);
        this.parts.countertop.countertopBase.mesh.scaling.x =
            width / (countertopDimensions.width / this.parts.countertop.countertopBase.mesh.scaling.x);

        const backsplashDimensions = Utilities.getMeshDimensions(this.parts.countertop.backsplash.mesh);
        this.parts.countertop.backsplash.mesh.scaling.x =
            width / (backsplashDimensions.width / this.parts.countertop.backsplash.mesh.scaling.x);
    }

    resizeDepth(dimensions) {
        let depth = dimensions.depth;
        const DOOR_DEPTH = 0.024;
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelBottom.mesh, depth - DOOR_DEPTH);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelTop.mesh, depth - DOOR_DEPTH);
        const panelTopDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelTop.mesh);

        this.parts.skirting.mesh.position.z -= (depth - DOOR_DEPTH - panelTopDimensions.depth) / 2;
        this.parts.door1.mesh.position.z -= (depth - DOOR_DEPTH - panelTopDimensions.depth) / 2;
    }

    resizeCounterTopBlockInDepth(dimensions) {
        const depth = dimensions.depth;
        const COUNTERTOP_FORWARD_EXTENSION = 0.012;
        const countertopBaseDimensions = Utilities.getMeshDimensions(this.parts.countertop.countertopBase.mesh);

        Utilities.resizeMeshInDepth(this.parts.countertop.countertopBase.mesh, depth + COUNTERTOP_FORWARD_EXTENSION);
        this.parts.countertop.backsplash.mesh.position.z +=
            (depth + COUNTERTOP_FORWARD_EXTENSION - countertopBaseDimensions.depth) / 2;
    }

    resizeHeight(height) {
        this.resizeCabinetBlockInHeight(height);
        this.resizeSkirtingBlockInHeight(height);
    }

    resizeCabinetBlockInHeight(height) {
        let SKIRTING_AND_BASE_COUNTERTOP_HEIGHT = 0.27;
        Utilities.resizeMeshInHeight(this.parts.door1.mesh, height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT);
        const door1Dimensions = Utilities.getMeshDimensions(this.parts.door1.mesh);

        this.parts.cabinet.panelTop.mesh.position.y +=
            (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;
        this.parts.cabinet.panelBottom.mesh.position.y -=
            (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;
    }

    resizeSkirtingBlockInHeight(height) {
        let SKIRTING_AND_BASE_COUNTERTOP_HEIGHT = 0.27;
        const door1Dimensions = Utilities.getMeshDimensions(this.parts.door1.mesh);
        const shift = (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;
        this.parts.skirting.mesh.position.y -= shift;
    }

    resizeCounterTopBlockInHeight(height) {
        const SKIRTING_AND_BASE_COUNTERTOP_HEIGHT = 0.27;

        const door1Dimensions = Utilities.getMeshDimensions(this.parts.door1.mesh);
        const shift = (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;

        this.parts.countertop.backsplash.mesh.position.y += shift;
        this.parts.countertop.countertopBase.mesh.position.y += shift;
    }
}
