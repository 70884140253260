var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { attrs: { app: "", dense: "" } },
    [
      _c("v-img", {
        attrs: { "max-height": "40", contain: "", src: _vm.logoSource },
      }),
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        { attrs: { cols: "8", xs: "6" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              !_vm.$vuetify.breakpoint.xsOnly
                ? _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.mdiTapeMeasure)),
                  ])
                : _vm._e(),
              _c("span", [
                _vm._v(_vm._s(_vm.selectedUnit === "mm" ? "MM" : "IN")),
              ]),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "", inset: "" } }),
          _vm.isStandardDomain
            ? _c(
                "v-col",
                { attrs: { "align-self": "center", cols: "auto" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-icon", { attrs: { left: "", right: "" } }, [
                        _vm._v(_vm._s(_vm.mdiCloudUploadOutline)),
                      ]),
                      _c("v-checkbox", {
                        attrs: {
                          "hide-details": "",
                          disabled: _vm.isSceneSaved,
                        },
                        on: { click: _vm.saveProject },
                        model: {
                          value: _vm.isSceneSavedComp,
                          callback: function ($$v) {
                            _vm.isSceneSavedComp = $$v
                          },
                          expression: "isSceneSavedComp",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-col",
                { attrs: { "align-self": "center", cols: "auto" } },
                [
                  _c(
                    "v-row",
                    [
                      !_vm.isStandardDomain
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "white--text mb-1 mx-2",
                              attrs: {
                                color: "green",
                                small: _vm.$vuetify.breakpoint.mdAndDown,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.$route.params.projectId
                                    ? _vm.toggleUpdateDialog(true)
                                    : _vm.toggleDialog(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$route.params.projectId
                                      ? "Save Project"
                                      : "Quote"
                                  ) +
                                  " "
                              ),
                              !_vm.$route.params.projectId
                                ? _c("v-icon", [
                                    _vm._v(
                                      " " + _vm._s(_vm.mdiCurrencyUsd) + " "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
          _vm.isStandardDomain
            ? _c(
                "v-col",
                {
                  staticClass: "pa-1",
                  attrs: { cols: "auto", "align-self": "center" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        color: "primary",
                        href: _vm.redirectDashboard,
                      },
                    },
                    [_vm._v("Dashboard")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }