<template>
    <v-btn
        text
        :small="$vuetify.breakpoint.mdAndDown"
        color="red"
        @click="resetProject"
    >
        Reset
    </v-btn>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    methods: {
        ...mapMutations('core', ['setStep', 'setStartedDesigning']),
        resetProject() {
            this.$store.commit('core/setResetProject', true);

            console.log(this.$store.state.core.editorEngine);
            this.$store.state.core.editorEngine.clearScene();
            this.setStartedDesigning(false);
            this.setStep(1);
        },
    },
};
</script>
