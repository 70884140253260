const textureLineSize = 6;
export const FixtureTextureUpdate = (fixture) => {
    let a0 = 12;
    for (var i = 0; i < fixture.parts.length; i++) {
        let p = fixture.parts[i];
        switch (fixture.type) {
            case 'openSpace':
                //Always 1 parts
                p.ctx.fillStyle = 'rgba( 0, 0, 0, 0 )';
                p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                p.ctx.fillStyle = p.colors.baseColor;
                p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                p.ctx.strokeStyle = p.colors.lineColor;
                p.ctx.lineWidth = textureLineSize;
                p.ctx.setLineDash([32, 16]);
                p.ctx.strokeRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                break;
            case 'window':
                //Always 1 parts
                p.ctx.fillStyle = 'rgba( 0, 0, 0, 0)';
                p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                p.ctx.fillStyle = p.colors.baseColor;
                p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                p.ctx.strokeStyle = p.colors.lineColor;
                p.ctx.lineWidth = textureLineSize;
                p.ctx.setLineDash([0]);

                p.ctx.beginPath();
                p.ctx.moveTo(a0, 0);
                p.ctx.lineTo(a0, p.dt._texture.baseHeight);

                p.ctx.moveTo(p.dt._texture.baseWidth - a0, 0);
                p.ctx.lineTo(p.dt._texture.baseWidth - a0, p.dt._texture.baseHeight);

                p.ctx.moveTo(a0, p.dt._texture.baseHeight * 0.5);
                p.ctx.lineTo(p.dt._texture.baseWidth - a0, p.dt._texture.baseHeight * 0.5);

                p.ctx.stroke();
                p.ctx.strokeRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);

                break;
            case 'door':
                //Sometimes 2 parts
                //Sometimes a different Icon if subtype
                switch (fixture.subType) {
                    case 'standard': {
                        switch (i) {
                            case 0:
                                p.ctx.fillStyle = 'rgba(0,0,0,0)';
                                p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                                p.ctx.fillStyle = p.colors.baseColor;
                                p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                                p.ctx.strokeStyle = p.colors.lineColor;
                                p.ctx.lineWidth = textureLineSize;
                                p.ctx.setLineDash([0]);
                                p.ctx.beginPath();
                                p.ctx.moveTo(a0, 0);
                                p.ctx.lineTo(a0, p.dt._texture.baseHeight);
                                p.ctx.moveTo(p.dt._texture.baseWidth - a0, 0);
                                p.ctx.lineTo(p.dt._texture.baseWidth - a0, p.dt._texture.baseHeight);
                                p.ctx.stroke();
                                p.ctx.beginPath();
                                p.ctx.moveTo(a0, 0);
                                p.ctx.lineTo(a0, p.dt._texture.baseHeight);
                                if (parseFloat(fixture.dimensions.width) > 1599) {
                                    console.log('fixture width > 1599');
                                    let t0 = (a0 + (p.dt._texture.baseWidth - a0)) * 0.5;
                                    p.ctx.moveTo(t0 - a0, 0);
                                    p.ctx.lineTo(t0 - a0, p.dt._texture.baseHeight);
                                    p.ctx.moveTo(t0 + a0, 0);
                                    p.ctx.lineTo(t0 + a0, p.dt._texture.baseHeight);
                                }
                                p.ctx.moveTo(p.dt._texture.baseWidth - a0, 0);
                                p.ctx.lineTo(p.dt._texture.baseWidth - a0, p.dt._texture.baseHeight);
                                p.ctx.stroke();
                                break;
                            case 1:
                                p.ctx.fillStyle = p.colors.baseColor;
                                p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                                p.ctx.strokeStyle = p.colors.lineColor;
                                p.ctx.lineWidth = textureLineSize;
                                p.ctx.setLineDash([32, 16]);
                                p.ctx.clearRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                                if (parseFloat(fixture.dimensions.width) > 1599) {
                                    p.ctx.fillStyle = p.colors.baseColor;
                                    p.ctx.beginPath();
                                    p.ctx.arc(a0, 0, p.dt._texture.baseHeight * 0.5 - a0 * 2, 0, Math.PI * 1.5);
                                    p.ctx.stroke();
                                    p.ctx.beginPath();
                                    p.ctx.arc(p.dt._texture.baseWidth - a0, 0, p.dt._texture.baseHeight * 0.5 - a0 * 2, 0, Math.PI * 1.5);
                                    p.ctx.stroke();
                                    p.ctx.clearRect(0, p.dt._texture.baseWidth * 0.5 - a0 * 4, a0 - textureLineSize * 0.5, a0 * 4);
                                    p.ctx.clearRect(
                                        p.dt._texture.baseWidth - a0 + textureLineSize * 0.5,
                                        p.dt._texture.baseWidth * 0.5 - a0 * 4,
                                        a0,
                                        a0 * 4
                                    );
                                    p.ctx.setLineDash([0]);
                                    p.ctx.beginPath();
                                    p.ctx.moveTo(a0, 0);
                                    p.ctx.lineTo(a0, p.dt._texture.baseHeight * 0.5 - a0 * 2);
                                    p.ctx.moveTo(p.dt._texture.baseWidth - a0, 0);
                                    p.ctx.lineTo(p.dt._texture.baseWidth - a0, p.dt._texture.baseHeight * 0.5 - a0 * 2);
                                    p.ctx.stroke();
                                } else {
                                    p.ctx.beginPath();
                                    p.ctx.arc(a0, 0, p.dt._texture.baseHeight - a0 * 2, 0, Math.PI * 1.5);
                                    p.ctx.stroke();
                                    p.ctx.clearRect(0, p.dt._texture.baseWidth - a0 * 4, a0 - textureLineSize * 0.5, a0 * 4);
                                    p.ctx.setLineDash([0]);
                                    p.ctx.beginPath();
                                    p.ctx.moveTo(a0, 0);
                                    p.ctx.lineTo(a0, p.dt._texture.baseHeight - a0 * 2);
                                    p.ctx.stroke();
                                }
                                break;
                        }
                        break;
                    }
                    case 'sliding': {
                        p.ctx.fillStyle = 'rgba( 0, 0, 0, 0 )';
                        p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                        p.ctx.fillStyle = p.colors.baseColor;
                        p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                        p.ctx.strokeStyle = p.colors.lineColor;
                        p.ctx.lineWidth = textureLineSize;
                        p.ctx.setLineDash([0]);

                        p.ctx.beginPath();
                        p.ctx.moveTo(a0, 0);
                        p.ctx.lineTo(a0, p.dt._texture.baseHeight);
                        p.ctx.moveTo(p.dt._texture.baseWidth - a0, 0);
                        p.ctx.lineTo(p.dt._texture.baseWidth - a0, p.dt._texture.baseHeight);
                        p.ctx.moveTo(p.dt._texture.baseWidth * 0.5, 0);
                        p.ctx.lineTo(p.dt._texture.baseWidth * 0.5, p.dt._texture.baseHeight);
                        p.ctx.stroke();

                        p.ctx.lineWidth = textureLineSize * 0.75;
                        p.ctx.beginPath();
                        p.ctx.moveTo(a0, p.dt._texture.baseHeight * 0.35);
                        p.ctx.lineTo(p.dt._texture.baseWidth * 0.5, p.dt._texture.baseHeight * 0.35);
                        p.ctx.moveTo(p.dt._texture.baseWidth * 0.5, p.dt._texture.baseHeight * 0.65);
                        p.ctx.lineTo(p.dt._texture.baseWidth - a0, p.dt._texture.baseHeight * 0.65);
                        p.ctx.stroke();

                        break;
                    }
                    case 'balcony': {
                        p.ctx.fillStyle = 'rgba( 0, 0, 0, 0 )';
                        p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                        p.ctx.fillStyle = p.colors.baseColor;
                        p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                        p.ctx.strokeStyle = p.colors.lineColor;
                        p.ctx.lineWidth = textureLineSize;
                        p.ctx.setLineDash([0]);

                        p.ctx.beginPath();
                        p.ctx.moveTo(a0, 0);
                        p.ctx.lineTo(a0, p.dt._texture.baseHeight);
                        p.ctx.moveTo(p.dt._texture.baseWidth - a0, 0);
                        p.ctx.lineTo(p.dt._texture.baseWidth - a0, p.dt._texture.baseHeight);
                        p.ctx.moveTo(p.dt._texture.baseWidth * 0.5, 0);
                        p.ctx.lineTo(p.dt._texture.baseWidth * 0.5, p.dt._texture.baseHeight);
                        p.ctx.stroke();

                        p.ctx.lineWidth = textureLineSize * 0.75;
                        p.ctx.beginPath();
                        p.ctx.moveTo(a0, p.dt._texture.baseHeight * 0.35);
                        p.ctx.lineTo(p.dt._texture.baseWidth * 0.5, p.dt._texture.baseHeight * 0.35);
                        p.ctx.moveTo(p.dt._texture.baseWidth * 0.5, p.dt._texture.baseHeight * 0.65);
                        p.ctx.lineTo(p.dt._texture.baseWidth - a0, p.dt._texture.baseHeight * 0.65);
                        p.ctx.stroke();
                        break;
                    }
                }
                break;
            case 'fixedColumn':
                //Always 1 parts
                p.ctx.fillStyle = 'rgba( 0, 0, 0, 0 )';
                p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                p.ctx.fillStyle = p.colors.baseColor;
                p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                p.ctx.strokeStyle = p.colors.lineColor;
                p.ctx.setLineDash([0]);
                p.ctx.lineWidth = textureLineSize;
                p.ctx.strokeRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
                break;
        }

        if (i == 0) {
            if (fixture.selected || fixture.isHover) {
                p.ctx.fillStyle = p.colors.highlightColor;
                p.ctx.fillRect(0, 0, p.dt._texture.baseWidth, p.dt._texture.baseHeight);
            }
        }
        //console.log("flipY", p.colors.flipY)
        p.dt.update(p.colors.flipY || false);
    }
};
