import { Vector3 } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';

export class SectionCalculator {
    currentCabinet;
    constructor(editor) {
        this.editor = editor;
    }

    replaceEmptyCabinetSections(widthToFill, fillerTolerance = 0, customCabinet = false) {
        widthToFill -= fillerTolerance;
        widthToFill = Utilities.roundToPrecision(widthToFill, 1000);

        const availableCabinetDimensions = Constants.availableCabinetDimensions[this.editor.unit];
        const availableCabinetDimensionsInteger = availableCabinetDimensions.map((x) => {
            if (this.editor.unit === 'mm') {
                return x * 1000;
            } else {
                return x * 10000;
            }
        });

        let widthToFillInteger = Utilities.roundToPrecision(widthToFill * 1000, 1000);
        if (this.editor.unit === 'in') {
            widthToFillInteger = Utilities.roundToPrecision(widthToFill * 10000, 10000);
        }

        const knapsackResult = Utilities.knapsackAlgorithm(widthToFillInteger, availableCabinetDimensionsInteger);
        let numberOfSectionsPerType = knapsackResult.result.map((x) => {
            if (this.editor.unit === 'mm') {
                return { sectionWidth: x.sectionWidth / 1000, numberOfSections: 1 };
            } else {
                return { sectionWidth: x.sectionWidth / 10000, numberOfSections: 1 };
            }
        });
        if (this.editor.unit === 'mm') {
            this.currentCabinet.filledWidth += Utilities.roundToPrecision(knapsackResult.filledWidth / 1000, 1000);
        } else {
            this.currentCabinet.filledWidth += Utilities.roundToPrecision(knapsackResult.filledWidth / 10000, 1000);
        }

        this.fixRoundingLoss(numberOfSectionsPerType, widthToFill);
        this.optimizeSectionWidths(numberOfSectionsPerType);
        return numberOfSectionsPerType;
    }

    optimizeSectionWidths(sections) {
        if (sections.length === 0) {
            return;
        }
        //should I set average cabinet width as a limit?
        const averageCabinetWidth = Utilities.getAverageCabinetWidth(Constants.availableCabinetDimensions[this.editor.unit]);
        for (let index = 0; index <= sections.length - 1; index++) {
            if (sections[index].sectionWidth < averageCabinetWidth) {
                for (let secondOperator = index + 1; secondOperator < sections.length; secondOperator++) {
                    let sum = 0;
                    if (this.editor.unit === 'in') {
                        sum = Utilities.roundToPrecision(
                            sections[index].sectionWidth + sections[secondOperator].sectionWidth,
                            10000
                        );
                    } else {
                        sum = Utilities.roundToPrecision(
                            sections[index].sectionWidth + sections[secondOperator].sectionWidth,
                            1000
                        );
                    }
                    if (sum <= averageCabinetWidth && Constants.availableCabinetDimensions[this.editor.unit].includes(sum)) {
                        sections[index].sectionWidth = sum;
                        sections.splice(secondOperator, 1);
                        continue;
                    }
                }
            }
        }
        return sections;
    }

    fixRoundingLoss(numberOfSectionsPerType, widthToFill) {
        let filledWidth = 0;
        for (let index = 0; index < numberOfSectionsPerType.length; index++) {
            filledWidth += numberOfSectionsPerType[index].sectionWidth;
        }

        let lastSectionWidth = 0;
        if (numberOfSectionsPerType.length >= 1) {
            lastSectionWidth = numberOfSectionsPerType[numberOfSectionsPerType.length - 1].sectionWidth;
        }
        const newSectionWidth = lastSectionWidth + widthToFill - filledWidth;
        const valueInArray = Utilities.getClosestNumberInArray(
            newSectionWidth,
            Constants.availableCabinetDimensions[this.editor.unit],
            true
        );

        if (valueInArray - newSectionWidth < 0.005 && lastSectionWidth !== valueInArray) {
            if (numberOfSectionsPerType.length >= 1) {
                this.currentCabinet.filledWidth -= numberOfSectionsPerType[numberOfSectionsPerType.length - 1].sectionWidth;
                numberOfSectionsPerType.splice(numberOfSectionsPerType.length - 1, 1);
            }
            this.currentCabinet.filledWidth += valueInArray;
            numberOfSectionsPerType.push({
                sectionWidth: valueInArray,
                numberOfSections: 1,
            });
        }

        return numberOfSectionsPerType;
    }

    addCustomCabinet(numberOfSectionsPerType, widthToFill) {
        let filledWidth = 0;
        for (let index = 0; index < numberOfSectionsPerType.length; index++) {
            filledWidth += numberOfSectionsPerType[index].sectionWidth * numberOfSectionsPerType[index].numberOfSections;
        }

        if (numberOfSectionsPerType.length >= 1) {
            let lastSectionWidth = numberOfSectionsPerType[numberOfSectionsPerType.length - 1].sectionWidth;

            const newSectionWidth = lastSectionWidth + widthToFill - filledWidth;
            const valueInArray = Utilities.getClosestNumberInArray(
                newSectionWidth,
                Constants.availableCabinetDimensions[this.editor.unit],
                true
            );

            if (valueInArray - lastSectionWidth < Constants.availableCabinetDimensions[this.editor.unit][0]) {
                numberOfSectionsPerType.splice(numberOfSectionsPerType.length - 1, 1);
                this.currentCabinet.filledWidth += widthToFill - filledWidth;
                numberOfSectionsPerType.push({
                    sectionWidth: lastSectionWidth + widthToFill - filledWidth,
                    numberOfSections: 1,
                });
            }
        }

        return [numberOfSectionsPerType, widthToFill];
    }

    replaceCabinetsWithAppliances(data, appliancesOnCabinet, numberOfSectionsPerType) {
        let startingFillerSize = data.cabinet.calculateStartingFillerForSymmetry();
        if (
            appliancesOnCabinet.filter((a) => {
                return a.type === Constants.appliance.type.REFRIGERATOR && a.format === Constants.appliance.format.FREESTANDING;
            }).length > 0
        ) {
            const emptySpaceForFreeStanding = 0.05;
            const sidePanelWidth = 0.018;
            startingFillerSize += emptySpaceForFreeStanding + sidePanelWidth * 2;
        }
        let fillerShift = startingFillerSize;

        let lastAppliancePosition = data.startingPoint
            .clone()
            .add(data.replacementDirection.clone().scale(data.cabinet.filledWidth + fillerShift));
        let applianceProjectedPosition = Utilities.projectPointOnWall(
            appliancesOnCabinet[0].meshComponent.getMesh().position,
            appliancesOnCabinet[0].assignedWall
        );

        const distance = Vector3.Distance(lastAppliancePosition, applianceProjectedPosition);
        const applianceWidth = appliancesOnCabinet[0].width / 2;
        let distanceFromLastAppliance = distance - applianceWidth;
        if (distanceFromLastAppliance < 0) {
            distanceFromLastAppliance = 0;
        }

        let firstBatch = this.replaceEmptyCabinetSections(distanceFromLastAppliance, 0, appliancesOnCabinet[0].type === 'hood');
        numberOfSectionsPerType = numberOfSectionsPerType.concat(firstBatch);

        for (let index = 0; index < appliancesOnCabinet.length; index++) {
            let otherAppliance = this.hasCooktopOver(appliancesOnCabinet, index);
            let currentAppliance = appliancesOnCabinet[index];
            let cooktop = otherAppliance.appliance;
            if (appliancesOnCabinet[index].type === Constants.appliance.type.COOKTOP && otherAppliance.appliance) {
                currentAppliance = otherAppliance.appliance;
                cooktop = appliancesOnCabinet[index];
            }
            currentAppliance.connectedCooktop = cooktop;

            const sectionWidthNeeded = currentAppliance.getSectionWidthNeeded();
            numberOfSectionsPerType.push({
                sectionWidth: sectionWidthNeeded,
                numberOfSections: 1,
                sectionData: currentAppliance,
                cooktop: cooktop,
            });
            if (otherAppliance.appliance) {
                index++;
            }
            this.currentCabinet.filledWidth += sectionWidthNeeded;
            lastAppliancePosition = data.startingPoint.add(
                data.replacementDirection.clone().scale(data.cabinet.filledWidth + fillerShift)
            );
            if (appliancesOnCabinet[index + 1]) {
                applianceProjectedPosition = Utilities.projectPointOnWall(
                    appliancesOnCabinet[index + 1].position,
                    appliancesOnCabinet[index + 1].assignedWall
                );
                distanceFromLastAppliance =
                    Vector3.Distance(lastAppliancePosition, applianceProjectedPosition) -
                    appliancesOnCabinet[index + 1].width / 2;
                //this condition will be removed if we add interpolation for appliance movement
                if (distanceFromLastAppliance < 0.15) {
                    distanceFromLastAppliance = 0;
                }
            } else {
                let closestPoint = Utilities.getClosestPoint(
                    data.startingPoint,
                    data.allowedSegment.firstPoint,
                    data.allowedSegment.secondPoint
                );
                let endPoint = data.allowedSegment.secondPoint;
                if (closestPoint.closestPointIndex === Constants.corner.CORNER_B) {
                    endPoint = data.allowedSegment.firstPoint;
                }
                distanceFromLastAppliance = Vector3.Distance(lastAppliancePosition, endPoint);
            }

            let spaceLeftForFiller = data.cabinet.calculateSpaceNeededForFiller();
            let endingFiller = 0;
            if (index === appliancesOnCabinet.length - 1) {
                if (
                    data.cabinet.type === Constants.cabinet.type.WALL &&
                    (spaceLeftForFiller === 2 * Constants.SMALLEST_FILLER_SIZE[this.editor.unit] ||
                        (data.cabinet.cornerCabinetInfo &&
                            spaceLeftForFiller === Constants.SMALLEST_FILLER_SIZE[this.editor.unit]))
                ) {
                    endingFiller = Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
                } else if (spaceLeftForFiller === 2 * Constants.SMALLEST_FILLER_SIZE[this.editor.unit]) {
                    endingFiller = Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
                } else if (spaceLeftForFiller === 2 * Constants.STANDARD_FILLER_SIZE[this.editor.unit]) {
                    endingFiller = Constants.STANDARD_FILLER_SIZE[this.editor.unit];
                }
            }

            let endCabinets = this.replaceEmptyCabinetSections(distanceFromLastAppliance, endingFiller);
            numberOfSectionsPerType = numberOfSectionsPerType.concat(endCabinets);
            lastAppliancePosition = data.startingPoint.add(
                data.replacementDirection.clone().scale(data.cabinet.filledWidth + fillerShift)
            );
        }
        return numberOfSectionsPerType;
    }

    hasCooktopOver(appliancesOnCabinet, currentApplianceIndex) {
        let otherApplianceData = {
            appliance: null,
            type: null,
        };
        if (currentApplianceIndex + 1 < appliancesOnCabinet.length) {
            const otherAppliance = appliancesOnCabinet[currentApplianceIndex + 1];
            const otherApplianceBlockedSegment = otherAppliance.getEdgesOnWall();
            const currentApplianceBlockedSegment = appliancesOnCabinet[currentApplianceIndex].getEdgesOnWall();

            if (
                ((otherAppliance.type === Constants.appliance.type.COOKTOP &&
                    ['oven', 'range'].includes(appliancesOnCabinet[currentApplianceIndex].type)) ||
                    (appliancesOnCabinet[currentApplianceIndex].type === Constants.appliance.type.COOKTOP &&
                        ['oven', 'range'].includes(otherAppliance.type))) &&
                Utilities.areSegmentsOverlapping(otherApplianceBlockedSegment, currentApplianceBlockedSegment)
            ) {
                otherApplianceData = {
                    appliance: appliancesOnCabinet[currentApplianceIndex + 1],
                    type: appliancesOnCabinet[currentApplianceIndex + 1].type,
                };
            }
        }
        return otherApplianceData;
    }
}
