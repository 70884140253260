import { Utilities } from '../../../Tools/utilities';
import { Model } from '../Model';

export class Handle extends Model {
    constructor() {
        super();
    }

    async setModel() {
        const result = await this.import('handle.babylon');
        this.meshComponent.setMesh(Utilities.getElementByMeshName('handle', result.meshes));
        this.meshComponent.getMesh().setEnabled(false);
        this.meshComponent.getMesh().name = 'handle';
        this.setParts();
        this.addTexture();
    }

    addTexture() {
        this.meshComponent.getMesh().material = this.editor.PBRComponent.getTextureFromPool(
            'texture',
            'handles',
            '#FFFFFF',
            0,
            0,
            '',
            'metallic'
        );
    }
}
