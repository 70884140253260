import {
    Vector3,
    ArcRotateCamera,
    Quaternion,
    Tools,
    Camera,
    Ray,
    FreeCamera,
    Color3,
    RenderTargetTexture,
} from '@babylonjs/core';
import { Constants } from '../Tools/constants';
import { Component } from './Base/Component';
import { MeasurementLine } from '../KitchenObjects/Cabinets/CabinetComponents/MeasurementLine';
import { RoomObject } from '../KitchenObjects/Room/RoomObject';
import { Wall } from '../KitchenObjects/BuildingBlocks/Wall';
import { PDFDocument, degrees, BlendMode } from 'pdf-lib';

import { Utilities } from '../Tools/utilities';
import store from '@/store/index.js';

export class CameraComponent extends Component {
    camera;
    ray;
    leftRay;
    rightRay;
    secondLeftRay;
    secondRightRay;
    hiddenComponents;
    canvas;
    rays;
    editor;

    constructor() {
        super();
        this.rays = [];
        this.hiddenComponents = [];
    }

    prepare(canvas) {
        this.canvas = canvas;
        this.camera = new ArcRotateCamera(
            'Camera',
            Math.PI / 2,
            Math.PI / 10,
            9,
            new Vector3(0, 0, 0),
            this.editor.sceneComponent.get()
        );
        this.camera.wheelDeltaPercentage = 0.02;
        this.camera.lowerRadiusLimit = 0;
        this.camera.attachControl(canvas, true);
        this.camera.keysUp.push(87); // "w"
        this.camera.keysDown.push(83); // "s"
        this.camera.keysLeft.push(65); // "a"
        this.camera.keysRight.push(68); // "d"
        this.camera.inertia = 0.5;
        this.camera.angularSensibility = 800;
        this.editor.sceneComponent.addToActiveCameras(this.camera);
        this.editor.sceneComponent.get().activeCamera = this.camera;
    }

    rotateRay(angle) {
        const ray = this.camera.getForwardRay();
        const vector = ray.direction.clone();

        const rotationQuaternion = Quaternion.FromEulerAngles(0, angle, 0);
        vector.rotateByQuaternionToRef(rotationQuaternion, ray.direction);
        return ray;
    }

    hideMeshPickedByRay(hit) {
        if (hit.pickedMesh) {
            if (
                [
                    'Base Cabinet',
                    'Wall Cabinet',
                    'Tall Cabinet',
                    'Island',
                    'rightHandle',
                    'leftHandle',
                    'ground',
                    'linkedMesh',
                    'box',
                    'lineSystem',
                    'meshClone',
                    'floor',
                ].includes(hit.pickedMesh.name)
            ) {
                return;
            }

            const roomObject = this.editor.getObjectByType(RoomObject);
            if (hit.pickedMesh.type === 'wall') {
                const wallComponent = roomObject.walls[hit.pickedMesh.id];
                wallComponent.meshComponent.getMesh().visibility = 0;
                wallComponent.hideCabinets(false);
                this.hiddenComponents.push(wallComponent);
            }

            this.editor.fixtures.forEach((fixture) => {
                if (fixture.assignedWall.id === hit.pickedMesh.id) {
                    fixture.meshComponent.getMesh().visibility = 0;
                    this.hiddenComponents.push(fixture);
                }
            });

            this.editor.cabinets.forEach((cabinet) => {
                if (cabinet.assignedWall && cabinet.assignedWall.id === hit.pickedMesh.id) {
                    cabinet.meshComponent.getMesh().visibility = 0;
                    if (cabinet.getComponentByType(MeasurementLine)) {
                        cabinet.measurementLines.dispose();
                    }
                    cabinet.handles.show(false);
                    this.hiddenComponents.push(cabinet);
                }
            });
        }
    }

    showHiddenComponents() {
        for (let i = 0; i < this.hiddenComponents.length; i++) {
            if (this.hiddenComponents[i].meshComponent) {
                this.hiddenComponents[i].meshComponent.mesh.visibility = Constants.cabinet.transparency.VISIBLE;
                if (this.hiddenComponents[i].meshComponent.getMesh().type === 'wall') {
                    this.hiddenComponents[i].meshComponent.getMesh().visibility = 1;
                    this.hiddenComponents[i].hideCabinets(true);
                }
            }
        }
        this.hiddenComponents = [];
    }

    castRays() {
        this.rays = [
            this.camera.getForwardRay(1000),
            this.rotateRay(Math.PI / 21),
            this.rotateRay(Math.PI / 17),
            this.rotateRay(-Math.PI / 21),
            this.rotateRay(-Math.PI / 17),
        ];
    }

    getPickedInfo() {
        if (this.camera.beta > Constants.camera.ray.ANGLE_LIMIT) {
            this.castRays();
            this.hiddenComponents = [];
            for (let i = 0; i < this.rays.length; i++) {
                const hit = this.editor.sceneComponent.get().pickWithRay(this.rays[i]);
                if (hit.pickedMesh) {
                    this.hideMeshPickedByRay(hit);
                }
            }
        }
    }

    disableCameraRotation() {
        this.camera.detachControl(this.canvas);
    }

    enableCameraRotation() {
        this.camera.attachControl(this.canvas);
    }

    getHiddenWalls() {
        let hiddenWalls = [];

        if (this.camera.beta > Math.abs(Constants.camera.hiddenComponents.ANGLE)) {
            for (let index = 0; index < this.hiddenComponents.length; index++) {
                if (typeof this.hiddenComponents[index] instanceof Wall) {
                    hiddenWalls.push(this.hiddenComponents[index]);
                }
            }
        }
        return hiddenWalls;
    }

    get() {
        return this.camera;
    }

    getVisibleWall() {
        const hiddenWalls = this.getHiddenWalls();
        const walls = this.editor.getObjectByType(RoomObject).walls;

        for (let wall = 0; wall < walls.length; wall++) {
            if (!hiddenWalls.includes(walls[wall])) {
                return walls[wall];
            }
        }
    }

    async printDesign(apollo, projectId) {
        this.editor.isPrinting = true;
        await this.prepareForScreenshot();
        let pdf = null;
        do {
            this.editor.stopDownload = false;
            pdf = await this.captureScreenshots();
        } while (this.editor.stopDownload);

        await this.downloadPdf(pdf, apollo, projectId);
        this.returnToPreScreenshotState();
        this.stopDownload = false;
    }

    async addWatermark(pdfDocument) {
        const pdfPages = pdfDocument.getPages();
        for (const page of pdfPages) {
            page.drawText('Designed with KitchenWhiz.com', {
                x: 80,
                opacity: 0.1,
                rotate: degrees(0),
                y: 250,
                size: 50,
                blendMode: BlendMode.Multiply,
            });
        }
        return pdfDocument;
    }

    async addBusinessInfo(pdfDocument) {
        const pdfPages = pdfDocument.getPages();
        const info = store.state.business.info;
        const logoURL = info.business?.user?.picture;
        const imageBytes = await fetch(logoURL).then((res) => res.arrayBuffer());
        const isPNG = logoURL.includes('.png');
        let logo;

        if (isPNG) {
            logo = await pdfDocument.embedPng(imageBytes);
        } else {
            logo = await pdfDocument.embedJpg(imageBytes);
        }
        const logoDims = logo.scaleToFit(200, 50);

        for (const page of pdfPages) {
            page.drawImage(logo, {
                x: page.getWidth() - logoDims.width - 60,
                y: 20,
                width: logoDims.width,
                height: logoDims.height,
            });

            if (info.business.name) {
                page.drawText(info.business.name, {
                    x: 60,
                    opacity: 1,
                    y: 50,
                    size: 14,
                    blendMode: BlendMode.Darken,
                });
            }
            if (info.business.website) {
                page.drawText(info.business.website, {
                    x: 60,
                    opacity: 1,
                    y: 35,
                    size: 12,
                });
            }
            if (info.business.phone) {
                page.drawText(info.business.phone, {
                    x: 60,
                    opacity: 1,
                    y: 20,
                    size: 12,
                });
            }
        }
        return pdfDocument;
    }

    async prepareForScreenshot() {
        store.commit('core/toggleProjectLoading', true);
        this.editor.sceneComponent.get().render();
        this.editor.sceneComponent.changeColor(new Color3.White());

        this.camera.alpha = Math.PI / 2;
        this.camera.beta = 0;
        this.camera.radius = 6;
    }

    async captureScreenshots() {
        const canvasWidth = this.editor.canvas.clientWidth;
        const canvasHeight = this.editor.canvas.clientHeight;
        const pdfResult = await PDFDocument.create();
        this.createScreenshotCameras();
        this.editor.sceneComponent.engine.flushFramebuffer();

        await this.captureTopViewScreenshot(pdfResult);
        await this.captureWallScreenShots(pdfResult);
        await this.showIslands();
        await this.captureIslandScreenshots(pdfResult);

        await store.commit('core/resizeCanvas', {
            width: canvasWidth,
            height: canvasHeight,
        });

        this.editor.canvas.width = canvasWidth;
        this.editor.canvas.height = canvasHeight;
        this.editor.widgetUI.scaleTo(canvasWidth, canvasHeight);
        this.editor.isPrinting = false;

        return pdfResult;
    }

    returnToPreScreenshotState() {
        const walls = this.editor.getObjectByType(RoomObject).walls;
        for (let index = 0; index < walls.length; index++) {
            walls[index].hide(true);
        }

        this.editor.sceneComponent.changeColor();
        store.commit('core/toggleProjectLoading', false);
    }

    createScreenshotCameras() {
        this.createTopViewScreenshotCamera();
        this.createScreenshotCamera();
        this.createIslandScreenshotCamera();
    }

    async downloadPdf(pdf, apollo, projectId) {
        let pdfResult = pdf;
        if (store.state.generated.hasWatermark) {
            pdfResult = await this.addWatermark(pdf);
        }

        if (store.state.business.info) {
            pdfResult = await this.addBusinessInfo(pdf);
        }

        if (pdfResult && store.state.business.isStandardDomain) {
            const downloadPdfId = await store.dispatch('generated/addProjectDownloadedEntry', { apollo, projectId });

            if (!downloadPdfId) return;
        }

        const arrBytes = await pdfResult.save();
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([arrBytes], { type: 'application/pdf' }));
        a.download = 'kitchen-plan.pdf';
        a.click();
    }

    setPageOrientation(page) {
        const pageHeight = page.getHeight();
        page.setHeight(page.getWidth());
        page.setWidth(pageHeight);
    }

    createTopViewScreenshotCamera() {
        const roomCenter = Utilities.getPolygonCentroid(this.editor.sceneInfo.corners);
        this.topViewScreenshotCamera = new FreeCamera(
            'UniversalCamera',
            new Vector3(roomCenter.x, 10, roomCenter.y),
            this.editor.sceneComponent.get()
        );

        this.topViewScreenshotCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
        this.topViewScreenshotCamera.target = new Vector3(roomCenter.x, 0, roomCenter.y);
        const maxWallLength = this.editor.getObjectByType(RoomObject).getMaxWallLength();
        const distance = maxWallLength;

        const rect = this.editor.sceneComponent.engine.getRenderingCanvasClientRect();
        const aspect = rect.width / rect.height;

        this.topViewScreenshotCamera.orthoTop = distance / 2;
        this.topViewScreenshotCamera.orthoLeft = (-distance / 2) * aspect;
        this.topViewScreenshotCamera.orthoRight = (distance / 2) * aspect;
        this.topViewScreenshotCamera.orthoBottom = -distance / 2;
    }

    createScreenshotCamera() {
        const roomCenter = Utilities.getPolygonCentroid(this.editor.sceneInfo.corners);
        this.screenshotCamera = new FreeCamera(
            'UniversalCamera',
            new Vector3(roomCenter.x, 20, roomCenter.y),
            this.editor.sceneComponent.get()
        );
        this.screenshotCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
        this.screenshotCamera.target = new Vector3(roomCenter.x, 0, roomCenter.y);
        const canvas = this.editor.canvas;
        const ratio = canvas.clientWidth / canvas.clientHeight;

        let zoom = this.screenshotCamera.position.z;
        let width = zoom * ratio;

        this.screenshotCamera.orthoTop = zoom;
        this.screenshotCamera.orthoLeft = -width;
        this.screenshotCamera.orthoRight = width;
        this.screenshotCamera.orthoBottom = -zoom;

        this.editor.sceneComponent.addToActiveCameras(this.screenshotCamera);
    }

    createIslandScreenshotCamera() {
        const roomCenter = Utilities.getPolygonCentroid(this.editor.sceneInfo.corners);
        this.firstIslandScreenshotCamera = new FreeCamera(
            'UniversalCamera',
            new Vector3(roomCenter.x, 20, roomCenter.y),
            this.editor.sceneComponent.get()
        );
        this.firstIslandScreenshotCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
        this.firstIslandScreenshotCamera.target = new Vector3(roomCenter.x, 0, roomCenter.y);

        this.secondIslandScreenshotCamera = new FreeCamera(
            'UniversalCamera',
            new Vector3(roomCenter.x, 20, roomCenter.y),
            this.editor.sceneComponent.get()
        );
        this.secondIslandScreenshotCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
        this.secondIslandScreenshotCamera.target = new Vector3(roomCenter.x, 0, roomCenter.y);
    }

    createScreenshotRay(middleOfWall) {
        this.screenshotRay = new Ray(
            this.screenshotCamera.position.clone(),
            middleOfWall.clone().subtract(this.screenshotCamera.position.clone()).normalize(),
            1000
        );
    }

    showIslands() {
        for (let index = 0; index < this.editor.cabinets.length; index++) {
            if (this.editor.cabinets[index].type === 'island') {
                this.editor.cabinets[index].toggleVisibility(true);
            }
        }
    }

    async captureTopViewScreenshot(pdfResult) {
        const walls = this.editor.getObjectByType(RoomObject).walls;
        for (let index = 0; index < walls.length; index++) {
            walls[index].meshComponent.getMesh().visibility = 1;
            walls[index].hideCabinets(true);
            walls[index].disposeCabinetMeasurementLines();
            walls[index].toggleCabinetEdgesRendering(true);
        }

        await this.createRTT(this.topViewScreenshotCamera, pdfResult);
    }

    async captureWallScreenShots(pdfResult) {
        const walls = this.editor.getObjectByType(RoomObject).walls;
        for (let index = 0; index < this.editor.cabinets.length; index++) {
            if (this.editor.cabinets[index].type === 'island') {
                this.editor.cabinets[index].toggleVisibility(false);
            }
        }
        for (let index = 0; index < walls.length; index++) {
            if (walls[index].getObjectsOnWall().length !== 0) {
                walls[index].hideAllWallsBesidesThis();
                const cabinets = walls[index].getCabinetsOnWall();
                walls[index].addFullLengthMeasurementLine();
                let connectedPeninsula;
                for (const cabinet of cabinets) {
                    if (
                        cabinet.connectedCabinet &&
                        cabinet.connectedCabinet.sections[0].type === Constants.section.type.CORNER_SECTION
                    ) {
                        cabinet.connectedCabinet.sections[0].meshComponent.getMesh().setEnabled(true);
                        cabinet.connectedCabinet.sections[0].buildingBlocks.doorBlock.meshComponent.getMesh().setEnabled(true);
                        cabinet.connectedCabinet.sections[0].measurementLine.update();
                    }

                    if (
                        cabinet.secondConnectedCabinet &&
                        cabinet.secondConnectedCabinet.sections[0].type === Constants.section.type.CORNER_SECTION
                    ) {
                        cabinet.secondConnectedCabinet.sections[0].meshComponent.getMesh().setEnabled(true);
                        cabinet.secondConnectedCabinet.sections[0].buildingBlocks.doorBlock.meshComponent
                            .getMesh()
                            .setEnabled(true);
                        cabinet.secondConnectedCabinet.sections[0].measurementLine.update();
                    }
                    cabinet.toggleEdgesRendering(true);
                    if (cabinet.peninsulaConnection?.peninsula) {
                        connectedPeninsula = cabinet.peninsulaConnection.peninsula;
                        connectedPeninsula.toggleVisibility(true);
                        connectedPeninsula.toggleMeasurementLines(false);
                        connectedPeninsula.enablePeninsulaCornerMeasurements();
                    }
                }

                const wallRatio = walls[index].length / 2.8;
                if (this.editor.canvas.width > this.editor.canvas.height) {
                    await store.commit('core/resizeCanvas', {
                        width: this.editor.canvas.height * wallRatio,
                        height: this.editor.canvas.height,
                    });
                } else {
                    await store.commit('core/resizeCanvas', {
                        width: this.editor.canvas.width,
                        height: this.editor.canvas.width / wallRatio,
                    });
                }
                this.editor.sceneComponent.get().render();
                this.editor.sceneComponent.engine.resize();
                this.placeCameraInFrontOfWall(walls[index]);

                this.editor.sceneComponent.engine.flushFramebuffer();
                this.editor.sceneComponent.get().render();

                await this.createRTT(this.screenshotCamera, pdfResult);
                for (const cabinet of cabinets) {
                    cabinet.toggleEdgesRendering(false);
                }
                walls[index].meshComponent.enableEdges(false);
                walls[index].measurementLine.dispose();
                connectedPeninsula?.toggleVisibility(true);
            }
        }
    }

    renderToTexture(rtt, camera) {
        this.editor.sceneComponent.scene.incrementRenderId();
        this.editor.sceneComponent.scene.resetCachedMaterial();
        rtt.render(true);
        camera.getProjectionMatrix(true); // Force cache refresh;
        this.editor.sceneComponent.scene.render();
    }

    async createRTT(camera, pdfResult) {
        let self = this;
        this.editor.sceneComponent.engine.flushFramebuffer();
        camera.attachControl(this.editor.canvas, true);
        this.editor.sceneComponent.get().activeCameras.push(camera);
        const rtt = new RenderTargetTexture(
            'screenShot',

            {
                width: this.editor.sceneComponent.engine.getRenderWidth(),
                height: this.editor.sceneComponent.engine.getRenderHeight(),
            },
            this.editor.sceneComponent.get()
        );
        this.renderToTexture(rtt, camera);

        camera.outputRenderTarget = rtt;

        await new Promise((resolve, reject) => {
            window.setTimeout(() => {
                rtt.readPixels(undefined, undefined, undefined, false).then((data) => {
                    const { width, height } = rtt.getSize();
                    Tools.DumpData(
                        width,
                        height,
                        data,
                        async function (fileData) {
                            const jpgImage = await pdfResult.embedPng(fileData);
                            const page = pdfResult.addPage();
                            self.setPageOrientation(page);
                            const newSize = jpgImage.scaleToFit(page.getWidth(), page.getHeight() - 100);

                            const heightIn = newSize.height;
                            const widthIn = newSize.width;
                            page.drawImage(jpgImage, {
                                x: page.getWidth() / 2 - parseInt(widthIn) / 2,
                                y: page.getHeight() / 2 - parseInt(heightIn) / 2,
                                width: parseInt(widthIn),
                                height: parseInt(heightIn),
                            });
                        },
                        undefined,
                        undefined,
                        true
                    );
                });
                rtt.dispose();
                rtt.disposeFramebufferObjects();
                rtt.clearPostProcesses(true);
                this.editor.sceneComponent.get().activeCameras = [this.camera];
                resolve();
            }, 2000);
        });
    }

    async captureIslandScreenshots(pdfResult) {
        const walls = this.editor.getObjectByType(RoomObject).walls;
        for (let index = 0; index < walls.length; index++) {
            walls[index].hide(false);
        }
        for (let index = 0; index < this.editor.cabinets.length; index++) {
            if (this.editor.cabinets[index].type === 'island') {
                this.editor.sceneComponent.engine.flushFramebuffer();
                await this.placeCameraInFrontOfIsland(this.editor.cabinets[index]);
                this.editor.sceneComponent.engine.flushFramebuffer();

                const firstClosestRow = this.editor.cabinets[index].findClosestRowToPosition(
                    this.firstIslandScreenshotCamera.position
                );
                const secondClosestRow = this.editor.cabinets[index].findClosestRowToPosition(
                    this.secondIslandScreenshotCamera.position
                );

                this.editor.cabinets[index].togglePrintMeasurementLinesForRow(secondClosestRow, false);
                this.editor.cabinets[index].toggleEdgesRendering(secondClosestRow, false);
                this.editor.cabinets[index].toggleEdgesRendering(firstClosestRow, true);

                await this.createRTT(this.firstIslandScreenshotCamera, pdfResult);
                this.editor.sceneComponent.engine.flushFramebuffer();
                this.editor.cabinets[index].togglePrintMeasurementLinesForRow(secondClosestRow, true);
                this.editor.cabinets[index].togglePrintMeasurementLinesForRow(firstClosestRow, false);

                this.editor.cabinets[index].toggleEdgesRendering(secondClosestRow, true);
                this.editor.cabinets[index].toggleEdgesRendering(firstClosestRow, false);

                await this.createRTT(this.secondIslandScreenshotCamera, pdfResult);
                this.editor.cabinets[index].togglePrintMeasurementLinesForRow(secondClosestRow, false);
                this.editor.cabinets[index].toggleEdgesRendering(secondClosestRow, false);

                this.editor.cabinets[index].toggleMeasurementLinesForRow('firstRow', true);
                this.editor.cabinets[index].toggleMeasurementLinesForRow('secondRow', true);
            }
        }
    }

    async placeCameraInFrontOfIsland(island) {
        island.toggleVisibility(true);
        island.buildMeasurementLinesForPrint();

        const middle = island.getCenterAfterReplacement();
        this.firstIslandScreenshotCamera.position = middle.clone();
        this.secondIslandScreenshotCamera.position = middle.clone();
        const distance = 2.8;

        const rect = this.editor.sceneComponent.engine.getRenderingCanvasClientRect();
        const aspect = rect.height / rect.width;

        if ([Math.PI, 0].includes(island.meshComponent.getMesh().rotation.y)) {
            this.firstIslandScreenshotCamera.position.z -= distance;
            this.secondIslandScreenshotCamera.position.z += distance;
        } else {
            this.firstIslandScreenshotCamera.position.x -= distance;
            this.secondIslandScreenshotCamera.position.x += distance;
        }

        this.firstIslandScreenshotCamera.orthoLeft = -(distance / 2) / aspect;
        this.firstIslandScreenshotCamera.orthoRight = distance / 2 / aspect;
        this.firstIslandScreenshotCamera.orthoTop = (5 * distance) / 6;
        this.firstIslandScreenshotCamera.orthoBottom = -distance / 6;

        this.secondIslandScreenshotCamera.orthoLeft = -(distance / 2) / aspect;
        this.secondIslandScreenshotCamera.orthoRight = distance / 2 / aspect;
        this.secondIslandScreenshotCamera.orthoTop = (5 * distance) / 6;
        this.secondIslandScreenshotCamera.orthoBottom = -distance / 6;

        this.firstIslandScreenshotCamera.target = middle.clone();
        this.secondIslandScreenshotCamera.target = middle.clone();
    }

    placeCameraInFrontOfWall(wall) {
        wall.hide(true);
        wall.buildMeasurementLinesForPrint();
        const wallDirection = wall.getDirection();
        const middleOfWall = wall.baseInnerCornerB.clone().add(wallDirection.scale(wall.length / 2));
        const roomCorners = this.editor.sceneInfo.corners.concat(this.editor.sceneInfo.corners[0]);

        const rect = this.editor.sceneComponent.engine.getRenderingCanvasClientRect();
        const aspect = rect.height / rect.width;
        let distance = 2.8 + 1;
        this.screenshotCamera.orthoLeft = -(distance / 2) / aspect;
        this.screenshotCamera.orthoRight = distance / 2 / aspect;
        this.screenshotCamera.orthoTop = (5 * distance) / 6;
        this.screenshotCamera.orthoBottom = -distance / 6;
        if (wall.length / 2.8 >= 1 / aspect) {
            distance = wall.length + 1 + 0.65;
            this.screenshotCamera.orthoLeft = -(distance / 2);
            this.screenshotCamera.orthoRight = distance / 2;
            this.screenshotCamera.orthoTop = ((5 * distance) / 6) * aspect;
            this.screenshotCamera.orthoBottom = (-distance / 6) * aspect;
        }

        const shiftedPosition = Utilities.calculateShiftValue(middleOfWall, -distance, roomCorners, wall);

        this.screenshotCamera.position = shiftedPosition;
        this.createScreenshotRay(middleOfWall);
        const hit = this.editor.sceneComponent.get().pickWithRay(this.screenshotRay);

        if (hit.pickedMesh?.type === 'wall') {
            const wallComponent = this.editor.getObjectByType(RoomObject).walls[hit.pickedMesh.id];
            wallComponent.meshComponent.getMesh().visibility = 0;
            wallComponent.hideCabinets(false);
        }

        this.screenshotCamera.target = middleOfWall;
        this.screenshotCamera.computeWorldMatrix();
    }

    zoomIn() {
        console.log('zoom in');
        this.camera.radius -= 0.3;
    }

    zoomOut() {
        console.log('zoom out');
        this.camera.radius += 0.3;
    }
}
