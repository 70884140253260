export default {
    id: 'cabinet',
    name: 'Cabinet',
    type: {
        base: {
            id: 'base',
            name: 'Base Cabinet',
            img: require('@/assets/icons/cabinets/base-cabinet.svg'),
            dimensions: {
                width: {
                    value: {
                        mm: 600,
                        in: 24,
                    },
                    min: {
                        mm: 100,
                        in: 3.93,
                    },
                    max: {
                        mm: 10000,
                        in: 393.7,
                    },
                },
                height: {
                    value: {
                        mm: 900,
                        in: 36,
                    },
                    min: {
                        mm: 100,
                        in: 3.94,
                    },
                    max: {
                        mm: 1000,
                        in: 47.2,
                    },
                },
                depth: {
                    value: {
                        mm: 580,
                        in: 24,
                    },
                    min: {
                        mm: 100,
                        in: 3.94,
                    },
                    max: {
                        mm: 700,
                        in: 27.55,
                    },
                },
            },
        },
        wall: {
            id: 'wall',
            name: 'Wall Cabinet',
            img: require('@/assets/icons/cabinets/wall-cabinet.svg'),
            dimensions: {
                width: {
                    value: {
                        mm: 600,
                        in: 24,
                    },
                    min: {
                        mm: 100,
                        in: 3.93,
                    },
                    max: {
                        mm: 10000,
                        in: 393.7,
                    },
                },
                height: {
                    value: {
                        mm: 700,
                        in: 28,
                    },
                    min: {
                        mm: 100,
                        in: 3.94,
                    },
                    max: {
                        mm: 1500,
                        in: 91.5,
                    },
                },
                depth: {
                    value: {
                        mm: 360,
                        in: 14,
                    },
                    min: {
                        mm: 100,
                        in: 3.94,
                    },
                    max: {
                        mm: 700,
                        in: 19,
                    },
                },
                heightFromFloor: {
                    value: {
                        mm: 1450,
                        in: 57,
                    },
                    min: {
                        mm: 100,
                        in: 3.94,
                    },
                    max: {
                        mm: 3000,
                        in: 122,
                    },
                },
            },
        },
        tall: {
            id: 'tall',
            name: 'Tall Cabinet',
            img: require('@/assets/icons/cabinets/tall-cabinet.svg'),
            dimensions: {
                width: {
                    value: {
                        mm: 600,
                        in: 24,
                    },
                    min: {
                        mm: 100,
                        in: 3.93,
                    },
                    max: {
                        mm: 10000,
                        in: 393.7,
                    },
                },
                height: {
                    value: {
                        mm: 2150,
                        in: 84.6,
                    },
                    min: {
                        mm: 100,
                        in: 3.94,
                    },
                    max: {
                        mm: 3000,
                        in: 118,
                    },
                },
                depth: {
                    value: {
                        mm: 580,
                        in: 24,
                    },
                    min: {
                        mm: 100,
                        in: 3.94,
                    },
                    max: {
                        mm: 700,
                        in: 27.55,
                    },
                },
            },
        },
        island: {
            id: 'island',
            name: 'Island',
            img: require('@/assets/icons/cabinets/island.svg'),
            dimensions: {
                width: {
                    value: {
                        mm: 900,
                        in: 36,
                    },
                    min: {
                        mm: 100,
                        in: 3.93,
                    },
                    max: {
                        mm: 10000,
                        in: 393.7,
                    },
                },
                height: {
                    value: {
                        mm: 900,
                        in: 36,
                    },
                    min: {
                        mm: 100,
                        in: 3.94,
                    },
                    max: {
                        mm: 1000,
                        in: 47.2,
                    },
                },
                depth: {
                    value: {
                        mm: 600,
                        in: 24,
                    },
                    items: {
                        mm: [600, 900, 1200],
                        in: [24, 36, 48],
                    },
                    min: {
                        mm: 300,
                        in: 12,
                    },
                    max: {
                        mm: 1500,
                        in: 59,
                    },
                },
            },
        },
    },
};
