import Vue from 'vue';
import VueRouter from 'vue-router';
import Editor from '@/views/Editor.vue';
import { authGuard } from '../auth/authGuard';
// import { getInstance } from '../auth';

Vue.use(VueRouter);

const routes = [
    {
        path: '/:projectId?',
        name: 'editor',
        beforeEnter: authGuard,
        component: Editor,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
