var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.selectedAppliance && _vm.object.length
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(
              " More than one appliance and objects selected. Select only one appliance or multiple objects of the same type to edit. "
            ),
          ])
        : !_vm.selectedAppliance && !_vm.isTypeSame
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(" Not same type selected "),
          ])
        : _vm.selectedAppliance
        ? _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [_c("ApplianceEdit")],
            1
          )
        : _c(
            "v-tabs",
            {
              attrs: {
                "background-color": "indigo",
                centered: "",
                height: "38",
                dark: "",
                "slider-size": "5",
                "show-arrows": "",
              },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tabs-slider", { attrs: { color: "yellow" } }),
              _c(
                "v-btn",
                {
                  staticClass: "my-auto",
                  attrs: { color: "indigo lighten-2", small: "" },
                  on: { click: _vm.deselectObjects },
                },
                [
                  _c("v-icon", { attrs: { color: "indigo darken-3" } }, [
                    _vm._v(_vm._s(_vm.mdiArrowLeftCircle)),
                  ]),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.smAndUp
                ? _c(
                    "v-tab",
                    { staticClass: "float-left", attrs: { disabled: "" } },
                    [
                      _vm._v(
                        _vm._s(_vm.object[0].type) +
                          ", " +
                          _vm._s(_vm.object[0].subtype)
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.dimensionProps && !_vm.isManySelected && !_vm.isBuiltinHood
                ? _c(
                    "v-tab",
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiTapeMeasure))])],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab",
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiPaletteOutline))])],
                1
              ),
              _vm.config.doorType
                ? _c(
                    "v-tab",
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFileCabinet))])],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tabs-items",
                {
                  attrs: { continuous: "" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _vm.$vuetify.breakpoint.smAndUp ? _c("v-tab-item") : _vm._e(),
                  _vm.dimensionProps &&
                  !_vm.isManySelected &&
                  !_vm.isBuiltinHood
                    ? _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              !_vm.isSubtypeSame
                                ? _c("v-alert", { attrs: { type: "error" } }, [
                                    _vm._v(" Not same subtype selected "),
                                  ])
                                : _c(
                                    "v-row",
                                    {
                                      staticClass: "indigo lighten-5 pt-2",
                                      attrs: { justify: "center" },
                                    },
                                    _vm._l(
                                      _vm.dimensionProps,
                                      function (value, key) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: key,
                                            staticClass: "pa-2",
                                            attrs: {
                                              cols: "6",
                                              sm: "3",
                                              "align-self": "start",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-form",
                                              { ref: key, refInFor: true },
                                              [
                                                _c("v-combobox", {
                                                  ref: key,
                                                  refInFor: true,
                                                  attrs: {
                                                    items:
                                                      _vm.config.dimensions[key]
                                                        .items[
                                                        _vm.selectedUnit
                                                      ],
                                                    label: key,
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                    subtype: "number",
                                                    rules: [
                                                      _vm.rules.required,
                                                      _vm.rules.between(
                                                        _vm.config.dimensions[
                                                          key
                                                        ].min[_vm.selectedUnit],
                                                        _vm.config.dimensions[
                                                          key
                                                        ].max[_vm.selectedUnit]
                                                      ),
                                                    ],
                                                    suffix: _vm.selectedUnit,
                                                    disabled:
                                                      key === "height" ||
                                                      _vm.disableCornerInput,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.resize(key)
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "no-data",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "v-list-item",
                                                              [
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          " Press "
                                                                        ),
                                                                        _c(
                                                                          "kbd",
                                                                          [
                                                                            _vm._v(
                                                                              "enter"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " to update "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.dimensionProps[key]
                                                        .value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dimensionProps[key],
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dimensionProps[key].value",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            key === "width"
                                              ? _c(
                                                  "v-btn-toggle",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-center",
                                                    attrs: {
                                                      tile: "",
                                                      color: "primary",
                                                      mandatory: "",
                                                      "background-color":
                                                        "indigo lighten-5",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeDirection(
                                                          $event,
                                                          key
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.dimensionProps[key]
                                                          .direction.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.dimensionProps[
                                                            key
                                                          ].direction,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "\n                                        dimensionProps[key].direction.value\n                                    ",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.dimensionProps[key]
                                                      .direction.items,
                                                    function (direction) {
                                                      return _c(
                                                        "v-btn",
                                                        {
                                                          key: direction,
                                                          attrs: {
                                                            value: direction,
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.mdiArrowDirection(
                                                                    key,
                                                                    direction
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-tab-item", [
                    _c(
                      "div",
                      { staticClass: "text-center ma-3" },
                      _vm._l(_vm.config.collections, function (collection, i) {
                        return _c(
                          "v-bottom-sheet",
                          {
                            key: collection.name,
                            attrs: { inset: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: "primary",
                                                outlined: "",
                                                dark: "",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " + _vm._s(collection.name) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: collection.sheet,
                              callback: function ($$v) {
                                _vm.$set(collection, "sheet", $$v)
                              },
                              expression: "collection.sheet",
                            },
                          },
                          [
                            _c(
                              "v-sheet",
                              { staticClass: "text-center" },
                              [
                                _c("EditDesignCollectionSheet", {
                                  attrs: {
                                    collection: collection,
                                    collIdx: i,
                                    isTypeSame: _vm.isTypeSame,
                                    config: _vm.config,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ]),
                  _vm.config.doorType
                    ? _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-container",
                            { staticClass: "pa-0", attrs: { fluid: "" } },
                            [
                              !_vm.isSubtypeSame
                                ? _c("v-alert", { attrs: { type: "error" } }, [
                                    _vm._v(" Not same subtype selected "),
                                  ])
                                : _c(
                                    "v-slide-group",
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        mandatory: "",
                                        "show-arrows": "",
                                        value: _vm.object[0].door.type,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeDoorType($event)
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.doorTypeArr,
                                      function (doorType) {
                                        return _c("v-slide-item", {
                                          key: doorType.id,
                                          attrs: { value: doorType.id },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  active,
                                                  toggle,
                                                }) {
                                                  return [
                                                    _c("v-hover", {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              hover,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "pa-1 ma-2",
                                                                    attrs: {
                                                                      color:
                                                                        active
                                                                          ? "green lighten-1"
                                                                          : null,
                                                                      elevation:
                                                                        hover
                                                                          ? 16
                                                                          : 2,
                                                                      width: _vm
                                                                        .$vuetify
                                                                        .breakpoint
                                                                        .smAndUp
                                                                        ? 100
                                                                        : 100,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        toggle,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "py-2 text-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-img",
                                                                          {
                                                                            staticClass:
                                                                              "pa-2",
                                                                            attrs:
                                                                              {
                                                                                src: doorType.img,
                                                                                height:
                                                                                  "50",
                                                                                contain:
                                                                                  "",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm.$vuetify
                                                                      .breakpoint
                                                                      .smAndUp
                                                                      ? _c(
                                                                          "v-card-subtitle",
                                                                          {
                                                                            staticClass:
                                                                              "px-0 text-caption text-center py-1",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                doorType.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      }
                                    ),
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }