import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { BaseUnit } from './BaseUnit';
import { Vector3 } from '@babylonjs/core';
import { BaseAppliance } from '../Appliances/BaseAppliance';

export class WallUnit extends BaseUnit {
    constructor() {
        super();
    }

    calculateSpaceNeededForFiller() {
        //Calculates the minimum space needed for filler
        //This is not necessarily the filler space,
        // could be bigger for specific layouts but never smaller

        const cabinetInfo = this.getPositionInfo();
        let spaceLeftForFiller = 0;

        if (this.connectedCabinet) {
            if (cabinetInfo.cornerB || this.adaptToPreviousCabinet) {
                spaceLeftForFiller = Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
            }
        } else if (this.secondConnectedCabinet) {
            if (cabinetInfo.cornerA || this.adaptToPreviousCabinet) {
                spaceLeftForFiller = Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
            }
        } else if (cabinetInfo.cornerA && cabinetInfo.cornerB) {
            spaceLeftForFiller = 2 * Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
        } else if (cabinetInfo.cornerA || cabinetInfo.cornerB) {
            spaceLeftForFiller = Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
            if (
                (this.baseCabinetUnderneath?.connectedCabinet?.type === Constants.cabinet.type.TALL && cabinetInfo.cornerB) ||
                (this.baseCabinetUnderneath?.secondConnectedCabinet?.type === Constants.cabinet.type.TALL && cabinetInfo.cornerA)
            ) {
                spaceLeftForFiller = 2 * Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
            }
        } else if (
            this.baseCabinetUnderneath?.connectedCabinet?.type === Constants.cabinet.type.TALL ||
            this.baseCabinetUnderneath?.secondConnectedCabinet?.type === Constants.cabinet.type.TALL
        ) {
            spaceLeftForFiller = Constants.STANDARD_FILLER_SIZE[this.editor.unit];
        }
        return spaceLeftForFiller;
    }

    calculateStartingFillerForSymmetry() {
        //The wall unit's starting filler size should satisfy the equation below
        // extended cabinet's depth section + starting filler of base unit underneath = firstSection + starting filler of wall unit
        // OR first section size + starting filler of base unit underneath = firstSection + starting filler of wall unit
        let startingFiller = 0;
        let sameReplacementDirection = this.baseCabinetUnderneath
            ? this.replacementData.direction.equals(this.baseCabinetUnderneath.replacementData.direction)
            : false;
        const positionInfo = this.getPositionInfo();

        if (this.baseCabinetUnderneath) {
            let baseCabinetFirstSection = 0;
            if (sameReplacementDirection) {
                if (this.baseCabinetUnderneath.connectedCabinet && positionInfo.cornerA) {
                    baseCabinetFirstSection = this.baseCabinetUnderneath.connectedCabinet.depth;
                } else if (this.baseCabinetUnderneath.secondConnectedCabinet && positionInfo.cornerB) {
                    baseCabinetFirstSection = this.baseCabinetUnderneath.secondConnectedCabinet.depth;
                } else if (!this.baseCabinetUnderneath.sections[1].appliance) {
                    baseCabinetFirstSection = this.baseCabinetUnderneath.sections[1].width;
                }
            } else {
                if (this.baseCabinetUnderneath.connectedCabinet && this.baseCabinetUnderneath.secondConnectedCabinet) {
                    baseCabinetFirstSection = this.baseCabinetUnderneath.connectedCabinet.depth;
                }
                //  else if (this.baseCabinetUnderneath.connectedCabinet && positionInfo.corner) {
                //     baseCabinetFirstSection = this.baseCabinetUnderneath.connectedCabinet.depth;
                // } else if (this.baseCabinetUnderneath.secondConnectedCabinet && positionInfo.cornerB) {
                //     baseCabinetFirstSection = this.baseCabinetUnderneath.secondConnectedCabinet.depth;
                // }
                else {
                    baseCabinetFirstSection =
                        this.baseCabinetUnderneath.sections[this.baseCabinetUnderneath.sections.length - 2].width;
                }
            }

            const matchingFiller = this.getMatchingFillerUnderneath();
            let leftSum = baseCabinetFirstSection + matchingFiller;

            let firstSectionWidth = Utilities.getClosestSmallerNumberInArray(
                leftSum - Constants.SMALLEST_FILLER_SIZE[this.editor.unit],
                Constants.availableCabinetDimensions[this.editor.unit]
            );
            if (leftSum <= Constants.availableCabinetDimensions[this.editor.unit][0]) {
                firstSectionWidth = 0;
            }
            if (matchingFiller === 0) {
                startingFiller = 0;
            } else if (leftSum > firstSectionWidth) {
                startingFiller = leftSum - firstSectionWidth;
            }
        } else if ((positionInfo.cornerA && !this.connectedCabinet) || (positionInfo.cornerB && !this.secondConnectedCabinet)) {
            startingFiller = Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
        }

        if (this.connectedCabinet || this.secondConnectedCabinet || (!positionInfo.cornerA && !positionInfo.cornerB)) {
            startingFiller = 0;
        }
        return startingFiller;
    }

    getMatchingFillerUnderneath() {
        if (this.baseCabinetUnderneath) {
            const baseCabinetSections = this.baseCabinetUnderneath.sections;
            const firstSectionPosition = baseCabinetSections[0].position;
            const lastSectionPosition = baseCabinetSections[baseCabinetSections.length - 1].position;

            const replacementDataStartingPoint = this.replacementData.startingPoint.clone();
            replacementDataStartingPoint.y = firstSectionPosition.y;

            const differenceFromFirstSection = Vector3.Distance(firstSectionPosition, this.replacementData.startingPoint);
            const differenceFromLastSection = Vector3.Distance(lastSectionPosition, this.replacementData.startingPoint);

            if (differenceFromLastSection < differenceFromFirstSection) {
                return this.baseCabinetUnderneath.fillerData.endingFillerSize;
            } else {
                return this.baseCabinetUnderneath.fillerData.startingFillerSize;
            }
        }
    }

    calculateCornerCabinetDoorForSymmetry() {
        if (this.baseCabinetUnderneath) {
            const leftSum = this.baseCabinetUnderneath.depth + Constants.STANDARD_FILLER_SIZE[this.editor.unit];
            this.cornerCabinetDoorSize = leftSum - this.depth;
        } else {
            this.cornerCabinetDoorSize = Constants.wallCornerCabinet.DOOR_SIZE[this.editor.unit];
        }
    }

    getCornerCabinetDoor() {
        if (!this.cornerCabinetDoorSize) {
            this.calculateCornerCabinetDoorForSymmetry();
        }
        return this.cornerCabinetDoorSize;
    }

    adaptToBaseCabinetUnderneath() {
        if (!this.baseCabinetUnderneath || !this.baseCabinetUnderneath?.sections[0]) {
            return;
        }

        const assignedWallDirection = this.assignedWall.getDirection();
        const blockedSegmentOnWall = this.getEdgesOnWall();

        const baseCabinetBlockedSegment = this.baseCabinetUnderneath.getEdgesAfterReplacement();

        //If there is a peninsula attached at the base cabinet underneath
        const peninsulaConnection = this.baseCabinetUnderneath?.peninsulaConnection;
        if (peninsulaConnection.peninsula) {
            baseCabinetBlockedSegment[peninsulaConnection.edgeIndex] = peninsulaConnection.connectionEdge;
        }

        //calculating difference between starting and ending points of the wall cabinet and its base cabinet underneath
        let distanceFromFirstPoints = Vector3.Distance(blockedSegmentOnWall.firstPoint, baseCabinetBlockedSegment.firstPoint);
        let distanceFromSecondPoints = Vector3.Distance(blockedSegmentOnWall.secondPoint, baseCabinetBlockedSegment.secondPoint);
        if (this.connectedCabinet && this.baseCabinetUnderneath.connectedCabinet) {
            distanceFromFirstPoints = 0;
        } else if (this.secondConnectedCabinet && this.baseCabinetUnderneath.secondConnectedCabinet) {
            distanceFromSecondPoints = 0;
        }
        if (
            distanceFromFirstPoints > 0 &&
            distanceFromFirstPoints < Constants.symmetry.MARGIN &&
            distanceFromSecondPoints > 0 &&
            distanceFromSecondPoints < Constants.symmetry.MARGIN
        ) {
            this.meshComponent.getMesh().scaling.x = this.baseCabinetUnderneath.filledWidth / this.baseWidth;

            const position = baseCabinetBlockedSegment.firstPoint.subtract(
                assignedWallDirection.scale(this.baseCabinetUnderneath.filledWidth / 2)
            );

            this.meshComponent.getMesh().position = position;

            const heightFromFloor = this.meshComponent.getMesh().position.y;
            this.meshComponent.getMesh().position.y = heightFromFloor;
            this.shiftCabinet();
        } else if (distanceFromFirstPoints > 0 && distanceFromFirstPoints < Constants.symmetry.MARGIN && !this.connectedCabinet) {
            let resizingAmount = distanceFromFirstPoints;
            assignedWallDirection.negateInPlace();
            if (Vector3.Distance(blockedSegmentOnWall.secondPoint, baseCabinetBlockedSegment.firstPoint) < this.width) {
                resizingAmount = -distanceFromFirstPoints;
            }
            let scaling = (this.width + resizingAmount) / this.baseWidth;
            this.meshComponent.getMesh().scaling.x = scaling;
            this.meshComponent.getMesh().position.subtractInPlace(assignedWallDirection.clone().scale(resizingAmount / 2));
        } else if (
            distanceFromSecondPoints > 0 &&
            distanceFromSecondPoints < Constants.symmetry.MARGIN &&
            !this.secondConnectedCabinet
        ) {
            let resizingAmount = distanceFromSecondPoints;
            if (Vector3.Distance(blockedSegmentOnWall.firstPoint, baseCabinetBlockedSegment.secondPoint) < this.width) {
                resizingAmount = -distanceFromSecondPoints;
            }
            let scaling = (this.width + resizingAmount) / this.baseWidth;
            this.meshComponent.getMesh().scaling.x = scaling;
            this.meshComponent.getMesh().position.subtractInPlace(assignedWallDirection.clone().scale(resizingAmount / 2));
        }
        this.width = this.meshComponent.getMesh().scaling.x * this.baseWidth;
    }

    isReplacingFromLeftToRight() {
        const wallDirection = this.assignedWall.getDirection();
        const replacementDirection = this.replacementData.direction;
        if (wallDirection.equals(replacementDirection)) {
            return true;
        }
    }

    calculateStartingPointAndReplacementDirection() {
        this.editor.cabinetReplacer.cabinetAligner.alignWallAndBaseCabinet(this);

        const cabinetPositionInfo = this.getPositionInfo();
        //this is the segment where we are allowed to put sections on
        const allowedSegment = this.calculateAllowedSegment(false);

        const wallDirection = this.assignedWall.getDirection();
        const wallReplacementDirection = this.assignedWall.getReplacementDirection();

        const obstacleNearCornerB = this.obstacleManager.getObstacleNearCornerB();
        const obstacleNearCornerA = this.obstacleManager.getObstacleNearCornerA();

        let startingPoint = Constants.corner.CORNER_B;
        let obstacle = obstacleNearCornerB;
        if (!wallDirection.equals(wallReplacementDirection)) {
            startingPoint = Constants.corner.CORNER_A;
            obstacle = obstacleNearCornerA;
        }
        if (obstacle instanceof BaseAppliance) {
            obstacle = null;
        }
        if (!this.shouldAdaptToPreviousCabinet(obstacle)) {
            obstacle = null;
        }

        this.replacementData.direction = wallReplacementDirection;

        if (this.cornerCabinetInfo) {
            if (this.cornerCabinetInfo.cornerIndex === Constants.corner.CORNER_B) {
                this.replacementData.startingPoint = this.assignedWall.baseInnerCornerB;
                allowedSegment.secondPoint = this.replacementData.startingPoint;
                this.replacementData.direction = this.assignedWall.getDirection();
                if (this.connectedCabinet) {
                    allowedSegment.firstPoint.addInPlace(
                        this.replacementData.direction.clone().scale(-this.getCornerCabinetDoor())
                    );
                }
            } else if (this.cornerCabinetInfo.cornerIndex === Constants.corner.CORNER_A) {
                this.replacementData.startingPoint = this.assignedWall.baseInnerCornerA;
                allowedSegment.firstPoint = this.replacementData.startingPoint;
                this.replacementData.direction = this.assignedWall.getDirection().negate();
                if (this.secondConnectedCabinet) {
                    allowedSegment.secondPoint.addInPlace(
                        this.replacementData.direction.clone().scale(-this.getCornerCabinetDoor())
                    );
                }
            }
            if (obstacle) {
                //WRITE THIS BETTER
                this.adaptToPreviousCabinet = true;
            }
        } else if (this.secondConnectedCabinet) {
            this.replacementData.direction = this.assignedWall.getDirection();
            allowedSegment.secondPoint.addInPlace(
                this.replacementData.direction.clone().scale(this.secondConnectedCabinet.getCornerCabinetDoor())
            );
            this.replacementData.startingPoint = allowedSegment.secondPoint;

            if (this.connectedCabinet) {
                allowedSegment.firstPoint.addInPlace(this.replacementData.direction.clone().scale(-this.getCornerCabinetDoor()));
            }
            if (obstacle) {
                //WRITE THIS BETTER
                this.adaptToPreviousCabinet = true;
            }
        } else if (this.connectedCabinet) {
            this.replacementData.direction = this.assignedWall.getDirection().negate();
            allowedSegment.firstPoint.addInPlace(
                this.replacementData.direction.clone().scale(this.connectedCabinet.getCornerCabinetDoor())
            );
            this.replacementData.startingPoint = allowedSegment.firstPoint;
            if (this.secondConnectedCabinet) {
                allowedSegment.secondPoint.addInPlace(this.replacementData.direction.clone().scale(-this.getCornerCabinetDoor()));
            }

            if (obstacle) {
                //WRITE THIS BETTER
                this.adaptToPreviousCabinet = true;
            }
        } else if (cabinetPositionInfo.cornerB) {
            this.replacementData.startingPoint = allowedSegment.secondPoint;
            this.replacementData.direction = this.assignedWall.getDirection();
            if (obstacle) {
                //WRITE THIS BETTER
                this.adaptToPreviousCabinet = true;
            }
        } else if (cabinetPositionInfo.cornerA) {
            this.replacementData.direction = this.assignedWall.getDirection().negate();
            this.replacementData.startingPoint = allowedSegment.firstPoint;
            if (obstacle) {
                //WRITE THIS BETTER
                this.adaptToPreviousCabinet = true;
            }
        } else if (obstacle) {
            const edgesAfterReplacement = obstacle.getEdgesAfterReplacement();
            let nearestEdge = edgesAfterReplacement.firstPoint;
            if (
                Vector3.Distance(this.meshComponent.getPosition(), edgesAfterReplacement.firstPoint) >
                Vector3.Distance(this.meshComponent.getPosition(), edgesAfterReplacement.secondPoint)
            ) {
                nearestEdge = edgesAfterReplacement.secondPoint;
            }

            this.replacementData.startingPoint = nearestEdge;
        } else {
            if (
                startingPoint === Constants.corner.CORNER_B &&
                obstacleNearCornerA &&
                !(obstacleNearCornerA instanceof BaseAppliance) &&
                this.shouldAdaptToPreviousCabinet(obstacleNearCornerA)
            ) {
                this.replacementData.direction = this.assignedWall.getDirection().negate();
                const edgesAfterReplacement = obstacleNearCornerA.getEdgesAfterReplacement();
                this.replacementData.startingPoint = edgesAfterReplacement.secondPoint.clone();
                allowedSegment.firstPoint = edgesAfterReplacement.secondPoint.clone();
            } else if (
                startingPoint === Constants.corner.CORNER_A &&
                obstacleNearCornerB &&
                !(obstacleNearCornerB instanceof BaseAppliance) &&
                this.shouldAdaptToPreviousCabinet(obstacleNearCornerB)
            ) {
                const edgesAfterReplacement = obstacleNearCornerB.getEdgesAfterReplacement();
                this.replacementData.startingPoint = edgesAfterReplacement.firstPoint.clone();
                allowedSegment.secondPoint = edgesAfterReplacement.firstPoint.clone();
                this.replacementData.direction = this.assignedWall.getDirection();
            } else {
                this.replacementData.startingPoint = allowedSegment.secondPoint;
                this.replacementData.direction = this.assignedWall.getDirection();
            }
        }

        return [this.replacementData.direction, this.replacementData.startingPoint, allowedSegment];
    }
}
