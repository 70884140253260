import { Component } from '../../../../Components/Base/Component';
import { Constants } from '../../../../Tools/constants';
import { Utilities } from '../../../../Tools/utilities';

export class ResizingManager extends Component {
    constructor() {
        super();
    }

    resizeWidth(dimensions) {
        this.resizeCabinetBlockInWidth(dimensions);
        this.resizeCornerCabinetLHFInWidth(dimensions);
        this.resizeCountertopLHFInWidth(dimensions);
        this.resizeSkirtingLHFInWidth(dimensions);
    }

    resizeCabinetBlockInWidth(dimensions) {
        let width = dimensions.width;
        width -= Constants.baseCabinetModel.sidePanel.WIDTH * 2;

        Utilities.resizeMeshInWidth(this.parts.cabinet.panelBottom.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cabinet.panelTopFront.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cabinet.panelTopBack.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cabinet.panelBack.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cornerCabinetLHF.islandLHF.panelIslandLHF.mesh, dimensions.width);

        const panelBackDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBack.mesh);
        const shift = (width - panelBackDimensions.width) / 2;
        this.parts.cabinet.panelLeft.mesh.position.x -= shift;
        this.parts.cabinet.panelRight.mesh.position.x += shift;
    }

    resizeCornerCabinetLHFInWidth(dimensions) {
        Utilities.resizeMeshInWidth(this.parts.cornerCabinetLHF.doorLHF.mesh, dimensions.doorWidth);
        const countertopBaseDimensions = Utilities.getMeshDimensions(
            this.parts.cornerCabinetLHF.countertopLHF.countertopLHF1.mesh
        );

        const panelFrontLHFDimensions = Utilities.getMeshDimensions(this.parts.cornerCabinetLHF.panelFrontLHF.mesh);
        let panelShift = (dimensions.connectedCabinetDepth - panelFrontLHFDimensions.width) / 2;

        Utilities.resizeMeshInWidth(this.parts.cornerCabinetLHF.panelFrontLHF.mesh, dimensions.connectedCabinetDepth);

        const wholeWidthShift = (dimensions.width - countertopBaseDimensions.width) / 2;
        const doorLHFDimensions = Utilities.getMeshDimensions(this.parts.cornerCabinetLHF.doorLHF.mesh);
        let doorWidthShift = (dimensions.doorWidth - doorLHFDimensions.width) / 2;

        this.parts.cornerCabinetLHF.doorLHF.mesh.position.x -= wholeWidthShift - doorWidthShift;
        this.parts.cornerCabinetLHF.fillerLHF.outerFillerCornerLHF1.mesh.position.x -= panelShift - doorWidthShift;
        this.parts.cornerCabinetLHF.panelFrontLHF.mesh.position.x += wholeWidthShift - panelShift;
    }

    resizeCountertopLHFInWidth(dimensions) {
        //margin to make gap less visible
        dimensions.width += 0.003;
        Utilities.resizeMeshInWidth(this.parts.cornerCabinetLHF.countertopLHF.countertopLHF1.mesh, dimensions.width);
        Utilities.resizeMeshInWidth(this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF1.mesh, dimensions.width);
        Utilities.resizeMeshInWidth(this.parts.cornerCabinetLHF.islandLHF.countertopLHFIslandBack.mesh, dimensions.width);

        const countertopLHF1Dimensions = Utilities.getMeshDimensions(
            this.parts.cornerCabinetLHF.countertopLHF.countertopLHF1.mesh
        );
        const shift = (dimensions.width - countertopLHF1Dimensions.width) / 2;
        this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF2.mesh.position.x += shift;
    }

    resizeSkirtingLHFInWidth(dimensions) {
        const countertopBaseDimensions = Utilities.getMeshDimensions(
            this.parts.cornerCabinetLHF.countertopLHF.countertopLHF1.mesh
        );
        const skirtingLHF1Dimensions = Utilities.getMeshDimensions(this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF1.mesh);
        const wholeWidthShift = (dimensions.width - countertopBaseDimensions.width) / 2;
        Utilities.resizeMeshInWidth(this.parts.cornerCabinetLHF.islandLHF.skirtingIslandLHF.mesh, dimensions.width);
        Utilities.resizeMeshInWidth(this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF1.mesh, dimensions.doorWidth + 0.06);

        const shift = (dimensions.doorWidth + 0.06 - skirtingLHF1Dimensions.width) / 2;

        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF1.mesh.position.x -= wholeWidthShift - shift;
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF5.mesh.position.x -= wholeWidthShift;

        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF2.mesh.position.x += wholeWidthShift;
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF3.mesh.position.x += wholeWidthShift;
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF4.mesh.position.x += wholeWidthShift;
    }

    resizeDepth(dimensions) {
        this.resizeCabinetBlockInDepth(dimensions);
        this.resizeCornerCabinetLHFInDepth(dimensions);
        this.resizeSkirtingLHFInDepth(dimensions);
        this.resizeCountertopLHFInDepth(dimensions);
    }

    resizeCabinetBlockInDepth(dimensions) {
        const DOOR_DEPTH = 0.024;
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelLeft.mesh, dimensions.depth - DOOR_DEPTH);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelRight.mesh, dimensions.depth - DOOR_DEPTH);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelBottom.mesh, dimensions.depth - DOOR_DEPTH);
        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);

        const shift = (dimensions.depth - panelBottomDimensions.depth) / 2;
        this.parts.cabinet.panelTopBack.mesh.position.z += shift;
        this.parts.cabinet.panelTopFront.mesh.position.z -= shift;
        this.parts.cabinet.panelBack.mesh.position.z += shift;
        this.parts.cornerCabinetLHF.islandLHF.panelIslandLHF.mesh.position.z +=
            (dimensions.depth - DOOR_DEPTH - panelBottomDimensions.depth) / 2;
        this.parts.cornerCabinetLHF.islandLHF.countertopLHFIslandBack.mesh.position.z +=
            (dimensions.depth - DOOR_DEPTH - panelBottomDimensions.depth) / 2;
    }

    resizeCornerCabinetLHFInDepth(dimensions) {
        const DOOR_DEPTH = 0.024;
        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);
        const shift = (dimensions.depth - DOOR_DEPTH - panelBottomDimensions.depth) / 2;
        //door
        this.parts.cornerCabinetLHF.doorLHF.mesh.position.z -= shift;
        this.parts.cornerCabinetLHF.fillerLHF.outerFillerCornerLHF1.mesh.position.z -= shift;
        this.parts.cornerCabinetLHF.panelFrontLHF.mesh.position.z -= shift;
    }

    resizeSkirtingLHFInDepth(dimensions) {
        const DOOR_DEPTH = 0.024;
        const LHF3_DIFFERENCE_FROM_STANDARD = 0.12;
        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);
        const shift = (dimensions.depth - DOOR_DEPTH - panelBottomDimensions.depth) / 2;

        const skirtingLHF3Dimensions = Utilities.getMeshDimensions(this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF3.mesh);
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF1.mesh.position.z -= shift;
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF5.mesh.position.z -= shift;
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF2.mesh.position.z -= shift;
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF4.mesh.position.z -= shift;

        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF3.mesh.scaling.z =
            (dimensions.depth - LHF3_DIFFERENCE_FROM_STANDARD) / skirtingLHF3Dimensions.depth;

        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF3.mesh.position.z -=
            (dimensions.depth - LHF3_DIFFERENCE_FROM_STANDARD - skirtingLHF3Dimensions.depth) / 2 - shift;

        this.parts.cornerCabinetLHF.islandLHF.skirtingIslandLHF.mesh.position.z += shift + DOOR_DEPTH / 2;
    }

    resizeCountertopLHFInDepth(dimensions) {
        const COUNTERTOP_FORWARD_EXTENSION = 0.018;
        const countertopLHF1Dimensions = Utilities.getMeshDimensions(
            this.parts.cornerCabinetLHF.countertopLHF.countertopLHF1.mesh
        );
        const shift = (dimensions.depth + COUNTERTOP_FORWARD_EXTENSION - countertopLHF1Dimensions.depth) / 2;
        Utilities.resizeMeshInDepth(
            this.parts.cornerCabinetLHF.countertopLHF.countertopLHF1.mesh,
            dimensions.depth + COUNTERTOP_FORWARD_EXTENSION
        );

        Utilities.resizeMeshInDepth(
            this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF2.mesh,
            dimensions.depth + COUNTERTOP_FORWARD_EXTENSION
        );

        this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF1.mesh.position.z += shift;
    }

    resizeHeight(height) {
        this.resizeCabinetBlockInHeight(height);
        this.resizeSkirtingBlockInHeight(height);
        this.resizeCounterTopBlockInHeight(height);
    }

    resizeCabinetBlockInHeight(height) {
        const SKIRTING_AND_BASE_COUNTERTOP_HEIGHT = 0.27;
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelBack.mesh, height - 0.376);
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelLeft.mesh, height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT);
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelRight.mesh, height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT);
        Utilities.resizeMeshInHeight(this.parts.cornerCabinetLHF.doorLHF.mesh, height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT);
        Utilities.resizeMeshInHeight(
            this.parts.cornerCabinetLHF.panelFrontLHF.mesh,
            height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT
        );
        Utilities.resizeMeshInHeight(
            this.parts.cornerCabinetLHF.islandLHF.panelIslandLHF.mesh,
            height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT
        );
        Utilities.resizeMeshInHeight(
            this.parts.cornerCabinetLHF.fillerLHF.outerFillerCornerLHF1.mesh,
            height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT
        );

        const door1Dimensions = Utilities.getMeshDimensions(this.parts.cornerCabinetLHF.doorLHF.mesh);
        this.parts.cabinet.panelTopBack.mesh.position.y +=
            (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;
        this.parts.cabinet.panelTopFront.mesh.position.y +=
            (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;
        this.parts.cabinet.panelBottom.mesh.position.y -=
            (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;
    }

    resizeSkirtingBlockInHeight(height) {
        const SKIRTING_AND_BASE_COUNTERTOP_HEIGHT = 0.27;
        const door1Dimensions = Utilities.getMeshDimensions(this.parts.cornerCabinetLHF.doorLHF.mesh);
        const shift = (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;

        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF1.mesh.position.y -= shift;
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF2.mesh.position.y -= shift;
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF3.mesh.position.y -= shift;
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF4.mesh.position.y -= shift;
        this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF5.mesh.position.y -= shift;
    }

    resizeCounterTopBlockInHeight(height) {
        const SKIRTING_AND_BASE_COUNTERTOP_HEIGHT = 0.27;

        const door1Dimensions = Utilities.getMeshDimensions(this.parts.cornerCabinetLHF.doorLHF.mesh);
        const shift = (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;

        this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF1.mesh.position.y += shift;
        this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF2.mesh.position.y += shift;
        this.parts.cornerCabinetLHF.countertopLHF.countertopLHF1.mesh.position.y += shift;
        this.parts.cornerCabinetLHF.islandLHF.countertopLHFIslandBack.mesh.position.y += shift;
    }
}
