import { Color3, MeshBuilder, Vector3, StandardMaterial } from '@babylonjs/core';
import { BaseMeasurementLine } from '../../../Components/Base/BaseMeasurementLine';
import { Constants } from '../../../Tools/constants';
import { Utilities } from '../../../Tools/utilities';
import { Island } from '../../Cabinets/Island';
import { RoomObject } from '../../Room/RoomObject';
import { CornerCabinet } from '../CornerCabinet';

export class MeasurementLine extends BaseMeasurementLine {
    constructor() {
        super();
    }

    build() {
        let lineCoordinates;
        const slots = this.getMeasurementLinesSlots();
        lineCoordinates = this.calculateLineSystemCoordinates(slots);

        if (this.linkedMeshes.length === 0) {
            this.buildLinkedMesh(lineCoordinates);
        }
        this.updateLength(lineCoordinates);

        if (!this.lineSystem) {
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        } else {
            this.lineSystem.dispose();
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        }
        this.lineSystem.color = new Color3(0, 0, 0);
        this.mesh = this.lineSystem;
    }

    buildForPrint() {
        let lineCoordinates;
        const slots = this.getMeasurementLinesSlots();
        lineCoordinates = this.calculateLineSystemCoordinatesForPrint(slots);

        if (this.linkedMeshes.length === 0) {
            this.buildLinkedMesh(lineCoordinates);
        }
        if (this.object.parentCabinet.type === Constants.cabinet.type.WALL) {
            this.updateLength(lineCoordinates);
        } else {
            this.updateLength(lineCoordinates, null, 'bottom');
        }

        if (!this.lineSystem) {
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        } else {
            this.lineSystem.dispose();
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        }
        this.lineSystem.color = new Color3(0, 0, 0);
        this.mesh = this.lineSystem;
    }

    getMeasurementLinesSlots() {
        let blockedSegmentOnWall;
        let assignedWall = this.object.assignedWall;
        let direction;
        if (this.object.parentCabinet instanceof Island) {
            const edges = this.object.parentCabinet.getEdges(this.object.assignedRow);
            if (this.object.parentCabinet.peninsulaData.isPeninsula) {
                const projectedEdge = Utilities.projectPointOnWall(
                    edges.leftPoint,
                    this.object.parentCabinet.peninsulaData.connectedCabinet.assignedWall
                );
                projectedEdge.y += edges.leftPoint.y;
                if (Vector3.Distance(edges.leftPoint, projectedEdge) < Vector3.Distance(edges.rightPoint, projectedEdge)) {
                    assignedWall = { baseInnerCornerA: edges.rightPoint, baseInnerCornerB: projectedEdge };
                } else {
                    assignedWall = { baseInnerCornerA: projectedEdge, baseInnerCornerB: edges.leftPoint };
                }
            } else {
                assignedWall = { baseInnerCornerA: edges.rightPoint, baseInnerCornerB: edges.leftPoint };
            }
            direction = assignedWall.baseInnerCornerA.subtract(assignedWall.baseInnerCornerB);
            direction.normalize();

            let projectedPoint = Utilities.getPointOnLine({
                cornerA: assignedWall.baseInnerCornerA.clone(),
                cornerB: assignedWall.baseInnerCornerB.clone(),
                point: this.object.meshComponent.getMesh().position
                    ? this.object.meshComponent.getMesh().position.clone()
                    : this.object.meshComponent.getWorldPosition(),
            });
            projectedPoint.y = 0;
            blockedSegmentOnWall = {
                firstPoint: projectedPoint.clone().addInPlace(direction.clone().scaleInPlace(this.object.width / 2)),
                secondPoint: projectedPoint.clone().addInPlace(direction.clone().scaleInPlace(-this.object.width / 2)),
            };
        } else {
            blockedSegmentOnWall = this.object.getEdgesOnWall();
            direction = this.object.assignedWall.getDirection();
        }
        let firstPoint = blockedSegmentOnWall.firstPoint.clone();
        let secondPoint = blockedSegmentOnWall.secondPoint.clone();

        const firstPointVertical = assignedWall.baseInnerCornerB;
        if (this.object.parentCabinet instanceof Island) {
            firstPointVertical.y = 0
        }
        let secondPointVertical = firstPointVertical.clone();
        secondPointVertical.y +=  this.object.parentCabinet.height;

        let slots = [
            {
                firstPoint: firstPoint,
                secondPoint: secondPoint,
                fixture: this.object,
            },
            {
                firstPoint: firstPointVertical,
                secondPoint: secondPointVertical,
                fixture: this.object,
                orientation: 'vertical'
            },
        ];
        if (this.object instanceof CornerCabinet) {
            let startingPoint = secondPoint;
            direction = this.object.assignedWall.getDirection();
            let endingPoint = firstPoint;
            if (this.object.cornerIndex === Constants.corner.CORNER_A) {
                startingPoint = firstPoint;
                direction = direction.negate();
                endingPoint = secondPoint;
            }

            let measurementLineWidth = 0;
            // console.log(
            //     'cabinet ',
            //     this.object.parentCabinet,
            //     '\ncorner cabinet info',
            //     this.object.parentCabinet.cornerCabinetInfo
            // );
            if (this.object.parentCabinet.cornerCabinetInfo?.cornerIndex === Constants.corner.CORNER_A) {
                measurementLineWidth = this.object.parentCabinet.connectedCabinet.depth;
                if (this.object.parentCabinet.type === Constants.cabinet.type.WALL) {
                    measurementLineWidth += this.object.parentCabinet.getCornerCabinetDoor();
                }
            } else if (this.object.parentCabinet.cornerCabinetInfo?.cornerIndex === Constants.corner.CORNER_B) {
                measurementLineWidth = this.object.parentCabinet.secondConnectedCabinet.depth;
                if (this.object.parentCabinet.type === Constants.cabinet.type.WALL) {
                    measurementLineWidth += this.object.parentCabinet.getCornerCabinetDoor();
                }
            }

            let secondSlotPoint = startingPoint.add(direction.clone().scale(measurementLineWidth));
            let thirdSlotPoint = secondSlotPoint.clone().add(direction.clone().scale(this.object.fillerSize));

            const roomObject = this.object.editor.getObjectByType(RoomObject);
            const roomCorners = roomObject.baseOuterCorners.concat(roomObject.baseOuterCorners[0]);
            let lengthOnOtherWall = this.object.depth;
            if (this.object.parentCabinet.type === Constants.cabinet.type.WALL) {
                lengthOnOtherWall += this.object.parentCabinet.getCornerCabinetDoor();
            }
            let pointOnOtherWall = Utilities.calculateShiftValue(
                startingPoint.clone(),
                -lengthOnOtherWall,
                roomCorners,
                this.object.assignedWall
            );

            slots = [
                {
                    firstPoint: startingPoint,
                    secondPoint: secondSlotPoint,
                    fixture: 'blockedArea',
                },
                {
                    firstPoint: secondSlotPoint,
                    secondPoint: thirdSlotPoint,
                    fixture: 'filler',
                },
                {
                    firstPoint: thirdSlotPoint,
                    secondPoint: endingPoint,
                    fixture: 'door',
                },
                {
                    firstPoint: startingPoint,
                    secondPoint: pointOnOtherWall,
                    fixture: 'blockedArea',
                },
            ];
        }
        return slots;
    }

    calculateLineSystemCoordinates(slots) {
        let lineCoordinates = [];
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        const roomCorners = roomObject.baseOuterCorners.concat(roomObject.baseOuterCorners[0]);
        let assignedWall;
        if (this.object.parentCabinet instanceof Island) {
            const edges = this.object.parentCabinet.getEdges(this.object.assignedRow);
            assignedWall = { baseInnerCornerA: edges.leftPoint.clone(), baseInnerCornerB: edges.rightPoint.clone() };
        } else {
            assignedWall = this.object.assignedWall;
        }

        for (let index = 0; index < slots.length; index++) {
            if (slots[index].orientation === 'vertical') continue;

            let firstPointClone = slots[index].firstPoint.clone();
            let secondPointClone = slots[index].secondPoint.clone();
            let firstPoint = slots[index].firstPoint.clone();
            let secondPoint = slots[index].secondPoint.clone();

            if (slots[index].fixture !== null) {
                if (this.object.parentCabinet instanceof Island) {
                    const result = this.getMeasurementLineShiftData(firstPointClone.clone(), secondPointClone.clone());
                    firstPointClone = result.firstPointClone;
                    secondPointClone = result.secondPointClone;
                } else {
                    firstPointClone = Utilities.calculateShiftValue(
                        firstPointClone,
                        -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                        roomCorners,
                        assignedWall
                    );
                    secondPointClone = Utilities.calculateShiftValue(
                        secondPointClone,
                        -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                        roomCorners,
                        assignedWall
                    );
                }

                if (this.object.parentCabinet.type === Constants.cabinet.type.WALL || this.object instanceof CornerCabinet) {
                    let heightFromFloor = this.object.parentCabinet.heightFromFloor
                        ? this.object.parentCabinet.heightFromFloor
                        : 0;

                    firstPoint.y += heightFromFloor + this.object.height;
                    secondPoint.y += heightFromFloor + this.object.height;
                    firstPointClone = firstPoint.clone();
                    secondPointClone = secondPoint.clone();
                    firstPointClone.y += 0.2;
                    secondPointClone.y += 0.2;
                } else {
                    firstPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                    secondPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                    firstPoint.y += Constants.cabinet.measurementLine.MARGIN;
                    secondPoint.y += Constants.cabinet.measurementLine.MARGIN;
                }
            } else {
                if (this.object.parentCabinet instanceof Island) {
                    const result = this.getMeasurementLineShiftData(firstPointClone.clone(), secondPointClone.clone());
                    firstPointClone = result.firstPointClone;
                    secondPointClone = result.secondPointClone;
                } else {
                    firstPointClone = Utilities.calculateShiftValue(
                        firstPointClone,
                        -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                        roomCorners,
                        assignedWall
                    );
                    secondPointClone = Utilities.calculateShiftValue(
                        secondPointClone,
                        -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                        roomCorners,
                        assignedWall
                    );
                    if (this.object.parentCabinet.type === Constants.cabinet.type.WALL || this.object instanceof CornerCabinet) {
                        let heightFromFloor = this.object.parentCabinet.heightFromFloor
                            ? this.object.parentCabinet.heightFromFloor
                            : 0;

                        firstPoint.y += heightFromFloor + this.object.height;
                        secondPoint.y += heightFromFloor + this.object.height;

                        firstPointClone = firstPoint.clone();
                        secondPointClone = secondPoint.clone();
                        firstPointClone.y += 0.2;
                        secondPointClone.y += 0.2;
                    } else {
                        firstPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                        secondPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                        firstPoint.y += Constants.cabinet.measurementLine.MARGIN;
                        secondPoint.y += Constants.cabinet.measurementLine.MARGIN;
                    }
                }
            }

            lineCoordinates.push(
                [firstPointClone, secondPointClone],
                [firstPoint, firstPointClone],
                [secondPoint, secondPointClone]
            );
        }
        return lineCoordinates;
    }

    calculateLineSystemCoordinatesForPrint(slots) {
        let lineCoordinates = [];
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        const roomCorners = roomObject.baseOuterCorners.concat(roomObject.baseOuterCorners[0]);
        let assignedWall;
        if (this.object.parentCabinet instanceof Island) {
            const edges = this.object.parentCabinet.getEdges(this.object.assignedRow);
            assignedWall = { baseInnerCornerA: edges.leftPoint.clone(), baseInnerCornerB: edges.rightPoint.clone() };
        } else {
            assignedWall = this.object.assignedWall;
        }

        for (let index = 0; index < slots.length; index++) {
            let firstPointClone = slots[index].firstPoint.clone();
            firstPointClone = Utilities.calculateShiftValue(firstPointClone.clone(), -0.2, roomCorners, assignedWall);

            let secondPointClone = slots[index].secondPoint.clone();
            secondPointClone = Utilities.calculateShiftValue(secondPointClone.clone(), -0.2, roomCorners, assignedWall);

            let firstPoint = slots[index].firstPoint.clone();
            firstPoint = Utilities.calculateShiftValue(firstPoint.clone(), -0.2, roomCorners, assignedWall);

            let secondPoint = slots[index].secondPoint.clone();
            secondPoint = Utilities.calculateShiftValue(secondPoint.clone(), -0.2, roomCorners, assignedWall);

            if (slots[index].orientation !== 'vertical') {
                if (this.object.parentCabinet.type === Constants.cabinet.type.WALL) {
                    let heightFromFloor = this.object.parentCabinet.heightFromFloor ? this.object.parentCabinet.heightFromFloor : 0;
    
                    firstPoint.y += heightFromFloor + this.object.height;
                    secondPoint.y += heightFromFloor + this.object.height;
    
                    firstPointClone = firstPoint.clone();
                    secondPointClone = secondPoint.clone();
                    firstPointClone.y += 0.2;
                    secondPointClone.y += 0.2;
                } else {
                    firstPointClone.y -= 0.15;
                    secondPointClone.y -= 0.15;
                }
                if (this.object instanceof CornerCabinet && index === slots.length - 1) {
                    continue;
                }
            } else {
                let shift;
                let wallDirection;
                let wallNormal;
                if (this.object.parentCabinet.type === Constants.cabinet.type.WALL) {
                    let heightFromFloor = this.object.parentCabinet.heightFromFloor ? this.object.parentCabinet.heightFromFloor : 0;
    
                    firstPoint.y += heightFromFloor;
                    secondPoint.y += heightFromFloor;
    
                    firstPointClone = firstPoint.clone();
                    secondPointClone = secondPoint.clone();

                    shift = 0.2;
                    wallDirection = assignedWall.baseInnerCornerB.clone().subtract(assignedWall.baseInnerCornerA);
                    wallNormal = wallDirection.normalize();
                } else if (this.object.parentCabinet instanceof Island) {
                    shift = 0.2;
                    wallDirection = assignedWall.baseInnerCornerA.clone().subtract(assignedWall.baseInnerCornerB);
                    wallNormal = wallDirection.normalize();
                } else if (this.object.parentCabinet.type === 'tall') {
                    shift = 0.45;
                    wallDirection = assignedWall.baseInnerCornerB.clone().subtract(assignedWall.baseInnerCornerA);
                    wallNormal = wallDirection.normalize();
                } else {
                    shift = 0.2;
                    wallDirection = assignedWall.baseInnerCornerB.clone().subtract(assignedWall.baseInnerCornerA);
                    wallNormal = wallDirection.normalize();
                }
                wallNormal = wallNormal.scale(shift);
                firstPointClone = firstPointClone.add(wallNormal);
                secondPointClone = secondPointClone.add(wallNormal);
            }

            lineCoordinates.push(
                [firstPointClone, secondPointClone],
                [firstPoint, firstPointClone],
                [secondPoint, secondPointClone]
            );
        }
        return lineCoordinates;
    }
}
