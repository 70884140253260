var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    {
      staticClass: "d-flex flex-column justify-center flex-shrink-1 height",
      attrs: { cols: "12", sm: "4" },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              !_vm.isStandardDomain
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text mb-1 mx-2",
                      attrs: {
                        color: "green",
                        small: _vm.$vuetify.breakpoint.mdAndDown,
                      },
                      on: {
                        click: function ($event) {
                          _vm.$route.params.projectId
                            ? _vm.toggleUpdateDialog(true)
                            : _vm.toggleDialog(true)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$route.params.projectId
                              ? "Save Project"
                              : "Quote"
                          ) +
                          " "
                      ),
                      !_vm.$route.params.projectId
                        ? _c("v-icon", [
                            _vm._v(" " + _vm._s(_vm.mdiCurrencyUsd) + " "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "white--text mx-2",
                  attrs: {
                    color: "primary",
                    small: _vm.$vuetify.breakpoint.mdAndDown,
                    disabled: _vm.disablePrint,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.printDesign()
                    },
                  },
                },
                [
                  _vm._v(" Print "),
                  _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiFilePdfBox) + " ")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isStandardDomain && _vm.subscription?.product === "free"
            ? _c("v-row", { attrs: { justify: "center" } }, [
                _c("p", { staticClass: "text-caption" }, [
                  _vm._v(
                    "Downloaded " +
                      _vm._s(_vm.downloadCount) +
                      " / 5 (Upgrade for more)"
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "text-center mt-2" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.mdAndDown },
                  on: {
                    click: function ($event) {
                      return _vm.setStep(3)
                    },
                  },
                },
                [_vm._v(" Previous ")]
              ),
              _c("resetBtn"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }