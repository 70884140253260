import { Color3, MeshBuilder, Vector3 } from '@babylonjs/core';
import { MeshComponent } from '../Components/MeshComponent';
import { Utilities } from '../Tools/utilities';
import { Constants } from '../Tools/constants';
import { RoomObject } from './Room/RoomObject';
import { BaseObject } from './BaseObject';

export class BaseKitchenObject extends BaseObject {
    width;
    height;
    depth;
    heightFromFloor;

    dimensions = {
        width: null,
        height: null,
        depth: null,
    };

    position;

    assignedWall;
    // type;

    id;
    name;
    type;

    isInCorner;
    meshComponent;

    constructor() {
        super();
        this.meshComponent = this.registerComponent(MeshComponent);
    }

    setDimensions(dimensions) {
        this.width = dimensions.width ? dimensions.width : null;
        this.height = dimensions.height ? dimensions.height : null;
        this.depth = dimensions.depth ? dimensions.depth : null;

        if (this.editor.unit === 'in') {
            this.dimensions = Utilities.convertToInches(dimensions);
        } else {
            this.dimensions = Utilities.convertFromMMToBJSUnit(dimensions);
        }
    }

    setId(id) {
        this.id = id;
    }

    setName(name) {
        this.name = name;
    }

    setType(type) {
        this.type = type;
    }

    setPosition(position) {
        this.position = position.clone();
    }

    rotate(angle) {
        this.meshComponent.mesh.rotation.y = -1 * angle;
        let direction = this.meshComponent.mesh.getDirection(Vector3.Backward());
        this.meshComponent.mesh.translate(direction, this.depth / 2);
    }

    getAssignedWall(walls) {
        let minDistance = Number.MAX_VALUE;
        let closestWallId = -1;

        for (let i = 0; i < walls.length; i++) {
            let projectedPoint = Utilities.getPointOnLine({
                cornerA: walls[i].baseInnerCornerA,
                cornerB: walls[i].baseInnerCornerB,
                point: this.position,
                shouldCalculateWallWidth: false,
                width: 0,
            });

            const distance = Vector3.Distance(this.position, projectedPoint);
            if (distance < minDistance) {
                minDistance = distance;
                closestWallId = i;
            }
        }
        return closestWallId;
    }

    shift(shift = this.depth / 2) {
        const roomObject = this.editor.getObjectByType(RoomObject);
        const roomCorners = roomObject.baseOuterCorners.concat(roomObject.baseOuterCorners[0]);
        if (this.meshComponent.getMesh().name !== 'fixedColumn') {
            if (this.assignedWall.findWallOrientation() === Constants.wall.orientation.HORIZONTAL) {
                this.meshComponent.getMesh().position = Utilities.calculateShiftValue(
                    this.meshComponent.getMesh().position,
                    shift,
                    roomCorners,
                    this.assignedWall
                );
            } else {
                this.meshComponent.getMesh().position = Utilities.calculateShiftValue(
                    this.meshComponent.getMesh().position,
                    shift,
                    roomCorners,
                    this.assignedWall
                );
            }
        }
    }

    getEdgesOnWall(sideMargin = 0) {
        //first point is the one closer to corner A of wall
        //second point is the one closer to corner B of wall
        if (this.assignedWall) {
            let projectedFixturePosition = Utilities.projectPointOnWall(this.meshComponent.getMesh().position, this.assignedWall);
            let direction = this.assignedWall.getDirection();

            const firstPoint = projectedFixturePosition.clone();
            const secondPoint = projectedFixturePosition.clone();

            const points = {
                firstPoint: firstPoint.addInPlace(direction.clone().scaleInPlace(this.width / 2 + sideMargin)),
                secondPoint: secondPoint.addInPlace(direction.clone().scaleInPlace(-this.width / 2 - sideMargin)),
            };

            return points;
        }
    }

    buildMesh() {
        let subtractionMesh = this.height;
        if (this.type === Constants.fixture.type.WINDOW) {
            subtractionMesh += 2 * Constants.models3D.window.SILL_HEIGHT;
        }
        this.meshComponent.mesh = MeshBuilder.CreateBox(
            'mesh',
            { width: this.width, height: subtractionMesh, depth: this.depth, updatable: true },
            this.editor.sceneComponent.get()
        );
    }

    positionMesh() {
        this.meshComponent.mesh.position = this.position;
        this.meshComponent.mesh.position.y += this.height / 2;
        this.position = this.meshComponent.mesh.position;
    }

    getPositionInfo() {
        this.isInCorner = this.getPositionInfoOnSegment({
            firstPoint: this.assignedWall?.baseInnerCornerA,
            secondPoint: this.assignedWall?.baseInnerCornerB,
        });
        return this.isInCorner;
    }

    getPositionInfoOnSegment(segment) {
        let meshPosition = this.meshComponent.mesh.position.clone();
        meshPosition = Utilities.getPointOnLine({
            cornerA: segment.firstPoint,
            cornerB: segment.secondPoint,
            point: meshPosition.clone(),
        });

        const distanceFromCornerA = Vector3.Distance(meshPosition, segment.firstPoint) - this.width / 2;
        const distanceFromCornerB = Vector3.Distance(meshPosition, segment.secondPoint) - this.width / 2;

        const MARGIN = 0.06;
        let positionInfo = { cornerA: null, cornerB: null };
        if (distanceFromCornerA < MARGIN) {
            positionInfo = { cornerA: segment.firstPoint, cornerB: null };
            if (distanceFromCornerB < MARGIN) {
                positionInfo = { cornerA: segment.firstPoint, cornerB: segment.secondPoint };
            }
        } else if (distanceFromCornerB < MARGIN) {
            positionInfo = { cornerA: null, cornerB: segment.secondPoint };
        }
        return positionInfo;
    }
}
