import { Vector3 } from '@babylonjs/core';
import { Component } from '../../../Components/Base/Component';
import { Utilities } from '../../../Tools/utilities';
import { RoomObject } from '../../Room/RoomObject';
import store from '@/store/index.js';
import { Constants } from '../../../Tools/constants';
import { BaseUnit } from '../../Cabinets/BaseUnit';
import { Island } from '../../Cabinets/Island';
import { BaseAppliance } from '../BaseAppliance';

export class MovementManager extends Component {
    constructor() {
        super();
    }

    followCursor() {
        this.object.editor.selectedItem = this.object;
        store.commit('appliances/setSelectedAppliance', this.object);
        this.object.availableSlots = this.object.editor.getCabinetSlots();
    }

    handleMovement(pick, onWallsAlso) {
        if (store.state.core.step !== 4) {
            let appliance = this.object;
            let applianceCloneData = appliance.createApplianceClone();
            applianceCloneData.movementManager.move(pick, onWallsAlso);

            if (
                !applianceCloneData.obstacleManager.checkForObstacles() &&
                !applianceCloneData.obstacleManager.checkForApplianceObstacles()
            ) {
                this.move(pick, onWallsAlso);
                if (this.object.currentCabinet && this.object.currentCabinet.sectionsInformation) {
                    this.object.currentCabinet.sectionsInformation = [];
                }
            }
            applianceCloneData.meshComponent.mesh.dispose();
            applianceCloneData.measurementLine.dispose();
        }
    }

    slideOnSegment(pickedPoint, currentCabinetBlockedSegment) {
        const projectedPick = Utilities.getPointOnLine({
            cornerA: currentCabinetBlockedSegment.firstPoint,
            cornerB: currentCabinetBlockedSegment.secondPoint,
            point: pickedPoint.clone(),
            shouldCalculateWallWidth: true,
            width: this.object.width,
        });

        this.object.position.x = projectedPick.x;
        this.object.position.z = projectedPick.z;
        let currentCabinetHeight = this.object.height;
        if (this.object.currentCabinet) {
            currentCabinetHeight = this.object.currentCabinet.height;
        }
        this.object.position.y = this.object.height / 2 + (currentCabinetHeight - this.object.height) + Constants.error.MARGIN;
        this.object.meshComponent.getMesh().position = this.object.position;

        if (this.object.currentCabinet?.type === Constants.cabinet.type.ISLAND) {
            this.object.shiftOnIsland(pickedPoint);
            this.object.meshComponent.getMesh().rotation.y = (this.object.rotation * Math.PI) / 180;
            if (this.object.type === Constants.appliance.type.HOOD) {
                this.object.meshComponent.getMesh().rotation.y = -(this.object.rotation * Math.PI) / 180;
            }
        } else {
            const shiftOutwards = 0.04;
            this.object.rotateAppliance();
            this.object.shift(-(this.object.depth / 2 + shiftOutwards));
        }
        if ((this.object.type === Constants.appliance.type.COOKTOP || this.object.subtype === 'range') && this.object.hasHood) {
            const cooktopPosition = this.object.meshComponent.getPosition().clone();
            this.object.hasHood.meshComponent.setPosition(
                new Vector3(cooktopPosition.x, this.object.hasHood.meshComponent.getPosition().y, cooktopPosition.z)
            );
            this.object.hasHood.position = new Vector3(
                cooktopPosition.x,
                this.object.hasHood.meshComponent.getPosition().y,
                cooktopPosition.z
            );
            this.object.hasHood.assignedRow = this.object.assignedRow;
            this.object.hasHood.assignedWall = this.object.assignedWall;
            this.object.hasHood.meshComponent.getMesh().rotation.y = -this.object.meshComponent.getMesh().rotation.y;
            this.object.hasHood.setCurrentCabinet(this.object);

            if (this.object.hasHood?.currentCabinet?.sectionsInformation) {
                this.object.hasHood.currentCabinet.sectionsInformation = [];
            }
        }
    }

    move(pick, onWallsAlso = false) {
        if (
            (pick.pickedMesh?.type === Constants.cabinet.type.ISLAND ||
                (this.object.currentCabinet?.type === Constants.cabinet.type.ISLAND && pick.pickedMesh?.type !== 'cabinet')) &&
            this.object.type !== 'refrigerator'
        ) {
            this.moveOnIsland(pick);
        } else {
            this.moveOnWalls(pick, onWallsAlso);
        }
    }

    moveOnIsland(pick) {
        let currentIsland;
        if (this.object.currentCabinet?.type !== Constants.cabinet.type.ISLAND) {
            for (let index = 0; index < this.object.editor.cabinets.length; index++) {
                if (pick.pickedMesh.id === this.object.editor.cabinets[index].id) {
                    currentIsland = this.object.editor.cabinets[index];
                }
            }
        } else {
            currentIsland = this.object.currentCabinet;
        }

        this.object.currentCabinet = currentIsland;
        let projectedPickFirstRow = new Vector3(9999, 9999, 9999);
        let projectedPickSecondRow = new Vector3(9999, 9999, 9999);

        currentIsland.setInnerPeninsulaRow();
        let firstRowEdges = currentIsland.getEdges('firstRow');
        let secondRowEdges = currentIsland.getEdges('secondRow');

        if (currentIsland.rowDepths.firstRow !== 0) {
            projectedPickFirstRow = Utilities.getPointOnLine({
                cornerA: firstRowEdges.leftPoint.clone(),
                cornerB: firstRowEdges.rightPoint.clone(),
                point: pick.pickedPoint.clone(),
            });
        }

        if (currentIsland.rowDepths.secondRow !== 0) {
            projectedPickSecondRow = Utilities.getPointOnLine({
                cornerA: secondRowEdges.leftPoint.clone(),
                cornerB: secondRowEdges.rightPoint.clone(),
                point: pick.pickedPoint.clone(),
            });
        }

        if (
            Vector3.Distance(projectedPickFirstRow, pick.pickedPoint) < Vector3.Distance(projectedPickSecondRow, pick.pickedPoint)
        ) {
            //if appliance is closer to first row
            const secondRowAppliances = currentIsland.getAppliancesOnRow('secondRow');
            //if first row is too small to fit appliance switch row depths
            if (
                currentIsland.rowDepths.firstRow < this.object.depth &&
                currentIsland.rowDepths.secondRow !== 0 &&
                !currentIsland.peninsulaData.isPeninsula &&
                secondRowAppliances.length <= 1 &&
                secondRowAppliances[0]?.id === this.object.id
            ) {
                currentIsland.switchIslandRowDepths(currentIsland);
                firstRowEdges = currentIsland.getEdges('firstRow');
            }

            if (currentIsland.rowDepths.firstRow >= this.object.depth) {
                if (this.object.type === Constants.appliance.type.HOOD) {
                    this.moveOnCooktop(pick);
                } else {
                    if (currentIsland.rotation === 0) {
                        this.object.meshComponent.getMesh().rotation.y = 0;
                        if (this.object.type === Constants.appliance.type.HOOD) {
                            this.object.meshComponent.getMesh().rotation.y = Math.PI;
                        }
                    } else if (currentIsland.rotation === 90) {
                        this.object.meshComponent.getMesh().rotation.y = -Math.PI / 2;
                        if (this.object.type === Constants.appliance.type.HOOD) {
                            this.object.meshComponent.getMesh().rotation.y = Math.PI / 2;
                        }
                    }
                    this.object.assignedRow = 'firstRow';
                    this.slideOnSegment(pick.pickedPoint, {
                        firstPoint: firstRowEdges.leftPoint,
                        secondPoint: firstRowEdges.rightPoint,
                    });
                }
            }
        } else {
            //if appliance is closer to secondRow
            const firstRowAppliances = currentIsland.getAppliancesOnRow('firstRow');
            //if second row is too small to fit appliance switch row depths
            if (
                currentIsland.rowDepths.secondRow < this.object.depth &&
                currentIsland.rowDepths.firstRow !== 0 &&
                !currentIsland.peninsulaData.isPeninsula &&
                firstRowAppliances.length <= 1 &&
                firstRowAppliances[0]?.id === this.object.id
            ) {
                currentIsland.switchIslandRowDepths();
                secondRowEdges = currentIsland.getEdges('secondRow');
            }
            if (currentIsland.rowDepths.secondRow >= this.object.depth) {
                if (this.object.type === Constants.appliance.type.HOOD) {
                    this.moveOnCooktop(pick);
                } else {
                    if (currentIsland.rotation === 0) {
                        this.object.meshComponent.getMesh().rotation.y = Math.PI;
                        if (this.object.type === Constants.appliance.type.HOOD) {
                            this.object.meshComponent.getMesh().rotation.y = 0;
                        }
                    } else if (currentIsland.rotation === 90) {
                        this.object.meshComponent.getMesh().rotation.y = Math.PI / 2;
                        if (this.object.type === Constants.appliance.type.HOOD) {
                            this.object.meshComponent.getMesh().rotation.y = -Math.PI / 2;
                        }
                    }
                    this.object.assignedRow = 'secondRow';
                    this.slideOnSegment(pick.pickedPoint, {
                        firstPoint: secondRowEdges.leftPoint,
                        secondPoint: secondRowEdges.rightPoint,
                    });
                }
            }
        }
        this.object.measurementLine.update();
    }

    getCooktopSlotsOnIsland() {
        const appliancesOnIsland = this.object.currentCabinet.getAppliances();
        const currentRowEdges = this.object.currentCabinet.getEdges(this.object.assignedRow);
        const rowDirection = currentRowEdges.leftPoint.clone().add(currentRowEdges.rightPoint.clone());
        rowDirection.normalize();

        let applianceSlots = [];
        for (let index = 0; index < appliancesOnIsland.length; index++) {
            if (appliancesOnIsland[index].type !== Constants.appliance.type.HOOD) {
                const projectedPoint = Utilities.getPointOnLine({
                    cornerA: currentRowEdges.leftPoint.clone(),
                    cornerB: currentRowEdges.rightPoint.clone(),
                    point: appliancesOnIsland[index].position.clone(),
                });
                const blockedSegmentOnRow = {
                    firstPoint: projectedPoint.clone().add(rowDirection.scale(this.object.width / 2)),
                    secondPoint: projectedPoint.clone().add(rowDirection.scale(-this.object.width / 2)),
                    appliance: appliancesOnIsland[index],
                };

                applianceSlots.push(blockedSegmentOnRow);
            }
        }
        return applianceSlots;
    }

    moveOnCooktop(pick) {
        const cooktopSlots = this.getCooktopSlotsOnIsland();
        for (let index = 0; index < cooktopSlots.length; index++) {
            const projectedPoint = Utilities.getPointOnLine({
                cornerA: cooktopSlots[index].firstPoint.clone(),
                cornerB: cooktopSlots[index].secondPoint.clone(),
                point: pick.pickedPoint,
            });
            if (
                Utilities.isPointBetween(
                    cooktopSlots[index].firstPoint.clone(),
                    cooktopSlots[index].secondPoint.clone(),
                    projectedPoint
                )
            ) {
                if (!cooktopSlots[index].appliance.hasHood) {
                    if (this.object.currentCooktop) {
                        this.object.currentCooktop.hasHood = false;
                    }
                    this.object.currentCooktop = cooktopSlots[index].appliance;
                    this.object.assignedRow = cooktopSlots[index].assignedRow;
                    const cooktopPosition = cooktopSlots[index].appliance.position.clone();
                    this.object.meshComponent.getMesh().position = new Vector3(
                        cooktopPosition.x,
                        this.object.meshComponent.getMesh().position.y,
                        cooktopPosition.z
                    );
                    this.object.meshComponent.getMesh().rotation.y =
                        -this.object.currentCooktop.meshComponent.getMesh().rotation.y;

                    if (!this.object.isClone) {
                        cooktopSlots[index].appliance.hasHood = this.object;
                    }
                }
            }
        }
    }

    moveOnWalls(pick, onWallsAlso) {
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        let allowSliding = false;
        let currentCabinetBlockedSegment = null;
        let slotObject = null;
        let closestWall = Utilities.findClosestWall(pick.pickedPoint, roomObject.walls, 0.3);
        if (!closestWall) {
            if (this.object.assignedWall) {
                closestWall = this.object.assignedWall;
            } else {
                return;
            }
        } else if (this.object.type !== Constants.appliance.type.HOOD) {
            this.object.assignedWall = closestWall;
        }

        const projectedPick = Utilities.projectPointOnWall(pick.pickedPoint, closestWall);
        let typesOfCabinetsToConsider = ['base', 'tall'];
        if (this.object.type === Constants.appliance.type.HOOD) {
            typesOfCabinetsToConsider = ['appliance'];
        }

        roomObject.slotManager.getAllSlots(typesOfCabinetsToConsider);
        const slots = roomObject.slotManager.slots['wall' + closestWall.id];

        for (let index = 0; index < slots.length; index++) {
            currentCabinetBlockedSegment = { firstPoint: slots[index].firstPoint, secondPoint: slots[index].secondPoint };
            slotObject = slots[index].object;

            if (
                Utilities.isPointBetween(
                    currentCabinetBlockedSegment.firstPoint,
                    currentCabinetBlockedSegment.secondPoint,
                    projectedPick
                )
            ) {
                if (slotObject instanceof BaseUnit || slotObject instanceof Island) {
                    if (this.isCabinetAllowed(slotObject)) {
                        allowSliding = true;
                        this.object.currentCabinet = slotObject;
                        break;
                    }
                } else if (
                    slotObject instanceof BaseAppliance &&
                    (Constants.appliance.type.COOKTOP === slotObject?.type || slotObject?.subtype === 'range') &&
                    this.object.type === Constants.appliance.type.HOOD &&
                    !slotObject.hasHood
                ) {
                    if (this.object.currentCooktop) {
                        this.object.currentCooktop.hasHood = false;
                    }
                    allowSliding = true;
                    this.object.currentCooktop = slotObject;
                    if (!this.object.isClone) {
                        this.object.setCurrentCabinet(this.object.currentCooktop);
                        slotObject.hasHood = this.object;
                    }
                } else if (
                    onWallsAlso &&
                    slotObject !== 'connectedCabinet' &&
                    this.object.type !== 'hood' &&
                    Vector3.Distance(currentCabinetBlockedSegment.firstPoint, currentCabinetBlockedSegment.secondPoint) >
                        Constants.error.MARGIN
                ) {
                    this.object.currentCabinet = null;
                    allowSliding = true;
                    break;
                }
            }
        }
        if (this.object.currentCooktop) {
            currentCabinetBlockedSegment = this.object.currentCooktop.getEdgesOnWall();
        } else if (this.object.currentCabinet) {
            currentCabinetBlockedSegment = this.object.currentCabinet.calculateAllowedSegment();
        }
        if (allowSliding) {
            if (!this.object.assignedWall) {
                this.object.assignedWall = closestWall;
            }
            this.slideOnSegment(pick.pickedPoint, currentCabinetBlockedSegment);
            if (this.object.currentCooktop) {
                this.object.position = this.object.currentCooktop.meshComponent.getMesh().position.clone();
                this.object.position.y = this.object.height / 2 + Constants.hood.HEIGHT_FROM_FLOOR;
                this.object.meshComponent.getMesh().position = this.object.position;
            } else if (this.object.currentCabinet?.type === 'tall' && this.object.type === 'oven') {
                this.object.meshComponent.getMesh().position.y = 0.9 + this.object.height / 2;
                this.object.position.y = 0.9;
            } else if (this.object.type === Constants.appliance.type.REFRIGERATOR) {
                this.object.meshComponent.getPosition().y = this.object.height / 2;
                this.object.position.y = this.object.height / 2;
            } else {
                let heightFromFloor = 0;
                let ovenHeightDifference = 0;
                if (this.object.type === Constants.appliance.type.OVEN) {
                    ovenHeightDifference = Constants.oven.HEIGHT_MARGIN;
                }
                const currentCabinetHeight = this.object.currentCabinet ? this.object.currentCabinet.height : this.object.height;
                heightFromFloor = currentCabinetHeight - this.object.height + Constants.error.MARGIN - ovenHeightDifference;
                this.object.meshComponent.getMesh().position.y = this.object.height / 2 + heightFromFloor;
                this.object.position.y = this.object.height / 2 + heightFromFloor;
                if (this.object.type === Constants.appliance.type.COOKTOP) {
                    this.object.meshComponent.getMesh().position.y += 0.008;
                }
            }
        }

        this.object.measurementLine.update();
    }

    isCabinetAllowed(cabinet) {
        if (['dishwasher', 'cooktop', 'washer', 'sink'].includes(this.object.type) && ['base'].includes(cabinet.type)) {
            return true;
        } else if (this.object.type === 'hood') {
            return true;
        } else if (this.object.type === 'oven') {
            if (
                (this.object.subtype === 'range' && ['base'].includes(cabinet.type)) ||
                (this.object.format === 'builtin' && ['tall', 'base'].includes(cabinet.type))
            ) {
                return true;
            }
        } else if (this.object.type === 'refrigerator' && ['tall'].includes(cabinet.type)) {
            return true;
        }
    }
}
