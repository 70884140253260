import { StandardMaterial, Vector3, SceneLoader } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { BaseObject } from '../BaseObject';
import { SlotManager } from './RoomComponents/SlotManager';
import { Wall } from '../BuildingBlocks/Wall';

export class RoomObject extends BaseObject {
    positions;
    floor;

    constructor() {
        super();

        this.walls = [];
        this.cornerPositions = [];
        this.baseOuterCorners = [];
        this.slotManager = this.registerComponent(SlotManager);
    }

    setCornerPositions(cornerPositions) {
        this.cornerPositions = cornerPositions;
    }

    calculateInnerCorner(corner, previousCorner, nextCorner, ply) {
        let innerCorner = Vector3.Zero();
        const previousLine = Vector3.Zero();
        corner.subtractToRef(previousCorner, previousLine);

        const nextLine = Vector3.Zero();
        corner.subtractToRef(nextCorner, nextLine);

        const angle = Math.acos(Vector3.Dot(previousLine, nextLine) / (previousLine.length() * nextLine.length()));
        const direction = Vector3.Cross(previousLine, nextLine).normalize().y;
        const lineNormal = new Vector3(previousLine.z, 0, -1 * previousLine.x).normalize();
        previousLine.normalize();
        innerCorner = corner.add(lineNormal.scale(ply)).add(previousLine.scale((direction * ply) / Math.tan(angle / 2)));
        return innerCorner;
    }

    calculateOuterCornerPosition(corner, previousCorner, nextCorner) {
        const innerCorner = this.calculateInnerCorner(corner, previousCorner, nextCorner, Constants.room.dimensions.WIDTH);
        const directionVector = corner.subtract(innerCorner);
        const outerCorner = corner.add(directionVector);
        return outerCorner;
    }

    calculateOuterCornerPositions() {
        for (let index = 0; index < this.cornerPositions.length; index++) {
            if (index === this.cornerPositions.length - 1) {
                this.baseOuterCorners.push(
                    this.calculateOuterCornerPosition(
                        this.cornerPositions[index],
                        this.cornerPositions[index - 1],
                        this.cornerPositions[0]
                    )
                );
            } else if (index === 0) {
                this.baseOuterCorners.push(
                    this.calculateOuterCornerPosition(
                        this.cornerPositions[index],
                        this.cornerPositions[this.cornerPositions.length - 1],
                        this.cornerPositions[index + 1]
                    )
                );
            } else {
                this.baseOuterCorners.push(
                    this.calculateOuterCornerPosition(
                        this.cornerPositions[index],
                        this.cornerPositions[index - 1],
                        this.cornerPositions[index + 1]
                    )
                );
            }
        }
    }

    build() {
        if (!this.cornerPositions) {
            return;
        }
        this.calculateOuterCornerPositions();

        for (let i = 0; i < this.cornerPositions.length; i++) {
            this.walls[i] = this.editor.addObject(Wall);
            let index = 0;
            this.walls[i].setId(i);
            if (i !== this.cornerPositions.length - 1) {
                index = i + 1;
            }
            this.walls[i].buildMesh(
                this.cornerPositions[index],
                this.cornerPositions[i],
                this.baseOuterCorners[index],
                this.baseOuterCorners[i]
            );
        }
    }

    async setFixture(fixtureComponent) {
        let scene = this.editor.sceneComponent.get();
        let wallCSG;

        fixtureComponent.assignedWall = this.walls[fixtureComponent.getAssignedWall(this.walls)];
        wallCSG = fixtureComponent.assignedWall.meshComponent.getCSGFromMesh();

        let previousPosition = fixtureComponent.meshComponent.getMesh().position;
        fixtureComponent.shift();
        fixtureComponent.setPosition(fixtureComponent.meshComponent.getMesh().position);

        let fixtureCSG = fixtureComponent.meshComponent.getCSGFromMesh();

        if (wallCSG) {
            let resultCSG = wallCSG.subtract(fixtureCSG);
            fixtureComponent.assignedWall.replaceMesh(
                resultCSG.toMesh(
                    fixtureComponent.assignedWall.id,
                    new StandardMaterial('wall' + fixtureComponent.assignedWall.id + 'material', scene),
                    scene
                )
            );
        }

        let fileName;
        if (fixtureComponent.type === Constants.fixture.type.WINDOW) {
            fileName = 'window3DModel.babylon';
            previousPosition.y += Constants.models3D.window.SILL_HEIGHT;
        } else if (fixtureComponent.type === Constants.fixture.type.DOOR) {
            if (fixtureComponent.subType !== Constants.fixture.subType.BALCONY) {
                fileName = 'door3DModel.babylon';
            } else {
                fileName = 'balcony3DModel.babylon';
            }
        }

        if (
            fixtureComponent.type !== Constants.fixture.type.FIXED_COLUMN &&
            fixtureComponent.type !== Constants.fixture.type.OPEN_SPACE
        ) {
            const meshName = fixtureComponent.meshComponent.getMesh().name;
            fixtureComponent.meshComponent.getMesh().dispose();
            if (fileName) {
                let result = await SceneLoader.ImportMeshAsync('', fileName, '', scene);
                fixtureComponent.meshComponent.mesh = result.meshes[0];
                fixtureComponent.meshComponent.mesh.name = meshName;
            }

            fixtureComponent.resize();
        }

        fixtureComponent.meshComponent.getMesh().position = previousPosition;
        fixtureComponent.meshComponent.getMesh().type = 'fixture';
        fixtureComponent.position = previousPosition;
        fixtureComponent.rotate(fixtureComponent.assignedWall.rotationAngle);
    }

    getMaxWallLength() {
        let max = 0;
        for (const wall of this.walls) {
            if (wall.length > max) {
                max = wall.length + 0.30;
            }
        }
        return max;
    }
}
