import { Vector3 } from '@babylonjs/core';
import { Constants } from './constants';

export class Utilities {
    static getPointOnLine(parameters) {
        const { cornerA, cornerB, point, shouldCalculateWallWidth, width } = parameters;
        let direction = cornerA.subtract(cornerB);
        let magnitude = direction.length();

        direction = direction.normalize();
        //Do projection from the point but clamp it
        let vectorAP = point.subtract(cornerB);
        let dotProduct = Vector3.Dot(vectorAP, direction);

        if (shouldCalculateWallWidth) {
            dotProduct = Utilities.clamp(dotProduct, width / 2, magnitude - width / 2);
        } else {
            dotProduct = Utilities.clamp(dotProduct, 0, magnitude);
        }

        return cornerB.add(direction.scale(dotProduct));
    }

    static clamp(number, min, max) {
        return Math.min(Math.max(number, min), max);
    }

    static isPointInside(cabinetPosition, roomCorners) {
        let x = cabinetPosition.x,
            y = cabinetPosition.z;

        let inside = false;
        for (let i = 0, j = roomCorners.length - 1; i < roomCorners.length; j = i++) {
            let xi = roomCorners[i].x,
                yi = roomCorners[i].z;
            let xj = roomCorners[j].x,
                yj = roomCorners[j].z;

            let intersect = yi > y != yj >= y && x <= ((xj - xi) * (y - yi)) / (yj - yi) + xi;

            if (intersect) {
                inside = !inside;
            }
        }
        return inside;
    }

    static isPointBetween(pointA, pointB, pointToCheck) {
        return (
            Math.abs(
                Vector3.Distance(pointA, pointB) -
                    (Vector3.Distance(pointA, pointToCheck) + Vector3.Distance(pointToCheck, pointB))
            ) <= 0.00000000000001
        );
    }

    static calculateShiftValue(positionToShift, shiftAmount, roomCorners, assignedWall) {
        let oldPosition = positionToShift.clone();

        let wallDirection = assignedWall.baseInnerCornerB.clone().subtract(assignedWall.baseInnerCornerA);
        let wallNormal = new Vector3(wallDirection.z, 0, -1 * wallDirection.x).normalize();

        wallNormal = wallNormal.scale(shiftAmount);

        oldPosition.addInPlace(wallNormal);
        if (!Utilities.isPointInside(oldPosition, roomCorners)) {
            positionToShift.subtractInPlace(wallNormal);
        } else {
            positionToShift = oldPosition;
        }
        positionToShift = oldPosition;
        return positionToShift;
    }

    static findMiddlePointBetween(pointA, pointB) {
        let distance = Vector3.Distance(pointA, pointB);
        let direction = pointB.clone().subtract(pointA.clone());
        direction.normalize();
        direction.scaleInPlace(distance / 2);

        return pointA.add(direction);
    }

    static angleBetweenWalls(leftWall, rightWall, roomCorners) {
        const origin = leftWall.baseInnerCornerA;
        const firstPoint = leftWall.baseInnerCornerB;
        const secondPoint = rightWall.baseInnerCornerA;

        const firstVector = origin.subtract(firstPoint);
        const secondVector = origin.subtract(secondPoint);

        const dot = Vector3.Dot(firstVector, secondVector);
        const angle = Math.acos(dot / (firstVector.length() * secondVector.length()));

        const originProjection = Utilities.getPointOnLine({
            cornerA: firstPoint,
            cornerB: secondPoint,
            point: origin,
            shouldCalculateWallWidth: false,
            width: 0,
        });

        if (Utilities.isPointInside(originProjection, roomCorners)) {
            return (angle * 180) / Math.PI;
        } else {
            return 360 - (angle * 180) / Math.PI;
        }
    }

    static getClosestPoint(pickedPoint, pointA, pointB) {
        const distanceFromA = Vector3.Distance(pickedPoint, pointA);
        const distanceFromB = Vector3.Distance(pickedPoint, pointB);
        let closestPoint = pointA;
        let index = 'cornerA';
        if (distanceFromA > distanceFromB) {
            closestPoint = pointB;
            index = 'cornerB';
        }
        return { closestPoint: closestPoint, closestPointIndex: index };
    }

    static projectPointOnWall(pickedPoint, wall, shouldCalculateWallWidth = false, width = 0) {
        return Utilities.getPointOnLine({
            cornerA: wall.baseInnerCornerA.clone(),
            cornerB: wall.baseInnerCornerB.clone(),
            point: pickedPoint.clone(),
            shouldCalculateWallWidth: shouldCalculateWallWidth,
            width: width,
        });
    }

    static findDistanceFromWall(pickedPoint, wall) {
        const projectedPoint = Utilities.projectPointOnWall(pickedPoint, wall);
        return Vector3.Distance(pickedPoint, projectedPoint);
    }

    static convertToMillimeters(fixture) {
        return {
            width: (fixture.dimensions.width * Constants.unit.INCH_STEP) / Constants.MM_TO_BJS_COEFF,
            height: (fixture.dimensions.height * Constants.unit.INCH_STEP) / Constants.MM_TO_BJS_COEFF,
            heightFromFloor: (fixture.dimensions.heightFromFloor * Constants.unit.INCH_STEP) / Constants.MM_TO_BJS_COEFF,
            depth: (fixture.dimensions.depth * Constants.unit.INCH_STEP) / Constants.MM_TO_BJS_COEFF,
        };
    }

    static convertToInches(dimensions) {
        const precision = 10;
        let dimensionsInInches = {};
        if (dimensions.heightFromFloor) {
            dimensionsInInches.heightFromFloor =
                (dimensions.heightFromFloor * Constants.MM_TO_BJS_COEFF) / Constants.unit.INCH_STEP;
            dimensionsInInches.heightFromFloor = this.roundToPrecision(dimensionsInInches.heightFromFloor, precision);
        }
        if (dimensions.width) {
            dimensionsInInches.width = (dimensions.width * Constants.MM_TO_BJS_COEFF) / Constants.unit.INCH_STEP;
            dimensionsInInches.width = this.roundToPrecision(dimensionsInInches.width, precision);
        }
        if (dimensions.height) {
            dimensionsInInches.height = (dimensions.height * Constants.MM_TO_BJS_COEFF) / Constants.unit.INCH_STEP;
            dimensionsInInches.height = this.roundToPrecision(dimensionsInInches.height, precision);
        }
        if (dimensions.depth) {
            dimensionsInInches.depth = (dimensions.depth * Constants.MM_TO_BJS_COEFF) / Constants.unit.INCH_STEP;
            dimensionsInInches.depth = this.roundToPrecision(dimensionsInInches.depth, precision);
        }

        return dimensionsInInches;
    }

    static convertCoordinatesFrom2D(coordinate) {
        return new Vector3(coordinate.x, 0, coordinate.y).scaleInPlace(Constants.room.scale);
    }

    static haveSameDirection(firstVector, secondVector) {
        const dot = Vector3.Dot(firstVector, secondVector);
        const angle = Math.acos((dot / (firstVector.length() * secondVector.length())).toFixed(3));
        if (angle === 0) {
            return true;
        }
    }

    static getPolygonCentroid(cornerPositions) {
        let centroid = new Vector3(0, 0, 0);

        for (let i = 0; i < cornerPositions.length; i++) {
            let point = cornerPositions[i];
            centroid.x += point.x;
            centroid.y += point.y;
            centroid.z += point.z;
        }

        centroid.x /= cornerPositions.length;
        centroid.y /= cornerPositions.length;
        centroid.z /= cornerPositions.length;

        return centroid;
    }

    static getAdjacentWallsIds(currentWallId, walls) {
        if (currentWallId === 0) {
            return { leftWall: walls.length - 1, rightWall: 1 };
        } else if (currentWallId === walls.length - 1) {
            return { leftWall: walls.length - 2, rightWall: 0 };
        } else {
            return { leftWall: currentWallId - 1, rightWall: currentWallId + 1 };
        }
    }

    static getElementByMeshId(meshId, elements) {
        for (let index = 0; index < elements.length; index++) {
            if (elements[index].meshComponent) {
                if (elements[index].meshComponent.getMesh().id === meshId) {
                    return elements[index];
                }
            } else if (elements[index].getMesh().id === meshId) {
                return elements[index];
            }
        }
    }

    static findClosestWall(pickedPoint, walls, margin = 1000) {
        if (!pickedPoint) {
            return;
        }
        let minDistance = Number.MAX_VALUE;
        let closestWall;

        for (let i = 0; i < walls.length; i++) {
            const projectedPoint = Utilities.projectPointOnWall(pickedPoint, walls[i]);
            const distance = Vector3.Distance(pickedPoint, projectedPoint);
            if (distance < minDistance && walls[i].meshComponent.getMesh().visibility >= 0.3 && distance < margin) {
                minDistance = distance;
                closestWall = walls[i];
            }
        }
        return closestWall;
    }

    static isInside(pickedPoint, cornerPositions, dimensions) {
        const roomCorners = cornerPositions.concat(cornerPositions[0]);
        const leftCorner = new Vector3(pickedPoint.x - dimensions.width / 2, pickedPoint.y, pickedPoint.z + dimensions.depth / 2);
        const rightCorner = new Vector3(
            pickedPoint.x + dimensions.width / 2,
            pickedPoint.y,
            pickedPoint.z + dimensions.depth / 2
        );
        const topCorner = new Vector3(pickedPoint.x + dimensions.width / 2, pickedPoint.y, pickedPoint.z - dimensions.depth / 2);
        const bottomCorner = new Vector3(
            pickedPoint.x - dimensions.width / 2,
            pickedPoint.y,
            pickedPoint.z - dimensions.depth / 2
        );

        return (
            Utilities.isPointInside(leftCorner, roomCorners) &&
            Utilities.isPointInside(rightCorner, roomCorners) &&
            Utilities.isPointInside(topCorner, roomCorners) &&
            Utilities.isPointInside(bottomCorner, roomCorners)
        );
    }

    static findClosestPoint(pointA, pointB, firstClosestPoint, secondClosestPoint, segment) {
        let closestPoint = new Vector3(9999, 9999, 9999);
        if (
            secondClosestPoint &&
            Vector3.Distance(pointA, closestPoint) > Vector3.Distance(pointA, secondClosestPoint) &&
            Vector3.Distance(pointA, secondClosestPoint) < Vector3.Distance(pointA, firstClosestPoint)
        ) {
            closestPoint = secondClosestPoint;
        } else if (firstClosestPoint && Vector3.Distance(pointA, closestPoint) > Vector3.Distance(pointA, firstClosestPoint)) {
            closestPoint = firstClosestPoint;
        } else if (
            Vector3.Distance(pointA, closestPoint) > Vector3.Distance(pointA, segment.cornerB) &&
            Vector3.Distance(segment.cornerB, pointB) > Vector3.Distance(pointA, segment.cornerB)
        ) {
            closestPoint = segment.cornerB;
        } else if (
            Vector3.Distance(pointA, closestPoint) > Vector3.Distance(pointA, segment.cornerA) &&
            Vector3.Distance(segment.cornerA, pointB) > Vector3.Distance(pointA, segment.cornerA)
        ) {
            closestPoint = segment.cornerA;
        }
        return closestPoint;
    }

    static getMeshDimensions(mesh) {
        let dimensions = mesh.getBoundingInfo().boundingBox.extendSizeWorld.scale(2);

        return {
            width: dimensions.x,
            height: dimensions.y,
            depth: dimensions.z,
        };
    }

    static getCornerWalls(corner, walls) {
        let leftWall = null;
        let rightWall = null;
        for (let index = 0; index < walls.length; index++) {
            let wall = walls[index];
            if (corner.equals(wall.baseInnerCornerA)) {
                leftWall = wall;
            } else if (corner.equals(wall.baseInnerCornerB)) {
                rightWall = wall;
            }
        }
        return { leftWall: leftWall, rightWall: rightWall };
    }

    static getIndexInArray(object, objectArray) {
        for (let index = 0; index < objectArray.length; index++) {
            if (object.meshComponent.getMesh().id === objectArray[index].meshComponent.getMesh().id) {
                return index;
            }
        }
    }

    static getClosestNumberInArray(number, array, getClosestBiggerNumber = false) {
        //returns the closest value to given number in the given array
        let closestValue = array.reduce(function (previous, current) {
            return Math.abs(current - number) < Math.abs(previous - number) &&
                (current <= number || getClosestBiggerNumber) &&
                number !== 0
                ? current
                : previous;
        });

        return closestValue;
    }

    static getClosestSmallerNotEqualNumberInArray(number, array) {
        //returns the closest value to given number in the given array
        let closestValue = array.reduce(function (previous, current) {
            return Math.abs(current - number) < Math.abs(previous - number) && current < number && number !== 0
                ? current
                : previous;
        });

        return closestValue;
    }

    static getClosestSmallerNumberInArray(number, array) {
        number = Utilities.roundToPrecision(number, 10000);
        //returns the closest value to given number in the given array
        let closestValue = array.reduce(function (previous, current) {
            return Math.abs(current - number) < Math.abs(previous - number) && current <= number ? current : previous;
        });
        return closestValue;
    }

    static expressNumberAsSumOfArrayElements(number, array) {
        const values = [];
        for (let index = 0; index < array.length; index++) {
            if (Math.floor(number / array[index]) !== 0) {
                let times = Math.floor(number / array[index]);
                for (let noTimes = 0; noTimes < times; noTimes++) {
                    values.push(array[index]);
                }
                number -= array[index] * times;
            }
        }
        return values;
    }

    static getIndexFromMeshId(meshId, array) {
        for (let index = 0; index < array.length; index++) {
            if (array[index].id === meshId) {
                return index;
            }
        }
    }

    static areSegmentsOverlapping(firstSegment, secondSegment) {
        if (
            Utilities.isPointBetween(firstSegment.firstPoint, firstSegment.secondPoint, secondSegment.firstPoint) ||
            Utilities.isPointBetween(firstSegment.firstPoint, firstSegment.secondPoint, secondSegment.secondPoint) ||
            Utilities.isPointBetween(secondSegment.firstPoint, secondSegment.secondPoint, firstSegment.firstPoint) ||
            Utilities.isPointBetween(secondSegment.firstPoint, secondSegment.secondPoint, firstSegment.secondPoint)
        ) {
            return true;
        } else {
            return false;
        }
    }

    static roundToPrecision(number, precision = 100) {
        const result = Math.round((parseFloat(number) + Number.EPSILON) * precision) / precision;
        return result;
    }

    static getElementByMeshName(name, elements) {
        for (let index = 0; index < elements.length; index++) {
            if (elements[index].name === name) {
                return elements[index];
            }
        }
    }

    static findClosestPointToWall(pointA, pointB, wall) {
        let closestPointToWall = 'leftPoint';
        if (Utilities.findDistanceFromWall(pointA, wall) > Utilities.findDistanceFromWall(pointB, wall)) {
            closestPointToWall = 'rightPoint';
        }
        return closestPointToWall;
    }

    static resizeMeshInWidth(mesh, size) {
        const meshDimensions = this.getMeshDimensions(mesh);
        mesh.scaling.x = size / (meshDimensions.width / mesh.scaling.x);
    }

    static resizeMeshInDepth(mesh, size) {
        const meshDimensions = this.getMeshDimensions(mesh);
        mesh.scaling.z = size / (meshDimensions.depth / mesh.scaling.z);
    }

    static resizeMeshInHeight(mesh, size) {
        const meshDimensions = this.getMeshDimensions(mesh);
        mesh.scaling.y = size / (meshDimensions.height / mesh.scaling.y);
    }

    static convertToBJSUnit(dimensions) {
        if (dimensions.width) {
            dimensions.width /= Constants.MM_TO_BJS_COEFF;
        }
        if (dimensions.height) {
            dimensions.height /= Constants.MM_TO_BJS_COEFF;
        }
        if (dimensions.depth) {
            dimensions.depth /= Constants.MM_TO_BJS_COEFF;
        }
        if (dimensions.heightFromFloor) {
            dimensions.heightFromFloor /= Constants.MM_TO_BJS_COEFF;
        }
        return dimensions;
    }

    static convertFromMMToBJSUnit(dimensions) {
        const dimensionsInMM = {}
        if (dimensions.width) {
            dimensionsInMM.width = Utilities.roundToPrecision(dimensions.width * Constants.MM_TO_BJS_COEFF);
        }
        if (dimensions.height) {
            dimensionsInMM.height = Utilities.roundToPrecision(dimensions.height * Constants.MM_TO_BJS_COEFF);
        }
        if (dimensions.depth) {
            dimensionsInMM.depth = Utilities.roundToPrecision(dimensions.depth * Constants.MM_TO_BJS_COEFF);
        }
        if (dimensions.heightFromFloor) {
            dimensionsInMM.heightFromFloor = Utilities.roundToPrecision(dimensions.heightFromFloor * Constants.MM_TO_BJS_COEFF);
        }
        return dimensionsInMM;
    }

    static getAverageCabinetWidth(availableCabinetDimensions) {
        let sum = 0;
        for (let index = 0; index < availableCabinetDimensions.length; index++) {
            sum += availableCabinetDimensions[index];
        }
        let averageCabinetWidth = sum / availableCabinetDimensions.length;
        return Utilities.getClosestNumberInArray(averageCabinetWidth, availableCabinetDimensions, true);
    }

    static knapsackAlgorithm(W, wt) {
        const val = wt;
        const n = val.length;
        const result = [];
        let i, w;
        let K = new Array(n + 1);
        for (i = 0; i < K.length; i++) {
            K[i] = new Array(W + 1);
            for (let j = 0; j < W + 1; j++) {
                K[i][j] = 0;
            }
        }

        // Build table K[][] in bottom up manner
        for (i = 0; i <= n; i++) {
            for (w = 0; w <= W; w++) {
                if (i == 0 || w == 0) K[i][w] = 0;
                else if (wt[i - 1] <= w) K[i][w] = Math.max(val[i - 1] + K[i - 1][w - wt[i - 1]], K[i - 1][w]);
                else K[i][w] = K[i - 1][w];
            }
        }

        // stores the result of Knapsack
        let res = K[n][W];
        const filledWidth = res;

        w = W;
        for (i = n; i > 0 && res > 0; i--) {
            // either the result comes from the top
            // (K[i-1][w]) or from (val[i-1] + K[i-1]
            // [w-wt[i-1]]) as in Knapsack table. If
            // it comes from the latter one/ it means
            // the item is included.
            if (res == K[i - 1][w]) continue;
            else {
                // This item is included.
                result.push({ sectionWidth: wt[i - 1], numberOfSections: 1 });

                // Since this weight is included its
                // value is deducted
                res = res - val[i - 1];
                w = w - wt[i - 1];
            }
        }
        return { result, filledWidth };
    }
}
