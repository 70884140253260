import store from '@/store/index.js';
import { Vector3, Quaternion } from '@babylonjs/core';
import { FixturePartBuilder } from './Part/FixturePartBuilder';
import { FixtureTextureUpdate } from './Part/FixtureTextureUpdate';
export class Fixture {
    constructor(data, parent) {
        this.type = data.type;
        this.subType = data.subType;

        this.uID = 'Fixture|' + this.type + '/' + this.subType + ':' + Date.now();

        this.dimensions = data.dimensions;
        this.currentUnit = data.currentUnit;

        this.assignedSegment = -1;
        this.assignedSlot = -1;
        this.flipped = data.flipped || 0;
        this.roomPolygon = [];
        this.lastValue = null;
        this.parts = [];

        this.dragOb = null;

        this.parent = parent;
        this.engine = parent.parent;
        this.scene = this.engine.scene;
        this.wallThickness = parent.wallThickness;

        this.selected = false;
        this.isHover = false;
        this.widget = null;
        this.rotation = 0;

        this.positionFor3D = null;
        FixturePartBuilder(this);
        this.startDrag();
    }

    //Iterate though all parts on the Icon and update the dynamic textures
    updateTextureParts() {
        FixtureTextureUpdate(this);
    }

    startDrag() {
        this.engine.controls.selectedItem = this;
        store.commit('fixtures/setSelectedFixture', this);
        this.selected = true;
        this.updateTextureParts();
        this.roomPolygon = this.parent.getRoomPolygon();
    }

    update() {
        this.widget.update();
    }

    validateDimensions() {
        //Grab each key in the dimensions and check the store min max values
        let keys = Object.keys(this.dimensions);
        let fData = store.state.fixtures.fixtures;

        for (let i = 0; i < keys.length; i++) {
            let k = keys[i];
            let mn = Number(fData[this.type].type[this.subType].dimensions[k].min[this.currentUnit]);
            let mx = Number(fData[this.type].type[this.subType].dimensions[k].max[this.currentUnit]);
            this.dimensions[k] = Math.min(Math.max(this.dimensions[k], mn), mx);
        }
    }

    init() {
        FixturePartBuilder(this);
        this.startDrag();
    }

    purge(force = false) {
        if (!force) {
            const idx = this.parent.fixtures.findIndex((f) => f.uID === this.uID);
            this.parent.fixtures.splice(idx, 1);
        }

        this.widget.dispose();
        this.parts.forEach((p) => {
            p.dispose();
        });
        this.widget.dispose();
        this.parts = [];
    }

    resize() {
        this.purge();
        this.init();
        this.updateTextureParts();
        this.parent.fixtures.push(this);
    }

    dispose() {
        if (this.selected) {
            this.engine.controls.selectedItem = null;
            this.engine.controls.lastSegmentData = null;
            this.selected = false;
            this.selectedFixture = store.commit('fixtures/setSelectedFixture', null);
        }

        this.purge();
        if (this.getAssignedSegment()) {
            this.getAssignedSegment().widget.dirty = true;
            this.getAssignedSegment().widget.update();
        }
    }

    setFromSegmentData(data) {
        this.assignedSegment = data.closestId;
        this.assignedSlot = data.slotId;
        if (!this.parent.parent.controls.isSceneLoading) {
            this.flipped = data.isFlipped ? 1 : 0;
        }
        this.updateRotation(this.flipped);
        this.setPosition(this.getAssignedSegment().valueTo3dPosition(data.value));
        this.lastValue = data.value;
        this.update();
    }

    getAssignedSegment() {
        return this.assignedSegment != -1 ? this.parent.segments[this.assignedSegment] : false;
    }

    getAssignedSlot() {
        let seg = this.getAssignedSegment();
        return seg && this.assignedSlot != -1 ? seg.slots[this.assignedSlot] : false;
    }

    getPosition() {
        return this.getRoot().position;
    }

    setPosition(v) {
        this.getRoot().position = v;
    }

    getRotation() {
        return this.getAssignedSegment().rotation;
    }

    updateRotation(flipped = 0) {
        let as = this.getAssignedSegment();
        this.getRoot().rotationQuaternion = new Quaternion.RotationAxis(new Vector3(0, 0, 1), as.angle + Math.PI * flipped);
    }

    setRotation(r) {
        this.getRoot().rotationQuaternion = r;
    }

    getLeftValue() {
        return this.getAssignedSegment().slots[this.assignedSlot].a;
    }

    getRightValue() {
        return this.getAssignedSegment().slots[this.assignedSlot].b;
    }

    getRoot() {
        return this.parts[0].plane;
    }

    getBBWorldPoints() {
        return this.getRoot().getBoundingInfo().boundingBox.vectorsWorld;
    }

    getWidthText() {
        return this.dimensions.width;
    }

    getName() {
        switch (this.type) {
            case 'door':
                switch (this.subType) {
                    case 'standard':
                        return 'Standard Door';
                    case 'sliding':
                        return 'Sliding Door';
                    case 'balcony':
                        return 'Balcony Door';
                }
                break;
            case 'window':
                return 'Window';
            case 'openSpace':
                return 'Open Space';
            case 'fixedColumn':
                return 'Column';
        }
    }

    getEditorCore() {
        return this.parent.getEditorCore();
    }
}
