import {
    Vector3,
    Scene,
    HemisphericLight,
    MeshBuilder,
    Engine,
    Color3,
    KeyboardEventTypes,
    SpotLight,
    StandardMaterial,
    Color4,
    SSAORenderingPipeline,
    LightGizmo,
    GizmoManager,
} from '@babylonjs/core';
import store from '@/store/index.js';
import { Component } from './Base/Component';
import { RoomObject } from '../KitchenObjects/Room/RoomObject';
import { Constants } from '../Tools/constants';
import { Utilities } from '../Tools/utilities';

export class SceneComponent extends Component {
    scene;
    engine;

    constructor() {
        super();
    }

    addMesh(mesh) {
        this.scene.addMesh(mesh);
    }

    prepare() {
        this.engine = new Engine(this.editor.canvas, true, { preserveDrawingBuffer: true, stencil: true });
        this.scene = new Scene(this.engine);
        this.light = new HemisphericLight('hemiLight', new Vector3(15, 10, 7), this.scene);

        this.light.intensity = 1;

        this.ground = MeshBuilder.CreateGround(
            'ground',
            {
                height: 20,
                width: 20,
            },
            this.scene
        );
        this.ground.material = new StandardMaterial('groundMaterial', this.scene);
        this.ground.material.maxSimultaneousLights = 10;
        this.ground.alwaysSelectAsActiveMesh = true;

        this.setBoundingBoxColor();
        this.listenForKeyboardEvents();

        this.scene.blockfreeActiveMeshesAndRenderingGroups = true;
        this.scene.useGeometryIdsMap = true;
        this.scene.skipFrustumClipping = true;
        this.scene.freezeMaterials();

        this.scene.cleanCachedTextureBuffer();
    }

    createGizmos() {
        const lightGizmo = new LightGizmo();
        lightGizmo.scaleRatio = 2;
        lightGizmo.light = this.light;
        const gizmoManager = new GizmoManager(this.scene);
        gizmoManager.positionGizmoEnabled = true;
        gizmoManager.rotationGizmoEnabled = true;
        gizmoManager.usePointerToAttachGizmos = false;
        gizmoManager.attachToMesh(lightGizmo.attachedMesh);
    }

    applySSOA() {
        var ssaoRatio = {
            ssaoRatio: 0.5, // Ratio of the SSAO post-process, in a lower resolution
            combineRatio: 1.0, // Ratio of the combine post-process (combines the SSAO and the scene)
        };

        var ssao = new SSAORenderingPipeline('ssao', this.scene, ssaoRatio);

        ssao.fallOff = 0.000001;
        ssao.area = 1;
        ssao.radius = 0.0001;
        ssao.totalStrength = 0.7;
        ssao.base = 0.5;
        const camera = this.editor.cameraComponent.camera;
        // Attach camera to the SSAO render pipeline
        console.log(this.scene.postProcessRenderPipelineManager);

        this.scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline('ssao', camera, true);
        this.scene.postProcessRenderPipelineManager.enableEffectInPipeline('ssao', ssao.SSAOCombineRenderEffect, camera);

        // Manage SSAO
        var isAttached = true;
        window.addEventListener('keydown', function (evt) {
            // draw SSAO with scene when pressed "1"
            if (evt.keyCode === 49) {
                console.log(this);
                if (!isAttached) {
                    isAttached = true;
                    this.scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline('ssao', camera);
                }
                this.scene.postProcessRenderPipelineManager.enableEffectInPipeline('ssao', ssao.SSAOCombineRenderEffect, camera);
            }
            // draw without SSAO when pressed "2"
            else if (evt.keyCode === 50) {
                isAttached = false;
                this.scene.postProcessRenderPipelineManager.detachCamerasFromRenderPipeline('ssao', camera);
            }
            // draw only SSAO when pressed "2"
            else if (evt.keyCode === 51) {
                if (!isAttached) {
                    isAttached = true;
                    this.scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline('ssao', camera);
                }
                this.scene.postProcessRenderPipelineManager.disableEffectInPipeline('ssao', ssao.SSAOCombineRenderEffect, camera);
            }
        });
    }

    setBoundingBoxColor(color = Color3.Black()) {
        this.scene.getBoundingBoxRenderer().backColor = color;
        this.scene.getBoundingBoxRenderer().frontColor = color;
    }

    setSpotLights() {
        this.spotlights = [];
        const walls = this.editor.getObjectByType(RoomObject).walls;

        for (let index = 0; index < walls.length; index++) {
            const cornerA = walls[index].baseInnerCornerA.clone();
            const cornerB = walls[index].baseInnerCornerB.clone();

            cornerA.y = Constants.room.dimensions.HEIGHT;
            cornerB.y = Constants.room.dimensions.HEIGHT;

            const wallHasCabinets = walls[index].getObjectsOnWall().length !== 0;
            if (wallHasCabinets) {
                const wallLength = walls[index].length;
                const numberOfSpotLights = parseInt(wallLength) - 1;

                for (let spotLightIndex = 1; spotLightIndex < numberOfSpotLights; spotLightIndex++) {
                    this.createSpotLight(new Vector3.Lerp(cornerA, cornerB, spotLightIndex / numberOfSpotLights), walls[index]);
                }
            }
        }
    }

    createSpotLight(position, wall) {
        const roomCorners = this.editor.sceneInfo.corners.concat(this.editor.sceneInfo.corners[0]);
        roomCorners[0] = new Vector3(roomCorners[0].x, Constants.room.dimensions.HEIGHT, roomCorners[0].y);
        position = Utilities.calculateShiftValue(position.clone(), -1, roomCorners, wall);

        let light = new SpotLight('spotLight', position.clone(), new Vector3(0, -1, 0), Math.PI / 2, 10, this.scene);
        light.intensity = 10;
        this.spotlights.push(light);
    }

    listenForKeyboardEvents() {
        this.scene.onKeyboardObservable.add((kbInfo) => {
            switch (kbInfo.type) {
                case KeyboardEventTypes.KEYDOWN:
                    if (['ShiftLeft', 'ShiftRight'].includes(kbInfo.event.code)) {
                        this.editor.multiselection = true;
                    }
                    break;
                case KeyboardEventTypes.KEYUP:
                    if (['ShiftLeft', 'ShiftRight'].includes(kbInfo.event.code)) {
                        this.editor.multiselection = false;
                    }
                    break;
            }
        });
    }

    save3D() {
        const roomObject = this.editor.getObjectByType(RoomObject);
        store.commit('core/setProjectData', {
            corners: store.state.core.projectData.corners,
            fixtures: store.state.core.projectData.fixtures,
            cabinets: store.state.core.cabinetData,
            appliances: store.getters['core/applianceData'],
            canvasLoaded: store.state.core.canvasLoaded,
            startedDesigning: store.state.core.startedDesigning,
            step: store.state.core.step,
            unit: store.state.core.unit,
            floor: roomObject.floor?.customization,
            walls: roomObject.walls?.map((wall) => wall.customization),
        });
        this.editor.sceneInfo = store.state.core.projectData;
    }

    get() {
        return this.scene;
    }

    changeColor(color = new Color4(0.2, 0.2, 0.3, 1.0)) {
        this.scene.clearColor = color;
    }

    addToActiveCameras(camera) {
        this.scene.activeCameras.push(camera);
    }
}
