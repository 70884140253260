import { Mesh, MeshBuilder, Vector3 } from '@babylonjs/core';
import { Constants } from '../../../../Tools/constants';
import { Utilities } from '../../../../Tools/utilities';
import { CabinetStructure } from '../../../BuildingBlocks/CabinetStructure';
import { DoorBlock } from '../../../BuildingBlocks/DoorBlock';
import { Skirting } from '../../../BuildingBlocks/Skirting';
import { Model } from '../../Model';
import { ResizingManager } from './ResizingManager';

export class TallCornerSection extends Model {
    constructor() {
        super();
        this.side = null;
        this.parts = {};
        this.parentCabinet = null;
        this.resizingManager = this.registerComponent(ResizingManager);
    }

    async setModel() {
        const result = await this.import('tallCornerLShape.babylon');
        this.meshComponent.setMesh(Utilities.getElementByMeshName('tallCornerLShape', result.meshes));
        this.meshComponent.setName('tallCornerLShape');
        this.setParts();
    }

    resize(dimensions) {
        this.resizingManager.parts = this.parts;
        this.resizingManager.resizeWidth(dimensions);
        this.resizingManager.resizeDepth(dimensions);
        this.resizingManager.resizeHeight(dimensions);
    }

    placeHandle(tallCabinetModel) {
        const handleClone = Mesh.MergeMeshes([this.editor.models3D.handle.meshComponent.getMesh().clone()]);
        handleClone.position = this.parts.handle.mesh.position.clone();
        handleClone.type = 'handle';
        handleClone.parent = tallCabinetModel;
        return handleClone;
    }

    mergeModel() {
        const tallCornerCabinet = MeshBuilder.CreateBox('tall-cabinet-model', { width: 0.1, depth: 0.1, height: 0.1 });
        tallCornerCabinet.visibility = 0;
        const tallCorner = { mesh: tallCornerCabinet, buildingBlocks: {} };

        this.placeHandle(tallCorner.mesh);
        tallCorner.buildingBlocks.cabinetBlock = this.mergeCabinetBlock(tallCorner.mesh);
        tallCorner.buildingBlocks.skirtingBlock = this.mergeSkirtingBlock(tallCorner.mesh);
        tallCorner.buildingBlocks.doorBlock = this.mergeDoorBlock(tallCorner.mesh);

        tallCorner.mesh.rotation = Vector3.Zero();
        return tallCorner;
    }

    mergeCabinetBlock(parentMesh) {
        let meshes = [];
        Object.keys(this.parts.cabinet).forEach((key) => {
            if (!this.parts.cabinet[key].mesh.name.startsWith('shelf')) {
                meshes.push(this.parts.cabinet[key].mesh);
            }
        });
        const innerStructure = this.editor.addObject(CabinetStructure);
        innerStructure.setParentSection(this.currentParentSection);
        innerStructure.create(meshes, 'cabinets');
        innerStructure.meshComponent.setParent(parentMesh);
        return innerStructure;
    }

    mergeSkirtingBlock(parentMesh) {
        let meshes = [];
        Object.keys(this.parts.skirting).forEach((key) => {
            meshes.push(this.parts.skirting[key].mesh);
        });
        const skirtingBlock = this.editor.addObject(Skirting);
        skirtingBlock.setParentSection(this.currentParentSection);
        skirtingBlock.enableSelection();
        skirtingBlock.create(meshes, 'skirting');
        skirtingBlock.meshComponent.setParent(parentMesh);
        return skirtingBlock;
    }

    mergeDoorBlock(parentMesh) {
        let meshes = [];
        Object.keys(this.parts.doors).forEach((key) => {
            meshes.push(this.parts.doors[key].mesh);
        });
        const doorBlock = this.editor.addObject(DoorBlock);
        doorBlock.setParentSection(this.currentParentSection);
        doorBlock.create(meshes, 'cabinets');
        doorBlock.meshComponent.setParent(parentMesh);
        return doorBlock;
    }

    getResizingDimensions(sectionWidth = null) {
        const cabinet = this.parentCabinet;
        const connectedCabinet = cabinet.connectedCabinet ? cabinet.connectedCabinet : cabinet.secondConnectedCabinet;
        const DOOR_SIZE = Constants.baseCornerCabinet.DOOR_SIZE[this.editor.unit];
        return {
            width: DOOR_SIZE + connectedCabinet.depth + Constants.STANDARD_FILLER_SIZE[this.editor.unit],
            doorWidth: DOOR_SIZE,
            connectedCabinetDepth: connectedCabinet.depth + Constants.STANDARD_FILLER_SIZE[this.editor.unit],
            depth: cabinet.depth,
            height: cabinet.height,
        };
    }
}
