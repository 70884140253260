import { Vector3 } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';

export class CabinetAligner {
    constructor(editor) {
        this.editor = editor;
    }

    alignWallAndBaseCabinet(wallCabinet) {
        if (!wallCabinet.baseCabinetUnderneath || !wallCabinet.baseCabinetUnderneath?.sections[0]) {
            return;
        }
        return wallCabinet.adaptToBaseCabinetUnderneath();
    }

    calculateDisplacement(cabinet) {
        let displacement = 0;
        const closestObstacles = cabinet.obstacleManager.findNearestObstacles(['wall', 'window']);
        displacement = this.displacementForContinuousShape(cabinet, closestObstacles.closestFixtureFromPointB);

        return displacement;
    }

    displacementForContinuousShape(cabinet, obstacle) {
        if (!obstacle) {
            return 0;
        }
        const cabinetPositionInfo = cabinet.getPositionInfo();
        const obstactlePositionInfo = obstacle.getPositionInfo();

        const projectedLastReplacedPosition = Utilities.projectPointOnWall(
            obstacle.meshComponent.getMesh().position,
            obstacle.assignedWall
        );
        const projectedCurrentCabinetPosition = Utilities.projectPointOnWall(
            cabinet.meshComponent.getMesh().position,
            cabinet.assignedWall
        );

        if (
            Vector3.Distance(projectedLastReplacedPosition, projectedCurrentCabinetPosition) -
                (obstacle.width / 2 + cabinet.width / 2) <
                Constants.continuousLayout.MARGIN &&
            !cabinetPositionInfo.cornerA &&
            !cabinetPositionInfo.cornerB &&
            !cabinet.connectedCabinet &&
            !cabinet.secondConnectedCabinet
        ) {
            let distanceFromWall = 0;
            if (obstacle.connectedCabinet && !obstactlePositionInfo.cornerA && obstacle.cornerCabinetInfo) {
                distanceFromWall = obstacle.connectedCabinet.depth;
            } else if (obstacle.secondConnectedCabinet && !obstactlePositionInfo.cornerB && obstacle.cornerCabinetInfo) {
                distanceFromWall = obstacle.secondConnectedCabinet.depth;
            }
            let considerDeadSpaceAfterFill = obstacle.width + distanceFromWall - obstacle.filledWidth;

            if (
                !obstactlePositionInfo.cornerA &&
                !obstactlePositionInfo.cornerB &&
                !obstacle.connectedCabinet &&
                !obstacle.secondConnectedCabinet
            ) {
                considerDeadSpaceAfterFill = 0;
            }

            return (
                Vector3.Distance(projectedLastReplacedPosition, projectedCurrentCabinetPosition) -
                (obstacle.width / 2 + cabinet.width / 2) +
                considerDeadSpaceAfterFill
            );
        } else {
            return 0;
        }
    }

    adaptStartingPoints(cabinet, startingPoint, allowedSegment, replacementDirection) {
        if (cabinet.secondConnectedCabinet) {
            let DOOR_SIZE = 0;
            if (cabinet.secondConnectedCabinet.cornerCabinetInfo) {
                DOOR_SIZE = cabinet.secondConnectedCabinet.getCornerCabinetDoor();
            } else {
                DOOR_SIZE = cabinet.getCornerCabinetDoor();
            }
            startingPoint.addInPlace(replacementDirection.clone().scale(DOOR_SIZE));
            allowedSegment.secondPoint = startingPoint;

            if (cabinet.connectedCabinet) {
                allowedSegment.firstPoint.addInPlace(replacementDirection.clone().scale(-DOOR_SIZE));
            }
        } else if (cabinet.connectedCabinet) {
            let DOOR_SIZE = 0;
            if (cabinet.connectedCabinet.cornerCabinetInfo) {
                DOOR_SIZE = cabinet.connectedCabinet.getCornerCabinetDoor();
            } else {
                DOOR_SIZE = cabinet.getCornerCabinetDoor();
            }
            startingPoint.addInPlace(replacementDirection.clone().scale(DOOR_SIZE));
            allowedSegment.firstPoint = startingPoint;
            if (cabinet.secondConnectedCabinet) {
                allowedSegment.secondPoint.addInPlace(replacementDirection.clone().scale(DOOR_SIZE));
            }
        } else if (
            cabinet.baseCabinetUnderneath &&
            !cabinet.connectedCabinet &&
            !cabinet.secondConnectedCabinet
            // &&
            // !cabinetPositionInfo.cornerA &&
            // !cabinetPositionInfo.cornerB
        ) {
            if (!this.shouldAdaptStartingPoints(cabinet)) {
                return [startingPoint, allowedSegment, replacementDirection];
            }
            if (cabinet.baseCabinetUnderneath.cornerCabinetInfo?.cornerIndex === Constants.corner.CORNER_A) {
                startingPoint = allowedSegment.firstPoint;
                replacementDirection = cabinet.assignedWall.getDirection().negate();
            } else if (cabinet.baseCabinetUnderneath.cornerCabinetInfo?.cornerIndex === Constants.corner.CORNER_B) {
                startingPoint = allowedSegment.secondPoint;
                replacementDirection = cabinet.assignedWall.getDirection();
            } else if (cabinet.baseCabinetUnderneath.connectedCabinet) {
                startingPoint = allowedSegment.firstPoint;
            } else if (cabinet.baseCabinetUnderneath.secondConnectedCabinet) {
                startingPoint = allowedSegment.secondPoint;
                replacementDirection = cabinet.assignedWall.getDirection();
            }
        }

        return [startingPoint, allowedSegment, replacementDirection];
    }

    shouldAdaptStartingPoints(cabinet) {
        if (cabinet.baseCabinetUnderneath) {
            const cabinetPositionInfo = cabinet.getEdgesOnWall();
            const cabinetUnderNeathPositionInfo = cabinet.baseCabinetUnderneath.getEdgesOnWall();

            const distanceFromFirstPoints = Vector3.Distance(
                cabinetPositionInfo.firstPoint,
                cabinetUnderNeathPositionInfo.firstPoint
            );
            const distanceFromSecondPoints = Vector3.Distance(
                cabinetPositionInfo.secondPoint,
                cabinetUnderNeathPositionInfo.secondPoint
            );

            if (distanceFromFirstPoints <= 0.15 && distanceFromSecondPoints <= 0.15) {
                return true;
            }
        }
    }
}
