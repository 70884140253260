var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper-header",
    [
      _c(
        "v-stepper-step",
        {
          staticClass: "ma-0 py-0 px-2",
          attrs: { complete: _vm.step > 1, step: "1", editable: _vm.step > 1 },
        },
        [_vm._v("Draw Room ")]
      ),
      _c("v-divider"),
      _c(
        "v-stepper-step",
        {
          staticClass: "ma-0 py-0 px-2",
          attrs: { complete: _vm.step > 2, editable: _vm.step > 2, step: "2" },
        },
        [_vm._v("Draw Cabinets ")]
      ),
      _c("v-divider"),
      _c(
        "v-stepper-step",
        {
          staticClass: "ma-0 py-0 px-2",
          attrs: { complete: _vm.step > 3, editable: _vm.step > 3, step: "3" },
        },
        [_vm._v("Add Appliances ")]
      ),
      _c("v-divider"),
      _c(
        "v-stepper-step",
        { staticClass: "ma-0 py-0 px-2", attrs: { step: "4" } },
        [_vm._v("Final Design ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }