var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper-content",
    { staticClass: "pa-2", attrs: { step: "3" } },
    [
      !_vm.selectedAppliance && !_vm.selectedCabinet
        ? _c(
            "v-row",
            { staticClass: "pa-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "8", md: "9" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "blue-grey lighten-4 rounded-lg" },
                    [
                      _c(
                        "v-slide-group",
                        {
                          staticClass: "mx-auto",
                          attrs: { "show-arrows": "" },
                        },
                        _vm._l(_vm.appliances, function (appliance, key) {
                          return _c(
                            "v-slide-item",
                            { key: key },
                            [
                              _c("v-hover", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ hover }) {
                                        return [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "pa-1 ma-2",
                                              attrs: {
                                                elevation: hover ? 16 : 2,
                                                width: _vm.$vuetify.breakpoint
                                                  .smAndUp
                                                  ? 150
                                                  : 100,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;(_vm.openDialog = true),
                                                    _vm.setModalAppliance(
                                                      appliance
                                                    )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "py-2 text-caption text-center text-subtitle-2",
                                                },
                                                [
                                                  _c("v-img", {
                                                    staticClass: "pa-2",
                                                    attrs: {
                                                      src: appliance.img,
                                                      height: "60",
                                                      contain: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.$vuetify.breakpoint.smAndUp
                                                ? _c(
                                                    "v-card-subtitle",
                                                    {
                                                      staticClass:
                                                        "text-center py-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(appliance.name)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "800px",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                  },
                  model: {
                    value: _vm.openDialog,
                    callback: function ($$v) {
                      _vm.openDialog = $$v
                    },
                    expression: "openDialog",
                  },
                },
                [_vm.openDialog ? _c("ApplianceDialog") : _vm._e()],
                1
              ),
              _c("StepsButtons"),
            ],
            1
          )
        : _vm.selectedAppliance
        ? _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [_c("ApplianceEdit")],
            1
          )
        : _vm.selectedCabinet
        ? _c("v-container", [_c("CabinetEdit")], 1)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }