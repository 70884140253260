export default {
    cabinets: {
        id: 'cabinets',
        collections: [
            {
                sheet: false,
                name: 'Mono Color',
                color: {
                    selected: null,
                    type: 'monoColor',
                    items: [
                        '#FFFFFF',
                        '#F9F3E3',
                        '#F4ECD5',
                        '#F1F2E0',
                        '#DBD7BC',
                        '#D4CBBC',
                        '#958A84',
                        '#9F8F7B',
                        '#F6D2B8',
                        '#E8D6CC',
                        '#3B242C',
                        '#130002',
                        '#410100',
                        '#650000',
                        '#AE0202',
                        '#949391',
                        '#808078',
                        '#636162',
                        '#605D66',
                        '#48464B',
                        '#323232',
                        '#2C2C2C',
                        '#3C3C3C',
                        '#443F43',
                        '#323137',
                        '#79839C',
                        '#CC462B',
                        '#B7C97D',
                        '#010101',
                        '#020200',
                    ],
                },
                surface: {
                    selected: 'matt',
                    items: ['matt', 'gloss'],
                },
                doorStyle: {
                    selected: 'contemporary',
                    items: ['contemporary'],
                },
            },
            {
                sheet: false,
                name: 'Wood',
                color: {
                    selected: null,
                    type: 'texture',
                    items: [
                        'WoodButcherBlockHickory001',
                        'WoodButcherBlockHickory002',
                        'WoodButcherBlockHickoryDark001',
                        'WoodButcherBlockHickoryDark002',
                        'WoodButcherBlockSapele001',
                        'WoodButcherBlockSapele002',
                        'WoodCrownChorusHall001',
                        'WoodCrownChorusHall002',
                        'WoodCrownSmoothWalnut001',
                        'WoodCrownWhisper001',
                        'WoodFine001',
                        'WoodFine002',
                        'WoodFine003',
                        'WoodFine004',
                        'WoodFine005',
                        'WoodFine006',
                        'WoodFine007',
                        'WoodFine008',
                        'WoodFine009',
                        'WoodFine010',
                        'WoodFine011',
                        'WoodFine012',
                        'WoodFine013',
                        'WoodFine014',
                        'WoodFineDark001',
                        'WoodFineDark002',
                        'WoodFineDark003',
                        'WoodFineDark004',
                        'WoodFinePlywoodBurnt001',
                        'WoodFineVeneerOak001',
                        'WoodFineVeneerOak002',
                        'WoodFineVeneerOakLarge001',
                        'WoodFineVeneerOakLarge002',
                        'WoodFineVeneerWalnut001',
                        'WoodFineVeneerWalnut002',
                        'WoodFineVeneerWalnut003',
                        'WoodFineVeneerWalnut004',
                        'WoodFineVeneerWalnut005',
                        'WoodFineVeneerWalnut006',
                        'WoodFineVeneerWalnutLarge001',
                        'WoodFineVeneerWalnutLarge002',
                        'WoodFineVeneerWalnutLarge003',
                        'WoodFineVeneerWalnutLarge004',
                        'WoodFineVeneerWalnutLarge005',
                        'WoodFineVeneerWalnutLarge006',
                        'WoodMahoganyAfricanSanded001',
                        'WoodMelamineBallare001',
                        'WoodMelamineBallareLarge001',
                        'WoodMelamineBruciato001',
                        'WoodMelamineBruciato002',
                        'WoodPlywood001',
                        'WoodPlywood002',
                        'WoodQuarteredAntioco001',
                        'WoodQuarteredCaramel001',
                        'WoodQuarteredCatseye001',
                        'WoodQuarteredChampagne001',
                        'WoodQuarteredChiffon001',
                        'WoodQuarteredCittadino',
                        'WoodQuarteredCittadino001',
                        'WoodQuarteredCoffeeTable001',
                        'WoodQuarteredCorduroy001',
                        'WoodQuarteredEucalyptusSmoked001',
                        'WoodQuarteredMidnightVista001',
                        'WoodQuarteredMidnightVista002',
                        'WoodQuarteredMocha001',
                        'WoodQuarteredNewMoon001',
                        'WoodQuarteredOrangeGrove001',
                        'WoodQuarteredPersian001',
                        'WoodQuarteredRecchiuti001',
                        'WoodQuarteredRiccione001',
                        'WoodQuarteredSafari001',
                        'WoodQuarteredSafari002',
                        'WoodQuarteredSarphina001',
                        'WoodQuarteredSlate001',
                        'WoodQuarteredSpottedGum001',
                        'WoodQuarteredSunflowerSeed001',
                        'WoodQuarteredTimberwolf001',
                        'WoodQuarteredVenezia001',
                        'WoodQuarteredWhiteOak001',
                    ],
                },
                surface: {
                    selected: 'matt',
                    items: ['matt', 'gloss'],
                },
                doorStyle: {
                    selected: 'contemporary',
                    items: ['contemporary'],
                },
            },
        ],
        doorType: [
            {
                id: '1door',
                name: '1 Door',
                img: require('@/assets/icons/door-types/1door.svg'),
                cabinetType: ['base', 'wall', 'tall'],
            },
            {
                id: '2door',
                name: '2 Door',
                img: require('@/assets/icons/door-types/2door.svg'),
                cabinetType: ['base', 'wall', 'tall'],
            },
            {
                id: '4drawer',
                name: '4 Drawer',
                img: require('@/assets/icons/door-types/4drawer.svg'),
                cabinetType: ['base'],
            },
            {
                id: '2drawer',
                name: '2 Big Drawer',
                img: require('@/assets/icons/door-types/2drawer.svg'),
                cabinetType: ['base'],
            },
            {
                id: '2small1BigDrawer',
                name: '2 Small 1 Big Drawer',
                img: require('@/assets/icons/door-types/2small1BigDrawer.svg'),
                cabinetType: ['base'],
            },
            {
                id: '1door1Drawer',
                name: '1 Door 1 Drawer',
                img: require('@/assets/icons/door-types/1door1Drawer.svg'),
                cabinetType: ['base'],
            },
            {
                id: '2door1Drawer',
                name: '2 Door 1 Drawer',
                img: require('@/assets/icons/door-types/2door1Drawer.svg'),
                cabinetType: ['base'],
            },
        ],
        dimensions: {
            base: {
                width: {
                    items: {
                        mm: [150, 200, 300, 400, 450, 600, 800, 900, 1200],
                        in: [12, 15, 18, 21, 24, 27, 30, 36, 48],
                    },
                    min: {
                        mm: 140,
                        in: 11,
                    },
                    max: {
                        mm: 1250,
                        in: 49,
                    },
                },
                height: {
                    items: {
                        mm: [900],
                        in: [36],
                    },
                    min: {
                        mm: 600,
                        in: 23,
                    },
                    max: {
                        mm: 1350,
                        in: 49,
                    },
                },
            },
            baseCorner: {
                width: {
                    items: {
                        mm: [150, 200, 300, 400, 450, 600],
                        in: [12, 15, 18, 21, 24],
                    },
                    min: {
                        mm: 140,
                        in: 11,
                    },
                    max: {
                        mm: 650,
                        in: 25,
                    },
                },
                height: {
                    items: {
                        mm: [900],
                        in: [36],
                    },
                    min: {
                        mm: 600,
                        in: 23,
                    },
                    max: {
                        mm: 1250,
                        in: 49,
                    },
                },
            },
            wall: {
                width: {
                    items: {
                        mm: [150, 200, 300, 400, 450, 600, 800, 900, 1200],
                        in: [12, 15, 18, 21, 24, 27, 30, 36, 48],
                    },
                    min: {
                        mm: 140,
                        in: 11,
                    },
                    max: {
                        mm: 1250,
                        in: 49,
                    },
                },
                height: {
                    items: {
                        mm: [700, 900],
                        in: [27, 30],
                    },
                    min: {
                        mm: 140,
                        in: 11,
                    },
                    max: {
                        mm: 1250,
                        in: 49,
                    },
                },
            },
            wallCorner: {
                width: {
                    items: {
                        mm: [280],
                        in: [12],
                    },
                    min: {
                        mm: 140,
                        in: 11,
                    },
                    max: {
                        mm: 300,
                        in: 13,
                    },
                },
                height: {
                    items: {
                        mm: [700, 900],
                        in: [27, 30],
                    },
                    min: {
                        mm: 140,
                        in: 11,
                    },
                    max: {
                        mm: 1250,
                        in: 49,
                    },
                },
            },
            tall: {
                width: {
                    items: {
                        mm: [150, 200, 300, 400, 450, 600, 800, 900],
                        in: [12, 15, 18, 21, 24, 27, 30, 36],
                    },
                    min: {
                        mm: 140,
                        in: 11,
                    },
                    max: {
                        mm: 950,
                        in: 37,
                    },
                },
                height: {
                    items: {
                        mm: [2150],
                        in: [84.6],
                    },
                    min: {
                        mm: 1200,
                        in: 47,
                    },
                    max: {
                        mm: 3000,
                        in: 120,
                    },
                },
            },
            tallCorner: {
                width: {
                    items: {
                        mm: [150, 200, 300, 400, 450, 600],
                        in: [12, 15, 18, 21, 24],
                    },
                    min: {
                        mm: 140,
                        in: 11,
                    },
                    max: {
                        mm: 650,
                        in: 25,
                    },
                },
                height: {
                    items: {
                        mm: [2150],
                        in: [84.6],
                    },
                    min: {
                        mm: 1200,
                        in: 47,
                    },
                    max: {
                        mm: 3000,
                        in: 120,
                    },
                },
            },
        },
    },
    wall: {
        id: 'wall',
        collections: [
            {
                sheet: false,
                name: 'Mono Colors',
                color: {
                    selected: null,
                    type: 'combo',
                    pattern: 'PlasterNaturalPaint',
                    items: [
                        '#FFFFFF',
                        '#F9F3E3',
                        '#F4ECD5',
                        '#F1F2E0',
                        '#DBD7BC',
                        '#D4CBBC',
                        '#958A84',
                        '#9F8F7B',
                        '#F6D2B8',
                        '#E8D6CC',
                        '#3B242C',
                        '#130002',
                        '#410100',
                        '#650000',
                        '#AE0202',
                        '#949391',
                        '#808078',
                        '#636162',
                        '#605D66',
                        '#48464B',
                        '#323232',
                        '#2C2C2C',
                        '#3C3C3C',
                        '#443F43',
                        '#323137',
                        '#79839C',
                        '#CC462B',
                        '#B7C97D',
                        '#010101',
                        '#020200',
                    ],
                },
            },
        ],
    },
    countertop: {
        id: 'countertop',
        collections: [
            {
                sheet: false,
                name: 'Marble/Granite',
                color: {
                    selected: null,
                    type: 'texture',
                    items: [
                        'Marble01',
                        'Marble02',
                        'Marble03',
                        'Marble04',
                        'Marble05',
                        'Marble07',
                        'Marble08',
                        'Marble09',
                        'Marble10',
                        'Marble11',
                        'Marble13',
                        'Marble14',
                        'Marble16',
                        'Marble17',
                        'Marble18',
                        'Marble19',
                        'Marble20',
                        'Marble21',
                        'Marble22',
                        'Marble23',
                        'Marble24',
                        'Marble25',
                        'Marble26',
                        'Marble27',
                        'Marble28',
                        'Marble29',
                        'Marble30',
                        'Marble31',
                        'Marble32',
                        'Marble33',
                        'Marble34',
                        'Marble35',
                        'Marble36',
                        'Marble37',
                        'Marble38',
                        'Marble39',
                        'Marble40',
                        'Marble41',
                        'Marble42',
                        'Marble43',
                        'Marble44',
                        'Marble45',
                        'Marble46',
                        'Marble47',
                        'Marble48',
                        'Marble49',
                        'Marble50',
                        'Marble51',
                        'Marble52',
                        'Marble53',
                        'Marble54',
                        'Marble55',
                        'Marble56',
                        'Marble61',
                        'Marble62',
                        'Marble66',
                        'Marble68',
                        'Marble69',
                        'MarbleBlackAtlantisHoned001',
                        'MarbleBronzoAmaniHoned001',
                        'MarbleCarraraHoned001',
                        'MarbleCipollinoHoned001',
                        'MarbleGreyFlueryHoned001',
                        'MarbleGrigioCollemandinaHoned001',
                        'MarbleGrigioImperialeHoned001',
                        'MarbleGrigioIntensoHoned001',
                        'MarbleNeroBelvedereHoned001',
                        'MarbleSaintLaurentHoned001',
                        'MarbleSirioHoned001',
                        'MarbleSpiderWhiteHoned001',
                        'MarbleStatuarioHoned001',
                        'StoneMarbleCalacatta001',
                        'StoneMarbleCalacatta002',
                        'StoneMarbleCalacatta003',
                        'StoneMarbleCalciteBianca001',
                        'StoneMarbleCalciteBianca002',
                        'StoneMarbleCarrara001',
                        'StoneMarbleCremaValencia001',
                        'StoneMarbleCremaValencia002',
                        'StoneMarbleLyra001',
                        'StoneMarbleLyra002',
                        'StoneMarblePortoroGold001',
                    ],
                },
            },
            {
                sheet: false,
                name: 'Quartz',
                color: {
                    selected: null,
                    type: 'texture',
                    items: [
                        'QuartzPolishedWhite001',
                        'StoneQuartzAlpine001',
                        'StoneQuartzBeige001',
                        'StoneQuartzCobaltBlue001',
                        'StoneQuartzGrey001',
                        'StoneQuartzMetallicOrange001',
                    ],
                },
            },
        ],
    },
    skirting: {
        id: 'skirting',
        collections: [
            {
                sheet: false,
                name: 'Standard',
                color: {
                    selected: null,
                    type: 'texture',
                    items: ['MetalAluminiumScratched'],
                },
            },
        ],
    },
    doors: {
        id: 'doors',
        collections: [
            {
                sheet: false,
                name: 'Standard',
                color: {
                    selected: null,
                    type: 'monoColor',
                    items: ['#FFFFFF', '#FF0000', '#D3D3D3'],
                },
            },
        ],
    },
    windows: {
        id: 'windows',
        collections: [
            {
                sheet: false,
                name: 'Standard',
                color: {
                    selected: null,
                    type: 'monoColor',
                    items: ['#FFFFFF', '#FF0000', '#D3D3D3'],
                },
            },
        ],
    },
    floor: {
        id: 'floor',
        collections: [
            {
                sheet: false,
                name: 'Ceramic Tiles',
                color: {
                    selected: null,
                    type: 'texture',
                    items: [
                        'TilesCeramicSquare003',
                        'TilesCeramicSquare002',
                        'TilesCeramicSquare001',
                        'TilesCeramicSquareLarge001',
                        'TilesLaminateBlackSlateSquare001',
                        'TilesLaminateFlorenceSquare001',
                        'TilesSquareGraphiteSlate001',
                        'TilesSquareGray001',
                        'TilesVinylGreySquare001',
                    ],
                },
            },
            {
                sheet: false,
                name: 'Marble Tiles',
                color: {
                    selected: null,
                    type: 'texture',
                    items: [
                        'TilesMarbleBronzoAmaniHoned001',
                        'TilesMarbleCappuccinoHoned001',
                        'TilesMarbleEmpressGreenHoned001',
                        'TilesMarbleGreyFlueryHoned001',
                        'TilesMarbleGrigioCollemandinaHoned001',
                        'TilesMarbleGrigioImperialeHoned001',
                        'TilesMarbleRedLuxuryHoned001',
                        'TilesMarbleRojoAlicanteHoned001',
                        'TilesMarbleSaintLaurentHoned001',
                        'TilesMarbleSanPedroHoned001',
                        'TilesMarbleSirioHoned001',
                        'TilesMarbleSpanishGoldHoned001',
                        'TilesMarbleSpiderWhiteHoned001',
                        'TilesMarbleStatuarioHoned001',
                    ],
                },
            },
        ],
    },
};
