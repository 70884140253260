import { Mesh, MeshBuilder, Vector3 } from '@babylonjs/core';
import { Constants } from '../../../../Tools/constants';
import { Utilities } from '../../../../Tools/utilities';
import { BuildingBlock } from '../../../BuildingBlocks/BuildingBlock';
import { CabinetStructure } from '../../../BuildingBlocks/CabinetStructure';
import { Countertop } from '../../../BuildingBlocks/Countertop';
import { DoorBlock } from '../../../BuildingBlocks/DoorBlock';
import { Skirting } from '../../../BuildingBlocks/Skirting';
import { Model } from '../../Model';
import { ResizingManager } from './ResizingManager';

export class CornerSection extends Model {
    constructor() {
        super();
        this.side = null;
        this.parts = {};
        this.parentCabinet = null;
        this.resizingManager = this.registerComponent(ResizingManager);
    }

    async setModel() {
        const result = await this.import('baseCornerCabinetBlind.babylon');
        this.meshComponent.setMesh(Utilities.getElementByMeshName('BaseCornerCabinetBlind', result.meshes));
        this.meshComponent.setName('BaseCornerCabinetBlind');
        this.setParts();
    }

    placeHandle(parentMesh) {
        if (!this.sectionInfo.isFiller) {
            const handleClone = Mesh.MergeMeshes([this.editor.models3D.handle.meshComponent.getMesh().clone()], true);
            handleClone.position = this.parts.cornerCabinetLHF.handleLHF.mesh.position;
            handleClone.parent = parentMesh;
            this.buildingBlocks.handleClone = handleClone;
        }
    }

    mergeModel() {
        const cornerCabinetMesh = MeshBuilder.CreateBox('base-cabinet-model', { width: 0.1, depth: 0.1, height: 0.1 });
        cornerCabinetMesh.visibility = 0;

        const cornerCabinet = { mesh: cornerCabinetMesh, buildingBlocks: {} };

        this.placeHandle(cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.innerStructure = this.mergeInnerTextureBlock(cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.doorBlock = this.mergeOuterTextureLHF(cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.fillerBlock = this.mergeFillerLHF(cornerCabinet.mesh);

        cornerCabinet.buildingBlocks.countertopBlock = this.mergeCounterTopLHF(cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.frontPanelBlock = this.mergeFrontPanelBlockLHF(cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.skirtingBlock = this.mergeSkirtingBlockLHF(cornerCabinet.mesh);

        cornerCabinet.mesh.rotation = Vector3.Zero();
        if (
            !(
                (this.parentCabinet.cornerCabinetInfo?.cornerIndex === Constants.corner.CORNER_A &&
                    this.parentCabinet.type !== Constants.cabinet.type.ISLAND) ||
                (this.parentCabinet.type === Constants.cabinet.type.ISLAND &&
                    this.parentCabinet.peninsulaData?.connectedCabinet?.peninsulaConnection.edgeIndex === 'secondPoint')
            )
        ) {
            cornerCabinet.mesh.scaling.x = -1;
        }
        this.meshComponent.getMesh().isVisible = 0;
        return cornerCabinet;
    }

    mergeInnerTextureBlock(parentMesh) {
        const meshes = [];
        Object.keys(this.parts.cabinet).forEach((key) => {
            meshes.push(this.parts.cabinet[key].mesh);
        });

        if (this.parentCabinet.type === Constants.cabinet.type.ISLAND) {
            meshes.push(this.parts.cornerCabinetLHF.islandLHF.panelIslandLHF.mesh);
        }
        const innerStructure = this.editor.addObject(CabinetStructure);
        innerStructure.setParentSection(this.currentParentSection);
        innerStructure.create(meshes, 'cabinets');
        innerStructure.meshComponent.setParent(parentMesh);
        return innerStructure;
    }

    mergeOuterTextureLHF(parentMesh) {
        const meshes = [
            this.parts.cornerCabinetLHF.doorLHF.mesh,
            //  this.parts.cornerCabinetLHF.fillerLHF.outerFillerCornerLHF1.mesh,
        ];

        const outerTextureBlock = this.editor.addObject(DoorBlock);
        outerTextureBlock.setParentSection(this.currentParentSection);
        outerTextureBlock.enableSelection();
        outerTextureBlock.create(meshes, 'cabinets');

        outerTextureBlock.meshComponent.setParent(parentMesh);
        return outerTextureBlock;
    }

    mergeFillerLHF(parentMesh) {
        const meshes = [this.parts.cornerCabinetLHF.fillerLHF.outerFillerCornerLHF1.mesh];

        const outerTextureBlock = this.editor.addObject(DoorBlock);
        outerTextureBlock.setParentSection(this.currentParentSection);
        outerTextureBlock.enableSelection();
        outerTextureBlock.create(meshes, 'cabinets');

        outerTextureBlock.meshComponent.setParent(parentMesh);
        return outerTextureBlock;
    }

    mergeCounterTopLHF(parentMesh) {
        const meshes = [
            this.parts.cornerCabinetLHF.countertopLHF.countertopLHF1.mesh,
            this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF2.mesh,
        ];
        if (!this.parentCabinet.peninsulaData?.connectedCabinet) {
            meshes.push(this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF1.mesh);
        } else {
            this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF1.mesh.isVisible = false;
        }

        const countertopBlock = this.editor.addObject(BuildingBlock);
        countertopBlock.setParentSection(this.currentParentSection);
        countertopBlock.enableSelection();
        countertopBlock.create(meshes, 'countertop');
        countertopBlock.meshComponent.setParent(parentMesh);
        return countertopBlock;
    }

    mergeFrontPanelBlockLHF(parentMesh) {
        const meshes = [this.parts.cornerCabinetLHF.panelFrontLHF.mesh];
        const frontPanelBlock = this.editor.addObject(BuildingBlock);
        frontPanelBlock.setParentSection(this.currentParentSection);
        frontPanelBlock.create(meshes, 'cabinets');
        frontPanelBlock.meshComponent.setParent(parentMesh);
        return frontPanelBlock;
    }

    mergeSkirtingBlockLHF(parentMesh) {
        const meshes = [
            this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF1.mesh,
            this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF2.mesh,
            this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF3.mesh,
            this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF4.mesh,
            this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF5.mesh,
        ];
        const skirtingBlock = this.editor.addObject(Skirting);
        skirtingBlock.setParentSection(this.currentParentSection);
        skirtingBlock.enableSelection();
        skirtingBlock.create(meshes, 'skirting');
        skirtingBlock.meshComponent.setParent(parentMesh);
        return skirtingBlock;
    }

    resize(dimensions) {
        this.resizingManager.parts = this.parts;
        this.resizingManager.resizeWidth(dimensions);
        this.resizingManager.resizeDepth(dimensions);
        this.resizingManager.resizeHeight(dimensions.height + 0.1);
    }

    getResizingDimensions(sectionWidth = null) {
        return {
            width:
                parseFloat(this.parentCabinet.depth) +
                this.parentCabinet.getCornerCabinetDoor() +
                Constants.STANDARD_FILLER_SIZE[this.editor.unit],
            depth: parseFloat(this.parentCabinet.depth),
            height: parseFloat(this.parentCabinet.height),
            doorWidth: parseFloat(this.parentCabinet.getCornerCabinetDoor()),
            connectedCabinetDepth: parseFloat(this.parentCabinet.depth),
        };
    }
}
