var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper",
    {
      staticClass: "px-0 px-sm-5",
      attrs: { "non-linear": "" },
      model: {
        value: _vm.step,
        callback: function ($$v) {
          _vm.step = $$v
        },
        expression: "step",
      },
    },
    [
      _c(
        "v-stepper-items",
        [
          _c("DrawRoom"),
          _c("DrawCabinets"),
          _c("AddAppliances"),
          _c("Generated"),
        ],
        1
      ),
      _c("StepperHeader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }