<template>
    <v-stepper-content step="1" class="pa-2">
        <!-- Insert Wall Dimension -->
        <v-row v-if="!isWallsClosed">
            <v-col class="pa-0" cols="8" md="9">
                <!-- Wall Dimension Input -->
                <v-card elevation="0" color="rounded-lg">
                    <v-card-text>
                        <v-text-field
                            class="white"
                            v-model="typedWallDim"
                            :disabled="disableInput"
                            @click:clear="resetTyping"
                            :hint="
                                disableInput
                                    ? 'Click once on the canvas to activate wall inputs'
                                    : null
                            "
                            label="Current Wall Dimension"
                            type="number"
                            :suffix="selectedUnit"
                            persistent-hint
                            single-line
                            outlined
                            clearable
                            dense
                            >Wall Dimension
                        </v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
            <StepsButtons :stepNumber="2" class="py-0"></StepsButtons>
        </v-row>

        <!-- Add fixtures -->
        <v-row v-if="isWallsClosed && !selectedFixture" class="pa-2">
            <v-col cols="8" md="9">
                <v-row
                    class="flex-wrap flex-row blue-grey lighten-4 rounded-lg"
                >
                    <v-slide-group show-arrows class="mx-auto">
                        <v-slide-item
                            v-for="(fixture, key) in fixtures"
                            :key="key"
                        >
                            <v-hover v-slot:default="{ hover }">
                                <v-card
                                    :elevation="hover ? 16 : 2"
                                    class="pa-1 ma-2"
                                    :width="
                                        $vuetify.breakpoint.smAndUp ? 150 : 70
                                    "
                                    @click="
                                        (openDialog = true),
                                            setmodalFixture(fixture)
                                    "
                                >
                                    <v-card-text class="py-2 text-center">
                                        <v-icon class="mr-2">{{
                                            fixture.icon
                                        }}</v-icon>
                                    </v-card-text>

                                    <v-card-subtitle
                                        class="text-center py-1"
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        >{{ fixture.name }}</v-card-subtitle
                                    >
                                </v-card>
                            </v-hover>
                        </v-slide-item>
                    </v-slide-group>
                </v-row>
            </v-col>

            <v-dialog
                v-model="openDialog"
                max-width="800px"
                :fullscreen="$vuetify.breakpoint.xsOnly"
            >
                <FixturesDialog v-if="openDialog"></FixturesDialog>
            </v-dialog>

            <StepsButtons :stepNumber="2"></StepsButtons>
        </v-row>

        <!-- Edit fixture -->
        <v-container v-if="isWallsClosed && selectedFixture && !openDialog">
            <FixtureEdit></FixtureEdit>
        </v-container>
    </v-stepper-content>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StepsButtons from './StepsButtons';
import FixturesDialog from './FixturesDialog';
import FixtureEdit from './FixtureEdit';

export default {
    components: {
        StepsButtons,
        FixturesDialog,
        FixtureEdit,
    },
    computed: {
        ...mapState('fixtures', [
            'fixtures',
            'modalFixture',
            'selectedFixture',
        ]),
        ...mapGetters('core', ['selectedUnit', 'editorEngine']),
        isWallsClosed() {
            return this.editorEngine.floorplan.isClosed;
        },
        disableInput() {
            const corners = this.editorEngine.floorplan.corners;
            if (this.isWallsClosed || !corners.length) {
                return true;
            }
            return false;
        },
        // Input for wall dimension
        typedWallDim: {
            set(typedWallDim) {
                this.$store.commit('fixtures/setTypedWallDim', typedWallDim);
            },
            get() {
                return this.$store.state.fixtures.typedWallDim;
            },
        },
        openDialog: {
            set(openDialog) {
                this.$store.commit('fixtures/openDialog', openDialog);
            },
            get() {
                return this.$store.state.fixtures.openDialog;
            },
        },
    },
    methods: {
        resetTyping() {
            this.$store.commit('fixtures/resetTyping');
        },
        setmodalFixture(fixture) {
            this.$store.commit('fixtures/setmodalFixture', fixture);
        },
    },
    watch: {
        disableInput: function () {},
    },
};
</script>
