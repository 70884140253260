export let TexturePool = {
    // collectionType: {
    //     //can be a patterned texture
    //     diffuseTexture: {
    //         baseColor: {
    //             surface: {
    //                 gloss: {
    //                     microsurface: {
    //                         clearCoatOn: {},
    //                         clearCoatOff: {},
    //                     },
    //                 },
    //                 matt: {},
    //             },
    //         },
    //     },
    // },
};

export function clear() {
    TexturePool = {};
}
