<template>
    <div>
        <!-- Measurement Choice Dialog -->
        <v-dialog v-model="startedDesigning" max-width="550" persistent>
            <v-card>
                <v-card-title class="text-h5 justify-center">
                    Select Measurement Unit
                </v-card-title>

                <v-card-text class="text-center px-2">
                    <v-btn-toggle
                        v-model="unit"
                        mandatory
                        tile
                        color="primary"
                        group
                        class="justify-center flex-wrap"
                    >
                        <v-btn value="mm" :disabled="costingUnit && 'mm' !== costingUnit">
                            <v-icon left>{{ mdiTapeMeasure }}</v-icon>
                            <span>Millimeters (mm)</span>
                        </v-btn>

                        <v-btn value="in" :disabled="costingUnit && 'in' !== costingUnit">
                            <v-icon left>{{ mdiTapeMeasure }}</v-icon>
                            <span>Inches (in)</span>
                        </v-btn>
                    </v-btn-toggle>

                    <v-slide-group
                        v-model="layoutSelected"
                        class="py-4"
                        mandatory
                        show-arrows="always"
                    >
                        <v-slide-item
                            v-for="layout in layouts"
                            :key="layout"
                            :value="layout"
                            v-slot="{ active, toggle }"
                        >
                            <div>
                                <v-card
                                    :color="active ? 'blue lighten-4' : 'white'"
                                    class="ma-4 pa-6"
                                    height="100"
                                    width="100"
                                    @click="toggle"
                                >
                                    <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-scale-transition>
                                            <v-img
                                                :src="
                                                    require(`@/assets/icons/room-layouts/${layout}.png`)
                                                "
                                            ></v-img>
                                        </v-scale-transition>
                                    </v-row>
                                </v-card>
                                <p>{{ layout }}</p>
                            </div>
                        </v-slide-item>
                    </v-slide-group>
                </v-card-text>

                <v-card-actions class="justify-center">
                    <v-btn
                        color="green darken-1 white--text"
                        @click="startDesigning"
                        width="100%"
                    >
                        Start Designing
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Project Loader -->
        <v-dialog v-model="isProjectLoading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex';
import { mdiTapeMeasure } from '@mdi/js';

export default {
    data() {
        return {
            mdiTapeMeasure: mdiTapeMeasure,
            layouts: ['rect', 'L1', 'custom'],
            layoutSelected: 'rect',
        };
    },
    computed: {
        ...mapGetters('core', [
            'selectedUnit',
            'startedDesigning',
            'isProjectLoading',
        ]),
        ...mapState('business', ['costing']),
        unit: {
            get() {
                return this.selectedUnit;
            },
            set(value) {
                this.setUnit(value);
            },
        },
        costingUnit() {
            const unit = this.costing?.business?.business_costing?.unit.value;

            return unit;
        }
    },
    methods: {
        ...mapMutations('core', ['setUnit', 'setStartedDesigning']),
        ...mapActions('core', ['loadProjectAction']),
        startDesigning() {
            console.log('start desigining');
            this.setStartedDesigning(true);

            if (this.layoutSelected !== 'custom') {
                const layouts = {
                    rect: [
                        { x: 2.787, y: -2.875 },
                        { x: -1.213, y: -2.875 },
                        { x: -1.213, y: 1.125 },
                        { x: 2.787, y: 1.125 },
                    ],
                    L1: [
                        { x: 2.573, y: -3.077 },
                        { x: -1.427, y: -3.077 },
                        { x: -1.427, y: 0.923 },
                        { x: 0.573, y: 0.923 },
                        { x: 0.573, y: -1.077 },
                        { x: 2.573, y: -1.077 },
                    ],
                };
                const project = {
                    corners: layouts[`${this.layoutSelected}`],
                    fixtures: [],
                    cabinets: null,
                    appliances: null,
                    canvasLoaded: true,
                    startedDesigning: true,
                    step: 1,
                    unit: this.unit,
                };
                this.loadProjectAction({ project });
            }
        },
    },
};
</script>

<style scoped>
.v-slide-group__content {
    justify-content: center;
}
</style>
