import { BaseUnit } from '../../KitchenObjects/Cabinets/BaseUnit';
import { RoomObject } from '../../KitchenObjects/Room/RoomObject';
import { Utilities } from '../../Tools/utilities';

export class LayoutDetector {
    layouts = [];
    constructor(editor) {
        this.editor = editor;
    }

    extractLayouts() {
        const roomObject = this.editor.getObjectByType(RoomObject);
        const slotManager = roomObject.slotManager;
        const baseSlots = slotManager.getAllSlots(['base', 'tall']);
        const wallSlots = slotManager.getAllSlots('wall');
        for (let index = 0; index < roomObject.walls.length; index++) {
            this.calculateLayouts(baseSlots['wall' + roomObject.walls[index].id], wallSlots['wall' + roomObject.walls[index].id]);
        }
    }

    calculateLayouts(baseSlots, wallSlots) {
        for (let wallIndex = 0; wallIndex < wallSlots.length; wallIndex++) {
            for (let index = 0; index < baseSlots.length; index++) {
                if (
                    baseSlots[index].object instanceof BaseUnit &&
                    wallSlots[wallIndex].object instanceof BaseUnit &&
                    Utilities.areSegmentsOverlapping(
                        baseSlots[index].object.getEdgesOnWall(),
                        wallSlots[wallIndex].object.getEdgesOnWall()
                    )
                ) {
                    wallSlots[wallIndex].object.baseCabinetUnderneath = baseSlots[index].object;
                    baseSlots[index].object.wallCabinetOnTop = wallSlots[wallIndex].object;
                    this.layouts['layout' + this.layouts.length++] = [baseSlots[index], wallSlots[wallIndex]];
                }
            }
        }
    }
}
