import { VertexBuffer } from '@babylonjs/core';

export class WallsUpdateWidget {
    constructor(wall, angleBetweenWalls, wallThickness) {
        this.wall = wall;
        this.wallThickness = wallThickness;
        this.updateAngle(angleBetweenWalls);
        this.upperLeftVertexX = 6;
        this.upperRightVertexX = 9;
        this.defaultLeftXPosition = -1;
        this.defaultRightXPosition = -1;
    }

    updateAngle(angleBetweenWalls) {
        this.angleBetweenWalls = Math.abs(angleBetweenWalls);
        this.rotationAngle = 360 - (360 - this.angleBetweenWalls) / 2 - 90 - this.angleBetweenWalls;
        this.rotationAngle = (this.rotationAngle * Math.PI) / 180;
        this.coordinateOffset = this.wallThickness * Math.tan(this.rotationAngle) + 0.001;
    }

    updateRightVertexPosition() {
        let positions = this.wall.getVerticesData(VertexBuffer.PositionKind);

        if (this.defaultLeftXPosition === -1) {
            this.defaultLeftXPosition = positions[this.upperLeftVertexX];
        }

        positions[this.upperLeftVertexX] = this.defaultLeftXPosition;
        positions[this.upperLeftVertexX] += this.coordinateOffset / this.wall.scaling.x;
        this.wall.updateVerticesData(VertexBuffer.PositionKind, positions);
    }

    updateLeftVertexPosition() {
        let positions = this.wall.getVerticesData(VertexBuffer.PositionKind);

        if (this.defaultRightXPosition === -1) {
            this.defaultRightXPosition = positions[this.upperRightVertexX];
        }

        positions[this.upperRightVertexX] = this.defaultRightXPosition;
        positions[this.upperRightVertexX] -= this.coordinateOffset / this.wall.scaling.x;
        this.wall.updateVerticesData(VertexBuffer.PositionKind, positions);
    }
}
