import { MeshBuilder, Vector3 } from '@babylonjs/core';
import { Constants } from '../../../../Tools/constants';
import { Utilities } from '../../../../Tools/utilities';
import { Component } from '../../../../Components/Base/Component';

export class ResizingManager extends Component {
    constructor() {
        super();
    }

    resizeDoorPanel() {
        let doorPanelDimensions = Utilities.getMeshDimensions(this.object.parts.doorPanel);
        let topFrameDimensions = Utilities.getMeshDimensions(this.object.parts.frameTop);

        this.object.parts.doorPanel.scaling.x = (this.object.width - 0.05 * 2) / doorPanelDimensions.width;
        this.object.parts.doorPanel.scaling.y = (this.object.height - topFrameDimensions.height + 0.0975 / 2) / doorPanelDimensions.height;

        this.object.doorPanelWidth = doorPanelDimensions.width * this.object.parts.doorPanel.scaling.x;
        this.object.doorPanelHeight = doorPanelDimensions.height * this.object.parts.doorPanel.scaling.y;

        this.object.parts.lockInsideLeft.visibility = 0;
        this.object.parts.handleOutsideLeft.visibility = 0;

        if (this.object.width >= Constants.models3D.door.DOUBLE_DOOR_MIN_SIZE) {
            this.handleDoubleDoorPanels();
        }
    }

    handleDoubleDoorPanels() {
        const doorPanelPosition = this.object.parts.doorPanel.position;
        this.object.parts.lockInsideLeft.visibility = 1;
        this.object.parts.handleOutsideLeft.visibility = 1;
        this.object.parts.doorPanel.visibility = 0;

        const doorPanels = this.buildDoublePanels();
        let panelPosition = new Vector3(doorPanelPosition.x, doorPanelPosition.y, doorPanelPosition.z - Constants.models3D.door.FRAME_DEPTH);
        doorPanels.left.position = panelPosition.clone();
        doorPanels.right.position = panelPosition.clone();
        doorPanels.right.position.x += this.object.doorPanelWidth / 4;
        doorPanels.left.position.x -= this.object.doorPanelWidth / 4;

        doorPanels.left.setParent(this.object.parts.doorPanel);
        doorPanels.right.setParent(this.object.parts.doorPanel);

        if (this.object.subType === Constants.fixture.subType.SLIDING) {
            this.object.parts.slidingDoorHandleLeft.setParent(this.object.parts.slidingDoorHandle);
            this.object.parts.slidingDoorHandle.position.x -= this.object.doorPanelWidth / 2;
        } else {
            this.object.parts.lockInsideLeft.setParent(this.object.parts.lockInside);
            this.object.parts.lockInside.position.x -= this.object.doorPanelWidth / 2;
            this.object.parts.handleOutsideLeft.setParent(this.object.parts.handleOutside);
            this.object.parts.handleOutside.position.x -= this.object.doorPanelWidth / 2;
        }
    }

    buildDoublePanels() {
        return {
            left: MeshBuilder.CreateBox(
                'leftDoorPanel',
                { height: this.object.doorPanelHeight, width: this.object.doorPanelWidth / 2, depth: 0.04 },
                this.object.editor.sceneComponent.get()
            ),
            right: MeshBuilder.CreateBox(
                'rightDoorPanel',
                { height: this.object.doorPanelHeight, width: this.object.doorPanelWidth / 2, depth: 0.04 },
                this.object.editor.sceneComponent.get()
            ),
        };
    }

    resizeVerticalFrames(frame) {
        const cornerDimensions = Utilities.getMeshDimensions(this.object.parts.frameCornerLeft);
        const dimensions = Utilities.getMeshDimensions(this.object.parts[frame]);
        this.object.parts[frame].scaling.y = (this.object.height - cornerDimensions.height + 0.0975 / 2) / dimensions.height;

        let movement = (Constants.models3D.door.BASE_WIDTH - this.object.doorPanelWidth) / 2;
        let scaleIdicator = -1;
        if (this.object.doorPanelWidth > Constants.models3D.door.BASE_WIDTH) {
            movement = (this.object.doorPanelWidth - Constants.models3D.door.BASE_WIDTH) / 2;
            scaleIdicator = 1;
        }

        if (frame === Constants.models3D.door.FRAME_LEFT) {
            this.object.parts[frame].position.x -= movement * scaleIdicator;
            this.object.parts.frameCornerLeft.position.x -= movement * scaleIdicator;
        } else {
            this.object.parts[frame].position.x += movement * scaleIdicator;
            this.object.parts.frameCornerRight.position.x += movement * scaleIdicator;

            this.object.parts.lockInside.position.x += movement * scaleIdicator;
            this.object.parts.handleOutside.position.x += movement * scaleIdicator;
            this.object.parts.slidingDoorHandle.position.x += movement * scaleIdicator;
        }
    }

    resizeTopFrame() {
        const dimensions = Utilities.getMeshDimensions(this.object.parts.frameTop);
        const cornerDimensions = Utilities.getMeshDimensions(this.object.parts.frameCornerLeft);
        this.object.parts.frameTop.scaling.x = (this.object.width - 2 * cornerDimensions.width + 2 * 0.0169) / dimensions.width;

        let movement = (Constants.models3D.door.BASE_HEIGHT - this.object.doorPanelHeight) / 2;
        let scaleIdicator = -1;

        if (this.object.doorPanelWidth > Constants.models3D.window.BASE_HEIGHT) {
            movement = (this.object.doorPanelHeight - Constants.models3D.door.BASE_HEIGHT) / 2;
            scaleIdicator = 1;
        }

        this.object.parts.frameTop.position.y += movement * scaleIdicator;
        this.object.parts.frameCornerLeft.position.y += movement * scaleIdicator;
        this.object.parts.frameCornerRight.position.y += movement * scaleIdicator;
    }

    resizeBalconyGlass() {
        const firstDoorGlass = this.object.balconyParts.innerDoor1.glassLeft;
        const secondDoorGlass = this.object.balconyParts.innerDoor2.glassLeft2;

        const glassDimensions = Utilities.getMeshDimensions(firstDoorGlass);

        const firstDoorInnerFrameRightDimensions = Utilities.getMeshDimensions(this.object.balconyParts.innerDoor1.innerFrameRight);
        const outerFrameLeftDimensions = Utilities.getMeshDimensions(this.object.balconyParts.outerFrame.outerFrameRight);
        const firstDoorInnerFrameTopDimensions = Utilities.getMeshDimensions(this.object.balconyParts.innerDoor1.innerFrameTop);
        const outerFrameTopDimensions = Utilities.getMeshDimensions(this.object.balconyParts.outerFrame.outerFrameTop);

        const xAxisScaling = (this.object.width - (outerFrameLeftDimensions.width * 2 + firstDoorInnerFrameRightDimensions.width * 3)) / 2;
        const yAxisScaling = this.object.height - (firstDoorInnerFrameTopDimensions.height * 2 + outerFrameTopDimensions.height);

        firstDoorGlass.scaling.x = xAxisScaling / glassDimensions.width;
        secondDoorGlass.scaling.x = xAxisScaling / glassDimensions.width;

        firstDoorGlass.scaling.y = yAxisScaling / glassDimensions.height;
        secondDoorGlass.scaling.y = yAxisScaling / glassDimensions.height;

        this.object.balconyGlassWidth = firstDoorGlass.scaling.x * glassDimensions.width;
        this.object.balconyGlassHeight = firstDoorGlass.scaling.y * glassDimensions.height;
    }

    resizeBalconyVertically() {
        const firstDoorGlass = this.object.balconyParts.innerDoor1.glassLeft;
        const firstDoorGlassDimensions = Utilities.getMeshDimensions(firstDoorGlass);

        const outerFrameLeft = this.object.balconyParts.outerFrame.outerFrameLeft;
        const outerFrameRight = this.object.balconyParts.outerFrame.outerFrameRight;

        const outerCornerDimensions = Utilities.getMeshDimensions(this.object.balconyParts.outerFrame.outerCornerTopLeft);
        const dimensions = Utilities.getMeshDimensions(outerFrameLeft);

        outerFrameLeft.scaling.y = (this.object.height - outerCornerDimensions.height + 0.02) / dimensions.height;
        outerFrameRight.scaling.y = (this.object.height - outerCornerDimensions.height + 0.02) / dimensions.height;

        this.object.balconyParts.innerDoor1.innerFrameLeft.scaling.y = (this.object.height - outerCornerDimensions.height + 0.02) / dimensions.height;
        this.object.balconyParts.innerDoor2.innerFrameLeft2.scaling.y =
            (this.object.height - outerCornerDimensions.height + 0.02) / dimensions.height;

        this.object.balconyParts.innerDoor1.innerFrameRight.scaling.y =
            (this.object.height - outerCornerDimensions.height + 0.02) / dimensions.height;
        this.object.balconyParts.innerDoor2.innerFrameRight2.scaling.y =
            (this.object.height - outerCornerDimensions.height + 0.02) / dimensions.height;

        let movement = firstDoorGlassDimensions.width - this.object.balconyGlassWidth;
        let scaleIdicator = -1;

        if (this.object.balconyGlassWidth > firstDoorGlassDimensions.width) {
            movement = this.object.balconyGlassWidth - firstDoorGlassDimensions.width;
            scaleIdicator = 1;
        }

        outerFrameLeft.position.x += movement * scaleIdicator;
        this.object.balconyParts.outerFrame.outerCornerTopLeft.position.x -= movement * scaleIdicator;

        outerFrameRight.position.x -= movement * scaleIdicator;
        this.object.balconyParts.outerFrame.outerCornerTopRight.position.x += movement * scaleIdicator;

        this.object.balconyParts.innerDoor2.innerFrameLeft2.position.x += movement * scaleIdicator;

        this.object.balconyParts.innerDoor1.innerFrameCornerTopLeft.position.x += movement * scaleIdicator;
        this.object.balconyParts.innerDoor2.innerFrameCornerTopLeft2.position.x += movement * scaleIdicator;

        this.object.balconyParts.innerDoor1.innerFrameCornerTopRight.position.x -= movement * scaleIdicator;
        this.object.balconyParts.innerDoor2.innerFrameCornerTopRight2.position.x += movement * scaleIdicator;

        this.object.balconyParts.innerDoor1.innerFrameRight.position.x -= movement * scaleIdicator;

        this.object.balconyParts.innerDoor1.innerFrameCornerBottomRight.position.x -= movement * scaleIdicator;
        this.object.balconyParts.innerDoor1.innerFrameCornerBottomLeft.position.x -= movement * scaleIdicator;

        this.object.balconyParts.innerDoor1.innerFrameCornerTopLeft.position.x -= movement * scaleIdicator;
        this.object.balconyParts.innerDoor2.innerFrameCornerTopRight2.position.x -= movement * scaleIdicator;

        this.object.balconyParts.innerDoor1.innerFrameCornerBottomLeft.position.x += movement * scaleIdicator;
        this.object.balconyParts.innerDoor2.innerFrameCornerBottomLeft2.position.x += movement * scaleIdicator;
    }

    resizeBalconyHorizontally() {
        const outerFrameTop = this.object.balconyParts.outerFrame.outerFrameTop;
        const outerFrameTopDimensions = Utilities.getMeshDimensions(outerFrameTop);
        const outerCornerDimensions = Utilities.getMeshDimensions(this.object.balconyParts.outerFrame.outerCornerTopLeft);
        const innerCornerDimensions = Utilities.getMeshDimensions(this.object.balconyParts.innerDoor1.innerFrameCornerTopLeft);
        const innerFrameDimensions = Utilities.getMeshDimensions(this.object.balconyParts.innerDoor1.innerFrameTop);

        outerFrameTop.scaling.x = (this.object.width - outerCornerDimensions.width * 2) / outerFrameTopDimensions.width;

        this.object.balconyParts.innerDoor1.innerFrameTop.scaling.x =
            (this.object.width - outerCornerDimensions.width * 2 - innerCornerDimensions.width * 2) / 2 / innerFrameDimensions.width;
        this.object.balconyParts.innerDoor1.innerFrameBottom.scaling.x =
            (this.object.width - outerCornerDimensions.width * 2 - innerCornerDimensions.width * 2) / 2 / innerFrameDimensions.width;
        this.object.balconyParts.innerDoor2.innerFrameTop2.scaling.x =
            (this.object.width - outerCornerDimensions.width * 2 - innerCornerDimensions.width * 2) / 2 / innerFrameDimensions.width;
        this.object.balconyParts.innerDoor2.innerFrameBottom2.scaling.x =
            (this.object.width - outerCornerDimensions.width * 2 - innerCornerDimensions.width * 2) / 2 / innerFrameDimensions.width;

        const firstDoorGlass = this.object.balconyParts.innerDoor1.glassLeft;
        const firstDoorGlassDimensions = Utilities.getMeshDimensions(firstDoorGlass);

        let movement = firstDoorGlassDimensions.height - this.object.balconyGlassHeight;
        let scaleIdicator = -1;

        if (this.object.balconyGlassHeight > firstDoorGlassDimensions.height) {
            movement = this.object.balconyGlassHeight - firstDoorGlassDimensions.height;
            scaleIdicator = 1;
        }
        this.object.balconyParts.outerFrame.outerCornerTopLeft.position.y += (movement * scaleIdicator) / 2;
        this.object.balconyParts.outerFrame.outerCornerTopRight.position.y += (movement * scaleIdicator) / 2;
        outerFrameTop.position.y += (movement * scaleIdicator) / 2;

        this.object.balconyParts.innerDoor1.innerFrameCornerTopLeft.position.y += (movement * scaleIdicator) / 2;
        this.object.balconyParts.innerDoor1.innerFrameCornerTopRight.position.y += (movement * scaleIdicator) / 2;

        this.object.balconyParts.innerDoor1.innerFrameCornerBottomLeft.position.y -= (movement * scaleIdicator) / 2;
        this.object.balconyParts.innerDoor1.innerFrameCornerBottomRight.position.y -= (movement * scaleIdicator) / 2;

        this.object.balconyParts.innerDoor2.innerFrameCornerTopLeft2.position.y += (movement * scaleIdicator) / 2;
        this.object.balconyParts.innerDoor2.innerFrameCornerTopRight2.position.y += (movement * scaleIdicator) / 2;

        this.object.balconyParts.innerDoor2.innerFrameCornerBottomLeft2.position.y -= (movement * scaleIdicator) / 2;
        this.object.balconyParts.innerDoor2.innerFrameCornerBottomRight2.position.y -= (movement * scaleIdicator) / 2;

        this.object.balconyParts.innerDoor2.innerFrameTop2.position.y += (movement * scaleIdicator) / 2;
        this.object.balconyParts.innerDoor1.innerFrameTop.position.y += (movement * scaleIdicator) / 2;

        this.object.balconyParts.innerDoor2.innerFrameBottom2.position.y -= (movement * scaleIdicator) / 2;
        this.object.balconyParts.innerDoor1.innerFrameBottom.position.y -= (movement * scaleIdicator) / 2;
    }
}
