import store from '@/store/index.js';
import { Vector2 } from '@babylonjs/core';

export const MM_STEP = 0.001;
export const INCH_STEP = 0.0254;
export const ONE_32 = 0.03125;
export const INCH_STEP32 = INCH_STEP * ONE_32;
export const CORNER_POINT_BOX_SIZE = 0.15;
export const INCH_IN_M = 1.0 / INCH_STEP;

export const inch2BjsUnit = (value) => {
    return value * INCH_STEP;
};

export const mm2BjsUnit = (value) => {
    return value * MM_STEP;
};

export const bjsUnit2mm = (value) => {
    return value * 1000;
};

export const bjsUnit2inch = (value) => {
    return value * INCH_IN_M;
};

export const bjsUnitAsSelectedUnit = (value) => {
    return store.getters['core/selectedUnit'] == 'mm' ? bjsUnit2mm(value) : bjsUnit2inch(value);
};

export const unitAsBjsUnit = (value) => {
    return store.getters['core/selectedUnit'] == 'mm' ? mm2BjsUnit(value) : inch2BjsUnit(value);
};

export const roundRect = (x, y, width, height, radius, context) => {
    let r = x + width;
    let b = y + height;

    context.beginPath();
    context.moveTo(x + radius, y);
    context.lineTo(r - radius, y);
    context.quadraticCurveTo(r, y, r, y + radius);
    context.lineTo(r, y + height - radius);
    context.quadraticCurveTo(r, b, r - radius, b);
    context.lineTo(x + radius, b);
    context.quadraticCurveTo(x, b, x, b - radius);
    context.lineTo(x, y + radius);
    context.quadraticCurveTo(x, y, x + radius, y);
    context.fill();
};

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
export const line_intersect = (a, b) => {
    let x1 = a[0],
        y1 = a[1],
        x2 = a[2],
        y2 = a[3],
        x3 = b[0],
        y3 = b[1],
        x4 = b[2],
        y4 = b[3];
    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false;
    }
    let denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

    // Lines are parallel
    if (denominator === 0) {
        return false;
    }

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false;
    }

    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1);
    let y = y1 + ua * (y2 - y1);
    return new Vector2(x, y);
};

//https://stackoverflow.com/questions/22521982/check-if-point-is-inside-a-polygon
// ray-casting algorithm based on
// http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
export const isInside = (point, vs) => {
    var x = point[0],
        y = point[1];
    var inside = false;

    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0],
            yi = vs[i][1];
        var xj = vs[j][0],
            yj = vs[j][1];

        var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
};

// Excerpt from: https://github.com/Animatron/player/blob/master/anm.collisions.js
export const edgeTest = (p1, p2, p3, r2) => {
    var rot = [-(p2[1] - p1[1]), p2[0] - p1[0]];

    var ref = rot[0] * (p3[0] - p1[0]) + rot[1] * (p3[1] - p1[1]) >= 0;

    for (var i = 0, il = r2.length; i < il; i += 2) {
        if (rot[0] * (r2[i] - p1[0]) + rot[1] * (r2[i + 1] - p1[1]) >= 0 === ref) {
            return false;
        }
    }

    return true;
};

// both rects must be specified as all four points in plain vector, like:
//   [ x1, y1, x2, y2, x3, y3, x4, y4 ], clockwise from top-left point
// their points must already be rotated and specified in global space before passing to this function
export const isecRects = (r1, r2) => {
    if (!r1 || !r2) throw new Error('Rects are not accessible');

    var pn, px;
    for (let pi = 0, pl = r1.length; pi < pl; pi += 2) {
        pn = pi === pl - 2 ? 0 : pi + 2; // next point
        px = pn === pl - 2 ? 0 : pn + 2;

        if (edgeTest([r1[pi], r1[pi + 1]], [r1[pn], r1[pn + 1]], [r1[px], r1[px + 1]], r2)) {
            return false;
        }
    }

    for (let pi = 0, pl = r2.length; pi < pl; pi += 2) {
        pn = pi === pl - 2 ? 0 : pi + 2; // next point
        px = pn === pl - 2 ? 0 : pn + 2;

        if (edgeTest([r2[pi], r2[pi + 1]], [r2[pn], r2[pn + 1]], [r2[px], r2[px + 1]], r1)) {
            return false;
        }
    }
    return true;
};

//https://stackoverflow.com/questions/4652468/is-there-a-javascript-function-that-reduces-a-fraction
export const reduce = (numerator, denominator) => {
    var gcd = function gcd(a, b) {
        return b ? gcd(b, a % b) : a;
    };

    gcd = gcd(numerator, denominator);
    return [numerator / gcd, denominator / gcd];
};

export const lerp = (a, b, v) => {
    return (1 - v) * a + v * b;
};

//https://math.stackexchange.com/questions/2193720/find-a-point-on-a-line-segment-which-is-the-closest-to-other-point-not-on-the-li
export const closestPointBetween2D = (P, A, B) => {
    const v = [B[0] - A[0], B[1] - A[1]];
    const u = [A[0] - P[0], A[1] - P[1]];
    const vu = v[0] * u[0] + v[1] * u[1];
    const vv = v[0] ** 2 + v[1] ** 2;
    const t = -vu / vv;

    if (t >= 0 && t <= 1) {
        return _vectorToSegment2D(t, [0, 0], A, B);
    }
    const g0 = _sqDiag2D(_vectorToSegment2D(0, P, A, B));
    const g1 = _sqDiag2D(_vectorToSegment2D(1, P, A, B));

    return g0 <= g1 ? A : B;
};

const _vectorToSegment2D = (t, P, A, B) => {
    return [(1 - t) * A[0] + t * B[0] - P[0], (1 - t) * A[1] + t * B[1] - P[1]];
};

const _sqDiag2D = (P) => {
    return P[0] ** 2 + P[1] ** 2;
};

//https://stackoverflow.com/questions/22521982/check-if-point-is-inside-a-polygon
export const mouseInside = (point, vs) => {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
    var x = point[0],
        y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0],
            yi = vs[i][1];
        var xj = vs[j][0],
            yj = vs[j][1];

        var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
};

export const rectanglesOverlap = (topLeft1, bottomRight1, topLeft2, bottomRight2) => {
    if (topLeft1.x > bottomRight2.x || topLeft2.x > bottomRight1.x) {
        return false;
    }
    if (topLeft1.y < bottomRight2.y || topLeft2.y < bottomRight1.y) {
        return false;
    }
    return true;
};

export const rectangleFromCenterPoint = (width, height, center) => {
    const topLeft = new Vector2(center.x - width / 2, center.y + height / 2);

    const bottomRight = new Vector2(center.x + width / 2, center.y - height / 2);
    return [topLeft, bottomRight];
};
