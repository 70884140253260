<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">Add {{ appliance.name }}:</span>
        </v-card-title>

        <!-- Appliance props -->
        <v-card-text class="px-2">
            <!-- Subtype -->
            <v-container>
                <v-row justify="center">
                    <v-slide-group
                        v-model="selectedApplianceProps.subtype"
                        show-arrows
                        active-class="success"
                        class="mx-auto"
                    >
                        <v-slide-item
                            v-for="(subtype, key) in appliance.subtype"
                            :key="key"
                            v-slot="{ active, toggle }"
                        >
                            <v-hover v-slot:default="{ hover }">
                                <v-card
                                    :color="active ? 'green lighten-1' : null"
                                    :elevation="hover ? 16 : 2"
                                    class="pa-1 ma-2"
                                    :width="
                                        $vuetify.breakpoint.smAndUp ? 150 : 70
                                    "
                                    @click="toggle"
                                >
                                    <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-card-text
                                            class="
                                                py-2
                                                text-caption
                                                text-center
                                                text-subtitle-2
                                            "
                                        >
                                            <v-img
                                                :src="subtype.img"
                                                height="80"
                                                contain
                                                class="pa-2"
                                            ></v-img>
                                        </v-card-text>

                                        <v-card-subtitle
                                            class="text-center py-1"
                                            v-if="$vuetify.breakpoint.smAndUp"
                                            >{{ subtype.name }}</v-card-subtitle
                                        >
                                    </v-row>
                                </v-card>
                            </v-hover>
                        </v-slide-item>
                    </v-slide-group>
                </v-row>
            </v-container>

            <!-- Dimensions, Direction, Color -->
            <v-container>
                <v-form ref="form" v-model="isValid">
                    <v-container
                        v-for="subtype in appliance.subtype"
                        :key="subtype.id"
                        class="pa-0"
                    >
                        <!-- Dimensions -->
                        <v-row
                            v-if="
                                appliance.subtype[
                                    selectedApplianceProps.subtype
                                ].id === subtype.id && subtype.dimensions
                            "
                            justify="center"
                        >
                            <v-col
                                v-for="(dimension, key) in subtype.dimensions"
                                :key="key"
                                cols="6"
                                sm="4"
                            >
                                <v-combobox
                                    v-model.number="
                                        dimension.value[selectedUnit]
                                    "
                                    @input.native="
                                        dimension.value[selectedUnit] =
                                            $event.srcElement.value
                                    "
                                    :items="dimension.items[selectedUnit]"
                                    :label="key"
                                    ref="key"
                                    outlined
                                    required
                                    subtype="number"
                                    :rules="[
                                        rules.required,
                                        rules.between(
                                            dimension.min[selectedUnit],
                                            dimension.max[selectedUnit]
                                        ),
                                    ]"
                                    :suffix="selectedUnit"
                                ></v-combobox>
                            </v-col>
                        </v-row>

                        <!-- Direction, Color -->
                        <v-row
                            v-if="
                                appliance.subtype[
                                    selectedApplianceProps.subtype
                                ].id === subtype.id
                            "
                            justify="center"
                            align="center"
                        >
                            <!-- Direction -->
                            <v-btn-toggle
                                v-if="subtype.direction"
                                v-model="selectedApplianceProps.direction"
                                tile
                                color="primary"
                                group
                                mandatory
                            >
                                <v-btn
                                    v-for="direction in subtype.direction.items"
                                    :key="direction"
                                    :value="direction"
                                >
                                    {{ direction }}
                                </v-btn>
                            </v-btn-toggle>

                            <v-divider class="mx-2" vertical></v-divider>

                            <!-- Color -->
                            <v-btn-toggle
                                v-if="subtype.color"
                                v-model="selectedApplianceProps.color"
                                tile
                                color="primary"
                                group
                            >
                                <v-btn
                                    v-for="color in subtype.color.items"
                                    :key="color"
                                    :value="color"
                                >
                                    {{ color }}
                                </v-btn>
                            </v-btn-toggle>

                            <v-divider class="mx-2" vertical></v-divider>

                            <!-- Format -->
                            <v-btn-toggle
                                v-if="subtype.format"
                                v-model="selectedApplianceProps.format"
                                tile
                                color="primary"
                                group
                                mandatory
                            >
                                <v-btn
                                    v-for="format in subtype.format.items"
                                    :key="format"
                                    :value="format"
                                >
                                    {{ format }}
                                </v-btn>
                            </v-btn-toggle>
                        </v-row>
                    </v-container>

                    <v-input
                        v-if="appliance.id === 'hood' && cooktopAlert"
                        :rules="[rules.hasCooktop]"
                    ></v-input>
                    <v-input
                        v-if="appliance.id === 'refrigerator' && builtinFridgeAlert"
                        :rules="[rules.hasTallCabinetsForFridge]"
                    ></v-input>
                </v-form>
            </v-container>
            <small>*indicates required field</small>
        </v-card-text>

        <!-- Dialog Actions -->
        <v-alert v-if="appliance.id === 'hood' && cooktopAlert" type="error">{{
            cooktopAlert
        }}</v-alert>
        <v-alert v-if="appliance.id === 'refrigerator' && builtinFridgeAlert && selectedApplianceProps.format === 'builtin'" type="error">{{
            builtinFridgeAlert
        }}</v-alert>

        <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="blue darken-1" text @click="closeDialog"
                >Cancel
            </v-btn>
            <v-btn
                class="white--text"
                color="blue darken-1"
                @click="addObject"
                large
                :disabled="!isValid"
                >Add
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            isValid: false,
            rules: {
                required: (value) => !!value || value === 0 || 'Required',
                between(min, max) {
                    return (value) =>
                        (value >= min && value <= max) ||
                        `Must be between ${min} and ${max}`;
                },
                hasCooktop: (value) =>
                    !this.cooktopAlert || `${this.cooktopAlert}`,
                hasTallCabinetsForFridge: (value) => 
                    !this.builtinFridgeAlert || `${this.builtinFridgeAlert}`
            },
        };
    },
    computed: {
        ...mapState('appliances', {
            appliance: (state) => state.modalAppliance,
        }),
        ...mapGetters('core', ['selectedUnit', 'editorEngine']),
        selectedApplianceProps: {
            set({ subtype, direction, color }) {
                this.$store.commit('appliances/setModalApplianceProps', {
                    subtype,
                    direction,
                    color,
                });
            },
            get() {
                return this.$store.state.appliances.selectedApplianceProps;
            },
        },
        cooktopAlert() {
            const checkForFreeCooktops =
                this.editorEngine.checkForFreeCooktops();
            let alert = null;

            if (!checkForFreeCooktops.hasCooktops) {
                alert = 'No cooktop placed';
            } else if (!checkForFreeCooktops.hasFreeCooktops) {
                alert = 'Place a cooktop first';
            }

            return alert;
        },
        builtinFridgeAlert() {
            const hasTallCabinets =
                this.editorEngine.hasTallCabinets();
            let alert = !hasTallCabinets && this.selectedApplianceProps.format === 'builtin' ? 'Built in refrigerators require Tall Cabinets in the room' : null;

            return alert;
        }
    },
    methods: {
        closeDialog() {
            this.$store.commit('appliances/openDialog', false);
        },
        addObject() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch('appliances/addObject');
                this.closeDialog();
            }
        },
    },
    destroyed() {
        this.$store.commit('appliances/setModalAppliance', null);
        this.selectedApplianceProps.subtype = 0;
    },
};
</script>
