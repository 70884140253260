import { SceneLoader, Texture, Vector3 } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { BaseAppliance } from './BaseAppliance';
import store from '@/store/index.js';
export class Hood extends BaseAppliance {
    currentCooktop;
    constructor() {
        super();
    }

    fillEmptyDimensionsFromVue() {
        this.depth = 0.36;
        this.height = 0.7;
        if (this.editor.sceneInfo.unit === 'in') {
            this.depth = 0.36;
            this.height = 0.68;
        }
        if (this.subtype === 'builtin') {
            this.height = 0.181228;
        }
    }

    setInitialPosition() {
        const appliances = this.editor.appliances;

        if (appliances.length === 0) {
            this.position = new Vector3.Zero();
            this.meshComponent.getMesh().position = this.position;
            return;
        }
        let freeCooktop;
        for (let index = 0; index < appliances.length; index++) {
            if (
                (appliances[index].type === Constants.appliance.type.COOKTOP || appliances[index].subtype === 'range') &&
                !appliances[index].hasHood
            ) {
                freeCooktop = appliances[index];
                this.position = freeCooktop.meshComponent.getMesh().position.clone();
                this.position.y = this.height / 2 + Constants.hood.HEIGHT_FROM_FLOOR;
                this.meshComponent.setPosition(this.position);
                this.setCurrentCooktop(freeCooktop);
                this.setRotation();
                this.setCurrentCabinet(freeCooktop);
                this.setHeightFromFloor();
                this.currentCooktop.hasHood = this;
                this.assignedWall = this.currentCabinet?.assignedWall;
                this.assignedRow = this.currentCabinet?.assignedRow;
                let currentCabinetHeight = this.currentCabinet ? this.currentCabinet.height : 0;
                this.position.y = Constants.hood.HEIGHT_FROM_FLOOR + this.height / 2;
                break;
            }
        }
    }

    setCurrentCabinet(freeCooktop) {
        if (freeCooktop.currentCabinet.type === Constants.cabinet.type.ISLAND) {
            this.currentCabinet = freeCooktop.currentCabinet;
        } else {
            const wallCabinets = freeCooktop.assignedWall.getCabinetsOnWall([Constants.cabinet.type.WALL]);
            const cooktopPosition = freeCooktop.meshComponent.getMesh().position;
            if (wallCabinets.length !== 0) {
                let previousDistance = Vector3.Distance(cooktopPosition, wallCabinets[0].meshComponent.getPosition());
                let closestWallCabinet = wallCabinets[0];

                for (let index = 1; index < wallCabinets.length; index++) {
                    const wallCabinetPosition = wallCabinets[index].meshComponent.getMesh().position;
                    const currentDistance = Vector3.Distance(cooktopPosition, wallCabinetPosition);
                    if (currentDistance < previousDistance) {
                        closestWallCabinet = wallCabinets[index];
                        previousDistance = Vector3.Distance(cooktopPosition, wallCabinetPosition);
                    }
                }
                this.currentCabinet = closestWallCabinet;
            }
        }
    }

    setCurrentCooktop(freeCooktop) {
        this.currentCooktop = freeCooktop;
    }

    setHeightFromFloor() {
        this.heightFromFloor =
            this.currentCabinet && this.currentCabinet.type !== Constants.cabinet.type.ISLAND
                ? this.currentCabinet.heightFromFloor
                : Constants.hood.HEIGHT_FROM_FLOOR;
    }

    setRotation() {
        this.rotation = this.currentCooktop.rotation;
        if (this.rotation) {
            this.meshComponent.getMesh().rotation.y = this.currentCooktop.meshComponent.getMesh().rotation.y;
        }
    }

    async setModel() {
        let filename = null;
        if (this.subtype === 'freestanding1') {
            filename = 'hood_style_1.babylon';
        } else if (this.subtype === 'freestanding2') {
            filename = 'hood_style_2.babylon';
        } else if (this.subtype === 'freestanding3') {
            filename = 'hood_style_3.babylon';
        } else if (this.subtype === 'builtin') {
            filename = 'builtinHood.babylon';
        }
        if (filename) {
            const result = await SceneLoader.ImportMeshAsync('', filename, '', this.editor.sceneComponent.get());
            this.meshComponent.setMesh(result.meshes[Utilities.getIndexFromMeshId(filename.split('.')[0], result.meshes)]);
            this.resizeToCurrentDimensions();
        }
        this.meshComponent.getMesh().alwaysSelectAsActiveMesh = true;

        this.addTexture();
    }

    addTexture() {
        let textureType = 'Hood';
        if (this.subtype === 'builtin') {
            textureType = 'BuiltinHood';
        }
        this.editor.PBRComponent.setPBRMetallicRoughnessToBuildingBlock(this.meshComponent.getMesh(), textureType, 'appliances/');
        let color = store.state.appliances.appliances[this.type].subtype[0].color.items[0];

        if (color === 'light') {
            const filePath = require('@/assets/textures/appliances/'.concat(textureType).concat('/diffuseTextureLight.jpg'));
            this.meshComponent.getMesh().material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        }
    }

    changeColor(color) {
        this.color = color;
        let textureType = 'Hood';
        if (this.subtype === 'builtin') {
            textureType = 'BuiltinHood';
        }

        if (color == 'dark') {
            const filePath = require('@/assets/textures/appliances/'.concat(textureType.concat('/diffuseTexture.jpg')));
            this.model.material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        } else if (color === 'light') {
            const filePath = require('@/assets/textures/appliances/'.concat(textureType.concat('/diffuseTextureLight.jpg')));
            this.model.material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        }
    }
}
