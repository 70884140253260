import Vue from 'vue';
import Vuex from 'vuex';

import { core } from './modules/core';
import { business } from './modules/business';
import { subscription } from './modules/subscription';
import { fixtures } from './modules/step1/fixtures';
import { cabinets } from './modules/step2/cabinets';
import { appliances } from './modules/step3/appliances';
import { generated } from './modules/step4/generated';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        core,
        business,
        fixtures,
        cabinets,
        appliances,
        generated,
        subscription,
    },
    state: {},
    mutations: {},
    actions: {},
});
