var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("StartModal"),
      _c("LeadDialog"),
      _c("UpdateLeadProjectDialog"),
      _c(
        "v-container",
        {
          staticClass: "pa-0",
          staticStyle: { position: "relative" },
          style: _vm.canvasStyle,
          attrs: { fluid: "" },
        },
        [_c("kw-Canvas"), _c("kw-Canvas-Bottom")],
        1
      ),
      _c(
        "v-container",
        { ref: "stepper", staticClass: "stepper pa-0", attrs: { fluid: "" } },
        [_vm.canvasLoaded ? _c("kw-Stepper") : _vm._e()],
        1
      ),
      _c(
        "v-overlay",
        { attrs: { value: !!_vm.popupMsg, "z-index": "1000" } },
        [
          _c(
            "v-alert",
            {
              staticClass: "popup-msg",
              attrs: { prominent: "", type: "error" },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", { staticClass: "grow" }, [
                    _c("p", [_vm._v(" " + _vm._s(_vm.popupMsg) + " ")]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.closePopupMsg()
                            },
                          },
                        },
                        [_vm._v("ok")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }