<template>
    <v-container class="ma-0 pa-0" fluid>
        <StartModal />
        <LeadDialog />
        <UpdateLeadProjectDialog />

        <v-container
            class="pa-0"
            fluid
            :style="canvasStyle"
            style="position: relative"
        >
            <kw-Canvas></kw-Canvas>
            <kw-Canvas-Bottom></kw-Canvas-Bottom>
        </v-container>

        <v-container ref="stepper" class="stepper pa-0" fluid>
            <kw-Stepper v-if="canvasLoaded"></kw-Stepper>
        </v-container>

        <v-overlay :value="!!popupMsg" z-index="1000">
            <v-alert prominent type="error" class="popup-msg">
                <v-row align="center">
                    <v-col class="grow">
                        <p>
                            {{ popupMsg }}
                        </p>
                    </v-col>
                    <v-col class="shrink">
                        <v-btn @click="closePopupMsg()">ok</v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </v-overlay>
    </v-container>
</template>

<script>
import Vue from 'vue';
import kwCanvas from '../components/kwCanvas';
import kwCanvasBottom from '../components/kwCanvasBottom';
import kwStepper from '../components/stepper/Stepper';
import StartModal from '../components/StartModal';
import LeadDialog from '../components/LeadDialog';
import UpdateLeadProjectDialog from '../components/UpdateLeadProjectDialog';
import fetchProject from '@/graphql/queries/fetchProject.gql';
import fetchBusinessInfo from '@/graphql/queries/fetchBusinessInfo.gql';
import fetchBusinessCosting from '@/graphql/queries/fetchBusinessCosting.gql';
import fetchProjectLeadData from '@/graphql/queries/fetchProjectLeadData.gql';
import { mapGetters, mapMutations, mapState } from 'vuex';

import { render } from '@/services/editor/render';
import { render3D } from '@/services/editor/3D/render3D';
import { Editor2D } from '@/services/editor/2D/Editor2D';
import { Editor3D } from '@/services/editor/3D/Editor3D/Editor3D';

export default {
    components: {
        kwCanvas,
        kwCanvasBottom,
        kwStepper,
        StartModal,
        LeadDialog,
        UpdateLeadProjectDialog,
    },
    data() {
        return {
            canvasStyle: {},
            userId: null,
        };
    },
    methods: {
        ...mapMutations('core', [
            'toggleSceneSaved',
            'setStartedDesigning',
            'toggleProjectLoading',
            'setStep',
            'resizeCanvas',
            'setPopupMsg',
            'setCabinetData',
            'logErrors',
            'setUnit',
        ]),
        ...mapMutations('business', ['setBusinessInfo', 'setBusinessCosting']),
        matchCanvasHeight() {
            Vue.nextTick(() => {
                const heightString = `calc(100vh - ${this.$refs.stepper.clientHeight}px - 48px)`;
                Vue.set(this.canvasStyle, 'height', heightString);
                this.editorEngine.canvas.height =
                    window.innerHeight - this.$refs.stepper.clientHeight - 48;
                this.editorEngine.canvas.width =
                    this.editorEngine.canvas.clientWidth;
            });
        },
        closePopupMsg() {
            this.setPopupMsg('');
        },
    },
    mounted() {
        this.matchCanvasHeight();
    },
    async beforeMount() {
        if (await this.$auth.doesSessionExist()) {
            this.userId = await this.$auth.getUserId();
            console.log("userId", this.userId);
        }
    },
    computed: {
        ...mapState('core', [
            'canvasLoaded',
            'canvasDim',
            'popupMsg',
            'isProjectLoading',
        ]),
        ...mapState('generated', ['selectedObject', 'tab']),
        ...mapState('business', ['isStandardDomain', 'info']),
        ...mapGetters('core', ['editorEngine', 'step', 'projectData']),
        cabinetData() {
            if (!this.editorEngine || !this.projectData) return false;
            const editorCabinets = this.editorEngine.cabinets;
            const savedCabinets = this.projectData.cabinets;

            if (this.isProjectLoading) {
                return savedCabinets;
            }

            if (this.step === 4) {
                for (let index = 0; index < editorCabinets.length; index++) {
                    savedCabinets[index].countertopCustomization =
                        editorCabinets[index].countertopCustomization;

                    savedCabinets[index].skirtingCustomization =
                        editorCabinets[index].skirtingCustomization;

                    if (
                        editorCabinets[index].sections.length !== 0 &&
                        editorCabinets[index].type !== 'island'
                    ) {
                        savedCabinets[index].sectionsInformation =
                            editorCabinets[index].sections.map((section) => {
                                return {
                                    id: section.id,
                                    sectionWidth: section.width,
                                    numberOfSections: 1,
                                    sectionData:
                                        typeof section.sectionData?.id ===
                                        'number'
                                            ? section.sectionData.id
                                            : section.sectionData,
                                    isFiller: section.isFiller,
                                    dimensions: {
                                        width: section.width,
                                        depth: section.depth,
                                        height: section.height,
                                    },
                                    customization: section.customization,
                                };
                            });
                    } else if (
                        editorCabinets[index].type === 'island' &&
                        editorCabinets[index].sections.firstRow.length !== 0
                    ) {
                        if (
                            !savedCabinets[index].sectionsInformation ||
                            Array.isArray(
                                savedCabinets[index].sectionsInformation
                            )
                        ) {
                            savedCabinets[index].sectionsInformation = {};
                        }
                        savedCabinets[index].sectionsInformation.firstRow =
                            editorCabinets[index].sections.firstRow.map(
                                (section) => {
                                    return {
                                        id: section.id,
                                        sectionWidth: section.width,
                                        numberOfSections: 1,
                                        assignedRow: 'firstRow',
                                        sectionData:
                                            typeof section.sectionData?.id ===
                                            'number'
                                                ? section.sectionData.id
                                                : section.sectionData,
                                        isFiller: section.isFiller,
                                        dimensions: {
                                            width: section.width,
                                            depth: section.depth,
                                            height: section.height,
                                        },
                                        customization: section.customization,
                                    };
                                }
                            );

                        savedCabinets[index].sectionsInformation.secondRow =
                            editorCabinets[index].sections.secondRow.map(
                                (section) => {
                                    return {
                                        id: section.id,
                                        sectionWidth: section.width,
                                        numberOfSections: 1,
                                        assignedRow: 'secondRow',
                                        sectionData:
                                            typeof section.sectionData?.id ===
                                            'number'
                                                ? section.sectionData.id
                                                : section.sectionData,
                                        isFiller: section.isFiller,
                                        dimensions: {
                                            width: section.width,
                                            depth: section.depth,
                                            height: section.height,
                                        },
                                        customization: section.customization,
                                    };
                                }
                            );
                    }
                }

                return savedCabinets;
            } else if (this.step > 1) {
                return editorCabinets.map((c) => {
                    return {
                        name: c.name,
                        type: c.type,
                        dimensions: {
                            width: c.width * 1000,
                            height: c.height * 1000,
                            depth: c.depth * 1000,
                            heightFromFloor: c.heightFromFloor * 1000,
                        },
                        position: c.position,
                        rotation: c.rotation,
                        wall: c.assignedWall ? c.assignedWall.id : null,
                        state: 'loadingScene',
                        id: c.id,
                        connectedCabinet: c.connectedCabinet
                            ? c.connectedCabinet.id
                            : null,
                        secondConnectedCabinet: c.secondConnectedCabinet
                            ? c.secondConnectedCabinet.id
                            : null,
                        peninsulaData: {
                            cabinetId: c.peninsulaData?.connectedCabinet?.id,
                            side: c.peninsulaData?.side,
                            connectionEdge: c.peninsulaData?.connectionEdge,
                            edgeIndex: c.peninsulaData?.edgeIndex,
                        },
                        sectionsInformation: c.sectionsInformation,
                        countertopCustomization: c.countertopCustomization,
                        skirtingCustomization: c.skirtingCustomization,
                    };
                });
            } else return false;
        },
    },
    watch: {
        projectData: {
            immediate: true,
            deep: true,
            handler(val, oldVal) {
                if (oldVal) {
                    this.toggleSceneSaved(false);
                    if (process.env.NODE_ENV === 'development') {
                        // console.log(
                        //     'Project DATA changed:',
                        //     JSON.stringify(val)
                        // );
                    }
                }
            },
        },
        step(value, oldVal) {
            console.log('step', value, 'oldStep', oldVal);
            this.matchCanvasHeight();
            if (oldVal === 4 && value === 3) {
                // this.editorEngine.sceneComponent.save3D();
                this.editorEngine.loadPreviousState();
            }

            if (value === 1) {
                if (this.editorEngine instanceof Editor3D) {
                    this.editorEngine.sceneComponent.save3D();
                    this.editorEngine.dispose();
                    render(this.projectData);
                }
            } else if (value === 2) {
                if (this.editorEngine instanceof Editor2D) {
                    this.editorEngine.controls.save2D();
                    this.editorEngine.dispose();
                    render3D(this.projectData);
                }
            }

            // Force window to resize because v-slide-group
            // didnt work on mobile
            if (typeof Event === 'function') {
                // modern browsers
                window.dispatchEvent(new Event('resize'));
            } else {
                // for IE and other old browsers
                // causes deprecation warning on modern browsers
                var evt = window.document.createEvent('UIEvents');
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
            }
        },
        canvasDim: {
            immediate: true,
            deep: true,
            handler(val) {
                Vue.set(this.canvasStyle, 'height', `${val.height}px`);
                Vue.set(this.canvasStyle, 'width', `${val.width}px`);
            },
        },
        cabinetData: {
            immediate: true,
            deep: true,
            handler(val) {
                this.setCabinetData(val);
                // console.log("cabinet data", val)
            },
        },
        selectedObject: {
            immediate: true,
            handler(val) {
                setTimeout(() => {
                    this.matchCanvasHeight();
                }, 400);
            },
        },
        tab() {
            setTimeout(() => {
                this.matchCanvasHeight();
            }, 400);
        },
    },
    apollo: {
        project: {
            query: fetchProject,
            update: (data) => data.project_by_pk,
            variables() {
                const empty = '00000000-0000-0000-0000-000000000000';
                return { projectId: this.$route.params.projectId || empty };
            },
            skip() {
                return !this.isStandardDomain;
            },
            error(error) {
                this.logErrors(JSON.stringify(error.message));
                console.error(error);
            },
            result(data) {
                if (process.env.NODE_ENV === 'development')
                    console.log('fetchProject', data?.data.project_by_pk?.data);

                const project = data.data.project_by_pk?.data
                    ? JSON.parse(data.data.project_by_pk?.data)
                    : {};

                this.$store.dispatch('core/loadProjectAction', { project });
            },
        },
        businessInfo: {
            query: fetchBusinessInfo,
            variables() {
                return {
                    userId: this.userId,
                };
            },
            update: (data) => data.business,
            skip() {
                return !this.isStandardDomain;
            },
            error(error) {
                this.logErrors(JSON.stringify(error.message));
                console.error(error);
            },
            result(data) {
                if (process.env.NODE_ENV === 'development')
                    console.log('fetchBusinessInfo', data?.data);

                const business = data.data.business[0];
                this.setBusinessInfo({ business });
            },
        },
        costing: {
            query: fetchBusinessCosting,
            update: (data) => data.business,
            variables() {
                if (this.isStandardDomain) {
                    return {};
                } else {
                    return { businessId: this.info.businessId };
                }
            },
            error(error) {
                this.logErrors(JSON.stringify(error.message));
                console.error(error);
            },
            result(data) {
                if (process.env.NODE_ENV === 'development')
                    console.log('fetchBusinessCosting', data?.data.business[0]);

                const business = data?.data.business[0];
                const businessCosting = business.business_costing;

                if (businessCosting) {
                    if (typeof businessCosting?.unit !== 'object') {
                        businessCosting.unit = JSON.parse(businessCosting.unit);
                        this.setUnit(businessCosting.unit.value);
                    }
                    if (typeof businessCosting?.extra_costs !== 'object') {
                        businessCosting.extra_costs = JSON.parse(
                            businessCosting.extra_costs
                        );
                    }
                    if (typeof businessCosting?.currency !== 'object') {
                        businessCosting.currency = JSON.parse(
                            businessCosting.currency
                        );
                    }

                    this.setBusinessCosting({ business });
                }
            },
        },
        projectLead: {
            query: fetchProjectLeadData,
            update: (data) => data.business_domains_projects_by_pk,
            variables() {
                const empty = '00000000-0000-0000-0000-000000000000';
                return { projectId: this.$route.params.projectId || empty };
            },
            skip() {
                return this.isStandardDomain;
            },
            error(error) {
                this.logErrors(JSON.stringify(error.message));
                console.error(error);
            },
            result(data) {
                if (process.env.NODE_ENV === 'development')
                    console.log(
                        'fetchProjectLead',
                        data.data?.business_domains_projects_by_pk?.data
                    );

                const project = data.data?.business_domains_projects_by_pk?.data
                    ? JSON.parse(
                          data.data?.business_domains_projects_by_pk?.data
                      )
                    : {};

                this.$store.dispatch('core/loadProjectAction', { project });
            },
        },
    },
};
</script>

<style>
.stepper {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.popup-msg {
    white-space: pre-line;
}
</style>
