<template>
    <v-dialog v-model="updateDialog" max-width="400" scrollable persistent>
        <v-form v-model="valid" @submit.prevent="saveLead">
            <v-card>
                <v-card-title class="justify-center pa-4">
                    <span class="text-h4">Update Project</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="pa-5">
                                <v-text-field
                                    v-model="codeComp"
                                    label="Update Code"
                                    required
                                    :rules="[rules.required]"
                                    persistent-hint
                                    hint="The code was sent to your email"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="toggleUpdateDialog(false)"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="green darken-1 white--text"
                        @click="saveLead"
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
                <v-alert v-if="alert.message" dismissible :type="alert.type">{{
                    alert.message
                }}</v-alert>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            valid: true,
            rules: {
                required: (value) => !!value || 'Required.',
                minCount: (value) => value.length >= 5 || 'Min 5 characters',
            },
        };
    },
    computed: {
        ...mapState('business', [
            'updateDialog',
            'updateCode',
            'loading',
            'alert',
        ]),
        codeComp: {
            get() {
                return this.updateCode;
            },
            set(code) {
                this.setUpdateCode(code);
            },
        },
    },
    methods: {
        ...mapMutations('business', ['toggleUpdateDialog', 'setUpdateCode']),
        ...mapActions('business', ['updateLeadAction']),
        saveLead() {
            this.updateLeadAction({
                apollo: this.$apollo,
                projectId: this.$route.params.projectId,
            });
        },
    },
};
</script>
