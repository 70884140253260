var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "inputs",
      model: {
        value: _vm.isValid,
        callback: function ($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid",
      },
    },
    [
      _vm.$vuetify.breakpoint.xsOnly
        ? _c("p", { staticClass: "text-overline text-center mb-0" }, [
            _vm._v(
              " " +
                _vm._s(_vm.appliance.type) +
                ", " +
                _vm._s(_vm.appliance.subtype) +
                " "
            ),
          ])
        : _vm._e(),
      _c(
        "v-tabs",
        {
          attrs: {
            "background-color": "indigo",
            centered: "",
            height: "38",
            dark: "",
            "slider-size": "5",
            "show-arrows": "",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tabs-slider", { attrs: { color: "yellow" } }),
          _c(
            "v-btn",
            {
              staticClass: "my-auto",
              attrs: { color: "indigo lighten-2", small: "" },
              on: { click: _vm.deselectObjects },
            },
            [
              _c("v-icon", { attrs: { color: "indigo darken-3" } }, [
                _vm._v(_vm._s(_vm.mdiArrowLeftCircle)),
              ]),
            ],
            1
          ),
          _vm.$vuetify.breakpoint.smAndUp
            ? _c(
                "v-tab",
                { staticClass: "float-left", attrs: { disabled: "" } },
                [
                  _vm._v(
                    _vm._s(_vm.appliance.type) +
                      ", " +
                      _vm._s(_vm.appliance.subtype)
                  ),
                ]
              )
            : _vm._e(),
          _vm.applianceProps.dimensions && _vm.step !== 4
            ? _c(
                "v-tab",
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiTapeMeasure))])],
                1
              )
            : _vm._e(),
          _c(
            "v-tab",
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiPaletteOutline))])],
            1
          ),
          _vm.$vuetify.breakpoint.xsOnly && _vm.step !== 4
            ? _c("v-tab", [_c("v-icon", [_vm._v(_vm._s(_vm.mdiDelete))])], 1)
            : _vm._e(),
          _c(
            "v-tabs-items",
            {
              attrs: { continuous: "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _vm.$vuetify.breakpoint.smAndUp ? _c("v-tab-item") : _vm._e(),
              _vm.applianceProps.dimensions && _vm.step !== 4
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "indigo lighten-5 pt-2",
                              attrs: { justify: "center" },
                            },
                            [
                              _vm._l(
                                _vm.appliance.dimensions,
                                function (value, key) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: key,
                                      staticClass: "pa-2",
                                      attrs: { cols: "4", sm: "3" },
                                    },
                                    [
                                      _c("v-combobox", {
                                        ref: "key",
                                        refInFor: true,
                                        attrs: {
                                          items:
                                            _vm.applianceProps.dimensions[key]
                                              .items[_vm.selectedUnit],
                                          label: key,
                                          outlined: "",
                                          dense: _vm.$vuetify.breakpoint.xsOnly,
                                          required: "",
                                          subtype: "number",
                                          rules: [
                                            _vm.rules.required,
                                            _vm.rules.between(
                                              _vm.limits[key].min[
                                                _vm.selectedUnit
                                              ],
                                              _vm.limits[key].max[
                                                _vm.selectedUnit
                                              ]
                                            ),
                                          ],
                                          suffix: _vm.selectedUnit,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.resize(key)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-data",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(" Press "),
                                                              _c("kbd", [
                                                                _vm._v("enter"),
                                                              ]),
                                                              _vm._v(
                                                                " to update "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.appliance.dimensions[key],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.appliance.dimensions,
                                              key,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "appliance.dimensions[key]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              !_vm.$vuetify.breakpoint.xsOnly
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-2 text-center",
                                      attrs: { cols: "1" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "red",
                                            dark: "",
                                            icon: "",
                                            large: "",
                                            tile: "",
                                          },
                                          on: { click: _vm.dispose },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { dark: "" } },
                                            [_vm._v(_vm._s(_vm.mdiDelete))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "indigo lighten-5 pt-2",
                          attrs: { justify: "center" },
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-2", attrs: { cols: "auto" } },
                            [
                              _vm.appliance.direction
                                ? _c(
                                    "v-btn-toggle",
                                    {
                                      attrs: {
                                        tile: "",
                                        color: "primary",
                                        mandatory: "",
                                      },
                                      on: { change: _vm.changeDirection },
                                      model: {
                                        value: _vm.appliance.direction,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.appliance,
                                            "direction",
                                            $$v
                                          )
                                        },
                                        expression: "appliance.direction",
                                      },
                                    },
                                    _vm._l(
                                      _vm.applianceProps.directionItems,
                                      function (direction) {
                                        return _c(
                                          "v-btn",
                                          {
                                            key: direction,
                                            attrs: { value: direction },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(direction) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-2", attrs: { cols: "auto" } },
                            [
                              _vm.appliance.color
                                ? _c(
                                    "v-btn-toggle",
                                    {
                                      attrs: {
                                        tile: "",
                                        color: "primary",
                                        mandatory: "",
                                      },
                                      on: { change: _vm.changeColor },
                                      model: {
                                        value: _vm.appliance.color,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.appliance, "color", $$v)
                                        },
                                        expression: "appliance.color",
                                      },
                                    },
                                    _vm._l(
                                      _vm.applianceProps.colorItems,
                                      function (color) {
                                        return _c(
                                          "v-btn",
                                          {
                                            key: color,
                                            attrs: { value: color },
                                          },
                                          [_vm._v(" " + _vm._s(color) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-2", attrs: { cols: "auto" } },
                            [
                              _vm.appliance.format
                                ? _c(
                                    "v-btn-toggle",
                                    {
                                      attrs: {
                                        tile: "",
                                        color: "primary",
                                        mandatory: "",
                                      },
                                      on: { change: _vm.changeFormat },
                                      model: {
                                        value: _vm.appliance.format,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.appliance, "format", $$v)
                                        },
                                        expression: "appliance.format",
                                      },
                                    },
                                    _vm._l(
                                      _vm.applianceProps.formatItems,
                                      function (format) {
                                        return _c(
                                          "v-btn",
                                          {
                                            key: format,
                                            attrs: { value: format },
                                          },
                                          [_vm._v(" " + _vm._s(format) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          !_vm.$vuetify.breakpoint.xsOnly && _vm.step !== 4
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pa-2 text-center",
                                  attrs: { cols: "1" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "red",
                                        dark: "",
                                        icon: "",
                                        large: "",
                                        tile: "",
                                      },
                                      on: { click: _vm.dispose },
                                    },
                                    [
                                      _c("v-icon", { attrs: { dark: "" } }, [
                                        _vm._v(_vm._s(_vm.mdiDelete)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.xsOnly && _vm.step !== 4
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "indigo lighten-5 pt-2",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-2", attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "red",
                                        dark: "",
                                        icon: "",
                                        large: "",
                                        tile: "",
                                      },
                                      on: { click: _vm.dispose },
                                    },
                                    [
                                      _c("v-icon", { attrs: { dark: "" } }, [
                                        _vm._v(_vm._s(_vm.mdiDelete)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }