import { Vector3, Color3, MeshBuilder } from '@babylonjs/core';
import { unitAsBjsUnit } from '../../Tools/Utilities';

import { OffsetLineWhiteSpace } from '../../Floorplan';
import { TextPlaneWidget } from '../../Widgets';

export class FixtureDiagramWidget {
    constructor(parent) {
        this.parent = parent;
        this.scene = parent.scene;
        this.UI = parent.parent.parent.widgetUI;
        this.lines = null;
        this.flipped = this.parent.flipped;
        this.dirty = true;
        this.makeWidgets();
        this.update();
    }

    makeWidgets() {
        if (this.parent.type != 'fixedColumn') {
            this.nameWidget = new TextPlaneWidget(
                () => {
                    return this.parent.getName();
                },
                {
                    positionValue: () => {
                        if (this.parent.assignedSegment !== -1) {
                            return this.parent
                                .getAssignedSegment()
                                .getUp()
                                .scale(this.getEditorCore().floorplan.wallThickness * (OffsetLineWhiteSpace * 0.75));
                        } else {
                            return Vector3.Zero();
                        }
                    },
                    rotationValue: () => {
                        if (this.parent.assignedSegment !== -1) {
                            let seg = this.parent.getAssignedSegment();
                            let angle = -seg.angle;

                            if (seg.b.x < seg.a.x) {
                                angle += Math.PI;
                            }

                            //console.log(angle)
                            return angle;
                        } else {
                            return 0;
                        }
                    },
                    width: 100,
                    height: 18,
                    linkedMesh: this.parent.getRoot(),
                    background: 'white',
                    color: 'black',
                },
                this
            );
            this.numberWidget = new TextPlaneWidget(
                () => {
                    return this.parent.getWidthText();
                },
                {
                    positionValue: () => {
                        if (this.parent.assignedSegment !== -1) {
                            return this.parent
                                .getAssignedSegment()
                                .getUp()
                                .scale(this.getEditorCore().floorplan.wallThickness * (OffsetLineWhiteSpace * 1.75));
                        } else {
                            return Vector3.Zero();
                        }
                    },
                    rotationValue: () => {
                        if (this.parent.assignedSegment !== -1) {
                            let seg = this.parent.getAssignedSegment();
                            let angle = -seg.angle;

                            if (seg.b.x < seg.a.x) {
                                angle += Math.PI;
                            }

                            //console.log(angle)
                            return angle;
                        } else {
                            return 0;
                        }
                    },
                    width: 75,
                    height: 18,
                    linkedMesh: this.parent.getRoot(),
                    background: 'transparent',
                    color: 'black',
                },
                this
            );
        } else {
            this.nameWidget = false;
            this.numberWidget = false;
        }
    }

    update() {
        if (this.parent.type != 'fixedColumn') {
            this.nameWidget.update();
            this.numberWidget.update();

            if (this.flipped == this.parent.flipped && !this.dirty) {
                return;
            }

            this.flipped = this.parent.flipped;

            if (this.lines) {
                this.lines.dispose();
                this.lines = null;
            }

            this.dirty = false;
            let fW = unitAsBjsUnit(this.parent.dimensions.width);
            let wT = this.parent.parent.wallThickness;

            let flip = this.flipped * 2.0 - 1.0;

            let points = [
                new Vector3(-fW * 0.5, 0, 0),
                new Vector3(-fW * 0.5, -(wT * OffsetLineWhiteSpace) * flip, 0),
                new Vector3(fW * 0.5, -(wT * OffsetLineWhiteSpace) * flip, 0),
                new Vector3(fW * 0.5, 0, 0),
            ];
            let lines = [points];

            let wU2 = new Vector3(0, wT * 0.25, 0);
            let wlb2 = new Vector3(-wT * 0.25, 0, 0);
            let dashes = [
                [points[1].add(wU2).add(wlb2), points[1].subtract(wU2).subtract(wlb2)],
                [points[2].add(wU2).add(wlb2), points[2].subtract(wU2).subtract(wlb2)],
            ];
            lines.push(...dashes);
            this.lines = MeshBuilder.CreateLineSystem(this.parent.uID + ':DiagramWidget', { lines }, this.scene);
            this.lines.ignorePick = true;
            this.lines.renderingGroupId = 2;
            this.lines.color = Color3.Black();
            this.lines.parent = this.parent.getRoot();
            this.lines.position.y = wT * -0.5 * flip;
        } else {
            //TODO
            return;
        }
    }

    dispose() {
        if (this.lines) {
            this.lines.dispose();
        }
        if (this.numberWidget) {
            this.numberWidget.getEditorCore().widgetUI.removeControl(this.numberWidget.parts.rect);
            // undefined when changing the dimension of a fixture
            this.numberWidget.meshAnchor.dispose();
        }
        if (this.nameWidget) {
            this.nameWidget.getEditorCore().widgetUI.removeControl(this.nameWidget.parts.rect);
            this.nameWidget.meshAnchor.dispose();
        }
    }

    getEditorCore() {
        return this.parent.getEditorCore();
    }
}
