<template>
    <v-dialog
        v-if="this.info?.business"
        v-model="dialog"
        max-width="800"
        scrollable
        persistent
    >
        <v-form v-model="valid" @submit.prevent="saveLead">
            <v-card>
                <v-card-title class="justify-center pa-4">
                    <span class="text-h3">Get a Quote</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                class="blue lighten-5 rounded-xl"
                            >
                                <v-img
                                    class="mx-auto ma-2"
                                    :src="info?.business.user?.picture"
                                    max-width="150"
                                    contain
                                ></v-img>
                                <p>{{ info?.business.description }}</p>
                                <v-chip
                                    class="mr-1"
                                    v-for="(location, i) in info.business
                                        .business_locations"
                                    :key="i"
                                    >{{
                                        location.administrative_area_level_1
                                    }}</v-chip
                                >
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-5">
                                <v-text-field
                                    v-model="leadComp.name"
                                    label="Name*"
                                    required
                                    :rules="[rules.required, rules.minCount]"
                                ></v-text-field>
                                <v-text-field
                                    v-model="leadComp.email"
                                    :rules="[
                                        rules.email,
                                        rules.required,
                                        rules.minCount,
                                    ]"
                                    label="Email*"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="leadComp.phone"
                                    label="Phone*"
                                    required
                                    :rules="[rules.required, rules.minCount]"
                                ></v-text-field>
                                <v-text-field
                                    v-model="leadComp.address"
                                    label="Address*"
                                    required
                                    :rules="[rules.required, rules.minCount]"
                                ></v-text-field>
                                <v-textarea
                                    v-model="leadComp.description"
                                    label="Description"
                                ></v-textarea>
                                <small>*indicates required field</small>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="toggleDialog(false)"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="green darken-1 white--text"
                        @click="saveLead"
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            valid: true,
            rules: {
                required: (value) => !!value || 'Required.',
                minCount: (value) => value.length >= 5 || 'Min 5 characters',
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail.';
                },
            },
        };
    },
    computed: {
        ...mapState('core', ['editorEngine', 'step']),
        ...mapState('business', ['info', 'dialog', 'lead', 'loading']),
        leadComp: {
            get() {
                return this.lead;
            },
            set(lead) {
                this.setLead(lead);
            },
        },
    },
    methods: {
        ...mapMutations('business', ['toggleDialog', 'setLead']),
        ...mapActions('business', ['saveLeadAction']),
        saveLead() {
            this.saveLeadAction({
                apollo: this.$apollo,
                editorEngine: this.editorEngine,
                step: this.step,
            });
        },
    },
};
</script>
