import { Component } from '../../../../Components/Base/Component';
import { Constants } from '../../../../Tools/constants';
import { Utilities } from '../../../../Tools/utilities';

export class ResizingManager extends Component {
    constructor() {
        super();
        this.lastDepth = 0.6;
    }

    resizeWidth(dimensions) {
        this.resizeCabinetBlockInWidth(dimensions);
        this.resizeCounterTopBlockInWidth(dimensions);
        this.resizeIslandBlockInWidth(dimensions);
        this.resizeSkirtingBlockInWidth(dimensions);
    }

    resizeCabinetBlockInWidth(dimensions) {
        let width = dimensions.width;
        width -= Constants.baseCabinetModel.sidePanel.WIDTH * 2;
        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);

        Utilities.resizeMeshInWidth(this.parts.cabinet.panelBottom.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cabinet.innerShelf.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cabinet.panelBack.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cabinet.panelTopBack.mesh, width);
        this.resizeDoorBlockInWidth(dimensions);
        let shift = (width - panelBottomDimensions.width) / 2;
        this.parts.cabinet.panelLeft.mesh.position.x -= shift;
        this.parts.cabinet.panelRight.mesh.position.x += shift;
    }

    resizeDoorBlockInWidth(dimensions) {
        let width = dimensions.width;
        width -= Constants.baseCabinetModel.sidePanel.WIDTH * 2;
        Utilities.resizeMeshInWidth(this.parts.door1.singleDoor.mesh, dimensions.width - Constants.GAP_BETWEEN_DOORS);
    }

    resizeCounterTopBlockInWidth(dimensions) {
        let width = dimensions.width;

        Utilities.resizeMeshInWidth(this.parts.countertop.backsplash.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.countertop.countertopBase.mesh, width);

        const backsplashDimensions = Utilities.getMeshDimensions(this.parts.countertop.backsplash.mesh);
        const shift = (width - backsplashDimensions.width) / 2;
        this.parts.countertop.countertopLeft.mesh.position.x -= shift;
        this.parts.countertop.countertopRight.mesh.position.x += shift;
    }

    resizeIslandBlockInWidth(dimensions) {
        let width = dimensions.width;
        const SIDE_SKIRTING_PIECES_WIDTH = 0.05;

        const islandBackDimensions = Utilities.getMeshDimensions(this.parts.island.islandBack.mesh);
        const islandSkirtingBackDimensions = Utilities.getMeshDimensions(this.parts.island.skirtingBack.mesh);

        const baseWidth = islandBackDimensions.width / this.parts.island.islandBack.mesh.scaling.x;
        Utilities.resizeMeshInWidth(this.parts.island.skirtingBack.mesh, width - SIDE_SKIRTING_PIECES_WIDTH * 2);

        this.parts.island.islandBack.mesh.scaling.x = width / baseWidth;
        this.parts.island.countertopBack.mesh.scaling.x = width / baseWidth;

        const shift = (width - islandBackDimensions.width) / 2;
        const sideSkirtingShift = (width - SIDE_SKIRTING_PIECES_WIDTH * 2 - islandSkirtingBackDimensions.width) / 2;
        this.parts.island.islandCountertopLeft.mesh.position.x -= shift;
        this.parts.island.islandCountertopRight.mesh.position.x += shift;
        this.parts.island.skirtingFrontLeftIsland.mesh.position.x -= sideSkirtingShift;
        this.parts.island.skirtingFrontRightIsland.mesh.position.x += sideSkirtingShift;
    }

    resizeSkirtingBlockInWidth(dimensions) {
        let width = dimensions.width;
        const SIDE_SKIRTING_PIECES_WIDTH = 0.05;
        width -= 2 * SIDE_SKIRTING_PIECES_WIDTH;

        Utilities.resizeMeshInWidth(this.parts.skirting.skirtingFrontCenter.mesh, width);
        const skirtingFrontCenterDimensions = Utilities.getMeshDimensions(this.parts.skirting.skirtingFrontCenter.mesh);
        const shift = (width - skirtingFrontCenterDimensions.width) / 2;
        this.parts.skirting.skirtingFrontLeft.mesh.position.x -= shift;
        this.parts.skirting.skirtingFrontRight.mesh.position.x += shift;
        this.parts.skirting.skirtingLeft.mesh.position.x -= shift;
        this.parts.skirting.skirtingRight.mesh.position.x += shift;
        this.parts.skirting.skirtingBackLeft.mesh.position.x -= shift;
        this.parts.skirting.skirtingBackRight.mesh.position.x += shift;
    }

    resizeDepth(depth) {
        this.resizeCabinetBlockInDepth(depth);
        this.resizeSkirtingBlockInDepth(depth);
        this.resizeCountertopBlockInDepth(depth);
        this.resizeIslandBlockInDepth(depth);
    }

    resizeCabinetBlockInDepth(depth) {
        const DOOR_DEPTH = 0.018;
        const PANEL_BOTTOM_DEPTH_DIFFERENCE = 0.07;
        const INNER_SHELF_DEPTH_DIFFERENCE = 0.13;

        const panelLeftDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelLeft.mesh);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelLeft.mesh, depth - DOOR_DEPTH);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelRight.mesh, depth - DOOR_DEPTH);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelBottom.mesh, depth - DOOR_DEPTH - PANEL_BOTTOM_DEPTH_DIFFERENCE);

        const innerShelfDimensions = Utilities.getMeshDimensions(this.parts.cabinet.innerShelf.mesh);
        Utilities.resizeMeshInDepth(this.parts.cabinet.innerShelf.mesh, depth - INNER_SHELF_DEPTH_DIFFERENCE);
        this.parts.door1.singleDoor.mesh.position.z -= (depth - DOOR_DEPTH - panelLeftDimensions.depth) / 2;
        this.parts.cabinet.panelBack.mesh.position.z += (depth - INNER_SHELF_DEPTH_DIFFERENCE - innerShelfDimensions.depth) / 2;
        this.parts.cabinet.panelTopBack.mesh.position.z +=
            (depth - INNER_SHELF_DEPTH_DIFFERENCE - innerShelfDimensions.depth) / 2;

        this.parts.door1.door1handle.mesh.position.z -= (depth - DOOR_DEPTH - panelLeftDimensions.depth) / 2;
    }

    resizeCountertopBlockInDepth(depth) {
        const COUNTERTOP_FORWARD_EXTENSION = 0.018;
        const fullDepth = depth;
        depth += COUNTERTOP_FORWARD_EXTENSION;

        Utilities.resizeMeshInDepth(this.parts.countertop.countertopBase.mesh, depth);
        Utilities.resizeMeshInDepth(this.parts.countertop.countertopLeft.mesh, depth);
        Utilities.resizeMeshInDepth(this.parts.countertop.countertopRight.mesh, depth);

        this.parts.countertop.backsplash.mesh.position.z += (fullDepth - this.lastDepth) / 2;
    }

    resizeSkirtingBlockInDepth(depth) {
        const INNER_SHELF_DEPTH_DIFFERENCE = 0.11;
        const DOOR_DEPTH = 0.018;
        const panelRightDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelRight.mesh);
        const shift = (depth - (panelRightDimensions.depth + DOOR_DEPTH)) / 2;

        Utilities.resizeMeshInDepth(this.parts.skirting.skirtingLeft.mesh, depth - INNER_SHELF_DEPTH_DIFFERENCE);
        Utilities.resizeMeshInDepth(this.parts.skirting.skirtingRight.mesh, depth - INNER_SHELF_DEPTH_DIFFERENCE);

        this.parts.skirting.skirtingFrontCenter.mesh.position.z -= shift;
        this.parts.skirting.skirtingFrontRight.mesh.position.z -= shift;
        this.parts.skirting.skirtingFrontLeft.mesh.position.z -= shift;

        const skirtingShift = (depth - DOOR_DEPTH - panelRightDimensions.depth) / 2;
        this.parts.skirting.skirtingBackLeft.mesh.position.z += skirtingShift;
        this.parts.skirting.skirtingBackRight.mesh.position.z += skirtingShift;
    }

    resizeIslandBlockInDepth(depth) {
        const DOOR_DEPTH = 0.018;
        const panelRightDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelRight.mesh);
        const shift = (depth - DOOR_DEPTH - panelRightDimensions.depth) / 2;

        this.parts.island.islandBack.mesh.position.z += shift;
        this.parts.island.countertopBack.mesh.position.z += shift;
        this.parts.island.islandCountertopLeft.mesh.position.z += shift;
        this.parts.island.islandCountertopRight.mesh.position.z += shift;
        this.parts.island.skirtingBack.mesh.position.z += shift;
        this.parts.island.skirtingFrontLeftIsland.mesh.position.z += shift;
        this.parts.island.skirtingFrontRightIsland.mesh.position.z += shift;
    }

    resizeHeight(height) {
        this.resizeCabinetBlockInHeight(height);
        this.resizeSkirtingBlockInHeight(height);
        this.resizeCounterTopBlockInHeight(height);
    }

    resizeCabinetBlockInHeight(height) {
        const SKIRTING_AND_BASE_COUNTERTOP_HEIGHT = 0.27;
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelBack.mesh, height - 0.376);
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelLeft.mesh, height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT);
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelRight.mesh, height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT);
        Utilities.resizeMeshInHeight(this.parts.door1.singleDoor.mesh, height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT);
        Utilities.resizeMeshInHeight(this.parts.island.islandBack.mesh, height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT);

        const door1Dimensions = Utilities.getMeshDimensions(this.parts.door1.singleDoor.mesh);
        this.parts.cabinet.panelTopBack.mesh.position.y +=
            (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;
        // this.parts.cabinet.panelTopFront.mesh.position.y +=
        //     (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;
        this.parts.cabinet.panelBottom.mesh.position.y -=
            (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;

        this.parts.door1.door1handle.mesh.position.y +=
            (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;
    }

    resizeSkirtingBlockInHeight(height) {
        const SKIRTING_AND_BASE_COUNTERTOP_HEIGHT = 0.27;
        const door1Dimensions = Utilities.getMeshDimensions(this.parts.door1.singleDoor.mesh);
        const shift = (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;

        this.parts.skirting.skirtingFrontCenter.mesh.position.y -= shift;
        this.parts.skirting.skirtingFrontLeft.mesh.position.y -= shift;
        this.parts.skirting.skirtingFrontRight.mesh.position.y -= shift;
        this.parts.skirting.skirtingLeft.mesh.position.y -= shift;
        this.parts.skirting.skirtingRight.mesh.position.y -= shift;
        this.parts.skirting.skirtingBackLeft.mesh.position.y -= shift;
        this.parts.skirting.skirtingBackRight.mesh.position.y -= shift;
    }

    resizeCounterTopBlockInHeight(height) {
        const SKIRTING_AND_BASE_COUNTERTOP_HEIGHT = 0.27;

        const door1Dimensions = Utilities.getMeshDimensions(this.parts.door1.singleDoor.mesh);
        const shift = (height - SKIRTING_AND_BASE_COUNTERTOP_HEIGHT - door1Dimensions.height) / 2;

        this.parts.countertop.backsplash.mesh.position.y += shift;
        this.parts.countertop.countertopBase.mesh.position.y += shift;
        this.parts.countertop.countertopLeft.mesh.position.y += shift;
        this.parts.countertop.countertopRight.mesh.position.y += shift;
        this.parts.island.countertopBack.mesh.position.y += shift;
    }
}
