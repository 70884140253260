import { Vector3, Color3, MeshBuilder } from '@babylonjs/core';

import { TextPlaneWidget } from '@/services/editor/2D/Widgets/TextPlaneWidget';
import { unitAsBjsUnit, bjsUnitAsSelectedUnit } from '@/services/editor/2D/Tools/Utilities';

export const OffsetLineWhiteSpace = 2;
const offsetStepLinesA = OffsetLineWhiteSpace * 2;
const offsetStepLinesB = OffsetLineWhiteSpace * 3;

export class FloorplanSegmentDiagramWidget {
    constructor(parent) {
        this.parent = parent;
        this.UI = parent.parent.parent.widgetUI;
        this.lines = null;
        this.distances = [];

        this.numberWidgets = [];
        this.buildNumberWidgets();

        this.dirty = true;
        this.asID = 0;
        this.update();
    }

    update() {
        if (this.lines) {
            this.lines.dispose();
            this.lines = null;
        }
        this.dirty = false;
        let engine = this.getEditorCore();

        let selectedItem = false;
        let slotUp = this.parent.slots.length > 1 ? 1 : 0;
        if (engine.controls.lastSegmentData) {
            selectedItem = engine.floorplan.segments[engine.controls.lastSegmentData.closestId];
            if (selectedItem && selectedItem.uID == this.parent.uID) {
                slotUp = 1;
            } else {
                console.log('SEGMENT Deselected:', selectedItem.uID);
                selectedItem = false;
            }
        }

        let wallUp = this.parent.getWallUp();
        let wallLeft = this.parent.getLeft().scale(this.parent.getLengthWithOffset());
        let wallLeftB = this.parent.getLeft().scale(this.parent.parent.wallThickness);
        let points = [this.parent.getAWithOffset().add(wallUp)];

        points.push(points[0].add(wallUp.scale(OffsetLineWhiteSpace + OffsetLineWhiteSpace * slotUp)));
        points.push(points[1].subtract(wallLeft));
        points.push(points[2].subtract(wallUp.scale(OffsetLineWhiteSpace + OffsetLineWhiteSpace * slotUp)));

        let lines = [points];

        let wU2 = wallUp.scale(0.25);
        let wlb2 = wallLeftB.scale(0.25);
        let dashes = [
            [points[1].add(wU2).add(wlb2), points[1].subtract(wU2).subtract(wlb2)],
            [points[2].add(wU2).add(wlb2), points[2].subtract(wU2).subtract(wlb2)],
        ];

        if (slotUp) {
            lines.push([
                points[1].subtract(wallUp.scale(OffsetLineWhiteSpace)),
                points[2].subtract(wallUp.scale(OffsetLineWhiteSpace)),
            ]);

            dashes.push(
                [lines[1][0].add(wU2).add(wlb2), lines[1][0].subtract(wU2).subtract(wlb2)],
                [lines[1][1].add(wU2).add(wlb2), lines[1][1].subtract(wU2).subtract(wlb2)]
            );
        }

        lines.push(...dashes);

        this.lines = MeshBuilder.CreateLineSystem(this.parent.uID + ':DiagramWidget', { lines }, this.scene);
        this.lines.ignorePick = true;
        this.lines.renderingGroupId = 2;
        this.lines.color = Color3.Black();

        this.checkWidgets(selectedItem);

        this.numberWidgets.forEach((w) => {
            w.update();
        });
    }

    checkWidgets(selectedItem) {
        if (!selectedItem) {
            if (this.parent.slots.length == 1) {
                if (this.numberWidgets.length != 1) {
                    this.buildNumberWidgets();
                }
            } else {
                if (this.parent.slots.length != this.numberWidgets.length - 2) {
                    this.buildNumberWidgets();
                }
            }
        } else {
            const engine = this.getEditorCore();
            const closestPoint = engine.getClosestSegmentData(engine.controls.mouse.lastSafePosition);

            if (this.asID !== closestPoint.slotId) {
                this.asID = closestPoint.slotId;
                this.buildNumberWidgets(selectedItem);
            }

            if (this.parent.slots.length == 1) {
                if (this.numberWidgets.length != 3) {
                    this.buildNumberWidgets(selectedItem);
                }
            } else {
                let count = this.parent.slots.length + 2;
                this.parent.slots.forEach((s) => {
                    if (s.fixture) {
                        count--;
                    }
                });

                //I dont understand why this is being so odd...
                // console.log(this.numberWidgets);
                // console.log('count:'+count, "this.numberWidgets:"+(this.numberWidgets.length));
                if (count != this.numberWidgets.length) {
                    this.buildNumberWidgets(selectedItem);
                }
            }
        }
    }

    buildNumberWidgets(selectedItem = false) {
        this.numberWidgets.forEach((w) => {
            w.dispose();
        });

        this.numberWidgets = [];
        let engine = this.getEditorCore();

        const widgetRotation = () => {
            if (this.parent.assignedSegment !== -1) {
                let seg = this.parent;
                let angle = -seg.angle;
                if (seg.b.x < seg.a.x) {
                    angle += Math.PI;
                }
                return angle;
            } else {
                return 0;
            }
        };

        this.numberWidgets.push(
            new TextPlaneWidget(
                () => {
                    let v = this.parent.getLengthText();
                    return v;
                },
                {
                    positionValue: () => {
                        let slotUp = this.parent.slots.length > 1 ? offsetStepLinesB : offsetStepLinesA;

                        if (engine.controls.lastSegmentData) {
                            let seg = engine.floorplan.segments[engine.controls.lastSegmentData.closestId];
                            if (seg && seg.uID == this.parent.uID) {
                                slotUp = offsetStepLinesB;
                            }
                        }
                        return this.parent.getCenter().add(this.parent.getWallUp().scale(slotUp));
                    },
                    rotationValue: widgetRotation,
                    width: 75,
                    height: 18,
                    background: 'transparent',
                    color: 'black',
                },
                this
            )
        );

        /*Cases:
        / Just Wall length:
        / slots.length == 1 && !selectedItem
        /
        / Just Wall Length and placementFixture.
        / slots.length == 1 && selectedItem
        /
        / Has Slots Filled No Placement Fixture.
        / slots.length > 1 && !selectedItem
        /
        / Has Slots Filled and placementFixture.
        / slots.length > 1 && selectedItem
        */

        //Selected item means this segment is selected currently by the user.

        if (this.parent.slots.length == 1 && !selectedItem) {
            //I think do nothing?
            return;
        } else if (this.parent.slots.length == 1 && selectedItem) {
            //Split the wall length and display the "new slots" size
            const fixtureWidth = engine.controls.selectedItem.dimensions.width; //the Fixture not the segment selected item
            this.numberWidgets.push(
                new TextPlaneWidget(
                    () => {
                        let v = bjsUnitAsSelectedUnit(
                            Vector3.Distance(engine.controls.selectedItem.getPosition(), this.parent.getAWithOffset(true))
                        );
                        let fixtureHalf = fixtureWidth * 0.5;
                        v -= fixtureHalf;
                        let u = engine.getUnitText();
                        //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                        // if (u == 'mm') {
                        v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                        // }
                        return v;
                    },
                    {
                        positionValue: () => {
                            let v = engine.value2Snap(
                                Vector3.Distance(engine.controls.selectedItem.getPosition(), this.parent.getAWithOffset())
                            );
                            let fh = unitAsBjsUnit(fixtureWidth * 0.5);
                            v = engine.value2Snap((v - fh) * 0.5);
                            return this.parent
                                .getAWithOffset()
                                .subtract(this.parent.getLeft().scale(v))
                                .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                        },
                        rotationValue: widgetRotation,
                        width: 75,
                        height: 18,
                        suffix: engine.getUnitText,
                        background: 'transparent',
                        color: 'black',
                        minValue: 0,
                    },
                    this
                ),
                new TextPlaneWidget(
                    () => {
                        let v = bjsUnitAsSelectedUnit(
                            Vector3.Distance(engine.controls.selectedItem.getPosition(), this.parent.getBWithOffset(true))
                        );
                        let fh = fixtureWidth * 0.5;
                        v = v - fh;
                        let u = engine.getUnitText();

                        v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';

                        return v;
                    },
                    {
                        positionValue: () => {
                            let v = Vector3.Distance(engine.controls.selectedItem.getPosition(), this.parent.getBWithOffset());
                            let fh = unitAsBjsUnit(fixtureWidth * 0.5);
                            v = engine.value2Snap((v - fh) * 0.5);
                            return this.parent
                                .getBWithOffset()
                                .add(this.parent.getLeft().scale(v))
                                .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                        },
                        rotationValue: widgetRotation,
                        width: 75,
                        height: 18,
                        suffix: engine.getUnitText,
                        background: 'transparent',
                        color: 'black',
                        minValue: 0,
                    },
                    this
                )
            );
        } else if (this.parent.slots.length > 1 && !selectedItem) {
            for (let s = 0; s < this.parent.slots.length; s++) {
                let slot = this.parent.slots[s];
                if (slot.fixture) {
                    continue;
                }
                //slot get get width by b - a,
                //get position by (a + b) * 0.5
                this.numberWidgets.push(
                    new TextPlaneWidget(
                        () => {
                            let v = bjsUnitAsSelectedUnit(engine.value2Snap(slot.b - slot.a));
                            let u = engine.getUnitText();
                            //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                            v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                            return v;
                        },
                        {
                            positionValue: () => {
                                let v = engine.value2Snap((slot.a + slot.b) * 0.5);
                                return this.parent
                                    .getAWithOffset()
                                    .subtract(this.parent.getLeft().scale(v))
                                    .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                            },
                            rotationValue: widgetRotation,
                            suffix: engine.getUnitText,
                            width: 75,
                            height: 18,
                            background: 'transparent',
                            color: 'black',
                            minValue: 0,
                        },
                        this
                    )
                );
            }
            return;
        } else if (this.parent.slots.length > 1 && selectedItem) {
            //Ew this part is going to be funky.
            let fWidth = engine.controls.selectedItem.dimensions.width; //the Fixture not the segment selected item.
            if (this.asID == 0) {
                let rightSlot = this.parent.slots[this.asID + 1];
                let rsP = this.parent.valueTo3dPosition(rightSlot.a);
                this.numberWidgets.push(
                    new TextPlaneWidget(
                        () => {
                            let v = bjsUnitAsSelectedUnit(
                                engine.value2Snap(
                                    Vector3.Distance(engine.controls.selectedItem.getPosition(), this.parent.getAWithOffset(true))
                                )
                            );
                            let fh = fWidth * 0.5;
                            v = v - fh;
                            let u = engine.getUnitText();
                            //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                            v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                            return v;
                        },
                        {
                            positionValue: () => {
                                let v = Vector3.Distance(
                                    engine.controls.selectedItem.getPosition(),
                                    this.parent.getAWithOffset()
                                );
                                let fh = unitAsBjsUnit(fWidth * 0.5);
                                v = engine.value2Snap((v - fh) * 0.5);
                                return this.parent
                                    .getAWithOffset()
                                    .subtract(this.parent.getLeft().scale(v))
                                    .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                            },
                            rotationValue: widgetRotation,
                            suffix: engine.getUnitText,
                            width: 75,
                            height: 18,
                            background: 'transparent',
                            color: 'black',
                            minValue: 0,
                        },
                        this
                    ),
                    new TextPlaneWidget(
                        () => {
                            let v = bjsUnitAsSelectedUnit(
                                engine.value2Snap(Vector3.Distance(engine.controls.selectedItem.getPosition(), rsP))
                            );
                            let fh = fWidth * 0.5;
                            v = v - fh;
                            let u = engine.getUnitText();
                            //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                            v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                            return v;
                        },
                        {
                            positionValue: () => {
                                let v = Vector3.Distance(engine.controls.selectedItem.getPosition(), rsP);
                                let fh = unitAsBjsUnit(fWidth * 0.5);
                                v = engine.value2Snap((v - fh) * 0.5);
                                return rsP
                                    .add(this.parent.getLeft().scale(v))
                                    .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                            },
                            rotationValue: widgetRotation,
                            suffix: engine.getUnitText,
                            background: 'transparent',
                            width: 75,
                            height: 18,
                            color: 'black',
                            minValue: 0,
                        },
                        this
                    )
                );
                for (let s = this.asID + 1; s < this.parent.slots.length; s++) {
                    let slot = this.parent.slots[s];
                    if (slot.fixture) {
                        continue;
                    }
                    this.numberWidgets.push(
                        new TextPlaneWidget(
                            () => {
                                let v = bjsUnitAsSelectedUnit(engine.value2Snap(slot.b - slot.a));
                                let u = engine.getUnitText();
                                //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                                v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                                return v;
                            },
                            {
                                positionValue: () => {
                                    let v = engine.value2Snap((slot.a + slot.b) * 0.5);
                                    return this.parent
                                        .getAWithOffset()
                                        .subtract(this.parent.getLeft().scale(v))
                                        .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                                },
                                rotationValue: widgetRotation,
                                suffix: engine.getUnitText,
                                width: 75,
                                height: 18,
                                background: 'transparent',
                                color: 'black',
                                minValue: 0,
                            },
                            this
                        )
                    );
                }
                return;
            } else if (this.asID == this.parent.slots.length - 1) {
                console.log('slots:', this.parent.slots);
                //Last Slot
                let leftSlot = this.parent.slots[this.asID - 1];
                let lsP = this.parent.valueTo3dPosition(leftSlot.b);
                this.numberWidgets.push(
                    new TextPlaneWidget(
                        () => {
                            let v = bjsUnitAsSelectedUnit(
                                engine.value2Snap(
                                    Vector3.Distance(engine.controls.selectedItem.getPosition(), this.parent.getBWithOffset(true))
                                )
                            );
                            let fh = fWidth * 0.5;
                            v = v - fh;
                            let u = engine.getUnitText();
                            //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                            v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                            return v;
                        },
                        {
                            positionValue: () => {
                                let v = Vector3.Distance(
                                    engine.controls.selectedItem.getPosition(),
                                    this.parent.getBWithOffset()
                                );
                                let fh = unitAsBjsUnit(fWidth * 0.5);
                                v = engine.value2Snap((v - fh) * 0.5);
                                return this.parent
                                    .getBWithOffset()
                                    .add(this.parent.getLeft().scale(v))
                                    .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                            },
                            rotationValue: widgetRotation,
                            suffix: engine.getUnitText,
                            width: 75,
                            height: 18,
                            background: 'transparent',
                            color: 'black',
                            minValue: 0,
                        },
                        this
                    ),
                    new TextPlaneWidget(
                        () => {
                            let v = bjsUnitAsSelectedUnit(
                                engine.value2Snap(Vector3.Distance(engine.controls.selectedItem.getPosition(), lsP))
                            );
                            let fh = fWidth * 0.5;
                            v = v - fh;
                            let u = engine.getUnitText();
                            //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                            v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                            return v;
                        },
                        {
                            positionValue: () => {
                                let v = Vector3.Distance(engine.controls.selectedItem.getPosition(), lsP);
                                let fh = unitAsBjsUnit(fWidth * 0.5);
                                v = engine.value2Snap((v - fh) * 0.5);
                                return lsP
                                    .subtract(this.parent.getLeft().scale(v))
                                    .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                            },
                            rotationValue: widgetRotation,
                            suffix: engine.getUnitText,
                            width: 75,
                            height: 18,
                            background: 'transparent',
                            color: 'black',
                            minValue: 0,
                        },
                        this
                    )
                );
                for (let s = 0; s < this.parent.slots.length - 1; s++) {
                    let slot = this.parent.slots[s];
                    if (slot.fixture) {
                        continue;
                    }
                    this.numberWidgets.push(
                        new TextPlaneWidget(
                            () => {
                                let v = bjsUnitAsSelectedUnit(engine.value2Snap(slot.b - slot.a));
                                let u = engine.getUnitText();
                                //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                                v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                                return v;
                            },
                            {
                                positionValue: () => {
                                    let v = engine.value2Snap((slot.a + slot.b) * 0.5);
                                    return this.parent
                                        .getAWithOffset()
                                        .subtract(this.parent.getLeft().scale(v))
                                        .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                                },
                                rotationValue: widgetRotation,
                                width: 75,
                                height: 18,
                                suffix: engine.getUnitText,
                                background: 'transparent',
                                color: 'black',
                                minValue: 0,
                            },
                            this
                        )
                    );
                }
                return;
            } else {
                //Not max left or right!
                let leftSlot = this.parent.slots[this.asID - 1];
                let lsP = this.parent.valueTo3dPosition(leftSlot.b);
                let rightSlot = this.parent.slots[this.asID + 1];
                let rsP = this.parent.valueTo3dPosition(rightSlot.a);

                this.numberWidgets.push(
                    new TextPlaneWidget(
                        () => {
                            let v = bjsUnitAsSelectedUnit(
                                engine.value2Snap(Vector3.Distance(engine.controls.selectedItem.getPosition(), lsP))
                            );
                            let fh = fWidth * 0.5;
                            v = v - fh;
                            let u = engine.getUnitText();
                            //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                            v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                            return v;
                        },
                        {
                            positionValue: () => {
                                let v = Vector3.Distance(engine.controls.selectedItem.getPosition(), lsP);
                                let fh = unitAsBjsUnit(fWidth * 0.5);
                                v = engine.value2Snap((v - fh) * 0.5);
                                return lsP
                                    .subtract(this.parent.getLeft().scale(v))
                                    .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                            },
                            rotationValue: widgetRotation,
                            suffix: engine.getUnitText,
                            width: 75,
                            height: 18,
                            background: 'transparent',
                            color: 'black',
                            minValue: 0,
                        },
                        this
                    ),
                    new TextPlaneWidget(
                        () => {
                            let v = bjsUnitAsSelectedUnit(
                                engine.value2Snap(Vector3.Distance(engine.controls.selectedItem.getPosition(), rsP))
                            );
                            let fh = fWidth * 0.5;
                            v = v - fh;
                            let u = engine.getUnitText();
                            //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                            v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                            return v;
                        },
                        {
                            positionValue: () => {
                                let v = Vector3.Distance(engine.controls.selectedItem.getPosition(), rsP);
                                let fh = unitAsBjsUnit(fWidth * 0.5);
                                v = engine.value2Snap((v - fh) * 0.5);
                                return rsP
                                    .add(this.parent.getLeft().scale(v))
                                    .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                            },
                            rotationValue: widgetRotation,
                            suffix: engine.getUnitText,
                            width: 75,
                            height: 18,
                            background: 'transparent',
                            color: 'black',
                            minValue: 0,
                        },
                        this
                    )
                );
                for (let s = 0; s < this.parent.slots.length; s++) {
                    let slot = this.parent.slots[s];
                    if (slot.fixture || s == engine.controls.lastSegmentData.slotId) {
                        continue;
                    }
                    this.numberWidgets.push(
                        new TextPlaneWidget(
                            () => {
                                let v = bjsUnitAsSelectedUnit(engine.value2Snap(slot.b - slot.a));
                                let u = engine.getUnitText();
                                //Some reason we cant place closer then 2mm according to the system values.  This might be float error, I think we can fix it by just -2 when in mm and we will have to see what to do with inches.
                                v = Math.round((parseFloat(v) + Number.EPSILON) * 100) / 100 + '';
                                return v;
                            },
                            {
                                positionValue: () => {
                                    let v = engine.value2Snap((slot.a + slot.b) * 0.5);
                                    return this.parent
                                        .getAWithOffset()
                                        .subtract(this.parent.getLeft().scale(v))
                                        .add(this.parent.getUp().scale(engine.floorplan.wallThickness * offsetStepLinesA));
                                },
                                rotationValue: widgetRotation,
                                suffix: engine.getUnitText,
                                width: 75,
                                height: 18,
                                background: 'transparent',
                                color: 'black',
                                minValue: 0,
                            },
                            this
                        )
                    );
                }
                return;
            }
        }
    }

    getEditorCore() {
        return this.parent.getEditorCore();
    }
}
