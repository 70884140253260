import { Constants } from '../../../Tools/constants';
import { BaseObstacleDetection } from '../../../Components/Base/BaseObstacleDetection';

export class ObstacleManager extends BaseObstacleDetection {
    constructor() {
        super();
    }

    checkForCabinetObstacles(cabinetCloneData) {
        let isBlocked = false;

        for (let index = 0; index < this.object.editor.cabinets.length; index++) {
            let cabinet = this.object.editor.cabinets[index];
            if (this.object.id !== cabinet.id) {
                if (!this.checkForCabinetObstacle(cabinetCloneData, cabinet)) {
                    isBlocked = false;
                    this.object.blockingCabinet = null;
                } else {
                    isBlocked = true;
                    this.object.blockingCabinet = cabinet;
                    break;
                }
            }
        }
        return isBlocked;
    }

    checkForCabinetObstacle(movingCabinet, cabinet) {
        if (!this.shouldConsiderObstacle(cabinet)) {
            return false;
        }
        movingCabinet.meshComponent.getMesh().computeWorldMatrix();
        cabinet.meshComponent.getMesh().computeWorldMatrix();

        if (
            movingCabinet.meshComponent.getMesh().intersectsMesh(cabinet.meshComponent.getMesh(), true) &&
            cabinet.connectedCabinet !== this.object &&
            cabinet.secondConnectedCabinet !== this.object
        ) {
            return true;
        } else {
            return false;
        }
    }

    shouldConsiderObstacle(fixture) {
        if (
            [Constants.fixture.type.OPEN_SPACE, Constants.fixture.type.DOOR, Constants.fixture.type.WINDOW].includes(
                fixture.meshComponent.getMesh().name
            ) &&
            this.object.type === 'island'
        ) {
            return false;
        }
        if (
            fixture.meshComponent.getMesh().name === Constants.fixture.type.WINDOW ||
            fixture.meshComponent.getMesh().name === Constants.fixture.type.OPEN_SPACE
        ) {
            if (
                (fixture.heightFromFloor > this.object.height && this.object.type == Constants.cabinet.type.BASE) ||
                this.object.type === 'island'
            ) {
                return false;
            }
            if (
                (this.object.type == Constants.cabinet.type.WALL &&
                    fixture.heightFromFloor > this.object.heightFromFloor + this.object.height) ||
                fixture.heightFromFloor + fixture.height < this.object.heightFromFloor
            ) {
                return false;
            }
            return true;
        } else if (
            ((this.object.name === 'Base Cabinet' || this.object.name === 'Tall Cabinet') &&
                fixture.heightFromFloor > this.object.height) ||
            (this.object.name === 'Wall Cabinet' &&
                fixture.height < this.object.heightFromFloor &&
                fixture.name !== 'Wall Cabinet')
        ) {
            return false;
        } else {
            return true;
        }
    }

    getObstacleNearCornerA() {
        let obstacleInfo = this.findNearestObstacles(['wall']);
        let obstacle = obstacleInfo.closestFixtureFromPointA;
        if (obstacle?.meshComponent.getMesh().type === 'fixture') {
            obstacle = null;
        }
        return obstacle;
    }

    getObstacleNearCornerB() {
        let obstacleInfo = this.findNearestObstacles(['wall']);
        let obstacle = obstacleInfo.closestFixtureFromPointB;
        if (obstacle?.meshComponent.getMesh().type === 'fixture') {
            obstacle = null;
        }
        return obstacle;
    }
}
