<template>
    <v-stepper-content step="3" class="pa-2">
        <!-- Add Appliances Buttons -->
        <v-row v-if="!selectedAppliance && !selectedCabinet" class="pa-2">
            <v-col cols="8" md="9">
                <v-row class="blue-grey lighten-4 rounded-lg">
                    <v-slide-group show-arrows class="mx-auto">
                        <v-slide-item
                            v-for="(appliance, key) in appliances"
                            :key="key"
                        >
                            <v-hover v-slot:default="{ hover }">
                                <v-card
                                    :elevation="hover ? 16 : 2"
                                    class="pa-1 ma-2"
                                    :width="
                                        $vuetify.breakpoint.smAndUp ? 150 : 100
                                    "
                                    @click="
                                        (openDialog = true),
                                            setModalAppliance(appliance)
                                    "
                                >
                                    <v-card-text
                                        class="
                                            py-2
                                            text-caption
                                            text-center
                                            text-subtitle-2
                                        "
                                    >
                                        <v-img
                                            :src="appliance.img"
                                            height="60"
                                            contain
                                            class="pa-2"
                                        ></v-img>
                                    </v-card-text>

                                    <v-card-subtitle
                                        class="text-center py-1"
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        >{{ appliance.name }}</v-card-subtitle
                                    >
                                </v-card>
                            </v-hover>
                        </v-slide-item>
                    </v-slide-group>
                </v-row>
            </v-col>

            <v-dialog
                v-model="openDialog"
                max-width="800px"
                :fullscreen="$vuetify.breakpoint.xsOnly"
            >
                <ApplianceDialog v-if="openDialog"></ApplianceDialog>
            </v-dialog>

            <StepsButtons></StepsButtons>
        </v-row>

        <!-- Edit Cabinet -->
        <v-container fluid v-else-if="selectedAppliance" class="pa-0">
            <ApplianceEdit></ApplianceEdit>
        </v-container>

        <v-container v-else-if="selectedCabinet">
            <CabinetEdit></CabinetEdit>
        </v-container>
    </v-stepper-content>
</template>

<script>
import ApplianceEdit from './ApplianceEdit';
import StepsButtons from './StepsButtons';
import { mapGetters, mapState } from 'vuex';
import ApplianceDialog from './ApplianceDialog';
import CabinetEdit from '../Step2DrawCabinets/CabinetEdit.vue';

export default {
    components: {
        StepsButtons,
        ApplianceEdit,
        ApplianceDialog,
        CabinetEdit,
    },
    computed: {
        ...mapState('cabinets', ['selectedCabinet']),
        ...mapState('appliances', ['appliances', 'selectedAppliance']),
        ...mapGetters('core', ['selectedUnit', 'editorEngine']),
        openDialog: {
            set(openDialog) {
                this.$store.commit('appliances/openDialog', openDialog);
            },
            get() {
                return this.$store.state.appliances.openDialog;
            },
        },
    },
    methods: {
        setModalAppliance(fixture) {
            this.$store.commit('appliances/setModalAppliance', fixture);
        },
    },
};
</script>
