<template>
    <div>
        <v-alert v-if="selectedAppliance && object.length" type="error">
            More than one appliance and objects selected. Select only one
            appliance or multiple objects of the same type to edit.
        </v-alert>

        <v-alert v-else-if="!selectedAppliance && !isTypeSame" type="error">
            Not same type selected
        </v-alert>

        <!-- Edit Cabinet -->
        <v-container fluid v-else-if="selectedAppliance" class="pa-0">
            <ApplianceEdit></ApplianceEdit>
        </v-container>

        <v-tabs
            v-else
            v-model="tab"
            background-color="indigo"
            centered
            height="38"
            dark
            slider-size="5"
            show-arrows
        >
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-btn
                color="indigo lighten-2"
                small
                class="my-auto"
                @click="deselectObjects"
            >
                <v-icon color="indigo darken-3">{{
                    mdiArrowLeftCircle
                }}</v-icon>
            </v-btn>

            <v-tab
                class="float-left"
                v-if="$vuetify.breakpoint.smAndUp"
                disabled
                >{{ object[0].type }}, {{ object[0].subtype }}</v-tab
            >
            <v-tab v-if="dimensionProps && !isManySelected && !isBuiltinHood"
                ><v-icon>{{ mdiTapeMeasure }}</v-icon></v-tab
            >
            <v-tab
                ><v-icon>{{ mdiPaletteOutline }}</v-icon></v-tab
            >
            <v-tab v-if="config.doorType"
                ><v-icon>{{ mdiFileCabinet }}</v-icon></v-tab
            >

            <v-tabs-items continuous v-model="tab">
                <!-- Empty for object type/subtype -->
                <v-tab-item v-if="$vuetify.breakpoint.smAndUp"></v-tab-item>

                <!-- Dimensions Tab -->
                <v-tab-item
                    v-if="dimensionProps && !isManySelected && !isBuiltinHood"
                >
                    <v-container fluid>
                        <v-alert v-if="!isSubtypeSame" type="error">
                            Not same subtype selected
                        </v-alert>
                        <v-row
                            v-else
                            justify="center"
                            class="indigo lighten-5 pt-2"
                        >
                            <v-col
                                v-for="(value, key) in dimensionProps"
                                :key="key"
                                cols="6"
                                sm="3"
                                class="pa-2"
                                align-self="start"
                            >
                                <!-- Dimension Input -->
                                <v-form :ref="key">
                                    <v-combobox
                                        v-model="dimensionProps[key].value"
                                        @input="resize(key)"
                                        :items="
                                            config.dimensions[key].items[
                                                selectedUnit
                                            ]
                                        "
                                        :label="key"
                                        :ref="key"
                                        outlined
                                        dense
                                        required
                                        subtype="number"
                                        :rules="[
                                            rules.required,
                                            rules.between(
                                                config.dimensions[key].min[
                                                    selectedUnit
                                                ],
                                                config.dimensions[key].max[
                                                    selectedUnit
                                                ]
                                            ),
                                        ]"
                                        :suffix="selectedUnit"
                                        :disabled="key === 'height' || disableCornerInput"
                                    >
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        Press
                                                        <kbd>enter</kbd> to
                                                        update
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                                </v-form>

                                <!-- Direction -->
                                <v-btn-toggle
                                    v-if="key === 'width'"
                                    v-model="
                                        dimensionProps[key].direction.value
                                    "
                                    @change="changeDirection($event, key)"
                                    tile
                                    color="primary"
                                    mandatory
                                    class="d-flex justify-center"
                                    background-color="indigo lighten-5"
                                >
                                    <v-btn
                                        v-for="direction in dimensionProps[key]
                                            .direction.items"
                                        :key="direction"
                                        :value="direction"
                                        small
                                    >
                                        <v-icon>
                                            {{
                                                mdiArrowDirection(
                                                    key,
                                                    direction
                                                )
                                            }}
                                        </v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

                <!-- Appearance Tab -->
                <v-tab-item>
                    <div class="text-center ma-3">
                        <v-bottom-sheet
                            v-for="(collection, i) in config.collections"
                            :key="collection.name"
                            v-model="collection.sheet"
                            inset
                        >
                            <!-- Collection Buttons -->
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    outlined
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-2"
                                >
                                    {{ collection.name }}
                                </v-btn>
                            </template>

                            <!-- Collection Sheet -->
                            <v-sheet class="text-center">
                                <EditDesignCollectionSheet
                                    :collection="collection"
                                    :collIdx="i"
                                    :isTypeSame="isTypeSame"
                                    :config="config"
                                />
                            </v-sheet>
                        </v-bottom-sheet>
                    </div>
                </v-tab-item>

                <!-- Door Type -->
                <v-tab-item v-if="config.doorType">
                    <v-container fluid class="pa-0">
                        <v-alert v-if="!isSubtypeSame" type="error">
                            Not same subtype selected
                        </v-alert>
                        <v-slide-group
                            v-else
                            mandatory
                            show-arrows
                            class="mx-auto"
                            :value="object[0].door.type"
                            @change="changeDoorType($event)"
                        >
                            <v-slide-item
                                v-for="doorType in doorTypeArr"
                                :key="doorType.id"
                                :value="doorType.id"
                                v-slot="{ active, toggle }"
                            >
                                <v-hover v-slot:default="{ hover }">
                                    <v-card
                                        :color="
                                            active ? 'green lighten-1' : null
                                        "
                                        :elevation="hover ? 16 : 2"
                                        class="pa-1 ma-2"
                                        :width="
                                            $vuetify.breakpoint.smAndUp
                                                ? 100
                                                : 100
                                        "
                                        @click="toggle"
                                    >
                                        <v-card-text
                                            class="py-2 text-center"
                                        >
                                            <v-img
                                                :src="doorType.img"
                                                height="50"
                                                contain
                                                class="pa-2"
                                            ></v-img>
                                        </v-card-text>

                                        <v-card-subtitle
                                            class="px-0 text-caption text-center py-1"
                                            v-if="$vuetify.breakpoint.smAndUp"
                                            >{{
                                                doorType.name
                                            }}</v-card-subtitle
                                        >
                                    </v-card>
                                </v-hover>
                            </v-slide-item>
                        </v-slide-group>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </div>
</template>

<script>
import {
    mdiArrowLeftCircle,
    mdiTapeMeasure,
    mdiPaletteOutline,
    mdiFileCabinet,
    mdiArrowSplitHorizontal,
    mdiArrowSplitVertical,
    mdiArrowLeftThick,
    mdiArrowRightThick,
    mdiArrowUpThick,
    mdiArrowDownThick,
} from '@mdi/js';
import { mapState, mapGetters, mapMutations } from 'vuex';
import ApplianceEdit from '@/components/stepper/Step3AddAppliances/ApplianceEdit.vue';
import EditDesignCollectionSheet from '@/components/stepper/Step4Generated/EditDesignCollectionSheet.vue';

export default {
    components: {
        ApplianceEdit,
        EditDesignCollectionSheet,
    },
    data() {
        return {
            tab: null,
            isValid: true,
            rules: {
                required: (value) => !!value || value === 0 || 'Required',
                between(min, max) {
                    return (value) =>
                        (value >= min && value <= max) ||
                        `Must be between ${min} and ${max}`;
                },
            },
            mdiFileCabinet: mdiFileCabinet,
            mdiTapeMeasure: mdiTapeMeasure,
            mdiPaletteOutline: mdiPaletteOutline,
            mdiArrowLeftCircle: mdiArrowLeftCircle,
            mdiArrows: {
                horizontal: {
                    left: mdiArrowLeftThick,
                    right: mdiArrowRightThick,
                    split: mdiArrowSplitVertical,
                },
                vertical: {
                    up: mdiArrowUpThick,
                    down: mdiArrowDownThick,
                    split: mdiArrowSplitHorizontal,
                },
            },
            direction: {
                width: 'split',
                height: 'split',
            },
        };
    },
    computed: {
        ...mapGetters('core', ['selectedUnit']),
        ...mapState('generated', {
            object: (state) => state.selectedObject,
            objects: (state) => state.objects,
        }),
        ...mapState('appliances', ['selectedAppliance']),
        isManySelected() {
            if (this.object.length > 1) return true;
            else return false;
        },
        isTypeSame() {
            return this.object.every((o) => o.type === this.object[0].type);
        },
        isSubtypeSame() {
            return this.object.every(
                (o) => o.subtype === this.object[0].subtype
            );
        },
        dimensionProps() {
            const dimensions = this.object[0].dimensions;

            if (!dimensions?.width) return null;

            return {
                width: {
                    value: dimensions.width,
                    direction: {
                        value: this.direction.width,
                        items: ['left', 'split', 'right'],
                    },
                },
                height: {
                    value: dimensions.height,
                    direction: {
                        value: this.direction.height,
                        items: ['up', 'split', 'down'],
                    },
                },
            };
        },
        // Go through doorType array and find styles
        // for specific cabinet subtype only.
        doorTypeArr() {
            const allTypes = this.config.doorType;

            const relevantTypes = allTypes.filter((type) => {
                for (const cabinetType of type.cabinetType) {
                    if (cabinetType === this.object[0].subtype) {
                        return true;
                    }
                }
            });

            return relevantTypes;
        },
        config() {
            const firstIdx = this.object[0];
            const type = firstIdx.type,
                subtype = firstIdx.subtype,
                objectType = this.objects[type];
            return {
                objectType: type,
                dimensions:
                    type === 'cabinets'
                        ? objectType?.dimensions[subtype]
                        : null,
                doorType: type === 'cabinets' ? objectType?.doorType : null,
                collections: objectType.collections,
            };
        },
        isBuiltinHood() {
            const parentAppliance = this.object[0].parentSection?.appliance;
            if (
                parentAppliance?.type === 'hood' &&
                parentAppliance?.subtype === 'builtin'
            ) {
                return true;
            } else return false;
        },
        disableCornerInput() {
            if (
                this.object.length &&
                this.object[0]?.subtype === 'baseCorner' ||
                this.object[0]?.subtype === 'wallCorner' ||
                this.object[0]?.subtype === 'tallCorner'
            ) {
                return true;
            } else return false;
        },
    },
    methods: {
        ...mapMutations('generated', ['setTab']),
        resize(key) {
            const oldVal = this.object[0].dimensions[key];
            // loop over selected objects and set dimension for each

            // console.log(
            //     'direction',
            //     this.dimensionProps[key].direction.value,
            //     this.dimensionProps[key].width.value
            // );
            if (this.$refs[key][0].validate()) {
                // this.object.resize(this.object.dimensions[key], key);
                console.log(
                    'this.dimensionProps[key].direction.value',
                    this.dimensionProps[key].direction.value
                );

                this.object[0].parentSection.resizeInPlace(
                    this.dimensionProps[key].value / 1000,
                    this.dimensionProps[key].direction.value
                );
            } else {
                this.object[0].dimensions[key] = oldVal;
            }
        },
        changeDirection(event, key) {
            console.log('change direction', event);
            this.direction.width = event;
        },
        changeDoorType(val) {
            console.log(val, this.object);
            for (let index = 0; index < this.object.length; index++) {
                this.object[index].changeDoorType(val);
            }
        },
        deselectObjects() {
            // turn off objects bounding boxes
            const selectedObjects = this.$store.state.generated.selectedObject;
            for (let index = 0; index < selectedObjects.length; index++) {
                selectedObjects[index].deselect();
                index--;
            }

            this.$store.commit('generated/setSelectedObject', []);
        },
        mdiArrowDirection(key, direction) {
            const orientation = key === 'width' ? 'horizontal' : 'vertical';
            return this.mdiArrows[orientation][direction];
        },
    },
    watch: {
        tab(val) {
            this.setTab(val);
        }
    }
};
</script>
