import { Vector3 } from '@babylonjs/core';
import { Component } from '../../../Components/Base/Component';
import { Constants } from '../../../Tools/constants';
import { BaseAppliance } from '../../Appliances/BaseAppliance';
import { BaseUnit } from '../../Cabinets/BaseUnit';

export class SlotManager extends Component {
    constructor() {
        super();
        this.slots = [];
    }

    getWallSlots(wall, typesOfObjectsToConsider) {
        const slotPoints = this.sortSlotPoints(wall, typesOfObjectsToConsider);
        this.slots['wall' + wall.id] = [];
        for (let index = 0; index < slotPoints.length - 1; index++) {
            let object = slotPoints[index].object;
            if (slotPoints[index].object !== slotPoints[index + 1].object) {
                object = null;
            }

            this.slots['wall' + wall.id].push({
                firstPoint: slotPoints[index].point,
                secondPoint: slotPoints[index + 1].point,
                object: object,
            });
        }

        let numberOfSlots = this.slots['wall' + wall.id].length;
        if (numberOfSlots > 2) {
            if (
                !this.slots['wall' + wall.id][0].object &&
                this.slots['wall' + wall.id][1].object instanceof BaseUnit &&
                this.slots['wall' + wall.id][1].object.secondConnectedCabinet
            ) {
                this.slots['wall' + wall.id][0].object = 'connectedCabinet';
            }

            if (
                !this.slots['wall' + wall.id][numberOfSlots - 1].object &&
                this.slots['wall' + wall.id][numberOfSlots - 2].object instanceof BaseUnit &&
                this.slots['wall' + wall.id][numberOfSlots - 2].object.connectedCabinet
            ) {
                this.slots['wall' + wall.id][numberOfSlots - 1].object = 'connectedCabinet';
            }
        }
    }

    sortSlotPoints(wall, typesOfObjectsToConsider) {
        const objectsOnWall = wall.getObjectsOnWall();
        let slotPoints = [];

        slotPoints.push({
            point: wall.baseInnerCornerB,
            object: null,
            distanceFromCornerB: 0,
        });
        for (let index = 0; index < objectsOnWall.length; index++) {
            if (objectsOnWall[index].type === 'oven' && objectsOnWall[index].subtype === 'ovenSignle') {
                continue;
            }
            if (
                typesOfObjectsToConsider.includes(objectsOnWall[index].type) ||
                (typesOfObjectsToConsider.includes('appliance') &&
                    objectsOnWall[index] instanceof BaseAppliance &&
                    objectsOnWall[index].type !== Constants.appliance.type.HOOD)
            ) {
                const blockedSegmentOnWall = objectsOnWall[index].getEdgesOnWall();
                slotPoints.push(
                    {
                        point: blockedSegmentOnWall.firstPoint,
                        object: objectsOnWall[index],
                        distanceFromCornerB: Vector3.Distance(blockedSegmentOnWall.firstPoint, wall.baseInnerCornerB),
                    },
                    {
                        point: blockedSegmentOnWall.secondPoint,
                        object: objectsOnWall[index],
                        distanceFromCornerB: Vector3.Distance(blockedSegmentOnWall.secondPoint, wall.baseInnerCornerB),
                    }
                );
            }
        }

        slotPoints.push({
            point: wall.baseInnerCornerA,
            object: null,
            distanceFromCornerB: wall.length,
        });

        slotPoints.sort((a, b) => {
            return a.distanceFromCornerB > b.distanceFromCornerB ? 1 : -1;
        });
        if (slotPoints[0].object instanceof BaseUnit || slotPoints[0].object instanceof BaseAppliance) {
            let temp = slotPoints[0];
            slotPoints[0] = slotPoints[1];
            slotPoints[1] = temp;
        }
        if (
            slotPoints[slotPoints.length - 1].object instanceof BaseUnit ||
            slotPoints[slotPoints.length - 1].object instanceof BaseAppliance
        ) {
            let temp = slotPoints[slotPoints.length - 1];
            slotPoints[slotPoints.length - 1] = slotPoints[slotPoints.length - 2];
            slotPoints[slotPoints.length - 2] = temp;
        }
        return slotPoints;
    }

    getAllSlots(typesOfCabinetsToConsider) {
        let walls = this.object.walls;
        this.slots = [];
        for (let index = 0; index < walls.length; index++) {
            this.getWallSlots(walls[index], typesOfCabinetsToConsider);
        }

        return this.slots;
    }
}
