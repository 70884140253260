import { Color3, MeshBuilder, Ray, Vector3 } from '@babylonjs/core';
import { Component } from '../../../Components/Base/Component';
import { Text } from '../../../Components/Text';
import { Constants } from '../../../Tools/constants';
import { Utilities } from '../../../Tools/utilities';
import store from '@/store/index.js';
import { RoomObject } from '../../Room/RoomObject';

export class DistanceMeasurements extends Component {
    rays = {
        topRay: null,
        bottomRay: null,
        leftRay: null,
        rightRay: null,
    };

    obstacleData = {
        top: null,
        bottom: null,
        left: null,
        right: null,
    };

    lineSystem;
    linkedMeshes = [];

    constructor() {
        super();
    }

    checkForNearestObstacles() {
        const edgePoints = this.object.getEdgePoints();
        let topPointDirection = new Vector3(0, 0, -1);
        let bottomPointDirection = new Vector3(0, 0, 1);
        let leftPointDirection = new Vector3(1, 0, 0);
        let rightPointDirection = new Vector3(-1, 0, 0);

        if (this.object.rotation === 90 || this.object.rotation === 270) {
            topPointDirection = new Vector3(0, 0, 1);
            bottomPointDirection = new Vector3(0, 0, -1);
            leftPointDirection = new Vector3(1, 0, 0);
            rightPointDirection = new Vector3(-1, 0, 0);
        }
        const topHit = this.castRay(edgePoints.topPoint, topPointDirection, 'top');
        const bottomHit = this.castRay(edgePoints.bottomPoint, bottomPointDirection, 'bottom');
        const leftHit = this.castRay(edgePoints.leftPoint, leftPointDirection, 'left');
        const rightHit = this.castRay(edgePoints.rightPoint, rightPointDirection, 'right');

        if (topHit.pickedMesh && bottomHit.pickedMesh && leftHit.pickedMesh && rightHit.pickedMesh) {
            this.obstacleData = {
                top: topHit,
                bottom: bottomHit,
                left: leftHit,
                right: rightHit,
            };
        }
    }

    castRay(point, direction, side) {
        const origin = point;
        let length = 100;
        if (!this.rays[side + 'Ray']) {
            this.rays[side + 'Ray'] = new Ray(origin, direction, length);
        } else {
            this.rays[side + 'Ray'].origin = point;
            this.rays[side + 'Ray'].direction = direction;
        }

        this.object.meshComponent.getMesh().isMoving = true;
        let hit = this.object.editor.sceneComponent.get().pickWithRay(this.rays[side + 'Ray'], this.pickingPredicate);
        this.object.meshComponent.getMesh().isMoving = false;
        return hit;
    }

    pickingPredicate(mesh) {
        if (
            mesh.isMoving ||
            mesh.isClone ||
            mesh.parent?.isClone ||
            mesh.id === 'ray' ||
            ['blockedArea', 'lineSystem', 'frontHandle', 'backHandle', 'leftHandle', 'rightHandle'].includes(mesh.name)
        ) {
            return false;
        } else {
            return true;
        }
    }

    addSlotLength(length) {
        const textBlock = new Text();
        textBlock.setValue(length);
        return textBlock;
    }

    buildLinkedMesh(lineCoordinates) {
        for (let index = 0; index < lineCoordinates.length; index++) {
            let length = Vector3.Distance(lineCoordinates[index][0], lineCoordinates[index][1]).toFixed(3) * 1000;
            this.linkedMeshes[index] = {
                linkedMesh: MeshBuilder.CreateBox('linkedMesh', { width: 0.1, height: 0.1, depth: 0.1 }),
                linkedMeshPosition: Utilities.findMiddlePointBetween(lineCoordinates[index][0], lineCoordinates[index][1]),
                length: length,
                lengthField: this.addSlotLength(length).textBlock,
            };
            this.linkedMeshes[index].linkedMesh.setEnabled(false);
        }
        return;
    }

    updateLength(slots) {
        for (let index = 0, linkedMeshIndex = 0; index < slots.length; index++, linkedMeshIndex++) {
            this.linkedMeshes[linkedMeshIndex].linkedMesh.dispose();
            this.linkedMeshes[linkedMeshIndex].linkedMesh = MeshBuilder.CreateBox('linkedMesh', {
                width: 0.1,
                height: 0.1,
                depth: 0.1,
            });
            this.linkedMeshes[linkedMeshIndex].linkedMesh.setEnabled(false);
            this.linkedMeshes[linkedMeshIndex].linkedMeshPosition = Utilities.findMiddlePointBetween(
                slots[index][0],
                slots[index][1]
            );
            this.linkedMeshes[linkedMeshIndex].linkedMesh.position = this.linkedMeshes[linkedMeshIndex].linkedMeshPosition;

            this.linkedMeshes[linkedMeshIndex].length =
                Vector3.Distance(slots[index][0], slots[index][1]).toFixed(3) * Constants.fixture.scale;
            if (store.getters['core/selectedUnit'] === 'in') {
                this.linkedMeshes[linkedMeshIndex].length = (
                    this.linkedMeshes[linkedMeshIndex].length / Constants.unit.INCH_STEP
                ).toFixed(3);
            }
            this.linkedMeshes[linkedMeshIndex].lengthField.text = this.linkedMeshes[linkedMeshIndex].length.toString();
            store.getters['core/editorEngine'].widgetUI.addControl(this.linkedMeshes[linkedMeshIndex].lengthField);

            this.linkedMeshes[linkedMeshIndex].lengthField.linkWithMesh(this.linkedMeshes[linkedMeshIndex].linkedMesh);
            this.linkedMeshes[linkedMeshIndex].lengthField.linkOffsetY = -10;

            this.linkedMeshes[linkedMeshIndex] = {
                linkedMesh: this.linkedMeshes[linkedMeshIndex].linkedMesh,
                linkedMeshPosition: this.linkedMeshes[linkedMeshIndex].linkedMeshPosition,
                length: this.linkedMeshes[linkedMeshIndex].length,
                lengthField: this.linkedMeshes[linkedMeshIndex].lengthField,
            };
        }
    }
    calculateLineSystemCoordinates() {
        let lineCoordinates = [];
        const edgePoints = this.object.getEdgePoints();

        const topObstaclePosition = this.calculateObstacleEdgePoint(this.obstacleData.top, edgePoints.topPoint);
        const bottomObstaclePosition = this.calculateObstacleEdgePoint(this.obstacleData.bottom, edgePoints.bottomPoint);
        const leftObstaclePosition = this.calculateObstacleEdgePoint(this.obstacleData.left, edgePoints.leftPoint);
        const rightObstaclePosition = this.calculateObstacleEdgePoint(this.obstacleData.right, edgePoints.rightPoint);

        lineCoordinates.push(
            [edgePoints.topPoint, topObstaclePosition],
            [edgePoints.bottomPoint, bottomObstaclePosition],
            [edgePoints.leftPoint, leftObstaclePosition],
            [edgePoints.rightPoint, rightObstaclePosition]
        );
        return lineCoordinates;
    }

    calculateObstacleEdgePoint(obstaclePosition, objectEdgePoint) {
        obstaclePosition = obstaclePosition.pickedPoint;
        objectEdgePoint.y = Constants.cabinet.measurementLine.MARGIN;
        obstaclePosition.y = Constants.cabinet.measurementLine.MARGIN;
        return obstaclePosition;
    }

    build() {
        this.checkForNearestObstacles();
        let lineCoordinates;
        lineCoordinates = this.calculateLineSystemCoordinates();
        if (this.linkedMeshes.length === 0) {
            this.buildLinkedMesh(lineCoordinates);
        }
        this.updateLength(lineCoordinates);
        if (!this.lineSystem) {
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        } else {
            this.lineSystem.dispose();
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        }
        this.lineSystem.color = new Color3(0, 0, 0);
    }

    update() {
        this.dispose();
        this.build();
    }

    dispose() {
        if (this.lineSystem) {
            this.lineSystem.dispose();
            for (let index = 0; index < this.linkedMeshes.length; index++) {
                this.linkedMeshes[index].linkedMesh.dispose();
                this.linkedMeshes[index].lengthField.dispose();
            }
        }
    }
}
