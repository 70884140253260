import { MeshBuilder, VertexBuffer } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { BuildingBlock } from './BuildingBlock';
import store from '@/store/index.js';

export class Floor extends BuildingBlock {
    customization;
    constructor() {
        super();
    }

    build() {
        if (!this.meshComponent.getMesh()) {
            this.meshComponent.setMesh(MeshBuilder.CreatePlane('floor', { updatable: true }, this.editor.sceneComponent.get()));
        }
        this.meshComponent.setType('floor');
        let positions = this.meshComponent.getMesh().getVerticesData(VertexBuffer.PositionKind);
        const heightMargin = 0.01;

        const calculatedPositions = this.calculateCoordinates();
        positions = [
            calculatedPositions.downLeft.x,
            heightMargin,
            calculatedPositions.downLeft.y,
            calculatedPositions.downRight.x,
            heightMargin,
            calculatedPositions.downLeft.y,
            calculatedPositions.upRight.x,
            heightMargin,
            calculatedPositions.upLeft.y,
            calculatedPositions.upLeft.x,
            heightMargin,
            calculatedPositions.upLeft.y,
        ];
        this.meshComponent.getMesh().updateVerticesData(VertexBuffer.PositionKind, positions);
        this.meshComponent.getMesh().bakeCurrentTransformIntoVertices();
        this.meshComponent.getMesh().alwaysSelectAsActiveMesh = true;
        this.addTexture();
        this.setActions();
        this.setInfo('floor');
    }

    calculateCoordinates() {
        const planeData = this.editor.sceneInfo.corners;
        let horizontalExtremes = { right: 0, left: 0 };
        let verticalExtremes = { up: 0, down: 0 };

        for (let index = 0; index < planeData.length; index++) {
            if (planeData[index].x < horizontalExtremes.left) {
                horizontalExtremes.left = planeData[index].x;
            } else if (planeData[index].x > horizontalExtremes.right) {
                horizontalExtremes.right = planeData[index].x;
            }

            if (planeData[index].y > verticalExtremes.up) {
                verticalExtremes.up = planeData[index].y;
            } else if (planeData[index].y < verticalExtremes.down) {
                verticalExtremes.down = planeData[index].y;
            }
        }

        return {
            upLeft: { x: horizontalExtremes.left, y: verticalExtremes.up },
            upRight: { x: horizontalExtremes.right, y: verticalExtremes.up },
            downLeft: { x: horizontalExtremes.left, y: verticalExtremes.down },
            downRight: { x: horizontalExtremes.right, y: verticalExtremes.down },
        };
    }

    addTexture() {
        let value = store.state.generated.objects.floor.collections[0].color.items[0];
        let color = '#FFFFFF';
        if (store.state.core.projectData.floor) {
            value = store.state.core.projectData.floor.value;
        }
        const pbr = this.editor.PBRComponent.getTextureFromPool(
            'texture',
            value,
            color,
            Constants.glossines.floor.MICROSURFACE,
            Constants.glossines.floor.CLEAR_COAT,
            ''
        );
        this.meshComponent.getMesh().material = pbr;
    }
}
