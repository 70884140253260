import router from '@/router';
import saveBusinessDomainLeadAction from '@/graphql/queries/saveBusinessDomainLeadAction.gql';
import updateProjectLeadAction from '@/graphql/queries/updateProjectLeadAction.gql';

export const business = {
    namespaced: true,
    state: {
        isStandardDomain: null,
        info: null,
        costing: null,
        dialog: false,
        updateDialog: false,
        updateCode: null,
        lead: {
            email: '',
            name: '',
            description: '',
            phone: '',
            address: '',
        },
        loading: false,
        alert: { message: null, type: null },
        totalPrice: 0,
    },
    mutations: {
        toggleStandardDomain(state, val) {
            state.isStandardDomain = val;
        },
        setBusinessInfo(state, val) {
            state.info = val;
        },
        setBusinessCosting(state, val) {
            state.costing = val;
        },
        toggleDialog(state, val) {
            state.dialog = val;
        },
        toggleUpdateDialog(state, val) {
            state.updateDialog = val;
        },
        setLead(state, val) {
            state.lead = val;
        },
        setUpdateCode(state, val) {
            state.updateCode = val;
        },
        toggleLoading(state, val) {
            state.loading = val;
        },
        setAlert(state, {message, type}) {
            state.alert = {message, type};
        },
        setTotalPrice(state, val) {
            state.totalPrice = val;
        }
    },
    actions: {
        saveLeadAction({ state, rootState, commit }, { apollo, editorEngine, step }) {
            commit('toggleLoading', true);

            if (step === 1) {
                editorEngine.controls.save2D();
            } else if (
                step === 2 ||
                step === 3 ||
                step === 4
            ) {
                editorEngine.sceneComponent.save3D();
            }

            apollo
                .mutate({
                    mutation: saveBusinessDomainLeadAction,
                    variables: {
                        data: JSON.stringify(rootState.core.projectData),
                        business_id: state.info.businessId,
                        email: state.lead.email,
                        name: state.lead.name,
                        description: state.lead.description,
                        phone: state.lead.phone,
                        address: state.lead.address,
                    },
                })
                .then((data) => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('savedLead', data.data);
                    }
                    const projectId =
                        data.data.saveBusinessDomainLead.projectId;
                    router.replace({ params: { projectId } });
                    router.go(); // refresh because of BJS issue on adding projectId
                })
                .catch((error) => {
                    console.error(error);
                    commit('core/logError', JSON.stringify(error.message), { root: true })
                });
        },
        updateLeadAction({ state, rootState, commit }, { apollo, projectId }) {
            commit('toggleLoading', true);
            const projectData = rootState.core.projectData;

            apollo
                .mutate({
                    mutation: updateProjectLeadAction,
                    variables: {
                        projectId,
                        updateCode: state.updateCode,
                        data: JSON.stringify(projectData),
                    },
                })
                .then((data) => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('updateProject', data.data.updateProjectLead);
                    }

                    commit('setAlert', {message: data.data.updateProjectLead.message, type: 'success'});
                    
                    commit('toggleLoading', false);
                    setTimeout(() => {
                        commit('toggleUpdateDialog', false);
                    }, 5000);
                })
                .catch((error) => {
                    console.error(error);
                    commit('setAlert', {message: JSON.stringify(error.message), type: 'error'});
                    commit('toggleLoading', false);
                });
        }
    },
};
