import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router/index';
import store from './store/index';
import VueApollo from 'vue-apollo';
import { apolloProvider } from '@/graphql/vue-apollo';
import SuperTokens from 'supertokens-website';

Vue.config.productionTip = false;

SuperTokens.init({
    apiDomain: process.env.VUE_APP_AUTH_DOMAIN,
    apiBasePath: '/auth',
    sessionScope: process.env.VUE_APP_SESSION_SCOPE
});
Vue.prototype.$auth = SuperTokens;

Vue.use(VueApollo);

new Vue({
    vuetify,
    router,
    store,
    apolloProvider,
    render: (h) => h(App),
}).$mount('#app');
