import { ShaderMaterial, Vector3 } from '@babylonjs/core';
import { Constants } from './../../Tools/constants';
import { Utilities } from './../../Tools/utilities';
import { Island } from '../../KitchenObjects/Cabinets/Island';
import { BaseCabinet } from '../../KitchenObjects/Models3D/BaseUnit/BaseNormalSection/BaseCabinet';
import { BaseFillerSection } from '../../KitchenObjects/Models3D/Fillers/BaseFillerSection';
import { WallCabinet } from '../../KitchenObjects/Models3D/WallUnit/WallCabinet';
import { TallCabinet } from '../../KitchenObjects/Models3D/TallUnit/TallCabinet';
import { TallFillerSection } from '../../KitchenObjects/Models3D/Fillers/TallFillerSection';
import { Handle } from '../../KitchenObjects/Models3D/Handles/Handle';
import { RoomObject } from '../../KitchenObjects/Room/RoomObject';
import { Floor } from '../../KitchenObjects/BuildingBlocks/Floor';
import store from '@/store/index.js';

export class DesignGenerator {
    cornerCabinetPositionData = [];
    constructor(editor) {
        this.editor = editor;
    }

    async generate() {
        store.commit('core/toggleProjectLoading', true);
        let connections = this.extractConnections();
        this.editor.getObjectByType(RoomObject).floor = this.editor.addObject(Floor);
        this.editor.getObjectByType(RoomObject).floor.build();
        await this.import3DModels();

        for (let index = 0; index < connections.length; index++) {
            this.decideCornerCabinetPosition(connections[index]);
        }
        this.editor.layoutDetector.extractLayouts();
        await this.replaceAll();
        for (let index = 0; index < (await this.editor.sceneComponent.get().materials.length); index++) {
            if (this.editor.sceneComponent.get().materials[index] instanceof ShaderMaterial) {
                this.editor.sceneComponent.get().materials[index].dispose();
                index -= 1;
            }
        }
        store.commit('core/toggleProjectLoading', false);
    }
    async replaceAll() {
        await this.editor.cabinetReplacer.replaceAllCabinets();
        await this.editor.islandReplacer.replaceAllIslands();
        this.replaceWithApplianceModels();
    }

    async import3DModels() {
        if (!this.editor.models3D.baseCabinetSection) {
            this.editor.models3D.baseCabinetSection = this.editor.addObject(BaseCabinet);
            await this.editor.models3D.baseCabinetSection.setModel();
        }

        if (!this.editor.models3D.baseFillerSection) {
            this.editor.models3D.baseFillerSection = this.editor.addObject(BaseFillerSection);
            await this.editor.models3D.baseFillerSection.setModel();
        }

        if (!this.editor.models3D.tallFillerSection) {
            this.editor.models3D.tallFillerSection = this.editor.addObject(TallFillerSection);
            await this.editor.models3D.tallFillerSection.setModel();
        }

        if (!this.editor.models3D.handle) {
            this.editor.models3D.handle = this.editor.addObject(Handle);
            await this.editor.models3D.handle.setModel();
        }

        if (!this.editor.models3D.tallCabinet) {
            this.editor.models3D.tallCabinet = this.editor.addObject(TallCabinet);
            await this.editor.models3D.tallCabinet.setModel();
        }

        if (!this.editor.models3D.wallCabinet) {
            this.editor.models3D.wallCabinet = this.editor.addObject(WallCabinet);
            await this.editor.models3D.wallCabinet.setModel();
        }
    }

    extractConnections() {
        const connections = [];
        const cabinetsToIgnore = [];
        for (let index = 0; index < this.editor.cabinets.length; index++) {
            if (!cabinetsToIgnore.includes(this.editor.cabinets[index])) {
                if (this.editor.cabinets[index].connectedCabinet) {
                    connections.push({
                        firstCabinet: this.editor.cabinets[index],
                        secondCabinet: this.editor.cabinets[index].connectedCabinet,
                        corner: this.editor.cabinets[index].assignedWall.baseInnerCornerA,
                    });
                    cabinetsToIgnore.push(this.editor.cabinets[index].connectedCabinet);
                }

                if (this.editor.cabinets[index].secondConnectedCabinet) {
                    connections.push({
                        firstCabinet: this.editor.cabinets[index],
                        secondCabinet: this.editor.cabinets[index].secondConnectedCabinet,
                        corner: this.editor.cabinets[index].assignedWall.baseInnerCornerB,
                    });
                    cabinetsToIgnore.push(this.editor.cabinets[index].secondConnectedCabinet);
                }

                if (
                    this.editor.cabinets[index].type === Constants.cabinet.type.ISLAND &&
                    this.editor.cabinets[index].peninsulaData.isPeninsula
                ) {
                    connections.push({
                        firstCabinet: this.editor.cabinets[index],
                        secondCabinet: this.editor.cabinets[index].peninsulaData.connectedCabinet,
                        corner: this.editor.cabinets[index].peninsulaData.connectionEdge,
                    });
                    cabinetsToIgnore.push(this.editor.cabinets[index].peninsulaData.connectedCabinet);
                } else if (this.editor.cabinets[index].peninsulaConnection.peninsula) {
                    connections.push({
                        firstCabinet: this.editor.cabinets[index].peninsulaConnection.peninsula,
                        secondCabinet: this.editor.cabinets[index],
                        corner: this.editor.cabinets[index].peninsulaConnection.connectionEdge,
                    });
                    cabinetsToIgnore.push(this.editor.cabinets[index].peninsulaConnection.peninsula);
                }
            }
        }
        return connections;
    }

    decideCornerCabinetPosition(connection) {
        if (connection.firstCabinet instanceof Island || connection.secondCabinet instanceof Island) {
            this.decideCornerCabinetForPeninsula(connection);
            return;
        }
        let cornerCabinetInfo = this.checkForAppliancesNearBlockedArea(connection.firstCabinet);
        if (!cornerCabinetInfo) {
            cornerCabinetInfo = this.checkForAppliancesNearBlockedArea(connection.secondCabinet);
        }
        if (connection.firstCabinet.type === Constants.cabinet.type.WALL) {
            if (!connection.firstCabinet.cornerCabinetInfo) {
                if (connection.firstCabinet.connectedCabinet) {
                    cornerCabinetInfo = {
                        cabinet: connection.firstCabinet,
                        cornerIndex: Constants.corner.CORNER_A,
                    };
                } else if (connection.firstCabinet.secondConnectedCabinet) {
                    cornerCabinetInfo = {
                        cabinet: connection.firstCabinet,
                        cornerIndex: Constants.corner.CORNER_B,
                    };
                }
            } else if (!connection.secondCabinet.cornerCabinetInfo) {
                if (connection.secondCabinet.connectedCabinet) {
                    cornerCabinetInfo = {
                        cabinet: connection.secondCabinet,
                        cornerIndex: Constants.corner.CORNER_A,
                    };
                } else if (connection.secondCabinet.secondConnectedCabinet) {
                    cornerCabinetInfo = {
                        cabinet: connection.secondCabinet,
                        cornerIndex: Constants.corner.CORNER_B,
                    };
                }
            }
        } else if (!cornerCabinetInfo) {
            if (!connection.firstCabinet.cornerCabinetInfo) {
                let cornerIndex = Constants.corner.CORNER_B;
                if (connection.firstCabinet.connectedCabinet === connection.secondCabinet) {
                    cornerIndex = Constants.corner.CORNER_A;
                }
                cornerCabinetInfo = {
                    cabinet: connection.firstCabinet,
                    cornerIndex: cornerIndex,
                };
            } else if (!connection.secondCabinet.cornerCabinetInfo) {
                let cornerIndex = Constants.corner.CORNER_B;
                if (connection.secondCabinet.connectedCabinet === connection.firstCabinet) {
                    cornerIndex = Constants.corner.CORNER_A;
                }
                cornerCabinetInfo = {
                    cabinet: connection.secondCabinet,
                    cornerIndex: cornerIndex,
                };
            }
        }

        //tall cabinet use cases
        if ([connection.firstCabinet.type, connection.secondCabinet.type].includes(Constants.cabinet.type.TALL)) {
            cornerCabinetInfo = this.decideCornerCabinetForTallConnections(connection);
        }
        if (cornerCabinetInfo) {
            cornerCabinetInfo.cabinet.cornerCabinetInfo = cornerCabinetInfo;
            this.cornerCabinetPositionData.push(cornerCabinetInfo);
        }
    }

    decideCornerCabinetForTallConnections(connection) {
        if ([connection.firstCabinet.type, connection.secondCabinet.type].includes(Constants.cabinet.type.TALL)) {
            let tallCabinet =
                connection.firstCabinet.type === Constants.cabinet.type.TALL ? connection.firstCabinet : connection.secondCabinet;
            const tallCabinetPositionInfo = tallCabinet.getPositionInfo();

            if (tallCabinet.connectedCabinet) {
                if (tallCabinetPositionInfo.cornerA) {
                    return {
                        cabinet: tallCabinet,
                        cornerIndex: Constants.corner.CORNER_A,
                    };
                } else {
                    return {
                        cabinet: tallCabinet.connectedCabinet,
                        cornerIndex: Constants.corner.CORNER_B,
                    };
                }
            } else if (tallCabinet.secondConnectedCabinet) {
                if (tallCabinetPositionInfo.cornerB) {
                    return { cabinet: tallCabinet, cornerIndex: Constants.corner.CORNER_B };
                } else {
                    return { cabinet: tallCabinet.secondConnectedCabinet, cornerIndex: Constants.corner.CORNER_A };
                }
            }
        }
    }

    decideCornerCabinetForPeninsula(connection) {
        if (
            connection.secondCabinet.peninsulaConnection.edgeIndex === 'firstPoint' &&
            connection.secondCabinet.peninsulaConnection.peninsula.depth <= 0.6
        ) {
            connection.firstCabinet.cornerCabinetInfo = {
                cabinet: connection.firstCabinet,
                cornerIndex: connection.firstCabinet.peninsulaData.edgeIndex,
                type: 'oneRow',
                side: 'rightSide',
            };
        } else if (
            connection.secondCabinet.peninsulaConnection.edgeIndex === 'secondPoint' &&
            connection.secondCabinet.peninsulaConnection.peninsula.depth <= 0.6
        ) {
            connection.firstCabinet.cornerCabinetInfo = {
                cabinet: connection.firstCabinet,
                cornerIndex: connection.firstCabinet.peninsulaData.edgeIndex,
                type: 'oneRow',
                side: 'leftSide',
            };
        } else if (
            connection.secondCabinet.peninsulaConnection.edgeIndex === 'firstPoint' &&
            connection.secondCabinet.peninsulaConnection.peninsula.depth > 0.6
        ) {
            connection.firstCabinet.cornerCabinetInfo = {
                cabinet: connection.firstCabinet,
                cornerIndex: connection.firstCabinet.peninsulaData.edgeIndex,
                type: 'twoRow',
                side: 'rightSide',
            };
        } else if (
            connection.secondCabinet.peninsulaConnection.edgeIndex === 'secondPoint' &&
            connection.secondCabinet.peninsulaConnection.peninsula.depth > 0.6
        ) {
            connection.firstCabinet.cornerCabinetInfo = {
                cabinet: connection.firstCabinet,
                cornerIndex: connection.firstCabinet.peninsulaData.edgeIndex,
                type: 'twoRow',
                side: 'leftSide',
            };
        } else {
            // not in penninsula usecase
            connection.firstCabinet.cornerCabinetInfo = {
                cabinet: connection.firstCabinet,
                cornerIndex: connection.firstCabinet.peninsulaData.edgeIndex,
                type: '',
                side: '',
            };
        }
    }

    checkForAppliancesNearBlockedArea(cabinet) {
        const appliancesOnCabinet = cabinet.getAppliances();
        if (appliancesOnCabinet.length > 0) {
            for (let index = 0; index < appliancesOnCabinet.length; index++) {
                let appliance = appliancesOnCabinet[index];
                const projectedAppliancePosition = Utilities.projectPointOnWall(
                    appliancesOnCabinet[index].meshComponent.getMesh().position,
                    appliancesOnCabinet[index].assignedWall
                );
                const allowedSegment = appliance.currentCabinet.calculateAllowedSegment();
                let otherCabinet = appliance.currentCabinet.connectedCabinet;
                let cornerIndex = Constants.corner.CORNER_B;
                if (!otherCabinet) {
                    otherCabinet = appliance.currentCabinet.secondConnectedCabinet;
                    cornerIndex = Constants.corner.CORNER_A;
                }
                if (
                    (Vector3.Distance(allowedSegment.firstPoint, projectedAppliancePosition) -
                        appliancesOnCabinet[index].width / 2 <=
                        Constants.baseCornerCabinet.DOOR_SIZE[this.editor.unit] ||
                        Vector3.Distance(allowedSegment.secondPoint, projectedAppliancePosition) -
                            appliancesOnCabinet[index].width / 2 <=
                            Constants.baseCornerCabinet.DOOR_SIZE[this.editor.unit]) &&
                    !otherCabinet.cornerCabinetInfo
                ) {
                    return {
                        cabinet: otherCabinet,
                        cornerIndex: cornerIndex,
                    };
                }
            }
        }
    }

    replaceWithApplianceModels() {
        let appliances = this.editor.appliances;
        for (let index = 0; index < appliances.length; index++) {
            this.setApplianceModel(appliances[index]);
        }
    }

    setApplianceModel(appliance) {
        appliance.meshComponent.getMesh().isVisible = 1;
        if (appliance.type === Constants.appliance.type.HOOD) {
            appliance.meshComponent.getMesh().position = appliance.currentCooktop.meshComponent.getMesh().position.clone();
            appliance.meshComponent.getMesh().position.y = Constants.hood.HEIGHT_FROM_FLOOR + appliance.height / 2;
        } else if (appliance.type === Constants.appliance.type.REFRIGERATOR) {
            appliance.meshComponent.getMesh().position.y = 0.96;
        }

        //if an appliance is builtin it looks like a simple normal cabinet so we hide it
        //except for the cooktop, it is considered builtin but it is placed on top of the cabinet
        if (appliance.format === Constants.appliance.format.BUILTIN && appliance.type !== Constants.appliance.type.COOKTOP) {
            appliance.meshComponent.getMesh().isVisible = false;
            appliance.meshComponent.getMesh().visibility = 0;
        }
    }

    async revert() {
        store.commit('core/toggleProjectLoading', true);
        let cabinets = this.editor.cabinets;
        let appliances = this.editor.appliances;
        for (let index = 0; index < cabinets.length; index++) {
            cabinets[index].disposeSections();
            cabinets[index].isSectioned = false;
            cabinets[index].cornerCabinetInfo = null;
            cabinets[index].baseCabinetUnderneath = null;
            cabinets[index].filledWidth = 0;
        }
        for (let index = 0; index < appliances.length; index++) {
            appliances[index].meshComponent.dispose();
        }
        const room = this.editor.getObjectByType(RoomObject);
        room.floor?.dispose();
        this.editor.cabinets = [];
        this.editor.appliances = [];
        await this.editor.buildKitchenObjects();
        store.commit('core/toggleProjectLoading', false);
    }
}
