import { Mesh, MeshBuilder, Vector3 } from '@babylonjs/core';

import { Rectangle, TextBlock } from '@babylonjs/gui';

/**
 *   Creates object to link a blank mesh to used to track the position of a UI component and handles the display of the attached value.
 *   @param linkedValue: ()=> number | number, The linkedValue param can be a function to get a dynamic number, or a number.
 *   @param params : ITextPlaneWidgetParams, An object with these properties.
 *   @param params.positionValue?: ()=> void | Vector3, The position param can be a Vector3 or a function to set the position of the plane.
 *   @param params.rotationValue?: ()=> void | number, The rotation param can be a number or a function to set the rotation of the plane.
 *   @param params.linkedMesh?: Mesh, I dont remember why this is here, will update when I come across it.
 *   @param parent: Floorplan, The floorplan object that created the TextPlaneWidget.
 */
export class TextPlaneWidget {
    //Getter for the positionValue
    get positionValue() {
        if (this.params.positionValue) {
            if (typeof this.params.positionValue !== 'function') {
                return this.params.positionValue;
            } else {
                return this.params.positionValue();
            }
        }
        return Vector3.Zero();
    }
    //Getter for the rotationValue
    get rotationValue() {
        if (this.params.rotationValue) {
            if (typeof this.params.rotationValue !== 'function') {
                return this.params.rotationValue;
            } else {
                return this.params.rotationValue();
            }
        }
        return 0;
    }
    //Getter for the UI element background Color
    get background() {
        return this.params.background ?? 'black';
    }
    //Getter for the UI element text Color
    get color() {
        return this.params.color ?? 'white';
    }
    //Getter for minValue
    get minValue() {
        return this.params.minValue ?? false;
    }
    //Getter for suffix
    get suffix() {
        if (this.params.suffix) {
            if (typeof this.params.suffix !== 'function') {
                return this.params.suffix;
            } else {
                return this.params.suffix();
            }
        }
        return '';
    }
    //Setter for suffix
    set suffix(value) {
        this.params.suffix = value;
    }
    //The Parts of the UI
    parts = {
        textBlock: null,
        rect: null,
    };

    constructor(linkedValue, parameters, parent) {
        //A uniqueID for the textPlane.
        this.uID = 'TextPlane:' + Date.now();

        //Inherent the parent fo access to the scene.
        this.parent = parent;

        //Set the params so the object can access them.
        this.params = parameters;

        //Set the linked value, this one skipps a setter/getter at the top.
        if (typeof linkedValue === 'function') {
            this.linkedValue = linkedValue;
        } else {
            this.linkedValue = () => {
                return linkedValue;
            };
        }

        //A flag to use to update the dynamicTexture or not.
        this.dirty = true;

        //Create the anchorMesh for the UI to link the element to
        this.meshAnchor = new MeshBuilder.CreateBox(this.uID + ':meshAnchor', { width: 0.1, depth: 0.1, height: 0.1 });
        this.meshAnchor.setEnabled(false);
        this.meshAnchor.width = '30px';
        this.meshAnchor.height = '30px';
        //I dont remember what this part was for, but I will update these comments when I get back to why this is here.
        if (parameters.linkedMesh) {
            this.linkedMesh = parameters.linkedMesh;

            this.linkedMesh.width = 0.26;
            this.linkedMesh.height = 0.26;
            this.meshAnchor.position = parameters.linkedMesh.position.clone();
        } else {
            parameters.linkedMesh = false;
        }

        this._buildParts();
        this.update();
    }

    /**
     *   Creates the UI elements.
     *   @returns void
     */
    _buildParts() {
        this.parts.rect = new Rectangle();
        this.parts.rect.isPointerBlocker = false;
        this.parts.rect.fontFamily = 'monospace';
        this.parts.rect.height = this.params.height + 'px';
        this.parts.rect.cornerRadius = 6;
        this.parts.rect.thickness = 0;
        this.parts.rect.background = this.background;
        this.parts.textBlock = new TextBlock();
        this.parts.textBlock.text = ' ';
        this.parts.textBlock.color = this.color;
        this.parts.textBlock.fontSize = 13 * Math.abs(this.getEditorCore().controls.zoomRatio);
        this.parts.rect.addControl(this.parts.textBlock);
        this.getEditorCore().widgetUI.addControl(this.parts.rect);
        this.parts.rect.linkWithMesh(this.meshAnchor);
    }

    /**
     *   Updates the UI elements.
     *   @returns void
     */
    update() {
        //Get the linkedValue
        const value = this.linkedValue();
        //See if object needs to be displayed?
        if (!this._checkMinValue(value)) {
            //Skip some calculations.
            return;
        }

        //Set the position of the anchor.
        if (this.linkedMesh) {
            //Setting may be faster then cloning each cycle.
            this.meshAnchor.position.set(this.linkedMesh.position.x, this.linkedMesh.position.y, this.linkedMesh.position.z);
            this.meshAnchor.position.addInPlace(this.getPosition());
        } else {
            this.meshAnchor.position = this.getPosition();
        }
        this.parts.rect.rotation = this.getRotation();

        //Get the suffix approximate the text size (this can be fixed later) and set the elements text value.

        this.parts.textBlock.text = value.toString();
        this.updateTextPlaneWidgetSize();
    }

    updateTextPlaneWidgetSize() {
        let fontSize = 10 * this.getEditorCore().controls.zoomRatio;
        this.parts.textBlock.fontSize = fontSize;
        this.parts.rect.width = (fontSize * this.params.width) / 15 + 'px';
        this.parts.rect.height = (fontSize * this.params.height) / 15 + 'px';
    }

    /**
     *   Checks if the element needs to be displayed.
     *   @returns boolean
     */
    _checkMinValue(value) {
        if (this.minValue !== false && this.minValue >= value) {
            this.parts.rect.alpha = 0;
            return false;
        } else {
            this.parts.rect.alpha = 1;
            return true;
        }
    }

    /**
     *   Updates the UI elements.
     *   @returns void
     */
    dispose() {
        this.getEditorCore().widgetUI.removeControl(this.parts.rect);
        this.meshAnchor.dispose();
    }

    /**
     *   Gets the Core Editor Object.
     *   @returns Editor
     */
    getEditorCore() {
        return this.parent.getEditorCore();
    }

    /**
     *   Gets the BJS Scene from the editorCore.
     *   @returns Scene
     */
    getScene() {
        return this.getEditorCore().scene;
    }

    /**
     *   Gets the positionValue.
     *   @returns Vector3
     */
    getPosition() {
        return this.positionValue;
    }

    /**
     *   Gets the rotationValue.
     *   @returns number
     */
    getRotation() {
        return this.rotationValue;
    }

    /**
     *   Gets the suffix.
     *   @returns string
     */
    getSuffix() {
        return this.suffix;
    }
}
