import { TextBlock } from '@babylonjs/gui';
import store from '@/store/index.js';
import { Component } from './Base/Component';
import { Constants } from '../Tools/constants';
import { Color3 } from '@babylonjs/core';

export class Text extends Component {
    textBlock;
    linkedMesh;
    constructor() {
        super();
    }

    build() {
        this.textBlock = new TextBlock();
    }

    setValue(value) {
        if (store.getters['core/selectedUnit'] === 'in') {
            value = value / Constants.unit.INCH_STEP;
        }
        this.build();
        this.textBlock.text = value.toString();
        this.textBlock.color = new Color3(0, 0, 0);
        this.textBlock.fontSize = 14;
    }
}
