import VueApollo from 'vue-apollo';
import store from '@/store/index.js';
import ApolloClient from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import SuperTokens from 'supertokens-website';

const getHeaders = async () => {
    const headers = {
        'content-type': 'application/json'
    };
    if (await SuperTokens.doesSessionExist() && store.state.business.isStandardDomain) {
        let token = (await SuperTokens.getAccessTokenPayloadSecurely()).jwt;

        if (process.env.NODE_ENV === 'development') console.log('token:', token);

        headers.authorization = token ? `Bearer ${token}` : '';
        return headers;
    } else {
        return headers;
    }
};

// Create a WebSocket link:
const wsLink = new WebSocketLink({
    uri: process.env.VUE_APP_WS_LINK,
    options: {
        lazy: true,
        reconnect: true,
        timeout: 30000,
        connectionParams: async () => {
            return { headers: await getHeaders() };
        },
    },
});

if (process.env.NODE_ENV === 'development') {
    wsLink.subscriptionClient.on('connecting', () => {
        console.log('connecting');
        console.log('to', process.env.VUE_APP_WS_LINK);
    });

    wsLink.subscriptionClient.on('connected', () => {
        console.log('connected');
    });

    wsLink.subscriptionClient.on('reconnecting', () => {
        console.log('reconnecting');
    });

    wsLink.subscriptionClient.on('reconnected', () => {
        console.log('reconnected');
    });

    wsLink.subscriptionClient.on('disconnected', () => {
        console.log('disconnected');
    });
}

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () => wsLink.subscriptionClient.maxConnectTimeGenerator.max;

const cache = new InMemoryCache({
    addTypename: true,
});

const client = new ApolloClient({
    link: wsLink,
    cache,
});

export const apolloProvider = new VueApollo({
    defaultClient: client,
});
