var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper-content",
    { staticClass: "pa-2", attrs: { step: "4" } },
    [
      !_vm.selectedObject.length && !_vm.selectedAppliance
        ? _c(
            "v-row",
            { staticClass: "pa-2" },
            [
              _vm.$vuetify.breakpoint.smAndUp
                ? _c(
                    "v-col",
                    { attrs: { cols: "5", sm: "8" } },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass:
                            "pa-2 blue-grey lighten-5 rounded-lg elevation-2",
                        },
                        [
                          _c("p", { staticClass: "mx-auto" }, [
                            _vm._v(
                              " Select an object to edit. Multi-select with shift+click "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("StepsButtons"),
            ],
            1
          )
        : !_vm.selectedAppliance
        ? _c("v-container", { staticClass: "pa-0" }, [_c("EditDesign")], 1)
        : !_vm.selectedObject.length
        ? _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [_c("ApplianceEdit")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }