<template>
    <v-stepper-content step="4" class="pa-2">
        <!-- Print PDF -->
        <v-row v-if="!selectedObject.length && !selectedAppliance" class="pa-2">
            <v-col cols="5" sm="8" v-if="$vuetify.breakpoint.smAndUp">
                <v-sheet class="pa-2 blue-grey lighten-5 rounded-lg elevation-2">
                    <p class="mx-auto">
                        Select an object to edit. Multi-select with shift+click
                    </p>
                </v-sheet>
            </v-col>

            <StepsButtons></StepsButtons>
        </v-row>

        <!-- Edit Cabinet -->
        <v-container class="pa-0" v-else-if="!selectedAppliance">
            <EditDesign></EditDesign>
        </v-container>

        <!-- Edit Cabinet -->
        <v-container fluid v-else-if="!selectedObject.length" class="pa-0">
            <ApplianceEdit></ApplianceEdit>
        </v-container>
    </v-stepper-content>
</template>

<script>
import EditDesign from './EditDesign';
import ApplianceEdit from '@/components/stepper/Step3AddAppliances/ApplianceEdit.vue';
import StepsButtons from './StepsButtons';
import { mapGetters, mapState } from 'vuex';

export default {
    components: {
        StepsButtons,
        EditDesign,
        ApplianceEdit,
    },
    computed: {
        ...mapState('generated', ['selectedObject']),
        ...mapState('appliances', ['selectedAppliance']),
        ...mapGetters('core', ['selectedUnit', 'editorEngine']),
    },
};
</script>
