var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550", persistent: "" },
          model: {
            value: _vm.startedDesigning,
            callback: function ($$v) {
              _vm.startedDesigning = $$v
            },
            expression: "startedDesigning",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 justify-center" }, [
                _vm._v(" Select Measurement Unit "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "text-center px-2" },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass: "justify-center flex-wrap",
                      attrs: {
                        mandatory: "",
                        tile: "",
                        color: "primary",
                        group: "",
                      },
                      model: {
                        value: _vm.unit,
                        callback: function ($$v) {
                          _vm.unit = $$v
                        },
                        expression: "unit",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            value: "mm",
                            disabled:
                              _vm.costingUnit && "mm" !== _vm.costingUnit,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(_vm._s(_vm.mdiTapeMeasure)),
                          ]),
                          _c("span", [_vm._v("Millimeters (mm)")]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            value: "in",
                            disabled:
                              _vm.costingUnit && "in" !== _vm.costingUnit,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(_vm._s(_vm.mdiTapeMeasure)),
                          ]),
                          _c("span", [_vm._v("Inches (in)")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-slide-group",
                    {
                      staticClass: "py-4",
                      attrs: { mandatory: "", "show-arrows": "always" },
                      model: {
                        value: _vm.layoutSelected,
                        callback: function ($$v) {
                          _vm.layoutSelected = $$v
                        },
                        expression: "layoutSelected",
                      },
                    },
                    _vm._l(_vm.layouts, function (layout) {
                      return _c("v-slide-item", {
                        key: layout,
                        attrs: { value: layout },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ active, toggle }) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "ma-4 pa-6",
                                          attrs: {
                                            color: active
                                              ? "blue lighten-4"
                                              : "white",
                                            height: "100",
                                            width: "100",
                                          },
                                          on: { click: toggle },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "fill-height",
                                              attrs: {
                                                align: "center",
                                                justify: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-scale-transition",
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: require(`@/assets/icons/room-layouts/${layout}.png`),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("p", [_vm._v(_vm._s(layout))]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green darken-1 white--text",
                        width: "100%",
                      },
                      on: { click: _vm.startDesigning },
                    },
                    [_vm._v(" Start Designing ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.isProjectLoading,
            callback: function ($$v) {
              _vm.isProjectLoading = $$v
            },
            expression: "isProjectLoading",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v(" Please stand by "),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }