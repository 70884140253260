var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    {
      staticClass: "d-flex flex-column justify-center flex-shrink-1 height",
      attrs: { cols: "4", md: "3" },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "white--text",
          attrs: { color: "green", small: _vm.$vuetify.breakpoint.mdAndDown },
          on: {
            click: function ($event) {
              _vm.setStep(4)
              _vm.generateDesign()
            },
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$vuetify.breakpoint.mdAndDown ? "Next" : "Generate Design"
              ) +
              " "
          ),
        ]
      ),
      _c(
        "v-container",
        { staticClass: "text-center py-0" },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", small: _vm.$vuetify.breakpoint.mdAndDown },
              on: {
                click: function ($event) {
                  return _vm.setStep(2)
                },
              },
            },
            [_vm._v(" Previous ")]
          ),
          _c("resetBtn"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }