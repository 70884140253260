import { Mesh, MeshBuilder } from '@babylonjs/core';
import { Constants } from '../../../Tools/constants';
import { Utilities } from '../../../Tools/utilities';
import { CabinetStructure } from '../../BuildingBlocks/CabinetStructure';
import { DoorBlock } from '../../BuildingBlocks/DoorBlock';
import { Model } from '../Model';

export class WallCabinet extends Model {
    constructor() {
        super();
        this.innerTextureParts = null;
    }

    async setModel() {
        const result = await this.import('wallCabinet.babylon');
        this.meshComponent.setMesh(Utilities.getElementByMeshName('WallCabinet', result.meshes));
        this.meshComponent.getMesh().setEnabled(false);
        this.meshComponent.getMesh().name = 'wall-cabinet';
        this.parts.cabinet = {};
        this.setParts();
    }

    resize(dimensions) {
        this.width = dimensions.width;
        this.depth = dimensions.depth;
        this.height = dimensions.height;
        this.resizeWidth(dimensions);
        this.resizeDepth(dimensions);
        this.resizeHeight(dimensions.height);
    }

    mergeModel() {
        const wallCabinetModelMesh = MeshBuilder.CreateBox('wall-cabinet-model', { width: 0.1, depth: 0.1, height: 0.1 });
        wallCabinetModelMesh.visibility = 0;

        const wallCabinetModel = { mesh: wallCabinetModelMesh, buildingBlocks: {} };
        const handle = this.placeHandle(wallCabinetModel.mesh);

        wallCabinetModel.buildingBlocks.innerStructure = this.getInnerStructureBlock(wallCabinetModel.mesh);
        wallCabinetModel.buildingBlocks.doorBlock = this.getDoorBlock(wallCabinetModel.mesh);
        wallCabinetModel.buildingBlocks.handleBlock = handle;

        const appliance = this.sectionInfo.appliance;
        if (appliance) {
            if (appliance?.type === Constants.appliance.type.HOOD) {
                if (appliance?.format === Constants.appliance.format.BUILTIN) {
                    appliance.meshComponent.getMesh().setEnabled(false);
                    this.parts.doors.buildinHood.mesh.visibility = 1;
                    const hoodClone = this.parts.doors.buildinHood.mesh.clone();
                    hoodClone.setEnabled(true);
                    hoodClone.parent = wallCabinetModel.mesh;
                } else if (appliance?.format === Constants.appliance.format.FREESTANDING) {
                    handle.visibility = 0;
                    wallCabinetModel.mesh.visibility = 0;
                    wallCabinetModel.mesh.isVisible = false;
                    wallCabinetModel.buildingBlocks.innerStructure.meshComponent.getMesh().visibility = 0;
                    wallCabinetModel.buildingBlocks.doorBlock.meshComponent.getMesh().visibility = 0;
                }
            }
        }
        return wallCabinetModel;
    }

    placeHandle(parentMesh) {
        if (!this.sectionInfo.isFiller) {
            const handleClone = Mesh.MergeMeshes([this.editor.models3D.handle.meshComponent.getMesh().clone()]);
            if (this.width <= 0.15) {
                Utilities.resizeMeshInWidth(handleClone, 0.12);
            }
            this.parts.doors.door1Handle.mesh.visibility = 0;
            handleClone.position = this.parts.doors.door1Handle.mesh.position.clone();
            handleClone.type = 'handle';
            handleClone.parent = parentMesh;
            handleClone.alwaysSelectAsActiveMesh = true;

            return handleClone;
        }
    }

    getInnerStructureBlock(parentMesh) {
        const meshes = [];
        Object.keys(this.parts.cabinet).forEach((key) => {
            meshes.push(this.parts.cabinet[key].mesh);
        });
        const innerStructure = this.editor.addObject(CabinetStructure);
        innerStructure.setParentSection(this.currentParentSection);
        innerStructure.create(meshes, 'cabinets');
        innerStructure.meshComponent.setParent(parentMesh);
        return innerStructure;
    }

    getDoorBlock(parentMesh) {
        const meshes = [this.parts.doors.door1.mesh];
        const doorBlock = this.editor.addObject(DoorBlock);
        doorBlock.setParentSection(this.currentParentSection);
        doorBlock.enableSelection();
        doorBlock.create(meshes, 'cabinets');
        doorBlock.meshComponent.setParent(parentMesh);
        return doorBlock;
    }

    resizeWidth(dimensions) {
        let width = dimensions.width;
        width -= Constants.baseCabinetModel.sidePanel.WIDTH * 2;

        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);
        this.parts.cabinet.panelBottom.mesh.scaling.x =
            width / (panelBottomDimensions.width / this.parts.cabinet.panelBottom.mesh.scaling.x);

        const panelTopDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelTop.mesh);
        this.parts.cabinet.panelTop.mesh.scaling.x =
            width / (panelTopDimensions.width / this.parts.cabinet.panelTop.mesh.scaling.x);

        const shelfDimensions = Utilities.getMeshDimensions(this.parts.cabinet.shelf.mesh);
        this.parts.cabinet.shelf.mesh.scaling.x = width / (shelfDimensions.width / this.parts.cabinet.shelf.mesh.scaling.x);

        const panelBackDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBack.mesh);
        this.parts.cabinet.panelBack.mesh.scaling.x =
            width / (panelBackDimensions.width / this.parts.cabinet.panelBack.mesh.scaling.x);

        const door1Dimensions = Utilities.getMeshDimensions(this.parts.doors.door1.mesh);
        this.parts.doors.door1.mesh.scaling.x =
            (dimensions.width - Constants.GAP_BETWEEN_DOORS) / (door1Dimensions.width / this.parts.doors.door1.mesh.scaling.x);

        const shift = (width - shelfDimensions.width) / 2;
        this.parts.cabinet.panelLeft.mesh.position.x -= shift;
        this.parts.cabinet.panelRight.mesh.position.x += shift;
    }

    resizeDepth(dimensions) {
        const panelLeftDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelLeft.mesh);
        this.parts.cabinet.panelLeft.mesh.scaling.z =
            (dimensions.depth - 0.024) / (panelLeftDimensions.depth / this.parts.cabinet.panelLeft.mesh.scaling.z);

        const panelRightDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelRight.mesh);
        this.parts.cabinet.panelRight.mesh.scaling.z =
            (dimensions.depth - 0.024) / (panelRightDimensions.depth / this.parts.cabinet.panelRight.mesh.scaling.z);

        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);
        this.parts.cabinet.panelBottom.mesh.scaling.z =
            (dimensions.depth - 0.024) / (panelBottomDimensions.depth / this.parts.cabinet.panelBottom.mesh.scaling.z);

        const panelTopDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelTop.mesh);
        this.parts.cabinet.panelTop.mesh.scaling.z =
            (dimensions.depth - 0.024) / (panelTopDimensions.depth / this.parts.cabinet.panelTop.mesh.scaling.z);

        const shelfDimensions = Utilities.getMeshDimensions(this.parts.cabinet.shelf.mesh);
        this.parts.cabinet.shelf.mesh.scaling.z =
            (dimensions.depth - 0.1) / (shelfDimensions.depth / this.parts.cabinet.shelf.mesh.scaling.z);

        const shift = (dimensions.depth - 0.024 - panelTopDimensions.depth) / 2;
        this.parts.cabinet.panelBack.mesh.position.z += shift;
        this.parts.doors.door1.mesh.position.z -= shift;
        this.parts.doors.door1Handle.mesh.position.z -= shift;
    }

    resizeHeight(height) {
        this.resizeCabinetBlockInHeight(height);
    }

    resizeCabinetBlockInHeight(height) {
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelBack.mesh, height);
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelLeft.mesh, height);
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelRight.mesh, height);
        Utilities.resizeMeshInHeight(this.parts.doors.door1.mesh, height);

        const door1Dimensions = Utilities.getMeshDimensions(this.parts.doors.door1.mesh);

        this.parts.cabinet.panelBottom.mesh.position.y -= (height - door1Dimensions.height) / 2;
        this.parts.cabinet.panelTop.mesh.position.y += (height - door1Dimensions.height) / 2;
        this.parts.doors.door1Handle.mesh.position.y -= (height - door1Dimensions.height) / 2;
    }

    getResizingDimensions(sectionWidth) {
        return {
            width: sectionWidth,
            depth: this.parentCabinet.depth,
            height: this.parentCabinet.height,
        };
    }
}
