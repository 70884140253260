import { Color3, MeshBuilder } from '@babylonjs/core';
import { BaseMeasurementLine } from '../../../Components/Base/BaseMeasurementLine';
import { Constants } from '../../../Tools/constants';
import { Utilities } from '../../../Tools/utilities';
import { RoomObject } from '../../Room/RoomObject';

export class MeasurementLine extends BaseMeasurementLine {
    constructor() {
        super();
    }

    build() {
        let lineCoordinates;

        const slots = this.getMeasurementLinesSlots();
        lineCoordinates = this.calculateLineSystemCoordinates(slots);

        if (this.linkedMeshes.length === 0) {
            this.buildLinkedMesh(lineCoordinates);
        }
        this.updateLength(lineCoordinates);

        if (!this.lineSystem) {
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        } else {
            this.lineSystem.dispose();
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        }
        this.lineSystem.color = new Color3(0, 0, 0);
        this.mesh = this.lineSystem;
    }

    calculateLineSystemCoordinates(slots) {
        let lineCoordinates = [];
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        const roomCorners = roomObject.baseOuterCorners.concat(roomObject.baseOuterCorners[0]);

        for (let index = 0; index < slots.length; index++) {
            let firstPointClone = slots[index].firstPoint.clone();
            let secondPointClone = slots[index].secondPoint.clone();
            let firstPoint = slots[index].firstPoint.clone();
            let secondPoint = slots[index].secondPoint.clone();
            if (slots[index].fixture !== null) {
                if (this.object.currentCabinet?.type === Constants.cabinet.type.ISLAND) {
                    const result = this.getMeasurementLineShiftData(
                        firstPointClone.clone(),
                        secondPointClone.clone(),
                        this.object.currentCabinet
                    );
                    firstPointClone = result.firstPointClone;
                    secondPointClone = result.secondPointClone;
                } else {
                    firstPointClone = Utilities.calculateShiftValue(
                        firstPointClone,
                        -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                        roomCorners,
                        this.object.assignedWall
                    );
                    secondPointClone = Utilities.calculateShiftValue(
                        secondPointClone,
                        -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                        roomCorners,
                        this.object.assignedWall
                    );

                    firstPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                    secondPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                    firstPoint.y += Constants.cabinet.measurementLine.MARGIN;
                    secondPoint.y += Constants.cabinet.measurementLine.MARGIN;
                }
            } else {
                if (this.object.currentCabinet?.type === Constants.cabinet.type.ISLAND) {
                    const result = this.getMeasurementLineShiftData(
                        firstPointClone.clone(),
                        secondPointClone.clone(),
                        this.object.currentCabinet
                    );
                    firstPointClone = result.firstPointClone;
                    secondPointClone = result.secondPointClone;
                } else {
                    firstPointClone = Utilities.calculateShiftValue(
                        firstPointClone,
                        -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                        roomCorners,
                        this.object.assignedWall
                    );
                    secondPointClone = Utilities.calculateShiftValue(
                        secondPointClone,
                        -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                        roomCorners,
                        this.object.assignedWall
                    );

                    firstPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                    secondPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                    firstPoint.y += Constants.cabinet.measurementLine.MARGIN;
                    secondPoint.y += Constants.cabinet.measurementLine.MARGIN;
                }
            }

            lineCoordinates.push(
                [firstPointClone, secondPointClone],
                [firstPoint, firstPointClone],
                [secondPoint, secondPointClone]
            );
        }
        return lineCoordinates;
    }
}
