export let Constants = {
    object: {
        type: {
            CABINET: 'cabinet',
            APPLIANCE: 'appliance',
            FIXTURE: 'fixture',
        },
    },
    fixture: {
        type: {
            DOOR: 'door',
            WINDOW: 'window',
            FIXED_COLUMN: 'fixedColumn',
            OPEN_SPACE: 'openSpace',
        },
        subType: {
            SLIDING: 'sliding',
            STANDARD: 'standard',
            BALCONY: 'balcony',
        },
        scale: 1000,
        margin: 0.05,
    },
    room: {
        dimensions: {
            HEIGHT: 2.8,
            WIDTH: 0.15,
        },
        scale: 1,
    },
    unit: {
        INCH_STEP: 25.4,
    },
    camera: {
        ray: {
            ANGLE_LIMIT: 1.04,
        },
        hiddenComponents: {
            ANGLE: Math.PI / 2.3,
        },
    },
    wall: {
        orientation: {
            HORIZONTAL: 'horizontal',
            VERTICAL: 'vertical',
        },
    },
    cabinet: {
        type: {
            BASE: 'base',
            WALL: 'wall',
            TALL: 'tall',
            ISLAND: 'island',
        },
        handle: {
            SIZE: 0.25,
            MARGIN: 0.001,
        },
        measurementLine: {
            SHIFTING: 0.3,
            MARGIN: 0.02,
        },
        state: {
            RESIZING: 'resizing',
            MOVING: 'moving',
            NONE: 'none',
        },
        transparency: {
            SEMI_TRANSPARENT: 0.1,
            VISIBLE: 0.1,
            FULLY_VISIBLE: 0.4,
        },
        minDimensions: {
            WIDTH: 0.3,
            HEIGHT: 0.9,
            DEPTH: 0.58,
        },
    },
    error: {
        MARGIN: 0.001,
    },
    corner: {
        CORNER_A: 'cornerA',
        CORNER_B: 'cornerB',
    },
    blockedCornerArea: {
        SIZE: 0.64,
        STANDARD_FILLER_SIZE: 0.06,
        WALL_STANDARD_FILLER_SIZE: 0.04,
    },

    models3D: {
        window: {
            GLASS_INSIDE: 'glassInside',
            GLASS_OUTSIDE: 'glassOutside',
            FRAME_BOTTOM: 'frameBottom',
            FRAME_TOP: 'frameTop',
            FRAME_LEFT: 'frameLeft',
            FRAME_RIGHT: 'frameRight',
            CORNER_BOTTTOM_LEFT: 'cornerBottomLeft',
            CORNER_BOTTTOM_RIGHT: 'cornerBottomRight',
            CORNER_TOP_LEFT: 'cornerTopLeft',
            CORNER_TOP_RIGHT: 'cornerTopRight',
            SILL: 'sill',
            HANDLE: 'handle',
            BASE_WIDTH: 1.18,
            BASE_HEIGHT: 1.06,
            GLASS_INDENTATION: 0.02396,
            SILL_HEIGHT: 0.02,
        },
        door: {
            DOOR_PANEL: 'doorPanel',
            FRAME_TOP: 'frameTop',
            FRAME_LEFT: 'frameLeft',
            FRAME_RIGHT: 'frameRight',
            FRAME_CORNER_LEFT: 'frameCornerLeft',
            FRAME_CORNER_RIGHT: 'frameCornerRight',
            HANDLE_OUTSIDE: 'handleOutside',
            SLIDING_DOOR_HANDLE: 'slidingDoorHandle',
            LOCK_INSIDE: 'lockInside',
            HANDLE_OUTSIDE_LEFT: 'handleOutsideLeft',
            LOCK_INSIDE_LEFT: 'lockInsideLeft',
            SLIDING_DOOR_HANDLE_LEFT: 'slidingDoorHandleLeft',
            BASE_WIDTH: 0.693989,
            BASE_HEIGHT: 2.07376,
            FRAME_DEPTH: 0.05887,
            DOUBLE_DOOR_MIN_SIZE: 0.9,
        },
        balcony: {
            innerDoor1: {
                INNER_FRAME_RIGHT: 'innerFrameRight',
                INNER_FRAME_LEFT: 'innerFrameLeft',
                INNER_FRAME_TOP: 'innerFrameTop',
                INNER_FRAME_BOTTOM: 'innerFrameBottom',
                INNER_FRAME_CORNER_BOTTOM_RIGHT: 'innerFrameCornerBottomRight',
                INNER_FRAME_CORNER_BOTTOM_LEFT: 'innerFrameCornerBottomLeft',
                INNER_FRAME_CORNER_TOP_LEFT: 'innerFrameCornerTopLeft',
                INNER_FRAME_CORNER_TOP_RIGHT: 'innerFrameCornerTopRight',
                GLASS_LEFT: 'glassLeft',
            },
            innerDoor2: {
                INNER_FRAME_RIGHT2: 'innerFrameRight2',
                INNER_FRAME_LEFT2: 'innerFrameLeft2',
                INNER_FRAME_TOP2: 'innerFrameTop2',
                INNER_FRAME_Bottom2: 'innerFrameBottom2',
                INNER_FRAME_CORNER_BOTTOM_RIGHT2: 'innerFrameCornerBottomRight2',
                INNER_FRAME_CORNER_BOTTOM_LEFT2: 'innerFrameCornerBottomLeft2',
                INNER_FRAME_CORNER_TOP_LEFT2: 'innerFrameCornerTopLeft2',
                INNER_FRAME_CORNER_TOP_Right2: 'innerFrameCornerTopRight2',
                GLASS_LEFT2: 'glassLeft2',
            },
            outerFrame: {
                OUTER_CORNER_TOP_LEFT: 'outerCornerTopLeft',
                OUTER_CORNER_TOP_Right: 'outerCornerTopRight',
                OUTER_CORNER_FRAME_LEFT: 'outerCornerFrameLeft',
                OUTER_CORNER_FRAME_RIGHT: 'outerCornerFrameRight',
                OUTER_FRAME_TOP: 'outerFrameTop',
            },
        },
    },
    continuousLayout: {
        MARGIN: 0.3,
    },
    appliance: {
        type: {
            SINK: 'sink',
            COOKTOP: 'cooktop',
            HOOD: 'hood',
            OVEN: 'oven',
            DISHWASHER: 'dishwasher',
            WASHING_MACHINE: 'washer',
            REFRIGERATOR: 'refrigerator',
        },
        format: {
            FREESTANDING: 'freestanding',
            BUILTIN: 'builtin',
        },
    },
    hood: {
        HEIGHT_FROM_FLOOR: 1.45,
        HEIGHT_ADDITION: 0.1,
    },
    oven: {
        subtype: {
            RANGE: 'range',
        },
        HEIGHT_MARGIN: 0.05,
    },
    symmetry: {
        MARGIN: 0.3,
    },
    baseCabinetModel: {
        buildingBlock: {
            CABINET: 'cabinet',
            COUNTERTOP: 'countertop',
            SKIRTING: 'skirting',
            DOOR_1: 'door1',
            DOOR_2: 'door2',
        },
        sidePanel: {
            WIDTH: 0.018,
        },
    },
    textures: {
        WOOD_QUARTERED_CITTADINO: 'WoodQuarteredCittadino',
        STONE_MARBLE_CALACATTA: 'StoneMarbleCalacatta',
        PLASTIC_SCUFFED: 'PlasticScuffed',
        METAL_ALUMINIUM_SCRATCHED: 'MetalAluminiumScratched',
    },
    GAP_BETWEEN_DOORS: 0.004,
    MM_TO_BJS_COEFF: 1000,
    STANDARD_FILLER_SIZE: {
        mm: 0.06,
        in: 0.0508,
    },
    SMALLEST_FILLER_SIZE: {
        mm: 0.04,
        in: 0.0499,
    },
    BIGGEST_ONE_ROW_ISLAND: 0.6,
    BIGGEST_ONE_ROW_ISLAND_INCHES: 0.6095999999999999,
    wallCornerCabinet: {
        DOOR_SIZE: {
            mm: 0.28,
            in: 0.3048,
        },
    },
    baseCornerCabinet: {
        DOOR_SIZE: { mm: 0.45, in: 0.4572 },
    },
    availableCabinetDimensions: {
        mm: [0.15, 0.2, 0.3, 0.45, 0.6, 0.9, 1.2],
        in: [0.2286, 0.3048, 0.381, 0.4572, 0.5334, 0.6096, 0.6858, 0.762, 0.9144, 1.2192],
    },
    section: {
        type: {
            CORNER_SECTION: 'cornerSection',
            SIMPLE_SECTION: 'singleSection',
            FILLER: 'filler',
        },
    },
    standardDimensions: {
        base: {
            WIDTH: {
                mm: 0.6,
                in: 0.6096,
            },
            HEIGHT: {
                mm: 0.9,
                in: 0.036,
            },
            DEPTH: {
                mm: 0.58,
                in: 0.6096,
            },
        },
        wall: {
            WIDTH: {
                mm: 0.6,
                in: 0.6096,
            },
            HEIGHT: {
                mm: 0.7,
                in: 0.028,
            },
            DEPTH: {
                mm: 0.36,
                in: 0.014,
            },
        },
        tall: {
            WIDTH: {
                mm: 0.6,
                in: 0.6096,
            },
            HEIGHT: {
                mm: 2.15,
                in: 0.0846,
            },
            DEPTH: {
                mm: 0.58,
                in: 0.6096,
            },
        },
    },
    filler: {
        type: {
            BASE: 'base',
            TALL: 'tall',
        },
    },
    highlighting: {
        HEX_COLOR: '#ADD8E6',
    },
    glossines: {
        matt: { MICROSURFACE: 0, CLEAR_COAT: 0 },
        countertop: { MICROSURFACE: 0.9, CLEAR_COAT: 0 },
        cabinets: { MICROSURFACE: 1, CLEAR_COAT: 1 },
        floor: { MICROSURFACE: 0.88, CLEAR_COAT: 0 },
        wall: { MICROSURFACE: 0.5, CLEAR_COAT: 0 },
        skirting: { MICROSURFACE: 0.5, CLEAR_COAT: 0 },
    },

    DOOR_DEPTH: 0.02,
};
