import { MovementManager } from './ApplianceComponents/MovementManager';
import { MeshComponent } from '../../Components/MeshComponent';
import { BaseKitchenObject } from '../BaseKitchenObject';
import { ActionManager, Color3, ExecuteCodeAction, MeshBuilder, StandardMaterial, Texture, Vector3 } from '@babylonjs/core';
import store from '@/store/index.js';
import { Constants } from '../../Tools/constants';
import { ObstacleManager } from './ApplianceComponents/ObstacleManager';
import { Utilities } from '../../Tools/utilities';
import { MeasurementLine } from './ApplianceComponents/MeasurementLine';
import { Island } from '../Cabinets/Island';

export class BaseAppliance extends BaseKitchenObject {
    currentCabinet;
    type;
    subtype;
    dimensions;
    color;
    direction;
    format;
    currentUnit = store.getters['core/selectedUnit'];
    hasHood = false;
    currentColor = new Color3(1, 1, 1);

    constructor() {
        super();
        this.movementManager = this.registerComponent(MovementManager);
        this.meshComponent = this.registerComponent(MeshComponent);
        this.obstacleManager = this.registerComponent(ObstacleManager);
        this.measurementLine = this.registerComponent(MeasurementLine);
    }

    async build(data) {
        this.setProperties(data);
        if (typeof data.id === 'number') {
            this.setId(data.id);
            this.editor.lastApplianceId = data.id + 1;
        } else {
            this.setId(this.editor.lastApplianceId);
            this.editor.lastApplianceId++;
        }
        this.position = new Vector3(0, 0, 0);
        if (data.position) {
            await this.createMesh();
            this.position = new Vector3(data.position._x, data.position._y, data.position._z);
            this.meshComponent.setPosition(this.position);
        } else {
            await this.createMesh();
            this.setInitialPosition();
            this.movementManager.followCursor();
        }
        if (this.meshComponent.getMesh()) {
            let currentCabinetHeight = this.currentCabinet ? this.currentCabinet.height : 0;
            if (this.type !== 'hood' && !data.position) {
                let ovenHeightDifference = 0;
                if (this.type === Constants.appliance.type.OVEN) {
                    ovenHeightDifference = Constants.oven.HEIGHT_MARGIN;
                }
                if (this.type !== Constants.appliance.type.COOKTOP) {
                    this.meshComponent.mesh.position.y += currentCabinetHeight - this.height / 2 - ovenHeightDifference;
                    this.position = this.meshComponent.mesh.position;
                }
            }
        }
    }

    async createMesh() {
        await this.setModel();
        this.model = this.meshComponent.getMesh().clone();
        this.meshComponent.setMesh(this.createSurroundingBox());
        this.model.parent = this.meshComponent.getMesh();
        this.meshComponent.setType(Constants.object.type.APPLIANCE);
        this.setActions();
        this.positionMesh();
        this.rotateAppliance();
        this.meshComponent.mesh.id = this.id;
        this.meshComponent.mesh.position.y -= this.height - Constants.error.MARGIN;
    }

    createSurroundingBox() {
        const surroundingBox = MeshBuilder.CreateBox('surroundingBox', {
            width: this.width + 0.001,
            depth: this.depth + 0.1,
            height: this.height + 0.001,
        });
        surroundingBox.visibility = 0.5;
        surroundingBox.material = new StandardMaterial('surroundingBoxMaterial', this.editor.sceneComponent.get());
        surroundingBox.material.diffuseColor = Color3.FromHexString(Constants.highlighting.HEX_COLOR);
        return surroundingBox;
    }

    highlight() {
        this.meshComponent.getMesh().visibility = 0.4;
        this.meshComponent.getMesh().isVisible = true;
        this.meshComponent.getMesh().showBoundingBox = true;
        this.model.visibility = 1;
    }

    unhighlight() {
        this.meshComponent.getMesh().visibility = 0.01;
        this.meshComponent.getMesh().isVisible = false;

        this.meshComponent.getMesh().showBoundingBox = false;
        this.model.visibility = 1;
    }

    setProperties(data) {
        this.color = data.color;
        this.direction = data.direction;
        this.type = data.type;
        this.subtype = data.subtype;
        this.format = data.format;
        this.setDimensions(data.dimensions);

        //since these dimensions come empty from vue
        if (['sink', 'hood', 'oven'].includes(data.type)) {
            this.fillEmptyDimensionsFromVue();
        } else if (data.type === 'cooktop') {
            this.depth = 0.6;
            this.height = 0.04;
            if (this.editor.sceneInfo.unit === 'in') {
                this.depth = 0.6095999999999999;
                this.height = 0.04;
            }
        } else if (data.type === 'refrigerator') {
            this.height = 1.92;
            this.depth = 0.6;
            if (this.editor.sceneInfo.unit === 'in') {
                this.depth = 0.6095999999999999;
                this.height = 1.92;
            }
        } else if (data.type === 'washer') {
            this.depth = 0.6;
            this.height = 0.85;
            if (this.editor.sceneInfo.unit === 'in') {
                this.depth = 0.6095999999999999;
                this.height = 0.85;
            }
        } else if (data.type === 'dishwasher') {
            this.depth = 0.6;
            if (this.editor.sceneInfo.unit === 'in') {
                this.depth = 0.6095999999999999;
            }
        }
    }

    rotateAppliance() {
        if (this.assignedWall) {
            this.rotation = -this.assignedWall.rotationAngle;
            this.meshComponent.mesh.rotation.y = this.rotation;
        } else if (this.rotation) {
            this.meshComponent.mesh.rotation.y = (this.rotation * Math.PI) / 180;
        }
    }

    setInitialPosition() {
        const cabinets = this.editor.cabinets;
        if (cabinets.length === 0) {
            this.position = new Vector3.Zero();
            this.meshComponent.getMesh().position = this.position;
            return;
        }
        let randomCabinet = cabinets[Math.floor(Math.random() * cabinets.length)];

        while (
            randomCabinet.assignedWall?.meshComponent.getMesh().visibility === 0 ||
            randomCabinet.type === Constants.cabinet.type.WALL
        ) {
            randomCabinet = cabinets[Math.floor(Math.random() * cabinets.length)];
        }
        this.position = randomCabinet.meshComponent.getMesh().position.clone();
        this.position.y = this.height / 2;
        this.meshComponent.getMesh().position = this.position;
        this.currentCabinet = randomCabinet;
        this.assignedWall = randomCabinet.assignedWall;
    }

    setActions() {
        this.meshComponent.mesh.actionManager = new ActionManager(this.editor.sceneComponent.get());
        this.meshComponent.mesh.actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPointerOverTrigger,
                },
                () => {
                    this.highlight();
                }
            )
        );

        this.meshComponent.mesh.actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPointerOutTrigger,
                },
                () => {
                    if (store.state.appliances.selectedAppliance?.id !== this.id) {
                        this.unhighlight();
                    }
                }
            )
        );

        this.meshComponent.mesh.actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPickDownTrigger,
                },
                () => {
                    this.availableSlots = this.editor.getCabinetSlots();
                    this.editor.selectedItem = this;
                    // if (store.state.appliances.selectedAppliance?.id === this.id) {
                    //     store.state.appliances.selectedAppliance.deselect();
                    //     this.editor.selectedItem = this;
                    // } else
                    if (store.state.appliances.selectedAppliance && store.state.appliances.selectedAppliance?.id !== this.id) {
                        store.state.appliances.selectedAppliance.deselect();
                        this.select();
                    } else if (!store.state.appliances.selectedAppliance) {
                        this.select();
                    }
                }
            )
        );

        this.meshComponent.mesh.actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPickUpTrigger,
                },
                () => {
                    this.availableSlots = this.editor.getCabinetSlots();
                    if (store.state.appliances.selectedAppliance?.id === this.id && !this.editor.selectedItem) {
                        store.state.appliances.selectedAppliance.deselect();
                    }
                }
            )
        );
    }

    dispose() {
        if (this.currentCooktop) {
            this.currentCooktop.hasHood = false;
        }
        this.meshComponent.getMesh().dispose(false, true);
        this.measurementLine.dispose();
        this.editor.selectedItem = null;
        this.editor.appliances.splice(Utilities.getIndexInArray(this, this.editor.appliances), 1);
        this.editor.removeObject(this);
        store.commit('appliances/setSelectedAppliance', null);
        this.editor.sceneComponent.save3D();
    }

    select() {
        if (store.state.cabinets.selectedCabinet) {
            store.state.cabinets.selectedCabinet.deselect();
        } else if (store.state.appliances.selectedAppliance) {
            store.state.appliances.selectedAppliance.deselect();
        } else if (store.state.generated.selectedObject.length !== 0) {
            const selected = [].concat(store.state.generated.selectedObject);
            for (let index = 0; index < selected.length; index++) {
                selected[index].meshComponent.getMesh().showBoundingBox = false;
            }
            store.commit('generated/setSelectedObject', []);
        }
        this.editor.selectedItem = this;
        store.commit('appliances/setSelectedAppliance', this);
        this.highlight();

        if (this.currentCabinet instanceof Island) {
            return;
        }
        if (store.state.core.step !== 4) {
            this.measurementLine.build();
        }
    }

    deselect() {
        this.unhighlight();
        this.meshComponent.isHighlighted = false;
        this.editor.selectedItem = null;
        this.measurementLine.dispose();
        store.commit('appliances/setSelectedAppliance', null);
    }

    createApplianceClone() {
        const meshClone = this.meshComponent.getMesh().clone('meshClone');
        meshClone.id = this.id;

        const applianceObject = new BaseAppliance();
        applianceObject.editor = this.editor;

        applianceObject.position = this.position;
        applianceObject.width = this.width;
        applianceObject.height = this.height;
        applianceObject.depth = this.depth;
        applianceObject.dimensions = this.dimensions;

        applianceObject.meshComponent.mesh = meshClone;
        applianceObject.meshComponent.getMesh().position = this.position;

        applianceObject.isClone = true;
        applianceObject.assignedWall = this.assignedWall;
        applianceObject.type = this.type;
        applianceObject.subtype = this.subtype;
        applianceObject.format = this.format;
        applianceObject.currentCabinet = this.currentCabinet;
        applianceObject.currentCooktop = this.currentCooktop;

        return applianceObject;
    }

    shiftOnIsland(pickedPoint) {
        let threshold = this.currentCabinet.getOneRowThreshold();
        let position = this.currentCabinet.meshComponent.getMesh().position.clone();
        let bottomPoint;
        let topPoint;
        let orientationIndicator = 0.05;

        if (this.type === Constants.appliance.type.COOKTOP || this.type === Constants.appliance.type.SINK) {
            orientationIndicator = 0;
        }
        if (this.currentCabinet.rotation === 0 || this.currentCabinet.rotation === 180) {
            bottomPoint = new Vector3(position.x, position.y, position.z + this.depth / 2);
            topPoint = new Vector3(position.x, position.y, position.z - this.depth / 2);

            if (this.currentCabinet.depth === threshold) {
                position = pickedPoint;
                bottomPoint = new Vector3(position.x, position.y, position.z - this.depth / 2);
                topPoint = new Vector3(position.x, position.y, position.z + this.depth / 2);
            }
            if (
                Vector3.Distance(this.meshComponent.getMesh().position, bottomPoint) >
                Vector3.Distance(this.meshComponent.getMesh().position, topPoint)
            ) {
                this.meshComponent.getMesh().position.z -=
                    this.currentCabinet.rowDepths[this.assignedRow] / 2 - this.depth / 2 + orientationIndicator;
                this.rotation = this.currentCabinet.rotation - 180 + this.currentCabinet.rotation;
            } else {
                this.meshComponent.getMesh().position.z +=
                    this.currentCabinet.rowDepths[this.assignedRow] / 2 - this.depth / 2 + orientationIndicator;
                this.rotation = this.currentCabinet.rotation + this.currentCabinet.rotation;
            }
        } else if (this.currentCabinet.rotation === 90 || this.currentCabinet.rotation === 270) {
            bottomPoint = new Vector3(position.x + this.depth / 2, position.y, position.z);
            topPoint = new Vector3(position.x - this.depth / 2, position.y, position.z);

            if (this.currentCabinet.depth === threshold) {
                position = pickedPoint;
                bottomPoint = new Vector3(position.x - this.depth / 2, position.y, position.z);
                topPoint = new Vector3(position.x + this.depth / 2, position.y, position.z);
            }
            if (
                Vector3.Distance(this.meshComponent.getMesh().position, bottomPoint) >
                Vector3.Distance(this.meshComponent.getMesh().position, topPoint)
            ) {
                this.meshComponent.getMesh().position.x -=
                    this.currentCabinet.rowDepths[this.assignedRow] / 2 - this.depth / 2 + orientationIndicator;
                this.rotation = this.currentCabinet.rotation - 180 + (this.currentCabinet.rotation - 90);
            } else {
                this.meshComponent.getMesh().position.x +=
                    this.currentCabinet.rowDepths[this.assignedRow] / 2 - this.depth / 2 + orientationIndicator;
                this.rotation = this.currentCabinet.rotation + (this.currentCabinet.rotation - 90);
            }
        }
        this.meshComponent.getMesh().position.y += 0.025;
    }

    getBlockedSegmentOnIsland() {
        if (this.currentCabinet instanceof Island) {
            const edges = this.currentCabinet.getEdges(this.assignedRow);
            let assignedWall = { baseInnerCornerA: edges.rightPoint, baseInnerCornerB: edges.leftPoint };
            let direction = assignedWall.baseInnerCornerA.subtract(assignedWall.baseInnerCornerB);
            direction.normalize();
            let projectedPoint = Utilities.getPointOnLine({
                cornerA: assignedWall.baseInnerCornerA.clone(),
                cornerB: assignedWall.baseInnerCornerB.clone(),
                point: this.position.clone(),
            });
            projectedPoint.y = 0;
            let blockedSegmentOnWall = {
                firstPoint: projectedPoint.clone().addInPlace(direction.clone().scaleInPlace(this.width / 2)),
                secondPoint: projectedPoint.clone().addInPlace(direction.clone().scaleInPlace(-this.width / 2)),
            };
            return blockedSegmentOnWall;
        }
    }

    resize(size, key) {
        if (this.editor.unit === 'in') {
            size = (size * Constants.unit.INCH_STEP) / Constants.fixture.scale;
        } else {
            size /= Constants.MM_TO_BJS_COEFF;
        }
        switch (key) {
            case 'width':
                this.meshComponent.getMesh().scaling.x = size / (this.width / this.meshComponent.getMesh().scaling.x);
                this.width = size;
                this.measurementLine.update();
                break;
            case 'height':
                this.meshComponent.getMesh().scaling.y = size / (this.height / this.meshComponent.getMesh().scaling.y);
                this.height = size;
                this.measurementLine.update();
                break;
            case 'depth':
                this.meshComponent.getMesh().scaling.z = size / (this.depth / this.meshComponent.getMesh().scaling.z);
                this.depth = size;
                this.measurementLine.update();
                break;
        }
    }

    resizeToCurrentDimensions(width = this.width, depth = this.depth, height = this.height) {
        this.resizeWidth(width);
        this.resizeDepth(depth);
        this.resizeHeight(height);
    }

    resizeWidth(size = this.width) {
        const dimensions = Utilities.getMeshDimensions(this.meshComponent.getMesh());
        this.meshComponent.getMesh().scaling.x = size / (dimensions.width / this.meshComponent.getMesh().scaling.x);
    }

    resizeHeight(size = this.height) {
        const dimensions = Utilities.getMeshDimensions(this.meshComponent.getMesh());
        this.meshComponent.getMesh().scaling.y = size / (dimensions.height / this.meshComponent.getMesh().scaling.y);
    }

    resizeDepth(size = this.depth) {
        const dimensions = Utilities.getMeshDimensions(this.meshComponent.getMesh());
        this.meshComponent.getMesh().scaling.z = size / (dimensions.depth / this.meshComponent.getMesh().scaling.z);
    }

    getSectionWidthNeeded() {
        return this.width;
    }

    setMaterial(color) {
        this.meshComponent.getMesh().material.diffuseColor = color;
        this.meshComponent.getMesh().material.baseColor = color;
    }

    changeColor(color) {
        this.color = color;
        const textureType = this.type.charAt(0).toUpperCase() + this.type.slice(1);

        if (color == 'dark') {
            const filePath = require('@/assets/textures/appliances/'.concat(textureType.concat('/diffuseTexture.jpg')));
            this.model.material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        } else if (color === 'light') {
            const filePath = require('@/assets/textures/appliances/'.concat(textureType.concat('/diffuseTextureLight.jpg')));
            this.model.material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        }
    }

    hide(enable) {
        if (!this.meshComponent.getMesh()) {
            return;
        }
        this.meshComponent.getMesh().setEnabled(enable);
        this.model.setEnabled(enable);
        if (this.hasHood) {
            if (!this.hasHood.meshComponent.getMesh()) {
                return;
            }
            this.hasHood.meshComponent.getMesh().setEnabled(enable);
            this.hasHood.model.setEnabled(enable);
        }
    }
}
