import { Vector3 } from '@babylonjs/core';
import { BaseAppliance } from '../../KitchenObjects/Appliances/BaseAppliance';
import { BaseUnit } from '../../KitchenObjects/Cabinets/BaseUnit';
import { CornerCabinet } from '../../KitchenObjects/CabinetSections/CornerCabinet';
import { SingleCabinet } from '../../KitchenObjects/CabinetSections/SingleCabinet';
import { CornerSection } from '../../KitchenObjects/Models3D/BaseUnit/BaseCornerSection/CornerSection';
import { TallCornerSection } from '../../KitchenObjects/Models3D/TallUnit/TallCornerSection/TallCornerSection';
import { WallCornerSection } from '../../KitchenObjects/Models3D/WallUnit/WallCornerSection';
import { RoomObject } from '../../KitchenObjects/Room/RoomObject';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { CabinetAligner } from './CabinetAligner';
import { LayoutUseCaseHandler } from './LayoutUseCaseHandler';
import store from '@/store/index.js';

export class CabinetReplacer {
    scanResults;
    baseCabinetSection;
    constructor(editor) {
        this.editor = editor;
        this.cabinetAligner = new CabinetAligner(editor);
        this.layoutUseCaseHandler = new LayoutUseCaseHandler(editor);
    }

    async replaceAllCabinets() {
        const walls = this.editor.getObjectByType(RoomObject).walls;
        for (let index = 0; index < walls.length; index++) {
            const cabinets = walls[index].getCabinetsInReplacementDirection();
            await this.processCabinets(cabinets.base);
            await this.processCabinets(cabinets.wall);
        }
    }

    async processCabinets(slots) {
        for (let index = 0; index < slots.length; index++) {
            const cabinet = slots[index];
            if (cabinet instanceof BaseUnit && !cabinet.isSectioned) {
                this.layoutUseCaseHandler.sectionCalculator.currentCabinet = cabinet;
                await this.replaceCabinet(cabinet);
            }
        }
    }

    async replaceCabinet(cabinet) {
        cabinet.blockedArea?.dispose();
        cabinet.isSectioned = true;

        let [replacementDirection, startingPoint, allowedSegment] = cabinet.calculateStartingPointAndReplacementDirection();

        const data = {
            startingPoint: startingPoint.clone(),
            replacementDirection: replacementDirection.clone(),
            cabinet: cabinet,
            allowedSegment: allowedSegment,
        };

        await this.generateSeparateCabinets(data);
    }

    async generateSeparateCabinets(data) {
        let result = this.layoutUseCaseHandler.handleAllUseCases(data);
        const cabinets = JSON.parse(JSON.stringify(store.state.core.projectData.cabinets));

        //if we are loading the project from presaved data
        for (const cabinet of cabinets) {
            if (data.cabinet.id === cabinet.id && cabinet.sectionsInformation && cabinet.sectionsInformation?.length !== 0) {
                result = JSON.parse(JSON.stringify(cabinet.sectionsInformation));
                for (let index = 0; index < result.length; index++) {
                    if (typeof result[index].sectionData === 'number') {
                        const foundAppliance = this.editor.getApplianceById(result[index].sectionData);
                        result[index].sectionData = foundAppliance;
                    }
                }
            }
            if (cabinet.countertopCustomization && data.cabinet.id === cabinet.id) {
                data.cabinet.countertopCustomization = cabinet.countertopCustomization;
            }
        }
        console.log('result', result);
        console.log('data.cabinet.type', data.cabinet.type);
        this.repositionConnectedPeninsula(data);
        let positionIndex = 0;
        for (let idx = 0; idx < result.length; idx++) {
            // sent idx to vuex
            store.commit('generated/setGeneratedIDX', idx);

            let currentModel, parentCabinetRotation;
            const resultForType = result[idx];
            if (resultForType.numberOfSections === 0) {
                continue;
            }

            let resizingDimensions = {
                width: resultForType.sectionWidth,
                depth: data.cabinet.depth,
                height: data.cabinet.height,
            };

            [currentModel, resizingDimensions, parentCabinetRotation] = await this.chooseModelTypeAndResizingDimensions(
                data,
                resultForType
            );
            currentModel.setSectionInfo(resultForType);
            currentModel.resize(resizingDimensions);

            for (let index = 0; index < resultForType.numberOfSections; index++) {
                const sectionSpecifications = {
                    index: null,
                    resultForType,
                    replacementDirection: data.replacementDirection,
                    row: null,
                    isOnWall: true,
                };

                if (idx === result.length - 1 && index === resultForType.numberOfSections - 1) {
                    sectionSpecifications.index = 'last';
                }

                let position = data.startingPoint.add(data.replacementDirection.clone().scale(resultForType.sectionWidth / 2));
                data.startingPoint = data.startingPoint.add(data.replacementDirection.scale(resultForType.sectionWidth));

                const singleCabinet = this.createSingleCabinet(data, resultForType, position.clone());
                singleCabinet.positionIndex = positionIndex;

                currentModel.setCurrentParentSection(singleCabinet);
                let baseCabinetInstance = currentModel.mergeModel(sectionSpecifications);
                if (
                    resultForType.sectionData?.isCornerCabinet &&
                    ((data.cabinet.type === Constants.cabinet.type.WALL &&
                        resultForType.sectionData.cornerIndex === Constants.corner.CORNER_A) ||
                        (data.cabinet.type === Constants.cabinet.type.TALL &&
                            resultForType.sectionData.cornerIndex === Constants.corner.CORNER_B))
                ) {
                    baseCabinetInstance.mesh.scaling.z = -1;
                }

                if (resultForType.sectionData?.isCornerCabinet) {
                    currentModel.meshComponent.getMesh().dispose();
                }

                baseCabinetInstance.mesh.rotation.y = parentCabinetRotation;
                baseCabinetInstance.mesh.position = singleCabinet.meshComponent.getPosition().clone();
                baseCabinetInstance.mesh.position.y = resizingDimensions.height / 2;
                baseCabinetInstance.mesh.position.y += data.cabinet.heightFromFloor ? data.cabinet.heightFromFloor : 0;

                const appliance = resultForType.sectionData;
                if (appliance?.subtype === 'ovenSignle') {
                    appliance.meshComponent.getMesh().position.y += 0.1;
                }
                singleCabinet.meshComponent.setMesh(baseCabinetInstance.mesh);
                singleCabinet.setBuildingBlocks(baseCabinetInstance.buildingBlocks);
                const averageCabinetWidth = Utilities.getAverageCabinetWidth(
                    Constants.availableCabinetDimensions[this.editor.unit]
                );
                if (singleCabinet.width > averageCabinetWidth && singleCabinet.type !== Constants.section.type.CORNER_SECTION) {
                    singleCabinet.buildingBlocks.doorBlock.changeDoorType('2door');
                }
                if (resultForType.isFiller) {
                    singleCabinet.type = 'fillerSection';
                }
                data.cabinet.sections.push(singleCabinet);
                singleCabinet.sectionData =
                    typeof resultForType.sectionData?.id === 'number' ? resultForType.sectionData.id : resultForType.sectionData;
                singleCabinet.isFiller = resultForType.isFiller;

                const sectionInformation = singleCabinet.getInformation();
                // if (data.cabinet.type === 'base' && data.cabinet.id === 5) {
                    // console.log("data.cabinet.type", data.cabinet.type);
                    // console.log("data.cabinet.id", data.cabinet.id);
                    // console.log("idx", idx);
                    // console.log("sectionInformation.index", sectionInformation.index);
                    // console.log("data.cabinet", data.cabinet);
                    // debugger
                // }

                if (sectionInformation) {
                    const sectionsInformationIdx = data.cabinet.sectionsInformation[sectionInformation.index];
                    if (!resultForType.sectionData?.isCornerCabinet && !resultForType.isFiller) {
                        if (sectionsInformationIdx.customization.cabinets.doorType) {
                            baseCabinetInstance.buildingBlocks.doorBlock.changeDoorType(
                                sectionsInformationIdx.customization.cabinets.doorType
                            );
                        }
                    }
                }

                positionIndex += 1;
            }
        }
        data.cabinet.meshComponent.getMesh().dispose(false, true);
        if ([Constants.cabinet.type.BASE, Constants.cabinet.type.TALL].includes(data.cabinet.type)) {
            if (data.cabinet.type !== Constants.cabinet.type.TALL) {
                data.cabinet.mergeCountertop();
            }
            data.cabinet.mergeSkirting();
        }

        data.cabinet.addEndPanel(data.startingPoint.clone());
    }

    async chooseModelTypeAndResizingDimensions(data, resultForType) {
        let parentCabinetRotation = data.cabinet.meshComponent.getMesh().rotation.y;
        let currentModel;
        const cabinet = data.cabinet;
        let resizingDimensions = {
            width: resultForType.sectionWidth,
            depth: cabinet.depth,
            height: cabinet.height,
        };
        if (resultForType.sectionData?.isCornerCabinet) {
            //WHEN SECTION IS A CORNER CABINET
            if (cabinet.type === Constants.cabinet.type.WALL) {
                //WALL CABINET TYPE
                currentModel = this.editor.addObject(WallCornerSection);
                await currentModel.setModel();

                //can be removed if model rotation is fixed in Blender
                if (cabinet.cornerCabinetInfo.cornerIndex === Constants.corner.CORNER_B) {
                    parentCabinetRotation += Math.PI;
                }
            } else if (cabinet.type === Constants.cabinet.type.TALL) {
                //TALL CABINET TYPE
                currentModel = this.editor.addObject(TallCornerSection);
                await currentModel.setModel();
                if (cabinet.cornerCabinetInfo.cornerIndex === Constants.corner.CORNER_B) {
                    parentCabinetRotation += Math.PI;
                }
            } else if (data.cabinet.type === Constants.cabinet.type.BASE) {
                currentModel = this.editor.addObject(CornerSection);
                await currentModel.setModel();
            }
        } else if (resultForType.isFiller && cabinet.type !== Constants.cabinet.type.WALL) {
            //WHEN SECTION IS A FILLER
            currentModel = this.editor.models3D.baseFillerSection;
            if (cabinet.type === Constants.cabinet.type.TALL) {
                currentModel = this.editor.models3D.tallFillerSection;
                if (resultForType.type === Constants.filler.type.BASE) {
                    currentModel = this.editor.models3D.baseFillerSection;
                }
            }
        } else {
            //NORMAL SECTION
            if (data.cabinet.type === Constants.cabinet.type.BASE) {
                currentModel = this.editor.models3D.baseCabinetSection;
            } else if (data.cabinet.type === Constants.cabinet.type.WALL) {
                currentModel = this.editor.models3D.wallCabinet;
            } else if (data.cabinet.type === Constants.cabinet.type.TALL) {
                currentModel = this.editor.models3D.tallCabinet;
            }
        }
        currentModel.setParentCabinet(data.cabinet);
        resizingDimensions = currentModel.getResizingDimensions(resultForType.sectionWidth);
        return [currentModel, resizingDimensions, parentCabinetRotation];
    }

    repositionConnectedPeninsula(data) {
        //calculation of the peninsula displacement based on the connected cabinet sectioning
        const cabinetPositionInfo = data.cabinet.getPositionInfo();
        const connectedPeninsula = data.cabinet.peninsulaConnection?.peninsula;
        const edgeIndex = data.cabinet.peninsulaConnection?.edgeIndex;
        const countertopExtension = 0.02;

        if (connectedPeninsula) {
            //calculating the actual filled width
            let displacement = data.cabinet.width - data.cabinet.filledWidth - connectedPeninsula.depth + countertopExtension;
            if (
                !data.cabinet.cornerCabinetInfo &&
                ((cabinetPositionInfo.cornerA &&
                    data.cabinet.connectedCabinet &&
                    data.cabinet.connectedCabinet.cornerCabinetInfo.cornerIndex === 'cornerB') ||
                    (cabinetPositionInfo.cornerB &&
                        data.cabinet.secondConnectedCabinet &&
                        data.cabinet.secondConnectedCabinet.cornerCabinetInfo.cornerIndex === 'cornerA'))
            ) {
                displacement -= data.cabinet.depth;
            } else if (data.cabinet.cornerCabinetInfo && !cabinetPositionInfo.cornerA && !cabinetPositionInfo.cornerB) {
                displacement += data.cabinet.depth;
            }

            if (data.cabinet.connectedCabinet && edgeIndex === 'secondPoint') {
                if ([90, 270].includes(connectedPeninsula.rotation)) {
                    connectedPeninsula.meshComponent.getMesh().position.x -= displacement;
                } else {
                    connectedPeninsula.meshComponent.getMesh().position.z -= displacement;
                }
            } else if (data.cabinet.secondConnectedCabinet && edgeIndex === 'firstPoint') {
                if ([90, 270].includes(connectedPeninsula.rotation)) {
                    connectedPeninsula.meshComponent.getMesh().position.x += displacement;
                } else {
                    connectedPeninsula.meshComponent.getMesh().position.z -= displacement;
                }
            }
        }
    }

    createSingleCabinet(data, resultForType, position) {
        const parentCabinet = data.cabinet;
        const width = resultForType.sectionWidth;
        let section = null;

        if (resultForType.sectionData?.isCornerCabinet) {
            section = this.editor.addObject(CornerCabinet);
            if (parentCabinet.type !== Constants.cabinet.type.WALL) {
                section.fillerSize = resultForType.sectionData.fillerSize;
                section.cornerIndex = resultForType.sectionData.cornerIndex;
            } else {
                section.fillerSize = 0;
                section.cornerIndex = resultForType.sectionData.cornerIndex;
            }
        } else {
            section = this.editor.addObject(SingleCabinet);
            section.appliance = resultForType.sectionData ? resultForType.sectionData : null;
        }
        section.build(parentCabinet, width, position);

        if (resultForType.sectionData instanceof BaseAppliance) {
            const cooktop = resultForType.cooktop;
            const appliance = resultForType.sectionData;
            const sectionPosition = section.meshComponent.getPosition().clone();

            if (Constants.appliance.type.SINK === appliance.type) {
                appliance.meshComponent.setPosition(
                    new Vector3(sectionPosition.x, parentCabinet.height - appliance.height / 2, sectionPosition.z)
                );
            } else if (appliance.type === Constants.appliance.type.COOKTOP) {
                appliance.meshComponent.setPosition(new Vector3(sectionPosition.x, parentCabinet.height, sectionPosition.z));
            } else {
                appliance.meshComponent.setPosition(sectionPosition);
            }

            if (appliance.type === Constants.appliance.type.COOKTOP) {
                if (appliance.hasHood) {
                    appliance.hasHood.meshComponent.setPosition(appliance.meshComponent.getPosition().clone());
                    if (appliance.hasHood.currentCabinet) {
                        appliance.hasHood.meshComponent.getMesh().position.y =
                            appliance.hasHood.currentCabinet.heightFromFloor +
                            appliance.hasHood.height / 2 +
                            Constants.hood.HEIGHT_ADDITION;
                    } else {
                        appliance.hasHood.meshComponent.getMesh().position.y =
                            Constants.hood.HEIGHT_FROM_FLOOR + appliance.hasHood.height / 2;
                    }
                }
            }
            if (appliance.type === Constants.appliance.type.HOOD) {
                appliance.meshComponent.getPosition().y =
                    section.meshComponent.getPosition().clone().y + Constants.hood.HEIGHT_ADDITION;
            } else if (appliance.type === Constants.appliance.type.OVEN && appliance.subtype !== 'range') {
                appliance.meshComponent.getPosition().y -= Constants.oven.HEIGHT_MARGIN;
            }

            if (cooktop) {
                cooktop.meshComponent.setPosition(section.meshComponent.getPosition().clone());
                cooktop.meshComponent.getPosition().y += section.height / 2;
                cooktop.position = cooktop.meshComponent.getPosition();
                if (cooktop.hasHood) {
                    cooktop.hasHood.meshComponent.setPosition(cooktop.meshComponent.getPosition().clone());
                    cooktop.hasHood.meshComponent.getMesh().position.y =
                        Constants.hood.HEIGHT_FROM_FLOOR + cooktop.hasHood.height / 2 + Constants.hood.HEIGHT_ADDITION;
                }
            }

            appliance.meshComponent.getMesh().rotation.y = appliance.rotation;
        }
        return section;
    }
}
