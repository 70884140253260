import { Color3 } from '@babylonjs/core';
import config from '../../../../../store/modules/step4/config';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { BuildingBlock } from './BuildingBlock';
import store from '@/store/index.js';

export class CabinetStructure extends BuildingBlock {
    constructor() {
        super();
    }

    setInfo(type) {
        this.setId();
        this.setType(type);
        this.setSubtype();

        const collection = config[this.type]?.collections[0];
        if (collection) {
            this.setColor(collection.color.items[0], collection.color.type);
            this.setCollection(collection.name);
            this.setSurface(collection.surface?.items[0]);
            this.setDoor(collection.doorStyle?.items[0], config[this.type]?.doorType);
            this.setDimensions();
        }
    }

    setDoor(style, type) {
        if (type && type.length !== 0) {
            type = type[0].id;
        } else {
            type = null;
        }
        this.door = { style, type };
    }

    setDimensions() {
        const cabinetStructureDimensions = Utilities.getMeshDimensions(this.meshComponent.getMesh());
        this.width = this.parentSection?.width;
        this.height = Utilities.roundToPrecision(cabinetStructureDimensions?.height);

        this.dimensions = {
            width: this.parentSection?.width * Constants.MM_TO_BJS_COEFF,
            height: Utilities.roundToPrecision(cabinetStructureDimensions?.height) * Constants.MM_TO_BJS_COEFF,
        };
        if (this.editor.unit === 'in') {
            this.dimensions.width = Utilities.roundToPrecision(this.dimensions.width / Constants.unit.INCH_STEP, 10);
            this.dimensions.height = Utilities.roundToPrecision(this.dimensions.height / Constants.unit.INCH_STEP, 10);
        }

        if (this.parentSection.type === Constants.section.type.CORNER_SECTION) {
            this.dimensions.width = Constants.baseCornerCabinet.DOOR_SIZE[this.editor.unit] * Constants.MM_TO_BJS_COEFF;
            this.dimensions.cabinetWidth = this.parentSection?.width * Constants.MM_TO_BJS_COEFF;

            if (this.editor.unit === 'in') {
                this.dimensions.width = Utilities.roundToPrecision(this.dimensions.width / Constants.unit.INCH_STEP, 10);
                this.dimensions.cabinetWidth = Utilities.roundToPrecision(
                    this.dimensions.cabinetWidth / Constants.unit.INCH_STEP,
                    10
                );
            }
        }
    }

    changeColor(collectionName, collectionType, color, pattern = null) {
        this.collection.name = collectionName;
        this.color.value = color;
        this.color.type = collectionType;
        this.collectionType = collectionType;
        const innerStructure = this.parentSection.buildingBlocks?.innerStructure;

        let microsurface = Constants.glossines[this.type].MICROSURFACE;
        let clearCoat = Constants.glossines[this.type].CLEAR_COAT;

        if (this.surface === 'matt') {
            microsurface = Constants.glossines.matt.MICROSURFACE;
            clearCoat = Constants.glossines.matt.CLEAR_COAT;
        }

        if (collectionType === 'monoColor') {
            this.currentColor = Color3.FromHexString(color);
            const pbrMaterial = this.editor.PBRComponent.getTextureFromPool(
                collectionType,
                'noPattern',
                color,
                microsurface,
                clearCoat,
                ''
            );
            this.meshComponent.getMesh().material = pbrMaterial;

            if (innerStructure) {
                innerStructure.meshComponent.getMesh().material = pbrMaterial;
            }

            this.saveCustomizationInformation(collectionName, color, collectionType, 'noPattern');
            if (this.parentSection.buildingBlocks && this.type === 'cabinets' && this.parentSection.type === 'cornerSection') {
                this.parentSection.buildingBlocks.fillerBlock.currentColor = this.currentColor;
                this.parentSection.buildingBlocks.fillerBlock.meshComponent.getMesh().material = pbrMaterial;
                this.parentSection.buildingBlocks.fillerBlock.saveCustomizationInformation(
                    collectionName,
                    color,
                    collectionType,
                    'noPattern'
                );
            }
        } else if (collectionType === 'combo') {
            this.currentColor = Color3.FromHexString(color);
            const pbrMaterial = this.editor.PBRComponent.getTextureFromPool(
                collectionType,
                pattern,
                color,
                microsurface,
                clearCoat,
                ''
            );

            this.meshComponent.getMesh().material = pbrMaterial;

            if (innerStructure) {
                innerStructure.meshComponent.getMesh().material = pbrMaterial;
            }
            this.saveCustomizationInformation(collectionName, color, collectionType, pattern);

            if (this.parentSection.buildingBlocks && this.type === 'cabinets' && this.parentSection.type === 'cornerSection') {
                this.parentSection.buildingBlocks.fillerBlock.currentColor = this.currentColor;
                this.parentSection.buildingBlocks.fillerBlock.meshComponent.getMesh().material = pbrMaterial;
                this.parentSection.buildingBlocks.fillerBlock.saveCustomizationInformation(
                    collectionName,
                    color,
                    collectionType,
                    pattern
                );
            }
        } else if (collectionType === 'texture') {
            this.currentColor = new Color3(1, 1, 1);
            this.color.pattern = color;
            const pbrMaterial = this.editor.PBRComponent.getTextureFromPool(
                collectionType,
                color,
                '#FFFFFF',
                microsurface,
                clearCoat,
                ''
            );
            this.meshComponent.getMesh().material = pbrMaterial;

            if (innerStructure) {
                innerStructure.meshComponent.getMesh().material = pbrMaterial;
            }

            this.saveCustomizationInformation(collectionName, color, collectionType, pattern);
            if (this.parentSection.buildingBlocks && this.type === 'cabinets' && this.parentSection.type === 'cornerSection') {
                this.parentSection.buildingBlocks.fillerBlock.currentColor = this.currentColor;
                this.parentSection.buildingBlocks.fillerBlock.meshComponent.getMesh().material = pbrMaterial;
                this.parentSection.buildingBlocks.fillerBlock.saveCustomizationInformation(
                    collectionName,
                    color,
                    collectionType,
                    pattern
                );
            }
        }
    }

    saveCustomizationInformation(collectionName, color, collectionType, pattern) {
        const sectionInformation = this.parentSection.getInformation();
        if (typeof this.parentSection.customization[this.type] !== 'object') {
            this.parentSection.customization[this.type] = {};
        }
        this.parentSection.customization[this.type].color = {
            collection: collectionName,
            value: color,
            type: collectionType,
            pattern: pattern,
        };
        this.parentSection.customization[this.type].surface = this.surface;

        if (this.parentSection.parentCabinet.type === Constants.cabinet.type.ISLAND) {
            if (
                sectionInformation &&
                this.parentSection.parentCabinet?.sectionsInformation?.[this.parentSection.assignedRow]?.[
                    sectionInformation.index
                ]
            ) {
                this.parentSection.parentCabinet.sectionsInformation[this.parentSection.assignedRow][
                    sectionInformation.index
                ].customization[this.type].color = {
                    collection: collectionName,
                    value: color,
                    type: collectionType,
                    pattern: pattern,
                };

                this.parentSection.parentCabinet.sectionsInformation[this.parentSection.assignedRow][
                    sectionInformation.index
                ].customization[this.type].surface = this.surface;
            }
        } else if (sectionInformation && this.parentSection.parentCabinet.sectionsInformation[sectionInformation.index]) {
            this.parentSection.parentCabinet.sectionsInformation[sectionInformation.index].customization[this.type].color = {
                collection: collectionName,
                value: color,
                type: collectionType,
                pattern: pattern,
            };

            this.parentSection.parentCabinet.sectionsInformation[sectionInformation.index].customization[this.type].surface =
                this.surface;
        }
        this.editor.sceneComponent.save3D();
    }

    changeSurface(surface = this.surface) {
        const sectionInformation = this.parentSection.getInformation();

        this.surface = surface;
        let hexColorValue = '#FFFFFF';
        let pattern = 'noPattern';
        if (['combo', 'monoColor'].includes(this.color.type)) {
            hexColorValue = this.color.value;
        } else {
            pattern = this.color.value;
        }

        let microsurface = Constants.glossines[this.type].MICROSURFACE;
        let clearCoat = Constants.glossines[this.type].CLEAR_COAT;
        if (surface === 'matt') {
            microsurface = Constants.glossines.matt.MICROSURFACE;
            clearCoat = Constants.glossines.matt.CLEAR_COAT;
        }

        const pbr = this.editor.PBRComponent.getTextureFromPool(
            this.collectionType,
            pattern,
            hexColorValue,
            microsurface,
            clearCoat,
            '',
            'glossiness'
        );
        this.meshComponent.getMesh().material = pbr;
        const innerStructure = this.parentSection.buildingBlocks?.innerStructure;

        if (innerStructure) {
            innerStructure.meshComponent.getMesh().material = pbr;
        }

        // save in sectionsInformation
        this.parentSection.customization[this.type].surface = this.surface;
        if (this.parentSection.parentCabinet.type === Constants.cabinet.type.ISLAND) {
            if (
                sectionInformation &&
                this.parentSection.parentCabinet?.sectionsInformation?.[this.parentSection.assignedRow]?.[
                    sectionInformation.index
                ]
            ) {
                this.parentSection.parentCabinet.sectionsInformation[this.parentSection.assignedRow][
                    sectionInformation.index
                ].customization[this.type].surface = this.surface;
            }
        } else if (sectionInformation && this.parentSection.parentCabinet.sectionsInformation[sectionInformation.index]) {
            this.parentSection.parentCabinet.sectionsInformation[sectionInformation.index].customization[this.type].surface =
                this.surface;
        }
    }
}
