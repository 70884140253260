<template>
    <v-app>
        <kw-Toolbar />
        <v-main>
            <v-alert v-if="errors" type="error" class="mt-3">
                {{ errors }}
            </v-alert>
            
            <v-container
                class="text-center"
                fluid
                v-if="$apollo.loading || isPageLoading"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-container>

            <v-container class="pa-0" fluid v-else>
                <router-view></router-view>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import kwToolbar from './components/ToolBar';
import SuperTokens from 'supertokens-website';

export default {
    name: 'App',
    components: {
        kwToolbar,
    },
    data() {
        return {
            //
        };
    },
    computed: {
        ...mapState('core', ['isPageLoading', 'errors']),
    },
    methods: {
        ...mapActions('subscription', ['fetchSubscription']),
    },
    async mounted() {
        if (await SuperTokens.doesSessionExist()) {
            this.fetchSubscription(this);
        }
    }
};
</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
}

body {
    overflow: hidden;
    height: 100vh;
}
</style>
