<template>
    <v-app-bar app dense>
        <v-img max-height="40" contain :src="logoSource"></v-img>

        <v-spacer></v-spacer>

        <v-toolbar-items cols="8" xs="6">
            <!-- Measurement Unit -->
            <v-col cols="auto">
                <v-icon left v-if="!$vuetify.breakpoint.xsOnly">{{
                    mdiTapeMeasure
                }}</v-icon>
                <span>{{ selectedUnit === 'mm' ? 'MM' : 'IN' }}</span>
            </v-col>
            <v-divider vertical inset></v-divider>

            <!-- Save Project -->
            <v-col v-if="isStandardDomain" align-self="center" cols="auto">
                <v-row>
                    <v-icon left right>{{ mdiCloudUploadOutline }}</v-icon>
                    <v-checkbox
                        @click="saveProject"
                        hide-details
                        v-model="isSceneSavedComp"
                        :disabled="isSceneSaved"
                    ></v-checkbox>
                </v-row>
            </v-col>

            <!-- Quote -->
            <v-col v-else align-self="center" cols="auto">
                <v-row>
                    <v-btn
                        v-if="!isStandardDomain"
                        color="green"
                        class="white--text mb-1 mx-2"
                        :small="$vuetify.breakpoint.mdAndDown"
                        @click="
                            $route.params.projectId
                                ? toggleUpdateDialog(true)
                                : toggleDialog(true)
                        "
                    >
                        {{ $route.params.projectId ? 'Save Project' : 'Quote' }}
                        <v-icon v-if="!$route.params.projectId">
                            {{ mdiCurrencyUsd }}
                        </v-icon>
                    </v-btn>
                </v-row>
            </v-col>

            <v-col v-if="isStandardDomain" cols="auto" align-self="center" class="pa-1">
                <v-btn small color="primary" :href="redirectDashboard"
                    >Dashboard</v-btn
                >
            </v-col>
        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { mdiTapeMeasure, mdiCloudUploadOutline, mdiCurrencyUsd } from '@mdi/js';

export default {
    data() {
        return {
            mdiTapeMeasure,
            mdiCloudUploadOutline,
            mdiCurrencyUsd,
        };
    },
    computed: {
        ...mapGetters('core', ['selectedUnit', 'isSceneSaved']),
        ...mapState('business', ['info', 'isStandardDomain']),
        isSceneSavedComp: {
            set(value) {
                this.toggleSceneSaved(value);
            },
            get() {
                return this.isSceneSaved;
            },
        },
        logoSource() {
            if (!this.isStandardDomain || this.info?.business?.user?.picture) {
                return this.info?.business?.user.picture;
            } else {
                return require('@/assets/kw_logo.png');
            }
        },
        redirectDashboard() {
            return process.env.VUE_APP_DASHBOARD_DOMAIN;
        },
    },
    methods: {
        ...mapMutations('core', ['toggleSceneSaved']),
        ...mapMutations('business', ['toggleDialog', 'toggleUpdateDialog']),
        ...mapActions('core', ['saveProjectAction']),
        saveProject() {
            this.saveProjectAction({
                apollo: this.$apollo,
                projectId: this.$route.params.projectId,
            });
        },
    },
};
</script>
