import { StandardMaterial, CSG, Color3, HighlightLayer, Color4 } from '@babylonjs/core';
import { Vector3 } from 'babylonjs';
import { Component } from './Base/Component';
export class MeshComponent extends Component {
    mesh;
    name;
    highlightLayer;

    constructor() {
        super();
        this.isHighlighted = false;
    }

    getMesh() {
        return this.mesh;
    }

    setMesh(mesh) {
        this.mesh?.dispose();
        this.mesh = mesh;
    }

    setName(name) {
        this.mesh.name = name;
        this.name = name;
    }

    setPosition(position) {
        this.mesh.position = position;
    }

    getPosition() {
        return this.mesh.position;
    }

    setMaterial() {
        this.mesh.material = new StandardMaterial('material2', this.object.editor.sceneComponent.get());
    }

    getCSGFromMesh() {
        return CSG.FromMesh(this.mesh);
    }

    unregister() {
        this.mesh.dispose();
    }

    getChildByName(name) {
        const children = this.mesh.getChildren();
        for (let index = 0; index < children.length; index++) {
            if (children[index].name === name) {
                return children[index];
            }
        }
    }

    setType(type) {
        this.getMesh().type = type;
    }

    setColor(color) {
        this.setMaterial();
        this.getMesh().material.diffuseColor = color;
    }

    dispose() {
        this.mesh?.dispose();
    }

    setParent(parent) {
        this.mesh.setParent(parent);
    }

    enableEdges(enable) {
        if (enable) {
            this.mesh.enableEdgesRendering();
            this.mesh.edgesWidth = 2.0;
            this.mesh.edgesColor = new Color4(0, 0, 0, 1);
        } else {
            this.mesh.disableEdgesRendering();
        }
    }

    getWorldPosition() {
        return this.mesh.getBoundingInfo().boundingBox.centerWorld.clone();
    }

    setRotation(rotation) {
        this.mesh.rotation.y = rotation;
    }
}
