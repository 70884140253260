import store from '@/store/index.js';
import { Vector3 } from 'babylonjs';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { BaseKitchenObject } from '../BaseKitchenObject';
import { Island } from '../Cabinets/Island';
import { BaseCabinet } from '../Models3D/BaseUnit/BaseNormalSection/BaseCabinet';
import { MeasurementLine } from './SectionComponents/MeasurementLine';

export class Section extends BaseKitchenObject {
    parentCabinet;
    customization = {};
    constructor() {
        super();
        this.measurementLine = this.registerComponent(MeasurementLine);
        this.type = 'section';
    }

    build(parentCabinet, width, position = null, rowDepth = null) {
        this.id = this.editor.lastSectionId;
        this.editor.lastSectionId++;
        this.setParent(parentCabinet);
        this.setWidth(width, rowDepth);
        this.buildMesh();
        if (position) {
            this.setPosition(position);
            this.positionMesh();
            if (this.parentCabinet.type === Constants.cabinet.type.WALL) {
                this.meshComponent.getMesh().position.y += this.parentCabinet.heightFromFloor;
                this.position = this.meshComponent.getMesh().position;
            }
        }
        this.meshComponent.getMesh().rotation = this.parentCabinet.meshComponent.getMesh().rotation;
        this.assignedWall = this.parentCabinet.assignedWall;
        if (!(parentCabinet instanceof Island)) {
            this.shift(-this.depth / 2);
        }
        this.measurementLine.build();
        this.meshComponent.getMesh().visibility = 0.001;
    }

    setParent(parent) {
        this.parentCabinet = parent;
    }

    setWidth(width, rowDepth) {
        if (!rowDepth) {
            rowDepth = this.parentCabinet.depth;
        }
        this.setDimensions({
            width: width,
            height: this.parentCabinet.height,
            depth: rowDepth,
        });
    }

    showBoundingBox(enable) {
        this.buildingBlocks.doorBlock.meshComponent.mesh.showBoundingBox = enable;
        if (this.buildingBlocks.innerStructure) {
            this.buildingBlocks.innerStructure.meshComponent.mesh.showBoundingBox = enable;
        }
    }

    dispose() {
        this.meshComponent.getMesh().dispose();
        this.measurementLine.dispose();
        this.buildingBlocks.doorBlock?.dispose();
    }

    async resizeInPlace(width, direction, resizeAdjacent = true) {
        if (this.editor.unit === 'in' && resizeAdjacent) {
            width *= Constants.unit.INCH_STEP;
        }
        if (this.type === 'fillerSection') {
            store.commit('core/setPopupMsg', 'Failed: You can not resize fillers');
            return;
        }
        let changeInWidth = this.width - width;

        width = Utilities.roundToPrecision(width, 1000);
        if (!this.canResize(changeInWidth, direction)) {
            return;
        }
        this.width = width;
        this.dimensions.width = width;
        if (this.editor.unit === 'in' && resizeAdjacent) {
            this.dimensions.width /= Constants.unit.INCH_STEP / 1000;
        }
        this.buildingBlocks.doorBlock.width = Utilities.roundToPrecision(width * 1000, 1000);
        this.buildingBlocks.doorBlock.dimensions.width = Utilities.roundToPrecision(width * 1000, 1000);
        if (this.editor.unit === 'in' && resizeAdjacent) {
            this.buildingBlocks.doorBlock.dimensions.width /= Constants.unit.INCH_STEP;
            this.buildingBlocks.doorBlock.dimensions.width = Utilities.roundToPrecision(
                this.buildingBlocks.doorBlock.dimensions.width,
                100
            );
        }

        if (width === 0) {
            this.dispose();
            const index = this.findIndex();
            if (this.parentCabinet.type === Constants.cabinet.type.ISLAND) {
                this.parentCabinet.sections[this.assignedRow].splice(index, 1);
            } else {
                this.parentCabinet.sections.splice(index, 1);
            }
            return;
        }

        const rotation = this.meshComponent.getMesh().rotation.y;
        this.buildingBlocks.doorBlock.meshComponent.getMesh().dispose();

        const newSection = await this.getResizedModel(width);

        const previousPosition = this.meshComponent.getWorldPosition();
        newSection.position = previousPosition;
        newSection.rotation.y = rotation;

        this.meshComponent.getMesh().dispose();
        this.meshComponent.setMesh(newSection);
        this.meshComponent.getMesh().computeWorldMatrix();
        this.measurementLine.update();

        if (resizeAdjacent) {
            this.resizeAdjacentCabinets(changeInWidth, direction);
        }

        store.commit('generated/setSelectedObject', []);
        this.editor.sceneComponent.save3D();
    }

    canResize(changeInWidth, direction) {
        changeInWidth = Utilities.roundToPrecision(changeInWidth, 1000);
        const adjacentSections = this.getAdjacentSections();
        if (direction === 'split') {
            if (changeInWidth < 0) {
                if (!adjacentSections.left) {
                    store.commit('core/setPopupMsg', 'Failed: Left cabinet missing');
                    return false;
                }

                if (!adjacentSections.right) {
                    store.commit('core/setPopupMsg', 'Failed: Left cabinet missing');
                    return false;
                }
                if (
                    adjacentSections.left.appliance &&
                    adjacentSections.left.appliance.type !== 'cooktop' &&
                    adjacentSections.right.appliance &&
                    adjacentSections.right.appliance.type !== 'cooktop'
                ) {
                    store.commit('core/setPopupMsg', 'Failed: Side cabinets have appliances');
                    return false;
                } else if (adjacentSections.left.appliance && adjacentSections.left.appliance.type !== 'cooktop') {
                    store.commit('core/setPopupMsg', 'Failed: There is an appliance on the left');
                    return false;
                } else if (adjacentSections.right.appliance && adjacentSections.right.appliance.type !== 'cooktop') {
                    store.commit('core/setPopupMsg', 'Failed: There is an appliance on the right');
                    return false;
                }

                if (adjacentSections.left.type === 'cornerSection') {
                    store.commit('core/setPopupMsg', 'Failed: There is a corner cabinet on the left');
                    return false;
                } else if (adjacentSections.right.type === 'cornerSection') {
                    store.commit('core/setPopupMsg', 'Failed: There is a corner cabinet on the right');
                    return false;
                }

                if (adjacentSections.left.type === 'fillerSection') {
                    store.commit('core/setPopupMsg', 'Failed: There is a filler on the left');
                    return false;
                } else if (adjacentSections.right.type === 'fillerSection') {
                    store.commit('core/setPopupMsg', 'Failed: There is a filler on the right');
                    return false;
                }

                if (
                    Math.abs(changeInWidth / 2) > adjacentSections.right.width ||
                    Math.abs(changeInWidth / 2) > adjacentSections.left.width
                ) {
                    store.commit('core/setPopupMsg', 'Failed: The side cabinets are too small');
                    return false;
                }

                if (Math.abs(changeInWidth / 2) > adjacentSections.left.width) {
                    store.commit('core/setPopupMsg', 'Failed: The right cabinet is too small');
                    return false;
                }
            } else {
                if (Math.abs(changeInWidth / 2) < Constants.availableCabinetDimensions[this.editor.unit][0]) {
                    store.commit('core/setPopupMsg', 'Failed: The side cabinets would be too small');
                    return false;
                }
            }
        } else if (direction === 'right') {
            if (changeInWidth < 0) {
                if (!adjacentSections.right) {
                    store.commit('core/setPopupMsg', 'Failed: Right cabinet missing');
                    return false;
                }

                if (adjacentSections.right.appliance && adjacentSections.right.appliance.type !== 'cooktop') {
                    store.commit('core/setPopupMsg', 'Failed: There is an appliance on the right');
                    return false;
                }

                if (Math.abs(changeInWidth) > adjacentSections.right.width) {
                    store.commit('core/setPopupMsg', 'Failed: The cabinet on the right is too small');
                    return false;
                }
                if (adjacentSections.right.type === 'cornerSection') {
                    store.commit('core/setPopupMsg', 'Failed: There is a corner cabinet on the right');
                    return false;
                }

                if (adjacentSections.right.type === 'fillerSection') {
                    store.commit('core/setPopupMsg', 'Failed: There is a filler on the right');
                    return false;
                }
            }
        } else if (direction === 'left') {
            if (changeInWidth < 0) {
                if (!adjacentSections.left) {
                    store.commit('core/setPopupMsg', 'Failed: Left cabinet missing');
                    return false;
                }
                if (adjacentSections.left.appliance && adjacentSections.left.appliance.type !== 'cooktop') {
                    store.commit('core/setPopupMsg', 'Failed: There is an appliance on the left');
                    return false;
                }

                if (Math.abs(changeInWidth) > adjacentSections.left.width) {
                    store.commit('core/setPopupMsg', 'Failed: The cabinet on the left is too small');
                    return false;
                }
                if (adjacentSections.left.type === 'cornerSection') {
                    store.commit('core/setPopupMsg', 'Failed: There is a corner cabinet on the left');
                    return false;
                }

                if (adjacentSections.left.type === 'fillerSection') {
                    store.commit('core/setPopupMsg', 'Failed: There is a filler on the left');
                    return false;
                }
            }
        }
        return true;
    }

    resizeAdjacentCabinets(changeInWidth, direction) {
        if (direction === 'split') {
            this.resizeAdjacentCabinetsSplit(changeInWidth);
        } else if (direction === 'left') {
            this.resizeAdjacentCabinetsLeft(changeInWidth);
        } else if (direction === 'right') {
            this.resizeAdjacentCabinetsRight(changeInWidth);
        }
    }

    async resizeAdjacentCabinetsSplit(changeInWidth) {
        const sides = this.getLeftAndRightDirections();
        const directionLeft = sides.left;
        const directionRight = sides.right;

        const adjacentSections = this.getAdjacentSections();
        const position = this.meshComponent.getWorldPosition();
        const rotation = this.meshComponent.getMesh().rotation.y;

        if (changeInWidth < 0) {
            let doorType = '1door';
            if (this.width > 0.6) {
                doorType = '2door';
            }

            this.buildingBlocks.doorBlock.changeDoorType(doorType);
            if (adjacentSections.left && adjacentSections.right) {
                const positionLeft = adjacentSections.left.meshComponent.getWorldPosition();
                const positionRight = adjacentSections.right.meshComponent.getWorldPosition();

                //selected cabinet's width is increased, adjacent cabinets are resized down to fit
                await adjacentSections.left.resizeInPlace(adjacentSections.left.width + changeInWidth / 2, false, false);
                await adjacentSections.right.resizeInPlace(adjacentSections.right.width + changeInWidth / 2, false, false);
                adjacentSections.left.meshComponent.getMesh().position = positionLeft
                    .clone()
                    .add(directionLeft.scale(-changeInWidth / 4));

                adjacentSections.right.meshComponent.getMesh().position = positionRight.add(
                    directionRight.scale(-changeInWidth / 4)
                );
                if (adjacentSections.left.width !== 0) {
                    adjacentSections.left.measurementLine.update();
                }
                if (adjacentSections.right.width !== 0) {
                    adjacentSections.right.measurementLine.update();
                }
            }
        } else {
            //selected cabinet's width is decreased, we add new adjacent cabinets if it has enough room
            const selectedCabinetIndex = this.findIndex();
            const replacementDirection = this.parentCabinet.replacementData.direction;

            let newLeftCabientId;
            let newRightCabientId;

            if (Utilities.haveSameDirection(replacementDirection, directionRight)) {
                newLeftCabientId = selectedCabinetIndex;
                newRightCabientId = selectedCabinetIndex + 2;
            } else {
                newLeftCabientId = selectedCabinetIndex + 1;
                newRightCabientId = selectedCabinetIndex;
            }

            let doorType = '1door';
            if (this.width > 0.6) {
                doorType = '2door';
            }
            this.buildingBlocks.doorBlock.changeDoorType(doorType);

            const adjacentLeft = await this.editor.insertSection(
                changeInWidth / 2,
                this.parentCabinet,
                newLeftCabientId,
                this.assignedRow
            );
            if (adjacentLeft) {
                adjacentLeft.meshComponent.setRotation(rotation);
                adjacentLeft.meshComponent.getMesh().position = position
                    .clone()
                    .add(directionLeft.scale(this.width / 2 + changeInWidth / 4));
                adjacentLeft.measurementLine.update();
                let doorType = '1door';
                if (adjacentLeft.width > 0.6) {
                    doorType = '2door';
                }
                adjacentLeft.buildingBlocks.doorBlock.changeDoorType(doorType);
            }

            const adjacentRight = await this.editor.insertSection(
                changeInWidth / 2,
                this.parentCabinet,
                newRightCabientId,
                this.assignedRow
            );
            if (adjacentRight) {
                adjacentRight.meshComponent.setRotation(rotation);
                adjacentRight.meshComponent.getMesh().position = position
                    .clone()
                    .add(directionRight.scale(this.width / 2 + changeInWidth / 4));
                adjacentRight.measurementLine.update();
                let doorType = '1door';
                if (adjacentRight.width > 0.6) {
                    doorType = '2door';
                }
                adjacentRight.buildingBlocks.doorBlock.changeDoorType(doorType);
            }
        }
    }

    async resizeAdjacentCabinetsRight(changeInWidth) {
        const sides = this.getLeftAndRightDirections();
        const directionRight = sides.right;

        const adjacentSections = this.getAdjacentSections();
        const position = this.meshComponent.getWorldPosition();
        const rotation = this.meshComponent.getMesh().rotation.y;

        if (changeInWidth < 0) {
            if (adjacentSections.right) {
                const positionRight = adjacentSections.right.meshComponent.getWorldPosition();
                this.meshComponent.getMesh().position = position.clone().add(directionRight.scale(-changeInWidth / 2));

                let doorType = '1door';
                if (this.width > 0.6) {
                    doorType = '2door';
                }
                this.buildingBlocks.doorBlock.changeDoorType(doorType);

                await adjacentSections.right.resizeInPlace(adjacentSections.right.width + changeInWidth, false, false);
                adjacentSections.right.meshComponent.getMesh().position = positionRight
                    .clone()
                    .add(directionRight.scale(-changeInWidth / 2));

                this.measurementLine.update();

                if (adjacentSections.right.width !== 0) {
                    adjacentSections.right.measurementLine.update();
                }
            }
        } else {
            const selectedCabinetIndex = this.findIndex();
            let newRightCabientId;
            const replacementDirection = this.parentCabinet.replacementData.direction;

            if (Utilities.haveSameDirection(replacementDirection, directionRight)) {
                newRightCabientId = selectedCabinetIndex;
            } else {
                newRightCabientId = selectedCabinetIndex + 1;
            }
            this.meshComponent.getMesh().position = position.clone().add(directionRight.scale(changeInWidth / 2));
            let doorType = '1door';
            if (this.width > 0.6) {
                doorType = '2door';
            }
            this.buildingBlocks.doorBlock.changeDoorType(doorType);
            this.measurementLine.update();
            const adjacentRight = await this.editor.insertSection(
                changeInWidth,
                this.parentCabinet,
                newRightCabientId,
                this.assignedRow
            );
            if (adjacentRight) {
                adjacentRight.meshComponent.setRotation(rotation);
                adjacentRight.meshComponent.getMesh().position = position.clone().add(directionRight.scale(-this.width / 2));
                if (adjacentRight.width !== 0) {
                    adjacentRight.measurementLine.update();
                }
                let doorType = '1door';
                if (adjacentRight.width > 0.6) {
                    doorType = '2door';
                }
                adjacentRight.buildingBlocks.doorBlock.changeDoorType(doorType);
            }
        }
    }

    async resizeAdjacentCabinetsLeft(changeInWidth) {
        const sides = this.getLeftAndRightDirections();
        const directionLeft = sides.left;
        const adjacentSections = this.getAdjacentSections();
        const position = this.meshComponent.getWorldPosition();
        const rotation = this.meshComponent.getMesh().rotation.y;

        if (changeInWidth < 0) {
            if (adjacentSections.left) {
                const positionLeft = adjacentSections.left.meshComponent.getWorldPosition();
                this.meshComponent.getMesh().position = position.clone().add(directionLeft.scale(-changeInWidth / 2));
                let doorType = '1door';
                if (this.width > 0.6) {
                    doorType = '2door';
                }
                this.buildingBlocks.doorBlock.changeDoorType(doorType);
                await adjacentSections.left.resizeInPlace(adjacentSections.left.width + changeInWidth, false, false);
                this.measurementLine.update();
                adjacentSections.left.meshComponent.getMesh().position = positionLeft
                    .clone()
                    .add(directionLeft.scale(-changeInWidth / 2));

                if (adjacentSections.left.width !== 0) {
                    adjacentSections.left.measurementLine.update();
                }
            }
        } else {
            let newLeftCabientId;
            const replacementDirection = this.parentCabinet.replacementData.direction;
            const selectedCabinetIndex = this.findIndex();

            if (Utilities.haveSameDirection(replacementDirection, directionLeft)) {
                newLeftCabientId = selectedCabinetIndex;
            } else {
                newLeftCabientId = selectedCabinetIndex + 1;
            }
            this.meshComponent.getMesh().position = position.clone().add(directionLeft.scale(changeInWidth / 2));
            let doorType = '1door';
            if (this.width > 0.6) {
                doorType = '2door';
            }
            this.buildingBlocks.doorBlock.changeDoorType(doorType);
            this.measurementLine.update();
            const adjacentLeft = await this.editor.insertSection(
                changeInWidth,
                this.parentCabinet,
                newLeftCabientId,
                this.assignedRow
            );
            if (adjacentLeft) {
                adjacentLeft.meshComponent.setRotation(rotation);
                adjacentLeft.meshComponent.getMesh().position = position.clone().add(directionLeft.scale(-this.width / 2));
                if (changeInWidth !== 0) {
                    adjacentLeft.measurementLine.update();
                }

                let doorType = '1door';
                if (adjacentLeft.width > 0.6) {
                    doorType = '2door';
                }
                adjacentLeft.buildingBlocks.doorBlock.changeDoorType(doorType);
            }
        }
    }

    async getResizedModel(width) {
        let newSection;
        let result;
        if (['base', 'island'].includes(this.parentCabinet.type)) {
            let model = this.editor.models3D.baseCabinetSection;
            if (this.parentCabinet.type === 'island') {
                model = this.editor.addObject(BaseCabinet);
                await model.setModel();
            }
            model.resizingManager.parts = model.parts;
            model.parentCabinet = this.parentCabinet;
            model.resizingManager.resizeWidth({ width });
            if (this.parentCabinet.type === 'island') {
                model.resizingManager.resizeDepth(this.parentCabinet.rowDepths[this.assignedRow]);
            }
            model.currentParentSection = this;

            result = model.mergeModel();
            result.buildingBlocks.countertopBlock.meshComponent.getMesh().setEnabled(false);
            result.buildingBlocks.countertopBlock.meshComponent.getMesh().isPickable = false;
            result.buildingBlocks.skirtingBlock.meshComponent.getMesh().setEnabled(false);
            result.buildingBlocks.skirtingBlock.meshComponent.getMesh().isPickable = false;
            newSection = result.mesh;
        } else if (this.parentCabinet.type === 'wall') {
            this.editor.models3D.wallCabinet.sectionInfo.isFiller = null;
            this.editor.models3D.wallCabinet.sectionInfo.appliance = null;

            this.editor.models3D.wallCabinet.resizeWidth({ width });
            this.editor.models3D.wallCabinet.currentParentSection = this;
            result = this.editor.models3D.wallCabinet.mergeModel();
            newSection = result.mesh;
        } else if (this.parentCabinet.type === 'tall') {
            this.editor.models3D.tallCabinet.resizeWidth({ width });
            this.editor.models3D.tallCabinet.currentParentSection = this;
            result = this.editor.models3D.tallCabinet.mergeModel();
            result.buildingBlocks.skirtingBlock.meshComponent.getMesh().setEnabled(false);
            result.buildingBlocks.skirtingBlock.meshComponent.getMesh().isPickable = false;
            newSection = result.mesh;
        }
        this.buildingBlocks = result.buildingBlocks;
        return newSection;
    }

    getAdjacentSections() {
        let sections = this.parentCabinet.sections;
        let sides = this.getLeftAndRightDirections();

        if (this.parentCabinet.type === Constants.cabinet.type.ISLAND) {
            sections = this.parentCabinet.sections[this.assignedRow];
            sides = this.getLeftAndRightDirectionsIsland();
        }
        const position = this.meshComponent.getWorldPosition();

        for (let index = 0; index < sections.length; index++) {
            if (sections[index].id === this.id) {
                let adjacentSections = {};
                if (sections[index - 1]) {
                    const previousPosition = sections[index - 1].meshComponent.getWorldPosition();
                    const previousDirection = previousPosition.clone().subtract(position.clone());
                    previousDirection.normalize();
                    if (Utilities.haveSameDirection(previousDirection, sides.left)) {
                        adjacentSections.left = sections[index - 1];
                    } else {
                        adjacentSections.right = sections[index - 1];
                    }
                }

                if (sections[index + 1]) {
                    const nextPosition = sections[index + 1].meshComponent.getWorldPosition();
                    const nextDirection = nextPosition.clone().subtract(position.clone());
                    nextDirection.normalize();

                    if (Utilities.haveSameDirection(nextDirection, sides.left)) {
                        adjacentSections.left = sections[index + 1];
                    } else {
                        adjacentSections.right = sections[index + 1];
                    }
                }
                return adjacentSections;
            }
        }
    }

    findIndex() {
        if (this.parentCabinet.type === 'island') {
            for (let index = 0; index < this.parentCabinet.sections[this.assignedRow].length; index++) {
                if (this.parentCabinet.sections[this.assignedRow][index].id === this.id) {
                    return index;
                }
            }
        }
        for (let index = 0; index < this.parentCabinet.sections.length; index++) {
            if (this.parentCabinet.sections[index].id === this.id) {
                return index;
            }
        }
    }

    getLeftAndRightDirections() {
        if (this.parentCabinet.type === 'island') {
            return this.getLeftAndRightDirectionsIsland();
        }
        let position = this.meshComponent.getWorldPosition();
        position.y = 0;
        position = Utilities.projectPointOnWall(position, this.parentCabinet.assignedWall);

        const edgesOnWall = this.getEdgesOnWall();
        const right = edgesOnWall.firstPoint.clone().subtract(position.clone()).normalize();
        const left = edgesOnWall.secondPoint.clone().subtract(position.clone()).normalize();

        return { right, left };
    }

    getLeftAndRightDirectionsIsland() {
        const rotation = this.meshComponent.getMesh().rotation.y;
        let right, left;
        if (rotation === 0 || rotation === Math.PI) {
            if (this.assignedRow === 'secondRow') {
                right = new Vector3(-1, 0, 0);
                left = new Vector3(1, 0, 0);
            } else {
                right = new Vector3(1, 0, 0);
                left = new Vector3(-1, 0, 0);
            }
        } else if (rotation === Math.PI / 2 || rotation === (-3 * Math.PI) / 2) {
            if (this.assignedRow === 'secondRow') {
                right = new Vector3(0, 0, 1);
                left = new Vector3(0, 0, -1);
            } else {
                left = new Vector3(0, 0, -1);
                right = new Vector3(0, 0, 1);
            }
        }
        return { right, left };
    }

    getInformation() {
        const sectionsInformation = this.parentCabinet.sectionsInformation;
        const index = this.parentCabinet.type === 'island' ? store.state.generated.islandGeneratedIDX : store.state.generated.generatedIDX;

        if (this.parentCabinet.type !== 'island' && !sectionsInformation?.length) {
            return;
        }
        
        return {
            index,
            sectionsInformation,
        };
    }
}
