import { Vector3 } from '@babylonjs/core';
import { BaseAppliance } from '../../KitchenObjects/Appliances/BaseAppliance';
import { SingleCabinet } from '../../KitchenObjects/CabinetSections/SingleCabinet';
import { BaseCabinet } from '../../KitchenObjects/Models3D/BaseUnit/BaseNormalSection/BaseCabinet';
import { PeninsulaCornerSection } from '../../KitchenObjects/Models3D/BaseUnit/BaseCornerSection/PeninsulaCornerSection';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { SectionCalculator } from './SectionCalculator';
import store from '@/store/index.js';

export class IslandReplacer {
    availableCabinetDimensions;
    islands;
    constructor(editor) {
        this.editor = editor;
        this.filledWidth = 0;
        this.sectionCalculator = new SectionCalculator(editor);
    }

    async replaceAllIslands() {
        const cabinets = this.editor.cabinets;
        for (let index = 0; index < cabinets.length; index++) {
            if (cabinets[index].type === Constants.cabinet.type.ISLAND) {
                await this.section(cabinets[index]);
            }
        }
    }

    async section(island) {
        island.setInnerPeninsulaRow();
        let firstRowEdges = island.getEdges('firstRow');
        let secondRowEdges = island.getEdges('secondRow');

        if (island.rowDepths.firstRow !== 0) {
            island.filledWidth = 0;
            this.filledWidth = 0;
            await this.sectionRow(island, firstRowEdges, 'firstRow');
        }
        island.filledWidth = 0;
        if (island.rowDepths.secondRow !== 0) {
            this.filledWidth = 0;
            island.filledWidth = 0;
            await this.sectionRow(island, secondRowEdges, 'secondRow');
        }

        island.mergeCountertop();
        island.mergeSkirting();
    }

    async sectionRow(island, edges, row) {
        island.filledWidth = 0;
        this.sectionCalculator.currentCabinet = island;
        let widthToFill = island.width;
        const allowedSegment = island.getEdges(row);
        const replacementDirectionInfo = this.defineReplacementDirection(island, edges);
        let startingPoint = replacementDirectionInfo.startingPoint;
        let replacementDirection = replacementDirectionInfo.replacementDirection;
        let result = [];

        if (island.peninsulaData.isPeninsula) {
            if (island.peninsulaData.innerRow === row) {
                widthToFill -= Constants.baseCornerCabinet.DOOR_SIZE[this.editor.unit];
                result = this.setCornerCabinetSectionInfo(island);
            } else {
                //This section width should be changed to adapt dinamically to the depth of the base cabinet
                result = [{ sectionWidth: 0.04, numberOfSections: 1, sectionData: { isFiller: true } }];
                widthToFill += island.peninsulaData.connectedCabinet.depth - 0.04;
            }
        }

        const appliancesOnIsland = this.sortAppliancesInReplacementOrder(island, startingPoint, row);
        if (appliancesOnIsland.length === 0) {
            result = result.concat(this.sectionCalculator.replaceEmptyCabinetSections(widthToFill));
        } else {
            let endOfCornerCabinetIfAny = startingPoint.clone();
            if (island.cornerCabinetInfo) {
                endOfCornerCabinetIfAny = startingPoint.clone().add(replacementDirection.scale(result[0].sectionWidth));
                let closestEdgeToWall = Utilities.findClosestPointToWall(
                    allowedSegment.leftPoint,
                    allowedSegment.rightPoint,
                    island.peninsulaData.connectedCabinet.assignedWall
                );
                allowedSegment[closestEdgeToWall] = endOfCornerCabinetIfAny.clone();
            }
            result = result.concat(
                this.calculateSectionsWithAppliances(
                    { cabinet: island, startingPoint: endOfCornerCabinetIfAny, replacementDirection, allowedSegment },
                    appliancesOnIsland
                )
            );
        }
        const cabinets = JSON.parse(JSON.stringify(store.state.core.projectData.cabinets));
        for (const cabinet of cabinets) {
            if (cabinet.type === 'island' && island.id === cabinet.id && cabinet?.sectionsInformation?.[row]?.length) {
                result = JSON.parse(JSON.stringify(cabinet.sectionsInformation[row]));
                for (let index = 0; index < result.length; index++) {
                    if (typeof result[index].sectionData === 'number') {
                        const foundAppliance = this.editor.getApplianceById(result[index].sectionData);
                        result[index].sectionData = foundAppliance;
                    }
                }
            }
            if (cabinet.type === 'island' && cabinet.countertopCustomization && island.id === cabinet.id) {
                island.countertopCustomization = cabinet.countertopCustomization;
            }
        }
        await this.placeSections([result, island, row, replacementDirection, startingPoint]);
        island.meshComponent.getMesh().dispose();
    }

    async placeSections(sectionPlacementInfo) {
        let [result, island, row, replacementDirection, startingPoint] = sectionPlacementInfo;
        let positionIndex = 0;
        for (let idx = 0; idx < result.length; idx++) {
            // sent idx to vuex
            store.commit('generated/setIslandGeneratedIDX', idx);

            let resultForType = result[idx];
            if (resultForType.numberOfSections === 0) {
                continue;
            }

            let [currentModel, resizingDimensions] = await this.chooseModelTypeAndResizingDimensions(island, resultForType, row);
            const cabinetSectionRotation = this.calculateCabinetSectionRotation(island, row);

            for (let index = 0; index < resultForType.numberOfSections; index++) {
                const sectionSpecifications = {
                    index: null,
                    resultForType,
                    replacementDirection,
                    row,
                };
                if (idx === 0 && index === 0) {
                    sectionSpecifications.index = 'first';
                } else if (index === resultForType.numberOfSections - 1 && idx === result.length - 1) {
                    sectionSpecifications.index = 'last';
                }

                island[row].filledWidth += resultForType.sectionWidth;
                let position = startingPoint.add(replacementDirection.scale(resultForType.sectionWidth / 2));
                let islandCabinetSectionClone = null;
                position.y = island.height / 2;

                const section = this.createSection(island, resultForType, position.clone(), row);
                section.positionIndex = positionIndex;

                currentModel.setCurrentParentSection(section);
                currentModel.resize(resizingDimensions);

                if (!resultForType.sectionData?.isCornerCabinet) {
                    currentModel.chosePartsForIslandSingleRow();
                }

                if (resultForType.sectionData?.isCornerCabinet) {
                    islandCabinetSectionClone = currentModel.mergeModel(resultForType?.sectionData);
                    currentModel.rotateToFitIslandOrientation(
                        resultForType.sectionData.side,
                        resultForType.sectionData.type,
                        islandCabinetSectionClone.mesh
                    );
                } else if (resultForType.sectionData?.isFiller) {
                    islandCabinetSectionClone = currentModel.mergeModel();
                } else {
                    islandCabinetSectionClone = currentModel.mergeModel(sectionSpecifications);
                }

                islandCabinetSectionClone.mesh.visibility = 0;
                islandCabinetSectionClone.mesh.position = position;
                islandCabinetSectionClone.mesh.rotation.y = cabinetSectionRotation;
                startingPoint = startingPoint.add(replacementDirection.scale(resultForType.sectionWidth));
                section.meshComponent.setMesh(islandCabinetSectionClone.mesh);
                section.buildingBlocks = islandCabinetSectionClone.buildingBlocks;

                const sectionInformation = section.getInformation();

                if (!resultForType.sectionData?.isCornerCabinet && !resultForType.isFiller) {
                    if (
                        sectionInformation &&
                        island.sectionsInformation?.[row]?.[sectionInformation.index]?.customization?.cabinets?.doorType
                    ) {
                        section.buildingBlocks.doorBlock.changeDoorType(
                            island.sectionsInformation[row][sectionInformation.index].customization.cabinets.doorType
                        );
                    }
                }
                positionIndex = positionIndex + 1;
            }
        }
        island.addEndCabinet(row, startingPoint);
    }

    async chooseModelTypeAndResizingDimensions(island, resultForType, row) {
        let parentCabinetRotation = island.meshComponent.getMesh().rotation.y;
        let currentModel;
        let resizingDimensions = {
            width: resultForType.sectionWidth,
            depth: island.rowDepths[row],
            height: island.height,
        };
        if (resultForType.sectionData?.isCornerCabinet) {
            //WHEN SECTION IS A CORNER CABINET
            currentModel = this.editor.addObject(PeninsulaCornerSection);
            await currentModel.setModel();
            resizingDimensions = {
                width:
                    island.peninsulaData.connectedCabinet.depth +
                    Constants.baseCornerCabinet.DOOR_SIZE[this.editor.unit] +
                    Constants.STANDARD_FILLER_SIZE[this.editor.unit],
                depth: island.rowDepths[island.peninsulaData.innerRow],
                height: island.height,
                doorWidth: Constants.baseCornerCabinet.DOOR_SIZE[this.editor.unit],
                connectedCabinetDepth: island.peninsulaData.connectedCabinet.depth,
            };
        } else if (resultForType.isFiller && island.type !== Constants.cabinet.type.WALL) {
            //WHEN SECTION IS A FILLER
            currentModel = this.editor.models3D.baseFillerSection;
        } else {
            //NORMAL SECTION
            currentModel = this.editor.addObject(BaseCabinet);
            await currentModel.setModel();
        }
        currentModel.setSectionInfo(resultForType);
        currentModel.setParentCabinet(island);

        return [currentModel, resizingDimensions, parentCabinetRotation];
    }

    defineReplacementDirection(island, edges) {
        let startingPoint = edges.leftPoint.clone();
        let replacementDirection = Vector3.Right();

        if (island.rotation === 90 || island.rotation === 270) {
            replacementDirection = new Vector3(0, 0, -1);
            if (island.peninsulaData.side === 'bottom') {
                replacementDirection = new Vector3(0, 0, 1);
                startingPoint = edges.rightPoint.clone();
            }
            replacementDirection.normalize();
        } else if (island.peninsulaData.side === 'top' || island.peninsulaData.side === 'left') {
            replacementDirection.negateInPlace();
            startingPoint = edges.rightPoint.clone();
        }
        if (island.cornerCabinetInfo) {
            startingPoint = Utilities.projectPointOnWall(startingPoint, island.peninsulaData.connectedCabinet.assignedWall);
        }
        startingPoint.y = 0;
        island.replacementData = { direction: replacementDirection, startingPoint };
        return { replacementDirection, startingPoint };
    }

    setCornerCabinetSectionInfo(island) {
        return [
            {
                sectionWidth:
                    island.peninsulaData.connectedCabinet.depth +
                    Constants.baseCornerCabinet.DOOR_SIZE[this.editor.unit] +
                    Constants.STANDARD_FILLER_SIZE[this.editor.unit],
                numberOfSections: 1,
                sectionData: {
                    isCornerCabinet: true,
                    fillerSize: Constants.STANDARD_FILLER_SIZE[this.editor.unit],
                    side: island.cornerCabinetInfo.side,
                    type: island.cornerCabinetInfo.type,
                },
            },
        ];
    }

    calculateCabinetSectionRotation(island, row) {
        if ((island.rotation === 0 || island.rotation === 180) && row === 'secondRow') {
            return Math.PI;
        } else if (island.rotation === 90 || island.rotation === 270) {
            if (row === 'firstRow') {
                return Math.PI / 2;
            } else if (row === 'secondRow') {
                return -Math.PI / 2;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    sortAppliancesInReplacementOrder(island, startingPoint, row) {
        const parentCabinet = island;
        let appliancesOnCabinet = parentCabinet.getAppliances();
        appliancesOnCabinet = appliancesOnCabinet.filter((appliance) => appliance.assignedRow === row);
        return appliancesOnCabinet.sort((a, b) => {
            return Vector3.Distance(a.position, startingPoint) > Vector3.Distance(b.position, startingPoint) ? 1 : -1;
        });
    }

    getResizingDimensions(resultForType, island, row) {
        let resizingDimensions = {
            width: resultForType.sectionWidth,
            depth: island.rowDepths[row],
            height: island.height,
        };

        if (resultForType.sectionData?.isCornerCabinet) {
            resizingDimensions = {
                width: 1.09,
                depth: island.rowDepths[island.peninsulaData.innerRow],
                height: island.height,
                doorWidth: Constants.baseCornerCabinet.DOOR_SIZE[this.editor.unit],
                connectedCabinetDepth: island.peninsulaData.connectedCabinet.depth,
            };
        }
        return resizingDimensions;
    }

    hasCooktopOver(appliancesOnCabinet, currentApplianceIndex) {
        let otherApplianceData = {
            appliance: null,
            type: null,
        };
        if (currentApplianceIndex + 1 < appliancesOnCabinet.length) {
            const otherAppliance = appliancesOnCabinet[currentApplianceIndex + 1];
            const otherApplianceBlockedSegment = otherAppliance.getBlockedSegmentOnIsland();
            const currentApplianceBlockedSegment = appliancesOnCabinet[currentApplianceIndex].getBlockedSegmentOnIsland();

            if (
                (otherAppliance.type === Constants.appliance.type.COOKTOP ||
                    appliancesOnCabinet[currentApplianceIndex].type === Constants.appliance.type.COOKTOP) &&
                Utilities.areSegmentsOverlapping(otherApplianceBlockedSegment, currentApplianceBlockedSegment)
            ) {
                otherApplianceData = {
                    appliance: appliancesOnCabinet[currentApplianceIndex + 1],
                    type: appliancesOnCabinet[currentApplianceIndex + 1].type,
                };
            }
        }

        return otherApplianceData;
    }

    calculateSectionsWithAppliances(data, appliancesOnIsland) {
        let lastAppliancePosition = data.startingPoint.clone();
        let applianceProjectedPosition = Utilities.getPointOnLine({
            cornerA: data.allowedSegment.leftPoint.clone(),
            cornerB: data.allowedSegment.rightPoint.clone(),
            point: appliancesOnIsland[0].meshComponent.getMesh().position.clone(),
        });
        applianceProjectedPosition.y = 0;
        let distanceFromLastAppliance =
            Vector3.Distance(lastAppliancePosition, applianceProjectedPosition) - appliancesOnIsland[0].width / 2;
        if (distanceFromLastAppliance < 0) {
            distanceFromLastAppliance = 0;
        }
        let numberOfSectionsPerType = this.sectionCalculator.replaceEmptyCabinetSections(distanceFromLastAppliance);

        for (let index = 0; index < appliancesOnIsland.length; index++) {
            let otherAppliance = this.hasCooktopOver(appliancesOnIsland, index);
            let currentAppliance = appliancesOnIsland[index];
            currentAppliance.meshComponent.getMesh().position = data.startingPoint.add(
                data.replacementDirection.scale(data.cabinet.filledWidth)
            );
            let cooktop = otherAppliance.appliance;
            if (appliancesOnIsland[index].type === Constants.appliance.type.COOKTOP && otherAppliance.appliance) {
                currentAppliance = otherAppliance.appliance;
                cooktop = appliancesOnIsland[index];
            }

            numberOfSectionsPerType.push({
                sectionWidth: currentAppliance.width,
                numberOfSections: 1,
                sectionData: currentAppliance,
                cooktop: cooktop,
            });

            if (otherAppliance.appliance) {
                index++;
            }

            this.filledWidth += currentAppliance.width;
            data.cabinet.filledWidth += currentAppliance.width;

            lastAppliancePosition = data.startingPoint.add(data.replacementDirection.clone().scale(data.cabinet.filledWidth));
            if (appliancesOnIsland[index + 1]) {
                applianceProjectedPosition = Utilities.getPointOnLine({
                    cornerA: data.allowedSegment.leftPoint.clone(),
                    cornerB: data.allowedSegment.rightPoint.clone(),
                    point: appliancesOnIsland[index + 1].meshComponent.getMesh().position,
                });

                applianceProjectedPosition.y = 0;
                distanceFromLastAppliance =
                    Vector3.Distance(lastAppliancePosition, applianceProjectedPosition) - appliancesOnIsland[index + 1].width / 2;
                if (distanceFromLastAppliance < 0.15) {
                    distanceFromLastAppliance = 0;
                }
            } else {
                let closestPoint = Utilities.getClosestPoint(
                    data.startingPoint,
                    data.allowedSegment.leftPoint,
                    data.allowedSegment.rightPoint
                );
                let endPoint = data.allowedSegment.rightPoint;
                if (closestPoint.closestPointIndex === Constants.corner.CORNER_B) {
                    endPoint = data.allowedSegment.leftPoint;
                }
                endPoint.y = 0;
                distanceFromLastAppliance = Vector3.Distance(lastAppliancePosition, endPoint);
            }
            let endCabinets = this.sectionCalculator.replaceEmptyCabinetSections(distanceFromLastAppliance);
            numberOfSectionsPerType = numberOfSectionsPerType.concat(endCabinets);
            lastAppliancePosition = data.startingPoint.add(data.replacementDirection.clone().scale(data.cabinet.filledWidth));
        }
        return numberOfSectionsPerType;
    }

    createSection(parentCabinet, resultForType, position, row) {
        const rowDepth = parentCabinet.rowDepths[row];
        let width = resultForType.sectionWidth;
        let section = this.editor.addObject(SingleCabinet);
        if (resultForType.sectionData?.isCornerCabinet) {
            section.type = Constants.section.type.CORNER_SECTION;
            section.sectionData = resultForType.sectionData;
        }

        section.assignedRow = row;
        section.build(parentCabinet, width, position.clone(), rowDepth);
        parentCabinet.sections[row].push(section);
        if (resultForType.sectionData instanceof BaseAppliance) {
            resultForType.sectionData.meshComponent.getMesh().position = position.clone();
            resultForType.sectionData.position = position;
            section.appliance = resultForType.sectionData ? resultForType.sectionData : null;
            section.appliance.meshComponent.getMesh().rotation.y = (section.appliance.rotation * Math.PI) / 180;
            if ([Constants.appliance.type.COOKTOP, Constants.appliance.type.SINK].includes(section.appliance.type)) {
                section.appliance.meshComponent.getMesh().position.y = section.appliance.currentCabinet.height;
                section.sectionData =
                    typeof resultForType.sectionData?.id === 'number' ? resultForType.sectionData.id : resultForType.sectionData;
                if (section.appliance.type === Constants.appliance.type.SINK) {
                    section.appliance.meshComponent.getMesh().position.y =
                        section.appliance.currentCabinet.height - section.appliance.height / 2;
                }
            } else if (section.appliance.type === Constants.appliance.type.HOOD) {
                section.appliance.meshComponent.getMesh().position.y =
                    Constants.hood.HEIGHT_FROM_FLOOR + section.appliance.height / 2;
            }
        }

        if (resultForType.cooktop) {
            resultForType.cooktop.meshComponent.mesh.position = section.meshComponent.getMesh().position.clone();
        }

        return section;
    }
}
