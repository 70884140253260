var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "inputs",
      model: {
        value: _vm.isValid,
        callback: function ($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid",
      },
    },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _vm._l(_vm.fixture.dimensions, function (value, key) {
            return _c(
              "v-col",
              {
                key: key,
                staticClass: "pa-1",
                attrs: { cols: "4", sm: "auto" },
              },
              [
                _c("v-text-field", {
                  ref: "key",
                  refInFor: true,
                  attrs: {
                    value: _vm.fixture.dimensions[key],
                    label: key,
                    min: _vm.limits[key].min[_vm.selectedUnit],
                    max: _vm.limits[key].max[_vm.selectedUnit],
                    suffix: _vm.selectedUnit,
                    outlined: "",
                    dense: _vm.$vuetify.breakpoint.xsOnly,
                    type: "number",
                    rules: [
                      _vm.rules.required,
                      _vm.rules.between(
                        _vm.limits[key].min[_vm.selectedUnit],
                        _vm.limits[key].max[_vm.selectedUnit]
                      ),
                    ],
                  },
                  on: {
                    input: function ($event) {
                      return _vm.resize(key)
                    },
                  },
                }),
              ],
              1
            )
          }),
          _c(
            "v-col",
            { staticClass: "pa-1", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: {
                    color: "red",
                    dark: "",
                    icon: "",
                    large: "",
                    tile: "",
                  },
                  on: { click: _vm.dispose },
                },
                [
                  _c("v-icon", { attrs: { dark: "" } }, [
                    _vm._v(_vm._s(_vm.mdiDelete)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }