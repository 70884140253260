<template>
    <v-form ref="inputs" v-model="isValid">
        <v-row justify="center">
            <v-col
                v-for="(value, key) in fixture.dimensions"
                :key="key"
                cols="4"
                sm="auto"
                class="pa-1"
            >
                <v-text-field
                    :value="fixture.dimensions[key]"
                    @input="resize(key)"
                    :label="key"
                    ref="key"
                    :min="limits[key].min[selectedUnit]"
                    :max="limits[key].max[selectedUnit]"
                    :suffix="selectedUnit"
                    outlined
                    :dense="$vuetify.breakpoint.xsOnly"
                    type="number"
                    :rules="[
                        rules.required,
                        rules.between(
                            limits[key].min[selectedUnit],
                            limits[key].max[selectedUnit]
                        ),
                    ]"
                ></v-text-field>
            </v-col>
            <v-col cols="auto" class="pa-1">
                <v-btn
                    @click="dispose"
                    class="ma-2"
                    color="red"
                    dark
                    icon
                    large
                    tile
                >
                    <v-icon dark>{{ mdiDelete }}</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mdiDelete } from '@mdi/js';

export default {
    data() {
        return {
            isValid: true,
            rules: {
                required: (value) => !!value || value === 0 || 'Required',
                between(min, max) {
                    return (value) =>
                        (value >= min && value <= max) ||
                        `Must be between ${min} and ${max}`;
                },
            },
            mdiDelete: mdiDelete,
        };
    },
    computed: {
        ...mapGetters('core', ['selectedUnit']),
        ...mapState('fixtures', {
            fixture: (state) => state.selectedFixture,
            fixtures: (state) => state.fixtures,
            type: (state) => state.modalType,
        }),
        limits() {
            const type = this.fixture.type,
                subType = this.fixture.subType,
                fixtureDimensions =
                    this.fixtures[type].type[subType].dimensions,
                limits = {};

            Object.keys(fixtureDimensions).forEach((d) => {
                console.log(limits);
                limits[d] = {
                    min: fixtureDimensions[d].min,
                    max: fixtureDimensions[d].max,
                };
            });

            return limits;
        },
    },
    methods: {
        resize(key) {
            const oldVal = this.fixture.dimensions[key];

            if (this.$refs.inputs.validate()) {
                this.fixture.dimensions[key] = event.target.value;
                this.fixture.resize();
            } else {
                this.fixture.dimensions[key] = oldVal;
            }
        },
        dispose() {
            this.fixture.dispose();
        },
    },
};
</script>
