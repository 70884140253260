<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">Add {{ fixture.name }}:</span>
        </v-card-title>
        <v-card-text class="px-2">
            <v-container>
                <!-- <p>{{ fixture }}</p> -->
                <!-- TYPES -->
                <v-item-group
                    mandatory
                    v-model="setModalFixtureType"
                    v-if="fixture.type"
                >
                    <h4>Type:*</h4>
                    <v-row justify="center">
                        <v-col
                            v-for="(obj, key) in fixture.type"
                            :key="key"
                            cols="4"
                            class="px-1"
                        >
                            <v-item
                                v-slot:default="{ active, toggle }"
                                :value="key"
                            >
                                <v-hover v-slot:default="{ hover }">
                                    <v-card
                                        :color="active ? 'blue lighten-4' : ''"
                                        :elevation="hover ? 16 : 2"
                                        :class="{ 'on-hover': hover }"
                                        @click="toggle"
                                    >
                                        <v-img
                                            :src="obj.img"
                                            height="150"
                                            contain
                                            class="pa-2"
                                        >
                                        </v-img>
                                        <v-card-text
                                            class="
                                                my-4
                                                text-body-2
                                                text-center
                                                text-subtitle-2
                                            "
                                            >{{ obj.name }}</v-card-text
                                        >
                                    </v-card>
                                </v-hover>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-item-group>

                <!-- Dimension fields -->
                <v-form v-model="isValid">
                    <v-row justify="center">
                        <v-col
                            v-for="(obj, key) in fixture.type[fixtureType]
                                .dimensions"
                            :key="key"
                            cols="6"
                            sm="4"
                        >
                            <v-text-field
                                v-model.number="obj.value[selectedUnit]"
                                :label="key"
                                ref="key"
                                :min="obj.min[selectedUnit]"
                                :max="obj.max[selectedUnit]"
                                :suffix="selectedUnit"
                                outlined
                                required
                                type="number"
                                :rules="[
                                    rules.required,
                                    rules.between(
                                        obj.min[selectedUnit],
                                        obj.max[selectedUnit]
                                    ),
                                ]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <small>*indicates required field</small>
        </v-card-text>

        <!-- Dialog Actions -->
        <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="blue darken-1" text @click="closeDialog"
                >Cancel
            </v-btn>
            <v-btn
                class="white--text"
                color="blue darken-1"
                @click="addObj"
                large
                :disabled="!isValid"
                >Add
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            isValid: false,
            rules: {
                required: (value) => !!value || value === 0 || 'Required',
                between(min, max) {
                    return (value) =>
                        (value >= min && value <= max) ||
                        `Must be between ${min} and ${max}`;
                },
            },
        };
    },
    computed: {
        ...mapState('fixtures', {
            fixture: (state) => state.modalFixture,
            fixtureType: (state) => state.modalType,
        }),
        ...mapGetters('core', ['selectedUnit']),
        setModalFixtureType: {
            set(type) {
                this.$store.commit('fixtures/setModalFixtureType', type);
            },
            get() {
                return this.$store.state.fixtures.modalType;
            },
        },
    },
    methods: {
        closeDialog() {
            this.$store.commit('fixtures/openDialog', false);
        },
        addObj() {
            this.$store.dispatch('fixtures/addObj');
            this.closeDialog();
        },
    },
    destroyed() {
        this.$store.commit('fixtures/setmodalFixture', null);
        this.setModalFixtureType = 'standard';
    },
};
</script>
