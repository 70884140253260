/*eslint no-unused-vars: "off"*/
import { Editor2D } from './2D/Editor2D';
import store from '@/store/index.js';
export const render = (sceneInfo = null) => {
    let editor = new Editor2D(onReady);
    store.commit('core/setEditorEngine', editor);
    store.commit('core/canvasLoaded');
};

const onReady = () => {
    store.state.core.editorEngine.controls.loadScene(store.state.core.projectData);
};
