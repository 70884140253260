var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper-content",
    { staticClass: "pa-2", attrs: { step: "1" } },
    [
      !_vm.isWallsClosed
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "8", md: "9" } },
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "0", color: "rounded-lg" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "white",
                              attrs: {
                                disabled: _vm.disableInput,
                                hint: _vm.disableInput
                                  ? "Click once on the canvas to activate wall inputs"
                                  : null,
                                label: "Current Wall Dimension",
                                type: "number",
                                suffix: _vm.selectedUnit,
                                "persistent-hint": "",
                                "single-line": "",
                                outlined: "",
                                clearable: "",
                                dense: "",
                              },
                              on: { "click:clear": _vm.resetTyping },
                              model: {
                                value: _vm.typedWallDim,
                                callback: function ($$v) {
                                  _vm.typedWallDim = $$v
                                },
                                expression: "typedWallDim",
                              },
                            },
                            [_vm._v("Wall Dimension ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("StepsButtons", {
                staticClass: "py-0",
                attrs: { stepNumber: 2 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isWallsClosed && !_vm.selectedFixture
        ? _c(
            "v-row",
            { staticClass: "pa-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "8", md: "9" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "flex-wrap flex-row blue-grey lighten-4 rounded-lg",
                    },
                    [
                      _c(
                        "v-slide-group",
                        {
                          staticClass: "mx-auto",
                          attrs: { "show-arrows": "" },
                        },
                        _vm._l(_vm.fixtures, function (fixture, key) {
                          return _c(
                            "v-slide-item",
                            { key: key },
                            [
                              _c("v-hover", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ hover }) {
                                        return [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "pa-1 ma-2",
                                              attrs: {
                                                elevation: hover ? 16 : 2,
                                                width: _vm.$vuetify.breakpoint
                                                  .smAndUp
                                                  ? 150
                                                  : 70,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;(_vm.openDialog = true),
                                                    _vm.setmodalFixture(fixture)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "py-2 text-center",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(fixture.icon)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.$vuetify.breakpoint.smAndUp
                                                ? _c(
                                                    "v-card-subtitle",
                                                    {
                                                      staticClass:
                                                        "text-center py-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(fixture.name)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "800px",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                  },
                  model: {
                    value: _vm.openDialog,
                    callback: function ($$v) {
                      _vm.openDialog = $$v
                    },
                    expression: "openDialog",
                  },
                },
                [_vm.openDialog ? _c("FixturesDialog") : _vm._e()],
                1
              ),
              _c("StepsButtons", { attrs: { stepNumber: 2 } }),
            ],
            1
          )
        : _vm._e(),
      _vm.isWallsClosed && _vm.selectedFixture && !_vm.openDialog
        ? _c("v-container", [_c("FixtureEdit")], 1)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }