import { FixtureDiagramWidget } from '../DiagramWidget';
import { FixturePart } from './FixturePart';
import { unitAsBjsUnit } from '../../Tools/Utilities';
import { Vector2 } from '@babylonjs/core';

export const FixturePartBuilder = (fixture) => {
    let cWidth = unitAsBjsUnit(fixture.dimensions.width);
    switch (fixture.type) {
        case 'openSpace':
            {
                fixture.parts.push(
                    new FixturePart(
                        new Vector2(cWidth, fixture.wallThickness),
                        Vector2.Zero(),
                        {
                            baseColor: 'rgb(255,255,255)',
                            lineColor: 'rgb(0,0,0)',
                            highlightColor: 'rgba(251, 243, 46, 0.5)',
                        },
                        true,
                        fixture
                    )
                );
            }
            break;
        case 'window': {
            fixture.parts.push(
                new FixturePart(
                    new Vector2(cWidth, fixture.wallThickness),
                    Vector2.Zero(),
                    {
                        baseColor: 'rgb( 255, 255, 255 )',
                        lineColor: 'rgb( 0, 0, 0 )',
                        highlightColor: 'rgba( 251, 243, 46, 0.5 )',
                    },
                    true,
                    fixture
                )
            );
            break;
        }
        case 'door':
            {
                switch (fixture.subType) {
                    case 'standard': {
                        //Separate the pushes for indexing.
                        fixture.parts.push(
                            new FixturePart(
                                new Vector2(cWidth, fixture.wallThickness),
                                Vector2.Zero(),
                                {
                                    baseColor: 'rgb( 255, 255, 255 )',
                                    lineColor: 'rgb( 0, 0, 0 )',
                                    highlightColor: 'rgba( 251, 243, 46, 0.5 )',
                                },
                                true,
                                fixture
                            )
                        );
                        fixture.parts.push(
                            new FixturePart(
                                new Vector2(cWidth, cWidth),
                                new Vector2(0, cWidth * 0.5 + fixture.wallThickness * 0.5),
                                {
                                    baseColor: 'rgba(255,255,255, 0.01)',
                                    lineColor: 'rgb(80,80,80)',
                                    highlightColor: 'rgba(0, 0, 0, 0)',
                                    flipY: false,
                                },
                                false,
                                fixture
                            )
                        );
                        break;
                    }
                    case 'sliding': {
                        fixture.parts.push(
                            new FixturePart(
                                new Vector2(cWidth, fixture.wallThickness),
                                Vector2.Zero(),
                                {
                                    baseColor: 'rgb(255,255,255)',
                                    lineColor: 'rgb(0,0,0)',
                                    highlightColor: 'rgba(251, 243, 46, 0.5)',
                                },
                                true,
                                fixture
                            )
                        );
                        break;
                    }
                    case 'balcony': {
                        fixture.parts.push(
                            new FixturePart(
                                new Vector2(cWidth, fixture.wallThickness),
                                Vector2.Zero(),
                                {
                                    baseColor: 'rgb( 255, 255, 255 )',
                                    lineColor: 'rgb( 0, 0, 0 )',
                                    highlightColor: 'rgba( 251, 243, 46, 0.5 )',
                                },
                                true,
                                fixture
                            )
                        );
                        break;
                    }
                }
            }
            break;
        case 'fixedColumn': {
            fixture.parts.push(
                new FixturePart(
                    new Vector2(cWidth, unitAsBjsUnit(fixture.dimensions.depth)),
                    Vector2.Zero(),
                    {
                        baseColor: 'rgb( 180, 180, 180 )',
                        lineColor: 'rgb( 0, 0, 0 )',
                        highlightColor: 'rgba( 251, 243, 46, 0.5 )',
                    },
                    true,
                    fixture
                )
            );
            break;
        }
    }
    fixture.widget = new FixtureDiagramWidget(fixture);
};
