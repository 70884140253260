var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("span", { staticClass: "text-h5" }, [
          _vm._v("Add " + _vm._s(_vm.appliance.name) + ":"),
        ]),
      ]),
      _c(
        "v-card-text",
        { staticClass: "px-2" },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-slide-group",
                    {
                      staticClass: "mx-auto",
                      attrs: { "show-arrows": "", "active-class": "success" },
                      model: {
                        value: _vm.selectedApplianceProps.subtype,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedApplianceProps, "subtype", $$v)
                        },
                        expression: "selectedApplianceProps.subtype",
                      },
                    },
                    _vm._l(_vm.appliance.subtype, function (subtype, key) {
                      return _c("v-slide-item", {
                        key: key,
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ active, toggle }) {
                                return [
                                  _c("v-hover", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ hover }) {
                                            return [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "pa-1 ma-2",
                                                  attrs: {
                                                    color: active
                                                      ? "green lighten-1"
                                                      : null,
                                                    elevation: hover ? 16 : 2,
                                                    width: _vm.$vuetify
                                                      .breakpoint.smAndUp
                                                      ? 150
                                                      : 70,
                                                  },
                                                  on: { click: toggle },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "fill-height",
                                                      attrs: {
                                                        align: "center",
                                                        justify: "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticClass:
                                                            "py-2 text-caption text-center text-subtitle-2",
                                                        },
                                                        [
                                                          _c("v-img", {
                                                            staticClass: "pa-2",
                                                            attrs: {
                                                              src: subtype.img,
                                                              height: "80",
                                                              contain: "",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                        ? _c(
                                                            "v-card-subtitle",
                                                            {
                                                              staticClass:
                                                                "text-center py-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  subtype.name
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function ($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid",
                  },
                },
                [
                  _vm._l(_vm.appliance.subtype, function (subtype) {
                    return _c(
                      "v-container",
                      { key: subtype.id, staticClass: "pa-0" },
                      [
                        _vm.appliance.subtype[
                          _vm.selectedApplianceProps.subtype
                        ].id === subtype.id && subtype.dimensions
                          ? _c(
                              "v-row",
                              { attrs: { justify: "center" } },
                              _vm._l(
                                subtype.dimensions,
                                function (dimension, key) {
                                  return _c(
                                    "v-col",
                                    { key: key, attrs: { cols: "6", sm: "4" } },
                                    [
                                      _c("v-combobox", {
                                        ref: "key",
                                        refInFor: true,
                                        attrs: {
                                          items:
                                            dimension.items[_vm.selectedUnit],
                                          label: key,
                                          outlined: "",
                                          required: "",
                                          subtype: "number",
                                          rules: [
                                            _vm.rules.required,
                                            _vm.rules.between(
                                              dimension.min[_vm.selectedUnit],
                                              dimension.max[_vm.selectedUnit]
                                            ),
                                          ],
                                          suffix: _vm.selectedUnit,
                                        },
                                        nativeOn: {
                                          input: function ($event) {
                                            dimension.value[_vm.selectedUnit] =
                                              $event.srcElement.value
                                          },
                                        },
                                        model: {
                                          value:
                                            dimension.value[_vm.selectedUnit],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              dimension.value,
                                              _vm.selectedUnit,
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "\n                                        dimension.value[selectedUnit]\n                                    ",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _vm.appliance.subtype[
                          _vm.selectedApplianceProps.subtype
                        ].id === subtype.id
                          ? _c(
                              "v-row",
                              { attrs: { justify: "center", align: "center" } },
                              [
                                subtype.direction
                                  ? _c(
                                      "v-btn-toggle",
                                      {
                                        attrs: {
                                          tile: "",
                                          color: "primary",
                                          group: "",
                                          mandatory: "",
                                        },
                                        model: {
                                          value:
                                            _vm.selectedApplianceProps
                                              .direction,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedApplianceProps,
                                              "direction",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedApplianceProps.direction",
                                        },
                                      },
                                      _vm._l(
                                        subtype.direction.items,
                                        function (direction) {
                                          return _c(
                                            "v-btn",
                                            {
                                              key: direction,
                                              attrs: { value: direction },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(direction) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" },
                                }),
                                subtype.color
                                  ? _c(
                                      "v-btn-toggle",
                                      {
                                        attrs: {
                                          tile: "",
                                          color: "primary",
                                          group: "",
                                        },
                                        model: {
                                          value:
                                            _vm.selectedApplianceProps.color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedApplianceProps,
                                              "color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedApplianceProps.color",
                                        },
                                      },
                                      _vm._l(
                                        subtype.color.items,
                                        function (color) {
                                          return _c(
                                            "v-btn",
                                            {
                                              key: color,
                                              attrs: { value: color },
                                            },
                                            [_vm._v(" " + _vm._s(color) + " ")]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" },
                                }),
                                subtype.format
                                  ? _c(
                                      "v-btn-toggle",
                                      {
                                        attrs: {
                                          tile: "",
                                          color: "primary",
                                          group: "",
                                          mandatory: "",
                                        },
                                        model: {
                                          value:
                                            _vm.selectedApplianceProps.format,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedApplianceProps,
                                              "format",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedApplianceProps.format",
                                        },
                                      },
                                      _vm._l(
                                        subtype.format.items,
                                        function (format) {
                                          return _c(
                                            "v-btn",
                                            {
                                              key: format,
                                              attrs: { value: format },
                                            },
                                            [_vm._v(" " + _vm._s(format) + " ")]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm.appliance.id === "hood" && _vm.cooktopAlert
                    ? _c("v-input", {
                        attrs: { rules: [_vm.rules.hasCooktop] },
                      })
                    : _vm._e(),
                  _vm.appliance.id === "refrigerator" && _vm.builtinFridgeAlert
                    ? _c("v-input", {
                        attrs: { rules: [_vm.rules.hasTallCabinetsForFridge] },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c("small", [_vm._v("*indicates required field")]),
        ],
        1
      ),
      _vm.appliance.id === "hood" && _vm.cooktopAlert
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(_vm._s(_vm.cooktopAlert)),
          ])
        : _vm._e(),
      _vm.appliance.id === "refrigerator" &&
      _vm.builtinFridgeAlert &&
      _vm.selectedApplianceProps.format === "builtin"
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(_vm._s(_vm.builtinFridgeAlert)),
          ])
        : _vm._e(),
      _c(
        "v-card-actions",
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: { click: _vm.closeDialog },
            },
            [_vm._v("Cancel ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: {
                color: "blue darken-1",
                large: "",
                disabled: !_vm.isValid,
              },
              on: { click: _vm.addObject },
            },
            [_vm._v("Add ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }