import { Mesh, MeshBuilder, Vector3 } from '@babylonjs/core';
import { Constants } from '../../../../Tools/constants';
import { Utilities } from '../../../../Tools/utilities';
import { BuildingBlock } from '../../../BuildingBlocks/BuildingBlock';
import { CabinetStructure } from '../../../BuildingBlocks/CabinetStructure';
import { DoorBlock } from '../../../BuildingBlocks/DoorBlock';
import { Skirting } from '../../../BuildingBlocks/Skirting';
import { Model } from '../../Model';
import { ResizingManager } from './ResizingManager';

export class BaseCabinet extends Model {
    buildingBlocks = {};
    constructor() {
        super();
        this.resizingManager = this.registerComponent(ResizingManager);
    }

    async setModel() {
        const result = await this.import('base-cabinet.babylon');
        this.meshComponent.setMesh(Utilities.getElementByMeshName('Cube', result.meshes));
        this.meshComponent.getMesh().setEnabled(false);
        this.meshComponent.setName('baseCabinet');
        this.setParts();
    }

    resize(dimensions) {
        this.resizingManager.parts = this.parts;
        const backsplashHeight = 0.1;

        this.width = dimensions.width;
        this.depth = dimensions.depth;
        this.height = dimensions.height;

        this.resizingManager.resizeWidth(dimensions);
        this.resizingManager.resizeDepth(dimensions.depth);
        this.resizingManager.resizeHeight(dimensions.height + backsplashHeight);
        this.resizingManager.lastDepth = dimensions.depth;
    }

    choosePartsToShow(sectionSpecifications) {
        const isSingleRow = this.parentCabinet?.rowDepths?.secondRow === 0 || this.parentCabinet?.rowDepths?.firstRow === 0;
        if (isSingleRow) {
            this.chosePartsForIslandSingleRow();
        }

        if (this.parentCabinet.type === Constants.cabinet.type.ISLAND) {
            let parentCabinetPositionInfo;
            if (this.parentCabinet.type === Constants.cabinet.type.BASE) {
                parentCabinetPositionInfo = this.parentCabinet.getPositionInfo();
            }
            let reverse = 0;
            if (
                sectionSpecifications.row === 'secondRow' ||
                (((this.parentCabinet.rotation === Math.PI &&
                    sectionSpecifications.replacementDirection.equals(Vector3.Right())) ||
                    (this.parentCabinet.rotation === Math.PI / 2 &&
                        sectionSpecifications.replacementDirection.equals(new Vector3(0, 0, 1))) ||
                    (this.parentCabinet.rotation === (3 * Math.PI) / 2 &&
                        sectionSpecifications.replacementDirection.equals(new Vector3(0, 0, -1)))) &&
                    this.parentCabinet.type === Constants.cabinet.type.BASE)
            ) {
                reverse = 1;
            }
            if (sectionSpecifications.index === 'first') {
                this.parts.skirting.skirtingFrontLeft.mesh.visibility = 0 + reverse;
                this.parts.skirting.skirtingFrontRight.mesh.visibility = 1 + reverse;
                if (this.parentCabinet.type === Constants.cabinet.type.ISLAND && isSingleRow) {
                    this.parts.island.skirtingFrontLeftIsland.mesh.visibility = 0;
                    this.parts.island.skirtingFrontRightIsland.mesh.visibility = 1;
                }
            } else if (sectionSpecifications.index === 'last') {
                if (
                    (!parentCabinetPositionInfo?.cornerB &&
                        (sectionSpecifications.replacementDirection.equals(Vector3.Right()) ||
                            sectionSpecifications.replacementDirection.equals(new Vector3(0, 1, 0)))) ||
                    (!parentCabinetPositionInfo?.cornerA &&
                        (sectionSpecifications.replacementDirection.equals(Vector3.Left()) ||
                            sectionSpecifications.replacementDirection.equals(new Vector3(0, 1, 0))))
                ) {
                    this.parts.skirting.skirtingFrontLeft.mesh.visibility = 1 + reverse;
                    this.parts.skirting.skirtingFrontRight.mesh.visibility = 0 + reverse;
                }

                if (this.parentCabinet.type === Constants.cabinet.type.ISLAND) {
                    if (this.parentCabinet.peninsulaData.isPeninsula) {
                        if (this.parentCabinet.peninsulaData.side === 'left') {
                            this.parts.skirting.skirtingFrontLeft.mesh.visibility = 1;
                            this.parts.skirting.skirtingFrontRight.mesh.visibility = 0;
                            if (sectionSpecifications.row === 'firstRow') {
                                this.parts.skirting.skirtingFrontLeft.mesh.visibility = 0;
                                this.parts.skirting.skirtingFrontRight.mesh.visibility = 1;
                            }

                            if (isSingleRow) {
                                this.parts.island.skirtingFrontLeftIsland.mesh.visibility = 0;
                                this.parts.island.skirtingFrontRightIsland.mesh.visibility = 1;
                            }
                        } else if (this.parentCabinet.peninsulaData.side === 'right') {
                            if (isSingleRow) {
                                this.parts.island.skirtingFrontLeftIsland.mesh.visibility = 1;
                                this.parts.island.skirtingFrontRightIsland.mesh.visibility = 0;
                            }
                        }
                    } else {
                        if (isSingleRow) {
                            this.parts.island.skirtingFrontLeftIsland.mesh.visibility = 1;
                            this.parts.island.skirtingFrontRightIsland.mesh.visibility = 0;
                        }
                    }
                }
            } else {
                this.parts.skirting.skirtingFrontLeft.mesh.visibility = 1;
                this.parts.skirting.skirtingFrontRight.mesh.visibility = 1;
                if (this.parentCabinet.type === Constants.cabinet.type.ISLAND && isSingleRow) {
                    this.parts.island.skirtingFrontLeftIsland.mesh.visibility = 1;
                    this.parts.island.skirtingFrontRightIsland.mesh.visibility = 1;
                }
            }
        }
    }

    mergeModel(sectionSpecifications = {}) {
        const baseCabinetModelMesh = MeshBuilder.CreateBox('base-cabinet-model', { width: 0.1, depth: 0.1, height: 0.1 });
        baseCabinetModelMesh.visibility = 0;

        const baseCabinetModel = { mesh: baseCabinetModelMesh, buildingBlocks: {} };

        this.choosePartsToShow(sectionSpecifications);
        this.doorHandle = this.placeHandle(baseCabinetModel);

        baseCabinetModel.buildingBlocks.innerStructure = this.mergeCabinetBlock(baseCabinetModel.mesh);
        baseCabinetModel.buildingBlocks.doorBlock = this.mergeDoorBlock(baseCabinetModel.mesh);
        baseCabinetModel.buildingBlocks.countertopBlock = this.mergeCounterTopBlock(baseCabinetModel.mesh);
        baseCabinetModel.buildingBlocks.skirtingBlock = this.mergeSkirtingBlock(baseCabinetModel.mesh, sectionSpecifications);
        baseCabinetModel.buildingBlocks.handleBlock = this.doorHandle;

        this.handleApplianceInsideModel(baseCabinetModel);

        return baseCabinetModel;
    }

    placeHandle(baseCabinetModel) {
        if (
            !this.sectionInfo.isFiller &&
            (!this.sectionInfo?.appliance ||
                ['cooktop', 'sink'].includes(this.sectionInfo.appliance.type) ||
                (this.sectionInfo.appliance.format === Constants.appliance.format.BUILTIN &&
                    this.sectionInfo.appliance.type !== Constants.appliance.type.OVEN))
        ) {
            const handleClone = Mesh.MergeMeshes([this.editor.models3D.handle.meshComponent.getMesh().clone()]);
            if (this.width <= 0.15) {
                Utilities.resizeMeshInWidth(handleClone, 0.12);
            }
            handleClone.position = this.parts.door1.door1handle.mesh.position;
            handleClone.type = 'handle';
            handleClone.parent = baseCabinetModel.mesh;
            handleClone.alwaysSelectAsActiveMesh = true;
            return handleClone;
        }
    }

    mergeCabinetBlock(parentMesh = null) {
        const meshes = [];
        Object.keys(this.parts.cabinet).forEach((key) => {
            if (this.parts.cabinet[key].mesh.visibility === 1) {
                meshes.push(this.parts.cabinet[key].mesh);
            }
        });

        if (this.parentCabinet.type === Constants.cabinet.type.ISLAND && this.parts.island.islandBack.mesh.visibility === 1) {
            meshes.push(this.parts.island.islandBack.mesh);
            this.parts.island.islandBack.mesh.visibility = 0;
        }

        const innerStructure = this.editor.addObject(CabinetStructure);
        innerStructure.setParentSection(this.currentParentSection);
        innerStructure.create(meshes, 'cabinets');
        if (parentMesh) {
            innerStructure.meshComponent.setParent(parentMesh);
        }
        return innerStructure;
    }

    mergeSkirtingBlock(parentMesh) {
        const meshes = [];
        Object.keys(this.parts.skirting).forEach((key) => {
            if (this.parts.skirting[key].mesh.visibility === 1) {
                meshes.push(this.parts.skirting[key].mesh);
            }
        });

        if (this.parentCabinet.type === Constants.cabinet.type.ISLAND) {
            Object.keys(this.parts.island).forEach((key) => {
                if (
                    this.parts.island[key].mesh.name.toLowerCase().includes('skirting') &&
                    this.parts.island[key].mesh.visibility === 1
                ) {
                    meshes.push(this.parts.island[key].mesh);
                }
            });
        }

        const skirtingBlock = this.editor.addObject(Skirting);
        skirtingBlock.setParentSection(this.currentParentSection);
        skirtingBlock.enableSelection();
        skirtingBlock.create(meshes, 'skirting');
        skirtingBlock.meshComponent.setParent(parentMesh);
        return skirtingBlock;
    }

    mergeCounterTopBlock(parentMesh) {
        const meshes = [];
        Object.keys(this.parts.countertop).forEach((key) => {
            if (!['countertopRight', 'countertopLeft'].includes(this.parts.countertop[key].mesh.name)) {
                if (
                    this.parentCabinet.type === Constants.cabinet.type.ISLAND &&
                    ['backsplash'].includes(this.parts.countertop[key].mesh.name)
                ) {
                    return;
                }
                if (this.parts.countertop[key].mesh.visibility === 1) {
                    meshes.push(this.parts.countertop[key].mesh);
                }
            }
        });

        if (this.parentCabinet.type === Constants.cabinet.type.ISLAND) {
            Object.keys(this.parts.island).forEach((key) => {
                if (
                    this.parts.island[key].mesh.name.toLowerCase().includes('countertop') &&
                    this.parts.island[key].mesh.visibility === 1
                ) {
                    meshes.push(this.parts.island[key].mesh);
                }
            });
        }
        const countertopBlock = this.editor.addObject(BuildingBlock);
        countertopBlock.setParentSection(this.currentParentSection);
        countertopBlock.enableSelection();
        countertopBlock.create(meshes, 'countertop');
        countertopBlock.meshComponent.setParent(parentMesh);
        return countertopBlock;
    }

    mergeDoorBlock(parentMesh = null) {
        const meshes = [this.parts.door1.singleDoor.mesh];
        const doorBlock = this.editor.addObject(DoorBlock);
        doorBlock.setParentSection(this.currentParentSection);
        doorBlock.create(meshes, 'cabinets');
        if (parentMesh) {
            doorBlock.meshComponent.setParent(parentMesh);
        }
        return doorBlock;
    }

    mergeOvenPanels(parentMesh) {
        const meshes = [this.parts.oven.frontPanelLowerOven.mesh, this.parts.oven.frontPanelUpperOven.mesh];

        const ovenPanelsBlock = this.editor.addObject(CabinetStructure);
        ovenPanelsBlock.setParentSection(this.currentParentSection);
        ovenPanelsBlock.enableSelection();
        ovenPanelsBlock.create(meshes, 'cabinets');
        ovenPanelsBlock.meshComponent.setParent(parentMesh);
        ovenPanelsBlock.isOvenProperty = true;
        return ovenPanelsBlock;
    }

    handleApplianceInsideModel(baseCabinetModel) {
        const buildingBlocks = baseCabinetModel.buildingBlocks;
        const appliance = this.sectionInfo?.appliance;

        if (appliance) {
            if (appliance.type === Constants.appliance.type.OVEN) {
                if (appliance.subtype === 'ovenSignle') {
                    this.hidePartsToFitApplianceType([buildingBlocks.doorBlock]);
                    this.parts.oven.ovenBody.mesh.parent = baseCabinetModel.mesh;
                    baseCabinetModel.buildingBlocks.ovenPanels = this.mergeOvenPanels(baseCabinetModel.mesh);
                    baseCabinetModel.buildingBlocks.ovenPanels.parent = baseCabinetModel.mesh;
                    this.parts.oven.ovenBody.mesh.visibility = 0;
                    appliance.model.position = this.parts.oven.ovenBody.mesh.position.clone();
                    appliance.meshComponent.getMesh().visibility = 1;
                } else if (appliance.subtype === 'range') {
                    this.hidePartsToFitApplianceType([
                        buildingBlocks.innerStructure,
                        buildingBlocks.skirtingBlock,
                        buildingBlocks.countertopBlock,
                        buildingBlocks.doorBlock,
                    ]);
                }
            } else if (
                appliance.format === Constants.appliance.format.FREESTANDING &&
                appliance.type !== Constants.appliance.type.HOOD
            ) {
                this.hidePartsToFitApplianceType([buildingBlocks.doorBlock]);
            } else if (
                appliance.format === Constants.appliance.format.BUILTIN &&
                appliance.type !== Constants.appliance.type.COOKTOP
            ) {
                appliance.meshComponent.getMesh().visibility = 0;
                appliance.model.visibility = 0;
            }
        }
    }

    hidePartsToFitApplianceType(blocksToHide) {
        for (let index = 0; index < blocksToHide.length; index++) {
            blocksToHide[index].meshComponent.getMesh().visibility = 0;
        }
    }

    showPartsToFitApplianceType(blocksToShow) {
        for (let index = 0; index < blocksToShow.length; index++) {
            blocksToShow[index].meshComponent.getMesh().visibility = 1;
        }
    }

    chosePartsForIslandSingleRow() {
        if (this.parentCabinet.type === Constants.cabinet.type.ISLAND) {
            const isSingleRow = this.parentCabinet.rowDepths.secondRow === 0 || this.parentCabinet?.rowDepths?.firstRow === 0;
            if (isSingleRow) {
                this.parts.island.countertopBack.mesh.visibility = 1;
                this.parts.island.islandBack.mesh.visibility = 1;
                this.parts.island.islandCountertopLeft.mesh.visibility = 0;
                this.parts.island.islandCountertopRight.mesh.visibility = 0;
                this.parts.island.skirtingBack.mesh.visibility = 1;
                this.parts.skirting.skirtingFrontLeft.mesh.visibility = 1;
                this.parts.skirting.skirtingFrontRight.mesh.visibility = 1;
                this.parts.skirting.skirtingBackLeft.mesh.visibility = 0;
                this.parts.skirting.skirtingBackRight.mesh.visibility = 0;
            } else {
                this.parts.island.countertopBack.mesh.visibility = 0;
                this.parts.island.islandBack.mesh.visibility = 0;
                this.parts.island.islandCountertopLeft.mesh.visibility = 0;
                this.parts.island.islandCountertopRight.mesh.visibility = 0;
                this.parts.island.skirtingBack.mesh.visibility = 0;
                this.parts.skirting.skirtingFrontLeft.mesh.visibility = 0;
                this.parts.skirting.skirtingFrontRight.mesh.visibility = 0;
                this.parts.skirting.skirtingBackLeft.mesh.visibility = 1;
                this.parts.skirting.skirtingBackRight.mesh.visibility = 1;
                this.parts.island.skirtingFrontLeftIsland.mesh.visibility = 0;
                this.parts.island.skirtingFrontRightIsland.mesh.visibility = 0;
            }
        } else {
            this.parts.island.countertopBack.mesh.visibility = 0;
            this.parts.island.islandBack.mesh.visibility = 0;
            this.parts.island.islandCountertopLeft.mesh.visibility = 0;
            this.parts.island.islandCountertopRight.mesh.visibility = 0;
            this.parts.island.skirtingBack.mesh.visibility = 0;
            this.parts.skirting.skirtingFrontLeft.mesh.visibility = 1;
            this.parts.skirting.skirtingFrontRight.mesh.visibility = 1;
            this.parts.skirting.skirtingBackLeft.mesh.visibility = 1;
            this.parts.skirting.skirtingBackRight.mesh.visibility = 1;
            this.parts.island.skirtingFrontLeftIsland.mesh.visibility = 0;
            this.parts.island.skirtingFrontRightIsland.mesh.visibility = 0;
        }
    }

    getResizingDimensions(sectionWidth) {
        return {
            width: sectionWidth,
            depth: this.parentCabinet.depth,
            height: this.parentCabinet.height,
        };
    }
}
