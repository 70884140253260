import { BaseObstacleDetection } from '../../../Components/Base/BaseObstacleDetection';
import { Constants } from '../../../Tools/constants';
import { Island } from '../../Cabinets/Island';

export class ObstacleManager extends BaseObstacleDetection {
    constructor() {
        super();
    }

    checkForApplianceObstacles() {
        if (this.object.editor.appliances.length <= 1) {
            return;
        }
        let isBlocked = false;
        for (let index = 0; index < this.object.editor.appliances.length; index++) {
            let appliance = this.object.editor.appliances[index];
            if (
                ((appliance.assignedWall && appliance.assignedWall?.id === this.object.assignedWall?.id) ||
                    this.object.currentCabinet instanceof Island) &&
                this.object.meshComponent.mesh.id !== appliance.id
            ) {
                if (!this.checkForApplianceObstacle(this.object, appliance)) {
                    isBlocked = false;
                } else {
                    isBlocked = true;
                    break;
                }
            }
        }
        return isBlocked;
    }

    checkForApplianceObstacle(movingCabinet, cabinet) {
        if (!this.shouldConsiderObstacle(cabinet)) {
            return false;
        }
        movingCabinet.meshComponent.getMesh().computeWorldMatrix();
        cabinet.meshComponent.getMesh().computeWorldMatrix();

        if (movingCabinet.meshComponent.getMesh().intersectsMesh(cabinet.meshComponent.getMesh(), true)) {
            return true;
        } else {
            return false;
        }
    }

    shouldConsiderObstacle(fixture) {
        if (
            fixture.meshComponent.getMesh().name === Constants.fixture.type.WINDOW ||
            fixture.meshComponent.getMesh().name === Constants.fixture.type.OPEN_SPACE
        ) {
            if (this.object.height && fixture.heightFromFloor > this.object.height) {
                return false;
            }
        }
        if (this.object.type === 'cooktop' || fixture.type === 'cooktop' || fixture.type === 'hood' || this.object.type === 'hood') {
            return false;
        }
        return true;
    }
}
