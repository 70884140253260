var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    {
      staticClass: "d-flex flex-column justify-center flex-shrink-1 height",
      attrs: { cols: "4", md: "3" },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "white--text",
          attrs: {
            color: "green",
            disabled: !_vm.isWallsClosed,
            small: _vm.$vuetify.breakpoint.mdAndDown,
          },
          on: {
            click: function ($event) {
              return _vm.setStep(_vm.stepNumber)
            },
          },
        },
        [_vm._v("Next ")]
      ),
      _c("resetBtn"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }