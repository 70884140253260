import { Vector3 } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { SectionCalculator } from './SectionCalculator';

export class LayoutUseCaseHandler {
    constructor(editor) {
        this.editor = editor;
        this.sectionCalculator = new SectionCalculator(editor);
    }

    handleAllUseCases(data) {
        const cabinetPositionInfo = data.cabinet.getPositionInfo();
        this.sectionCalculator.currentCabinet = data.cabinet;
        if (data.cabinet.connectedCabinet && data.cabinet.secondConnectedCabinet) {
            //if cabinet is extended in both corners
            return this.handleTwoExtendedCabinets(data);
        } else if (cabinetPositionInfo.cornerA && cabinetPositionInfo.cornerB) {
            //when cabinet touches walls on each end
            return this.handleFullLengthCabinet(data);
        } else if (data.cabinet.cornerCabinetInfo) {
            //when a cabinet has only one or no extended end and a corner cabinet present
            return this.handleCornerCabinetUseCase(data);
        } else if (data.cabinet.connectedCabinet || data.cabinet.secondConnectedCabinet) {
            //if cabinet has only one extended end but doesn't reach the connection corner
            return this.handleOneExtendedEndUseCase(data);
        } else if (cabinetPositionInfo.cornerA || cabinetPositionInfo.cornerB) {
            //if cabinet touches one corner and has no connected cabinets

            return this.handleCabinetTouchingOneCorner(data);
        } else if (data.cabinet.type === Constants.cabinet.type.WALL) {
            return this.handleWallCabinetNearTallCabinet(data);
        } else {
            const width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
            return this.calculateSections(width, data);
        }
    }

    handleTwoExtendedCabinets(data) {
        let width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let result = this.calculateSections(width, data);
        width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let spaceLeftForFiller = width - data.cabinet.filledWidth;
        let standardFillerSize = data.cabinet.calculateStartingFillerForSymmetry();

        const fillerSection = [{ sectionWidth: standardFillerSize, numberOfSections: 1, isFiller: true }];
        const endingFillerSection = [
            { sectionWidth: spaceLeftForFiller - standardFillerSize, numberOfSections: 1, isFiller: true },
        ];

        if (data.cabinet.type === Constants.cabinet.type.WALL) {
            //adds ending custom cabinet
            if (spaceLeftForFiller < Constants.availableCabinetDimensions[this.editor.unit][0]) {
                result[result.length - 1].sectionWidth += spaceLeftForFiller;
            }
            spaceLeftForFiller = 0;
            fillerSection[0].sectionWidth = 0;
        } else {
            if (!data.cabinet.cornerCabinetInfo) {
                result = fillerSection.concat(result);
                result = result.concat(endingFillerSection);
            } else {
                result[0].sectionWidth += standardFillerSize;
                result[0].sectionData.fillerSize = standardFillerSize;
                result = result.concat(endingFillerSection);
            }
            data.cabinet.setFillerData(true, standardFillerSize, spaceLeftForFiller - standardFillerSize);
        }
        this.postCalculationFixes(result, width, data.cabinet);
        return result;
    }

    handleFullLengthCabinet(data) {
        const positionInfo = data.cabinet.getPositionInfo();
        let width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let result = this.calculateSections(width, data);
        width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let spaceLeftForFiller = width - data.cabinet.filledWidth;
        let startingFillerSize = data.cabinet.calculateStartingFillerForSymmetry();
        if (startingFillerSize > spaceLeftForFiller) {
            startingFillerSize = Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
        }
        const standardFillerSize = startingFillerSize;
        const startingFillerSection = [{ sectionWidth: standardFillerSize, numberOfSections: 1, isFiller: true }];
        const endingFillerSection = [
            { sectionWidth: spaceLeftForFiller - standardFillerSize, numberOfSections: 1, isFiller: true },
        ];

        if (data.cabinet.type !== Constants.cabinet.type.WALL) {
            if (data.cabinet.cornerCabinetInfo) {
                result[0].sectionWidth += standardFillerSize;
                result[0].sectionData.fillerSize = standardFillerSize;
            }
        }
        if (data.cabinet.connectedCabinet) {
            if (data.cabinet.type !== Constants.cabinet.type.WALL) {
                data.cabinet.setFillerData(true, standardFillerSize, spaceLeftForFiller - standardFillerSize);
                if (positionInfo.cornerB && !data.cabinet.cornerCabinetInfo) {
                    result = startingFillerSection.concat(result);
                }
            } else {
                endingFillerSection[0].sectionWidth = spaceLeftForFiller;
                data.cabinet.setFillerData(false, 0, spaceLeftForFiller);
            }
        } else if (data.cabinet.secondConnectedCabinet) {
            if (data.cabinet.type !== Constants.cabinet.type.WALL) {
                data.cabinet.setFillerData(true, standardFillerSize, spaceLeftForFiller - standardFillerSize);
                if (positionInfo.cornerA && !data.cabinet.cornerCabinetInfo) {
                    result = startingFillerSection.concat(result);
                }
            } else {
                endingFillerSection[0].sectionWidth = spaceLeftForFiller;
                data.cabinet.setFillerData(false, 0, spaceLeftForFiller);
            }
        } else {
            startingFillerSection[0].sectionWidth = standardFillerSize;
            result = startingFillerSection.concat(result);
            data.cabinet.setFillerData(true, standardFillerSize, spaceLeftForFiller - standardFillerSize);
        }

        result = result.concat(endingFillerSection);
        data.cabinet.filledWidth += spaceLeftForFiller;
        this.postCalculationFixes(result, width, data.cabinet);

        return result;
    }

    handleCornerCabinetUseCase(data) {
        let width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let result = this.calculateSections(width, data);
        width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let spaceLeftForFiller = width - data.cabinet.filledWidth;
        let standardFillerSize = data.cabinet.calculateStartingFillerForSymmetry();
        const fillerSection = [{ sectionWidth: standardFillerSize, numberOfSections: 1, isFiller: true }];
        const endingFillerSection = [
            { sectionWidth: spaceLeftForFiller - standardFillerSize, numberOfSections: 1, isFiller: true },
        ];
        const cabinetPositionInfo = data.cabinet.getPositionInfo();

        if (
            (data.cabinet.cornerCabinetInfo.cornerIndex === Constants.corner.CORNER_A && cabinetPositionInfo.cornerB) ||
            (data.cabinet.cornerCabinetInfo.cornerIndex === Constants.corner.CORNER_B && cabinetPositionInfo.cornerA) ||
            data.cabinet.peninsulaConnection.peninsula
        ) {
            if (data.cabinet.type === Constants.cabinet.type.WALL) {
                data.cabinet.setFillerData(true, 0, spaceLeftForFiller);
                endingFillerSection[0].sectionWidth = spaceLeftForFiller;
            } else {
                result[0].sectionWidth += standardFillerSize;
                result[0].sectionData.fillerSize = standardFillerSize;
                data.cabinet.setFillerData(true, standardFillerSize, spaceLeftForFiller - standardFillerSize);
            }
            result = result.concat(endingFillerSection);
        } else if (data.cabinet.type === Constants.cabinet.type.WALL) {
            spaceLeftForFiller = 0;
            fillerSection[0].sectionWidth = 0;
        } else {
            data.cabinet.setFillerData(false, standardFillerSize, 0);
            result[0].sectionWidth += standardFillerSize;
            result[0].sectionData.fillerSize = standardFillerSize;
        }
        data.cabinet.filledWidth += spaceLeftForFiller;
        this.postCalculationFixes(result, width, data.cabinet);

        return result;
    }

    handleOneExtendedEndUseCase(data) {
        const cabinetPositionInfo = data.cabinet.getPositionInfo();
        let width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let result = this.calculateSections(width, data);
        width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let spaceLeftForFiller = width - data.cabinet.filledWidth;
        let standardFillerSize = data.cabinet.calculateStartingFillerForSymmetry();

        const fillerSection = [{ sectionWidth: standardFillerSize, numberOfSections: 1, isFiller: true }];
        const endingFillerSection = [
            { sectionWidth: spaceLeftForFiller - standardFillerSize, numberOfSections: 1, isFiller: true },
        ];

        if (data.cabinet.type !== Constants.cabinet.type.WALL) {
            if (cabinetPositionInfo.cornerA || cabinetPositionInfo.cornerB || data.cabinet.peninsulaConnection.peninsula) {
                if (data.cabinet.connectedCabinet) {
                    if (cabinetPositionInfo.cornerB) {
                        result = result.concat(endingFillerSection);
                        data.cabinet.filledWidth += spaceLeftForFiller;
                        data.cabinet.setFillerData(true, standardFillerSize, spaceLeftForFiller - standardFillerSize);
                    } else if (data.cabinet.peninsulaConnection.peninsula) {
                        result = result.concat(fillerSection);
                        data.cabinet.filledWidth += 2 * standardFillerSize;
                        data.cabinet.setFillerData(true, standardFillerSize, standardFillerSize);
                    } else {
                        data.cabinet.setFillerData(false, standardFillerSize, 0);
                        data.cabinet.filledWidth += standardFillerSize;
                    }
                } else if (data.cabinet.secondConnectedCabinet) {
                    if (cabinetPositionInfo.cornerA) {
                        result = result.concat(endingFillerSection);
                        data.cabinet.filledWidth += spaceLeftForFiller;
                        data.cabinet.setFillerData(true, standardFillerSize, spaceLeftForFiller - standardFillerSize);
                    } else if (data.cabinet.peninsulaConnection.peninsula) {
                        result = result.concat(fillerSection);
                        data.cabinet.filledWidth += 2 * standardFillerSize;
                        data.cabinet.setFillerData(true, standardFillerSize, standardFillerSize);
                    } else {
                        data.cabinet.setFillerData(false, standardFillerSize, 0);
                        data.cabinet.filledWidth += standardFillerSize;
                    }
                }
            } else {
                if (
                    (cabinetPositionInfo.cornerB && data.cabinet.connectedCabinet) ||
                    (cabinetPositionInfo.cornerA && data.cabinet.secondConnectedCabinet)
                ) {
                    data.cabinet.setFillerData(false, standardFillerSize, spaceLeftForFiller - standardFillerSize);
                    data.cabinet.filledWidth += spaceLeftForFiller - standardFillerSize;
                    result = result.concat(endingFillerSection);
                } else {
                    data.cabinet.setFillerData(false, standardFillerSize, 0);
                    data.cabinet.filledWidth += standardFillerSize;
                }
            }
            if (data.cabinet.type === Constants.cabinet.type.TALL && !data.cabinet.cornerCabinetInfo) {
                fillerSection[0].type = Constants.filler.type.BASE;
            }
            result = fillerSection.concat(result);
        } else {
            if (
                (data.cabinet.connectedCabinet && cabinetPositionInfo.cornerB) ||
                (data.cabinet.secondConnectedCabinet && cabinetPositionInfo.cornerA)
            ) {
                endingFillerSection[0].sectionWidth = spaceLeftForFiller;
                result = result.concat(endingFillerSection);
                data.cabinet.filledWidth += spaceLeftForFiller;
                data.cabinet.setFillerData(false, 0, spaceLeftForFiller);
            }
        }
        this.postCalculationFixes(result, width, data.cabinet);
        return result;
    }

    handleCabinetTouchingOneCorner(data) {
        let width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let result = this.calculateSections(width, data);
        width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);

        let spaceLeftForFiller = width - data.cabinet.filledWidth;
        let standardFillerSize = data.cabinet.calculateStartingFillerForSymmetry();
        const startingFillerSection = [{ sectionWidth: standardFillerSize, numberOfSections: 1, isFiller: true }];
        const endingFillerSection = [
            { sectionWidth: spaceLeftForFiller - standardFillerSize, numberOfSections: 1, isFiller: true },
        ];

        if (data.cabinet.peninsulaConnection.peninsula) {
            result = startingFillerSection.concat(result);
            result = result.concat(startingFillerSection);
            data.cabinet.setFillerData(true, standardFillerSize, standardFillerSize);
            data.cabinet.filledWidth += standardFillerSize * 2;
        } else if (data.cabinet.adaptToPreviousCabinet) {
            result = [{ sectionWidth: spaceLeftForFiller, numberOfSections: 1, isFiller: true }].concat(result);
            data.cabinet.filledWidth += spaceLeftForFiller;
            data.cabinet.setFillerData(true, spaceLeftForFiller, 0);
        } else {
            result = startingFillerSection.concat(result);
            data.cabinet.filledWidth += standardFillerSize;
            data.cabinet.setFillerData(false, standardFillerSize, 0);
            if (data.cabinet.baseCabinetUnderneath) {
                if (
                    [
                        data.cabinet.baseCabinetUnderneath.connectedCabinet?.type,
                        data.cabinet.baseCabinetUnderneath.secondConnectedCabinet?.type,
                    ].includes(Constants.cabinet.type.TALL) &&
                    !data.cabinet.baseCabinetUnderneath.cornerCabinetInfo
                ) {
                    result = result.concat(endingFillerSection);
                    data.cabinet.filledWidth += spaceLeftForFiller - standardFillerSize;
                    data.cabinet.setFillerData(true, standardFillerSize, spaceLeftForFiller - standardFillerSize);
                }
            }
        }
        this.postCalculationFixes(result, width, data.cabinet);
        return result;
    }

    handleWallCabinetNearTallCabinet(data) {
        let width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let result = this.calculateSections(width, data);
        width = Vector3.Distance(data.allowedSegment.firstPoint, data.allowedSegment.secondPoint);
        let spaceLeftForFiller = (width - data.cabinet.filledWidth) / 2;
        const fillerSection = [{ sectionWidth: spaceLeftForFiller, numberOfSections: 1, isFiller: true }];
        if (
            data.cabinet.baseCabinetUnderneath?.connectedCabinet?.type === Constants.cabinet.type.TALL ||
            data.cabinet.baseCabinetUnderneath?.secondConnectedCabinet?.type === Constants.cabinet.type.TALL
        ) {
            fillerSection[0].sectionWidth = Constants.blockedCornerArea.STANDARD_FILLER_SIZE;
            result = fillerSection.concat(result);
            data.cabinet.filledWidth += Constants.blockedCornerArea.STANDARD_FILLER_SIZE;
            data.cabinet.setFillerData(false, Constants.blockedCornerArea.STANDARD_FILLER_SIZE, 0);
        }
        this.postCalculationFixes(result, width, data.cabinet);
        return result;
    }

    calculateSections(width, data) {
        let obstacle = data.cabinet.obstacleManager.getObstacleNearCornerA();
        if (data.cabinet.assignedWall.replacementDirection.equals(data.cabinet.assignedWall.getDirection())) {
            obstacle = data.cabinet.obstacleManager.getObstacleNearCornerB();
        }

        if (obstacle?.type === Constants.appliance.type.HOOD) {
            obstacle = null;
        }

        const appliancesOnCabinet = this.sortAppliancesInReplacementOrder(data);
        let numberOfSectionsPerType = [];
        let widthToFill = width;
        let spaceLeftForFiller = data.cabinet.calculateSpaceNeededForFiller();

        if (data.cabinet.cornerCabinetInfo) {
            const cornerCabinetDimensions = this.handleCornerCabinetDimensions(data, appliancesOnCabinet);
            numberOfSectionsPerType.push(cornerCabinetDimensions);
            widthToFill = width - cornerCabinetDimensions.sectionWidth;
            data.cabinet.filledWidth += cornerCabinetDimensions.sectionWidth;
        }

        if (obstacle && data.cabinet.adaptToPreviousCabinet) {
            spaceLeftForFiller = Constants.STANDARD_FILLER_SIZE[this.editor.unit];

            const closestObstacle = this.getClosestObstacleSection(obstacle);
            const projectedLastSectionPosition = Utilities.projectPointOnWall(
                closestObstacle.meshComponent.getMesh().position,
                obstacle.assignedWall
            );
            const projectedCabinetPosition = Utilities.projectPointOnWall(
                data.cabinet.meshComponent.getMesh().position,
                data.cabinet.assignedWall
            );

            let addition =
                Vector3.Distance(projectedLastSectionPosition, projectedCabinetPosition) -
                data.cabinet.width / 2 -
                closestObstacle.width / 2;

            widthToFill += addition;
            data.cabinet.meshComponent.getMesh().scaling.x = (data.cabinet.width + addition) / data.cabinet.baseWidth;
            data.cabinet.width = data.cabinet.width + addition;
            data.cabinet.meshComponent.getMesh().position.addInPlace(data.replacementDirection.clone().scale(addition / 2));

            if (
                Vector3.Distance(data.allowedSegment.firstPoint, data.startingPoint) <
                Vector3.Distance(data.allowedSegment.secondPoint, data.startingPoint)
            ) {
                data.allowedSegment.secondPoint.addInPlace(data.replacementDirection.clone().scale(addition));
            } else {
                data.allowedSegment.firstPoint.addInPlace(data.replacementDirection.clone().scale(addition));
            }
        }

        if (appliancesOnCabinet.length === 0) {
            const result = numberOfSectionsPerType.concat(
                this.sectionCalculator.replaceEmptyCabinetSections(widthToFill, spaceLeftForFiller)
            );
            return result;
        } else {
            return this.sectionCalculator.replaceCabinetsWithAppliances(data, appliancesOnCabinet, numberOfSectionsPerType);
        }
    }

    getClosestObstacleSection(obstacle) {
        return obstacle.sections[obstacle.sections.length - 1];
    }

    sortAppliancesInReplacementOrder(data) {
        const parentCabinet = data.cabinet;
        const appliancesOnCabinet = parentCabinet.getAppliances();
        return appliancesOnCabinet.sort((a, b) => {
            return Vector3.Distance(a.position, data.startingPoint) > Vector3.Distance(b.position, data.startingPoint) ? 1 : -1;
        });
    }

    handleCornerCabinetDimensions(data) {
        const parentCabinet = data.cabinet;
        let cornerCabinetData = {
            sectionWidth: null,
            numberOfSections: 0,
            sectionData: { isCornerCabinet: false, fillerSize: 0 },
        };

        if (parentCabinet.cornerCabinetInfo) {
            let connectionWidth = parentCabinet.connectedCabinet?.depth;
            if (parentCabinet.cornerCabinetInfo.cornerIndex === Constants.corner.CORNER_B) {
                connectionWidth = parentCabinet.secondConnectedCabinet?.depth;
            }
            parentCabinet.calculateCornerCabinetDoorForSymmetry();
            let cornerCabinetWidth = Utilities.roundToPrecision(connectionWidth, 1000) + parentCabinet.getCornerCabinetDoor();
            cornerCabinetData = {
                sectionWidth: Utilities.roundToPrecision(cornerCabinetWidth, 1000),
                numberOfSections: 1,
                sectionData: { isCornerCabinet: true, fillerSize: 0, cornerIndex: parentCabinet.cornerCabinetInfo.cornerIndex },
            };
        }
        return cornerCabinetData;
    }

    postCalculationFixes(numberOfSectionsPerType) {
        const endingFiller = numberOfSectionsPerType[numberOfSectionsPerType.length - 1];
        if (
            endingFiller?.isFiller &&
            endingFiller.sectionWidth >= 0.1 /*Constants.availableCabinetDimensions[this.editor.unit][0]*/
        ) {
            for (let index = numberOfSectionsPerType.length - 2; index >= 0; index--) {
                if (!numberOfSectionsPerType[index]?.sectionData && numberOfSectionsPerType[index].numberOfSections !== 0) {
                    if (numberOfSectionsPerType[index].numberOfSections >= 1) {
                        numberOfSectionsPerType[index].numberOfSections -= 1;
                        const newWidth = Utilities.getClosestNumberInArray(
                            numberOfSectionsPerType[index].sectionWidth +
                                endingFiller.sectionWidth -
                                Constants.SMALLEST_FILLER_SIZE[this.editor.unit],
                            Constants.availableCabinetDimensions[this.editor.unit]
                        );
                        const newFiller = numberOfSectionsPerType[index].sectionWidth + endingFiller.sectionWidth - newWidth;
                        numberOfSectionsPerType.splice(index, 0, {
                            sectionWidth: newWidth,
                            numberOfSections: 1,
                        });
                        numberOfSectionsPerType[numberOfSectionsPerType.length - 1].sectionWidth = newFiller;
                        return;
                    }
                }
            }
        }
    }
}
