import { Mesh, SceneLoader, StandardMaterial, Texture } from '@babylonjs/core';
import { Utilities } from '../../Tools/utilities';
import { BaseAppliance } from './BaseAppliance';
export class Cooktop extends BaseAppliance {
    constructor() {
        super();
    }

    async setModel() {
        const result = await SceneLoader.ImportMeshAsync('', 'cooktop.babylon', '', this.editor.sceneComponent.get());
        switch (this.subtype) {
            case 'hobXSmall':
                this.meshComponent.setMesh(result.meshes[3]);
                this.addTexture('x_small');
                break;
            case 'hobStandard':
                this.meshComponent.setMesh(result.meshes[1]);
                this.addTexture('standard');
                break;
            case 'hobLarge':
                this.meshComponent.setMesh(result.meshes[2]);
                this.addTexture('large');
                break;
            case 'hobXLarge':
                this.meshComponent.setMesh(result.meshes[0]);
                this.addTexture('x_large');
                break;
        }
        this.meshComponent.getMesh().isVisible = true;
        this.meshComponent.getMesh().scaling.y = 4;
    }

    addTexture(diffuseTexture) {
        this.meshComponent.mesh.material = new StandardMaterial(
            'cooktopMaterial',
            this.editor.sceneComponent.get(),
            false,
            Mesh.DOUBLESIDE
        );
        this.meshComponent.mesh.material.diffuseTexture = new Texture(
            require('@/assets/textures/appliances/Cooktop/'.concat(diffuseTexture).concat('.jpg')),
            this.editor.sceneComponent.get()
        );
    }
}
