import { SceneLoader, Texture } from '@babylonjs/core';
import { Utilities } from '../../Tools/utilities';
import { BaseAppliance } from './BaseAppliance';
import store from '@/store/index.js';
export class Dishwasher extends BaseAppliance {
    constructor() {
        super();
    }

    async setModel() {
        const result = await SceneLoader.ImportMeshAsync('', 'dishwasher.babylon', '', this.editor.sceneComponent.get());
        this.meshComponent.setMesh(result.meshes[Utilities.getIndexFromMeshId('grouped_temp', result.meshes)]);
        this.resizeToCurrentDimensions();
        this.addTexture();
    }

    addTexture() {
        this.editor.PBRComponent.setPBRMetallicRoughnessToBuildingBlock(
            this.meshComponent.getMesh(),
            'Dishwasher',
            'appliances/'
        );
        let color = store.state.appliances.appliances[this.type].subtype[0].color.items[0];
        if (this.subtype === 'builtin') {
            color = store.state.appliances.appliances[this.type].subtype[0].color.items[1];
        }
        if (color === 'light') {
            const filePath = require('@/assets/textures/appliances/Dishwasher'.concat('/diffuseTextureLight.jpg'));
            this.meshComponent.getMesh().material.albedoTexture = new Texture(filePath, this.editor.sceneComponent.get());
        }
    }
}
