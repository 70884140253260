<template>
    <v-col
        class="d-flex flex-column justify-center flex-shrink-1 height"
        cols="4"
        md="3"
    >
        <v-btn
            color="green"
            class="white--text"
            :disabled="!isWallsClosed"
            :small="$vuetify.breakpoint.mdAndDown"
            @click="setStep(stepNumber)"
            >Next
        </v-btn>

        <resetBtn />
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import resetBtn from '@/components/resetBtn.vue';

export default {
    props: ['stepNumber'],
    components: {
        resetBtn,
    },
    computed: {
        ...mapGetters('core', ['editorEngine']),
        isWallsClosed() {
            return this.editorEngine.floorplan.isClosed;
        },
    },
    methods: {
        setStep(step) {
            this.$store.commit('core/setStep', step);
        },
    },
};
</script>
