import { mdiWindowClosedVariant, mdiDoor, mdiKeyboardSpace, mdiRectangleOutline } from '@mdi/js'

export default {
    window: {
        id: 'window',
        name: 'Window',
        icon: mdiWindowClosedVariant,
        type: {
            standard: {
                id: 'standard',
                name: 'Standard',
                img: require('@/assets/icons/fixtures/window-icons/icon-window-standard.png'),
                dimensions: {
                    width: {
                        value: {
                            mm: 1000,
                            in: 39.37,
                        },
                        min: {
                            mm: 300,
                            in: 11.81,
                        },
                        max: {
                            mm: 9000,
                            in: 354.33,
                        },
                    },
                    height: {
                        value: {
                            mm: 950,
                            in: 37.4,
                        },
                        min: {
                            mm: 100,
                            in: 3.94,
                        },
                        max: {
                            mm: 5000,
                            in: 196.85,
                        },
                    },
                    heightFromFloor: {
                        value: {
                            mm: 1100,
                            in: 43.31,
                        },
                        min: {
                            mm: 0,
                            in: 0,
                        },
                        max: {
                            mm: 3000,
                            in: 118.11,
                        },
                    },
                },
            },
            small: {
                id: 'small',
                name: 'Small',
                img: require('@/assets/icons/fixtures/window-icons/icon-window-small.png'),
                dimensions: {
                    width: {
                        value: {
                            mm: 1000,
                            in: 39.37,
                        },
                        min: {
                            mm: 300,
                            in: 11.81,
                        },
                        max: {
                            mm: 9000,
                            in: 354.33,
                        },
                    },
                    height: {
                        value: {
                            mm: 550,
                            in: 21.65,
                        },
                        min: {
                            mm: 100,
                            in: 3.94,
                        },
                        max: {
                            mm: 3000,
                            in: 118.11,
                        },
                    },
                    heightFromFloor: {
                        value: {
                            mm: 1050,
                            in: 41.34,
                        },
                        min: {
                            mm: 0,
                            in: 0,
                        },
                        max: {
                            mm: 3000,
                            in: 118.11,
                        },
                    },
                },
            },
            big: {
                id: 'big',
                name: 'Big',
                img: require('@/assets/icons/fixtures/window-icons/icon-window-big.png'),
                dimensions: {
                    width: {
                        value: {
                            mm: 1000,
                            in: 39.37,
                        },
                        min: {
                            mm: 300,
                            in: 11.81,
                        },
                        max: {
                            mm: 9000,
                            in: 354.33,
                        },
                    },
                    height: {
                        value: {
                            mm: 1650,
                            in: 64.96,
                        },
                        min: {
                            mm: 100,
                            in: 3.94,
                        },
                        max: {
                            mm: 3000,
                            in: 118.11,
                        },
                    },
                    heightFromFloor: {
                        value: {
                            mm: 800,
                            in: 31.5,
                        },
                        min: {
                            mm: 0,
                            in: 0,
                        },
                        max: {
                            mm: 3000,
                            in: 118.11,
                        },
                    },
                },
            },
        },
    },
    door: {
        id: 'door',
        name: 'Door',
        icon: mdiDoor,
        type: {
            standard: {
                id: 'standard',
                name: 'Standard',
                img: require('@/assets/icons/fixtures/door-icons/icon-doors-standard.png'),
                dimensions: {
                    width: {
                        value: {
                            mm: 800,
                            in: 31.5,
                        },
                        min: {
                            mm: 15,
                            in: 0.59,
                        },
                        max: {
                            mm: 9000,
                            in: 354.33,
                        },
                    },
                    height: {
                        value: {
                            mm: 2100,
                            in: 82.68,
                        },
                        min: {
                            mm: 0,
                            in: 0,
                        },
                        max: {
                            mm: 5000,
                            in: 196.85,
                        },
                    },
                },
            },
            sliding: {
                id: 'sliding',
                name: 'Sliding',
                img: require('@/assets/icons/fixtures/door-icons/icon-doors-sliding.png'),
                dimensions: {
                    width: {
                        value: {
                            mm: 800,
                            in: 31.5,
                        },
                        min: {
                            mm: 15,
                            in: 0.59,
                        },
                        max: {
                            mm: 9000,
                            in: 354.33,
                        },
                    },
                    height: {
                        value: {
                            mm: 2100,
                            in: 82.68,
                        },
                        min: {
                            mm: 0,
                            in: 0,
                        },
                        max: {
                            mm: 5000,
                            in: 196.85,
                        },
                    },
                },
            },
            balcony: {
                id: 'balcony',
                name: 'Balcony',
                img: require('@/assets/icons/fixtures/door-icons/icon-doors-balcony.png'),
                dimensions: {
                    width: {
                        value: {
                            mm: 800,
                            in: 31.5,
                        },
                        min: {
                            mm: 15,
                            in: 0.59,
                        },
                        max: {
                            mm: 9000,
                            in: 354.33,
                        },
                    },
                    height: {
                        value: {
                            mm: 2100,
                            in: 82.68,
                        },
                        min: {
                            mm: 0,
                            in: 0,
                        },
                        max: {
                            mm: 5000,
                            in: 196.85,
                        },
                    },
                },
            },
        },
    },
    openSpace: {
        id: 'openSpace',
        name: 'Open Space',
        icon: mdiKeyboardSpace,
        type: {
            standard: {
                id: 'standard',
                name: 'Standard',
                img: require('@/assets/icons/fixtures/icon-wall-open.png'),
                dimensions: {
                    width: {
                        value: {
                            mm: 800,
                            in: 31.5,
                        },
                        min: {
                            mm: 15,
                            in: 0.59,
                        },
                        max: {
                            mm: 9000,
                            in: 354.33,
                        },
                    },
                    height: {
                        value: {
                            mm: 2100,
                            in: 82.68,
                        },
                        min: {
                            mm: 0,
                            in: 0,
                        },
                        max: {
                            mm: 5000,
                            in: 196.85,
                        },
                    },
                    heightFromFloor: {
                        value: {
                            mm: 0,
                            in: 0,
                        },
                        min: {
                            mm: 0,
                            in: 0,
                        },
                        max: {
                            mm: 9000,
                            in: 354.33,
                        },
                    },
                },
            },
        },
    },
    fixedColumn: {
        id: 'fixedColumn',
        name: 'Fixed Column',
        icon: mdiRectangleOutline,
        type: {
            standard: {
                id: 'standard',
                name: 'Standard',
                img: require('@/assets/icons/fixtures/Fixed Column.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 200,
                            in: 7.87,
                        },
                        min: {
                            mm: 0,
                            in: 0,
                        },
                        max: {
                            mm: 5000,
                            in: 196.85,
                        },
                    },
                    depth: {
                        value: {
                            mm: 400,
                            in: 15.75,
                        },
                        min: {
                            mm: 0,
                            in: 0,
                        },
                        max: {
                            mm: 5000,
                            in: 196.85,
                        },
                    },
                },
            },
        },
    },
};
