import { MeshComponent } from '../MeshComponent';
import store from '@/store/index.js';
import { Constants } from '../../Tools/constants';
import { Color3, MeshBuilder, Vector3 } from '@babylonjs/core';
import { Utilities } from '../../Tools/utilities';
import { BaseAppliance } from '../../KitchenObjects/Appliances/BaseAppliance';
import { Text } from '../Text';

export class BaseMeasurementLine extends MeshComponent {
    currentWall;
    lineSystem;
    linkedMeshes = [];

    constructor() {
        super();
    }

    sortAppliancesInReplacementOrder(island, startingPoint, row) {
        const parentCabinet = island;
        let appliancesOnCabinet = parentCabinet.getAppliances();
        appliancesOnCabinet = appliancesOnCabinet.filter((appliance) => appliance.assignedRow === row);
        return appliancesOnCabinet.sort((a, b) => {
            return Vector3.Distance(a.position, startingPoint) > Vector3.Distance(b.position, startingPoint) ? 1 : -1;
        });
    }

    getMeasurementLinesSlots() {
        if (this.object.currentCabinet?.type === Constants.cabinet.type.ISLAND) {
            const rowEdges = this.object.currentCabinet.getEdges(this.object.assignedRow);
            rowEdges.leftPoint.y = 0;
            rowEdges.rightPoint.y = 0;
            const blockedSegmentOnIsland = this.object.getBlockedSegmentOnIsland();
            const appliancesOnIsland = this.sortAppliancesInReplacementOrder(
                this.object.currentCabinet,
                rowEdges.leftPoint,
                this.object.assignedRow
            );

            let currentApplianceIndex = 0;
            for (let index = 0; index < appliancesOnIsland.length; index++) {
                if (appliancesOnIsland[index] === this.object) {
                    currentApplianceIndex = index;
                }
            }
            const previousAppliance = currentApplianceIndex - 1 >= 0 ? appliancesOnIsland[currentApplianceIndex - 1] : null;
            const nextAppliance =
                currentApplianceIndex + 1 < appliancesOnIsland.length ? appliancesOnIsland[currentApplianceIndex + 1] : null;

            let closestObstacleToSecondPoint = rowEdges.leftPoint.clone();
            let closestObstacleToFirstPoint = rowEdges.rightPoint.clone();

            if (previousAppliance) {
                const previousApplianceEdges = previousAppliance.getBlockedSegmentOnIsland();
                closestObstacleToSecondPoint = previousApplianceEdges.firstPoint;
            }

            if (nextAppliance) {
                const nextApplianceEdges = nextAppliance.getBlockedSegmentOnIsland();
                closestObstacleToFirstPoint = nextApplianceEdges.secondPoint;
            }

            if (currentApplianceIndex === 0) {
                return [
                    { firstPoint: rowEdges.leftPoint, secondPoint: blockedSegmentOnIsland.secondPoint, fixture: null },
                    {
                        firstPoint: blockedSegmentOnIsland.secondPoint,
                        secondPoint: blockedSegmentOnIsland.firstPoint,
                        fixture: this.object,
                    },
                    { firstPoint: blockedSegmentOnIsland.firstPoint, secondPoint: rowEdges.rightPoint, fixture: null },
                ];
            } else {
                return [
                    { firstPoint: closestObstacleToSecondPoint, secondPoint: blockedSegmentOnIsland.secondPoint, fixture: null },
                    {
                        firstPoint: blockedSegmentOnIsland.secondPoint,
                        secondPoint: blockedSegmentOnIsland.firstPoint,
                        fixture: this.object,
                    },
                    { firstPoint: blockedSegmentOnIsland.firstPoint, secondPoint: closestObstacleToFirstPoint, fixture: null },
                ];
            }
        } else {
            let selectedCabinetBlockedSegment = this.object.getEdgesOnWall();
            let slotInfo = this.object.obstacleManager.findNearestObstacles();

            let closestFixtureFromAPoints;
            if (slotInfo.closestFixtureFromPointA) {
                closestFixtureFromAPoints = slotInfo.closestFixtureFromPointA.getEdgesOnWall();
            } else {
                closestFixtureFromAPoints = { firstPoint: null, secondPoint: null };
            }

            let allowedSegment;
            if (this.object instanceof BaseAppliance && this.object.currentCabinet) {
                let blockedSegment = this.object.currentCabinet.calculateAllowedSegment();
                allowedSegment = { cornerA: blockedSegment.firstPoint, cornerB: blockedSegment.secondPoint };
            } else {
                allowedSegment = {
                    cornerA: this.object.assignedWall.baseInnerCornerA,
                    cornerB: this.object.assignedWall.baseInnerCornerB,
                };
            }
            let closestPointFromA = Utilities.findClosestPoint(
                selectedCabinetBlockedSegment.firstPoint,
                selectedCabinetBlockedSegment.secondPoint,
                closestFixtureFromAPoints.firstPoint,
                closestFixtureFromAPoints.secondPoint,
                allowedSegment
            );

            let closestFixtureFromBPoints;
            if (slotInfo.closestFixtureFromPointB) {
                closestFixtureFromBPoints = slotInfo.closestFixtureFromPointB.getEdgesOnWall();
            } else {
                closestFixtureFromBPoints = { firstPoint: null, secondPoint: null };
            }

            let closestPointFromB = Utilities.findClosestPoint(
                selectedCabinetBlockedSegment.secondPoint,
                selectedCabinetBlockedSegment.firstPoint,
                closestFixtureFromBPoints.firstPoint,
                closestFixtureFromBPoints.secondPoint,
                allowedSegment
            );

            return [
                { firstPoint: closestPointFromA, secondPoint: selectedCabinetBlockedSegment.firstPoint, fixture: null },
                {
                    firstPoint: selectedCabinetBlockedSegment.firstPoint,
                    secondPoint: selectedCabinetBlockedSegment.secondPoint,
                    fixture: this.object,
                },
                { firstPoint: selectedCabinetBlockedSegment.secondPoint, secondPoint: closestPointFromB, fixture: null },
            ];
        }
    }

    addSlotLength(length) {
        const textBlock = new Text();
        textBlock.setValue(length);
        return textBlock;
    }

    build(lineCoordinates, position = 'top') {
        if (this.linkedMeshes.length === 0) {
            this.buildLinkedMesh(lineCoordinates);
        }
        this.updateLength(lineCoordinates, null, position);

        if (!this.lineSystem) {
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        } else {
            this.lineSystem.dispose();
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        }
        this.lineSystem.color = new Color3(0, 0, 0);
        this.mesh = this.lineSystem;
    }

    buildLinkedMesh(slots) {
        for (let index = 0; index < slots.length; index++) {
            let length = Vector3.Distance(slots[index][0], slots[index][1]).toFixed(3) * 1000;
            this.linkedMeshes[index] = {
                linkedMesh: MeshBuilder.CreateBox('linkedMesh', { width: 0.1, height: 0.1, depth: 0.1 }),
                linkedMeshPosition: Utilities.findMiddlePointBetween(slots[index][0], slots[index][1]),
                length: length,
                lengthField: this.addSlotLength(length).textBlock,
            };
            this.linkedMeshes[index].linkedMesh.setEnabled(false);
        }
        return;
    }

    updateLength(slots, value = null, position = 'top') {
        for (let index = 0, linkedMeshIndex = 0; index < slots.length; index += 3, linkedMeshIndex++) {
            this.linkedMeshes[linkedMeshIndex].linkedMesh.dispose();
            this.linkedMeshes[linkedMeshIndex].linkedMesh = MeshBuilder.CreateBox('linkedMesh', {
                width: 0.1,
                height: 0.1,
                depth: 0.1,
            });
            this.linkedMeshes[linkedMeshIndex].linkedMesh.setEnabled(false);

            this.linkedMeshes[linkedMeshIndex].linkedMeshPosition = Utilities.findMiddlePointBetween(
                slots[index][0],
                slots[index][1]
            );
            this.linkedMeshes[linkedMeshIndex].linkedMesh.position = this.linkedMeshes[linkedMeshIndex].linkedMeshPosition;

            this.linkedMeshes[linkedMeshIndex].length =
                Vector3.Distance(slots[index][0], slots[index][1]).toFixed(3) * Constants.fixture.scale;
            if (store.getters['core/selectedUnit'] === 'in') {
                if (value && linkedMeshIndex === 1) {
                    this.linkedMeshes[linkedMeshIndex].length = value;
                } else {
                    this.linkedMeshes[linkedMeshIndex].length = (
                        this.linkedMeshes[linkedMeshIndex].length / Constants.unit.INCH_STEP
                    ).toFixed(3);
                }
            }

            let precision = 100;
            if (this.object.editor.sceneInfo.unit === 'in') {
                precision = 10;
            }

            const lengthToPrecision = Utilities.roundToPrecision(
                parseFloat(this.linkedMeshes[linkedMeshIndex].length),
                precision
            );

            this.linkedMeshes[linkedMeshIndex].lengthField.text = lengthToPrecision.toString();
            store.getters['core/editorEngine'].widgetUI.addControl(this.linkedMeshes[linkedMeshIndex].lengthField);

            this.linkedMeshes[linkedMeshIndex].lengthField.linkWithMesh(this.linkedMeshes[linkedMeshIndex].linkedMesh);
            if (position === 'bottom') {
                if (slots[index][0].y === slots[index][1].y) {
                    this.linkedMeshes[linkedMeshIndex].lengthField.linkOffsetY = 10;
                } else {
                    this.linkedMeshes[linkedMeshIndex].lengthField.linkOffsetX = -20;
                }
            } else {
                if (slots[index][0].y === slots[index][1].y) {
                    this.linkedMeshes[linkedMeshIndex].lengthField.linkOffsetY = -10;
                } else {
                    this.linkedMeshes[linkedMeshIndex].lengthField.linkOffsetX = -20;
                }
            }

            this.linkedMeshes[linkedMeshIndex] = {
                linkedMesh: this.linkedMeshes[linkedMeshIndex].linkedMesh,
                linkedMeshPosition: this.linkedMeshes[linkedMeshIndex].linkedMeshPosition,
                length: this.linkedMeshes[linkedMeshIndex].length,
                lengthField: this.linkedMeshes[linkedMeshIndex].lengthField,
            };
        }
    }

    //Measurement line shift for island objects
    getMeasurementLineShiftData(firstPointClone, secondPointClone, parentCabinet = this.object.parentCabinet) {
        let directionConstant = 1;
        if (this.object.assignedRow === 'secondRow') {
            directionConstant = -1;
        }
        let shiftingCoordinate;
        if (parentCabinet.rotation === 90) {
            shiftingCoordinate = 'x';
        } else if (parentCabinet.rotation === 270) {
            shiftingCoordinate = 'x';
            directionConstant *= -1;
        } else if (parentCabinet.rotation === 0) {
            shiftingCoordinate = 'z';
        } else if (parentCabinet.rotation === 180) {
            shiftingCoordinate = 'z';
            directionConstant *= -1;
        }
        firstPointClone[shiftingCoordinate] -=
            (directionConstant * this.object.depth) / 2 + directionConstant * Constants.cabinet.measurementLine.SHIFTING;
        secondPointClone[shiftingCoordinate] -=
            (directionConstant * this.object.depth) / 2 + directionConstant * Constants.cabinet.measurementLine.SHIFTING;
        return {
            firstPointClone: firstPointClone,
            secondPointClone: secondPointClone,
        };
    }

    dispose() {
        if (this.mesh) {
            this.mesh.dispose();
            for (let index = 0; index < this.linkedMeshes.length; index++) {
                this.linkedMeshes[index].linkedMesh.dispose();
                this.linkedMeshes[index].lengthField.dispose();
            }
        }
    }

    update(value = false) {
        this.dispose();
        this.build(value);
    }

    updateForPrint() {
        this.dispose();
        this.buildForPrint();
    }
}
