import { PBRMaterial, SceneLoader, Texture } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { BaseAppliance } from './BaseAppliance';
import store from '@/store/index.js';
export class Sink extends BaseAppliance {
    constructor() {
        super();
    }

    async setModel() {
        let fileName;
        let parentNodeId;
        if (this.type === Constants.appliance.type.SINK) {
            if (this.subtype === 'oneBowl') {
                fileName = 'oneBowl.babylon';
                parentNodeId = 'oneBowl';
            } else if (this.subtype === 'oneBowlSide') {
                fileName = 'oneBowlSide.babylon';
                parentNodeId = 'oneBowlSide';
            } else if (this.subtype === 'twoBowls') {
                fileName = 'twoBowl.babylon';
                parentNodeId = 'twoBowl';
            } else if (this.subtype === 'twoBowlsSide') {
                fileName = 'twoBowlSide.babylon';
                parentNodeId = 'twoBowlSide';
            } else if (this.subtype === 'twoBowlsOneSmallSide') {
                fileName = 'twoBowlsOneSmallSide.babylon';
                parentNodeId = 'twoBowlsOneSmallSide';
            }
        }
        const result = await SceneLoader.ImportMeshAsync('', fileName, '', this.editor.sceneComponent.get());
        this.meshComponent.setMesh(result.meshes[Utilities.getIndexFromMeshId(parentNodeId, result.meshes)]);
        this.addTexture();
        return { fileName: fileName, parentNodeId: parentNodeId };
    }

    fillEmptyDimensionsFromVue() {
        if (this.subtype === 'oneBowl') {
            this.width = 0.6;
            if (this.editor.unit === 'in') {
                this.width = 0.609;
            }
            this.height = 0.197;
            this.depth = 0.5;
        } else if (this.subtype === 'twoBowls') {
            this.width = 0.9;
            if (this.editor.unit === 'in') {
                this.width = 0.9144;
            }
            this.height = 0.165;
            this.depth = 0.439;
        } else if (this.subtype === 'oneBowlSide') {
            this.width = 0.9;
            if (this.editor.unit === 'in') {
                this.width = 0.9144;
            }
            this.height = 0.178;
            this.depth = 0.5;
        } else if (this.subtype === 'twoBowlsSide') {
            this.width = 0.9;
            if (this.editor.unit === 'in') {
                this.width = 0.9144;
            }
            this.height = 0.178;
            this.depth = 0.5;
        } else if (this.subtype === 'twoBowlsOneSmallSide') {
            this.width = 0.6;
            if (this.editor.unit === 'in') {
                this.width = 0.609;
            }
            this.height = 0.178;
            this.depth = 0.5;
        }
    }

    addTexture() {
        this.editor.PBRComponent.setPBRMetallicRoughnessToBuildingBlock(this.meshComponent.getMesh(), 'Sink', 'appliances/');
        let color = store.state.appliances.appliances[this.type].subtype[0].color.items[0];

        if (color === 'light') {
            const filePath = require('@/assets/textures/appliances/Sink'.concat('/diffuseTextureLight.jpg'));
            this.meshComponent.getMesh().material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        }
    }
}
