import { StandardMaterial, Color3 } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { BaseKitchenObject } from '../BaseKitchenObject';
import { SceneComponent } from '../../Components/SceneComponent';

export class ColumnObject extends BaseKitchenObject {
    constructor() {
        super();
    }

    setDimensions(width, depth) {
        this.width = width;
        this.height = Constants.room.dimensions.HEIGHT;
        this.depth = depth;
    }

    setMaterial() {
        this.mesh.material = new StandardMaterial('material', this.object.getComponentByType(SceneComponent).get());
        this.mesh.material.diffuseColor = new Color3(0, 0, 0);
        // this.mesh.material.diffuseTexture = new Texture(
        //     "https://localhost/kw-testing/assets/column.png",
        //     this.object.getComponentByType(SceneComponent).get()
        // );
    }
}
