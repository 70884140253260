export class BaseObject {
    components;
    editor;

    constructor() {
        this.components = [];
    }

    registerComponent(ComponentType) {
        let component = new ComponentType();
        component.setObject(this);
        this.components.push(component);
        return component;
    }

    getComponentByType(type) {
        for (let index = 0; index < this.components.length; index++) {
            if (this.components[index] instanceof type) {
                return this.components[index];
            }
        }
        return null;
    }

    getComponentsByType(type) {
        let components = [];
        for (let index = 0; index < this.components.length; index++) {
            if (this.components[index] instanceof type) {
                components.push(this.components[index]);
            }
        }
        return components;
    }

    removeComponent(component) {
        for (let index = 0; index < this.components.length; index++) {
            if (component === this.components[index]) {
                this.components[index].unregister();
                this.components.splice(index, 1);
            }
        }
    }
}
