import SuperTokens from 'supertokens-website';
import store from '@/store/index.js';
import { apolloProvider } from '@/graphql/vue-apollo';
import queryBusinessDomainInfo from '@/graphql/queries/queryBusinessDomainInfo.gql';

export const authGuard = async (to, from, next) => {
    const isStandardDomain = window.location.hostname === process.env.VUE_APP_STANDARD_EDITOR_HOSTNAME;
    store.commit('business/toggleStandardDomain', isStandardDomain);

    if (!isStandardDomain) {
        store.commit('core/togglePageLoader', true);
        const businessInfo = await apolloProvider.defaultClient
            .query({
                query: queryBusinessDomainInfo,
                variables: {
                    hostname: window.location.hostname,
                },
                fetchPolicy: 'no-cache',
            })
            .then((data) => {
                return data.data;
            }).catch(error => {
                console.error(error);
                store.commit('core/logErrors', JSON.stringify(error.message));

            })

        console.log('businessInfo', businessInfo.queryBusinessDomainInfo);
        store.commit('business/setBusinessInfo', businessInfo.queryBusinessDomainInfo);
        store.commit('core/togglePageLoader', false);
        
        return next();
    }
    
    if ((await SuperTokens.doesSessionExist()) || !isStandardDomain) {
        return next();
    } else {
        window.open(`${process.env.VUE_APP_DASHBOARD_DOMAIN}/login`, '_self');
    }
};
