import { Vector3, MeshBuilder, StandardMaterial, DynamicTexture } from '@babylonjs/core';

const textureResRatio = 512;
export class FixturePart {
    constructor(size, offset, colors, clickable, parent) {
        this.parent = parent;
        this.scene = this.parent.scene;
        this.size = size;
        this.offset = offset;
        this.colors = colors;

        this.dt = new DynamicTexture(
            this.parent.type + '_Part-dt:' + this.parent.parts.length,
            {
                width: size.x * textureResRatio,
                height: size.y * textureResRatio,
            },
            this.scene
        );

        this.ctx = this.dt.getContext();

        this.plane = new MeshBuilder.CreatePlane(
            this.parent.type + '_Part-plane:' + this.parent.parts.length,
            {
                width: size.x,
                height: size.y,
            },
            this.scene
        );

        this.plane.root = this;
        this.plane.parent = this.parent.parent.fixtureParentNode;

        this.material = new StandardMaterial(this.parent.type + '_Part-mat:' + this.parent.parts.length, this.scene);

        this.material.disableLighting = true;
        this.material.emissiveTexture = this.dt;
        this.material.diffuseTexture = this.dt;
        this.material.diffuseTexture.hasAlpha = true;

        if (clickable) {
            this.plane.isFixture = true;
        }

        this.plane.material = this.material;

        if (this.parent.parts.length > 0) {
            this.plane.parent = this.parent.parts[0].plane;
            this.plane.position.addInPlace(new Vector3(this.offset.x, this.offset.y, 0));
        }
    }

    dispose() {
        this.plane.dispose();
        this.material.dispose();
        this.dt.dispose();
    }
}
