import { ActionManager, ExecuteCodeAction, Vector3, Tools, MeshBuilder, StandardMaterial } from '@babylonjs/core';
import { line_intersect } from '../../Tools/Utilities';
import { TextPlaneWidget } from '../../Widgets';
import { Mode } from '../../Tools/Mode';
import { SegmentOrientation } from '../../Tools/SegmentOrientation';

export class FloorplanCornerCap {
    constructor(segmentA, segmentB, parent) {
        this.parent = parent;
        this.uID = 'CornerCap:' + Date.now();

        this.segmentA = segmentA;
        this.segmentB = segmentB;

        this.points = [];

        this.angle = 0;

        this.deg = 0;
        this.calculatedAngle = 0;

        this.isInsideAngle = this.segmentA.hasAOffset && this.segmentB.hasBOffset;
        this.degree = this.calculateAngle();

        let segAPoly = this.segmentA.getRect();
        let segAtr = new Vector3(segAPoly[2], segAPoly[3], this.segmentA.b.z);
        let segAbr = new Vector3(segAPoly[4], segAPoly[5], this.segmentA.b.z);

        let segBPoly = this.segmentB.getRect();
        let segBtl = new Vector3(segBPoly[0], segBPoly[1], this.segmentB.b.z);
        this.points = [];
        let scene = this.parent.parent.scene;
        this.mesh = MeshBuilder.CreatePlane('plane', {
            height: 0.4,
            width: 0.4,
        });
        this.mesh.enablePointerMoveEvents = true;
        this.mesh.metadata = { isCorner: true };

        if (this.deg == 90) {
            this.points.push(this.segmentA.b.clone(), segAtr);
            this.points.push(this.points[1].add(this.segmentB.getUp().scale(this.parent.wallThickness)));
            this.points.push(segBtl);
        } else {
            let a = this.isInsideAngle ? this.segmentA.getBWithOffset() : segAtr;
            let b = a.add(this.segmentA.getLeft().scale(-this.parent.wallThickness));
            let c = this.isInsideAngle ? this.segmentB.getAWithOffset() : segBtl;
            let d = c.add(this.segmentB.getLeft().scale(this.parent.wallThickness));

            let intersect = line_intersect([a.x, a.y, b.x, b.y], [c.x, c.y, d.x, d.y]);

            this.points.push(this.segmentA.b);
            this.points.push(a);

            if (intersect) {
                this.points.push(new Vector3(intersect.x, intersect.y, this.segmentA.b.z));
            } else {
                this.points.push(b, d);
            }
            this.points.push(c);
        }
        this.calculatedAngle = this.isInsideAngle ? 360 - this.degree : this.degree;
        this.widget = new TextPlaneWidget(
            () => {
                return this.calculatedAngle + '°';
            },
            { positionValue: this.getCenter(), width: 40, height: 40 },
            this
        );
        this.showCornerCap(false);

        this.mesh.position = this.getCenter();
        this.mesh.position.z = -1;

        let mat = new StandardMaterial('mat', this.scene);
        mat.alpha = 0;
        this.mesh.material = mat;

        this.update();
        this.bindings();
    }

    bindings() {
        const actionManager = new ActionManager(this.scene);
        this.mesh.actionManager = actionManager;

        actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPointerOverTrigger,
                },
                () => {
                    this.parent.showAllCornerCaps(true);
                }
            )
        );

        actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPointerOutTrigger,
                },
                () => {
                    if (this.getEditorCore().controls.mode !== Mode.MOVING_CORNER) {
                        this.parent.showAllCornerCaps(false);
                    }
                }
            )
        );

        actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPickDownTrigger,
                },
                () => {
                    this.getEditorCore().controls.selectedItem = this;
                    this.parent.showAllCornerCaps(true);
                    this.getEditorCore().controls.lastMovementPoint = null;
                    this.getEditorCore().controls.mode = Mode.MOVING_CORNER;
                }
            )
        );
    }

    calculateAngle() {
        const firstAxis = this.segmentA.b.subtract(this.segmentA.a).normalize();
        const secondAxis = this.segmentB.a.subtract(this.segmentB.b).normalize();

        this.angle = Math.acos(Vector3.Dot(firstAxis, secondAxis));
        this.deg = Math.abs(this.getDeg());
        this.isInsideAngle = this.segmentA.hasAOffset && this.segmentB.hasBOffset;

        let angle = Math.round(Tools.ToDegrees(this.angle));

        this.calculatedAngle = this.isInsideAngle ? 360 - angle : angle;
        return angle;
    }

    showCornerCap(show) {
        this.widget.parts.textBlock.color = 'black';
        this.widget.parts.rect.background = 'yellow';
        this.widget.parts.rect.isVisible = show;
        this.widget.parts.textBlock.isVisible = show;
    }

    findCurrentCornerCapIndex() {
        let currentCornerCapIndex;

        for (let i = 0; i < this.getEditorCore().floorplan.cornerCaps.length; i++) {
            if (this.getEditorCore().floorplan.cornerCaps[i] === this) {
                currentCornerCapIndex = i + 1;

                if (currentCornerCapIndex == this.getEditorCore().floorplan.cornerCaps.length) {
                    currentCornerCapIndex = 0;
                }
                break;
            }
        }
        return currentCornerCapIndex;
    }

    calculatePreviousSnappingPointIndex(currentCornerCapIndex) {
        if (currentCornerCapIndex === 0) {
            return this.getEditorCore().floorplan.cornerCaps.length - 1;
        } else {
            return currentCornerCapIndex - 1;
        }
    }

    calculateNextSnappingPointIndex(currentCornerCapIndex) {
        if (currentCornerCapIndex === this.getEditorCore().floorplan.cornerCaps.length - 1) {
            return 0;
        } else {
            return currentCornerCapIndex + 1;
        }
    }

    handlePreviousSnapping(newPosition) {
        const segmentAOrientation = this.segmentA.findSegmentOrientation();
        const controls = this.getEditorCore().controls;

        if (segmentAOrientation === SegmentOrientation.HORIZONTAL) {
            controls.showHorizontalAxisLine(newPosition);
            controls.verticalAxisMesh.setEnabled(false);
        } else {
            controls.showVerticalAxisLine(newPosition);
            controls.horizontalAxisMesh.setEnabled(false);
        }
    }

    handleNextSnapping(newPosition, previousSnappingPointInfo, previousSnappingPoint) {
        const controls = this.getEditorCore().controls;
        const segmentBOrientation = this.segmentB.findSegmentOrientation();

        if (segmentBOrientation === SegmentOrientation.HORIZONTAL) {
            controls.showHorizontalAxisLine(newPosition);
            if (!previousSnappingPointInfo && newPosition !== previousSnappingPoint) {
                controls.verticalAxisMesh.setEnabled(false);
            }
        } else {
            controls.showVerticalAxisLine(newPosition);
            if (!previousSnappingPointInfo && newPosition !== previousSnappingPoint) {
                controls.horizontalAxisMesh.setEnabled(false);
            }
        }
    }

    move(position) {
        const controls = this.getEditorCore().controls;
        const currentCornerCapIndex = this.findCurrentCornerCapIndex();

        const nextSnappingPointIndex = this.calculateNextSnappingPointIndex(currentCornerCapIndex);
        const previousSnappingPointIndex = this.calculatePreviousSnappingPointIndex(currentCornerCapIndex);

        let newPosition = this.segmentB.a.add(position);

        const previousSnappingPoint = this.getEditorCore().testPointForSnapWhileDragging(newPosition, previousSnappingPointIndex);
        const previousSnappingPointInfo = controls.isSnapping;

        const nextSnappingPoint = this.getEditorCore().testPointForSnapWhileDragging(newPosition, nextSnappingPointIndex);
        const nextSnappingPointInfo = controls.isSnapping;

        if (
            (previousSnappingPointInfo && newPosition === previousSnappingPoint) ||
            (nextSnappingPointInfo && newPosition === nextSnappingPoint)
        ) {
            if (previousSnappingPointInfo && newPosition === previousSnappingPoint) {
                newPosition = previousSnappingPoint;
                this.handlePreviousSnapping(newPosition);
            }

            if (nextSnappingPointInfo && newPosition === nextSnappingPoint) {
                newPosition = nextSnappingPoint;
                this.handleNextSnapping(newPosition, previousSnappingPointInfo, previousSnappingPoint);
            }
        } else {
            controls.verticalAxisMesh.setEnabled(false);
            controls.horizontalAxisMesh.setEnabled(false);
        }

        this.segmentB.move(newPosition, this.segmentB.b, false);
    }

    get scene() {
        return this.getEditorCore().scene;
    }

    getAngle() {
        return this.segmentB.getCleanAngle() - this.segmentA.getCleanAngle();
    }

    getDeg() {
        let deg = (this.getAngle() / Math.PI) * 180;
        if (deg > 180) {
            deg -= 180;
        } else if (deg < -180) {
            deg += 180;
        }
        return Math.round(deg);
    }

    update() {
        this.widget.update();
    }

    getCenter() {
        let t = Vector3.Zero();
        this.points.forEach((p) => {
            t.addInPlace(p);
        });
        return t.scale(1 / this.points.length);
    }

    getEditorCore() {
        return this.parent.getEditorCore();
    }
}
