import { Component } from '../../../Components/Base/Component';
import { RoomObject } from '../../Room/RoomObject';
import { Constants } from '../../../Tools/constants';
import { Utilities } from '../../../Tools/utilities';
import { Vector3 } from '@babylonjs/core';
import store from '@/store/index.js';

export class MovementManager extends Component {
    constructor() {
        super();
    }

    followCursor() {
        this.object.editor.selectedItem = this.object;
        this.object.editor.resizing = false;
        this.object.editor.resizingIsland = false;
        this.object.editor.selectedItem.state = 'moving';
        store.commit('cabinets/setSelectedCabinet', this.object);
    }

    slideOnWall(pickedPoint) {
        const projectedPoint = Utilities.getPointOnLine({
            cornerA: this.object.assignedWall.baseInnerCornerA,
            cornerB: this.object.assignedWall.baseInnerCornerB,
            point: pickedPoint,
            shouldCalculateWallWidth: true,
            width: this.object.width,
        });

        if (projectedPoint) {
            this.object.position.x = projectedPoint.x;
            this.object.position.z = projectedPoint.z;

            this.object.meshComponent.getMesh().position = this.object.position.clone();
            this.object.rotateCabinet();
            this.object.shiftCabinet();
        }
    }

    move(pickedPoint) {
        if (this.object.type === Constants.cabinet.type.ISLAND) {
            let dimensions = {
                width: this.object.width,
                depth: this.object.depth,
            };
            if (this.object.rotation === 90 || this.object.rotation === 270) {
                dimensions.width = this.object.depth;
                dimensions.depth = this.object.width;
            }

            if (Utilities.isInside(pickedPoint, this.object.editor.getObjectByType(RoomObject).cornerPositions, dimensions)) {
                this.object.position.x = pickedPoint.x;
                this.object.position.z = pickedPoint.z;
                this.object.meshComponent.getMesh().position = this.object.position;
            }
        } else {
            this.object.extendedCabinetManager.disconnectExtendedCabinet('connectedCabinet');
            this.object.extendedCabinetManager.disconnectExtendedCabinet('secondConnectedCabinet');
            this.slideOnWall(pickedPoint);
        }
        this.object.measurementLines.update();
        if (this.object.type === Constants.cabinet.type.ISLAND && !this.object.isClone) {
            this.object.distanceMeasurements.update();
        }
    }

    handleCabinetMovement(pick) {
        let cabinet = this.object;
        this.resetSectionsInformation(cabinet);
        let cabinetCloneData = cabinet.createCabinetClone();
        this.interpolateMovement(pick, cabinetCloneData, cabinet);
        cabinetCloneData.dispose();
    }

    resetSectionsInformation(selectedCabinet) {
        if (
            (selectedCabinet.type === 'island' && selectedCabinet?.sectionsInformation?.firstRow) ||
            (selectedCabinet.type === 'island' && selectedCabinet?.sectionsInformation?.secondRow)
        ) {
            selectedCabinet.sectionsInformation.firstRow = [];
            selectedCabinet.sectionsInformation.secondRow = [];

        }

        selectedCabinet.sectionsInformation = [];
        if (selectedCabinet.connectedCabinet) {
            selectedCabinet.connectedCabinet.sectionsInformation = [];
        }

        if (selectedCabinet.secondConnectedCabinet) {
            selectedCabinet.secondConnectedCabinet.sectionsInformation = [];
        }
    }

    interpolateMovement(pick, cabinetCloneData, cabinet) {
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        let projectedPick;
        let movingDistance = 0;
        let interpolationScale = 1;
        if (cabinet.type === Constants.cabinet.type.ISLAND) {
            projectedPick = pick.pickedPoint;
            projectedPick.y = 0;
            movingDistance = Vector3.Distance(projectedPick, cabinetCloneData.meshComponent.mesh.position);
        } else {
            projectedPick = Utilities.projectPointOnWall(pick.pickedPoint, cabinetCloneData.assignedWall);
            movingDistance = Vector3.Distance(projectedPick, cabinetCloneData.meshComponent.mesh.position);
            interpolationScale = 1 / (movingDistance * 10);
        }
        let startingPoint = cabinetCloneData.meshComponent.mesh.position.clone();
        for (let index = 0; interpolationScale <= 1; index++, interpolationScale += 1 / (movingDistance * 10)) {
            const closestWall = Utilities.findClosestWall(pick.pickedPoint, roomObject.walls);
            if (
                this.object.type !== Constants.cabinet.type.ISLAND &&
                (cabinetCloneData.assignedWall === null ||
                    closestWall.id !== cabinetCloneData.assignedWall?.id ||
                    Utilities.isInside(pick.pickedPoint, roomObject.cornerPositions, {
                        width: cabinetCloneData.width,
                        depth: cabinetCloneData.depth,
                    }))
            ) {
                cabinetCloneData.assignedWall = Utilities.findClosestWall(pick.pickedPoint, roomObject.walls);
                projectedPick = Utilities.projectPointOnWall(pick.pickedPoint, cabinetCloneData.assignedWall);
            }
            cabinetCloneData.movementManager.move(Vector3.Lerp(startingPoint, projectedPick, interpolationScale));

            if (
                !cabinetCloneData.obstacleManager.checkForObstacles(Constants.fixture.margin) &&
                !cabinet.obstacleManager.checkForCabinetObstacles(cabinetCloneData)
            ) {
                cabinet.assignedWall = cabinetCloneData.assignedWall;
                cabinet.movementManager.move(cabinetCloneData.meshComponent.mesh.position);
                if (cabinet.type === Constants.cabinet.type.ISLAND) {
                    cabinet.distanceMeasurements.checkForNearestObstacles();
                    cabinet.snapOnCabinetEnd();
                }
            }
        }
        cabinet.position = cabinet.meshComponent.getPosition().clone();
    }
}
