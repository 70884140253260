import store from '@/store/index.js';
import { RoomObject } from '../../Room/RoomObject';
import { Component } from '../../../Components/Base/Component';
import { Constants } from '../../../Tools/constants';
import { Utilities } from '../../../Tools/utilities';
export class ExtendedCabinetManager extends Component {
    constructor() {
        super();
    }

    addNewCornerCabinet(connectedCabinetCorner, cornerIndex) {
        const selectedCabinet = this.object;
        let newCabinetAssignedWall = this.getExtendedCabinetWall(connectedCabinetCorner);

        if (newCabinetAssignedWall) {
            const newCabinetData = {
                name: selectedCabinet.name,
                type: selectedCabinet.type,
                dimensions: {
                    width:
                        Constants.standardDimensions[selectedCabinet.type].WIDTH[this.object.editor.unit] *
                        Constants.fixture.scale,
                    depth: selectedCabinet.depth * Constants.fixture.scale,
                    height: selectedCabinet.height * Constants.fixture.scale,
                    heightFromFloor: selectedCabinet.heightFromFloor * Constants.fixture.scale,
                },
                currentUnit: store.getters['core/selectedUnit'],
                state: 'new corner cabinet',
                wall: newCabinetAssignedWall.id,
                connectedCabinetCorner: connectedCabinetCorner,
                cornerIndex: cornerIndex,
            };

            const newCabinet = store.getters['core/editorEngine'].generateCabinet(newCabinetData);
            return newCabinet;
        }
    }

    getExtendedCabinetWall(connectedCabinetCorner) {
        //returns null if cabinet extension is not allowed
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        const roomCorners = roomObject.cornerPositions.concat(roomObject.cornerPositions[0]);
        const selectedCabinet = this.object;

        const adjacentWallsIds = Utilities.getAdjacentWallsIds(selectedCabinet.assignedWall.id, roomObject.walls);
        const leftWall = roomObject.walls[adjacentWallsIds.leftWall];
        const rightWall = roomObject.walls[adjacentWallsIds.rightWall];

        let newCabinetAssignedWall;
        let cornerAngle;
        if (
            connectedCabinetCorner.equals(leftWall.baseInnerCornerA) ||
            connectedCabinetCorner.equals(leftWall.baseInnerCornerB)
        ) {
            newCabinetAssignedWall = leftWall;
            cornerAngle = Utilities.angleBetweenWalls(newCabinetAssignedWall, selectedCabinet.assignedWall, roomCorners);
        } else if (
            connectedCabinetCorner.equals(rightWall.baseInnerCornerA) ||
            connectedCabinetCorner.equals(rightWall.baseInnerCornerB)
        ) {
            newCabinetAssignedWall = rightWall;
            cornerAngle = Utilities.angleBetweenWalls(selectedCabinet.assignedWall, newCabinetAssignedWall, roomCorners);
        }

        if (cornerAngle !== 90) {
            return null;
        } else {
            return newCabinetAssignedWall;
        }
    }

    showCornerCabinets() {
        let doorWidth = Constants.baseCornerCabinet.DOOR_SIZE[this.object.editor.unit];
        if (['tall', 'base'].includes(this.object.type)) {
            doorWidth = Constants.wallCornerCabinet.DOOR_SIZE[this.object.editor.unit];
        }
        if (this.object.width <= this.object.depth + doorWidth) {
            return;
        }
        this.object.getPositionInfo();
        if (
            this.object.isInCorner.cornerA !== null &&
            this.object.isInCorner.cornerB !== null &&
            !this.object.editor.selectedItem.connectedCabinet &&
            !this.object.editor.selectedItem.secondConnectedCabinet
        ) {
            this.addNewCornerCabinet(this.object.isInCorner.cornerA, 'cornerA');
            this.addNewCornerCabinet(this.object.isInCorner.cornerB, 'cornerB');
        } else if (this.object.isInCorner.cornerA !== null && !this.object.editor.selectedItem.connectedCabinet) {
            this.addNewCornerCabinet(this.object.isInCorner.cornerA, 'cornerA');
        } else if (this.object.isInCorner.cornerB !== null && !this.object.editor.selectedItem.secondConnectedCabinet) {
            this.addNewCornerCabinet(this.object.isInCorner.cornerB, 'cornerB');
        }
    }

    calculateExtendedCabinetPosition(connectedCabinetCorner) {
        let assignedWallDirection = this.object.assignedWall.getDirection();
        let scale;
        if (connectedCabinetCorner.equals(this.object.assignedWall.baseInnerCornerB)) {
            scale = this.object.width / 2 + this.object.depth;
        } else if (connectedCabinetCorner.equals(this.object.assignedWall.baseInnerCornerA)) {
            scale = -(this.object.width / 2 + this.object.depth);
        }
        assignedWallDirection.scaleInPlace(scale);
        const shiftedPosition = connectedCabinetCorner.add(assignedWallDirection);

        let initialPosition = shiftedPosition;
        if (this.object.type === Constants.cabinet.type.WALL) {
            initialPosition.y += this.object.heightFromFloor;
        }
        return initialPosition;
    }

    connectWithCabinet(cabinet, assignedCorner) {
        if (
            !(
                (this.object.type === 'wall' || cabinet.type === 'wall') &&
                (this.object.type === 'tall' || cabinet.type === 'tall')
            )
        ) {
            let cornerCoordinates = this.object.assignedWall.baseInnerCornerA;
            let cornerForOtherCabinet;
            if (assignedCorner === Constants.corner.CORNER_A) {
                this.object.connectedCabinet = cabinet;
                cabinet.secondConnectedCabinet = this.object;
                cornerForOtherCabinet = Constants.corner.CORNER_B;
            } else {
                this.object.secondConnectedCabinet = cabinet;
                cabinet.connectedCabinet = this.object;
                cornerCoordinates = this.object.assignedWall.baseInnerCornerB;
                cornerForOtherCabinet = Constants.corner.CORNER_A;
            }
            this.object.blockedArea.build(cornerCoordinates, assignedCorner);
            cabinet.blockedArea.build(cornerCoordinates, cornerForOtherCabinet);
        }
    }

    checkForExistingCabinetsToConnect() {
        if (this.object.blockingCabinet && this.object.assignedWall !== this.object.blockingCabinet.assignedWall) {
            const blockingCabinetPositionInfo = this.object.blockingCabinet.getPositionInfo();
            if (blockingCabinetPositionInfo.cornerB && blockingCabinetPositionInfo.cornerA) {
                let closestPoint = Utilities.getClosestPoint(
                    this.object.position,
                    blockingCabinetPositionInfo.cornerB,
                    blockingCabinetPositionInfo.cornerA
                );
                this.connectWithCabinet(this.object.blockingCabinet, closestPoint.closestPointIndex);
            } else if (blockingCabinetPositionInfo.cornerB) {
                this.connectWithCabinet(this.object.blockingCabinet, Constants.corner.CORNER_A);
            } else if (blockingCabinetPositionInfo.cornerA) {
                this.connectWithCabinet(this.object.blockingCabinet, Constants.corner.CORNER_B);
            }
        }
    }

    disconnectExtendedCabinet(connectedCabinet) {
        let secondConnectedCabinet = 'secondConnectedCabinet';
        let blockedAreaToDispose = 'meshForCornerA';
        let blockedAreaToDisposeForExtended = 'meshForCornerB';
        if (connectedCabinet === 'secondConnectedCabinet') {
            secondConnectedCabinet = 'connectedCabinet';
            blockedAreaToDispose = 'meshForCornerB';
            blockedAreaToDisposeForExtended = 'meshForCornerA';
        }
        if (this.object[connectedCabinet]) {
            if (this.object[connectedCabinet].meshComponent.getMesh().isExtendedCabinet) {
                this.object[connectedCabinet].dispose();
                this.object[connectedCabinet] = null;
                this.object.blockedArea.dispose(blockedAreaToDispose);
            } else {
                this.object[connectedCabinet].blockedArea.dispose(blockedAreaToDisposeForExtended);
                this.object.blockedArea.dispose(blockedAreaToDispose);
                this.object[connectedCabinet][secondConnectedCabinet] = null;
            }
            this.object[connectedCabinet] = null;
        }
    }

    handleExistingConnections(pick) {
        if (this.object.type !== Constants.cabinet.type.ISLAND) {
            const closestPoint = Utilities.getClosestPoint(
                pick.pickedPoint,
                this.object.assignedWall.baseInnerCornerA,
                this.object.assignedWall.baseInnerCornerB
            );
            if (closestPoint.closestPointIndex === Constants.corner.CORNER_A && this.object.connectedCabinet) {
                this.object.resizingManager.handleExtendedCabinetConnection('connectedCabinet');
            } else if (closestPoint.closestPointIndex === Constants.corner.CORNER_B && this.object.secondConnectedCabinet) {
                this.object.resizingManager.handleExtendedCabinetConnection('secondConnectedCabinet');
            }
        }
    }
}
