import { Mesh, MeshBuilder } from '@babylonjs/core';
import { Utilities } from '../../../Tools/utilities';
import { CabinetStructure } from '../../BuildingBlocks/CabinetStructure';
import { DoorBlock } from '../../BuildingBlocks/DoorBlock';
import { Model } from '../Model';

export class WallCornerSection extends Model {
    constructor() {
        super();
        this.side = null;
        this.parts = {};
        this.parentCabinet = null;
    }

    async setModel() {
        const result = await this.import('wallCornerLShape.babylon');
        this.meshComponent.setMesh(Utilities.getElementByMeshName('cornerWallBlind', result.meshes));
        this.meshComponent.setName('cornerWallBlind');
        this.setParts();
    }

    flip() {
        this.meshComponent.getMesh().scaling.z = -1;
    }

    resize(dimensions) {
        this.resizeDoorWidth(dimensions);
    }

    mergeInnerTextureBlock(parentMesh) {
        const meshes = [];
        Object.keys(this.parts.cabinet).forEach((key) => {
            meshes.push(this.parts.cabinet[key].mesh);
        });

        const innerStructure = this.editor.addObject(CabinetStructure);
        innerStructure.setParentSection(this.currentParentSection);
        innerStructure.create(meshes, 'cabinets');
        innerStructure.meshComponent.setParent(parentMesh);
        return innerStructure;
    }

    mergeDoorBlock(parentMesh) {
        const meshes = [this.parts.doorLeft.mesh, this.parts.doorRight.mesh];
        const doorBlock = this.editor.addObject(DoorBlock);
        doorBlock.setParentSection(this.currentParentSection);
        doorBlock.create(meshes, 'cabinets');
        doorBlock.meshComponent.setParent(parentMesh);
        return doorBlock;
    }

    mergeModel() {
        const wallCornerCabinetMesh = MeshBuilder.CreateBox('wall-cabinet-model', { width: 0.1, depth: 0.1, height: 0.1 });
        wallCornerCabinetMesh.visibility = 0;
        const wallCornerCabinet = { mesh: wallCornerCabinetMesh, buildingBlocks: {} };

        wallCornerCabinet.buildingBlocks.innerStructure = this.mergeInnerTextureBlock(wallCornerCabinet.mesh);
        wallCornerCabinet.buildingBlocks.handleLeft = this.placeLeftHandle(wallCornerCabinet.mesh);
        wallCornerCabinet.buildingBlocks.handleRight = this.placeRightHandle(wallCornerCabinet.mesh);
        wallCornerCabinet.buildingBlocks.doorBlock = this.mergeDoorBlock(wallCornerCabinet.mesh);

        return wallCornerCabinet;
    }

    placeLeftHandle(parentMesh) {
        if (!this.sectionInfo.isFiller) {
            const handleClone = Mesh.MergeMeshes([this.editor.models3D.handle.meshComponent.getMesh().clone()]);
            if (this.width <= 0.15) {
                Utilities.resizeMeshInWidth(handleClone, 0.12);
            }
            // handleClone.isEnabled(true);
            this.parts.handleLeft.mesh.visibility = 0;
            handleClone.position = this.parts.handleLeft.mesh.position.clone();
            handleClone.rotation.y = Math.PI;
            handleClone.type = 'handle';

            handleClone.parent = parentMesh;
            handleClone.alwaysSelectAsActiveMesh = true;

            return handleClone;
        }
    }

    placeRightHandle(parentMesh) {
        if (!this.sectionInfo.isFiller) {
            const handleClone = Mesh.MergeMeshes([this.editor.models3D.handle.meshComponent.getMesh().clone()]);
            // handleClone.isEnabled(true);
            if (this.width <= 0.15) {
                Utilities.resizeMeshInWidth(handleClone, 0.12);
            }
            this.parts.handleRight.mesh.visibility = 0;
            handleClone.position = this.parts.handleRight.mesh.position.clone();
            handleClone.rotation.y = Math.PI / 2;
            handleClone.type = 'handle';
            handleClone.parent = parentMesh;
            handleClone.alwaysSelectAsActiveMesh = true;

            return handleClone;
        }
    }

    resizeDoorWidth(dimensions) {
        const width = dimensions.connectedCabinetDepth + dimensions.doorWidth;

        const panelTopLeftDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelTopLeft.mesh);
        this.parts.cabinet.panelTopLeft.mesh.scaling.x = width / panelTopLeftDimensions.width;
        this.parts.cabinet.panelTopLeft.mesh.scaling.z = dimensions.connectedCabinetDepth / panelTopLeftDimensions.depth;

        const panelBottomLeftDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottomLeft.mesh);
        this.parts.cabinet.panelBottomLeft.mesh.scaling.x = width / panelBottomLeftDimensions.width;
        this.parts.cabinet.panelBottomLeft.mesh.scaling.z = dimensions.connectedCabinetDepth / panelBottomLeftDimensions.depth;

        const panelBottomRightDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottomRight.mesh);
        this.parts.cabinet.panelBottomRight.mesh.scaling.x =
            (dimensions.connectedCabinetDepth - 0.018) / panelBottomRightDimensions.width;
        this.parts.cabinet.panelBottomRight.mesh.scaling.z = dimensions.doorWidth / panelBottomRightDimensions.depth;

        this.parts.cabinet.panelBottomRight.mesh.position.z +=
            (dimensions.connectedCabinetDepth - panelBottomRightDimensions.width) / 2 +
            (dimensions.doorWidth - panelBottomRightDimensions.depth) / 2;
        // -=
        //     (dimensions.doorWidth - panelBottomRightDimensions.depth) / 2 -
        //     (dimensions.connectedCabinetDepth - panelBottomRightDimensions.width) / 2;

        this.parts.cabinet.panelBottomRight.mesh.position.x += (dimensions.doorWidth - panelBottomRightDimensions.depth) / 2;

        const panelTopRightDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelTopRight.mesh);
        this.parts.cabinet.panelTopRight.mesh.scaling.z = dimensions.doorWidth / panelTopRightDimensions.depth;
        this.parts.cabinet.panelTopRight.mesh.scaling.x =
            (dimensions.connectedCabinetDepth - 0.018) / panelTopRightDimensions.width;

        this.parts.cabinet.panelTopRight.mesh.position.z -=
            (dimensions.doorWidth - panelTopRightDimensions.depth) / 2 -
            (dimensions.connectedCabinetDepth - panelTopRightDimensions.width) / 2;

        this.parts.cabinet.panelTopRight.mesh.position.x += (dimensions.doorWidth - panelBottomRightDimensions.depth) / 2;

        /////////////////////////////////////////////////////////////////////

        const panelBackLeftDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBackLeft.mesh);
        this.parts.cabinet.panelBackLeft.mesh.scaling.x = (width - 0.068) / panelBackLeftDimensions.width;
        this.parts.cabinet.panelBackLeft.mesh.position.z -=
            (dimensions.connectedCabinetDepth - panelTopRightDimensions.depth) / 2;

        this.parts.cabinet.panelBackRight.mesh.scaling.z = (width - 0.068) / panelBackLeftDimensions.width;
        this.parts.cabinet.panelBackRight.mesh.position.x -=
            (dimensions.connectedCabinetDepth - panelTopRightDimensions.width) / 2;

        //////////////////////////////////////////////////////////////////////

        const panelRightDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelRight.mesh);
        this.parts.cabinet.panelRight.mesh.scaling.x = (dimensions.connectedCabinetDepth - 0.018) / panelRightDimensions.width;
        this.parts.cabinet.panelRight.mesh.position.z +=
            (dimensions.connectedCabinetDepth - 0.018 - panelRightDimensions.width) / 2;

        this.parts.cabinet.panelRight.mesh.position.x += (dimensions.doorWidth - panelBottomRightDimensions.depth) / 2;

        const panelLeftDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelLeft.mesh);
        this.parts.cabinet.panelLeft.mesh.scaling.z = (dimensions.connectedCabinetDepth - 0.018) / panelLeftDimensions.depth;
        this.parts.cabinet.panelLeft.mesh.position.x -=
            (dimensions.connectedCabinetDepth - 0.018 - panelLeftDimensions.depth) / 2;

        //////////////////////////////////////////////////////////////////////////////////////////

        const doorRightDimensions = Utilities.getMeshDimensions(this.parts.doorRight.mesh);
        this.parts.doorRight.mesh.scaling.z = dimensions.doorWidth / doorRightDimensions.depth;
        this.parts.doorRight.mesh.position.x +=
            (dimensions.doorWidth - panelBottomRightDimensions.depth) / 2 -
            (dimensions.connectedCabinetDepth - panelBottomRightDimensions.width) / 2 +
            0.018;

        ////
        //this.parts.handleRight.mesh.position.x -=
        //(dimensions.doorWidth - panelBottomRightDimensions.depth) / 2;
        //-
        //  (dimensions.connectedCabinetDepth - panelBottomRightDimensions.width) / 2 + 0.018;

        ////
        this.parts.doorRight.mesh.position.z +=
            (dimensions.connectedCabinetDepth - panelBottomRightDimensions.width) / 2 +
            (dimensions.doorWidth - panelBottomRightDimensions.depth) / 2;

        ////
        //this.parts.handleRight.mesh.position.z += dimensions.connectedCabinetDepth - panelBottomRightDimensions.width;
        // +
        // (dimensions.doorWidth - panelBottomRightDimensions.depth) / 2;

        ////

        ///////////////////////////////////////////////////////////////////////////////////////////////

        const doorLeftDimensions = Utilities.getMeshDimensions(this.parts.doorLeft.mesh);
        this.parts.doorLeft.mesh.scaling.x = dimensions.doorWidth / doorLeftDimensions.width;
        this.parts.doorLeft.mesh.position.x -= (dimensions.connectedCabinetDepth - panelBottomRightDimensions.width) / 2 - 0.018;

        ////
        // this.parts.handleLeft.mesh.position.x -=
        //     (dimensions.connectedCabinetDepth - panelBottomRightDimensions.width) / 2 - 0.018;

        // this.parts.handleLeft.mesh.position.z +=
        //     (dimensions.connectedCabinetDepth - panelBottomLeftDimensions.depth) / 2 - 0.018;
        /////
        //this.parts.doorLeft.mesh.position.z += (dimensions.connectedCabinetDepth - panelBottomLeftDimensions.depth) / 2;
    }

    getResizingDimensions(sectionWidth = null) {
        const cornerCabinetDoor = this.parentCabinet.getCornerCabinetDoor();
        return {
            doorWidth: cornerCabinetDoor,
            connectedCabinetDepth: this.parentCabinet.depth,
            width: this.parentCabinet.depth + cornerCabinetDoor,
            height: this.parentCabinet.height,
            depth: this.parentCabinet.depth,
        };
    }
}
