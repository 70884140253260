import { ActionManager, Color3, ExecuteCodeAction, Mesh } from '@babylonjs/core';
import store from '@/store/index.js';
import { BaseKitchenObject } from '../BaseKitchenObject';
import { Constants } from '../../Tools/constants';

export class BuildingBlock extends BaseKitchenObject {
    parentSection;
    constructor() {
        super();
        this.currentColor = new Color3(1, 1, 1);
        this.isSelectable = false;
    }

    create(meshes, type) {
        const sectionInformation = this.parentSection.getInformation();
        const mergedBlock = Mesh.MergeMeshes(meshes, false, false, false, false, true);
        mergedBlock.computeWorldMatrix();
        mergedBlock.alwaysSelectAsActiveMesh = true;
        this.meshComponent.setMesh(mergedBlock);

        this.setInfo(type);
        const collection = store.state.generated.objects[this.type]?.collections[0];
        if (collection) {
            let savedInfo = {
                collection: collection.name,
                type: collection.color.type,
                value: collection.color.items[0],
                pattern: collection.color.pattern,
            };
            let savedSurface = 'matt';
            if (sectionInformation) {
                if (!['countertop', 'skirting'].includes(this.type) || this.parentSection.type === 'cornerSection') {
                    if (
                        this.parentSection.parentCabinet.type === Constants.cabinet.type.ISLAND &&
                        this.parentSection.parentCabinet?.sectionsInformation?.[this.parentSection.assignedRow]?.[
                            sectionInformation.index
                        ]?.customization?.[this.type]
                    ) {
                        savedInfo =
                            this.parentSection.parentCabinet.sectionsInformation[this.parentSection.assignedRow][
                                sectionInformation.index
                            ].customization[this.type].color;
                        savedSurface =
                            this.parentSection.parentCabinet.sectionsInformation[this.parentSection.assignedRow][
                                sectionInformation.index
                            ].customization[this.type].surface;
                    } else if (
                        this.parentSection.parentCabinet?.sectionsInformation?.[sectionInformation.index]?.customization?.[
                            this.type
                        ]
                    ) {
                        savedInfo =
                            this.parentSection.parentCabinet.sectionsInformation[sectionInformation.index].customization[
                                this.type
                            ].color;
                        savedSurface =
                            this.parentSection.parentCabinet.sectionsInformation[sectionInformation.index].customization[
                                this.type
                            ].surface;
                    }
                    this.surface = savedSurface;
                }
            }

            this.changeColor(savedInfo.collection, savedInfo.type, savedInfo.value, savedInfo.pattern);

            if (this.isSelectable) {
                this.setActions();
            }
        }
    }

    setInfo(type) {
        this.setId();
        this.setType(type);
        this.setSubtype();

        const collection = store.state.generated.objects[this.type]?.collections[0];
        if (collection) {
            this.setColor(collection.color.items[0], collection.color.type);
            this.setCollection(collection.name);
            this.setSurface(collection.surface?.items[0]);
        }
    }

    setId() {
        this.id = this.editor.lastBuildingBlockId++;
    }

    setParentSection(section) {
        this.parentSection = section;
    }

    setType(type) {
        //Possibe types: ['cabinets','countertop','skirting','wall', 'floor']
        this.type = type;
    }

    setSubtype() {
        //Possibe subtypes: ['island','tall','wall', 'base']
        this.subtype = this.parentSection?.parentCabinet?.type;
        if (this.parentSection?.type === Constants.section.type.CORNER_SECTION) {
            if (this.subtype === Constants.cabinet.type.BASE) {
                this.subtype = 'baseCorner';
            } else if (this.subtype === Constants.cabinet.type.WALL) {
                this.subtype = 'wallCorner';
            } else if (this.subtype === Constants.cabinet.type.TALL) {
                this.subtype = 'tallCorner';
            } else if (this.subtype === Constants.cabinet.type.ISLAND) {
                this.subtype = 'baseCorner';
            }
        } else if (this.subtype === Constants.cabinet.type.ISLAND) {
            //Because we don't have an island type in the config, vue side
            this.subtype = Constants.cabinet.type.BASE;
        }
    }

    setSurface(surface) {
        if (surface) {
            this.surface = surface;
        }
    }

    setColor(value, type) {
        //Possible color types: ['monoColor', 'texture', 'combo']
        // if(type is monoColor || combo) ? type is hex value: type is texture name
        this.color = {
            value,
            type,
        };
    }

    setCollection(collection) {
        this.collection = { name: collection };
    }

    toggleBoundingBox(toggle) {
        this.meshComponent.getMesh().showBoundingBox = toggle;
        if (this.type === 'cabinets' && this.parentSection.type === 'cornerSection') {
            if (this.parentSection.buildingBlocks.fillerBlock) {
                this.parentSection.buildingBlocks.fillerBlock.meshComponent.getMesh().showBoundingBox = toggle;
            }
            this.parentSection.buildingBlocks.doorBlock.meshComponent.getMesh().showBoundingBox = toggle;
        }
    }

    setActions() {
        this.meshComponent.getMesh().actionManager = new ActionManager(this.editor.sceneComponent.get());
        this.onPointerOverTrigger();
        this.onPointerOutTrigger();
        this.onPointerPickTrigger();
    }

    onPointerOverTrigger() {
        this.meshComponent.getMesh().actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPointerOverTrigger,
                },
                () => {
                    this.toggleBoundingBox(true);
                }
            )
        );
    }

    onPointerOutTrigger() {
        this.meshComponent.getMesh().actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPointerOutTrigger,
                },
                () => {
                    if (!store.state.generated.selectedObject.includes(this)) {
                        this.toggleBoundingBox(false);
                    }
                }
            )
        );
    }

    onPointerPickTrigger() {
        this.meshComponent.getMesh().actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPickTrigger,
                },
                () => {
                    if (store.state.core.step === 4) {
                        if (store.state.generated.selectedObject.includes(this)) {
                            if (!this.editor.multiselection) {
                                const selected = store.state.generated.selectedObject;
                                for (let index = 0; index < selected.length; index++) {
                                    selected[index].meshComponent.getMesh().showBoundingBox = false;
                                    if (this.type === 'cabinets' && this.parentSection.type === 'cornerSection') {
                                        this.parentSection.buildingBlocks.fillerBlock.meshComponent.getMesh().showBoundingBox = false;
                                        this.parentSection.buildingBlocks.doorBlock.meshComponent.getMesh().showBoundingBox = false;
                                    }
                                }
                                if (selected.length > 1) {
                                    store.commit('generated/setSelectedObject', [this]);
                                    this.meshComponent.getMesh().showBoundingBox = true;
                                } else {
                                    store.commit('generated/setSelectedObject', []);
                                    this.meshComponent.getMesh().showBoundingBox = false;
                                }
                            } else {
                                let selected = this.deselect();
                                store.commit('generated/setSelectedObject', selected);
                            }
                        } else {
                            if (this.editor.multiselection) {
                                const selected = store.state.generated.selectedObject;
                                selected.push(this);
                                store.commit('generated/setSelectedObject', selected);
                            } else {
                                const selected = store.state.generated.selectedObject;

                                for (let index = 0; index < selected.length; index++) {
                                    selected[index].meshComponent.getMesh().showBoundingBox = false;
                                    if (
                                        selected[index].type === 'cabinets' &&
                                        selected[index].parentSection.type === 'cornerSection' &&
                                        selected[index].parentSection.parentCabinet.type === 'base'
                                    ) {
                                        selected[
                                            index
                                        ].parentSection.buildingBlocks.fillerBlock.meshComponent.getMesh().showBoundingBox = false;
                                        selected[
                                            index
                                        ].parentSection.buildingBlocks.doorBlock.meshComponent.getMesh().showBoundingBox = false;
                                    }
                                }
                                if (store.state.appliances.selectedAppliance) {
                                    store.state.appliances.selectedAppliance.deselect();
                                }
                                store.commit('generated/setSelectedObject', [this]);
                            }
                            this.meshComponent.getMesh().showBoundingBox = true;
                        }
                    }
                }
            )
        );
    }

    deselect() {
        const selected = store.state.generated.selectedObject;
        for (let index = 0; index < selected.length; index++) {
            if (selected[index].id === this.id) {
                selected.splice(index, 1);
                this.meshComponent.getMesh().showBoundingBox = false;
                if (this.type === 'cabinets' && this.parentSection.type === 'cornerSection') {
                    this.parentSection.buildingBlocks.fillerBlock.meshComponent.getMesh().showBoundingBox = false;
                    this.parentSection.buildingBlocks.doorBlock.meshComponent.getMesh().showBoundingBox = false;
                }
                break;
            }
        }
        return selected;
    }

    dispose() {
        this.meshComponent.getMesh().dispose();
    }

    changeColor(collectionName, collectionType, color, pattern = null) {
        this.collection.name = collectionName;
        this.color.value = color;
        this.collectionType = collectionType;

        let microsurface = Constants.glossines[this.type].MICROSURFACE;
        let clearCoat = Constants.glossines[this.type].CLEAR_COAT;

        if (this.surface === 'matt') {
            microsurface = Constants.glossines.matt.MICROSURFACE;
            clearCoat = Constants.glossines.matt.CLEAR_COAT;
        }

        if (collectionType === 'monoColor') {
            this.currentColor = Color3.FromHexString(color);
            const pbrMaterial = this.editor.PBRComponent.getTextureFromPool(
                collectionType,
                'noPattern',
                color,
                microsurface,
                clearCoat,
                ''
            );
            this.meshComponent.getMesh().material = pbrMaterial;
            this.saveCustomizationInformation(color, collectionType, 'noPattern');
        } else if (collectionType === 'combo') {
            this.currentColor = Color3.FromHexString(color);
            const pbrMaterial = this.editor.PBRComponent.getTextureFromPool(
                collectionType,
                pattern,
                color,
                microsurface,
                clearCoat,
                ''
            );

            this.meshComponent.getMesh().material = pbrMaterial;
            this.saveCustomizationInformation(color, collectionType, pattern);
        } else if (collectionType === 'texture') {
            this.currentColor = new Color3(1, 1, 1);
            this.color.pattern = color;
            const pbrMaterial = this.editor.PBRComponent.getTextureFromPool(
                collectionType,
                color,
                '#FFFFFF',
                microsurface,
                clearCoat,
                ''
            );
            this.meshComponent.getMesh().material = pbrMaterial;
            this.saveCustomizationInformation(color, collectionType, pattern);
        }
    }

    saveCustomizationInformation(color, collectionType, pattern) {
        if (this.type === 'floor') {
            this.customization = {
                value: color,
                type: collectionType,
                pattern: pattern,
                collectionType,
                collectionName: this.collection.name,
            };
            return;
        } else if (this.type === 'wall') {
            console.log('wall', this);
            this.customization = {
                value: color,
                type: collectionType,
                pattern: pattern,
                collectionType,
                collectionName: this.collection.name,
            };
            return;
        }
        const sectionInformation = this.parentSection.getInformation();
        if (typeof this.parentSection.customization[this.type] !== 'object') {
            this.parentSection.customization[this.type] = {};
        }
        this.parentSection.customization[this.type].color = {
            value: color,
            type: collectionType,
            pattern: pattern,
            collectionType,
            collectionName: this.collection.name,
        };

        if (['countertop', 'skirting'].includes(this.type)) {
            //Don't forget
            if (this.parentSection.parentCabinet.type === Constants.cabinet.type.ISLAND) {
                if (
                    sectionInformation &&
                    this.parentSection.parentCabinet?.sectionsInformation?.[this.parentSection.assignedRow]?.[
                        sectionInformation.index
                    ]
                ) {
                    this.parentSection.parentCabinet.sectionsInformation[this.parentSection.assignedRow][
                        sectionInformation.index
                    ].customization[this.type].color = {
                        value: color,
                        type: collectionType,
                        pattern: pattern,
                        collectionType,
                        collectionName: this.collection.name,
                    };
                }
            } else if (sectionInformation && this.parentSection.parentCabinet.sectionsInformation[sectionInformation.index]) {
                this.parentSection.parentCabinet.sectionsInformation[sectionInformation.index].customization[this.type].color = {
                    value: color,
                    type: collectionType,
                    pattern: pattern,
                    collectionType,
                    collectionName: this.collection.name,
                };
            }
        }
        this.editor.sceneComponent.save3D();
    }

    changeSurface(surface = this.surface) {
        this.surface = surface;
        let hexColorValue = '#FFFFFF';
        let pattern = 'noPattern';
        if (['combo', 'monoColor'].includes(this.color.type)) {
            hexColorValue = this.color.value;
        } else {
            pattern = this.color.value;
        }

        let microsurface = Constants.glossines[this.type].MICROSURFACE;
        let clearCoat = Constants.glossines[this.type].CLEAR_COAT;
        if (surface === 'matt') {
            microsurface = Constants.glossines.matt.MICROSURFACE;
            clearCoat = Constants.glossines.matt.CLEAR_COAT;
        }

        const pbr = this.editor.PBRComponent.getTextureFromPool(
            this.collectionType,
            pattern,
            hexColorValue,
            microsurface,
            clearCoat,
            '',
            'glossiness'
        );
        this.meshComponent.getMesh().material = pbr;
    }

    enableSelection() {
        this.isSelectable = true;
    }
}
