<template>
    <v-col
        class="d-flex flex-column justify-center flex-shrink-1 height"
        cols="12"
        sm="4"
    >
        <v-container>
            <v-row justify="center">
                <v-btn
                    v-if="!isStandardDomain"
                    color="green"
                    class="white--text mb-1 mx-2"
                    :small="$vuetify.breakpoint.mdAndDown"
                    @click="
                        $route.params.projectId
                            ? toggleUpdateDialog(true)
                            : toggleDialog(true)
                    "
                >
                    {{ $route.params.projectId ? 'Save Project' : 'Quote' }}
                    <v-icon v-if="!$route.params.projectId">
                        {{ mdiCurrencyUsd }}
                    </v-icon>
                </v-btn>
                <v-btn
                    color="primary"
                    class="white--text mx-2"
                    :small="$vuetify.breakpoint.mdAndDown"
                    @click="printDesign()"
                    :disabled="disablePrint"
                >
                    Print
                    <v-icon>
                        {{ mdiFilePdfBox }}
                    </v-icon>
                </v-btn>
            </v-row>

            <v-row justify="center" v-if="isStandardDomain && subscription?.product === 'free'">
                <p class="text-caption">Downloaded {{ downloadCount }} / 5 (Upgrade for more)</p>
            </v-row>
        </v-container>

        <v-container class="text-center mt-2">
            <v-row justify="center">
                <v-btn
                    text
                    :small="$vuetify.breakpoint.mdAndDown"
                    @click="setStep(3)"
                >
                    Previous
                </v-btn>

                <resetBtn />
            </v-row>
        </v-container>
    </v-col>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { mdiFilePdfBox, mdiCurrencyUsd } from '@mdi/js';
import resetBtn from '@/components/resetBtn.vue';

import fetchProjectsDonwloadedAggregate from '@/graphql/queries/fetchProjectsDonwloadedAggregate.gql';
import fetchProjectsDonwloadedAggregateSubscription from '@/graphql/queries/fetchProjectsDonwloadedAggregateSubscription.gql';

export default {
    components: {
        resetBtn,
    },
    data() {
        return {
            downloadCount: null,
            mdiFilePdfBox,
            mdiCurrencyUsd,
        };
    },
    computed: {
        ...mapGetters('core', ['editorEngine']),
        ...mapState('business', ['isStandardDomain']),
        ...mapState('subscription', ['subscription']),
        disablePrint() {
            if (this.isStandardDomain && this.subscription?.product === 'free') {
                if (this.downloadCount >= 5) return true;
            } else return false;

            return false
        }
    },
    methods: {
        ...mapMutations('core', ['logErrors']),
        ...mapMutations('business', ['toggleDialog', 'toggleUpdateDialog']),
        setStep(step) {
            this.$store.commit('core/setStep', step);
        },
        async printDesign() {
            console.log('print design from click start');
            this.$store.getters['core/editorEngine'].isPrinting = false;

            await this.$store.getters[
                'core/editorEngine'
            ].cameraComponent.printDesign(
                this.$apollo,
                this.$route.params.projectId
            );
            console.log('print design');
        },
    },
    apollo: {
        downloadCount: {
            query: fetchProjectsDonwloadedAggregate,
            update: (data) => data.projects_downloaded_aggregate.aggregate.count,
            variables() {
                var date = new Date();
                var monthStart = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    1
                );

                return { monthStart: monthStart.toISOString() };
            },
            subscribeToMore: {
                document: fetchProjectsDonwloadedAggregateSubscription,
                update: (data) => data.projects_downloaded_aggregate.aggregate.count,
                variables() {
                    var date = new Date();
                    var monthStart = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        1
                    );

                    return { monthStart: monthStart.toISOString() };
                },
                updateQuery: (previousResult, { subscriptionData }) => {
                    const newCount =
                        subscriptionData?.data.projects_downloaded_aggregate
                            ?.aggregate.count;

                    previousResult.projects_downloaded_aggregate.aggregate.count = newCount;

                    return previousResult;
                },
                error(error) {
                    this.logErrors(JSON.stringify(error.message));
                    console.error(error);
                },
            },
            skip() {
                return !this.isStandardDomain;
            },
            error(error) {
                this.logErrors(JSON.stringify(error.message));
                console.error(error);
            },
        },
    },
};
</script>
