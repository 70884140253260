import { SceneLoader, Texture } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { BaseAppliance } from './BaseAppliance';
import store from '@/store/index.js';

export class Refrigerator extends BaseAppliance {
    constructor() {
        super();
    }

    async setModel() {
        let filename;
        if (this.subtype === 'bottomFreezer') {
            filename = 'bottomFreezer.babylon';
        } else if (this.subtype === 'frenchDoor') {
            filename = 'frenchDoor-1freezer.babylon';
        } else if (this.subtype === 'sideBySide') {
            filename = 'sidebyside.babylon';
        }
        const result = await SceneLoader.ImportMeshAsync('', filename, '', this.editor.sceneComponent.get());
        this.meshComponent.setMesh(result.meshes[Utilities.getIndexFromMeshId(filename.split('.')[0], result.meshes)]);
        this.resizeToCurrentDimensions(this.width);
        this.addTexture();
    }

    getSectionWidthNeeded() {
        const emptySpaceForFreeStanding = 0.05;
        const sidePanelWidth = 0.018;
        if (this.format === Constants.appliance.format.FREESTANDING) {
            return this.width + emptySpaceForFreeStanding + sidePanelWidth * 2;
        }
        return this.width;
    }

    addTexture() {
        if (this.subtype === 'bottomFreezer') {
            this.editor.PBRComponent.setPBRMetallicRoughnessToBuildingBlock(this.meshComponent.getMesh(), 'Sink', 'appliances/');

            let color = store.state.appliances.appliances[this.type].subtype[0].color.items[0];

            if (color === 'light') {
                const filePath = require('@/assets/textures/appliances/Sink/diffuseTextureLight.jpg');
                this.meshComponent.getMesh().material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
            }
        } else {
            this.editor.PBRComponent.setPBRMetallicRoughnessToBuildingBlock(
                this.meshComponent.getMesh(),
                'Refrigerator',
                'appliances/'
            );

            let color = store.state.appliances.appliances[this.type].subtype[0].color.items[0];

            if (color === 'light') {
                const filePath = require('@/assets/textures/appliances/Refrigerator/diffuseTextureLight.jpg');
                this.meshComponent.getMesh().material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
            }
        }
    }

    changeColor(color) {
        this.color = color;
        let textureType = 'Refrigerator';
        if (this.subtype === 'bottomFreezer') {
            textureType = 'Sink';
        }

        if (color == 'dark') {
            const filePath = require('@/assets/textures/appliances/'.concat(textureType.concat('/diffuseTexture.jpg')));
            this.model.material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        } else if (color === 'light') {
            const filePath = require('@/assets/textures/appliances/'.concat(textureType.concat('/diffuseTextureLight.jpg')));
            this.model.material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        }
    }
}
