import { SceneLoader, Texture } from '@babylonjs/core';
import { Utilities } from '../../Tools/utilities';
import { BaseAppliance } from './BaseAppliance';
import store from '@/store/index.js';

export class WashingMachine extends BaseAppliance {
    constructor() {
        super();
    }

    async setModel() {
        const result = await SceneLoader.ImportMeshAsync(
            '',
            'modern_washing_machine.babylon',
            '',
            this.editor.sceneComponent.get()
        );
        this.meshComponent.setMesh(result.meshes[Utilities.getIndexFromMeshId('modern_washing_machine', result.meshes)]);
        this.resizeToCurrentDimensions();
        this.addTexture();
    }

    addTexture() {
        this.editor.PBRComponent.setPBRMetallicRoughnessToBuildingBlock(this.meshComponent.getMesh(), 'Washer', 'appliances/');
        let color = store.state.appliances.appliances[this.type].subtype[0].color.items[0];

        if (color === 'light') {
            const filePath = require('@/assets/textures/appliances/Washer'.concat('/diffuseTextureLight.jpg'));
            this.meshComponent.getMesh().material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        }
    }
}
