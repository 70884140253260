import { Color3, Matrix, MeshBuilder, StandardMaterial, Vector3, ActionManager, ExecuteCodeAction } from '@babylonjs/core';
import { Constants } from '../../../Tools/constants';
import { Component } from '../../../Components/Base/Component';
import store from '@/store/index.js';
export class HandleComponent extends Component {
    leftHandle;
    rightHandle;
    frontHandle;
    backHandle;

    constructor() {
        super();
    }

    build() {
        let handleWidth = Constants.cabinet.handle.SIZE;
        let handleDepth;
        if (this.object.type === Constants.cabinet.type.ISLAND) {
            handleWidth /= 2;
            handleDepth = this.object.depth;
        } else {
            handleDepth = this.object.depth;
        }

        this.buildMesh('rightHandle', handleWidth, handleWidth, handleDepth + Constants.cabinet.handle.MARGIN);
        this.buildMesh('leftHandle', handleWidth, handleWidth, handleDepth + Constants.cabinet.handle.MARGIN);

        if (this.object.type === Constants.cabinet.type.ISLAND) {
            this.buildIslandHandles();
        }

        this.setMaterial('leftHandle', new Color3(0, 0, 255));
        this.setMaterial('rightHandle', new Color3(0, 0, 255));

        this.leftHandle.setPivotMatrix(
            Matrix.Translation(
                handleWidth / 2 - Constants.cabinet.handle.MARGIN,
                -handleWidth / 2 + Constants.cabinet.handle.MARGIN,
                0
            ),
            false
        );

        this.leftHandle.position = new Vector3(-(this.object.width / 2), this.object.height / 2, 0);
        this.rightHandle.setPivotMatrix(
            Matrix.Translation(
                -handleWidth / 2 + Constants.cabinet.handle.MARGIN,
                -handleWidth / 2 + Constants.cabinet.handle.MARGIN,
                0
            ),
            false
        );

        this.rightHandle.position = new Vector3(this.object.width / 2, this.object.height / 2, 0);

        this.setActions('leftHandle');
        this.setActions('rightHandle');
    }

    buildIslandHandles() {
        const handleSize = Constants.cabinet.handle.SIZE / 2;
        this.buildMesh('frontHandle', this.object.width - handleSize * 2, handleSize, handleSize);
        this.buildMesh('backHandle', this.object.width - handleSize * 2, handleSize, handleSize);

        this.setMaterial('frontHandle', new Color3(255, 255, 255));
        this.setMaterial('backHandle', new Color3(0, 0, 0));
        this.frontHandle.setPivotMatrix(
            Matrix.Translation(
                0,
                -handleSize / 2 + Constants.cabinet.handle.MARGIN,
                -(handleSize / 2 - Constants.cabinet.handle.MARGIN)
            ),
            false
        );
        this.frontHandle.position = new Vector3(0, this.object.height / 2, this.object.depth / 2);
        this.backHandle.setPivotMatrix(
            Matrix.Translation(
                0,
                -handleSize / 2 + Constants.cabinet.handle.MARGIN,
                handleSize / 2 - Constants.cabinet.handle.MARGIN
            ),
            false
        );
        this.backHandle.position = new Vector3(0, this.object.height / 2, -this.object.depth / 2);
        this.setActions('backHandle');
        this.setActions('frontHandle');
    }

    setMaterial(handleName, color) {
        this[handleName].material = new StandardMaterial(handleName + 'material', this.object.editor.sceneComponent.get());
        this[handleName].material.diffuseColor = color;
    }

    buildMesh(handleName, width, height, depth) {
        if (!this[handleName]) {
            this[handleName] = MeshBuilder.CreateBox(
                handleName,
                { width: width, height: height, depth: depth },
                this.object.editor.sceneComponent.get()
            );
        }
        this[handleName].rotation = Vector3.Zero();
        this[handleName].id = this.object.id;
        this[handleName].setEnabled(false);
        this[handleName].setParent(this.object.meshComponent.getMesh());
    }

    show(enabled = true) {
        if (this.rightHandle && this.leftHandle) {
            this.rightHandle.setEnabled(enabled);
            this.leftHandle.setEnabled(enabled);
            if (this.frontHandle && this.backHandle) {
                this.frontHandle.setEnabled(enabled);
                this.backHandle.setEnabled(enabled);
            }
        } else if (enabled) {
            this.build();
        }
    }

    setActions(handle) {
        this[handle].actionManager = new ActionManager(this.object.editor.sceneComponent.get());
        this[handle].actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPickDownTrigger,
                },
                () => {
                    if (store.state.cabinets.selectedCabinet) {
                        if (
                            store.state.cabinets.selectedCabinet !== this.object &&
                            this.object.connectedCabinet?.id !== store.state.cabinets.selectedCabinet.id &&
                            this.object.secondConnectedCabinet?.id !== store.state.cabinets.selectedCabinet.id
                        ) {
                            return;
                        }
                        if (
                            this.object.connectedCabinet?.id === store.state.cabinets.selectedCabinet.id ||
                            this.object.secondConnectedCabinet?.id === store.state.cabinets.selectedCabinet.id
                        ) {
                            this.object.select();
                            return;
                        }

                        if (this.object.state !== 'moving') {
                            this.object.pickedHandle = this[handle];
                            if (this.object.type === 'island') {
                                this.object.editor.resizingIsland = true;
                            } else {
                                this.object.editor.resizing = true;
                            }
                        }
                        this.object.editor.selectedItem = this.object;
                        this.object.select();
                        store.commit('cabinets/setSelectedCabinet', this.object);
                    } else {
                        this.object.editor.selectedItem = this.object;
                        this.object.state = 'moving';
                        this.object.select();
                        store.commit('cabinets/setSelectedCabinet', this.object);
                    }
                }
            )
        );

        this[handle].actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPickTrigger,
                },
                () => {
                    this.object.select();
                    this.object.editor.selectedItem = this.object;
                    store.commit('cabinets/setSelectedCabinet', this.object);
                }
            )
        );
    }
}
