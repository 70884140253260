var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.costing &&
    _vm.cabinetData &&
    _vm.step === 4 &&
    !_vm.isProjectLoading
    ? _c(
        "div",
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "1400", scrollable: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  small: "",
                                  color: "primary lighten-2",
                                  dark: "",
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { dark: "" } }, [
                              _vm._v(_vm._s(_vm.mdiFormatListBulleted)),
                            ]),
                          ],
                          1
                        ),
                        _vm.businessCosting?.extra_info
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                attrs: { fab: "", light: "", "x-small": "" },
                                on: { click: _vm.showInfo },
                              },
                              [
                                _c("v-icon", { attrs: { dark: "" } }, [
                                  _vm._v(_vm._s(_vm.mdiInformationOutline)),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                3340838188
              ),
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "text-h5 grey lighten-2 justify-space-between",
                    },
                    [
                      _vm._v(" Pricing Information "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v(" close ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-subheader", [_vm._v("Total Price")]),
                      _c(
                        "v-sheet",
                        {
                          attrs: {
                            color: "green lighten-3",
                            elevation: "4",
                            "max-width": "300",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center", justify: "center" } },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "mb-0 pa-2 text-h4 text-center",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatter(_vm.totalPrice)) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.businessCosting?.extra_info
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        fab: "",
                                        light: "",
                                        "x-small": "",
                                      },
                                      on: { click: _vm.showInfo },
                                    },
                                    [
                                      _c("v-icon", { attrs: { dark: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.mdiInformationOutline)
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-subheader", [_vm._v("Cabinet Prices")]),
                      _c("v-data-table", {
                        staticClass: "elevation-4",
                        attrs: {
                          headers: _vm.cabinetItemsHeaders,
                          items: _vm.cabinetItems,
                          "sort-by": "type",
                          "group-by": "type",
                          "show-group-by": "",
                          "items-per-page": 30,
                          dense: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `item.subtype`,
                              fn: function ({ item }) {
                                return [
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm._.startCase(item.subtype)) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: `item.collectionType`,
                              fn: function ({ item }) {
                                return [
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._.startCase(item.collectionType)
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: `item.price`,
                              fn: function ({ item }) {
                                return [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.currency.symbol) +
                                        " " +
                                        _vm._s(item.price)
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("v-subheader", [_vm._v("Measurements")]),
                      _c("v-data-table", {
                        staticClass: "elevation-4",
                        attrs: {
                          headers: _vm.measurementHeaders,
                          items: _vm.measurementsArr,
                          dense: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v(" close ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }