import store from '@/store/index.js';

import { Fixture } from '../Fixture/Fixture';
import { FloorplanCornerPoint } from './CornerPoint';
import { FloorplanCornerCap } from './CornerCap';
import { FloorplanMeasurementWidget } from './MeasurementWidget';
import { FloorplanSegment } from './Segment';
import { WallsUpdateWidget } from './Segment/DiagramWidget';

import { line_intersect } from '../Tools/Utilities';
import { Mesh, Vector2 } from '@babylonjs/core';

export class Floorplan {
    constructor(parent) {
        this.parent = parent;
        this.corners = [];

        this.wallParentNode = new Mesh('Floorplan-wallParentNode', parent.scene);
        this.wallParentNode.visibility = 0;

        this.segments = [];
        this.cornerCaps = [];
        this.fixtureParentNode = new Mesh('Floorplan-fixtureParentNode', parent.scene);
        this.fixtureParentNode.position.z = -0.135;
        this.fixtures = [];

        this.measurementWidgets = [];

        this.wallThickness = 0.15; //-> 100mm
        this.roomPolygon = [];
        this.isClosed = false;
    }

    addFixture(data) {
        this.fixtures.push(new Fixture(data, this));
    }

    addCornerPoint(p) {
        this.corners.push(new FloorplanCornerPoint(p, this));
        store.commit('fixtures/resetTyping');
    }

    addMeasurementWidget(a, b, args) {
        let widget = new FloorplanMeasurementWidget(a, b, args, this);
        this.measurementWidgets.push(widget);
    }

    getLastCorner(i = 1) {
        return this.corners[this.corners.length - i] || false;
    }

    reIndexCorners() {
        this.corners.forEach((cp, i) => {
            cp.id = i;
        });
    }

    checkCornerRotation(id, cp) {
        let nextID = (id + 1) % this.corners.length;
        let cPos = cp.position;
        let nPos = this.corners[nextID].position;

        if ((nPos.x - cPos.x) * (nPos.y + cPos.y) < 0) {
            return false;
        } else {
            return true;
        }
    }

    checkClockRotation() {
        let sum = 0,
            nextID,
            cPos,
            nPos;

        this.corners.forEach((cp, i) => {
            nextID = (i + 1) % this.corners.length;
            cPos = cp.position;
            nPos = this.corners[nextID].position;
            sum += (nPos.x - cPos.x) * (nPos.y + cPos.y);
        });

        if (sum < 0) {
            //ANTI
            console.log('REVERSING CORNER POINTS');
            this.corners.reverse();
            // Move last element first in array corners
            // 2 times
            //this.corners.unshift(this.corners.pop())
            //this.corners.unshift(this.corners.pop())
        }
    }

    buildWalls() {
        this.roomPolygon = this.getRoomPolygon(true);
        this.buildSegments();
        if (this.corners.length) {
            this.corners.forEach((c) => {
                c.dispose();
            });
        }

        this.fadeInNodeAndChildren(this.wallParentNode);
        this.roomPolygon = this.getRoomPolygon(false);
    }

    buildSegments() {
        let points = this.corners.concat([this.corners[0]]);

        for (let i = 0; i < points.length - 1; i++) {
            let seg = new FloorplanSegment(points[i].position, points[i + 1].position, i, i + 1, this);
            seg.mesh.parent = this.wallParentNode;
            seg.mesh.visibility = this.wallParentNode.visibility;
            this.segments.push(seg);
        }

        for (let i = 0; i < this.segments.length; i++) {
            let _a = this.segments[i];
            let _b;
            if (i === this.segments.length - 1) {
                _b = this.segments[0];
            } else {
                _b = this.segments[i + 1];
            }
            let _atl = _a.getTopLine();
            let _btl = _b.getTopLine();
            let _intersect = line_intersect(_atl, _btl);
            if (_intersect) {
                _a.hasAOffset = _a.length - Vector2.Distance(_intersect, new Vector2(_atl[0], _atl[1]));
                _b.hasBOffset = _b.length - Vector2.Distance(_intersect, new Vector2(_btl[2], _btl[3]));
            }
        }

        for (let i = 0; i < points.length; i++) {
            let leftSegment;
            let rightSegment;
            let cap;
            if (i >= 1) {
                if (i < points.length - 1) {
                    cap = new FloorplanCornerCap(this.segments[i - 1], this.segments[i], this);
                    this.cornerCaps.push(cap);
                    leftSegment = this.segments[i - 1];
                    rightSegment = this.segments[i];
                } else {
                    cap = new FloorplanCornerCap(this.segments[i - 1], this.segments[0], this);
                    this.cornerCaps.push(cap);

                    leftSegment = this.segments[i - 1];
                    rightSegment = this.segments[0];
                }
                const angleBetweenWalls = cap.calculatedAngle;
                leftSegment.leftWallUpdateWidget = new WallsUpdateWidget(leftSegment.mesh, angleBetweenWalls, this.wallThickness);
                leftSegment.leftWallUpdateWidget.updateRightVertexPosition();

                rightSegment.rightWallUpdateWidget = new WallsUpdateWidget(
                    rightSegment.mesh,
                    angleBetweenWalls,
                    this.wallThickness
                );
                rightSegment.rightWallUpdateWidget.updateLeftVertexPosition();
            }
        }
    }

    buildCornerCaps() {
        let segments = this.segments.concat([this.segments[0]]);
        for (let i = 0; i < segments.length - 1; i++) {
            let cap = new FloorplanCornerCap(segments[i], segments[i + 1], this);
            cap.mesh.parent = this.wallParentNode;
            cap.mesh.visibility = this.wallParentNode.visibility;
            this.corners.push(cap);
        }
    }

    showAllCornerCaps(show = true) {
        this.cornerCaps.forEach((cornerCap) => {
            if (cornerCap) {
                cornerCap.showCornerCap(show);
            }
        });
    }

    fadeInNodeAndChildren(t) {
        if (t.visibility < 0.99) {
            t.visibility += 0.05;
            t._children.forEach((c) => {
                c.visibility = t.visibility;
            });
            setTimeout(() => {
                this.fadeInNodeAndChildren(t);
            }, 30);
            return;
        } else {
            t.visibility = 0.99;
            t._children.forEach((c) => {
                c.visibility = 0.99;
            });
        }
        console.log('Done Fadding In!');
    }

    getRoomPolygon(fromCornerPoints = false) {
        let polygon = [];
        if (!fromCornerPoints) {
            let segs = this.segments;
            //Create the Polygon
            for (let i = 0; i < segs.length - 1; i++) {
                polygon.push(segs[i].startAsArray(), segs[i].endAsArray());
            }
        } else {
            let points = this.corners.concat([this.corners[0]]);
            for (let i = 0; i < points.length - 1; i++) {
                polygon.push(points[i].position.x, points[i].position.y);
            }
        }
        return polygon;
    }

    getEditorCore() {
        return this.parent.getEditorCore();
    }
}
