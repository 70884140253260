<template>
    <div>
        <!-- Sheet Toolbar -->
        <v-toolbar flat>
            <v-toolbar-title class="grey--text">
                {{ collection.name }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- Surface Toggle -->
            <v-btn-toggle
                v-if="config.collections[collIdx].surface"
                :value="collection.surface.selected"
                @change="changeSurface(collIdx, $event)"
                tile
                color="primary"
                group
                mandatory
            >
                <v-btn
                    v-for="surface in collection.surface.items"
                    :key="surface"
                    :value="surface"
                >
                    {{ surface }}
                </v-btn>
            </v-btn-toggle>

            <v-spacer></v-spacer>

            <!-- Close Sheet Button -->
            <v-btn
                icon
                color="error"
                @click="
                    toggleCollectionSheet({
                        i: collIdx,
                        type: config.objectType,
                    })
                "
            >
                <v-icon>{{ mdiCloseCircle }}</v-icon>
            </v-btn>
        </v-toolbar>

        <v-tabs
            v-model="colTab"
            background-color="indigo"
            height="38"
            dark
            slider-size="5"
            show-arrows
        >
            <v-tab>Color</v-tab>
            <v-tab v-if="collection.doorStyle">Style</v-tab>
        </v-tabs>

        <v-tabs-items v-model="colTab">
            <!-- Color Tab -->
            <v-tab-item>
                <v-card
                    class="mx-auto"
                    max-height="300"
                    style="overflow-y: scroll; overflow-x: hidden"
                >
                    <v-container class="pa-3">
                        <v-item-group
                            :value="collection.color.selected"
                            @change="changeColor(collIdx, $event)"
                            :mandatory="!!collection.color.selected"
                        >
                            <v-row justify="center">
                                <v-col
                                    v-for="item in collection.color.items"
                                    :key="item"
                                    cols="auto"
                                >
                                    <v-item
                                        v-slot="{ active, toggle }"
                                        :value="item"
                                    >
                                        <!-- if texture -->
                                        <v-img
                                            v-if="
                                                collection.color.type ===
                                                'texture'
                                            "
                                            :src="
                                                require(`@/assets/textures/${item}/thumb.jpg`)
                                            "
                                            height="170"
                                            width="170"
                                            class="text-right pa-2"
                                            @click="toggle"
                                        >
                                            <v-btn icon dark>
                                                <v-icon color="green">
                                                    {{
                                                        active
                                                            ? mdiCheck
                                                            : null
                                                    }}
                                                </v-icon>
                                            </v-btn>
                                        </v-img>
                                        <!-- if hex -->
                                        <v-sheet
                                            v-else-if="
                                                collection.color.type ===
                                                    'monoColor' ||
                                                collection.color.type ===
                                                    'combo'
                                            "
                                            :color="item"
                                            height="90"
                                            width="90"
                                            elevation="24"
                                            class="text-right pa-2"
                                            @click="toggle"
                                        >
                                            <v-btn icon dark>
                                                <v-icon color="green">
                                                    {{
                                                        active
                                                            ? mdiCheck
                                                            : null
                                                    }}
                                                </v-icon>
                                            </v-btn>
                                        </v-sheet>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-item-group>
                    </v-container>
                </v-card>
            </v-tab-item>

            <!-- Style Tab -->
            <v-tab-item v-if="collection.doorStyle">
                <!-- Surface -->
                <v-btn-toggle
                    :value="collection.doorStyle.selected"
                    @change="changeDoorStyle(collIdx, $event)"
                    tile
                    color="primary"
                    group
                    mandatory
                >
                    <v-btn
                        v-for="doorStyle in collection.doorStyle.items"
                        :key="doorStyle"
                        :value="doorStyle"
                    >
                        {{ doorStyle }}
                    </v-btn>
                </v-btn-toggle>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { mdiCloseCircle, mdiCheck } from '@mdi/js'

export default {
    props: ['collection', 'collIdx', 'isTypeSame', 'config'],
    data() {
        return {
            colTab: null,
            mdiCloseCircle,
            mdiCheck
        };
    },
    computed: {
        ...mapState('generated', {
            object: (state) => state.selectedObject,
            objects: (state) => state.objects,
        }),
    },
    methods: {
        ...mapMutations('generated', [
            'toggleCollectionSheet',
            'changeSelectedColor',
            'changeSelectedSurface',
            'changeSelectedDoorStyle',
        ]),
        resetColorSelected() {
            // Remove selected color from all collections first
            for (const i of this.config.collections.keys()) {
                this.changeSelectedColor({
                    color: null,
                    i,
                    type: this.config.objectType,
                });
            }
        },
        changeColor(i, color) {
            this.resetColorSelected;
            this.changeSelectedColor({
                color,
                i,
                type: this.config.objectType,
            });

            console.log('change color to', color);
            console.log('collection name', this.config.collections[i].name);

            // Update color in BJS
            for (let index = 0; index < this.object.length; index++) {
                this.object[index].changeColor(
                    this.collection.name,
                    this.config.collections[i].color.type,
                    color,
                    this.config.collections[i].color?.pattern
                );
            }
        },
        changeSurface(i, surface) {
            console.log('change surface to:', surface);
            this.changeSelectedSurface({
                surface,
                i,
                type: this.config.objectType,
            });

            // Change surface in BJS
            for (let index = 0; index < this.object.length; index++) {
                this.object[index].changeSurface(surface);
            }
        },
        changeDoorStyle(i, doorStyle) {
            console.log('change door style to:', doorStyle);
            this.changeSelectedDoorStyle({
                doorStyle,
                i,
                type: this.config.objectType,
            });

            // change door style in BJS
        },
    },
    updated() {
        const object = this.object[0];

        for (const [i, collection] of this.config.collections.entries()) {
            this.changeSelectedColor({
                color:
                    collection.name === object.collection.name
                        ? object.color.value
                        : null,
                i,
                type: this.config.objectType,
            });

            if (object.surface) {
                this.changeSelectedSurface({
                    surface: object.surface,
                    i,
                    type: this.config.objectType,
                });
            }

            if (object.door) {
                this.changeSelectedDoorStyle({
                    doorStyle: object.door.style,
                    i,
                    type: this.config.objectType,
                });
            }
        }
    },
};
</script>

<style></style>
