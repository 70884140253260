import { SceneLoader } from '@babylonjs/core';
import { Utilities } from '../../Tools/utilities';
import { BaseKitchenObject } from '../BaseKitchenObject';

export class Model extends BaseKitchenObject {
    constructor() {
        super();
        this.model = null;
        this.parentCabinet;
        this.sectionInfo = {
            appliance: null,
        };
        this.parts = {};
        this.buildingBlocks = {};
    }

    async import(fileName) {
        return await SceneLoader.ImportMeshAsync('', fileName, '', this.editor.sceneComponent.scene);
    }

    setParts(parent = this.meshComponent.getMesh(), branch = this.parts) {
        const buildingBlocks = parent.getChildren();
        if (buildingBlocks.length > 0) {
            for (let index = 0; index < buildingBlocks.length; index++) {
                branch[buildingBlocks[index].name] = {};
                this.setParts(buildingBlocks[index], branch[buildingBlocks[index].name]);
            }
        } else {
            branch['mesh'] = parent;
            branch['mesh'].doNotPick = true;
        }
    }

    setParentCabinet(parent) {
        this.parentCabinet = parent;
    }

    setSectionInfo(resultForType) {
        this.sectionInfo = { appliance: resultForType.sectionData, isFiller: resultForType.isFiller };
    }

    getCabinetBuildingBlockByName(name) {
        return Utilities.getElementByMeshName(name, this.meshComponent.getMesh().getChildren());
    }

    setCurrentParentSection(section) {
        this.currentParentSection = section;
    }
}
