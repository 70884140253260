<template>
    <div v-if="costing && cabinetData && step === 4 && !isProjectLoading">
        <v-dialog v-model="dialog" width="1400" scrollable>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    color="primary lighten-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon dark>{{ mdiFormatListBulleted }}</v-icon>
                </v-btn>
                <v-btn
                    v-if="businessCosting?.extra_info"
                    class="mx-2"
                    fab
                    light
                    x-small
                    @click="showInfo"
                >
                    <v-icon dark>{{ mdiInformationOutline }}</v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-card-title
                    class="text-h5 grey lighten-2 justify-space-between"
                >
                    Pricing Information
                    <v-btn color="primary" text @click="dialog = false">
                        close
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-subheader>Total Price</v-subheader>
                    <v-sheet
                        color="green lighten-3"
                        elevation="4"
                        max-width="300"
                    >
                        <v-row align="center" justify="center">
                            <p class="mb-0 pa-2 text-h4 text-center">
                                {{ formatter(totalPrice) }}
                            </p>
                            <v-btn
                                v-if="businessCosting?.extra_info"
                                class="mx-2"
                                fab
                                light
                                x-small
                                @click="showInfo"
                            >
                                <v-icon dark>{{
                                    mdiInformationOutline
                                }}</v-icon>
                            </v-btn>
                        </v-row>
                    </v-sheet>
                    <v-subheader>Cabinet Prices</v-subheader>
                    <v-data-table
                        :headers="cabinetItemsHeaders"
                        :items="cabinetItems"
                        sort-by="type"
                        group-by="type"
                        class="elevation-4"
                        show-group-by
                        :items-per-page="30"
                        dense
                    >
                        <template v-slot:[`item.subtype`]="{ item }">
                            <p>
                                {{ _.startCase(item.subtype) }}
                            </p>
                        </template>
                        <template v-slot:[`item.collectionType`]="{ item }">
                            <p>
                                {{ _.startCase(item.collectionType) }}
                            </p>
                        </template>
                        <template v-slot:[`item.price`]="{ item }">
                            <p>{{ currency.symbol }} {{ item.price }}</p>
                        </template>
                    </v-data-table>

                    <v-subheader>Measurements</v-subheader>
                    <v-data-table
                        :headers="measurementHeaders"
                        :items="measurementsArr"
                        class="elevation-4"
                        dense
                    >
                    </v-data-table>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import { mdiInformationOutline, mdiFormatListBulleted } from '@mdi/js';
import { mapMutations, mapState } from 'vuex';
import { Utilities } from '@/services/editor/3D/Tools/utilities.js';

export default {
    data() {
        return {
            cabinetItemsArr: [],
            dialog: false,
            mdiFormatListBulleted,
            mdiInformationOutline,
        };
    },
    computed: {
        _() {
            return _;
        },
        ...mapState('core', [
            'cabinetData',
            'unit',
            'editorEngine',
            'step',
            'isProjectLoading',
        ]),
        ...mapState('business', ['costing']),
        businessCatalogs() {
            return this.costing.business.business_catalogs;
        },
        businessCosting() {
            return this.costing.business.business_costing;
        },
        currency() {
            if (!this.businessCosting) return;

            const currency = this.businessCosting.currency;

            return {
                symbol: currency.symbol,
                ticker: currency.ticker,
            };
        },
        measurements() {
            const sections = [];
            let measurements = {
                base: {
                    linear: null,
                    squaredFacing: null,
                    cubic: null,
                },
                wall: {
                    linear: null,
                    squaredFacing: null,
                    cubic: null,
                },
                tall: {
                    linear: null,
                    squaredFacing: null,
                    cubic: null,
                },
                island: {
                    linear: null,
                    squaredFacing: null,
                    cubic: null,
                },
            };

            for (const cabinet of this.cabinetData) {
                const type = cabinet.type;
                if (type === 'island') {
                    measurements = this.getMeasurements(
                        type,
                        cabinet.sectionsInformation.firstRow,
                        measurements
                    );
                    measurements = this.getMeasurements(
                        type,
                        cabinet.sectionsInformation.secondRow,
                        measurements
                    );
                } else {
                    measurements = this.getMeasurements(
                        type,
                        cabinet.sectionsInformation,
                        measurements
                    );
                }
            }

            return measurements;
        },
        cabinetItems() {
            let sections = [];
            for (const cabinet of this.cabinetData) {
                const type = cabinet.type;
                if (type === 'island') {
                    sections = this.getSectionsPrice(
                        type,
                        sections,
                        cabinet.sectionsInformation.firstRow
                    );
                    sections = this.getSectionsPrice(
                        type,
                        sections,
                        cabinet.sectionsInformation.secondRow
                    );
                } else {
                    sections = this.getSectionsPrice(
                        type,
                        sections,
                        cabinet.sectionsInformation
                    );
                }
            }

            return sections;
        },
        totalPrice() {
            let price = 0;

            for (const item of this.cabinetItems) {
                price += item.price;
            }
            this.setTotalPrice(price);

            return price;
        },
        plywoodSubstrateCostPerLinear() {
            const extraCosts = this.businessCosting.extra_costs;
            const linearPerBoard = extraCosts.plywoodSubstrate.linearPerBoard;

            if (
                linearPerBoard === 0 ||
                !this.measurements.base.linear ||
                this.measurements.island.linear
            ) {
                return 0;
            }

            const pricePerBoard = extraCosts.plywoodSubstrate.price;
            const totalLinear =
                this.measurements.base.linear + this.measurements.island.linear;

            const boardsRequired = Math.ceil(totalLinear / linearPerBoard);
            const totalBoardCost = boardsRequired * pricePerBoard;

            const pricePerLinear = totalBoardCost / totalLinear;

            return pricePerLinear;
        },
        cabinetItemsHeaders() {
            const unit = this.unit === 'mm' ? 'mm' : 'in';
            return [
                {
                    text: 'Type',
                    align: 'start',
                    value: 'type',
                },
                { text: 'Subtype', value: 'subtype' },
                { text: 'Collection', value: 'collectionType' },
                { text: 'Color', value: 'door.color' },
                { text: 'Door Type', value: 'door.type' },
                { text: `Door Surface`, value: 'door.surface' },
                { text: `Width (${unit})`, value: 'dimensions.width' },
                { text: `Height (${unit})`, value: 'dimensions.height' },
                { text: `Depth (${unit})`, value: 'dimensions.depth' },
                { text: 'Price', value: 'price' },
            ];
        },
        measurementsArr() {
            const items = [];
            for (const type in this.measurements) {
                items.push({
                    type: type,
                    ...this.measurements[type],
                });
            }

            return items;
        },
        measurementHeaders() {
            const unit = this.unit === 'mm' ? 'Meters' : 'Feet';
            return [
                { text: `Cabinet Type`, value: 'type' },
                { text: `Linear ${unit}`, value: 'linear' },
                { text: `Squared Facing ${unit}`, value: 'squaredFacing' },
                { text: `Cubic ${unit}`, value: 'cubic' },
            ];
        },
    },
    methods: {
        ...mapMutations('core', ['setPopupMsg']),
        ...mapMutations('business', ['setTotalPrice']),
        getSectionsPrice(type, sections, sectionsInformation) {
            for (const section of sectionsInformation) {
                let subtype;
                let newDimensions;
                let {
                    customization: {
                        cabinets: {
                            color: {
                                collection,
                                type: collectionType,
                                value: colorValue,
                            },
                            doorType,
                            surface,
                        },
                    },
                    dimensions,
                    isFiller,
                    sectionData,
                } = section;
                let isCooktop = false;

                if (this.unit === 'in') {
                    newDimensions = Utilities.convertToInches(dimensions);
                } else {
                    newDimensions =
                        Utilities.convertFromMMToBJSUnit(dimensions);
                }

                // check if isCooktop
                if (typeof sectionData === 'number') {
                    const appliance =
                        this.editorEngine.getApplianceById(sectionData);

                    if (appliance.type === 'cooktop') {
                        isCooktop = true;
                    }
                }

                // find section subtype
                if (isFiller) {
                    subtype = 'filler';
                } else if (typeof sectionData === 'number' && !isCooktop) {
                    const appliance =
                        this.editorEngine.getApplianceById(sectionData);

                    switch (appliance?.type) {
                        case 'sink':
                            subtype = 'sinkCabinet';
                            break;
                        case 'range':
                            subtype = 'rangeSpace';
                            break;
                        case 'dishwasher':
                            subtype =
                                appliance.subtype === 'freestanding'
                                    ? 'dishwasherCabinetFreestanding'
                                    : 'dishwasherCabinetBuiltin';
                            break;
                        case 'refrigerator':
                            subtype =
                                appliance.format === 'freestanding'
                                    ? 'fridgeCabinetFreestanding'
                                    : 'fridgeCabinetBuiltin';
                            break;
                        case 'hood':
                            subtype =
                                appliance.format === 'freestanding'
                                    ? 'hoodCabinetFreestanding'
                                    : 'hoodCabinetBuiltin';
                            break;
                        case 'oven':
                            switch (appliance.subtype) {
                                case 'ovenSignle':
                                    subtype = 'ovenCabinetSingle';
                                    break;
                                case 'ovenDouble':
                                    subtype = 'ovenCabinetDouble';
                                    break;
                                case 'ovenMicrowave':
                                    subtype = 'ovenMicrowaveCabinet';
                                    break;
                                case 'range':
                                    subtype = 'rangeSpace';
                                    break;
                            }
                            break;
                    }
                } else if (typeof sectionData === 'object') {
                    if (sectionData.isCornerCabinet) {
                        subtype = 'cornerCabinet';
                    }
                } else if (doorType) {
                    subtype = doorType;
                } else {
                    subtype = '1door';
                    doorType = '1door';
                }

                // get section height
                if (type === 'base' || type === 'island') {
                    newDimensions.height = this.unit === 'mm' ? 900 : 36;
                } else if (type === 'wall') {
                    const benchmarkHeight = this.unit === 'mm' ? 700 : 27;
                    if (newDimensions.height > benchmarkHeight) {
                        newDimensions.height = this.unit === 'mm' ? 900 : 30;
                    } else {
                        newDimensions.height = this.unit === 'mm' ? 700 : 27;
                    }
                } else if (type === 'tall') {
                    newDimensions.height = this.unit === 'mm' ? 2150 : 84.6;
                }

                // get price for section
                const catalogItem = this.businessCatalogs.filter((item) => {
                    if (
                        item.type === (type === 'island' ? 'base' : type) && // change to base if island
                        item.subtype === subtype &&
                        item.collection === collectionType &&
                        item.surface === surface &&
                        item.height === newDimensions.height
                    ) {
                        return item.price;
                    }
                });

                const costUnit = this.businessCosting.unit.value; // mm or in
                const costMethod = this.businessCosting.unit.method; // linear or squared-facing
                let itemPrice = catalogItem[0]?.price || 0;
                let price, costWidth, costHeight, costDepth;

                if (costUnit === 'mm') {
                    // convert unit to meters
                    costWidth = newDimensions.width / 1000;
                    costHeight = newDimensions.height / 1000;
                    costDepth = newDimensions.depth / 1000;
                } else {
                    // convert unit to feet
                    costWidth = newDimensions.width / 144;
                    costHeight = newDimensions.height / 144;
                    costDepth = newDimensions.depth / 144;
                }

                console.log('-------------------------');
                console.log(costWidth, 'x', costHeight, 'x', costDepth);
                console.log('type', type);
                console.log('subtype', subtype);

                const linearMeasures = costWidth;
                const squaredFacingMeasures = costWidth * costHeight;
                const cubicMeasures = costWidth * costHeight * costDepth;

                // cabinet cost
                if (costMethod === 'squared-facing') {
                    price = squaredFacingMeasures * itemPrice;
                } else if (costMethod === 'linear') {
                    price = linearMeasures * itemPrice;
                }

                console.log('base price', price);

                if (price) {
                    // add plywood sbstrate cost per linear
                    price +=
                        linearMeasures * this.plywoodSubstrateCostPerLinear;
                    console.log(
                        'plywood:',
                        linearMeasures * this.plywoodSubstrateCostPerLinear
                    );

                    // add import fee
                    price +=
                        cubicMeasures *
                        this.businessCosting.extra_costs.shippingImport.price;
                    console.log(
                        'import fee:',
                        cubicMeasures *
                            this.businessCosting.extra_costs.shippingImport
                                .price
                    );

                    // add install cost
                    price += this.installCost(
                        type,
                        linearMeasures,
                        squaredFacingMeasures
                    );

                    //  add countertop cost
                    if (type === 'base' || type === 'island') {
                        price += this.countertopCost(costWidth, costHeight);
                    }
                }

                sections.push({
                    type,
                    collectionType,
                    door: {
                        color: colorValue,
                        type: doorType,
                        surface,
                    },
                    dimensions: newDimensions,
                    subtype,
                    price: Utilities.roundToPrecision(price),
                });
            }

            return sections;
        },
        getMeasurements(type, sections, measurements) {
            for (const section of sections) {
                let newDimensions;
                let isFreestanding = false;
                let { dimensions, sectionData } = section;

                if (this.unit === 'in') {
                    newDimensions = Utilities.convertToInches(dimensions);
                } else {
                    newDimensions =
                        Utilities.convertFromMMToBJSUnit(dimensions);
                }

                // find if freestanding
                if (typeof sectionData === 'number') {
                    const appliance =
                        this.editorEngine.getApplianceById(sectionData);

                    if (
                        appliance.subtype === 'freestanding' ||
                        appliance.format === 'freestanding'
                    ) {
                        isFreestanding = true;
                    }
                }

                // get section height
                if (type === 'base') {
                    newDimensions.height = this.unit === 'mm' ? 900 : 36;
                } else if (type === 'wall') {
                    const benchmarkHeight = this.unit === 'mm' ? 700 : 27;
                    if (newDimensions.height > benchmarkHeight) {
                        newDimensions.height = this.unit === 'mm' ? 900 : 30;
                    } else {
                        newDimensions.height = this.unit === 'mm' ? 700 : 27;
                    }
                } else if (type === 'tall') {
                    newDimensions.height = this.unit === 'mm' ? 2150 : 84.6;
                }

                const costUnit = this.businessCosting.unit.value; // mm or in
                let costWidth, costHeight, costDepth;

                if (costUnit === 'mm') {
                    // convert unit to meters
                    costWidth = newDimensions.width / 1000;
                    costHeight = newDimensions.height / 1000;
                    costDepth = newDimensions.depth / 1000;
                } else {
                    // convert unit to feet
                    costWidth = newDimensions.width / 144;
                    costHeight = newDimensions.height / 144;
                    costDepth = newDimensions.depth / 144;
                }

                // Exlude freestanding from measurements
                // Include freestanding in tall cabinets
                if (!isFreestanding || (isFreestanding && type === 'tall')) {
                    measurements[type].linear +=
                        Utilities.roundToPrecision(costWidth);
                    measurements[type].squaredFacing +=
                        Utilities.roundToPrecision(costWidth * costHeight);
                    measurements[type].cubic += Utilities.roundToPrecision(
                        costWidth * costHeight * costDepth
                    );
                }
            }
            return measurements;
        },
        installCost(type, linearMeasures, squaredFacingMeasures) {
            const extraCosts = this.businessCosting.extra_costs;
            const baseCabinetPrice = extraCosts.installation.baseCabinetPrice;
            const wallCabinetPrice = extraCosts.installation.wallCabinetPrice;
            const tallCabinetPrice = extraCosts.installation.tallCabinetPrice;
            const unit = extraCosts.installation.unit;
            let cost, measures;

            if (unit === 'linear') {
                measures = linearMeasures;
            } else if (unit === 'squared') {
                measures = squaredFacingMeasures;
            }
            switch (type) {
                case 'base':
                case 'island':
                    cost = baseCabinetPrice * measures;
                    break;
                case 'wall':
                    cost = wallCabinetPrice * measures;
                    break;
                case 'tall':
                    cost = tallCabinetPrice * measures;
                    break;
            }

            console.log('install fee', cost);

            return cost;
        },
        countertopCost(width, depth) {
            const extraCosts = this.businessCosting.extra_costs;
            const price = extraCosts.countertop.price;
            const unit = extraCosts.countertop.unit;
            let cost, measures;

            if (unit === 'linear') {
                measures = width;
            } else if (unit === 'squared') {
                measures = width * depth;
            }

            cost = price * measures;

            console.log('countrtop fee', cost);

            return cost;
        },
        showInfo() {
            const message = this.businessCosting.extra_info;

            this.setPopupMsg(message);
        },
        formatter(number) {
            if (!this.currency) return;

            return new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: this.currency.ticker,
            }).format(number);
        },
    },
};
</script>
