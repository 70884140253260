import { Color3, Color4, Mesh, MeshBuilder } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { CabinetStructure } from './CabinetStructure';
import store from '@/store/index.js';

export class DoorBlock extends CabinetStructure {
    constructor() {
        super();
        this.enableSelection();
    }

    changeDoorType(type) {
        //QUESTION: what if it is a built in appliance
        if (['cornerSection', 'filler'].includes(this.parentSection.type)) {
            return;
        }
        if (!this.parentSection.appliance || ['sink', 'cooktop'].includes(this.parentSection?.appliance?.type)) {
            this.parentSection.buildingBlocks?.handleBlock?.dispose();
            this.meshComponent.getMesh().computeWorldMatrix(true);
            const currentMaterial = this.meshComponent.getMesh().material;
            switch (type) {
                case '1door':
                    this.switchToOneDoor();
                    break;

                case '2door':
                    this.switchTo2Doors();
                    break;

                case '4drawer':
                    this.switchTo4Drawers();
                    break;

                case '2drawer':
                    this.switchToTwoDrawers();
                    break;

                case '2small1BigDrawer':
                    this.switchToTwoSmallOneBigDrawer();
                    break;

                case '1door1Drawer':
                    this.switchToOneDoorOneDrawer();
                    break;

                case '2door1Drawer':
                    this.switchToTwoDoorOneDrawer();
                    break;
            }
            this.setActions();
            this.door.type = type;
            this.parentSection.customization.cabinets.doorType = type;

            //  if (!store.state.core.isProjectLoading) {
            this.editor.sceneComponent.save3D();
            //   }
            this.meshComponent.getMesh().material = currentMaterial;
        }
    }

    switchToOneDoor() {
        const rotation = this.parentSection.meshComponent.getMesh().rotation.y;
        const widthPerDoor = this.width;
        const door1 = MeshBuilder.CreateBox('door1', {
            width: widthPerDoor - Constants.GAP_BETWEEN_DOORS,
            height: this.height,
            depth: Constants.DOOR_DEPTH,
        });

        const newDoorBlock = Mesh.MergeMeshes([door1]);
        this.placeOneDoorHandle(newDoorBlock);
        newDoorBlock.position = this.meshComponent.getMesh().getBoundingInfo().boundingBox.centerWorld;
        newDoorBlock.rotation.y = rotation;
        this.meshComponent.getMesh().dispose();
        this.meshComponent.setMesh(newDoorBlock);
    }

    placeOneDoorHandle(newDoorBlock) {
        const handlePosition = newDoorBlock.position.clone();
        if ([Constants.cabinet.type.BASE, Constants.cabinet.type.ISLAND].includes(this.parentSection.parentCabinet.type)) {
            handlePosition.y += this.height / 2 - 0.135;
        } else if (this.parentSection.parentCabinet.type === Constants.cabinet.type.WALL) {
            handlePosition.y -= this.height / 2 - 0.04;
        }

        this.parentSection.buildingBlocks.handleBlock = this.placeHandle(handlePosition);
        this.parentSection.buildingBlocks.handleBlock.parent = newDoorBlock;
        if (this.width <= 0.15) {
            Utilities.resizeMeshInWidth(this.parentSection.buildingBlocks.handleBlock, 0.12);
        }
    }

    switchTo2Doors() {
        const rotation = this.parentSection.meshComponent.getMesh().rotation.y;
        const widthPerDoor = this.width / 2;
        const door1 = MeshBuilder.CreateBox('door1', {
            width: widthPerDoor - Constants.GAP_BETWEEN_DOORS,
            height: this.height,
            depth: Constants.DOOR_DEPTH,
        });
        const door2 = MeshBuilder.CreateBox('door2', {
            width: widthPerDoor - Constants.GAP_BETWEEN_DOORS,
            height: this.height,
            depth: Constants.DOOR_DEPTH,
        });

        door1.position.x -= widthPerDoor / 2;
        door2.position.x += widthPerDoor / 2;
        const newDoorBlock = Mesh.MergeMeshes([door1, door2]);

        this.placeTwoDoorHandles(newDoorBlock);

        newDoorBlock.position = this.meshComponent.getMesh().getBoundingInfo().boundingBox.centerWorld;
        newDoorBlock.rotation.y = rotation;
        this.meshComponent.getMesh().dispose();
        this.meshComponent.setMesh(newDoorBlock);
    }

    placeTwoDoorHandles(newDoorBlock) {
        const handleOnePosition = newDoorBlock.position.clone();
        if ([Constants.cabinet.type.BASE, Constants.cabinet.type.ISLAND].includes(this.parentSection.parentCabinet.type)) {
            handleOnePosition.y += this.height / 2 - 0.135;
        } else if (this.parentSection.parentCabinet.type === Constants.cabinet.type.WALL) {
            handleOnePosition.y -= this.height / 2 - 0.04;
        }

        handleOnePosition.x += this.width / 4;
        const handleOne = this.placeHandle(handleOnePosition);

        const handleTwoPosition = newDoorBlock.position.clone();

        if ([Constants.cabinet.type.BASE, Constants.cabinet.type.ISLAND].includes(this.parentSection.parentCabinet.type)) {
            handleTwoPosition.y += this.height / 2 - 0.135;
        } else if (this.parentSection.parentCabinet.type === Constants.cabinet.type.WALL) {
            handleTwoPosition.y -= this.height / 2 - 0.04;
        }

        handleTwoPosition.x -= this.width / 4;
        const handleTwo = this.placeHandle(handleTwoPosition);

        this.parentSection.buildingBlocks.handleBlock = Mesh.MergeMeshes([handleOne, handleTwo]);
        this.parentSection.buildingBlocks.handleBlock.parent = newDoorBlock;
    }

    switchTo4Drawers() {
        const rotation = this.parentSection.meshComponent.getMesh().rotation.y;
        const heightPerDoor = this.height / 4;
        const drawer1 = MeshBuilder.CreateBox('drawer1', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });
        const drawer2 = MeshBuilder.CreateBox('drawer2', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });
        const drawer3 = MeshBuilder.CreateBox('drawer3', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });
        const drawer4 = MeshBuilder.CreateBox('drawer4', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });

        drawer1.position.y -= (3 * heightPerDoor) / 2;
        drawer2.position.y += (3 * heightPerDoor) / 2;
        drawer3.position.y -= heightPerDoor / 2;
        drawer4.position.y += heightPerDoor / 2;

        const newDoorBlock = Mesh.MergeMeshes([drawer1, drawer2, drawer3, drawer4]);
        this.placeFourDrawersHandles(newDoorBlock);
        newDoorBlock.position = this.meshComponent.getMesh().getBoundingInfo().boundingBox.centerWorld;
        newDoorBlock.rotation.y = rotation;
        this.meshComponent.getMesh().dispose();
        this.meshComponent.setMesh(newDoorBlock);
    }

    placeFourDrawersHandles(newDoorBlock) {
        const handleOnePosition = newDoorBlock.position.clone();
        handleOnePosition.y += this.height / 8;
        const firstHandle = this.placeHandle(handleOnePosition);

        const handleTwoPosition = newDoorBlock.position.clone();
        handleTwoPosition.y += (3 * this.height) / 8;
        const secondHandle = this.placeHandle(handleTwoPosition);

        const handleThreePosition = newDoorBlock.position.clone();
        handleThreePosition.y -= this.height / 8;
        const thirdHandle = this.placeHandle(handleThreePosition);

        const handleFourPosition = newDoorBlock.position.clone();
        handleFourPosition.y -= (3 * this.height) / 8;
        const fourthHandle = this.placeHandle(handleFourPosition);

        this.parentSection.buildingBlocks.handleBlock = Mesh.MergeMeshes([firstHandle, secondHandle, thirdHandle, fourthHandle]);
        this.parentSection.buildingBlocks.handleBlock.parent = newDoorBlock;
    }

    switchToTwoDrawers() {
        const rotation = this.parentSection.meshComponent.getMesh().rotation.y;
        const heightPerDoor = this.height / 2;
        const drawer1 = MeshBuilder.CreateBox('drawer1', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });
        const drawer2 = MeshBuilder.CreateBox('drawer2', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });

        drawer1.position.y -= heightPerDoor / 2;
        drawer2.position.y += heightPerDoor / 2;
        const newDoorBlock = Mesh.MergeMeshes([drawer1, drawer2]);
        this.placeTwoDrawersHandles(newDoorBlock);
        newDoorBlock.position = this.meshComponent.getMesh().getBoundingInfo().boundingBox.centerWorld;
        newDoorBlock.rotation.y = rotation;
        this.meshComponent.getMesh().dispose();
        this.meshComponent.setMesh(newDoorBlock);
    }

    placeTwoDrawersHandles(newDoorBlock) {
        const handleOnePosition = newDoorBlock.position.clone();
        handleOnePosition.y += this.height / 4;
        const firstHandle = this.placeHandle(handleOnePosition);

        const handleTwoPosition = newDoorBlock.position.clone();
        handleTwoPosition.y -= this.height / 4;
        const secondHandle = this.placeHandle(handleTwoPosition);

        this.parentSection.buildingBlocks.handleBlock = Mesh.MergeMeshes([firstHandle, secondHandle]);
        this.parentSection.buildingBlocks.handleBlock.parent = newDoorBlock;
    }

    switchToTwoSmallOneBigDrawer() {
        const rotation = this.parentSection.meshComponent.getMesh().rotation.y;
        const heightPerDoor = this.height / 4;
        const drawer1 = MeshBuilder.CreateBox('drawer1', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });
        const drawer2 = MeshBuilder.CreateBox('drawer2', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });
        const drawer3 = MeshBuilder.CreateBox('drawer3', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: 2 * heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });

        drawer1.position.y += (3 * heightPerDoor) / 2;
        drawer2.position.y += heightPerDoor / 2;
        drawer3.position.y -= heightPerDoor;

        const newDoorBlock = Mesh.MergeMeshes([drawer1, drawer2, drawer3]);
        this.placeTwoSmallOneBigDrawerHandles(newDoorBlock);

        newDoorBlock.position = this.meshComponent.getMesh().getBoundingInfo().boundingBox.centerWorld;
        newDoorBlock.rotation.y = rotation;
        this.meshComponent.getMesh().dispose();
        this.meshComponent.setMesh(newDoorBlock);
    }

    placeTwoSmallOneBigDrawerHandles(newDoorBlock) {
        const handleOnePosition = newDoorBlock.position.clone();
        handleOnePosition.y += this.height / 8;
        const firstHandle = this.placeHandle(handleOnePosition);

        const handleTwoPosition = newDoorBlock.position.clone();
        handleTwoPosition.y += (3 * this.height) / 8;
        const secondHandle = this.placeHandle(handleTwoPosition);

        const handleThreePosition = newDoorBlock.position.clone();
        handleThreePosition.y -= this.height / 4;
        const thirdHandle = this.placeHandle(handleThreePosition);

        this.parentSection.buildingBlocks.handleBlock = Mesh.MergeMeshes([firstHandle, secondHandle, thirdHandle]);
        this.parentSection.buildingBlocks.handleBlock.parent = newDoorBlock;
    }

    switchToOneDoorOneDrawer() {
        const rotation = this.parentSection.meshComponent.getMesh().rotation.y;
        const heightPerDoor = this.height / 4;
        const drawer1 = MeshBuilder.CreateBox('drawer1', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });
        const door = MeshBuilder.CreateBox('drawer2', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: 3 * heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });

        drawer1.position.y += (3 * heightPerDoor) / 2;
        door.position.y -= heightPerDoor / 2;

        const newDoorBlock = Mesh.MergeMeshes([drawer1, door]);
        this.placeOneDoorOneDrawerHandles(newDoorBlock);
        newDoorBlock.position = this.meshComponent.getMesh().getBoundingInfo().boundingBox.centerWorld;
        newDoorBlock.rotation.y = rotation;
        this.meshComponent.getMesh().dispose();
        this.meshComponent.setMesh(newDoorBlock);
    }

    placeOneDoorOneDrawerHandles(newDoorBlock) {
        const handleOnePosition = newDoorBlock.position.clone();
        handleOnePosition.y += (2 * this.height) / 8 - 0.09;
        const firstHandle = this.placeHandle(handleOnePosition);

        const handleTwoPosition = newDoorBlock.position.clone();
        handleTwoPosition.y += (3 * this.height) / 8;
        const secondHandle = this.placeHandle(handleTwoPosition);

        this.parentSection.buildingBlocks.handleBlock = Mesh.MergeMeshes([firstHandle, secondHandle]);
        this.parentSection.buildingBlocks.handleBlock.parent = newDoorBlock;
    }

    switchToTwoDoorOneDrawer() {
        const rotation = this.parentSection.meshComponent.getMesh().rotation.y;
        const heightPerDoor = this.height / 4;
        const widthPerDoor = this.width / 2;
        const drawer1 = MeshBuilder.CreateBox('drawer1', {
            width: this.width - Constants.GAP_BETWEEN_DOORS,
            height: heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });
        const door1 = MeshBuilder.CreateBox('door1', {
            width: widthPerDoor - Constants.GAP_BETWEEN_DOORS,
            height: 3 * heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });

        const door2 = MeshBuilder.CreateBox('door2', {
            width: widthPerDoor - Constants.GAP_BETWEEN_DOORS,
            height: 3 * heightPerDoor - Constants.GAP_BETWEEN_DOORS,
            depth: Constants.DOOR_DEPTH,
        });

        drawer1.position.y += (3 * heightPerDoor) / 2;
        door1.position.y -= heightPerDoor / 2;
        door2.position.y -= heightPerDoor / 2;

        door1.position.x -= widthPerDoor / 2;
        door2.position.x += widthPerDoor / 2;

        const newDoorBlock = Mesh.MergeMeshes([drawer1, door1, door2]);
        this.placeTwoDoorOneDrawerHandles(newDoorBlock);
        newDoorBlock.position = this.meshComponent.getMesh().getBoundingInfo().boundingBox.centerWorld;
        newDoorBlock.rotation.y = rotation;
        this.meshComponent.getMesh().dispose();
        this.meshComponent.setMesh(newDoorBlock);
    }

    placeTwoDoorOneDrawerHandles(newDoorBlock) {
        const drawerHandle = newDoorBlock.position.clone();
        drawerHandle.y += (3 * this.height) / 8;
        const firstHandle = this.placeHandle(drawerHandle);

        const handleOnePosition = newDoorBlock.position.clone();
        handleOnePosition.y += this.height / 8;
        handleOnePosition.x += this.width / 4;
        const secondHandle = this.placeHandle(handleOnePosition);

        const handleTwoPosition = newDoorBlock.position.clone();
        handleTwoPosition.y += this.height / 8;
        handleTwoPosition.x -= this.width / 4;
        const thirdHandle = this.placeHandle(handleTwoPosition);

        this.parentSection.buildingBlocks.handleBlock = Mesh.MergeMeshes([firstHandle, secondHandle, thirdHandle]);
        this.parentSection.buildingBlocks.handleBlock.parent = newDoorBlock;
    }

    placeHandle(position) {
        const handle = this.editor.models3D.handle.meshComponent.getMesh().clone();
        handle.position = position;
        handle.isVisible = true;
        handle.setEnabled(true);
        return handle;
    }
}
