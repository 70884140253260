<template>
    <v-form ref="inputs" v-model="isValid">
        <p
            v-if="$vuetify.breakpoint.xsOnly"
            class="text-overline text-center mb-0"
        >
            {{ appliance.type }}, {{ appliance.subtype }}
        </p>
        <v-tabs
            v-model="tab"
            background-color="indigo"
            centered
            height="38"
            dark
            slider-size="5"
            show-arrows
        >
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-btn
                color="indigo lighten-2"
                small
                class="my-auto"
                @click="deselectObjects"
            >
                <v-icon color="indigo darken-3">{{ mdiArrowLeftCircle }}</v-icon>
            </v-btn>

            <v-tab
                class="float-left"
                v-if="$vuetify.breakpoint.smAndUp"
                disabled
                >{{ appliance.type }}, {{ appliance.subtype }}</v-tab
            >
            <v-tab v-if="applianceProps.dimensions && step !== 4"
                ><v-icon>{{ mdiTapeMeasure }}</v-icon></v-tab
            >
            <v-tab><v-icon>{{ mdiPaletteOutline }}</v-icon></v-tab>
            <v-tab v-if="$vuetify.breakpoint.xsOnly && step !== 4"
                ><v-icon>{{ mdiDelete }}</v-icon></v-tab
            >

            <v-tabs-items continuous v-model="tab">
                <!-- Empty for appliance type/subtype -->
                <v-tab-item v-if="$vuetify.breakpoint.smAndUp"></v-tab-item>

                <!-- Dimensions Tab -->
                <v-tab-item v-if="applianceProps.dimensions && step !== 4">
                    <v-container fluid>
                        <v-row justify="center" class="indigo lighten-5 pt-2">
                            <!-- Size Inputs -->
                            <v-col
                                v-for="(value, key) in appliance.dimensions"
                                :key="key"
                                cols="4"
                                sm="3"
                                class="pa-2"
                            >
                                <v-combobox
                                    v-model="appliance.dimensions[key]"
                                    @input="resize(key)"
                                    :items="
                                        applianceProps.dimensions[key].items[
                                            selectedUnit
                                        ]
                                    "
                                    :label="key"
                                    ref="key"
                                    outlined
                                    :dense="$vuetify.breakpoint.xsOnly"
                                    required
                                    subtype="number"
                                    :rules="[
                                        rules.required,
                                        rules.between(
                                            limits[key].min[selectedUnit],
                                            limits[key].max[selectedUnit]
                                        ),
                                    ]"
                                    :suffix="selectedUnit"
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Press <kbd>enter</kbd> to
                                                    update
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-col>

                            <!-- Dispose Button -->
                            <v-col
                                cols="1"
                                class="pa-2 text-center"
                                v-if="!$vuetify.breakpoint.xsOnly"
                            >
                                <v-btn
                                    @click="dispose"
                                    color="red"
                                    dark
                                    icon
                                    large
                                    tile
                                >
                                    <v-icon dark>{{ mdiDelete }}</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

                <!-- Appearance Tab -->
                <v-tab-item>
                    <v-container fluid>
                        <v-row justify="center" class="indigo lighten-5 pt-2">
                            <!-- Direction -->
                            <v-col cols="auto" class="pa-2">
                                <v-btn-toggle
                                    v-if="appliance.direction"
                                    v-model="appliance.direction"
                                    @change="changeDirection"
                                    tile
                                    color="primary"
                                    mandatory
                                >
                                    <v-btn
                                        v-for="direction in applianceProps.directionItems"
                                        :key="direction"
                                        :value="direction"
                                    >
                                        {{ direction }}
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>

                            <!-- Color -->
                            <v-col cols="auto" class="pa-2">
                                <v-btn-toggle
                                    v-if="appliance.color"
                                    v-model="appliance.color"
                                    @change="changeColor"
                                    tile
                                    color="primary"
                                    mandatory
                                >
                                    <v-btn
                                        v-for="color in applianceProps.colorItems"
                                        :key="color"
                                        :value="color"
                                    >
                                        {{ color }}
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>

                            <!-- Format -->
                            <v-col cols="auto" class="pa-2">
                                <v-btn-toggle
                                    v-if="appliance.format"
                                    v-model="appliance.format"
                                    @change="changeFormat"
                                    tile
                                    color="primary"
                                    mandatory
                                >
                                    <v-btn
                                        v-for="format in applianceProps.formatItems"
                                        :key="format"
                                        :value="format"
                                    >
                                        {{ format }}
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>

                            <!-- Dispose Button -->
                            <v-col
                                cols="1"
                                class="pa-2 text-center"
                                v-if="!$vuetify.breakpoint.xsOnly && step !== 4"
                            >
                                <v-btn
                                    @click="dispose"
                                    color="red"
                                    dark
                                    icon
                                    large
                                    tile
                                >
                                    <v-icon dark>{{ mdiDelete }}</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

                <!-- Delete Tab -->
                <v-tab-item v-if="$vuetify.breakpoint.xsOnly && step !== 4">
                    <v-container fluid>
                        <v-row justify="center" class="indigo lighten-5 pt-2">
                            <v-col cols="2" class="pa-2">
                                <v-btn
                                    @click="dispose"
                                    color="red"
                                    dark
                                    icon
                                    large
                                    tile
                                >
                                    <v-icon dark>{{ mdiDelete }}</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mdiDelete, mdiArrowLeftCircle, mdiTapeMeasure, mdiPaletteOutline } from '@mdi/js';

export default {
    data() {
        return {
            tab: null,
            tabs: ['dimensions', 'appearance', 'delete'],
            isValid: true,
            rules: {
                required: (value) => !!value || value === 0 || 'Required',
                between(min, max) {
                    return (value) =>
                        (value >= min && value <= max) ||
                        `Must be between ${min} and ${max}`;
                },
            },
            mdiDelete: mdiDelete,
            mdiTapeMeasure: mdiTapeMeasure,
            mdiArrowLeftCircle: mdiArrowLeftCircle,
            mdiPaletteOutline: mdiPaletteOutline,
        };
    },
    computed: {
        ...mapGetters('core', ['selectedUnit', 'step']),
        ...mapState('appliances', {
            appliance: (state) => state.selectedAppliance,
            appliances: (state) => state.appliances,
        }),
        applianceProps() {
            const type = this.appliance.type,
                subtype = this.appliance.subtype,
                appliance = this.appliances[type].subtype.find(
                    (t) => t.id === subtype
                ),
                dimensions = Object.keys(appliance.dimensions).length
                    ? appliance.dimensions
                    : null,
                directionItems = appliance.direction?.items,
                colorItems = appliance.color?.items,
                formatItems = appliance.format?.items;
            return { dimensions, directionItems, colorItems, formatItems };
        },
        limits() {
            const limits = {};

            Object.keys(this.applianceProps.dimensions).forEach((key) => {
                limits[key] = {
                    min: this.applianceProps.dimensions[key].min,
                    max: this.applianceProps.dimensions[key].max,
                };
            });

            return limits;
        },
    },
    methods: {
        resize(key) {
            const oldVal = this.appliance.dimensions[key];

            if (this.$refs.inputs.validate()) {
                console.log('key', key);
                console.log(
                    'this.appliance.dimensions[key]',
                    this.appliance.dimensions[key]
                );
                // this.appliance.dimensions[key] = event.target.value;
                this.appliance.resize(this.appliance.dimensions[key], key);
            } else {
                this.appliance.dimensions[key] = oldVal;
            }
        },
        changeDirection(event) {
            console.log('change direction', event);
            // this.appliance.direction(event);
        },
        changeColor(event) {
            console.log('change color', event);
            this.appliance.changeColor(event);
        },
        changeFormat(event) {
            console.log('change format', event);
            // this.appliance.format(event);
        },
        dispose() {
            this.appliance.dispose();
        },
        deselectObjects() {
            // turn off objects bounding boxes
            this.$store.state.appliances.selectedAppliance.deselect();
            this.$store.commit('appliances/setSelectedAppliance', null);
        },
    },
};
</script>
