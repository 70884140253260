import { Mesh, SceneLoader, Texture } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { BaseAppliance } from './BaseAppliance';
import store from '@/store/index.js';

export class Oven extends BaseAppliance {
    constructor() {
        super();
        this.rangeParts = {
            upperPart: null,
            lowerPart: null,
        };
    }

    fillEmptyDimensionsFromVue() {
        this.height = 0.6;
        this.depth = 0.6;
        if (this.subtype === Constants.oven.subtype.RANGE) {
            this.height = 0.85;
        }
        if (this.editor.sceneInfo.unit === 'in') {
            this.depth = 0.6095999999999999;
            this.height = 0.6095999999999999;
            if (this.subtype === Constants.oven.subtype.RANGE) {
                this.height = 0.85;
            }
        }
    }

    async setModel() {
        if (this.subtype === Constants.oven.subtype.RANGE) {
            const result = await SceneLoader.ImportMeshAsync('', 'range.babylon', '', this.editor.sceneComponent.get());
            this.meshComponent.setMesh(result.meshes[Utilities.getIndexFromMeshId('range', result.meshes)]);
            this.setRangeParts();
            this.resizeRange();
            this.addRangeTexture();
        } else {
            const result = await SceneLoader.ImportMeshAsync('', 'oven.babylon', '', this.editor.sceneComponent.get());
            this.meshComponent.setMesh(result.meshes[Utilities.getIndexFromMeshId('oven', result.meshes)]);
            this.resizeToCurrentDimensions();
            this.addTexture();
        }
    }

    setRangeParts() {
        const children = this.meshComponent.getMesh().getChildren();
        this.rangeParts.upperPart = children[1];
        this.rangeParts.lowerPart = children[0];
    }

    resizeRange() {
        this.resizeRangeWidth();
        this.resizeRangeHeight();
        this.resizeRangeDepth();
    }
    resizeRangeWidth() {
        const upperPartDimensions = Utilities.getMeshDimensions(this.rangeParts.upperPart);
        this.rangeParts.upperPart.scaling.x = this.width / upperPartDimensions.width;

        const lowerPartDimensions = Utilities.getMeshDimensions(this.rangeParts.lowerPart);
        this.rangeParts.lowerPart.scaling.x = this.width / lowerPartDimensions.width;
    }

    resizeRangeHeight() {
        const lowerPartDimensions = Utilities.getMeshDimensions(this.rangeParts.lowerPart);
        this.rangeParts.lowerPart.scaling.y = this.height / lowerPartDimensions.height;
    }

    resizeRangeDepth() {
        const upperPartDimensions = Utilities.getMeshDimensions(this.rangeParts.upperPart);
        this.rangeParts.upperPart.scaling.z = (this.depth - 0.065) / upperPartDimensions.depth;

        const lowerPartDimensions = Utilities.getMeshDimensions(this.rangeParts.lowerPart);
        this.rangeParts.lowerPart.scaling.z = this.depth / lowerPartDimensions.depth;
    }

    addTexture() {
        this.editor.PBRComponent.setPBRMetallicRoughnessToBuildingBlock(this.meshComponent.getMesh(), 'Oven', 'appliances/');
        let color = store.state.appliances.appliances[this.type].subtype[0].color.items[0];

        if (color === 'light') {
            const filePath = require('@/assets/textures/appliances/Oven'.concat('/diffuseTextureLight.jpg'));
            this.meshComponent.getMesh().material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        }
    }

    addRangeTexture() {
        this.meshComponent.setMesh(Mesh.MergeMeshes([this.rangeParts.upperPart, this.rangeParts.lowerPart]));
        this.editor.PBRComponent.setPBRMetallicRoughnessToBuildingBlock(this.meshComponent.getMesh(), 'Range', 'appliances/');
        let color = store.state.appliances.appliances[this.type].subtype[0].color.items[0];

        if (color === 'light') {
            const filePath = require('@/assets/textures/appliances/Range'.concat('/diffuseTextureLight.jpg'));
            this.meshComponent.getMesh().material.baseTexture.dispose();
            this.meshComponent.getMesh().material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        }
    }

    changeColor(color) {
        this.color = color;
        let textureType = 'Oven';
        if (this.subtype === 'range') {
            textureType = 'Range';
        }

        if (color == 'dark') {
            const filePath = require('@/assets/textures/appliances/'.concat(textureType.concat('/diffuseTexture.jpg')));
            this.model.material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        } else if (color === 'light') {
            const filePath = require('@/assets/textures/appliances/'.concat(textureType.concat('/diffuseTextureLight.jpg')));
            this.model.material.baseTexture = new Texture(filePath, this.editor.sceneComponent.get());
        }
    }
}
