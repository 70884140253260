var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("span", { staticClass: "text-h5" }, [
          _vm._v("Add " + _vm._s(_vm.fixture.name) + ":"),
        ]),
      ]),
      _c(
        "v-card-text",
        { staticClass: "px-2" },
        [
          _c(
            "v-container",
            [
              _vm.fixture.type
                ? _c(
                    "v-item-group",
                    {
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.setModalFixtureType,
                        callback: function ($$v) {
                          _vm.setModalFixtureType = $$v
                        },
                        expression: "setModalFixtureType",
                      },
                    },
                    [
                      _c("h4", [_vm._v("Type:*")]),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        _vm._l(_vm.fixture.type, function (obj, key) {
                          return _c(
                            "v-col",
                            {
                              key: key,
                              staticClass: "px-1",
                              attrs: { cols: "4" },
                            },
                            [
                              _c("v-item", {
                                attrs: { value: key },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ active, toggle }) {
                                        return [
                                          _c("v-hover", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ hover }) {
                                                    return [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          class: {
                                                            "on-hover": hover,
                                                          },
                                                          attrs: {
                                                            color: active
                                                              ? "blue lighten-4"
                                                              : "",
                                                            elevation: hover
                                                              ? 16
                                                              : 2,
                                                          },
                                                          on: { click: toggle },
                                                        },
                                                        [
                                                          _c("v-img", {
                                                            staticClass: "pa-2",
                                                            attrs: {
                                                              src: obj.img,
                                                              height: "150",
                                                              contain: "",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "my-4 text-body-2 text-center text-subtitle-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(obj.name)
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.isValid,
                    callback: function ($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid",
                  },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    _vm._l(
                      _vm.fixture.type[_vm.fixtureType].dimensions,
                      function (obj, key) {
                        return _c(
                          "v-col",
                          { key: key, attrs: { cols: "6", sm: "4" } },
                          [
                            _c("v-text-field", {
                              ref: "key",
                              refInFor: true,
                              attrs: {
                                label: key,
                                min: obj.min[_vm.selectedUnit],
                                max: obj.max[_vm.selectedUnit],
                                suffix: _vm.selectedUnit,
                                outlined: "",
                                required: "",
                                type: "number",
                                rules: [
                                  _vm.rules.required,
                                  _vm.rules.between(
                                    obj.min[_vm.selectedUnit],
                                    obj.max[_vm.selectedUnit]
                                  ),
                                ],
                              },
                              model: {
                                value: obj.value[_vm.selectedUnit],
                                callback: function ($$v) {
                                  _vm.$set(
                                    obj.value,
                                    _vm.selectedUnit,
                                    _vm._n($$v)
                                  )
                                },
                                expression: "obj.value[selectedUnit]",
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("small", [_vm._v("*indicates required field")]),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("div", { staticClass: "flex-grow-1" }),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: { click: _vm.closeDialog },
            },
            [_vm._v("Cancel ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: {
                color: "blue darken-1",
                large: "",
                disabled: !_vm.isValid,
              },
              on: { click: _vm.addObj },
            },
            [_vm._v("Add ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }