var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: {
        text: "",
        small: _vm.$vuetify.breakpoint.mdAndDown,
        color: "red",
      },
      on: { click: _vm.resetProject },
    },
    [_vm._v(" Reset ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }