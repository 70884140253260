import config from './config';

export const fixtures = {
    namespaced: true,
    state: {
        fixtures: config,
        modalFixture: '',
        modalType: 'standard',
        selectedFixture: null,
        openDialog: false,
        typedWallDim: '',
        typedWallEnd: 0,
    },
    mutations: {
        openDialog(state, openDialog) {
            state.openDialog = openDialog;
        },
        setmodalFixture(state, modalFixture) {
            state.modalFixture = modalFixture;
        },
        setModalFixtureType(state, type) {
            state.modalType = type;
        },
        setSelectedFixture(state, fixture) {
            state.selectedFixture = fixture;
            if (process.env.NODE_ENV === 'development')
                console.log('selected fixture:', fixture);
        },
        addObj(state, { selectedUnit, editorEngine }) {
            let data = {
                type: state.modalFixture.id,
                subType: state.modalType,
                dimensions: {},
                currentUnit: selectedUnit,
            };

            let dimensions =
                state.modalFixture.type[state.modalType].dimensions;

            Object.keys(dimensions).forEach((key) => {
                data.dimensions[key] = dimensions[key].value[data.currentUnit];
            });

            editorEngine.floorplan.addFixture(data);
        },
        setTypedWallDim(state, typedWallDim) {
            state.typedWallDim = typedWallDim;
        },
        setTypedWallEnd(state, typedWallEnd) {
            state.typedWallEnd = typedWallEnd;
        },
        resetTyping(state) {
            state.typedWallDim = '';
            state.typedWallEnd = 0;
        },
    },
    actions: {
        addObj({ commit, rootGetters }) {
            const selectedUnit = rootGetters['core/selectedUnit'],
                editorEngine = rootGetters['core/editorEngine'];

            commit('addObj', { selectedUnit, editorEngine });
        },
    },
};
