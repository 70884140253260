var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticStyle: { position: "absolute", bottom: "0", left: "0", right: "0" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        [
          _c("v-col"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-container",
                { staticClass: "mx-auto mb-3" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { elevation: "2", fab: "" },
                          on: { click: _vm.zoomIn },
                        },
                        [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiPlus))])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { elevation: "2", fab: "" },
                          on: { click: _vm.zoomOut },
                        },
                        [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiMinus))])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _vm.costing &&
              _vm.cabinetData &&
              _vm.step === 4 &&
              !_vm.isProjectLoading &&
              _vm.showCosting
                ? _c(
                    "v-sheet",
                    {
                      staticClass: "pa-4",
                      attrs: {
                        color: "green lighten-3",
                        elevation: "4",
                        "max-width": "500",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-0 pa-2 text-h6 text-center" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatter(_vm.totalPrice)) +
                                  " "
                              ),
                            ]
                          ),
                          _c("Costing"),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }