import { MeshBuilder } from '@babylonjs/core';
import { Utilities } from '../../../Tools/utilities';
import { CabinetStructure } from '../../BuildingBlocks/CabinetStructure';
import { DoorBlock } from '../../BuildingBlocks/DoorBlock';
import { Skirting } from '../../BuildingBlocks/Skirting';
import { Model } from '../Model';
import { ResizingManager } from './ResizingManager';

export class TallFillerSection extends Model {
    constructor() {
        super();
    }

    async setModel() {
        const result = await this.import('tallFiller.babylon');
        this.meshComponent.setMesh(Utilities.getElementByMeshName('tallFiller', result.meshes));
        this.meshComponent.getMesh().setEnabled(false);
        this.meshComponent.getMesh().name = 'tallFiller';
        this.setParts();
        this.resizingManager = this.registerComponent(ResizingManager);
    }

    resize(dimensions) {
        this.resizingManager.parts = this.parts;
        this.resizingManager.resizeWidth(dimensions);
        this.resizingManager.resizeDepth(dimensions);
        this.resizingManager.resizeHeight(dimensions.height + 0.118);
    }

    mergeModel() {
        const fillerModel = MeshBuilder.CreateBox('tall-cabinet-model', { width: 0.1, depth: 0.1, height: 0.1 });
        fillerModel.visibility = 0;

        const filler = { mesh: fillerModel, buildingBlocks: {} };

        filler.buildingBlocks.innerStructure = this.getInnerStructureBlock(filler.mesh);
        filler.buildingBlocks.doorBlock = this.getDoorBlock(filler.mesh);
        filler.buildingBlocks.skirtingBlock = this.getSkirtingBlock(filler.mesh);

        return filler;
    }

    getInnerStructureBlock(parentMesh) {
        const meshes = [];
        Object.keys(this.parts.cabinet).forEach((key) => {
            meshes.push(this.parts.cabinet[key].mesh);
        });
        const innerStructure = this.editor.addObject(CabinetStructure);
        innerStructure.setParentSection(this.currentParentSection);
        innerStructure.create(meshes, 'cabinets');
        innerStructure.meshComponent.setParent(parentMesh);
        return innerStructure;
    }

    getSkirtingBlock(parentMesh) {
        const meshes = [this.parts.skirting.mesh];
        const skirtingBlock = this.editor.addObject(Skirting);
        skirtingBlock.setParentSection(this.currentParentSection);
        skirtingBlock.enableSelection();
        skirtingBlock.create(meshes, 'skirting');
        skirtingBlock.meshComponent.setParent(parentMesh);
        return skirtingBlock;
    }

    getDoorBlock(parentMesh) {
        const meshes = [this.parts.door1.mesh];
        const doorBlock = this.editor.addObject(DoorBlock);
        doorBlock.setParentSection(this.currentParentSection);
        doorBlock.enableSelection();
        doorBlock.create(meshes, 'cabinets');
        doorBlock.meshComponent.setParent(parentMesh);
        return doorBlock;
    }

    getResizingDimensions(sectionWidth = null) {
        return {
            width: sectionWidth,
            depth: this.parentCabinet.depth,
            height: this.parentCabinet.height,
        };
    }
}
