<template>
    <v-col
        class="d-flex flex-column justify-center flex-shrink-1 height"
        cols="4"
        md="3"
    >
        <v-btn
            color="green"
            class="white--text"
            :small="$vuetify.breakpoint.mdAndDown"
            @click="
                setStep(4);
                generateDesign();
            "
        >
            {{ $vuetify.breakpoint.mdAndDown ? 'Next' : 'Generate Design' }}
        </v-btn>

        <v-container class="text-center py-0">
            <v-btn
                text
                :small="$vuetify.breakpoint.mdAndDown"
                @click="setStep(2)"
            >
                Previous
            </v-btn>

            <resetBtn />
        </v-container>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import resetBtn from '@/components/resetBtn.vue';

export default {
    components: {
        resetBtn,
    },
    computed: {
        ...mapGetters('core', ['editorEngine']),
    },
    methods: {
        setStep(step) {
            this.$store.commit('core/setStep', step);
        },
        generateDesign() {
            this.$store.getters['core/editorEngine'].generateDesign();
        },
    },
};
</script>
