import { Color3, CubeTexture, PBRMaterial, PBRMetallicRoughnessMaterial, Texture } from '@babylonjs/core';
import { TexturePool } from '../Editor3D/TexturePool';
import { Component } from './Base/Component';

export class PBRComponent extends Component {
    constructor() {
        super();
    }

    setPBRMetallicRoughnessToBuildingBlock(buildingBlock, textureType, folder) {
        if (!this[textureType]) {
            this[textureType] = new PBRMetallicRoughnessMaterial('metallicRoughnessMaterial', this.editor.sceneComponent.get());
        }
        let pbr = this[textureType];
        if (!buildingBlock) {
            return;
        }
        if (textureType !== 'noPattern') {
            pbr.baseTexture = new Texture(
                require('@/assets/textures/'.concat(folder).concat(textureType.concat('/diffuseTexture.jpg'))),
                this.editor.sceneComponent.get()
            );
        }
        pbr.metallicRoughnessTexture = new Texture(
            require('@/assets/textures/'.concat(folder).concat(textureType.concat('/metalness.jpg'))),
            this.editor.sceneComponent.get()
        );
        pbr.metallic = 0.9;
        pbr.roughness = 0.2;
        pbr.normalTexture = new Texture(
            require('@/assets/textures/'.concat(folder).concat(textureType.concat('/normalMap.jpg'))),
            this.editor.sceneComponent.get()
        );
        pbr.environmentTexture = new CubeTexture.CreateFromPrefilteredData(
            require('@/assets/textures/envSpecularHDR.dds').default,
            this.editor.sceneComponent.get()
        );
        buildingBlock.material = pbr.clone();
    }

    getTextureFromPool(collectionType, pattern, color, microsurface, clearCoat, folder, workflow = 'glossiness') {
        if (TexturePool[collectionType]) {
            if (TexturePool[collectionType][pattern]) {
                if (TexturePool[collectionType][pattern][color]) {
                    if (TexturePool[collectionType][pattern][color][microsurface * 1000]) {
                        if (TexturePool[collectionType][pattern][color][microsurface * 1000][clearCoat]) {
                            if (TexturePool[collectionType][pattern][color][microsurface * 1000][clearCoat].texture) {
                                return TexturePool[collectionType][pattern][color][microsurface * 1000][clearCoat].texture;
                            }
                        }
                    }
                }
            }
        }

        if (!TexturePool[collectionType]) {
            TexturePool[collectionType] = {};
        }
        if (!TexturePool[collectionType][pattern]) {
            TexturePool[collectionType][pattern] = {};
        }
        if (!TexturePool[collectionType][pattern][color]) {
            TexturePool[collectionType][pattern][color] = {};
        }
        if (!TexturePool[collectionType][pattern][color][microsurface * 1000]) {
            TexturePool[collectionType][pattern][color][microsurface * 1000] = {};
        }

        if (!TexturePool[collectionType][pattern][color][microsurface * 1000][clearCoat]) {
            TexturePool[collectionType][pattern][color][microsurface * 1000][clearCoat] = {};
        }

        TexturePool[collectionType][pattern][color][microsurface * 1000][clearCoat].texture = this.createPBRMaterial(
            pattern,
            Color3.FromHexString(color),
            microsurface,
            clearCoat,
            folder,
            workflow
        );
        return TexturePool[collectionType][pattern][color][microsurface * 1000][clearCoat].texture;
    }

    createPBRMaterial(pattern, color = new Color3(1, 1, 1), microsurface, clearCoat, folder, workflow) {
        if (workflow === 'glossiness') {
            return this.createGlossinessWorkflowPBRMaterial(pattern, color, microsurface, clearCoat, folder);
        } else if (workflow === 'metallic') {
            return this.createMetallicWorkflowPBRMaterial(pattern, folder);
        }
    }

    createGlossinessWorkflowPBRMaterial(pattern, color, microsurface, clearCoat, folder) {
        let pbr = new PBRMaterial(pattern + ' specularGlossinessMaterial', this.editor.sceneComponent.get());
        pbr.maxSimultaneousLights = 9;
        pbr.useMicroSurfaceFromReflectivityMapAlpha = true;
        pbr.microSurface = microsurface;
        pbr.clearCoat.isEnabled = clearCoat;
        pbr.albedoColor = color;
        if (pattern !== 'noPattern') {
            pbr.albedoTexture = new Texture(
                require('@/assets/textures/'.concat(folder).concat(pattern.concat('/diffuseTexture.jpg'))),
                this.editor.sceneComponent.get()
            );
        }
        pbr.reflectivityTexture = new Texture(
            require('@/assets/textures/'.concat(folder).concat(pattern.concat('/reflection.jpg'))),
            this.editor.sceneComponent.get()
        );
        pbr.bumpTexture = new Texture(
            require('@/assets/textures/'.concat(folder).concat(pattern.concat('/normalMap.jpg'))),
            this.editor.sceneComponent.get()
        );
        pbr.reflectionTexture = this.editor.cubeTexture;
        return pbr;
    }

    createMetallicWorkflowPBRMaterial(textureType, folder) {
        let pbr = new PBRMetallicRoughnessMaterial('metallicRoughnessMaterial', this.editor.sceneComponent.get());
        //pbr.maxSimultaneousLights = 9;

        if (textureType !== 'noPattern') {
            pbr.baseTexture = new Texture(
                require('@/assets/textures/'.concat(folder).concat(textureType.concat('/diffuseTexture.jpg'))),
                this.editor.sceneComponent.get()
            );
        }
        pbr.metallicRoughnessTexture = new Texture(
            require('@/assets/textures/'.concat(folder).concat(textureType.concat('/metalness.jpg'))),
            this.editor.sceneComponent.get()
        );
        pbr.metallic = 0.9;
        pbr.roughness = 0.2;
        pbr.normalTexture = new Texture(
            require('@/assets/textures/'.concat(folder).concat(textureType.concat('/normalMap.jpg'))),
            this.editor.sceneComponent.get()
        );
        pbr.environmentTexture = this.editor.cubeTexture;
        return pbr;
    }
}
