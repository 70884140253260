<template>
    <v-container
        fluid
        style="position: absolute; bottom: 0; left: 0; right: 0"
    >
        <v-row>
            <v-col></v-col>
            <!-- Buttons -->
            <v-col cols="6">
                <v-container class="mx-auto mb-3">
                    <v-row justify="center">
                        <v-btn elevation="2" fab class="mr-2" @click="zoomIn"
                            ><v-icon> {{ mdiPlus }}</v-icon></v-btn
                        >
                        <v-btn elevation="2" fab class="mr-2" @click="zoomOut"
                            ><v-icon> {{ mdiMinus }}</v-icon></v-btn
                        >
                    </v-row>
                </v-container>
            </v-col>

            <!-- costing module price -->
            <v-col>
                <v-sheet
                    v-if="costing && cabinetData && step === 4 && !isProjectLoading && showCosting"
                    class="pa-4"
                    color="green lighten-3"
                    elevation="4"
                    max-width="500"
                >
                    <v-row align="center" justify="center">
                        <p class="mb-0 pa-2 text-h6 text-center">
                            {{ formatter(totalPrice) }}
                        </p>
                        <Costing/>
                    </v-row>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mdiPlus, mdiMinus } from '@mdi/js';
import { mapGetters, mapState } from 'vuex';

import Costing from '@/components/Costing.vue';

export default {
    components: {
        Costing,
    },
    data() {
        return {
            mdiPlus,
            mdiMinus,
        };
    },
    computed: {
        ...mapGetters('core', ['editorEngine']),
        ...mapState('core', ['step', 'cabinetData', 'isProjectLoading']),
        ...mapState('business', ['costing', 'totalPrice', 'isStandardDomain']),
        businessCosting() {
            return this.costing.business.business_costing;
        },
        currency() {
            if (!this.businessCosting) return;

            const currency = this.businessCosting.currency;

            return {
                symbol: currency.symbol,
                ticker: currency.ticker,
            };
        },
        showCosting() {
            if (this.isStandardDomain) {
                return true;
            } else {
                // if costing module turned on and has projectId
                if (this.businessCosting.unit.isActive && this.$route.params.projectId) {
                    return true;
                }
            }

            return false
        }
    },
    methods: {
        zoomIn() {
            this.editorEngine.cameraComponent.zoomIn();
        },
        zoomOut() {
            this.editorEngine.cameraComponent.zoomOut();
        },
        formatter(number) {
            if (!this.currency) return;
            
            return new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: this.currency.ticker,
            }).format(number);
        },
    },
};
</script>
