import store from '@/store/index.js';
import { Color3, Mesh, StandardMaterial, Vector2, Vector3, Matrix, MeshBuilder } from '@babylonjs/core';
import { Rectangle, TextBlock } from '@babylonjs/gui';
import { TextPlaneWidget } from '../../Widgets';

export class FloorplanMeasurementWidget {
    constructor(a, b, args, parent) {
        this.parent = parent;
        this.uID = 'mA' + Date.now();
        this.a = a;
        this.b = b;
        this.mAnchor = new MeshBuilder.CreateBox(this.uID, { width: 0.1, height: 0.1, depth: 0.1 });
        this.mAnchor.position = a.clone().add(b.clone()).scale(0.5);

        this.dirty = true;

        this.args = args;

        this.parts = {
            lines: null,
            textBlock: null,
            rect: null,
        };
        this.angleValue = 0;
        if (this.parent.corners.length >= 2) {
            this.angleTextplaneWidget = this.createAngleTextForRibbon(
                Math.round(this.angleValue),
                this.parent.getLastCorner().position
            );
        }

        switch (args.type) {
            case 'no-lines':
                this.parts.rect = new Rectangle();
                this.parts.rect.height = '24px';
                this.parts.rect.cornerRadius = 6;
                this.parts.rect.thickness = 0;
                this.parts.rect.background = 'black';

                this.parts.textBlock = new TextBlock();

                this.parts.textBlock.text = Vector2.Distance(this.a, this.b) + '';
                this.parts.textBlock.color = 'white';
                this.parts.textBlock.fontSize = 16;

                this.parts.rect.addControl(this.parts.textBlock);
                this.parent.parent.widgetUI.addControl(this.parts.rect);
                this.parts.rect.linkWithMesh(this.mAnchor);
                break;
        }
    }

    setAB(a = false, b = false) {
        if (a) {
            this.a = a;
        }
        if (b) {
            this.b = b;
        }
        this.mAnchor.position = this.a.add(this.b).scale(0.5);
        this.dirty = true;
    }

    dispose() {
        this.parent.parent.widgetUI.removeControl(this.parts.rect);
        this.mAnchor.dispose();
    }

    disposeMesh(mesh) {
        if (mesh) {
            mesh.dispose();
        }
    }

    createAngleTextForRibbon(angle, position) {
        return new TextPlaneWidget(angle, { positionValue: position, width: 30, height: 30 }, this);
    }

    showAngleSector(origin, firstVector, secondVector, radius) {
        radius = radius || 1;
        const cross = Vector3.Cross(firstVector, secondVector);
        const dot = Vector3.Dot(firstVector, secondVector);
        const angle = Math.acos(dot / (firstVector.length() * secondVector.length()));

        this.angleValue = (angle * 180) / Math.PI;
        let points = [];
        const minNumberOfPoints = 4;
        const factor = 11;
        let numberOfPoints = Math.floor(radius * angle * factor);
        numberOfPoints = numberOfPoints < minNumberOfPoints ? minNumberOfPoints : numberOfPoints;

        const firstPoint = Vector3.Normalize(firstVector).scale(radius);
        const lastPoint = Vector3.Normalize(secondVector).scale(radius);
        let matrix;
        const rotatedAngle = angle / numberOfPoints;
        let rotated;

        for (let i = 0; i < numberOfPoints; i++) {
            matrix = Matrix.RotationAxis(cross, rotatedAngle * i);
            rotated = Vector3.TransformCoordinates(firstPoint.clone(), matrix);
            points.push(rotated.add(origin.clone()).clone());
        }
        points.push(lastPoint.add(origin.clone()).clone());

        let pointO = [];
        for (let j = 0; j < points.length; j++) {
            pointO.push(origin.clone());
        }

        if (pointO.length > 1 && points.length > 1) {
            this.disposeMesh(this.sector);
            this.sector = Mesh.CreateRibbon(
                'sector',
                [points, pointO],
                null,
                null,
                0,
                this.parent.parent.scene,
                true,
                Mesh.DOUBLESIDE
            );
        }

        if (this.angleValue && this.angleTextplaneWidget) {
            this.angleTextplaneWidget.parts.textBlock.text = Math.round(this.angleValue).toString() + '°';
        }

        if (this.sector) {
            this.sector.material = new StandardMaterial('standartMaterial', this.parent.parent.scene);
            this.sector.material.emissiveColor = new Color3(0, 122, 225);
            this.sector.material.diffuseColor = new Color3(0, 122, 225);
            this.sector.material.alpha = 0.6;
            this.parent.parent.scene.lights[0].excludedMeshes.push(this.sector);
        }
    }

    showAngleWhileDrawing() {
        if (this.parent.corners.length >= 2) {
            if (this.parent.getLastCorner() && this.parent.getLastCorner(2) && this.b) {
                const origin = this.parent.getLastCorner().position;
                const firstAxis = this.parent.getLastCorner(2).position.subtract(origin);
                const secondAxis = origin.subtract(this.b);
                this.showAngleSector(origin, secondAxis.scale(-1), firstAxis, 0.5);
            }
        }
    }

    update() {
        if (!this.dirty) {
            return;
        }
        this.dirty = false;
        let v = this.getText();
        this.parts.textBlock.text = v ? v : '';
        this.parts.rect.width = this.parts.textBlock.text.length * 12 + 'px';
        if (this.parent.corners.length >= 2) {
            this.showAngleWhileDrawing();
        }
    }

    setEnabled(enabled) {
        if (this.parts.rect) {
            this.dirty = true;
            this.parts.rect.isVisible = enabled;
        }
    }

    getText() {
        let d = this.parent.parent.value2Snap(Vector2.Distance(this.a, this.b));

        if (d == 0) {
            return false;
        }
        if (store.getters['core/selectedUnit'] == 'mm') {
            d *= 1000;
            d = Math.floor(d);
            d += 'mm';
        } else {
            d = this.parent.parent.inchFractionText(d);

            if (store.state.fixtures.typedWallDim) {
                d = store.state.fixtures.typedWallDim;
            }

            d += '″';
        }
        return d;
    }

    getEditorCore() {
        return this.parent.getEditorCore();
    }
}
