var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _c("v-toolbar-title", { staticClass: "grey--text" }, [
            _vm._v(" " + _vm._s(_vm.collection.name) + " "),
          ]),
          _c("v-spacer"),
          _vm.config.collections[_vm.collIdx].surface
            ? _c(
                "v-btn-toggle",
                {
                  attrs: {
                    value: _vm.collection.surface.selected,
                    tile: "",
                    color: "primary",
                    group: "",
                    mandatory: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeSurface(_vm.collIdx, $event)
                    },
                  },
                },
                _vm._l(_vm.collection.surface.items, function (surface) {
                  return _c(
                    "v-btn",
                    { key: surface, attrs: { value: surface } },
                    [_vm._v(" " + _vm._s(surface) + " ")]
                  )
                }),
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", color: "error" },
              on: {
                click: function ($event) {
                  return _vm.toggleCollectionSheet({
                    i: _vm.collIdx,
                    type: _vm.config.objectType,
                  })
                },
              },
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiCloseCircle))])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          attrs: {
            "background-color": "indigo",
            height: "38",
            dark: "",
            "slider-size": "5",
            "show-arrows": "",
          },
          model: {
            value: _vm.colTab,
            callback: function ($$v) {
              _vm.colTab = $$v
            },
            expression: "colTab",
          },
        },
        [
          _c("v-tab", [_vm._v("Color")]),
          _vm.collection.doorStyle ? _c("v-tab", [_vm._v("Style")]) : _vm._e(),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.colTab,
            callback: function ($$v) {
              _vm.colTab = $$v
            },
            expression: "colTab",
          },
        },
        [
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                {
                  staticClass: "mx-auto",
                  staticStyle: {
                    "overflow-y": "scroll",
                    "overflow-x": "hidden",
                  },
                  attrs: { "max-height": "300" },
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-3" },
                    [
                      _c(
                        "v-item-group",
                        {
                          attrs: {
                            value: _vm.collection.color.selected,
                            mandatory: !!_vm.collection.color.selected,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeColor(_vm.collIdx, $event)
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            _vm._l(_vm.collection.color.items, function (item) {
                              return _c(
                                "v-col",
                                { key: item, attrs: { cols: "auto" } },
                                [
                                  _c("v-item", {
                                    attrs: { value: item },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ active, toggle }) {
                                            return [
                                              _vm.collection.color.type ===
                                              "texture"
                                                ? _c(
                                                    "v-img",
                                                    {
                                                      staticClass:
                                                        "text-right pa-2",
                                                      attrs: {
                                                        src: require(`@/assets/textures/${item}/thumb.jpg`),
                                                        height: "170",
                                                        width: "170",
                                                      },
                                                      on: { click: toggle },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "green",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    active
                                                                      ? _vm.mdiCheck
                                                                      : null
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm.collection.color.type ===
                                                    "monoColor" ||
                                                  _vm.collection.color.type ===
                                                    "combo"
                                                ? _c(
                                                    "v-sheet",
                                                    {
                                                      staticClass:
                                                        "text-right pa-2",
                                                      attrs: {
                                                        color: item,
                                                        height: "90",
                                                        width: "90",
                                                        elevation: "24",
                                                      },
                                                      on: { click: toggle },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "green",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    active
                                                                      ? _vm.mdiCheck
                                                                      : null
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.collection.doorStyle
            ? _c(
                "v-tab-item",
                [
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: {
                        value: _vm.collection.doorStyle.selected,
                        tile: "",
                        color: "primary",
                        group: "",
                        mandatory: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeDoorStyle(_vm.collIdx, $event)
                        },
                      },
                    },
                    _vm._l(
                      _vm.collection.doorStyle.items,
                      function (doorStyle) {
                        return _c(
                          "v-btn",
                          { key: doorStyle, attrs: { value: doorStyle } },
                          [_vm._v(" " + _vm._s(doorStyle) + " ")]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }