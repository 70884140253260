import { Color3, MeshBuilder, StandardMaterial, Vector3, VertexBuffer } from '@babylonjs/core';
import { Constants } from '../../../Tools/constants';
import { Utilities } from '../../../Tools/utilities';
import { Component } from '../../../Components/Base/Component';
import { RoomObject } from '../../Room/RoomObject';
import { Container } from '@babylonjs/gui';

export class BlockedCornerArea extends Component {
    meshForCornerA;
    meshForCornerB;
    meshForPeninsulaConnection;
    blockedWidthForPeninsulaConnection;
    blockedWidthForCornerA;
    blockedWidthForCornerB;
    constructor() {
        super();
    }

    calculateCornerCabinetArea(corner, cornerIndex, fillerSize, connectionType = false) {
        if (connectionType === 'peninsulaConnection') {
            return this.calculatePeninsulaBlockedArea(fillerSize);
        }
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        let cornerWalls;
        let extendedCabinet;
        let wallCabinetSection = 0;
        let diagonalPoint;
        if (connectionType !== 'peninsulaConnection') {
            cornerWalls = Utilities.getCornerWalls(corner, roomObject.walls);
            extendedCabinet = 'connectedCabinet';
            if (cornerIndex === Constants.corner.CORNER_B) {
                extendedCabinet = 'secondConnectedCabinet';
            }
            if (this.object.type === 'wall') {
                wallCabinetSection = Constants.wallCornerCabinet.DOOR_SIZE[this.object.editor.unit];
                fillerSize = 0;
            }

            diagonalPoint = roomObject.calculateInnerCorner(
                corner,
                cornerWalls.leftWall.baseInnerCornerB,
                cornerWalls.rightWall.baseInnerCornerA,
                this.object[extendedCabinet].depth + wallCabinetSection + fillerSize
            );
            diagonalPoint = this.adaptDifferentDepthCabinets(diagonalPoint);
            const leftPoint = Utilities.projectPointOnWall(diagonalPoint.clone(), cornerWalls.leftWall);
            const rightPoint = Utilities.projectPointOnWall(diagonalPoint.clone(), cornerWalls.rightWall);
            return {
                corner: corner,
                leftPoint: leftPoint,
                diagonalPoint: diagonalPoint,
                rightPoint: rightPoint,
            };
        }
    }

    calculatePeninsulaBlockedArea(fillerSize) {
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        const roomCorners = roomObject.cornerPositions.concat(roomObject.cornerPositions[0]);

        let innerRow;
        let innerRowEdges;
        if (this.object.type === Constants.cabinet.type.ISLAND) {
            console.log('this is an island stop');
        } else {
            const peninsula = this.object.peninsulaConnection.peninsula;
            peninsula.setInnerPeninsulaRow();
            innerRow = peninsula.peninsulaData.innerRow;
            innerRowEdges = peninsula.getEdges(innerRow);
            let closestEdgeToWall = 'leftPoint';
            if (
                Utilities.findDistanceFromWall(innerRowEdges.leftPoint, this.object.assignedWall) >
                Utilities.findDistanceFromWall(innerRowEdges.rightPoint, this.object.assignedWall)
            ) {
                closestEdgeToWall = 'rightPoint';
            }
            let innerRowProjectedPosition = Utilities.projectPointOnWall(
                innerRowEdges[closestEdgeToWall],
                this.object.assignedWall
            );
            let wallDirection = this.object.assignedWall.getDirection();

            let directionConstant = 1;
            if (this.object.peninsulaConnection.edgeIndex === 'secondPoint') {
                wallDirection.negateInPlace();
                directionConstant = -1;
            }
            const peninsulaBlockedAreaOnWall = {
                firstPoint: innerRowProjectedPosition.clone().add(wallDirection.clone().scale(peninsula.rowDepths[innerRow] / 2)),
                secondPoint: innerRowProjectedPosition
                    .clone()
                    .add(wallDirection.clone().scale(-peninsula.rowDepths[innerRow] / 2)),
            };
            let corner = peninsulaBlockedAreaOnWall.firstPoint;
            let leftPoint = Utilities.calculateShiftValue(
                corner.clone(),
                -this.object.depth,
                roomCorners,
                this.object.assignedWall
            );
            let rightPoint = peninsulaBlockedAreaOnWall.secondPoint.clone().add(wallDirection.clone().scale(-fillerSize));
            if (
                Vector3.Distance(peninsulaBlockedAreaOnWall.firstPoint, peninsula.peninsulaData.connectionEdge) >
                Vector3.Distance(peninsulaBlockedAreaOnWall.secondPoint, peninsula.peninsulaData.connectionEdge)
            ) {
                corner = peninsulaBlockedAreaOnWall.secondPoint;
                leftPoint = Utilities.calculateShiftValue(
                    corner.clone(),
                    this.object.depth,
                    roomCorners,
                    this.object.assignedWall
                );
                rightPoint = peninsulaBlockedAreaOnWall.firstPoint.clone().add(wallDirection.clone().scale(-fillerSize));
            }

            let diagonalPoint = Utilities.calculateShiftValue(
                rightPoint.clone(),
                -this.object.depth,
                roomCorners,
                this.object.assignedWall
            );
            if (this.object.peninsulaConnection.edgeIndex === 'firstPoint') {
                let temp = leftPoint;
                leftPoint = rightPoint;
                rightPoint = temp;
            }
            return {
                corner: corner,
                leftPoint: leftPoint,
                diagonalPoint: diagonalPoint,
                rightPoint: rightPoint,
            };
        }
    }

    adaptDifferentDepthCabinets(diagonalPoint) {
        let pointOnWall = Utilities.projectPointOnWall(diagonalPoint.clone(), this.object.assignedWall);
        let direction = pointOnWall.clone().subtract(diagonalPoint);
        direction.normalize();
        direction.scaleInPlace(this.object.depth);
        return pointOnWall.subtract(direction);
    }

    updateBlockedCornerArea(planeData, meshToUpdate) {
        if (!this[meshToUpdate]) {
            this[meshToUpdate] = MeshBuilder.CreatePlane(
                'blockedArea',
                { updatable: true, size: this.object.depth },
                this.object.editor.sceneComponent.get()
            );
        }

        const material = new StandardMaterial(this.object.editor.sceneComponent.get());
        material.alpha = 1;
        material.diffuseColor = new Color3(1.0, 0.2, 0.7);
        this[meshToUpdate].material = material;

        let positions = this[meshToUpdate].getVerticesData(VertexBuffer.PositionKind);

        positions = [
            planeData.corner.x,
            planeData.corner.y,
            planeData.corner.z,
            planeData.leftPoint.x,
            planeData.leftPoint.y,
            planeData.leftPoint.z,
            planeData.diagonalPoint.x,
            planeData.diagonalPoint.y,
            planeData.diagonalPoint.z,
            planeData.rightPoint.x,
            planeData.rightPoint.y,
            planeData.rightPoint.z,
        ];
        this[meshToUpdate].updateVerticesData(VertexBuffer.PositionKind, positions);
        this[meshToUpdate].position.y = this.object.height + 0.0001;

        if (this.object.type === Constants.cabinet.type.WALL) {
            this[meshToUpdate].position.y += this.object.heightFromFloor;
        }
    }

    build(corner, cornerIndex, newFillerSize = Constants.STANDARD_FILLER_SIZE[this.object.editor.unit]) {
        let planeData;
        if (cornerIndex === Constants.corner.CORNER_A) {
            planeData = this.calculateCornerCabinetArea(corner, cornerIndex, newFillerSize);
            this.updateBlockedCornerArea(planeData, 'meshForCornerA');
            this.blockedWidthForCornerA = Vector3.Distance(planeData.corner, planeData.leftPoint);
        } else if (cornerIndex === Constants.corner.CORNER_B) {
            planeData = this.calculateCornerCabinetArea(corner, cornerIndex, newFillerSize);
            this.updateBlockedCornerArea(planeData, 'meshForCornerB');
            this.blockedWidthForCornerB = Vector3.Distance(planeData.corner, planeData.rightPoint);
        } else if (cornerIndex === 'firstPoint') {
            //for peninsula connections
            planeData = this.calculateCornerCabinetArea(corner, cornerIndex, newFillerSize, 'peninsulaConnection');
            this.updateBlockedCornerArea(planeData, 'meshForPeninsulaConnection', newFillerSize);
            this.blockedWidthForPeninsulaConnection = Vector3.Distance(planeData.corner, planeData.leftPoint);
        } else if (cornerIndex === 'secondPoint') {
            //for peninsula connections
            planeData = this.calculateCornerCabinetArea(corner, cornerIndex, newFillerSize, 'peninsulaConnection');
            this.updateBlockedCornerArea(planeData, 'meshForPeninsulaConnection', newFillerSize);
            this.blockedWidthForPeninsulaConnection = Vector3.Distance(planeData.corner, planeData.rightPoint);
        }
    }

    dispose(meshToDispose = null) {
        if (!meshToDispose) {
            if (this.meshForCornerA) {
                this.meshForCornerA.dispose();
                this.meshForCornerA = null;
            }
            if (this.meshForCornerB) {
                this.meshForCornerB.dispose();
                this.meshForCornerB = null;
            }

            if (this.meshForPeninsulaConnection) {
                this.meshForPeninsulaConnection.dispose();
                this.meshForPeninsulaConnection = null;
            }
        } else {
            if (this[meshToDispose]) {
                this[meshToDispose].dispose();
                this[meshToDispose] = null;
            }
        }
    }
}
