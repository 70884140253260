import { Component } from '../../../../Components/Base/Component';
import { Constants } from '../../../../Tools/constants';
import { Utilities } from '../../../../Tools/utilities';

export class ResizingManager extends Component {
    constructor() {
        super();
    }

    resizeWidth(dimensions) {
        let width = dimensions.width;
        width -= Constants.baseCabinetModel.sidePanel.WIDTH * 2;

        Utilities.resizeMeshInWidth(this.parts.cabinet.panelTop.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cabinet.panelBottom.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cabinet.panelBack.mesh, width);

        Utilities.resizeMeshInWidth(this.parts.doors.door.mesh, dimensions.doorWidth - Constants.GAP_BETWEEN_DOORS);
        Utilities.resizeMeshInWidth(
            this.parts.doors.connection.mesh,
            dimensions.connectedCabinetDepth - Constants.GAP_BETWEEN_DOORS
        );

        const panelBackDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBack.mesh);
        const connectionSize = Utilities.getMeshDimensions(this.parts.doors.connection.mesh);
        const doorSize = Utilities.getMeshDimensions(this.parts.doors.door.mesh);

        let shift = (width - panelBackDimensions.width) / 2;
        const connectionShift = (dimensions.connectedCabinetDepth - connectionSize.width) / 2;
        const doorShift = (dimensions.doorWidth - doorSize.width) / 2;

        this.parts.cabinet.panelLeft.mesh.position.x -= shift;
        this.parts.cabinet.panelRight.mesh.position.x += shift;
        this.parts.doors.connection.mesh.position.x += shift - connectionShift;
        this.parts.doors.door.mesh.position.x -= shift - doorShift;

        this.resizeSkirtingBlockInWidth(dimensions);
    }

    resizeSkirtingBlockInWidth(dimensions) {
        let width = dimensions.width;
        Utilities.resizeMeshInWidth(this.parts.skirting.skirtingFrontCenter.mesh, width);
        const skirtingFrontCenterDimensions = Utilities.getMeshDimensions(this.parts.skirting.skirtingFrontCenter.mesh);
        const shift = (width - skirtingFrontCenterDimensions.width) / 2;

        this.parts.skirting.skirtingLeft.mesh.position.x -= shift;
        this.parts.skirting.skirtingRight.mesh.position.x += shift;
        this.parts.skirting.skirtingBackLeft.mesh.position.x -= shift;
        this.parts.skirting.skirtingBackRight.mesh.position.x += shift;
    }

    resizeDepth(dimensions) {
        this.resizeCabinetBlockInDepth(dimensions);
        this.resizeSkirtingBlockInDepth(dimensions);
    }

    resizeCabinetBlockInDepth(dimensions) {
        const DOOR_SIZE = 0.018;
        const depth = dimensions.depth - DOOR_SIZE;
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelLeft.mesh, depth);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelRight.mesh, depth);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelBottom.mesh, depth);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelTop.mesh, depth);

        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);
        const shift = (depth - panelBottomDimensions.depth) / 2;

        this.parts.cabinet.panelBack.mesh.position.z += shift;
        this.parts.doors.door.mesh.position.z -= shift;
        this.parts.doors.connection.mesh.position.z -= shift;
    }

    resizeSkirtingBlockInDepth(dimensions) {
        const DOOR_SIZE = 0.018;
        const depth = dimensions.depth - DOOR_SIZE;
        Utilities.resizeMeshInDepth(this.parts.skirting.skirtingLeft.mesh, depth - 0.09);
        Utilities.resizeMeshInDepth(this.parts.skirting.skirtingRight.mesh, depth - 0.09);

        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);
        const shift = (depth - panelBottomDimensions.depth) / 2;

        this.parts.skirting.skirtingFrontCenter.mesh.position.z -= shift;
        this.parts.skirting.skirtingBackLeft.mesh.position.z += shift;
        this.parts.skirting.skirtingBackRight.mesh.position.z += shift;
    }

    resizeHeight(dimensions) {
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelLeft.mesh, dimensions.height - 0.15);
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelRight.mesh, dimensions.height - 0.15);
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelBack.mesh, dimensions.height - 0.186);
        Utilities.resizeMeshInHeight(this.parts.doors.door.mesh, dimensions.height - 0.15);
        Utilities.resizeMeshInHeight(this.parts.doors.connection.mesh, dimensions.height - 0.15);

        const panelLeftDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelLeft.mesh);
        const shift = (dimensions.height - 0.15 - panelLeftDimensions.height) / 2;

        this.parts.cabinet.panelTop.mesh.position.y += shift;
        this.parts.cabinet.panelBottom.mesh.position.y -= shift;
        this.parts.skirting.skirtingFrontCenter.mesh.position.y -= shift;
        this.parts.skirting.skirtingBackLeft.mesh.position.y -= shift;
        this.parts.skirting.skirtingBackRight.mesh.position.y -= shift;
        this.parts.skirting.skirtingLeft.mesh.position.y -= shift;
        this.parts.skirting.skirtingRight.mesh.position.y -= shift;
    }
}
