import { Vector3 } from '@babylonjs/core';
import { BaseAppliance } from '../../KitchenObjects/Appliances/BaseAppliance';
import { Utilities } from '../../Tools/utilities';
import { Component } from './Component';

export class BaseObstacleDetection extends Component {
    constructor() {
        super();
    }

    checkForObstacle(fixture, obstacleMargin = 0) {
        if (!this.shouldConsiderObstacle(fixture)) {
            return;
        }
        const blockedSegment = fixture.getEdgesOnWall(obstacleMargin);
        const cabinetBlockedSegment = this.object.getEdgesOnWall();

        if (
            Utilities.isPointBetween(blockedSegment.firstPoint, blockedSegment.secondPoint, cabinetBlockedSegment.firstPoint) ||
            Utilities.isPointBetween(blockedSegment.firstPoint, blockedSegment.secondPoint, cabinetBlockedSegment.secondPoint) ||
            (Utilities.isPointBetween(
                cabinetBlockedSegment.firstPoint,
                cabinetBlockedSegment.secondPoint,
                blockedSegment.firstPoint
            ) &&
                Utilities.isPointBetween(
                    cabinetBlockedSegment.firstPoint,
                    cabinetBlockedSegment.secondPoint,
                    blockedSegment.secondPoint
                ))
        ) {
            return blockedSegment;
        } else {
            return false;
        }
    }

    checkForObstacles(obstacleMargin) {
        let isBlocked = false;
        for (let index = 0; index < this.object.editor.fixtures.length; index++) {
            let fixture = this.object.editor.fixtures[index];
            if (fixture.assignedWall?.id === this.object.assignedWall?.id) {
                if (!this.checkForObstacle(fixture, obstacleMargin)) {
                    isBlocked = false;
                } else {
                    isBlocked = true;
                    break;
                }
            }
        }
        return isBlocked;
    }

    findNearestObstacles(typesToIgnore = []) {
        let selectedCabinetBlockedSegment = this.object.getEdgesOnWall();
        let staticObjectsOnWall = this.object.assignedWall.getObjectsOnWall();

        let minDistanceFromPointA = Number.POSITIVE_INFINITY;
        let minDistanceFromPointB = Number.POSITIVE_INFINITY;
        let closestObjectFromPointA = null;
        let closestObjectFromPointB = null;

        for (let index = 0; index < staticObjectsOnWall.length; index++) {
            if (
                this.object === staticObjectsOnWall[index].currentCabinet ||
                typesToIgnore.includes(staticObjectsOnWall[index].type)
            ) {
                continue;
            }

            if (this.object.currentCabinet && !(staticObjectsOnWall[index] instanceof BaseAppliance)) {
                continue;
            }
            const staticObjectBlockedSegment = staticObjectsOnWall[index].getEdgesOnWall();
            let staticObjectClosestPoint = staticObjectBlockedSegment.firstPoint;
            if (
                Vector3.Distance(staticObjectBlockedSegment.firstPoint, this.object.position) >
                Vector3.Distance(staticObjectBlockedSegment.secondPoint, this.object.position)
            ) {
                staticObjectClosestPoint = staticObjectBlockedSegment.secondPoint;
            }

            if (this.shouldConsiderObstacle(staticObjectsOnWall[index]) && staticObjectsOnWall[index] !== this.object) {
                let staticFixtureProjectedPosition = Utilities.projectPointOnWall(
                    staticObjectClosestPoint,
                    staticObjectsOnWall[index].assignedWall
                );

                let newMinDistanceFromPointA = Vector3.Distance(
                    staticFixtureProjectedPosition,
                    selectedCabinetBlockedSegment.firstPoint
                );
                let newMinDistanceFromPointB = Vector3.Distance(
                    staticFixtureProjectedPosition,
                    selectedCabinetBlockedSegment.secondPoint
                );

                if (newMinDistanceFromPointA < minDistanceFromPointA && newMinDistanceFromPointA < newMinDistanceFromPointB) {
                    minDistanceFromPointA = newMinDistanceFromPointA;
                    closestObjectFromPointA = staticObjectsOnWall[index];
                }

                if (newMinDistanceFromPointB < minDistanceFromPointB && newMinDistanceFromPointB < newMinDistanceFromPointA) {
                    minDistanceFromPointB = newMinDistanceFromPointB;
                    closestObjectFromPointB = staticObjectsOnWall[index];
                }
            }
        }
        return {
            minDistanceFromPointA: minDistanceFromPointA,
            closestFixtureFromPointA: closestObjectFromPointA,
            minDistanceFromPointB: minDistanceFromPointB,
            closestFixtureFromPointB: closestObjectFromPointB,
        };
    }
}
