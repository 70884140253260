import { BuildingBlock } from './BuildingBlock';
import store from '@/store/index.js';
import { Color3, Mesh } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';

export class Countertop extends BuildingBlock {
    constructor() {
        super();
    }

    create(meshes, type) {
        const mergedBlock = Mesh.MergeMeshes(meshes, false, false, false, false, true);
        mergedBlock.computeWorldMatrix();
        mergedBlock.alwaysSelectAsActiveMesh = true;
        this.meshComponent.setMesh(mergedBlock);

        this.setInfo(type);
        const collection = store.state.generated.objects[this.type]?.collections[0];
        if (collection) {
            let savedInfo = {
                type: collection.color.type,
                value: collection.color.items[0],
                pattern: collection.color.pattern,
            };

            if (this.parentCabinet.countertopCustomization?.color) {
                savedInfo = this.parentCabinet.countertopCustomization.color;
            }

            this.changeColor(collection.name, savedInfo.type, savedInfo.value, savedInfo.pattern);

            if (this.isSelectable) {
                this.setActions();
            }
        }
    }

    addTexture() {
        const collection = store.state.generated.objects.countertop?.collections[0];

        if (typeof this.parentCabinet.countertopCustomization !== 'object') {
            this.parentCabinet.countertopCustomization = {};
        }

        const countertopCustomization = this.parentCabinet.countertopCustomization;
        if (!countertopCustomization.color) {
            countertopCustomization.color = {
                type: collection.color.type,
                value: collection.color.items[0],
                pattern: 'noPattern',
            };
        }
        this.changeColor(
            collection.name,
            countertopCustomization.color.type,
            countertopCustomization.color.value,
            countertopCustomization.color.pattern
        );
    }

    changeColor(collectionName, collectionType, color, pattern = null) {
        this.collection.name = collectionName;
        this.color.value = color;
        this.collectionType = collectionType;

        let microsurface = Constants.glossines[this.type].MICROSURFACE;
        let clearCoat = Constants.glossines[this.type].CLEAR_COAT;

        if (this.surface === 'matt') {
            microsurface = Constants.glossines.matt.MICROSURFACE;
            clearCoat = Constants.glossines.matt.CLEAR_COAT;
        }

        if (collectionType === 'monoColor') {
            this.currentColor = Color3.FromHexString(color);
            const pbrMaterial = this.editor.PBRComponent.getTextureFromPool(
                collectionType,
                'noPattern',
                color,
                microsurface,
                clearCoat,
                ''
            );
            this.meshComponent.getMesh().material = pbrMaterial;
            this.parentCabinet.countertopCustomization.color = {
                value: color,
                type: collectionType,
                pattern: 'noPattern',
            };
        } else if (collectionType === 'combo') {
            this.currentColor = Color3.FromHexString(color);
            const pbrMaterial = this.editor.PBRComponent.getTextureFromPool(
                collectionType,
                pattern,
                color,
                microsurface,
                clearCoat,
                ''
            );

            this.meshComponent.getMesh().material = pbrMaterial;
            this.parentCabinet.countertopCustomization.color = {
                value: color,
                type: collectionType,
                pattern: pattern,
            };
        } else if (collectionType === 'texture') {
            this.currentColor = new Color3(1, 1, 1);
            this.color.pattern = color;
            const pbrMaterial = this.editor.PBRComponent.getTextureFromPool(
                collectionType,
                color,
                '#FFFFFF',
                microsurface,
                clearCoat,
                ''
            );
            this.meshComponent.getMesh().material = pbrMaterial;
            this.parentCabinet.countertopCustomization.color = {
                value: color,
                type: collectionType,
                pattern: pattern,
            };
        }

        this.editor.sceneComponent.save3D();
    }
}
