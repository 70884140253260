<template>
    <v-form ref="inputs" v-model="isValid">
        <v-row justify="center">
            <!-- Cabinet Dimensions -->
            <v-col
                v-for="(value, key) in cabinet.dimensions"
                :key="key"
                cols="4"
                sm="auto"
                class="pa-1"
            >
                <v-text-field
                    v-if="cabinet.type !== 'island' || key !== 'depth'"
                    :value="cabinet.dimensions[key]"
                    @input="resize(key, cabinet.type)"
                    :label="key"
                    ref="key"
                    :min="limits[key].min[selectedUnit]"
                    :max="limits[key].max[selectedUnit]"
                    :suffix="selectedUnit"
                    outlined
                    :dense="$vuetify.breakpoint.xsOnly"
                    type="number"
                    :rules="[
                        rules.required,
                        rules.between(
                            limits[key].min[selectedUnit],
                            limits[key].max[selectedUnit]
                        ),
                    ]"
                ></v-text-field>

                <!-- Island Depth only -->
                <v-combobox
                    v-else
                    v-model="cabinet.dimensions[key]"
                    @input="resize(key, cabinet.type)"
                    :items="cabinetDimensions[key].items[selectedUnit]"
                    :label="key"
                    ref="key"
                    outlined
                    :dense="$vuetify.breakpoint.xsOnly"
                    required
                    subtype="number"
                    :rules="[
                        rules.required,
                        rules.between(
                            limits[key].min[selectedUnit],
                            limits[key].max[selectedUnit]
                        ),
                    ]"
                    :suffix="selectedUnit"
                >
                    <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Press <kbd>enter</kbd> to update
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-combobox>
            </v-col>

            <!-- Rotate Island -->
            <v-col cols="auto" class="pa-1" v-if="cabinet.type === 'island'">
                <v-btn
                    @click="rotate"
                    class="ma-2"
                    color="primary"
                    dark
                    icon
                    large
                    tile
                >
                    <v-icon dark>{{ mdiPhoneRotateLandscape }}</v-icon>
                </v-btn>
            </v-col>

            <!-- Delete Cabinet -->
            <v-col cols="auto" class="pa-1">
                <v-btn
                    @click="dispose"
                    class="ma-2"
                    color="red"
                    dark
                    icon
                    large
                    tile
                >
                    <v-icon dark>{{ mdiDelete }}</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mdiDelete, mdiPhoneRotateLandscape } from '@mdi/js'

export default {
    data() {
        return {
            search: null,
            isValid: true,
            rules: {
                required: (value) => !!value || value === 0 || 'Required',
                between(min, max) {
                    return (value) =>
                        (value >= min && value <= max) ||
                        `Must be between ${min} and ${max}`;
                },
            },
            mdiDelete: mdiDelete,
            mdiPhoneRotateLandscape: mdiPhoneRotateLandscape,
        };
    },
    computed: {
        ...mapGetters('core', ['selectedUnit']),
        ...mapState('cabinets', {
            cabinet: (state) => state.selectedCabinet,
            cabinets: (state) => state.cabinets,
        }),
        cabinetDimensions() {
            const type = this.cabinet.type,
                cabinetDimensions = this.cabinets.type[type].dimensions;
            return cabinetDimensions;
        },
        limits() {
            const limits = {};

            Object.keys(this.cabinetDimensions).forEach((key) => {
                limits[key] = {
                    min: this.cabinetDimensions[key].min,
                    max: this.cabinetDimensions[key].max,
                    items: this.cabinetDimensions[key].items,
                };
            });
            // console.log(limits);

            return limits;
        },
    },
    methods: {
        resize(key, cabinetType) {
            const oldVal = this.cabinet.dimensions[key];

            if (this.$refs.inputs.validate()) {
                // comboboxes take the value from v-model
                if (key !== 'depth' || cabinetType !== 'island') {
                    this.cabinet.dimensions[key] = parseFloat(event.target.value);
                }
                this.cabinet.resizingManager.resize(
                    this.cabinet.dimensions[key],
                    key
                );
            } else {
                this.cabinet.dimensions[key] = oldVal;
            }
        },
        dispose() {
            this.cabinet.dispose();
        },
        rotate() {
            this.cabinet.rotate();
        },
    },
};
</script>
