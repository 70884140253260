var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "inputs",
      model: {
        value: _vm.isValid,
        callback: function ($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid",
      },
    },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _vm._l(_vm.cabinet.dimensions, function (value, key) {
            return _c(
              "v-col",
              {
                key: key,
                staticClass: "pa-1",
                attrs: { cols: "4", sm: "auto" },
              },
              [
                _vm.cabinet.type !== "island" || key !== "depth"
                  ? _c("v-text-field", {
                      ref: "key",
                      refInFor: true,
                      attrs: {
                        value: _vm.cabinet.dimensions[key],
                        label: key,
                        min: _vm.limits[key].min[_vm.selectedUnit],
                        max: _vm.limits[key].max[_vm.selectedUnit],
                        suffix: _vm.selectedUnit,
                        outlined: "",
                        dense: _vm.$vuetify.breakpoint.xsOnly,
                        type: "number",
                        rules: [
                          _vm.rules.required,
                          _vm.rules.between(
                            _vm.limits[key].min[_vm.selectedUnit],
                            _vm.limits[key].max[_vm.selectedUnit]
                          ),
                        ],
                      },
                      on: {
                        input: function ($event) {
                          return _vm.resize(key, _vm.cabinet.type)
                        },
                      },
                    })
                  : _c("v-combobox", {
                      ref: "key",
                      refInFor: true,
                      attrs: {
                        items:
                          _vm.cabinetDimensions[key].items[_vm.selectedUnit],
                        label: key,
                        outlined: "",
                        dense: _vm.$vuetify.breakpoint.xsOnly,
                        required: "",
                        subtype: "number",
                        rules: [
                          _vm.rules.required,
                          _vm.rules.between(
                            _vm.limits[key].min[_vm.selectedUnit],
                            _vm.limits[key].max[_vm.selectedUnit]
                          ),
                        ],
                        suffix: _vm.selectedUnit,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.resize(key, _vm.cabinet.type)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-data",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" Press "),
                                          _c("kbd", [_vm._v("enter")]),
                                          _vm._v(" to update "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.cabinet.dimensions[key],
                        callback: function ($$v) {
                          _vm.$set(_vm.cabinet.dimensions, key, $$v)
                        },
                        expression: "cabinet.dimensions[key]",
                      },
                    }),
              ],
              1
            )
          }),
          _vm.cabinet.type === "island"
            ? _c(
                "v-col",
                { staticClass: "pa-1", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        color: "primary",
                        dark: "",
                        icon: "",
                        large: "",
                        tile: "",
                      },
                      on: { click: _vm.rotate },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(_vm._s(_vm.mdiPhoneRotateLandscape)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "pa-1", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: {
                    color: "red",
                    dark: "",
                    icon: "",
                    large: "",
                    tile: "",
                  },
                  on: { click: _vm.dispose },
                },
                [
                  _c("v-icon", { attrs: { dark: "" } }, [
                    _vm._v(_vm._s(_vm.mdiDelete)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }