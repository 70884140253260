var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("kw-Toolbar"),
      _c(
        "v-main",
        [
          _vm.errors
            ? _c("v-alert", { staticClass: "mt-3", attrs: { type: "error" } }, [
                _vm._v(" " + _vm._s(_vm.errors) + " "),
              ])
            : _vm._e(),
          _vm.$apollo.loading || _vm.isPageLoading
            ? _c(
                "v-container",
                { staticClass: "text-center", attrs: { fluid: "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" },
                  }),
                ],
                1
              )
            : _c(
                "v-container",
                { staticClass: "pa-0", attrs: { fluid: "" } },
                [_c("router-view")],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }