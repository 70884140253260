import { Vector3 } from '@babylonjs/core';
import { Component } from '../../../Components/Base/Component';
import { RoomObject } from '../../Room/RoomObject';
import { Constants } from '../../../Tools/constants';
import { Utilities } from '../../../Tools/utilities';
export class ResizingManager extends Component {
    constructor() {
        super();
    }

    resize(size, key) {
        let scale;
        let currentDimension;
        let unconvertedSize = size;
        if (this.object.editor.unit === 'in') {
            size = (size * Constants.unit.INCH_STEP) / Constants.MM_TO_BJS_COEFF;
        } else {
            size /= Constants.MM_TO_BJS_COEFF;
        }
        switch (key) {
            case 'width':
                currentDimension = this.object.baseWidth;
                scale = size / currentDimension;
                this.object.meshComponent.getMesh().scaling.x = scale;
                this.object.width = size;
                this.object.dimensions.width = unconvertedSize;
                break;
            case 'height':
                this.object.meshComponent.getMesh().position.y = 0;
                currentDimension = this.object.baseHeight;
                scale = size / currentDimension;
                this.object.meshComponent.getMesh().scaling.y = scale;
                this.object.height = size;
                this.object.dimensions.height = unconvertedSize;
                if (!this.object.heightFromFloor) {
                    this.object.heightFromFloor = 0;
                }
                this.object.meshComponent.getMesh().position.y += this.object.heightFromFloor + this.object.height / 2;
                break;
            case 'depth':
                currentDimension = this.object.baseDepth;
                scale = size / currentDimension;
                this.object.meshComponent.getMesh().scaling.z = scale;
                this.object.depth = size;
                this.object.dimensions.depth = unconvertedSize;
                if (this.object.type !== Constants.cabinet.type.ISLAND) {
                    const height = this.object.meshComponent.getPosition().y;
                    this.object.meshComponent.setPosition(
                        Utilities.projectPointOnWall(this.object.meshComponent.getPosition().clone(), this.object.assignedWall)
                    );

                    this.object.shift(-this.object.depth / 2);
                    this.object.meshComponent.getMesh().position.y = height;
                }
                break;
            case 'heightFromFloor':
                currentDimension = this.object.heightFromFloor;
                this.object.meshComponent.getMesh().position.y += parseFloat(size) - parseFloat(currentDimension);
                this.object.heightFromFloor = parseFloat(size);
                this.object.dimensions.heightFromFloor = unconvertedSize;
                unconvertedSize = false;
                break;
        }

        this.object.position = this.object.meshComponent.getMesh().position;
        this.object.measurementLines.update();
        if (this.object.type === Constants.cabinet.type.ISLAND) {
            let rowDepths = this.object.calculateRowDepths(this.object.depth);
            this.object.setRowDepths(rowDepths.firstRow, rowDepths.secondRow);
        }
        this.object.editor.sceneComponent.save3D();
    }

    isResizingOverConnectedCabinet(pickedPoint) {
        const cabinet = this.object;
        const projectedPickedPoint = Utilities.projectPointOnWall(pickedPoint, cabinet.assignedWall);

        const result = Utilities.getClosestPoint(
            projectedPickedPoint,
            cabinet.assignedWall.baseInnerCornerA,
            cabinet.assignedWall.baseInnerCornerB
        );
        let connectedCabinet;
        let connectedCabinetPositionInfo;

        if (result.closestPointIndex === Constants.corner.CORNER_A && this.object.connectedCabinet) {
            connectedCabinet = this.object.connectedCabinet;
            connectedCabinetPositionInfo = connectedCabinet.getPositionInfo();
            if (!connectedCabinetPositionInfo.cornerB) {
                return false;
            }
        } else if (this.object.secondConnectedCabinet) {
            connectedCabinet = this.object.secondConnectedCabinet;
            connectedCabinetPositionInfo = connectedCabinet.getPositionInfo();
            if (!connectedCabinetPositionInfo.cornerA) {
                return false;
            }
        } else {
            return false;
        }

        const distanceFromWall = Utilities.findDistanceFromWall(projectedPickedPoint, connectedCabinet.assignedWall);
        if (distanceFromWall < connectedCabinet.depth) {
            return true;
        }
    }

    resizeOnHandleDrag(pickedPoint) {
        const wallDirection = this.object.assignedWall.getDirection();
        const blockedSegmentOnWall = this.object.getEdgesOnWall();
        let handlePosition = blockedSegmentOnWall.firstPoint;
        const pickedPointProjection = Utilities.projectPointOnWall(pickedPoint.clone(), this.object.assignedWall);

        let pickedHandleDirection = 1;
        if (this.object.pickedHandle.name === 'leftHandle') {
            pickedHandleDirection = -1;
            handlePosition = blockedSegmentOnWall.secondPoint;
        }

        const resizingAmount = Vector3.Distance(handlePosition, pickedPointProjection);
        let scalingDirection = this.handleScalingDirection(pickedPointProjection, handlePosition);

        let meshScaling = (this.object.width + resizingAmount * scalingDirection * pickedHandleDirection) / this.object.baseWidth;
        if (meshScaling * this.object.baseWidth < Constants.cabinet.minDimensions.WIDTH) {
            return;
        }
        this.object.meshComponent.mesh.scaling.x = meshScaling;
        this.object.width = this.object.meshComponent.mesh.scaling.x * this.object.baseWidth;
        this.object.dimensions.width = (this.object.width.toFixed(3) * Constants.fixture.scale).toFixed(3);
        if (this.object.editor.unit === 'in') {
            this.object.dimensions.width = this.object.dimensions.width / Constants.unit.INCH_STEP;
        }

        wallDirection.scaleInPlace((scalingDirection * resizingAmount) / 2);

        this.object.meshComponent.mesh.position = this.object.meshComponent.mesh.position.clone().add(wallDirection);
        this.object.position = this.object.meshComponent.mesh.position;

        if (!this.object.isClone) {
            this.object.handles.leftHandle.scaling.x = 1 / meshScaling;
            this.object.handles.rightHandle.scaling.x = 1 / meshScaling;

            if (this.object.handles.frontHandle && this.object.handles.backHandle) {
                this.object.handles.frontHandle.scaling.x = 1 / meshScaling;
                this.object.handles.backHandle.scaling.x = 1 / meshScaling;
            }
        }
        this.object.editor.sceneComponent.save3D();
    }

    handleScalingDirection(pickedPoint, currentPosition) {
        const resizingDirection = currentPosition.subtract(pickedPoint);
        resizingDirection.normalize();
        if (Utilities.haveSameDirection(resizingDirection, this.object.assignedWall.getDirection())) {
            return -1;
        }
        return 1;
    }

    handleIslandScaling(pick, parameter) {
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        const roomCorners = roomObject.cornerPositions.concat(roomObject.cornerPositions[0]);
        if (!Utilities.isPointInside(pick.pickedPoint, roomCorners)) {
            return;
        }
        const currentPosition = this.object.position;
        const pickedPoint = pick.pickedPoint;
        let scalingCoordinate;
        let baseParameter;
        let coordinate;
        if (parameter === 'width') {
            baseParameter = 'baseWidth';
            coordinate = 'x';
            scalingCoordinate = 'x';
            if (this.object.rotation === 90 || this.object.rotation === 270) {
                coordinate = 'z';
            }
        } else if (parameter === 'depth') {
            baseParameter = 'baseDepth';
            coordinate = 'z';
            scalingCoordinate = 'z';
            if (this.object.rotation === 90 || this.object.rotation === 270) {
                coordinate = 'x';
            }
        }
        const distance = Math.abs(pickedPoint[coordinate] - currentPosition[coordinate]);
        const resizingAmount = distance - this.object[baseParameter] / 2;
        const scale = (resizingAmount + this.object[baseParameter]) / this.object[baseParameter];
        let scaling = 0;
        if (pickedPoint[coordinate] > currentPosition[coordinate]) {
            scaling = (this.object[baseParameter] + resizingAmount - this.object[parameter]) / 2;
        } else {
            scaling = -(this.object[baseParameter] + resizingAmount - this.object[parameter]) / 2;
        }

        this.object.meshComponent.getMesh().scaling[scalingCoordinate] = scale;
        this.object[parameter] = resizingAmount + this.object[baseParameter];
        this.object.dimensions[parameter] = parseFloat(this.object[parameter] * Constants.fixture.scale).toFixed(3);
        if (this.object.editor.unit === 'in') {
            this.object.dimensions[parameter] = (
                parseFloat(this.object.dimensions[parameter]).toFixed(3) / Constants.unit.INCH_STEP
            ).toFixed(3);
        }
        this.object.meshComponent.getMesh().position[coordinate] += scaling;
        this.object.position = this.object.meshComponent.getMesh().position;

        this.object.handles.frontHandle.scaling[scalingCoordinate] = 1 / scale;
        this.object.handles.backHandle.scaling[scalingCoordinate] = 1 / scale;
        if (this.object.handles.leftHandle && this.object.handles.rightHandle) {
            this.object.handles.leftHandle.scaling[scalingCoordinate] = 1 / scale;
            this.object.handles.rightHandle.scaling[scalingCoordinate] = 1 / scale;
        }
        this.object.measurementLines.update();
        if (this.object.type === Constants.cabinet.type.ISLAND) {
            let rowDepths = this.object.calculateRowDepths(this.object.depth);
            this.object.setRowDepths(rowDepths.firstRow, rowDepths.secondRow);
        }
        this.object.editor.sceneComponent.save3D();
    }

    handleExtendedCabinetConnection(connectedCab) {
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        const roomCorners = roomObject.baseOuterCorners.concat(roomObject.baseOuterCorners[0]);

        const selectedSegment = this.object.getEdgesOnWall();
        const connectedCabinet = this.object[connectedCab];
        const errorMargin = 0.3;

        if (connectedCabinet) {
            const connectedCabinetSegment = connectedCabinet.getEdgesOnWall();

            const connectedProjectedFirstPoint = Utilities.calculateShiftValue(
                connectedCabinetSegment.firstPoint,
                -connectedCabinet.depth,
                roomCorners,
                connectedCabinet.assignedWall
            );

            const connectedProjectedSecondPoint = Utilities.calculateShiftValue(
                connectedCabinetSegment.secondPoint,
                -connectedCabinet.depth,
                roomCorners,
                connectedCabinet.assignedWall
            );

            const selectedProjectedFirstPoint = Utilities.calculateShiftValue(
                selectedSegment.firstPoint,
                -this.object.depth,
                roomCorners,
                this.object.assignedWall
            );

            const selectedProjectedSecondPoint = Utilities.calculateShiftValue(
                selectedSegment.secondPoint,
                -this.object.depth,
                roomCorners,
                this.object.assignedWall
            );

            const selectedCabinetPositionInfo = this.object.getPositionInfo();

            let cornerToCheck = selectedCabinetPositionInfo.cornerB;
            if (!cornerToCheck) {
                cornerToCheck = selectedCabinetPositionInfo.cornerA;
            }

            if (connectedCab === 'connectedCabinet') {
                if (selectedCabinetPositionInfo.cornerA) {
                    if (Vector3.Distance(selectedProjectedFirstPoint, connectedCabinetSegment.secondPoint) >= errorMargin) {
                        this.object.extendedCabinetManager.disconnectExtendedCabinet(connectedCab);
                    }
                } else {
                    if (Vector3.Distance(selectedSegment.firstPoint, connectedProjectedSecondPoint) >= errorMargin) {
                        this.object.extendedCabinetManager.disconnectExtendedCabinet(connectedCab);
                    }
                }
            } else if (connectedCab === 'secondConnectedCabinet') {
                if (selectedCabinetPositionInfo.cornerB) {
                    if (Vector3.Distance(selectedProjectedSecondPoint, connectedCabinetSegment.firstPoint) >= errorMargin) {
                        this.object.extendedCabinetManager.disconnectExtendedCabinet(connectedCab);
                    }
                } else {
                    if (Vector3.Distance(selectedSegment.secondPoint, connectedProjectedFirstPoint) >= errorMargin) {
                        this.object.extendedCabinetManager.disconnectExtendedCabinet(connectedCab);
                    }
                }
            }
        }
    }

    handleCabinetResizing(pick) {
        let cabinet = this.object;
        if (cabinet.meshComponent.mesh.position) {
            let interpolationScale = 0.01;
            let projectedPick = Utilities.projectPointOnWall(pick.pickedPoint, cabinet.assignedWall);
            let startingPoint = Utilities.projectPointOnWall(
                cabinet.pickedHandle.getAbsolutePosition(),
                cabinet.assignedWall,
                true,
                0
            );
            let cabinetCloneData = cabinet.createCabinetClone();
            for (let index = 0; interpolationScale <= 1; index++, interpolationScale += 0.01) {
                let interpolationPoint = Vector3.Lerp(startingPoint, projectedPick, interpolationScale);
                cabinetCloneData.resizingManager.resizeOnHandleDrag(interpolationPoint);

                if (
                    !cabinetCloneData.obstacleManager.checkForObstacles(Constants.fixture.margin) &&
                    !cabinet.obstacleManager.checkForCabinetObstacles(cabinetCloneData) &&
                    !cabinet.resizingManager.isResizingOverConnectedCabinet(interpolationPoint)
                ) {
                    cabinet.resizingManager.resizeOnHandleDrag(interpolationPoint);
                }
            }
            cabinetCloneData.dispose();
            cabinet.measurementLines.update();
        }
        cabinet.position = cabinet.meshComponent.getPosition().clone();
    }
}
