import { Mesh, MeshBuilder } from '@babylonjs/core';
import { Constants } from '../../../Tools/constants';
import { Utilities } from '../../../Tools/utilities';
import { CabinetStructure } from '../../BuildingBlocks/CabinetStructure';
import { Model } from '../Model';
import { DoorBlock } from '../../BuildingBlocks/DoorBlock';
import { Skirting } from '../../BuildingBlocks/Skirting';

export class TallCabinet extends Model {
    constructor() {
        super();
    }

    async setModel() {
        const result = await this.import('tallCabinet.babylon');
        this.meshComponent.setMesh(Utilities.getElementByMeshName('TallCabinet', result.meshes));
        this.meshComponent.getMesh().setEnabled(false);
        this.meshComponent.getMesh().name = 'tallCabinet';
        this.parts.cabinet = {};
        this.buildingBlocks = {};
        this.setParts();
    }

    mergeModel() {
        const tallCabinetModelMesh = MeshBuilder.CreateBox('tall-cabinet-model', { width: 0.1, depth: 0.1, height: 0.1 });
        tallCabinetModelMesh.visibility = 0;
        const tallCabinetModel = { mesh: tallCabinetModelMesh, buildingBlocks: {} };

        tallCabinetModel.buildingBlocks.handleBlock = this.placeHandle(tallCabinetModel.mesh);

        tallCabinetModel.buildingBlocks.innerStructure = this.mergeCabinetBlock(tallCabinetModel.mesh);
        tallCabinetModel.buildingBlocks.skirtingBlock = this.mergeSkirtingBlock(tallCabinetModel.mesh);
        tallCabinetModel.buildingBlocks.doorBlock = this.mergeDoorBlock(tallCabinetModel.mesh);

        const appliance = this.sectionInfo?.appliance;
        if (appliance?.type === Constants.appliance.type.REFRIGERATOR) {
            this.placeRefrigerator(tallCabinetModel);
        } else if (appliance?.type === Constants.appliance.type.OVEN) {
            this.placeOven(tallCabinetModel);
        }
        return tallCabinetModel;
    }

    placeHandle(parentMesh) {
        const handleClone = Mesh.MergeMeshes([this.editor.models3D.handle.meshComponent.getMesh().clone()], true);
        if (this.width <= 0.15) {
            Utilities.resizeMeshInWidth(handleClone, 0.12);
        }

        handleClone.position = this.parts.doors.door1.door1Handle.mesh.position;
        handleClone.parent = parentMesh;
        this.parts.doors.door1.door1Handle.mesh = handleClone;
        handleClone.alwaysSelectAsActiveMesh = true;

        return handleClone;
    }

    mergeCabinetBlock(parentMesh) {
        let meshes = [];
        Object.keys(this.parts.cabinet).forEach((key) => {
            if (!this.parts.cabinet[key].mesh.name.startsWith('shelf')) {
                if (
                    !(
                        this.sectionInfo?.appliance?.type === Constants.appliance.type.REFRIGERATOR &&
                        this.parts.cabinet[key].mesh.name === 'panelBottom'
                    )
                ) {
                    meshes.push(this.parts.cabinet[key].mesh);
                } else {
                    //because after scaling the dimensions of this mesh weren't updating
                    this.parts.cabinet[key].mesh.computeWorldMatrix();
                }
            }
        });

        const innerStructure = this.editor.addObject(CabinetStructure);
        innerStructure.setParentSection(this.currentParentSection);
        innerStructure.create(meshes, 'cabinets');
        innerStructure.meshComponent.setParent(parentMesh);
        return innerStructure;
    }

    mergeSkirtingBlock(parentMesh) {
        let meshes = [];
        Object.keys(this.parts.skirting).forEach((key) => {
            meshes.push(this.parts.skirting[key].mesh);
        });

        const skirtingBlock = this.editor.addObject(Skirting);
        skirtingBlock.setParentSection(this.currentParentSection);
        skirtingBlock.create(meshes, 'skirting');
        skirtingBlock.enableSelection();
        skirtingBlock.meshComponent.setParent(parentMesh);
        return skirtingBlock;
    }

    mergeDoorBlock(parentMesh) {
        const meshes = [this.parts.doors.door1.singleDoor.mesh];
        const doorBlock = this.editor.addObject(DoorBlock);
        doorBlock.setParentSection(this.currentParentSection);
        doorBlock.create(meshes, 'cabinets');
        doorBlock.meshComponent.setParent(parentMesh);
        return doorBlock;
    }

    mergeTopBlock(parentMesh) {
        const meshes = [];
        Object.keys(this.parts.topCabinet).forEach((key) => {
            meshes.push(this.parts.topCabinet[key].mesh);
        });
        const topCabinetBlock = this.editor.addObject(CabinetStructure);
        topCabinetBlock.setParentSection(this.currentParentSection);
        topCabinetBlock.enableSelection();
        topCabinetBlock.create(meshes, 'cabinets');
        topCabinetBlock.meshComponent.setParent(parentMesh);
        return topCabinetBlock;
    }

    placeRefrigerator(baseCabinetModel) {
        const appliance = this.sectionInfo.appliance;
        if (appliance.format === Constants.appliance.format.FREESTANDING) {
            appliance.meshComponent.getMesh().position.y = appliance.height / 2;
            this.resizeTopCabinetWidth(appliance.getSectionWidthNeeded());
            this.resizeTopCabinetHeight(this.parentCabinet.height - appliance.height);
            baseCabinetModel.buildingBlocks.skirtingBlock.meshComponent.getMesh().visibility = 0;
            baseCabinetModel.buildingBlocks.doorBlock.meshComponent.getMesh().visibility = 0;
            this.parts.cabinet.panelBottom.mesh.visibility = 0;
            this.parts.doors.door1.door1Handle.mesh.visibility = 0;

            const topCabinetBlock = this.mergeTopBlock(baseCabinetModel.mesh);
            topCabinetBlock.dimensions.height = Utilities.roundToPrecision(this.parentCabinet.height - appliance.height);
            baseCabinetModel.buildingBlocks.topCabinetBlock = topCabinetBlock;
        } else if (appliance.format === Constants.appliance.format.BUILTIN) {
            appliance.meshComponent.getMesh().visibility = 0;
            appliance.model.visibility = 0;
        }
    }

    placeOven(baseCabinetModel) {
        const appliance = this.sectionInfo.appliance;
        baseCabinetModel.buildingBlocks.doorBlock.meshComponent.getMesh().visibility = 0;
        const upperDoor = this.parts.doors.upperDoor.mesh.clone();
        const lowerDoor = this.parts.doors.lowerDoor.mesh.clone();

        const doorDimensions = Utilities.getMeshDimensions(upperDoor);
        const lowerDoorDimensions = Utilities.getMeshDimensions(lowerDoor);

        upperDoor.scaling.y = (this.parentCabinet.height - 0.85 - 0.595) / doorDimensions.height;
        lowerDoor.parent = baseCabinetModel.mesh;
        upperDoor.parent = baseCabinetModel.mesh;

        baseCabinetModel.buildingBlocks.handleBlock.dispose();
        const lowerDoorHandle = this.editor.models3D.handle.meshComponent.getMesh().clone();
        const upperDoorHandle = this.editor.models3D.handle.meshComponent.getMesh().clone();

        lowerDoorHandle.position = lowerDoor.position.clone();
        lowerDoorHandle.position.y += lowerDoorDimensions.height / 2 - 0.135;
        upperDoorHandle.position = upperDoor.position.clone();
        upperDoorHandle.position.y -= doorDimensions.height / 2 - 0.135;

        const mergedHandles = Mesh.MergeMeshes([lowerDoorHandle, upperDoorHandle], true);
        mergedHandles.parent = baseCabinetModel.mesh;
        const doorsToMerge = [lowerDoor, upperDoor];
        const doors = this.editor.addObject(DoorBlock);
        doors.setParentSection(this.currentParentSection);
        doors.enableSelection();
        doors.create(doorsToMerge, 'cabinets');
        doors.meshComponent.setParent(baseCabinetModel.mesh);
        baseCabinetModel.buildingBlocks.doorBlock = doors;

        this.parts.oven.ovenModel.mesh.visibility = 0;

        const ovenDimensions = Utilities.getMeshDimensions(this.sectionInfo.appliance.model);
        this.sectionInfo.appliance.model.scaling.x = appliance.width / ovenDimensions.width;
        this.sectionInfo.appliance.model.position = this.parts.oven.ovenModel.mesh.position.clone();
    }

    resize(dimensions) {
        this.width = dimensions.width;
        this.depth = dimensions.depth;
        this.height = dimensions.height;
        this.resizeWidth(dimensions);
        this.resizeDepth(dimensions);
        this.resizeHeight(dimensions);
    }

    resizeWidth(dimensions) {
        let width = dimensions.width;
        width -= Constants.baseCabinetModel.sidePanel.WIDTH * 2;

        Utilities.resizeMeshInWidth(this.parts.cabinet.panelTop.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cabinet.panelBottom.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.cabinet.panelBack.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.doors.door1.singleDoor.mesh, dimensions.width - Constants.GAP_BETWEEN_DOORS);

        const panelBackDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBack.mesh);
        let shift = (width - panelBackDimensions.width) / 2;
        this.parts.cabinet.panelLeft.mesh.position.x -= shift;
        this.parts.cabinet.panelRight.mesh.position.x += shift;
        this.resizeSkirtingBlockInWidth(dimensions);
    }

    resizeSkirtingBlockInWidth(dimensions) {
        let width = dimensions.width;
        width -= 0.1;

        Utilities.resizeMeshInWidth(this.parts.skirting.skirtingFrontCenter.mesh, width);
        const skirtingFrontCenterDimensions = Utilities.getMeshDimensions(this.parts.skirting.skirtingFrontCenter.mesh);
        const shift = (width - skirtingFrontCenterDimensions.width) / 2;

        this.parts.skirting.skirtingFrontLeft.mesh.position.x -= shift;
        this.parts.skirting.skirtingFrontRight.mesh.position.x += shift;
        this.parts.skirting.skirtingLeft.mesh.position.x -= shift;
        this.parts.skirting.skirtingRight.mesh.position.x += shift;
        this.parts.skirting.skirtingBackLeft.mesh.position.x -= shift;
        this.parts.skirting.skirtingBackRight.mesh.position.x += shift;
    }

    resizeTopCabinetWidth(width) {
        width -= Constants.baseCabinetModel.sidePanel.WIDTH * 2;

        Utilities.resizeMeshInWidth(this.parts.topCabinet.topCabinetPanelBottom.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.topCabinet.topCabinetPanelTop.mesh, width);
        Utilities.resizeMeshInWidth(this.parts.topCabinet.topCabinetPanelBack.mesh, width);
        Utilities.resizeMeshInWidth(
            this.parts.topCabinet.topCabinetSingleDoor.mesh,
            width + Constants.baseCabinetModel.sidePanel.WIDTH * 2
        );

        const door1Dimensions = Utilities.getMeshDimensions(this.parts.topCabinet.topCabinetSingleDoor.mesh);
        const shift = (width - door1Dimensions.width) / 2;
        this.parts.topCabinet.topCabinetPanelLeft.mesh.position.x -= shift;
        this.parts.topCabinet.topCabinetPanelRight.mesh.position.x += shift;
    }

    resizeTopCabinetHeight(height) {
        Utilities.resizeMeshInHeight(this.parts.topCabinet.topCabinetPanelBack.mesh, height);
        Utilities.resizeMeshInHeight(this.parts.topCabinet.topCabinetPanelLeft.mesh, height);
        Utilities.resizeMeshInHeight(this.parts.topCabinet.topCabinetPanelRight.mesh, height);
        Utilities.resizeMeshInHeight(this.parts.topCabinet.topCabinetSingleDoor.mesh, height);

        const panelBackDimensions = Utilities.getMeshDimensions(this.parts.topCabinet.topCabinetPanelBack.mesh);
        this.parts.topCabinet.topCabinetPanelTop.mesh.position.y += (height - panelBackDimensions.height) / 2;
        this.parts.topCabinet.topCabinetPanelBottom.mesh.position.y -= (height - panelBackDimensions.height) / 2;
    }

    resizeDepth(dimensions) {
        this.resizeCabinetBlockInDepth(dimensions);
        this.resizeSkirtingBlockInDepth(dimensions);
    }

    resizeCabinetBlockInDepth(dimensions) {
        const DOOR_SIZE = 0.018;
        const depth = dimensions.depth - DOOR_SIZE;
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelLeft.mesh, depth);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelRight.mesh, depth);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelBottom.mesh, depth);
        Utilities.resizeMeshInDepth(this.parts.cabinet.panelTop.mesh, depth);

        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);
        const shift = (depth - panelBottomDimensions.depth) / 2;

        this.parts.cabinet.panelBack.mesh.position.z += shift;
        this.parts.doors.door1.singleDoor.mesh.position.z -= shift;
        this.parts.doors.door1.door1Handle.mesh.position.z -= shift;
        if (this.sectionInfo.appliance?.type === Constants.appliance.type.OVEN) {
            this.parts.oven.ovenModel.mesh.position.z -= shift;
        }
    }

    resizeSkirtingBlockInDepth(dimensions) {
        const DOOR_SIZE = 0.018;
        const depth = dimensions.depth - DOOR_SIZE;
        Utilities.resizeMeshInDepth(this.parts.skirting.skirtingLeft.mesh, depth - 0.09);
        Utilities.resizeMeshInDepth(this.parts.skirting.skirtingRight.mesh, depth - 0.09);

        const panelBottomDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelBottom.mesh);
        const shift = (depth - panelBottomDimensions.depth) / 2;

        this.parts.skirting.skirtingFrontLeft.mesh.position.z -= shift;
        this.parts.skirting.skirtingFrontRight.mesh.position.z -= shift;
        this.parts.skirting.skirtingFrontCenter.mesh.position.z -= shift;
        this.parts.skirting.skirtingBackLeft.mesh.position.z += shift;
        this.parts.skirting.skirtingBackRight.mesh.position.z += shift;
    }

    resizeHeight(dimensions) {
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelLeft.mesh, dimensions.height - 0.15);
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelRight.mesh, dimensions.height - 0.15);
        Utilities.resizeMeshInHeight(this.parts.cabinet.panelBack.mesh, dimensions.height - 0.186);
        Utilities.resizeMeshInHeight(this.parts.doors.door1.singleDoor.mesh, dimensions.height - 0.15);

        const panelLeftDimensions = Utilities.getMeshDimensions(this.parts.cabinet.panelLeft.mesh);
        const shift = (dimensions.height - 0.15 - panelLeftDimensions.height) / 2;

        this.parts.cabinet.panelTop.mesh.position.y += shift;
        this.parts.cabinet.panelBottom.mesh.position.y -= shift;
        this.parts.skirting.skirtingFrontLeft.mesh.position.y -= shift;
        this.parts.skirting.skirtingFrontRight.mesh.position.y -= shift;
        this.parts.skirting.skirtingFrontCenter.mesh.position.y -= shift;
        this.parts.skirting.skirtingBackLeft.mesh.position.y -= shift;
        this.parts.skirting.skirtingBackRight.mesh.position.y -= shift;
        this.parts.skirting.skirtingLeft.mesh.position.y -= shift;
        this.parts.skirting.skirtingRight.mesh.position.y -= shift;
    }

    getResizingDimensions(sectionWidth) {
        return {
            width: sectionWidth,
            depth: this.parentCabinet.depth,
            height: this.parentCabinet.height,
        };
    }
}
