import { Vector3, CubeTexture } from '@babylonjs/core';
import { AdvancedDynamicTexture } from '@babylonjs/gui';
import store from '@/store/index.js';
import { Constants } from '../Tools/constants';
import { Utilities } from '../Tools/utilities';
import { SceneComponent } from '../Components/SceneComponent';
import { CameraComponent } from '../Components/CameraComponent';
import { RoomObject } from '../KitchenObjects/Room/RoomObject';
import { FloatingFixtureObject } from '../KitchenObjects/Fixtures/FloatingFixtureObject';
import { DoorObject } from '../KitchenObjects/Fixtures/DoorObject';
import { BaseUnit } from '../KitchenObjects/Cabinets/BaseUnit';
import { ColumnObject } from '../KitchenObjects/Fixtures/ColumnObject';
import { MouseActionsHandler } from '../Editor3D/MouseActionsHandler';
import { BaseAppliance } from '../KitchenObjects/Appliances/BaseAppliance';
import { DesignGenerator } from './DesignGeneration/DesignGenerator';
import { CabinetReplacer } from './DesignGeneration/CabinetReplacer';
import { LayoutDetector } from './DesignGeneration/LayoutDetector';
import { Sink } from '../KitchenObjects/Appliances/Sink';
import { IslandReplacer } from './DesignGeneration/IslandReplacer';
import { Island } from '../KitchenObjects/Cabinets/Island';
import { Hood } from '../KitchenObjects/Appliances/Hood';
import { Dishwasher } from '../KitchenObjects/Appliances/Dishwasher';
import { WashingMachine } from '../KitchenObjects/Appliances/WashingMachine';
import { Cooktop } from '../KitchenObjects/Appliances/Cooktop';
import { Oven } from '../KitchenObjects/Appliances/Oven';
import { Refrigerator } from '../KitchenObjects/Appliances/Refrigerator';
import { PBRComponent } from '../Components/PBRComponent';
import { WallUnit } from '../KitchenObjects/Cabinets/WallUnit';
import { clear } from './TexturePool';
import { Section } from '../KitchenObjects/CabinetSections/Section';

export class Editor3D {
    objects = [];
    globalComponents = [];
    selectedItem;
    fixtures = [];
    cabinets = [];
    appliances = [];
    resizing;
    resizingIsland;
    lastCabinetId = 0;
    lastApplianceId = 0;
    lastBuildingBlockId = 0;
    lastSectionId = 0;

    multiselection = false;
    models3D = {};
    isPrinting = false;

    constructor(sceneInfo) {
        this.mouseActionsHandler = new MouseActionsHandler(this);
        this.designGenerator = new DesignGenerator(this);
        this.cabinetReplacer = new CabinetReplacer(this);
        this.islandReplacer = new IslandReplacer(this);
        this.layoutDetector = new LayoutDetector(this);
        this.PBRComponent = this.addGlobalComponent(PBRComponent);
        this.loadEditor3D(sceneInfo);

        this.mouseActionsHandler.bindings();
        this.sceneComponent.engine.runRenderLoop(() => {
            this.cameraComponent.showHiddenComponents();
            this.cameraComponent.getPickedInfo();
            this.sceneComponent.get().render();
        });
    }

    async loadEditor3D(sceneInfo) {
        store.commit('core/toggleProjectLoading', true);
        this.sceneInfo = sceneInfo;
        this.canvas = document.getElementById('renderCanvas');

        this.canvas.style.border = 0;
        this.floorplan = { isClosed: true };
        this.unit = sceneInfo.unit;

        this.sceneComponent = this.addGlobalComponent(SceneComponent);
        this.sceneComponent.prepare(this.engine);
        window.onresize = () => {
            if (this.sceneComponent.engine) {
                this.sceneComponent.engine.resize();
            }
        };
        this.cameraComponent = this.addGlobalComponent(CameraComponent);
        this.cameraComponent.prepare(this.canvas);
        store.commit('core/setEditorEngine', this);

        this.widgetUI = AdvancedDynamicTexture.CreateFullscreenUI('UI');

        await this.buildKitchen();

        this.cubeTexture = new CubeTexture.CreateFromPrefilteredData(
            require('@/assets/textures/envSpecularHDR.dds').default,
            this.sceneComponent.get()
        );
        if (sceneInfo.step === 4) {
            await this.designGenerator.generate();
        } else {
            store.commit('core/toggleProjectLoading', false);
        }
        this.handleTabContextChange();
        //this.sceneComponent.createGizmos();
    }

    async buildKitchen() {
        const roomObject = this.addObject(RoomObject);
        const corners = this.retrieveCorners();
        roomObject.setCornerPositions(corners);
        roomObject.build();

        let target = Utilities.getPolygonCentroid(roomObject.cornerPositions);
        this.cameraComponent.camera.setTarget(new Vector3(target.x, target.y + Constants.room.dimensions.HEIGHT / 2, target.z));
        this.sceneComponent.ground.position = target;
        await this.buildKitchenObjects();
    }

    async buildKitchenObjects() {
        this.generateFixtures(this.getObjectByType(RoomObject));
        this.generateCabinets();
        await this.generateAppliances();
        this.recreateConnections();
    }

    recreateConnections() {
        if (this.sceneInfo.cabinets) {
            for (let cabinet = 0; cabinet < this.sceneInfo.cabinets.length; cabinet++) {
                let cabinetData = this.sceneInfo.cabinets[cabinet];
                const currentCabinet = Utilities.getElementByMeshId(cabinetData.id, this.cabinets);
                if (cabinetData.connectedCabinet) {
                    currentCabinet.extendedCabinetManager.connectWithCabinet(
                        Utilities.getElementByMeshId(cabinetData.connectedCabinet, this.cabinets),
                        Constants.corner.CORNER_A
                    );
                }

                if (cabinetData.secondConnectedCabinet) {
                    currentCabinet.extendedCabinetManager.connectWithCabinet(
                        Utilities.getElementByMeshId(cabinetData.secondConnectedCabinet, this.cabinets),
                        Constants.corner.CORNER_B
                    );
                }
            }
        }
    }

    addGlobalComponent(ComponentType) {
        const globalComponent = new ComponentType();
        globalComponent.editor = this;
        this.globalComponents.push(globalComponent);
        return globalComponent;
    }

    addObject(ObjectType) {
        const object = new ObjectType();
        object.editor = this;
        this.objects.push(object);
        return object;
    }

    getObjectByType(type) {
        for (let index = 0; index < this.objects.length; index++) {
            if (this.objects[index] instanceof type) {
                return this.objects[index];
            }
        }
        return null;
    }

    getObjectsByType(type) {
        let objects = [];
        for (let index = 0; index < this.objects.length; index++) {
            if (this.objects[index] instanceof type) {
                objects.push(this.objects[index]);
            }
        }
        return objects;
    }

    removeObject(object) {
        for (let index = 0; index < this.objects.length; index++) {
            if (object === this.objects[index]) {
                this.objects.splice(index, 1);
            }
        }
    }

    dispose() {
        clear();
        this.sceneComponent.engine.stopRenderLoop();
        this.sceneComponent.engine.dispose();
        delete this.sceneComponent.engine;
    }

    retrieveCorners() {
        let corners = [];
        for (const corner of this.sceneInfo.corners) {
            let convertedCorner = Utilities.convertCoordinatesFrom2D(corner);
            corners.push(convertedCorner);
        }
        return corners;
    }

    generateBaseFixture(fixtureData) {
        let fixtureComponent = this.addObject(fixtureData.type);
        fixtureComponent.setDimensions(fixtureData.dimensions.width, fixtureData.dimensions.height);
        fixtureComponent.setPosition(fixtureData.position);
        return fixtureComponent;
    }

    generateFixtures(roomObject) {
        for (const fixture of this.sceneInfo.fixtures) {
            let fixtureComponent;
            let fixtureDimensions = fixture.dimensions;

            if (this.unit === 'in') {
                fixtureDimensions = Utilities.convertToMillimeters(fixture);
                fixtureDimensions.width *= Constants.MM_TO_BJS_COEFF;
                fixtureDimensions.depth *= Constants.MM_TO_BJS_COEFF;
                fixtureDimensions.height *= Constants.MM_TO_BJS_COEFF;
                if (fixtureDimensions.heightFromFloor) {
                    fixtureDimensions.heightFromFloor *= Constants.MM_TO_BJS_COEFF;
                }
            }

            switch (fixture.type) {
                case Constants.fixture.type.DOOR:
                    fixtureComponent = this.generateBaseFixture({
                        type: DoorObject,
                        dimensions: {
                            width: (fixtureDimensions.width / Constants.MM_TO_BJS_COEFF) * Constants.room.scale,
                            height: fixtureDimensions.height / Constants.MM_TO_BJS_COEFF,
                        },
                        position: Utilities.convertCoordinatesFrom2D(fixture.position),
                    });

                    fixtureComponent.type = Constants.fixture.type.DOOR;
                    fixtureComponent.subType = fixture.subType;
                    fixtureComponent.buildMesh();
                    fixtureComponent.meshComponent.getMesh().name = Constants.fixture.type.DOOR;
                    break;

                case Constants.fixture.type.WINDOW:
                    fixture.position.z -= Constants.models3D.window.SILL_HEIGHT * 2;
                    fixtureComponent = this.generateBaseFixture({
                        type: FloatingFixtureObject,
                        dimensions: {
                            width: (fixtureDimensions.width / Constants.MM_TO_BJS_COEFF) * Constants.room.scale,
                            height: fixtureDimensions.height / Constants.MM_TO_BJS_COEFF,
                        },
                        position: Utilities.convertCoordinatesFrom2D(fixture.position),
                    });

                    fixtureComponent.setHeightFromFloor(fixtureDimensions.heightFromFloor / Constants.MM_TO_BJS_COEFF);
                    fixtureComponent.type = Constants.fixture.type.WINDOW;
                    fixtureComponent.buildMesh();
                    fixtureComponent.meshComponent.getMesh().name = Constants.fixture.type.WINDOW;
                    break;

                case Constants.fixture.type.FIXED_COLUMN:
                    fixtureComponent = this.generateBaseFixture({
                        type: ColumnObject,
                        dimensions: {
                            width: (fixtureDimensions.width / Constants.MM_TO_BJS_COEFF) * Constants.room.scale,
                            height: (fixtureDimensions.depth / Constants.MM_TO_BJS_COEFF) * Constants.room.scale,
                        },
                        position: Utilities.convertCoordinatesFrom2D(fixture.position),
                    });
                    fixtureComponent.setPosition(Utilities.convertCoordinatesFrom2D(fixture.columnPosition));
                    fixtureComponent.type = Constants.fixture.type.FIXED_COLUMN;
                    fixtureComponent.buildMesh();
                    fixtureComponent.positionMesh();
                    fixtureComponent.meshComponent.getMesh().name = Constants.fixture.type.FIXED_COLUMN;
                    break;

                default:
                    fixtureComponent = this.generateBaseFixture({
                        type: FloatingFixtureObject,
                        dimensions: {
                            width: (fixtureDimensions.width / Constants.MM_TO_BJS_COEFF) * Constants.room.scale,
                            height: fixtureDimensions.height / Constants.MM_TO_BJS_COEFF,
                        },
                        position: Utilities.convertCoordinatesFrom2D(fixture.position),
                    });

                    fixtureComponent.setHeightFromFloor(fixtureDimensions.heightFromFloor / Constants.MM_TO_BJS_COEFF);
                    fixtureComponent.type = Constants.fixture.type.OPEN_SPACE;
                    fixtureComponent.buildMesh();
                    fixtureComponent.meshComponent.getMesh().name = Constants.fixture.type.OPEN_SPACE;
            }

            fixtureComponent.rotate(fixture.rotation);

            this.fixtures.push(fixtureComponent);
            roomObject.setFixture(fixtureComponent);

            if (fixture.type === Constants.fixture.type.OPEN_SPACE) {
                this.removeObject(fixtureComponent);
            }
        }
    }

    generateCabinets() {
        const sceneInfo = JSON.parse(JSON.stringify(this.sceneInfo));
        if (sceneInfo.cabinets) {
            for (let cabinet = 0; cabinet < sceneInfo.cabinets.length; cabinet++) {
                let cabinetData = sceneInfo.cabinets[cabinet];
                this.generateCabinet(cabinetData);
            }
            store.commit('cabinets/setSelectedCabinet', null);
        }
    }

    async generateAppliances() {
        if (this.sceneInfo.appliances) {
            for (let appliance = 0; appliance < this.sceneInfo.appliances.length; appliance++) {
                let applianceData = this.sceneInfo.appliances[appliance];
                await this.generateAppliance(applianceData);
            }
            store.commit('appliances/setSelectedAppliance', null);
        }
    }

    generateCabinet(sceneData) {
        const data = { ...sceneData };

        const roomObject = this.getObjectByType(RoomObject);
        let cabinetObject;
        if (data.type === Constants.cabinet.type.ISLAND) {
            cabinetObject = this.addObject(Island);
        } else if (data.type === Constants.cabinet.type.WALL) {
            cabinetObject = this.addObject(WallUnit);
        } else {
            cabinetObject = this.addObject(BaseUnit);
        }
        if (typeof data.wall === 'number') {
            cabinetObject.assignedWall = roomObject.walls[data.wall];
        } else if (data.type !== Constants.cabinet.type.ISLAND) {
            cabinetObject.assignedWall = this.cameraComponent.getVisibleWall();
        }

        //If this method is being called to create an extended cabinet don't convert
        if (data.currentUnit === 'in' && !data.connectedCabinetCorner) {
            data.dimensions = Utilities.convertToMillimeters(data);
        } else {
            data.dimensions = Utilities.convertToBJSUnit(data.dimensions);
        }
        cabinetObject.setPosition(Utilities.getPolygonCentroid(roomObject.cornerPositions));
        cabinetObject.addCabinet(data, false);

        if (data.type !== Constants.cabinet.type.ISLAND) {
            let initialPosition = cabinetObject.position;
            if (data.connectedCabinetCorner) {
                //when adding extended cabinet
                initialPosition = cabinetObject.extendedCabinetManager.calculateExtendedCabinetPosition(
                    data.connectedCabinetCorner
                );
                cabinetObject.meshComponent.getMesh().isExtendedCabinet = true;
                initialPosition.y += cabinetObject.height / 2;
            } else if (data.position) {
                //when loading project from project data
                initialPosition = new Vector3(data.position._x, data.position._y, data.position._z);
            }
            cabinetObject.meshComponent.mesh.position = initialPosition;
            cabinetObject.setPosition(initialPosition);
            cabinetObject.rotateCabinet(cabinetObject.assignedWall.rotationAngle);
            if (!data.position) {
                cabinetObject.shiftCabinet();
            }
        } else {
            let rowDepths = cabinetObject.calculateRowDepths(cabinetObject.depth);
            cabinetObject.setRowDepths(rowDepths.firstRow, rowDepths.secondRow);
            if (data.position) {
                const position = new Vector3(data.position._x, data.position._y, data.position._z);
                cabinetObject.setPosition(position);
                cabinetObject.meshComponent.getMesh().position = position;
                if (data.rotation) {
                    cabinetObject.meshComponent.getMesh().rotation.y += (data.rotation * Math.PI) / 180;
                    cabinetObject.rotation = data.rotation;
                }
                if (data.peninsulaData.cabinetId) {
                    const peninsulaConnection = Utilities.getElementByMeshId(data.peninsulaData.cabinetId, this.cabinets);
                    cabinetObject.connectPeninsula(
                        peninsulaConnection,
                        data.peninsulaData.side,
                        data.peninsulaData.connectionEdge,
                        data.peninsulaData.edgeIndex
                    );
                }
            }
        }
        if (
            cabinetObject.meshComponent.getMesh().visibility === Constants.cabinet.transparency.VISIBLE &&
            data.state !== 'loadingScene'
        ) {
            cabinetObject.measurementLines.update();
        }

        cabinetObject.sectionsInformation = data.sectionsInformation;
        return cabinetObject;
    }

    async generateAppliance(data) {
        const appliance = this.createAppliance(data);
        if (data.currentCabinet) {
            appliance.currentCabinet = Utilities.getElementByMeshId(data.currentCabinet, this.cabinets);
            appliance.assignedWall = appliance.currentCabinet?.assignedWall;
            appliance.assignedRow = data?.assignedRow;
            appliance.rotation = data.rotation;
        } else if (typeof data.wall === 'number') {
            appliance.assignedWall = Utilities.getElementByMeshId(data.wall, this.getObjectByType(RoomObject).walls);
        }
        if (data.type === Constants.appliance.type.HOOD) {
            if (appliance.currentCabinet && appliance.currentCabinet.type === Constants.cabinet.type.WALL) {
                appliance.heightFromFloor = appliance.currentCabinet.heightFromFloor + appliance.height / 2;
            } else {
                appliance.heightFromFloor = Constants.hood.HEIGHT_FROM_FLOOR;
            }
            if (typeof data.currentCooktop === 'number') {
                appliance.currentCooktop = Utilities.getElementByMeshId(data.currentCooktop, this.appliances);
                appliance.currentCooktop.hasHood = appliance;
                appliance.rotation = appliance.currentCooktop.rotation;
                appliance.assignedWall = appliance.currentCooktop?.assignedWall;
            }
        }
        appliance.dimensions = data.dimensions;
        if (this.sceneInfo.unit === 'in') {
            data.dimensions = Utilities.convertToMillimeters(data);
        } else {
            data.dimensions = Utilities.convertToBJSUnit(data.dimensions);
        }
        await appliance.build(data);
        appliance.unhighlight();
    }

    generateNewAppliance(data) {
        const appliance = this.createAppliance(data);
        appliance.dimensions = data.dimensions;
        if (data.currentUnit === 'in') {
            data.dimensions = Utilities.convertToMillimeters(data);
        } else {
            data.dimensions = Utilities.convertToBJSUnit(data.dimensions);
        }
        appliance.build(data);
    }

    createAppliance(data) {
        const applianceClassesPerType = {
            sink: Sink,
            cooktop: Cooktop,
            hood: Hood,
            oven: Oven,
            dishwasher: Dishwasher,
            washer: WashingMachine,
            refrigerator: Refrigerator,
        };
        this.layoutDetector.extractLayouts();

        let applianceType = BaseAppliance;
        if (applianceClassesPerType[data.type]) {
            applianceType = applianceClassesPerType[data.type];
        }
        const appliance = this.addObject(applianceType);
        this.appliances.push(appliance);
        return appliance;
    }

    checkForFreeCooktops() {
        const appliances = this.appliances;
        const hoodInfo = {
            hasCooktops: false,
            hasFreeCooktops: false,
        };
        if (appliances.length > 0) {
            for (let index = 0; index < appliances.length; index++) {
                if (appliances[index].type === Constants.appliance.type.COOKTOP || appliances[index].subtype === 'range') {
                    hoodInfo.hasCooktops = true;
                    if (!appliances[index].hasHood) {
                        hoodInfo.hasFreeCooktops = true;
                        break;
                    }
                }
            }
        }
        return hoodInfo;
    }

    generateNewCabinet(data) {
        const newCabinet = this.generateCabinet(data);
        newCabinet.movementManager.followCursor();
    }

    getCabinetSlots() {
        let slots = [];
        for (let index = 0; index < this.cabinets.length; index++) {
            if (this.cabinets[index].type !== Constants.cabinet.type.ISLAND) {
                slots.push({ blockedSegment: this.cabinets[index].getEdgesOnWall(), cabinet: this.cabinets[index] });
            }
        }
        return slots;
    }

    generateDesign() {
        this.sceneComponent.save3D();
        this.designGenerator.generate();
    }

    loadPreviousState() {
        this.lastSectionId = 0;
        this.designGenerator.revert();
    }

    clearScene() {
        const sceneMeshes = this.sceneComponent.get().meshes;
        for (let index = 0; index < sceneMeshes.length; index++) {
            sceneMeshes[index].dispose(false, true);
            index--;
        }
        clear();
        store.commit('core/setProjectData', {});
        this.fixtures = [];
        this.cabinets = [];
        this.appliances = [];
        this.sceneInfo = {};
    }

    hasTallCabinets() {
        for (let index = 0; index < this.cabinets.length; index++) {
            if (this.cabinets[index].type === Constants.cabinet.type.TALL) {
                return true;
            }
        }
        return false;
    }

    handleTabContextChange() {
        document.addEventListener('visibilitychange', async () => {
            if (document.visibilityState === 'visible') {
                console.log('browser tab has focus');
                if (this.stopDownload) {
                    if (!this.isPrinting) {
                        this.stopDownload = false;
                    }
                }
            } else {
                console.log('browser tab does NOT have focus');
                if (this.isPrinting) {
                    this.stopDownload = true;
                }
            }
        });
    }

    async insertSection(width, parentCabinet, index = 0, row = null) {
        if (width <= 0) {
            return;
        }
        width = Utilities.roundToPrecision(width, 1000);
        const section = this.addObject(Section);
        section.build(parentCabinet, width);
        if (row) {
            parentCabinet.sections[row].splice(index, 0, section);
            section.assignedRow = row;
        } else {
            parentCabinet.sections.splice(index, 0, section);
        }
        const mesh = await section.getResizedModel(width);
        section.meshComponent.setMesh(mesh);

        mesh.setEnabled(true);
        mesh.visibility = 1;
        mesh.position = new Vector3.Zero();
        return section;
    }

    getApplianceById(id) {
        for (const appliance of this.appliances) {
            if (appliance.id === id) {
                return appliance;
            }
        }
    }
}
