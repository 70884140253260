import config from './config';
import insertProjectsDownloadedOne from '@/graphql/queries/insertProjectsDownloadedOne.gql';

export const generated = {
    namespaced: true,
    state: {
        objects: config,
        //[]
        selectedObject: [
            // {
            //     type: 'countertop',
            //     subtype: null,
            //     color: {
            //         value: '#FAF0E6',
            //         type: 'monoColor',
            //     },
            //     collection: {
            //         name: 'Mono Color',
            //     },
            // },
            // {
            //     type: 'wall',
            //     subtype: '1',
            //     color: {
            //         value: '#FAF0E6',
            //         type: 'monoColor',
            //     },
            //     collection: {
            //         name: 'Mono Color',
            //     },
            // },
            // {
            //     type: 'cabinets',
            //     subtype: 'base',
            //     color: {
            //         value: '#FAF0E6',
            //         type: 'monoColor',
            //     },
            //     collection: {
            //         name: 'Mono Color',
            //     },
            //     surface: 'gloss',
            //     door: {
            //         type: '2door',
            //         style: 'contemporary',
            //     },
            //     dimensions: {
            //         width: 600,
            //         height: 900,
            //     },
            // },
            // {
            //     type: 'cabinets',
            //     subtype: 'base',
            //     color: {
            //         value: '#FFFFFF',
            //         type: 'monoColor',
            //     },
            //     collection: {
            //         name: 'Mono Color',
            //     },
            //     surface: 'gloss',
            //     door: {
            //         type: '1door',
            //         style: 'contemporary',
            //     },
            //     dimensions: {
            //         width: 900,
            //         height: 900,
            //     },
            // },
        ],
        hasWatermark: false,
        generatedIDX: null,
        islandGeneratedIDX: null,
        tab: null,
    },
    mutations: {
        setSelectedObject(state, object) {
            state.selectedObject = object;
            if (process.env.NODE_ENV === 'development')
                console.log('selected object:', object);
        },
        toggleCollectionSheet(state, { i, type }) {
            const object = state.objects[type];
            object.collections[i].sheet = !object.collections[i].sheet;
        },
        changeSelectedColor(state, { color, i, type }) {
            state.objects[type].collections[i].color.selected = color;
        },
        changeSelectedSurface(state, { surface, i, type }) {
            state.objects[type].collections[i].surface.selected = surface;
        },
        changeSelectedDoorStyle(state, { doorStyle, i, type }) {
            state.objects[type].collections[i].doorStyle.selected = doorStyle;
        },
        setGeneratedIDX(state, val) {
            state.generatedIDX = val;
            // console.log("store idx", val)
        },
        setIslandGeneratedIDX(state, val) {
            state.islandGeneratedIDX = val;
        },
        setTab(state, val) {
            state.tab = val;
        },
        toggleWatermark(state, val) {
            state.hasWatermark = val;
        }
    },
    actions: {
        addProjectDownloadedEntry({ commit }, { apollo, projectId }) {
            let projectDownloadedEntryId = apollo
                .mutate({
                    mutation: insertProjectsDownloadedOne,
                    variables: {
                        project: projectId,
                    },
                })
                .then((data) => {
                    if (process.env.NODE_ENV === 'development')
                        console.log('projectDownloadedEntryId', data.data.insert_projects_downloaded_one.id);
                    
                        return data.data.insert_projects_downloaded_one.id;
                })
                .catch((error) => {
                    console.error(error);
                    commit('core/logError', JSON.stringify(error.message), { root: true })
                });

            return projectDownloadedEntryId;
        },
    }
};
