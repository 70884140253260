<template>
    <v-stepper v-model="step" non-linear class="px-0 px-sm-5">
        <v-stepper-items>
            <!-- Step 1 -->
            <DrawRoom></DrawRoom>
            <!-- Step 2 -->
            <DrawCabinets></DrawCabinets>
            <!-- Step 3 -->
            <AddAppliances></AddAppliances>
            <!-- Step 4 -->
            <Generated></Generated>
        </v-stepper-items>
        <!-- Header -->
        <StepperHeader></StepperHeader>
    </v-stepper>
</template>

<script>
import DrawRoom from './Step1DrawRoom/DrawRoom';
import DrawCabinets from './Step2DrawCabinets/DrawCabinets';
import AddAppliances from './Step3AddAppliances/AddAppliances';
import Generated from './Step4Generated/Generated';
import StepperHeader from './StepperHeader';

export default {
    components: {
        DrawRoom,
        DrawCabinets,
        AddAppliances,
        StepperHeader,
        Generated,
    },
    computed: {
        step: {
            set(step) {
                this.$store.commit('core/setStep', step);
            },
            get() {
                return this.$store.state.core.step;
            },
        },
    },
};
</script>
