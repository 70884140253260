import { render, staticRenderFns } from "./FixtureEdit.vue?vue&type=template&id=f0ec9cec&"
import script from "./FixtureEdit.vue?vue&type=script&lang=js&"
export * from "./FixtureEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f0ec9cec')) {
      api.createRecord('f0ec9cec', component.options)
    } else {
      api.reload('f0ec9cec', component.options)
    }
    module.hot.accept("./FixtureEdit.vue?vue&type=template&id=f0ec9cec&", function () {
      api.rerender('f0ec9cec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/stepper/Step1DrawRoom/FixtureEdit.vue"
export default component.exports