import saveProject from '@/graphql/queries/saveProject.gql';
import { Editor2D } from '../../services/editor/2D/Editor2D';
import { render3D } from '../../services/editor/3D/render3D';
import { Constants } from '@/services/editor/3D/Tools/constants.js';

export const core = {
    namespaced: true,
    state: {
        step: 0,
        startedDesigning: true,
        canvasLoaded: false,
        unit: 'mm',
        editorEngine: null,
        projectData: {},
        isSceneSaved: false,
        resetProject: false,
        isProjectLoading: true,
        isPageLoading: false,
        canvasDim: {
            width: null,
            height: null,
        },
        popupMsg: '',
        cabinetData: '',
        errors: null,
    },
    getters: {
        step: (state) => state.step,
        editorEngine: (state) => state.editorEngine,
        selectedUnit: (state) => state.unit,
        startedDesigning: (state) => !state.startedDesigning,
        isSceneSaved: (state) => state.isSceneSaved,
        isProjectLoading: (state) => state.isProjectLoading,
        projectData: (state) => state.projectData,
        applianceData: (state) => {
            return state.editorEngine.appliances.map((appliance) => ({
                name: appliance.name,
                type: appliance.type,
                color: appliance.color,
                direction: appliance.direction,
                subtype: appliance.subtype,
                format: appliance.format,
                rotation: appliance.rotation,
                dimensions: appliance.dimensions,
                position: appliance.meshComponent?.mesh?.position,
                wall: appliance.assignedWall ? appliance.assignedWall.id : null,
                currentCabinet: appliance.currentCabinet
                    ? appliance.currentCabinet.id
                    : null,
                currentCooktop: appliance.currentCooktop
                    ? appliance.currentCooktop.id
                    : null,
                id: appliance.id,
                assignedRow: appliance.assignedRow
                    ? appliance.assignedRow
                    : null,
            }));
        },
    },
    mutations: {
        // Save Step number for Stepper
        setStep(state, step) {
            state.step = step;
        },
        setUnit(state, unit) {
            state.unit = unit;
        },
        setEditorEngine(state, engine) {
            state.editorEngine = engine;
        },
        canvasLoaded(state) {
            state.canvasLoaded = true;
        },
        setStartedDesigning(state, val) {
            state.startedDesigning = val;
        },
        toggleSceneSaved(state, value) {
            state.isSceneSaved = value;
        },
        setResetProject(state, value) {
            state.resetProject = value;
        },
        setProjectData(state, data) {
            state.projectData = data;
        },
        toggleProjectLoading(state, val) {
            state.isProjectLoading = val;
        },
        setPopupMsg(state, msg) {
            state.popupMsg = msg;
        },
        setCabinetData(state, value) {
            state.cabinetData = value;
        },
        togglePageLoader(state, val) {
            state.isPageLoading = val;
        },
        resizeCanvas(state, {width, height}) {
            state.canvasDim.width = width;
            state.canvasDim.height = height;
        },
        logErrors(state, val) {
            state.errors = val;
        }
    },
    actions: {
        saveProjectAction({ commit, state }, { apollo, projectId }) {
            console.log('save project action');
            if (state.step === 1) {
                state.editorEngine.controls.save2D();
            } else if (
                state.step === 2 ||
                state.step === 3 ||
                state.step === 4
            ) {
                state.editorEngine.sceneComponent.save3D();
            }

            console.log('state.projectData', state.projectData);
            const projectData = JSON.stringify(state.projectData);
            console.log('projectId', projectId);
            console.log('projectData', projectData);

            apollo
                .mutate({
                    mutation: saveProject,
                    variables: {
                        projectId: projectId,
                        data: projectData,
                    },
                })
                .then((data) => {
                    if (process.env.NODE_ENV === 'development')
                        console.log('saveProject', data.data);

                    // set EditorEngine.hasChanges = false
                    commit('toggleSceneSaved', true);
                })
                .catch((error) => {
                    console.error(error);
                    commit('logErrors', JSON.stringify(error.message))
                });
        },
        loadProjectAction({ commit, state }, { project }) {
            // project is a JSON file

            commit('canvasLoaded', project.canvasLoaded);
            commit('setUnit', project.unit);
            commit('setStartedDesigning', project.startedDesigning);
            commit('setStep', project.step || 1);
            commit('setProjectData', project);
            console.log(project);

            if (state.step <= 1) {
                state.editorEngine.controls.loadScene(project);
                state.editorEngine.controls.fixCanvas();
                commit('toggleProjectLoading', false);
                console.log("---- load scene loading toggle ----")
            } else if (
                state.step === 2 ||
                state.step === 3 ||
                state.step === 4
            ) {
                if (state.editorEngine instanceof Editor2D) {
                    state.editorEngine.dispose();
                }
                render3D(project);
            }
            commit('toggleSceneSaved', true);
        },
    },
};
