import config from './config';

export const cabinets = {
    namespaced: true,
    state: {
        cabinets: config,
        selectedCabinet: null,
    },
    mutations: {
        addObj(
            state,
            {
                editorEngine,
                selectedUnit,
                cabinetProps: { id, name, dimensions },
            }
        ) {
            const data = {
                name,
                type: id,
                dimensions: {},
                currentUnit: selectedUnit,
            };

            Object.keys(dimensions).forEach((key) => {
                data.dimensions[key] = dimensions[key].value[data.currentUnit];
            });

            if (process.env.NODE_ENV === 'development') {
                console.log('add cabinet data', data);
            }
            editorEngine.generateNewCabinet(data);
            editorEngine.followCursor = true;
            // Use data for BJS cabinet obj
            // editorEngine
        },
        setSelectedCabinet(state, cabinet) {
            state.selectedCabinet = cabinet;

            if (process.env.NODE_ENV === 'development')
                console.log(/*'selected cabinet:', cabinet*/);
        },
    },
    actions: {
        addObj({ commit, rootGetters }, cabinetProps) {
            const selectedUnit = rootGetters['core/selectedUnit'],
                editorEngine = rootGetters['core/editorEngine'];
            console.log(cabinetProps);

            commit('addObj', { selectedUnit, editorEngine, cabinetProps });
        },
    },
};
