import store from '@/store/index.js';
import { Color3 } from '@babylonjs/core';
import { TexturePool } from '../../Editor3D/TexturePool';
import { BuildingBlock } from './BuildingBlock';

export class Skirting extends BuildingBlock {
    constructor() {
        super();
    }

    addTexture() {
        const collection = store.state.generated.objects.skirting?.collections[0];
        let customizationInformation = this.parentCabinet.skirtingCustomization.color;

        if (!customizationInformation) {
            customizationInformation = {
                type: collection.color.type,
                value: collection.color.items[0],
                pattern: 'noPattern',
            };
        }
        this.changeColor(
            collection.name,
            customizationInformation.type,
            customizationInformation.value,
            customizationInformation.pattern
        );
    }

    changeColor(collectionName, collectionType, color) {
        this.collection.name = collectionName;
        this.color.value = color;
        if (collectionType === 'texture') {
            this.currentColor = new Color3(1, 1, 1);
            this.color.pattern = color;
            this.meshComponent.getMesh().material = this.editor.PBRComponent.getTextureFromPool(
                collectionType,
                color,
                '#FFFFFF',
                0,
                0,
                '',
                'metallic'
            );
        }
        this.editor.sceneComponent.save3D();
    }
}
