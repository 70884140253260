import { Mesh, Vector3, ActionManager, VertexBuffer, ExecuteCodeAction, Color3 } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { BuildingBlock } from './BuildingBlock';
import store from '@/store/index.js';
import { BaseMeasurementLine } from '../../Components/Base/BaseMeasurementLine';
import { Utilities } from '../../Tools/utilities';
import { RoomObject } from '../Room/RoomObject';

export class Wall extends BuildingBlock {
    baseInnerCornerA;
    baseInnerCornerB;
    baseOuterCornerA;
    baseOuterCornerB;

    topInnerCornerA;
    topInnerCornerB;
    topOuterCornerA;
    topOuterCornerB;

    vertices;
    length;
    id;
    rotationAngle;
    diffuseColor;
    direction;
    replacementDirection;

    slots = [];

    leftFaceIndices = {
        FIRST_CORNER: 0,
        SECOND_CORNER: 3,
        THIRD_CORNER: 6,
        FOURTH_CORNER: 9,
    };

    rightFaceIndices = {
        FIRST_CORNER: 12,
        SECOND_CORNER: 15,
        THIRD_CORNER: 18,
        FOURTH_CORNER: 21,
    };

    frontFaceIndices = {
        FIRST_CORNER: 24,
        SECOND_CORNER: 27,
        THIRD_CORNER: 30,
        FOURTH_CORNER: 33,
    };

    backFaceIndices = {
        FIRST_CORNER: 36,
        SECOND_CORNER: 39,
        THIRD_CORNER: 42,
        FOURTH_CORNER: 45,
    };

    topFaceIndices = {
        FIRST_CORNER: 48,
        SECOND_CORNER: 51,
        THIRD_CORNER: 54,
        FOURTH_CORNER: 57,
    };

    bottomFaceIndices = {
        FIRST_CORNER: 60,
        SECOND_CORNER: 63,
        THIRD_CORNER: 66,
        FOURTH_CORNER: 69,
    };
    customization;

    constructor() {
        super();
        this.vertices = [];
        this.measurementLine = this.registerComponent(BaseMeasurementLine);
    }

    findWallOrientation() {
        if (
            Math.abs(this.baseInnerCornerB.x - this.baseInnerCornerA.x) >=
            Math.abs(this.baseInnerCornerB.z - this.baseInnerCornerA.z)
        ) {
            return Constants.wall.orientation.HORIZONTAL;
        } else {
            return Constants.wall.orientation.VERTICAL;
        }
    }

    setWallAngle() {
        this.rotationAngle = Math.atan2(
            this.baseInnerCornerB.z - this.baseInnerCornerA.z,
            this.baseInnerCornerB.x - this.baseInnerCornerA.x
        );
    }

    setId(id) {
        this.id = id;
    }

    setBaseCornerPositions(innerCornerA, innerCornerB, outerCornerA, outerCornerB) {
        this.baseInnerCornerA = innerCornerA;
        this.baseInnerCornerB = innerCornerB;
        this.baseOuterCornerA = outerCornerA;
        this.baseOuterCornerB = outerCornerB;
    }

    calculateTopCornerPositions() {
        this.topInnerCornerA = this.calculateTopCornerPosition(this.baseInnerCornerA);
        this.topInnerCornerB = this.calculateTopCornerPosition(this.baseInnerCornerB);
        this.topOuterCornerA = this.calculateTopCornerPosition(this.baseOuterCornerA);
        this.topOuterCornerB = this.calculateTopCornerPosition(this.baseOuterCornerB);
    }

    calculateTopCornerPosition(baseCorner) {
        return new Vector3(baseCorner.x, Constants.room.dimensions.HEIGHT, baseCorner.z);
    }

    updateVertexPositions() {
        this.vertices = this.meshComponent.mesh.getVerticesData(VertexBuffer.PositionKind);
        this.setWallVertices();
        this.meshComponent.mesh.updateVerticesData(VertexBuffer.PositionKind, this.vertices);
    }

    setWallVertices() {
        this.setLeftFaceVertices();
        this.setRightFaceVertices();
        this.setFrontFaceVertices();
        this.setBackFaceVertices();
        this.setTopFaceVertices();
        this.setBottomFaceVertices();
    }

    setVertexCoordinates(index, vertexPosition) {
        this.vertices[index] = vertexPosition.x;
        this.vertices[index + 1] = vertexPosition.y;
        this.vertices[index + 2] = vertexPosition.z;
    }

    setLeftFaceVertices() {
        this.setVertexCoordinates(this.leftFaceIndices.FIRST_CORNER, this.baseInnerCornerA);
        this.setVertexCoordinates(this.leftFaceIndices.SECOND_CORNER, this.baseOuterCornerA);
        this.setVertexCoordinates(this.leftFaceIndices.THIRD_CORNER, this.topOuterCornerA);
        this.setVertexCoordinates(this.leftFaceIndices.FOURTH_CORNER, this.topInnerCornerA);
    }

    setRightFaceVertices() {
        this.setVertexCoordinates(this.rightFaceIndices.FIRST_CORNER, this.topInnerCornerB);
        this.setVertexCoordinates(this.rightFaceIndices.SECOND_CORNER, this.topOuterCornerB);
        this.setVertexCoordinates(this.rightFaceIndices.THIRD_CORNER, this.baseOuterCornerB);
        this.setVertexCoordinates(this.rightFaceIndices.FOURTH_CORNER, this.baseInnerCornerB);
    }

    setFrontFaceVertices() {
        this.setVertexCoordinates(this.frontFaceIndices.FIRST_CORNER, this.topInnerCornerB);
        this.setVertexCoordinates(this.frontFaceIndices.SECOND_CORNER, this.baseInnerCornerB);
        this.setVertexCoordinates(this.frontFaceIndices.THIRD_CORNER, this.baseInnerCornerA);
        this.setVertexCoordinates(this.frontFaceIndices.FOURTH_CORNER, this.topInnerCornerA);
    }

    setBackFaceVertices() {
        this.setVertexCoordinates(this.backFaceIndices.FIRST_CORNER, this.topOuterCornerA);
        this.setVertexCoordinates(this.backFaceIndices.SECOND_CORNER, this.baseOuterCornerA);
        this.setVertexCoordinates(this.backFaceIndices.THIRD_CORNER, this.baseOuterCornerB);
        this.setVertexCoordinates(this.backFaceIndices.FOURTH_CORNER, this.topOuterCornerB);
    }

    setTopFaceVertices() {
        this.setVertexCoordinates(this.topFaceIndices.FIRST_CORNER, this.topOuterCornerA);
        this.setVertexCoordinates(this.topFaceIndices.SECOND_CORNER, this.topOuterCornerB);
        this.setVertexCoordinates(this.topFaceIndices.THIRD_CORNER, this.topInnerCornerB);
        this.setVertexCoordinates(this.topFaceIndices.FOURTH_CORNER, this.topInnerCornerA);
    }

    setBottomFaceVertices() {
        this.setVertexCoordinates(this.bottomFaceIndices.FIRST_CORNER, this.baseInnerCornerA);
        this.setVertexCoordinates(this.bottomFaceIndices.SECOND_CORNER, this.baseInnerCornerB);
        this.setVertexCoordinates(this.bottomFaceIndices.THIRD_CORNER, this.baseOuterCornerB);
        this.setVertexCoordinates(this.bottomFaceIndices.FOURTH_CORNER, this.baseOuterCornerA);
    }

    setDirection() {
        this.direction = this.baseInnerCornerA.subtract(this.baseInnerCornerB).normalize();
    }

    buildMesh(innerCornerA, innerCornerB, outerCornerA, outerCornerB) {
        this.setBaseCornerPositions(innerCornerA, innerCornerB, outerCornerA, outerCornerB);
        this.setDirection();
        this.calculateTopCornerPositions();

        this.length = Vector3.Distance(innerCornerA, innerCornerB);

        this.meshComponent.mesh = Mesh.CreateBox(this.id, { updatable: true }, this.editor.sceneComponent.get());
        this.meshComponent.mesh.alwaysSelectAsActiveMesh = true;
        this.setWallAngle();

        this.updateVertexPositions();
        this.addMaterial();

        this.meshComponent.mesh.convertToFlatShadedMesh();
        this.meshComponent.setType('wall');

        this.slots.push({
            firstPoint: this.baseInnerCornerA,
            secondPoint: this.baseInnerCornerB,
            length: this.length,
            fixture: null,
        });

        this.meshComponent.getMesh().bakeCurrentTransformIntoVertices();
        this.meshComponent.mesh.isBlocker = false;
        this.setActions();
        this.setInfo('wall');
    }

    setInfo(type) {
        this.setType(type);
        this.setSubtype();
        const collection = store.state.generated.objects[this.type].collections[0];
        this.setColor(collection.color.type, collection.color.items[0]);
        this.setCollection(collection.name);
    }

    getDirection() {
        const direction = this.baseInnerCornerA.subtract(this.baseInnerCornerB);
        return direction.normalize();
    }

    replaceMesh(newMesh) {
        let previousType = this.meshComponent.mesh.type;
        let previousId = this.meshComponent.mesh.id;
        this.meshComponent.mesh.dispose();

        this.meshComponent.mesh = newMesh;
        this.meshComponent.mesh.type = previousType;
        this.meshComponent.mesh.id = previousId;
        this.meshComponent.mesh.convertToFlatShadedMesh();
        this.addMaterial();
        this.setActions();
        this.meshComponent.mesh.isBlocker = false;
    }

    getObjectsOnWall() {
        let componentsOnWall = [];
        for (let index = 0; index < this.editor.fixtures.length; index++) {
            if (this.editor.fixtures[index].assignedWall.id === this.id) {
                componentsOnWall.push(this.editor.fixtures[index]);
            }
        }

        const cabinetsOnWall = this.getCabinetsOnWall();
        componentsOnWall = componentsOnWall.concat(cabinetsOnWall);
        const appliancesOnWall = this.getAppliancesOnWall();
        componentsOnWall = componentsOnWall.concat(appliancesOnWall);
        return componentsOnWall;
    }

    getCabinetsOnWall(allowedTypes = ['wall', 'base', 'tall']) {
        let cabinetsOnWall = [];
        for (let index = 0; index < this.editor.cabinets.length; index++) {
            if (
                this.editor.cabinets[index].type !== Constants.cabinet.type.ISLAND &&
                this.editor.cabinets[index].assignedWall.id === this.id &&
                allowedTypes.includes(this.editor.cabinets[index].type)
            ) {
                cabinetsOnWall.push(this.editor.cabinets[index]);
            }
        }
        return cabinetsOnWall;
    }

    hideCabinets(enable) {
        const cabinetsOnWall = this.getCabinetsOnWall();
        const appliancesOnWall = this.getAppliancesOnWall();

        for (const cabinet of cabinetsOnWall) {
            cabinet.hideSections(enable);
            for (const appliance of appliancesOnWall) {
                if (appliance?.currentCabinet?.type === 'wall' && appliance.type === 'hood' && appliance.subtype === 'builtin') {
                    appliance.hide(false);
                } else {
                    appliance.hide(enable);
                }
            }
        }
    }

    hide(enable) {
        this.meshComponent.getMesh().visibility = enable;
        this.hideCabinets(enable);
    }

    hideAdjacentWalls() {
        const roomObject = this.editor.getObjectByType(RoomObject);
        const adjacentWallsIds = Utilities.getAdjacentWallsIds(this.id, roomObject.walls);
        const leftWall = roomObject.walls[adjacentWallsIds.leftWall];
        const rightWall = roomObject.walls[adjacentWallsIds.rightWall];
        leftWall.hide(false);
        rightWall.hide(false);
    }

    hideAllWallsBesidesThis() {
        const roomObject = this.editor.getObjectByType(RoomObject);
        for (const wall of roomObject.walls) {
            if (wall.id !== this.id) {
                wall.hide(false);
            }
        }
    }

    getAppliancesOnWall() {
        let appliancesOnWall = [];
        for (let index = 0; index < this.editor.appliances.length; index++) {
            if (
                this.editor.appliances[index].type !== Constants.cabinet.type.ISLAND &&
                this.editor.appliances[index].assignedWall?.id === this.id
            ) {
                appliancesOnWall.push(this.editor.appliances[index]);
            }
        }
        return appliancesOnWall;
    }

    onPointerOverTrigger() {
        this.meshComponent.mesh.actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPointerOverTrigger,
                },
                () => {
                    if (store.state.core.step === 4) {
                        const color = Color3.FromHexString(Constants.highlighting.HEX_COLOR);
                        this.toggleBoundingBox(true);
                    }
                    if (this.editor.selectedItem && this.editor.selectedItem.state !== 'none' && !this.editor.resizing) {
                        if (
                            this.meshComponent.getMesh().visibility >= 0.3 &&
                            this.editor.selectedItem.type !== Constants.cabinet.type.ISLAND &&
                            !this.editor.selectedItem.blockingCabinet &&
                            this.editor.selectedItem?.type !== Constants.appliance.type.HOOD
                        ) {
                            this.editor.selectedItem.assignedWall = this;
                        }
                    }
                }
            )
        );
    }

    getCabinetsInReplacementDirection() {
        //The cabinets are first sorted based on the left to right direction then it is decided which direction to keep
        const baseSortedCabinets = this.sortCabinetsInReplacementOrder(['base', 'tall']);
        const wallSortedCabinets = this.sortCabinetsInReplacementOrder(['wall']);
        let reverseOrder = false;

        if (baseSortedCabinets.length > 0) {
            const firstBaseCabinet = baseSortedCabinets[0];
            const lastBaseCabinet =
                baseSortedCabinets.length > 1 ? baseSortedCabinets[baseSortedCabinets.length - 1] : baseSortedCabinets[0];

            const firstCabinetPositionInfo = firstBaseCabinet.getPositionInfo();
            const lastCabinetPositionInfo = lastBaseCabinet?.getPositionInfo();
            if (firstBaseCabinet.secondConnectedCabinet) {
                reverseOrder = false;
            } else if (lastBaseCabinet?.connectedCabinet) {
                reverseOrder = true;
            } else if (firstCabinetPositionInfo?.cornerB) {
                reverseOrder = false;
            } else if (lastCabinetPositionInfo?.cornerA) {
                reverseOrder = true;
            }
        }
        if (reverseOrder) {
            this.replacementDirection = this.getDirection().negate().clone();
            return { base: baseSortedCabinets.reverse(), wall: wallSortedCabinets.reverse() };
        } else {
            this.replacementDirection = this.getDirection().clone();
            return { base: baseSortedCabinets, wall: wallSortedCabinets };
        }
    }

    sortCabinetsInReplacementOrder(types) {
        const appliancesOnCabinet = this.getCabinetsOnWall(types);
        const defaultStartingPoint = this.baseInnerCornerB.clone();
        return appliancesOnCabinet.sort((a, b) => {
            return Vector3.Distance(a.position, defaultStartingPoint) > Vector3.Distance(b.position, defaultStartingPoint)
                ? 1
                : -1;
        });
    }

    getReplacementDirection() {
        return this.replacementDirection;
    }

    addMaterial() {
        let pattern = 'PlasterNaturalPaint';
        let color = '#FFFFFF';
        if (store.state.core.projectData.walls && store.state.core.projectData.walls[this.id]) {
            this.customization = store.state.core.projectData.walls[this.id];
            pattern = store.state.core.projectData.walls[this.id].pattern;
            color = store.state.core.projectData.walls[this.id].value;
        }
        const pbr = this.editor.PBRComponent.getTextureFromPool(
            'combo',
            pattern,
            color,
            Constants.glossines.wall.MICROSURFACE,
            Constants.glossines.wall.CLEAR_COAT,
            ''
        );
        this.meshComponent.getMesh().material = pbr;
    }

    buildMeasurementLinesForPrint() {
        const cabinetsOnWall = this.getCabinetsOnWall();
        for (const cabinet of cabinetsOnWall) {
            cabinet.buildMeasurementLinesForPrint();
        }
    }

    disposeCabinetMeasurementLines() {
        const cabinetsOnWall = this.getCabinetsOnWall();
        for (const cabinet of cabinetsOnWall) {
            cabinet.disposeSectionMeasurementLines();
        }
    }

    toggleCabinetEdgesRendering(enable) {
        const cabinetsOnWall = this.getCabinetsOnWall();
        for (const cabinet of cabinetsOnWall) {
            cabinet.toggleEdgesRendering(enable);
        }
    }

    addFullLengthMeasurementLine() {
        const cornerA = this.baseInnerCornerA.clone();
        const cornerB = this.baseInnerCornerB.clone();

        const topCornerA = cornerA.clone();
        const topCornerB = cornerB.clone();

        topCornerA.y += Constants.room.dimensions.HEIGHT;
        topCornerB.y += Constants.room.dimensions.HEIGHT;

        cornerA.y -= 2 * 0.15;
        cornerB.y -= 2 * 0.15;

        const lineCoordinates = [
            [cornerA, cornerB],
            [cornerA, topCornerA],
            [cornerB, topCornerB],
        ];

        this.measurementLine.build(lineCoordinates, 'bottom');
    }
}
