import store from '@/store/index.js';
import { PointerEventTypes } from '@babylonjs/core';
import { BaseAppliance } from '../KitchenObjects/Appliances/BaseAppliance';
import { BaseUnit } from '../KitchenObjects/Cabinets/BaseUnit';
import { Constants } from '../Tools/constants';

export class MouseActionsHandler {
    constructor(editor) {
        this.editor = editor;
    }

    bindings() {
        const scene = this.editor.sceneComponent.get();
        this.mouseOb = scene.onPointerObservable.add((pointerInfo) => {
            const pick = this.pick();

            if (store.state.cabinets.selectedCabinet) {
                store.state.cabinets.selectedCabinet.handles.show();
                store.state.cabinets.selectedCabinet.highlight();
            }

            switch (pointerInfo.type) {
                case PointerEventTypes.POINTERDOWN: {
                    this.handlePointerDownEvent(pick);

                    break;
                }
                case PointerEventTypes.POINTERUP: {
                    this.handlePointerUpEvent(pick);
                    break;
                }
                case PointerEventTypes.POINTERMOVE: {
                    this.handlePointerMoveEvent(pick);
                    break;
                }
                case PointerEventTypes.POINTERPICK:
                    this.handlePointerPickEvent(pick);
                    break;
            }
        });
    }

    pickingPredicate(mesh) {
        return mesh.visibility !== 0 && !mesh.doNotPick;
    }

    pick() {
        const scene = this.editor.sceneComponent.get();
        const result = scene.pick(scene.pointerX, scene.pointerY, (mesh) => this.pickingPredicate(mesh));

        scene.pointerDownPredicate = (mesh) => this.pickingPredicate(mesh);
        scene.pointerMovePredicate = (mesh) => this.pickingPredicate(mesh);
        scene.pointerPickPredicate = (mesh) => this.pickingPredicate(mesh);

        return result;
    }

    handlePointerDownEvent(pick) {
        if (pick.hit) {
            if (this.editor.selectedItem) {
                this.editor.cameraComponent.disableCameraRotation();
                if (this.editor.selectedItem instanceof BaseUnit) {
                    this.handleNewCabinetSelection(pick);
                    store.commit('cabinets/setSelectedCabinet', this.editor.selectedItem);
                }
            } else {
                this.editor.cameraComponent.enableCameraRotation();
            }
        }
    }

    handlePointerUpEvent(pick) {
        if (pick.hit) {
            if (this.editor.selectedItem) {
                this.editor.cameraComponent.enableCameraRotation();
                if (this.editor.selectedItem instanceof BaseUnit) {
                    if (
                        store.state.cabinets.selectedCabinet &&
                        (!store.state.cabinets.selectedCabinet.secondConnectedCabinet ||
                            !store.state.cabinets.selectedCabinet.connectedCabinet) &&
                        !this.editor.resizing &&
                        this.editor.selectedItem.type !== Constants.cabinet.type.ISLAND
                    ) {
                        this.editor.selectedItem.extendedCabinetManager.checkForExistingCabinetsToConnect();
                        this.editor.selectedItem.extendedCabinetManager.showCornerCabinets();
                    } else {
                        this.handleActionsAfterCabinetResize(pick);
                    }
                    this.editor.sceneComponent.save3D();
                    this.editor.selectedItem.state = 'none';
                    this.editor.selectedItem = null;
                } else if (this.editor.selectedItem instanceof BaseAppliance) {
                    this.editor.selectedItem = null;
                    this.editor.sceneComponent.save3D();
                }
            }
        }
    }

    handleActionsAfterCabinetResize(pick) {
        if (this.editor.resizing) {
            this.editor.resizing = false;
            this.editor.selectedItem.extendedCabinetManager.handleExistingConnections(pick);
            this.editor.selectedItem.pickedHandle = null;
            if (this.editor.selectedItem.type !== Constants.cabinet.type.ISLAND) {
                this.editor.selectedItem.extendedCabinetManager.checkForExistingCabinetsToConnect();

                const previousConnectedCorner = this.editor.selectedItem.connectedCabinet;
                const previousSecondConnectedCorner = this.editor.selectedItem.secondConnectedCabinet;

                if (
                    !store.state.cabinets.selectedCabinet.secondConnectedCabinet ||
                    !store.state.cabinets.selectedCabinet.connectedCabinet
                ) {
                    this.editor.selectedItem.extendedCabinetManager.showCornerCabinets();
                } else {
                    this.editor.selectedItem.connectedCabinet = previousConnectedCorner;
                    this.editor.selectedItem.secondConnectedCabinet = previousSecondConnectedCorner;
                }
            }
            this.resetSectionsInformation(store.state.cabinets.selectedCabinet);
        }
        
        if (this.editor.resizingIsland) {
            this.editor.resizingIsland = false;
            this.resetSectionsInformation(store.state.cabinets.selectedCabinet);
        }
    }

    resetSectionsInformation(selectedCabinet) {
        if (
            (selectedCabinet.type === 'island' && selectedCabinet?.sectionsInformation?.firstRow) ||
            (selectedCabinet.type === 'island' && selectedCabinet?.sectionsInformation?.secondRow)
        ) {
            selectedCabinet.sectionsInformation.firstRow = [];
            selectedCabinet.sectionsInformation.secondRow = [];

        }

        selectedCabinet.sectionsInformation = [];
        if (selectedCabinet.connectedCabinet) {
            selectedCabinet.connectedCabinet.sectionsInformation = [];
        }

        if (selectedCabinet.secondConnectedCabinet) {
            selectedCabinet.secondConnectedCabinet.sectionsInformation = [];
        }
    }

    handlePointerMoveEvent(pick) {
        if (!pick.pickedPoint) {
            return;
        }
        if (this.editor.selectedItem) {
            if (this.editor.selectedItem instanceof BaseUnit) {
                if (this.editor.selectedItem.state !== 'moving') {
                    if (this.editor.resizing) {
                        this.editor.selectedItem.resizingManager.handleCabinetResizing(pick);
                    } else if (this.editor.resizingIsland) {
                        if (
                            this.editor.selectedItem.pickedHandle.name === 'leftHandle' ||
                            this.editor.selectedItem.pickedHandle.name === 'rightHandle'
                        ) {
                            this.editor.selectedItem.resizingManager.handleIslandScaling(pick, 'width');
                        } else {
                            this.editor.selectedItem.resizingManager.handleIslandScaling(pick, 'depth');
                        }
                    }
                } else if (!this.editor.resizing && !this.editor.resizingIsland) {
                    this.editor.selectedItem.movementManager.handleCabinetMovement(pick);
                }
            } else if (this.editor.selectedItem instanceof BaseAppliance) {
                let allowMovementOnWalls = false;
                if (
                    (this.editor.selectedItem.type === 'dishwasher' && this.editor.selectedItem.format === 'freestanding') ||
                    (this.editor.selectedItem.type === 'oven' && this.editor.selectedItem.subtype === 'range') ||
                    this.editor.selectedItem.type === 'hood' ||
                    (this.editor.selectedItem.type === 'refrigerator' && this.editor.selectedItem.format === 'freestanding') ||
                    this.editor.selectedItem.type === 'washer'
                ) {
                    allowMovementOnWalls = true;
                }
                this.editor.selectedItem.movementManager.handleMovement(pick, allowMovementOnWalls);
            }
        }
    }

    handlePointerPickEvent(pick) {
        if (this.editor.selectedItem) {
            if (
                ['Base Cabinet', 'Wall Cabinet', 'Tall Cabinet', 'Island'].includes(pick.pickedMesh.name) &&
                !store.state.appliances.selectedAppliance
            ) {
                store.commit('cabinets/setSelectedCabinet', this.editor.selectedItem);
                this.editor.selectedItem.measurementLines.update();
                this.editor.selectedItem.state = 'none';
            }
        } else if (store.state.cabinets.selectedCabinet) {
            store.state.cabinets.selectedCabinet.deselect();
            store.commit('cabinets/setSelectedCabinet', null);
        } else if (store.state.appliances.selectedAppliance) {
            store.state.appliances.selectedAppliance.deselect();
        } else if (store.state.generated.selectedObject && !this.editor.multiselection && pick.pickedMesh.name === 'ground') {
            const selectedObjects = store.state.generated.selectedObject;
            for (let index = 0; index < selectedObjects.length; index++) {
                store.state.generated.selectedObject[index].deselect();
                index--;
            }
        }
    }

    handleNewCabinetSelection(pick) {
        const selectedCabinet = store.state.cabinets.selectedCabinet;
        if (selectedCabinet && this.editor.selectedItem !== selectedCabinet) {
            if (!selectedCabinet.meshComponent.getMesh().isExtendedCabinet && selectedCabinet !== this.editor.selectedItem) {
                const shouldDisposeCabinet = !(pick.pickedMesh.parent && pick.pickedMesh.parent.isExtendedCabinet);
                selectedCabinet.deselect(shouldDisposeCabinet);
            }
        }
    }
}
