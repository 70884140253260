import { Constants } from '../../../services/editor/3D/Tools/constants';
import config from './config';

export const appliances = {
    namespaced: true,
    state: {
        appliances: config,
        modalAppliance: '',
        selectedApplianceProps: {
            subtype: 0,
            direction: 'left',
            color: 'light',
            format: null,
        },
        selectedAppliance: '',
        //{
        //     color: 'light',
        //     currentUnit: 'mm',
        //     dimensions: {
        //         width: 500,
        //         depth: 300,
        //         height: 900,
        //     },
        //     direction: 'left',
        //     format: 'builtin',
        //     subtype: 'oneBowl',
        //     type: 'sink',
        // },
        openDialog: false,
    },
    mutations: {
        openDialog(state, openDialog) {
            state.openDialog = openDialog;
        },
        setModalAppliance(state, modalAppliance) {
            state.modalAppliance = modalAppliance;
        },
        setModalApplianceProps(state, { subtype, direction, color }) {
            state.selectedApplianceProps.subtype = subtype;
            state.selectedApplianceProps.direction = direction;
            state.selectedApplianceProps.color = color;
        },
        setSelectedAppliance(state, appliance) {
            state.selectedAppliance = appliance;
            if (process.env.NODE_ENV === 'development') {
                //console.log('selected appliance:', appliance);
            }
        },
        addObject(state, { selectedUnit, editorEngine }) {
            const subtype =
                state.modalAppliance.subtype[
                    state.selectedApplianceProps.subtype
                ].id;
            let data = {
                type: state.modalAppliance.id,
                subtype,
                direction: state.selectedApplianceProps.direction,
                color: state.selectedApplianceProps.color,
                format: state.selectedApplianceProps.format,
                dimensions: {},
                currentUnit: selectedUnit,
            };

            let dimensions =
                state.modalAppliance.subtype[
                    state.selectedApplianceProps.subtype
                ].dimensions;

            Object.keys(dimensions).forEach((key) => {
                data.dimensions[key] = dimensions[key].value[data.currentUnit];
            });

            if (process.env.NODE_ENV === 'development') {
                console.log('add appliance data', data);
            }

            const result = editorEngine.checkForFreeCooktops();

            if (
                result.hasFreeCooktops ||
                data.type !== Constants.appliance.type.HOOD
            ) {
                if (editorEngine.hasTallCabinets()) {
                    console.log('hi');
                }
                editorEngine.generateNewAppliance(data);
            }
            // let dimensions =
            //     state.modalAppliance.subtype[state.selectedApplianceProps.subtype].dimensions;

            // Object.keys(dimensions).forEach((key) => {
            //     data.dimensions[key] = dimensions[key].value[data.currentUnit];
            // });

            // editorEngine.floorplan.addFixture(data);
        },
    },
    actions: {
        addObject({ commit, rootGetters }) {
            const selectedUnit = rootGetters['core/selectedUnit'],
                editorEngine = rootGetters['core/editorEngine'];

            commit('addObject', { selectedUnit, editorEngine });
        },
    },
};
