import { CSG, Mesh, MeshBuilder, StandardMaterial, Vector3 } from '@babylonjs/core';
import { Utilities } from '../../Tools/utilities';
import store from '@/store/index.js';
import { BaseUnit } from './BaseUnit';
import { Row } from '../CabinetSections/SectionComponents/Row';
import { Countertop } from '../BuildingBlocks/Countertop';
import { Constants } from '../../Tools/constants';
import { Skirting } from '../BuildingBlocks/Skirting';
import { CornerCabinet } from '../CabinetSections/CornerCabinet';
import { BaseMeasurementLine } from '../../Components/Base/BaseMeasurementLine';
import { RoomObject } from '../Room/RoomObject';

export class Island extends BaseUnit {
    rowDepths = {
        firstRow: 0,
        secondRow: 0,
    };

    firstRow;
    secondRow;

    sections = {
        firstRow: [],
        secondRow: [],
    };

    peninsulaData = {
        isPeninsula: false,
        connectedCabinet: null,
        side: null,
        connectionEdge: null,
        edgeIndex: null,
        innerRow: null,
    };

    constructor() {
        super();
        this.rotation = 0;
        this.firstRow = this.registerComponent(Row);
        this.secondRow = this.registerComponent(Row);
    }

    getEdges(row) {
        const position = this.meshComponent.getMesh().position;
        let leftPoint;
        let rightPoint;

        let otherRowDepth = this.rowDepths.secondRow;
        let directionConstant = 1;
        if (row === 'secondRow') {
            otherRowDepth = this.rowDepths.firstRow;
            directionConstant = -1;
        }
        let totalDepth = this.depth;
        let offset = (totalDepth - otherRowDepth) / 2;

        if (this.rotation === 0 || this.rotation === 180) {
            leftPoint = new Vector3(
                position.x - this.width / 2,
                position.y,
                position.z - (directionConstant * this.depth) / 2 + directionConstant * offset
            );
            rightPoint = new Vector3(
                position.x + this.width / 2,
                position.y,
                position.z - (directionConstant * this.depth) / 2 + directionConstant * offset
            );
        } else {
            leftPoint = new Vector3(
                position.x - (directionConstant * this.depth) / 2 + directionConstant * offset,
                position.y,
                position.z + this.width / 2
            );
            rightPoint = new Vector3(
                position.x - (directionConstant * this.depth) / 2 + directionConstant * offset,
                position.y,
                position.z - this.width / 2
            );
        }
        return {
            leftPoint: leftPoint,
            rightPoint: rightPoint,
        };
    }

    calculateRowDepths(depth) {
        const smallestPossibleRow = 0.3;
        let threshold = this.getOneRowThreshold();
        let firstRowDepth = threshold;
        let secondRowDepth = 0;
        if (depth > 0) {
            if (depth > threshold) {
                secondRowDepth = depth - firstRowDepth;
                if (secondRowDepth < smallestPossibleRow) {
                    firstRowDepth += secondRowDepth;
                    secondRowDepth = 0;
                }
            } else {
                firstRowDepth = depth;
            }
        }
        return { firstRow: firstRowDepth, secondRow: secondRowDepth };
    }

    setRowDepths(firstRowDepth, secondRowDepth) {
        this.rowDepths.firstRow = firstRowDepth;
        this.rowDepths.secondRow = secondRowDepth;
    }

    rotate() {
        this.meshComponent.getMesh().rotation.y += Math.PI / 2;
        this.meshComponent.getMesh().rotation.y = this.meshComponent.getMesh().rotation.y % (Math.PI * 2);
        this.rotation = this.meshComponent.getMesh().rotation.y * (180 / Math.PI);
        this.measurementLines.update();
        this.distanceMeasurements.update();
    }

    disposeSections() {
        for (let index = 0; index < this.sections.firstRow.length; index++) {
            this.sections.firstRow[index].dispose();
        }
        for (let index = 0; index < this.sections.secondRow.length; index++) {
            this.sections.secondRow[index].dispose();
        }
        this.sections.firstRow = [];
        this.sections.secondRow = [];
        if (this.countertop) {
            this.countertop.dispose();
        }
        if (this.skirting) {
            this.skirting.dispose();
        }

        if (this.endPanel) {
            this.endPanel.dispose();
        }
    }

    snapOnCabinetEnd() {
        const cabinets = this.editor.cabinets;
        this.distanceMeasurements.checkForNearestObstacles();
        const obstacleData = this.distanceMeasurements.obstacleData;

        if (this.rotation === 0 || this.rotation === 180) {
            if (obstacleData.right.pickedMesh.type === 'cabinet') {
                const cabinet = Utilities.getElementByMeshId(obstacleData.right.pickedMesh.id, cabinets);
                this.handleSnapRepositioning(cabinet, 'z', 'right');
            } else if (obstacleData.left.pickedMesh.type === 'cabinet') {
                const cabinet = Utilities.getElementByMeshId(obstacleData.left.pickedMesh.id, cabinets);
                this.handleSnapRepositioning(cabinet, 'z', 'left');
            } else {
                this.disconnectPeninsula();
            }
        } else if (this.rotation === 90 || this.rotation === 270) {
            if (obstacleData.top.pickedMesh.type === 'cabinet') {
                const cabinet = Utilities.getElementByMeshId(obstacleData.top.pickedMesh.id, cabinets);
                this.handleSnapRepositioning(cabinet, 'x', 'top');
            } else if (obstacleData.bottom.pickedMesh.type === 'cabinet') {
                const cabinet = Utilities.getElementByMeshId(obstacleData.bottom.pickedMesh.id, cabinets);
                this.handleSnapRepositioning(cabinet, 'x', 'bottom');
            } else {
                this.disconnectPeninsula();
            }
        }
        this.distanceMeasurements.update();
    }

    handleSnapRepositioning(cabinet, axis, side) {
        let displacementDirection = this.getDisplacementDirectionOnSnap(side);
        const obstacleBlockedSegment = cabinet.getEdgesOnWall();
        let projectedIslandPosition = Utilities.projectPointOnWall(this.meshComponent.getMesh().position, cabinet.assignedWall);

        let islandEdge;
        let directionConstant;
        let edgeIndex;
        if (
            Vector3.Distance(obstacleBlockedSegment.firstPoint, projectedIslandPosition) <
                Vector3.Distance(obstacleBlockedSegment.secondPoint, projectedIslandPosition) &&
            !cabinet.connectedCabinet
        ) {
            islandEdge = obstacleBlockedSegment.firstPoint;
            edgeIndex = 'firstPoint';
            directionConstant = -1;
        } else if (
            Vector3.Distance(obstacleBlockedSegment.firstPoint, projectedIslandPosition) >
                Vector3.Distance(obstacleBlockedSegment.secondPoint, projectedIslandPosition) &&
            !cabinet.secondConnectedCabinet
        ) {
            islandEdge = obstacleBlockedSegment.secondPoint;
            edgeIndex = 'secondPoint';
            directionConstant = +1;
        }
        if (obstacleBlockedSegment.firstPoint[axis] < obstacleBlockedSegment.secondPoint[axis]) {
            directionConstant *= -1;
        }
        if (islandEdge && Vector3.Distance(islandEdge, projectedIslandPosition) < this.depth) {
            const repositioning = this.width / 2 + cabinet.depth;
            const connectionEdge = islandEdge.clone();
            islandEdge.addInPlace(displacementDirection.scale(repositioning));
            islandEdge.y = this.height / 2;
            islandEdge[axis] += (this.depth / 2) * directionConstant;
            if (Vector3.Distance(this.meshComponent.getMesh().position, islandEdge) < 0.9) {
                this.meshComponent.getMesh().position = islandEdge;
                this.position = islandEdge;
                this.measurementLines.update();
                this.connectPeninsula(cabinet, side, connectionEdge, edgeIndex);
            } else {
                this.disconnectPeninsula();
            }
        } else {
            this.disconnectPeninsula();
        }
    }

    getDisplacementDirectionOnSnap(side) {
        if (side === 'right') {
            return new Vector3(1, 0, 0);
        } else if (side === 'left') {
            return new Vector3(-1, 0, 0);
        } else if (side === 'top') {
            return new Vector3(0, 0, -1);
        } else if (side === 'bottom') {
            return new Vector3(0, 0, 1);
        }
    }

    //cabinet - the cabinet to which the peninsula connects to
    //side - the side of the island that is connecting
    //connectionEdge - on which edge of the cabinet the peninsula connects (returns Vector3)
    //edgeIndex - index of the connectionEdge (returns 'firstPoint' or 'secondPoint')
    connectPeninsula(cabinet, side, connectionEdge, edgeIndex) {
        this.peninsulaData = {
            isPeninsula: true,
            connectedCabinet: cabinet,
            side: side,
            connectionEdge: connectionEdge,
            edgeIndex: edgeIndex,
            innerRow: 'firstRow',
        };
        cabinet.peninsulaConnection = {
            peninsula: this,
            connectionEdge: connectionEdge,
            edgeIndex: edgeIndex,
        };

        this.setInnerPeninsulaRow();
        //   this.blockedArea.build(this.peninsulaData.connectionEdge, this.peninsulaData.edgeIndex);
        this.peninsulaData.connectedCabinet.blockedArea.build(this.peninsulaData.connectionEdge, this.peninsulaData.edgeIndex);
    }

    disconnectPeninsula() {
        if (this.peninsulaData.connectedCabinet) {
            this.peninsulaData.connectedCabinet.sectionsInformation = [];
            this.peninsulaData.connectedCabinet.blockedArea.dispose('meshForPeninsulaConnection');
            this.peninsulaData.connectedCabinet.peninsulaConnection = {
                peninsula: null,
                connectionEdge: null,
                edgeIndex: null,
            };
            this.peninsulaData = {
                isPeninsula: false,
                connectedCabinet: null,
                side: null,
                connectionEdge: null,
                edgeIndex: null,
                innerRow: null,
            };
        }
    }

    setInnerPeninsulaRow() {
        let firstRowEdges = this.getEdges('firstRow');
        let secondRowEdges = this.getEdges('secondRow');
        if (this.peninsulaData.isPeninsula) {
            let firstRowProjectedPoint = Utilities.projectPointOnWall(
                firstRowEdges.leftPoint,
                this.peninsulaData.connectedCabinet.assignedWall
            );
            let secondRowProjectedPoint = Utilities.projectPointOnWall(
                secondRowEdges.leftPoint,
                this.peninsulaData.connectedCabinet.assignedWall
            );

            if (
                Vector3.Distance(firstRowProjectedPoint, this.peninsulaData.connectionEdge) >
                Vector3.Distance(secondRowProjectedPoint, this.peninsulaData.connectionEdge)
            ) {
                if (this.rowDepths.firstRow < this.rowDepths.secondRow) {
                    this.switchIslandRowDepths();
                    this.peninsulaData.innerRow = 'secondRow';
                } else {
                    this.peninsulaData.innerRow = 'firstRow';
                }
            } else if (
                Vector3.Distance(secondRowProjectedPoint, this.peninsulaData.connectionEdge) >
                Vector3.Distance(firstRowProjectedPoint, this.peninsulaData.connectionEdge)
            ) {
                if (this.rowDepths.secondRow < this.rowDepths.firstRow) {
                    this.switchIslandRowDepths();
                    this.peninsulaData.innerRow = 'firstRow';
                } else {
                    this.peninsulaData.innerRow = 'secondRow';
                }
            }
        }
    }

    switchIslandRowDepths() {
        let temp = this.rowDepths.firstRow;
        this.rowDepths.firstRow = this.rowDepths.secondRow;
        this.rowDepths.secondRow = temp;
    }

    createCabinetClone() {
        const meshClone = this.meshComponent.getMesh().clone('meshClone');
        meshClone.id = this.id + 'meshClone';
        meshClone.isClone = true;
        const cabinetObject = store.state.core.editorEngine.addObject(Island);
        cabinetObject.position = this.position;
        cabinetObject.width = this.width;
        cabinetObject.height = this.height;
        cabinetObject.depth = this.depth;
        cabinetObject.baseWidth = this.baseWidth;
        cabinetObject.meshComponent.mesh = meshClone;
        cabinetObject.isClone = true;
        cabinetObject.assignedWall = this.assignedWall;
        cabinetObject.id = meshClone.id;
        cabinetObject.type = this.type;
        cabinetObject.handles.rightHandle = meshClone.getChildren()[0];
        cabinetObject.handles.leftHandle = meshClone.getChildren()[1];
        cabinetObject.handles.frontHandle = meshClone.getChildren()[2];
        cabinetObject.handles.backHandle = meshClone.getChildren()[3];
        cabinetObject.handles.rightHandle.name = 'rightHandle';
        cabinetObject.handles.leftHandle.name = 'leftHandle';
        cabinetObject.handles.frontHandle = 'frontHandle';
        cabinetObject.handles.backHandle = 'backHandle';

        cabinetObject.rotation = this.rotation;
        if (this.pickedHandle) {
            cabinetObject.pickedHandle = cabinetObject.handles[this.pickedHandle.name];
        }

        return cabinetObject;
    }

    getEdgePoints() {
        const position = this.meshComponent.getMesh().position;
        if (this.rotation === 90 || this.rotation === 270) {
            return {
                topPoint: new Vector3(position.x, position.y, position.z + this.width / 2),
                bottomPoint: new Vector3(position.x, position.y, position.z - this.width / 2),
                leftPoint: new Vector3(position.x + this.depth / 2, position.y, position.z),
                rightPoint: new Vector3(position.x - this.depth / 2, position.y, position.z),
            };
        } else {
            return {
                topPoint: new Vector3(position.x, position.y, position.z - this.depth / 2),
                bottomPoint: new Vector3(position.x, position.y, position.z + this.depth / 2),
                leftPoint: new Vector3(position.x + this.width / 2, position.y, position.z),
                rightPoint: new Vector3(position.x - this.width / 2, position.y, position.z),
            };
        }
    }

    mergeCountertop() {
        const meshes = [];
        for (let index = 0; index < this.sections.firstRow.length; index++) {
            if (this.sections.firstRow[index].type !== Constants.section.type.CORNER_SECTION) {
                meshes.push(this.sections.firstRow[index].buildingBlocks.countertopBlock.meshComponent.getMesh());
            }
        }

        for (let index = 0; index < this.sections.secondRow.length; index++) {
            if (this.sections.secondRow[index].type !== Constants.section.type.CORNER_SECTION) {
                meshes.push(this.sections.secondRow[index].buildingBlocks.countertopBlock.meshComponent.getMesh());
            }
        }

        this.countertop = this.editor.addObject(Countertop);
        let mergedCountertop = Mesh.MergeMeshes(meshes);
        this.countertop.parentCabinet = this;
        this.countertop.meshComponent.setMesh(mergedCountertop);
        mergedCountertop = this.createSinkHole();
        this.countertop.meshComponent.getMesh().type = 'countertop';
        this.countertop.setActions();
        this.countertop.setInfo('countertop');
        this.countertop.subtype = this.type;
        this.countertop.addTexture();
    }

    createSinkHole() {
        const appliancesOnUnit = this.getAppliancesOnRow('firstRow').concat(this.getAppliancesOnRow('secondRow'));

        for (let index = 0; index < appliancesOnUnit.length; index++) {
            const appliance = appliancesOnUnit[index];
            if (appliance.type === 'sink') {
                this.countertop.meshComponent.getMesh().visibility = 0;

                let modelWidth = 0;
                switch (appliance.subtype) {
                    case 'oneBowl':
                        modelWidth = 0.58;
                        break;

                    case 'twoBowls':
                        modelWidth = 0.84;
                        break;

                    case 'oneBowlSide':
                        modelWidth = 0.86;
                        break;
                }

                const subtractionBox = MeshBuilder.CreateBox('subtractionBox', {
                    width: modelWidth,
                    depth: appliance.depth,
                    height: appliance.height,
                });
                subtractionBox.position = appliance.meshComponent.getMesh().position.clone();
                subtractionBox.position.y += 0.01;
                const subtractionMeshCSG = CSG.FromMesh(subtractionBox);
                let counterTopCSG = CSG.FromMesh(this.countertop.meshComponent.getMesh());
                counterTopCSG.copyTransformAttributes(counterTopCSG);

                const result = counterTopCSG.subtract(subtractionMeshCSG);
                this.countertop.meshComponent.mesh = result.toMesh(
                    'countertopBlock',
                    new StandardMaterial('material', this.editor.sceneComponent.get()),
                    this.editor.sceneComponent.get()
                );
                subtractionBox.dispose();
            }
        }

        this.countertop.meshComponent.getMesh().type = 'countertop';
        return this.countertop.meshComponent.getMesh();
    }

    getAppliancesOnRow(row) {
        let appliancesOnCabinet = this.getAppliances();
        appliancesOnCabinet = appliancesOnCabinet.filter((appliance) => appliance.assignedRow === row);
        return appliancesOnCabinet;
    }

    mergeSkirting() {
        const meshes = [];
        for (let index = 0; index < this.sections.firstRow.length; index++) {
            if (this.sections.firstRow[index].type !== Constants.section.type.CORNER_SECTION) {
                meshes.push(this.sections.firstRow[index].buildingBlocks.skirtingBlock.meshComponent.getMesh());
            }
        }

        for (let index = 0; index < this.sections.secondRow.length; index++) {
            if (this.sections.secondRow[index].type !== Constants.section.type.CORNER_SECTION) {
                meshes.push(this.sections.secondRow[index].buildingBlocks.skirtingBlock.meshComponent.getMesh());
            }
        }
        this.skirting = this.editor.addObject(Skirting);
        const mergedSkirting = Mesh.MergeMeshes(meshes);
        mergedSkirting.type = 'skirting';
        this.skirting.meshComponent.setMesh(mergedSkirting);
        this.skirting.parentCabinet = this;

        this.skirting.setActions();
        this.skirting.setInfo('skirting');
        this.skirting.subtype = this.type;
        this.skirting.addTexture();
    }

    addEndCabinet(row, position) {
        if (
            this.sections[row][this.sections[row].length - 1].appliance &&
            ['dishwasher', 'washer'].includes(this.sections[row][this.sections[row].length - 1].appliance.type)
        ) {
            this.endPanel = MeshBuilder.CreateBox(
                'endPanel',
                { width: 0.018, depth: this.rowDepths[row], height: this.height },
                this.editor.sceneComponent.get()
            );
            this.endPanel.position = position;
            this.endPanel.position.y = this.height / 2;
            this.endPanel.rotation.y = this.meshComponent.getMesh().rotation.y;
        }
    }

    toggleVisibility(enable) {
        for (let index = 0; index < this.sections.firstRow.length; index++) {
            this.sections.firstRow[index].meshComponent.getMesh().setEnabled(enable);
            this.sections.firstRow[index].buildingBlocks.doorBlock.meshComponent.getMesh().setEnabled(enable);
            if (enable) {
                this.sections.firstRow[index].measurementLine.update();
            } else {
                this.sections.firstRow[index].measurementLine.dispose();
            }
        }

        for (let index = 0; index < this.sections.secondRow.length; index++) {
            this.sections.secondRow[index].meshComponent.getMesh().setEnabled(enable);
            this.sections.secondRow[index].buildingBlocks.doorBlock.meshComponent.getMesh().setEnabled(enable);
            if (enable) {
                this.sections.secondRow[index].measurementLine.update();
            } else {
                this.sections.secondRow[index].measurementLine.dispose();
            }
        }

        const appliancesOnUnit = this.getAppliancesOnRow('firstRow').concat(this.getAppliancesOnRow('secondRow'));
        for (const appliance of appliancesOnUnit) {
            appliance.hide(enable);
        }

        this.countertop.meshComponent.getMesh().setEnabled(enable);
        this.skirting.meshComponent.getMesh().setEnabled(enable);
    }

    toggleMeasurementLines(enable) {
        this.togglePrintMeasurementLinesForRow('firstRow', enable);
        this.togglePrintMeasurementLinesForRow('secondRow', enable);
    }

    toggleEdgesRendering(row, enable) {
        for (let index = 0; index < this.sections[row].length; index++) {
            this.sections[row][index].buildingBlocks.doorBlock.meshComponent.getMesh().showBoundingBox = enable;
            if (this.sections[row][index].buildingBlocks.innerStructure) {
                this.sections[row][index].buildingBlocks.innerStructure.meshComponent.getMesh().showBoundingBox = enable;
            }
        }
    }

    getCenterAfterReplacement() {
        const firstElement = this.sections.firstRow[0];
        const lastElement = this.sections.firstRow[this.sections.firstRow.length - 1];

        const islandEdges = this.getEdges();
        const direction = islandEdges.leftPoint.clone().subtract(islandEdges.rightPoint.clone()).normalize();

        let firstElementEdge = null;
        let lastElementEdge = null;

        if (
            Vector3.Distance(firstElement.meshComponent.getPosition(), islandEdges.rightPoint) <
            Vector3.Distance(lastElement.meshComponent.getPosition(), islandEdges.rightPoint)
        ) {
            firstElementEdge = firstElement.meshComponent
                .getPosition()
                .clone()
                .add(direction.scale(-firstElement.width / 2));

            lastElementEdge = lastElement.meshComponent
                .getPosition()
                .clone()
                .add(direction.scale(lastElement.width / 2));
        } else {
            direction.negateInPlace();
            firstElementEdge = firstElement.meshComponent
                .getPosition()
                .clone()
                .add(direction.scale(firstElement.width / 2));
            firstElementEdge.y = 0;

            lastElementEdge = lastElement.meshComponent
                .getPosition()
                .clone()
                .add(direction.scale(-lastElement.width / 2));
            lastElementEdge.y = 0;
        }
        const middle = firstElementEdge.clone().add(direction.scale(Vector3.Distance(firstElementEdge, lastElementEdge) / 2));

        middle.y = 0;
        return middle;
    }

    buildMeasurementLinesForPrint() {
        for (let index = 0; index < this.sections.firstRow.length; index++) {
            this.sections.firstRow[index].measurementLine.updateForPrint();
        }

        for (let index = 0; index < this.sections.secondRow.length; index++) {
            this.sections.secondRow[index].measurementLine.updateForPrint();
        }
    }

    togglePrintMeasurementLinesForRow(row, toggle) {
        for (const section of this.sections[row]) {
            if (toggle) {
                section.measurementLine.updateForPrint();
            } else {
                section.measurementLine.dispose();
            }
        }
    }

    toggleMeasurementLinesForRow(row, toggle) {
        for (const section of this.sections[row]) {
            if (toggle) {
                section.measurementLine.update();
            } else {
                section.measurementLine.dispose();
            }
        }
    }

    findClosestRowToPosition(position) {
        const firstRowEdges = this.getEdges('firstRow');
        const secondRowEdges = this.getEdges('secondRow');

        const firstRowDirection = firstRowEdges.leftPoint.clone().subtract(firstRowEdges.rightPoint.clone()).normalize();
        const secondRowDirection = secondRowEdges.leftPoint.clone().subtract(secondRowEdges.rightPoint.clone()).normalize();

        const firstRowMiddle = firstRowEdges.leftPoint
            .clone()
            .subtract(firstRowDirection.scale(Vector3.Distance(firstRowEdges.leftPoint, firstRowEdges.rightPoint) / 2));

        firstRowMiddle.y = 0;

        const secondRowMiddle = secondRowEdges.leftPoint
            .clone()
            .subtract(secondRowDirection.scale(Vector3.Distance(secondRowEdges.leftPoint, secondRowEdges.rightPoint) / 2));

        secondRowMiddle.y = 0;

        if (Vector3.Distance(position, firstRowMiddle) < Vector3.Distance(position, secondRowMiddle)) {
            return 'firstRow';
        }
        return 'secondRow';
    }

    calculateStartingFillerForSymmetry() {
        return 0;
    }

    enablePeninsulaCornerMeasurements() {
        if (this.peninsulaData.connectedCabinet) {
            const roomObject = this.editor.getObjectByType(RoomObject);
            const roomCorners = roomObject.baseOuterCorners.concat(roomObject.baseOuterCorners[0]);

            const wall = this.peninsulaData.connectedCabinet.assignedWall;
            const projectedPoint = Utilities.projectPointOnWall(this.meshComponent.getPosition().clone(), wall).clone();
            let pointA = projectedPoint.add(wall.direction.clone().scale(this.depth / 2));
            let pointB = projectedPoint.add(wall.direction.clone().scale(-this.depth / 2));

            pointA = Utilities.calculateShiftValue(pointA, -0.05, roomCorners, wall);
            pointB = Utilities.calculateShiftValue(pointB, -0.05, roomCorners, wall);

            // pointA.y += this.height;
            // pointB.y += this.height;

            const pointAElevated = pointA.clone();
            const pointBElevated = pointB.clone();

            pointAElevated.y -= Constants.cabinet.measurementLine.SHIFTING;
            pointBElevated.y -= Constants.cabinet.measurementLine.SHIFTING;

            const lineCoordinates = [
                [pointAElevated, pointBElevated],
                [pointA, pointAElevated],
                [pointB, pointBElevated],
            ];

            this.depthMeasurementLine = this.registerComponent(BaseMeasurementLine);
            this.depthMeasurementLine.build(lineCoordinates, 'bottom');
        }
    }
}
