import store from '@/store/index.js';
import { Vector3, ActionManager, ExecuteCodeAction, Matrix, MeshBuilder, Quaternion } from '@babylonjs/core';
import { unitAsBjsUnit, bjsUnitAsSelectedUnit } from '@/services/editor/2D/Tools/Utilities';
import { FloorplanSegmentDiagramWidget, WallsUpdateWidget } from './DiagramWidget';
import { SegmentOrientation } from '../../Tools/SegmentOrientation';

export class FloorplanSegment {
    constructor(a, b, indexA, indexB, parent) {
        this.parent = parent;
        this.indexA = indexA;
        if (indexB == this.parent.corners.length) {
            this.indexB = 0;
        } else {
            this.indexB = indexB;
        }

        this.idx = parent.segments.length;
        this.uID = 'Segment:' + Date.now();
        this.a = a;
        this.b = b;
        let scene = this.parent.parent.scene;
        this.angle = Math.atan2(b.y - a.y, b.x - a.x);
        this.length = Vector3.Distance(a, b);
        this.rotation = new Quaternion.RotationAxis(new Vector3(0, 0, 1), this.angle);
        this.mesh = MeshBuilder.CreatePlane(this.uID, { width: 1, height: this.parent.wallThickness, updatable: true }, scene);
        this.mesh.scaling.x = this.length;
        this.mesh.rotationQuaternion = this.rotation;
        this.mesh.position = this.b
            .add(this.a)
            .scale(0.5)
            .add(this.getUp().scale(this.parent.wallThickness * 0.5));
        this.mesh.material = this.material = this.parent.parent.materials['walls'];
        this.slots = [{ a: 0, b: this.length }];
        this.mesh.metadata = { isSegment: true };
        this.draggingOffset = 0;
        //aOffset if B is overlap
        //bOffset if A is overlap
        this.aOffset = 0;
        this.bOffset = 0;
        this.widget = new FloorplanSegmentDiagramWidget(this);
        this.leftWallUpdateWidget = null;
        this.rightWallUpdateWidget = null;
        this._bindings();
    }

    get scene() {
        return this.parent.parent.scene;
    }

    update() {
        if (this.slots.length == 1) {
            this.slots[0].a = this.bOffset;
            this.slots[0].b = this.length - this.aOffset;
        } else {
            this.slots[0].a = this.draggingOffset;
            this.slots[this.slots.length - 1].b = this.length;
        }
        this.mesh.scaling.x = this.length - this.aOffset - this.bOffset;
        this.mesh.position = this.b
            .add(this.a)
            .scale(0.5)
            .add(this.getUp().scale(this.parent.wallThickness * 0.5))
            .add(this.getLeft().scale((this.bOffset - this.aOffset) * -0.5));

        if (this.widget) {
            this.widget.update();
        }
    }

    _bindings() {
        const scene = this.scene;
        const actionManager = new ActionManager(this.scene);
        this.mesh.actionManager = actionManager;
        const highlightMat = this.getEditorCore().materials['walls-highlight'];
        const normalMat = this.getEditorCore().materials['walls'];
        const controls = this.getEditorCore().controls;

        this.mesh.onBeforeRenderObservable.add(() => {
            if (this.mesh.material === highlightMat && controls.mouse.lastHoverItem) {
                this.mesh.material = normalMat;
            }
            if (this.getEditorCore().controls.mode !== 'none') {
                return;
            }
            const ray = scene.createPickingRay(scene.pointerX, scene.pointerY, Matrix.Identity(), null);
            const pick = scene.pickWithRay(ray);
            if (pick.hit) {
                if (pick.pickedMesh === this.mesh && pick.pickedMesh?.metadata?.isSegment) {
                    this.mesh.material = highlightMat;
                    this.mesh.material._isDirty = true;
                    this.mesh.material = this.parent.parent.materials['walls-highlight'];
                    return;
                } else {
                    this.mesh.material._isDirty = true;
                    this.mesh.material = this.parent.parent.materials['walls'];
                }
            }
            this.mesh.material = normalMat;
        });

        actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPickDownTrigger,
                },
                () => {
                    this.getEditorCore().controls.selectedItem = this;
                    this.getEditorCore().controls.lastMovementPoint = null;
                    this.getEditorCore().controls.mode = 'movingSegment';
                    this.mesh.material = this.parent.parent.materials['walls-highlight'];
                }
            )
        );
    }

    /**
     *   Updates the Segments A point and B Point
     *   @param a: Vector3
     *   @param b: Vector3
     *   @param additive: boolean
     */
    move(a, b, additive = true, updateConnectedEntities = true) {
        const previousLength = Vector3.Distance(this.a, this.b);
        if (additive) {
            this.a.addInPlace(a);
            this.b.addInPlace(b);
        } else {
            this.a = a.clone();
            this.b = b.clone();
        }

        this.parent.corners[this.indexA].position = this.a;
        this.parent.corners[this.indexB].position = this.b;

        this.angle = Math.atan2(b.y - a.y, b.x - a.x);
        this.length = Vector3.Distance(a, b);
        this.draggingOffset = Math.abs(this.length - previousLength);
        this.rotation = new Quaternion.RotationAxis(new Vector3(0, 0, 1), this.angle);
        this.mesh.scaling.x = this.length;
        this.mesh.rotationQuaternion = this.rotation;
        this.mesh.position = this.b
            .add(this.a)
            .scale(0.5)
            .add(this.getUp().scale(this.parent.wallThickness * 0.5));
        this.update();

        this.slots.forEach((slot, i) => {
            if (slot.fixture) {
                const slotData = {
                    assignedSegment: this,
                    closestId: this.idx,
                    slotId: i,
                    value: slot.fixture.lastValue,
                    position: null,
                    isFlipped: slot.isFlipped,
                };
                slot.fixture.setFromSegmentData(slotData);
            }
        });
        const nextSegment = this.getNextSegment();
        const prevSegment = this.getPrevSegment();
        if (updateConnectedEntities) {
            nextSegment.move(this.b, nextSegment.b, additive, false);
            prevSegment.move(prevSegment.a, this.a, additive, false);
        }

        const cornerA = this.getCornerA();
        cornerA.widget.params.positionValue = this.b.clone();
        cornerA.calculateAngle();
        let newCornerValueA = cornerA.calculatedAngle;
        cornerA.widget.linkedValue = () => {
            return newCornerValueA + '°';
        };
        cornerA.widget.update();
        cornerA.mesh.position = this.b.clone();

        const cornerB = this.getCornerB();
        cornerB.widget.params.positionValue = this.a.clone();
        cornerB.calculateAngle();
        let newCornerValueB = cornerB.calculatedAngle;
        cornerB.widget.linkedValue = () => {
            return newCornerValueB + '°';
        };
        cornerB.widget.update();
        cornerB.mesh.position = this.a.clone();

        prevSegment.leftWallUpdateWidget.updateAngle(newCornerValueB);
        prevSegment.leftWallUpdateWidget.updateRightVertexPosition();

        this.rightWallUpdateWidget.updateAngle(newCornerValueB);
        this.rightWallUpdateWidget.updateLeftVertexPosition();

        this.leftWallUpdateWidget.updateAngle(newCornerValueA);
        this.leftWallUpdateWidget.updateRightVertexPosition();

        nextSegment.rightWallUpdateWidget.updateAngle(newCornerValueA);
        nextSegment.rightWallUpdateWidget.updateLeftVertexPosition();
    }

    findSegmentOrientation() {
        if (Math.abs(this.b.x - this.a.x) >= Math.abs(this.b.y - this.a.y)) {
            return SegmentOrientation.HORIZONTAL;
        } else {
            return SegmentOrientation.VERTICAL;
        }
    }

    blockSegmentMovement(check, orientation) {
        if (check) {
            return this.findSegmentOrientation() === orientation;
        }
    }

    getCornerA() {
        return this.parent.cornerCaps.filter((cornerCap) => {
            return cornerCap.segmentA.idx === this.idx;
        })[0];
    }

    getCornerB() {
        return this.parent.cornerCaps.filter((cornerCap) => {
            return cornerCap.segmentB.idx === this.idx;
        })[0];
    }

    getNextSegment() {
        let id = this.idx + 1;
        if (id > this.parent.segments.length - 1) {
            id = 0;
        }
        return this.parent.segments[id];
    }

    getPrevSegment() {
        let id = this.idx - 1;
        if (id < 0) {
            id = this.parent.segments.length - 1;
        }
        return this.parent.segments[id];
    }

    placeInSlot(item, inputData) {
        item.assignedSegment = this;
        item.assignedSlot = inputData.slotId;
        let hw = unitAsBjsUnit(item.dimensions.width) * 0.5;
        let a = inputData.value - hw;
        let b = inputData.value + hw;

        let oSlot = this.slots[inputData.slotId];

        let slot = {
            a,
            b,
            fixture: item,
        };

        let newSlots = [];

        if (oSlot.a != slot.a) {
            newSlots.push({ a: oSlot.a, b: slot.a });
            newSlots.push(slot);
            inputData.assignedSlot++;
        } else {
            newSlots.push(slot);
        }
        if (oSlot.a != slot.b) {
            newSlots.push({ a: slot.b, b: oSlot.b });
        }

        this.slots.splice(inputData.slotId, 1, ...newSlots);

        for (let i = 0; i < this.slots.length; i++) {
            let _item = this.slots[i].fixture;
            if (_item) {
                _item.assignedSlot = i;
            }
        }

        this.widget.update();
        item.selected = false;
        item.updateTextureParts();
        return true;
    }

    grabSlot(id) {
        if (this.slots.length == 2) {
            this.slots = [];
            this.slots.push({ a: this.bOffset, b: this.length - this.aOffset });
        } else if (this.slots.length > 2) {
            let r, l;
            if (id == 0) {
                r = this.slots[id + 1];

                if (r.fixture) {
                    delete this.slots[id].fixture;
                } else {
                    this.slots[id + 1].a = this.slots[id].a;
                    this.slots.splice(id, 1);
                }
            } else if (id == this.slots.length - 1) {
                l = this.slots[id - 1];
                if (l.fixture) {
                    delete this.slots[id].fixture;
                } else {
                    this.slots[id - 1].b = this.slots[id].b;
                    this.slots.splice(id, 1);
                }
            } else {
                r = this.slots[id + 1];
                l = this.slots[id - 1];
                if (r.fixture && l.fixture) {
                    delete this.slots[id].fixture;
                } else if (r.fixture && !l.fixture) {
                    this.slots[id - 1].b = this.slots[id].b;
                    this.slots.splice(id, 1);
                } else if (!r.fixture && l.fixture) {
                    this.slots[id + 1].a = this.slots[id].a;
                    this.slots.splice(id, 1);
                } else {
                    this.slots[id - 1].b = this.slots[id + 1].b;
                    this.slots.splice(id, 2);
                }
            }
        }

        let flag = false;
        for (let i = 0; i < this.slots.length; i++) {
            let f = this.slots[i].fixture;
            if (f) {
                flag = true;
                f.assignedSlot = i;
            }
        }
        //Fallback
        if (!flag) {
            this.slots = [];
            this.slots.push({ a: this.bOffset, b: this.length - this.aOffset });
        }

        this.update();

        return;
    }

    getLeft() {
        let left = Vector3.Zero();
        Vector3.Left().rotateByQuaternionToRef(this.rotation, left);
        return left;
    }

    getUp() {
        let up = Vector3.Zero();
        new Vector3(0, 1, 0).rotateByQuaternionToRef(this.rotation, up);
        return up;
    }

    getCenter() {
        return this.b.add(this.a).scale(0.5);
    }

    getWallUp() {
        return this.getUp().scale(this.parent.wallThickness);
    }

    getRect() {
        let a, b, c, d;
        d = this.a.clone();
        a = d.add(this.getUp().scale(this.parent.wallThickness));
        c = this.b.clone();
        b = c.add(this.getUp().scale(this.parent.wallThickness));
        return [a.x, a.y, b.x, b.y, c.x, c.y, d.x, d.y];
    }

    getTopLine() {
        let rect = this.getRect();
        return [rect[0], rect[1], rect[2], rect[3]];
    }

    getAWithOffset(addHalfWall = false) {
        return this.a.subtract(this.getLeft().scale(this.bOffset)).add(this.getWallUp().scale(addHalfWall ? 0.5 : 0));
    }

    getBWithOffset(addHalfWall = false) {
        return this.b.add(this.getLeft().scale(this.aOffset)).add(this.getWallUp().scale(addHalfWall ? 0.5 : 0));
    }

    getLengthWithOffset() {
        return Vector3.Distance(this.getAWithOffset(), this.getBWithOffset());
    }

    startAsArray() {
        return [this.a.x, this.a.y];
    }

    endAsArray() {
        return [this.b.x, this.b.y];
    }

    toArray() {
        return [...this.startAsArray(), ...this.endAsArray()];
    }

    getCleanAngle() {
        let a = this.angle;
        if (a >= Math.PI) {
            a -= Math.PI;
        } else if (a <= -Math.PI) {
            a += Math.PI;
        }
        return a;
    }

    getDeg() {
        let deg = (this.angle / Math.PI) * 180;
        if (deg > 180) {
            deg -= 180;
        } else if (deg < -180) {
            deg += 180;
        }
        return deg;
    }

    slotToSubSegment(index) {
        let s = this.slots[index];
        let l = this.getLeft();
        let a = this.a.add(l.clone().scale(-s.a));
        let b = this.a.add(l.clone().scale(-s.b));
        return { a: [a.x, a.y], b: [b.x, b.y] };
    }

    valueTo3dPosition(v) {
        return this.a
            .clone()
            .subtract(this.getLeft().scale(v))
            .add(this.getUp().scale(this.parent.wallThickness * 0.5));
    }

    getLengthText() {
        let v = bjsUnitAsSelectedUnit(this.getEditorCore().value2Snap(this.getLengthWithOffset()));
        v = store.getters['core/selectedUnit'] == 'mm' ? Math.round(v) : v.toFixed(2);
        return v;
    }

    getEditorCore() {
        return this.parent.getEditorCore();
    }
}
