import { Vector3, MeshBuilder } from '@babylonjs/core';

export class FloorplanCornerPoint {
    constructor(position = Vector3.Zero(), parent) {
        this.parent = parent;
        this.id = this.parent.corners.length;
        this.position = this.parent.parent.position2Snap(position);
        this.mesh = MeshBuilder.CreatePlane(
            'Corner:' + this.parent.corners.length,
            {
                width: this.parent.parent.controls.cornerPointBoxSize,
                height: this.parent.parent.controls.cornerPointBoxSize,
            },
            this.scene
        );
        this.mesh.isPoint = true;
        this.mesh.renderingGroupId = 2;
        this.mesh.core = this;
        this.mesh.position = this.position;
        this.material = this.parent.parent.materials['corners'];
    }

    setPosition(v) {
        this.position = v;
        if (this.mesh) {
            this.mesh.position = v;
        }
    }

    dispose() {
        this.mesh.dispose();
    }

    set material(v) {
        this.mesh.material = v;
    }

    get material() {
        return this.mesh.material;
    }

    getEditorCore() {
        return this.parent.getEditorCore();
    }
}
