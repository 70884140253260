import store from '@/store/index.js';
import {
    closestPointBetween2D,
    unitAsBjsUnit,
    INCH_STEP32,
    bjsUnit2inch,
    ONE_32,
    reduce,
    mouseInside,
    rectangleFromCenterPoint,
    rectanglesOverlap,
} from './Tools/Utilities';
import { Floorplan } from './Floorplan/Floorplan';
import {
    Engine,
    Vector2,
    Vector3,
    Color3,
    Quaternion,
    Scene,
    Camera,
    FreeCamera,
    HemisphericLight,
    MeshBuilder,
    StandardMaterial,
    DynamicTexture,
} from '@babylonjs/core';
import { GridMaterial } from '@babylonjs/materials';
import { AdvancedDynamicTexture } from '@babylonjs/gui';
import { roundRect } from './Tools';
import { EditorControls } from './Controls/EditorControls';
import { SegmentOrientation } from './Tools/SegmentOrientation';
export class Editor2D {
    constructor(onReady = null) {
        this.canvas = document.getElementById('renderCanvas');
        this.canvas.style.border = 0;
        //this.canvas.parentNode.style.cursor = "crosshair";
        this.engine = new Engine(this.canvas, true);

        this.prepScene(onReady);
        this.floorplan = new Floorplan(this);
        this.controls = new EditorControls(this);
    }

    getEditorCore() {
        return this;
    }

    getBJSEngine() {
        return this.engine;
    }

    prepScene(onReady = null) {
        const scene = new Scene(this.engine);

        if (onReady) {
            scene.onReadyObservable.add(onReady);
        }

        const camera = new FreeCamera('camera1', new Vector3(0, 0, -4.5), scene);
        camera.mode = Camera.ORTHOGRAPHIC_CAMERA;
        camera.minZ = 0.1;

        this.ratio = this.canvas.width / this.canvas.height;

        const light = new HemisphericLight('light', new Vector3(0, 0, -10), scene);
        light.intensity = 1.0;

        const workPlane = MeshBuilder.CreatePlane('gPlane', { width: 100, height: 100 }, scene);
        workPlane.position.z = 0.01;
        workPlane.renderingGroupId = 0;
        workPlane.material = new GridMaterial('groundMaterial', scene);
        workPlane.material.gridRatio = 0.1;
        workPlane.material.mainColor = Color3.White();
        workPlane.material.lineColor = new Color3(0.85, 0.85, 0.85);
        workPlane.material.minorUnitVisibility = 0.125;
        workPlane.material.backFaceCulling = false;
        workPlane.isGround = true;

        this.materials = {
            corners: new StandardMaterial('corners', scene),
            'corners-highlight': new StandardMaterial('corners-highlight', scene),
            walls: new StandardMaterial('walls', scene),
            'walls-highlight': new StandardMaterial('walls-highlight', scene),
        };

        let roundSquareIconDt = new DynamicTexture('roundSquareIconDt', 512, scene);
        let iCtx = roundSquareIconDt.getContext();
        iCtx.fillStyle = 'black';
        roundRect(0, 0, 512, 512, 64, iCtx);
        roundSquareIconDt.update();
        roundSquareIconDt.hasAlpha = true;

        let roundSquareIconDtH = new DynamicTexture('roundSquareIconDtH', 512, scene);
        iCtx = roundSquareIconDtH.getContext();
        iCtx.fillStyle = '#ffff00';
        roundRect(0, 0, 512, 512, 64, iCtx);
        roundSquareIconDtH.update();
        roundSquareIconDtH.hasAlpha = true;

        this.materials['corners'].diffuseTexture = roundSquareIconDt;
        this.materials['corners'].emissiveColor = Color3.Black();
        this.materials['corners'].disableLighting = true;
        this.materials['corners'].backFaceCulling = false;

        this.materials['corners-highlight'].diffuseTexture = roundSquareIconDtH;
        this.materials['corners-highlight'].emissiveColor = Color3.Yellow();
        this.materials['corners-highlight'].disableLighting = true;
        this.materials['corners-highlight'].backFaceCulling = false;

        let wallGradient = new DynamicTexture('wallGradient', 512, scene);
        iCtx = wallGradient.getContext();
        iCtx.fillStyle = 'black';

        let grey = new Color3(0.65, 0.65, 0.65);
        let darkgrey = new Color3(0.45, 0.45, 0.45);

        let gradient = iCtx.createLinearGradient(0, 0, 0, 512);
        gradient.addColorStop(0.0, darkgrey.toHexString());
        gradient.addColorStop(0.2, grey.toHexString());
        gradient.addColorStop(0.8, grey.toHexString());
        gradient.addColorStop(1.0, darkgrey.toHexString());
        iCtx.fillStyle = gradient;
        iCtx.fillRect(0, 0, 512, 512);
        wallGradient.update();
        this.materials['walls'].diffuseTexture = wallGradient;
        this.materials['walls'].emissiveColor = grey;
        this.materials['walls'].disableLighting = true;

        this.materials['walls-highlight'].diffuseTexture = wallGradient;
        this.materials['walls-highlight'].emissiveColor = Color3.Yellow();
        this.materials['walls-highlight'].disableLighting = true;
        this.materials['walls-highlight'].backFaceCulling = false;

        //Widget Stuff...
        this.widgetUI = AdvancedDynamicTexture.CreateFullscreenUI('UI');

        /*scene.debugLayer.show({
            globalRoot: document.getElementById('editor-canvas'),
            handleResize: true,
            embedMode: true
        });*/

        this.scene = scene;
        camera.computeWorldMatrix();
    }

    closePolygon() {
        this.controls.step = 'createPolygon';
        this.controls.mode = 'none';
        this.controls.segmentPreviewLine.mesh?.dispose();
        this.controls.segmentPreviewLine.points = [];

        let floorplan = this.floorplan;
        for (let i = 0; i < floorplan.measurementWidgets.length; i++) {
            floorplan.measurementWidgets[i].disposeMesh(floorplan.measurementWidgets[i].sector);
            floorplan.measurementWidgets[i].disposeMesh(floorplan.measurementWidgets[i].angleTextplaneWidget);
        }

        floorplan.measurementWidgets.forEach((widgetMeasurement) => {
            widgetMeasurement.dispose();
        });
        floorplan.measurementWidgets = [];

        this.controls.selectedItem = null;

        this.floorplan.checkClockRotation();
        this.floorplan.buildWalls();
        this.floorplan.isClosed = true;
        this.controls.step = 'placeFixtures';
        this.controls.mouse.lastHoverItem = null;
    }

    updateSegmentPreviewLine(pick, showLine2Mouse = true) {
        let controls = this.controls;
        let scene = this.scene;
        let floorplan = this.floorplan;
        let points = [];
        let pickedPoint = pick.pickedPoint.clone();

        if (floorplan.corners.length > controls.segmentPreviewLine.points.length - 1) {
            if (controls.segmentPreviewLine.mesh) {
                controls.segmentPreviewLine.mesh.dispose();
                controls.segmentPreviewLine.mesh = null;
            }

            floorplan.corners.forEach((cornerPoint) => {
                points.push(cornerPoint.position);
            });

            if (showLine2Mouse) {
                points.push(pickedPoint);
            }

            controls.segmentPreviewLine.points = points;
        } else {
            controls.segmentPreviewLine.mesh.dispose();
            controls.segmentPreviewLine.mesh = null;

            controls.segmentPreviewLine.points[controls.segmentPreviewLine.points.length - 1] = pickedPoint;
        }

        controls.segmentPreviewLine.mesh = MeshBuilder.CreateLines(
            'segmentPreviewLine',
            { points: controls.segmentPreviewLine.points },
            scene
        );
        controls.segmentPreviewLine.mesh.ignorePick = true;
        controls.segmentPreviewLine.mesh.renderingGroupId = 1;
        controls.segmentPreviewLine.mesh.color = Color3.Black();
    }

    naturalAxesSnaping(a, b, range) {
        if (a.x <= b.x + range && a.x >= b.x - range) {
            a.x = b.x;
        }

        if (a.y <= b.y + range && a.y >= b.y - range) {
            a.y = b.y;
        }

        return a;
    }

    testPointForSnapWhileDragging(point, cornerCapIndex) {
        let hasXChanged = false;
        let hasYChanged = false;
        const margin = 0.05;

        let cornerCap = this.floorplan.corners[cornerCapIndex].position;

        if (!hasXChanged) {
            if (point.x <= cornerCap.x + margin && point.x >= cornerCap.x - margin) {
                point.x = cornerCap.x;
                hasXChanged = true;
            }
        }

        if (!hasYChanged) {
            if (point.y <= cornerCap.y + margin && point.y >= cornerCap.y - margin) {
                point.y = cornerCap.y;
                hasYChanged = true;
            }
        }

        if (hasXChanged || hasYChanged) {
            this.controls.isSnapping = true;
        } else {
            this.controls.isSnapping = false;
        }

        return point;
    }

    testAllPointsForSnap(p) {
        let x = false;
        let y = false;
        for (let i = 0; i < this.floorplan.corners.length; i++) {
            let c = this.floorplan.corners[i].position;
            if (!x) {
                if (p.x <= c.x + this.controls.cornerPointBoxSize && p.x >= c.x - this.controls.cornerPointBoxSize) {
                    p.x = c.x;
                    x = true;
                }
            }

            if (!y) {
                if (p.y <= c.y + this.controls.cornerPointBoxSize && p.y >= c.y - this.controls.cornerPointBoxSize) {
                    p.y = c.y;
                    y = true;
                }
            }

            if (x !== false && y !== false) {
                return p;
            }
        }

        return p;
    }

    calculateAngle(origin, firstPoint, secondPoint) {
        const firstVector = origin.subtract(firstPoint);
        const secondVector = origin.subtract(secondPoint);

        const dot = Vector3.Dot(firstVector, secondVector);
        const angle = Math.acos(dot / (firstVector.length() * secondVector.length()));
        return (angle * 180) / Math.PI;
    }

    checkForInlineCorners(secondLastCorner, lastCorner, newPosition) {
        let flag = false;
        const angleValue = this.calculateAngle(lastCorner, secondLastCorner, newPosition);
        if (angleValue >= 179 && angleValue <= 181) {
            flag = true;
        }

        return flag;
    }

    cleanAllInlineCorners() {
        let floorplan = this.floorplan;
        let controls = this.controls;

        for (let i = 0; i < floorplan.corners.length - 2; i++) {
            let a = floorplan.corners[i].position;
            let b = floorplan.corners[i + 1].position;
            let c = floorplan.corners[i + 2].position;

            if ((a.x == b.x && b.x == c.x) || (a.y == b.y && b.y == c.y)) {
                let d = floorplan.corners[i + 1];
                d.dispose();
                floorplan.corners.splice(i + 1, 1);

                if (controls.segmentPreviewLine.mesh) {
                    controls.segmentPreviewLine.points.splice(i + 1, 1);
                    floorplan.measurementWidgets.splice(i + 1, 1)[0].dispose();

                    floorplan.measurementWidgets[i].setAB(floorplan.corners[i].position, floorplan.corners[i + 1].position);
                }
            }
        }
        floorplan.reIndexCorners();
    }

    checkCornerPointSelect(point) {
        let floorplan = this.floorplan;
        let controls = this.controls;
        let id = -1;

        for (let i = 0; i < floorplan.corners.length; i++) {
            let cornerPosition = floorplan.corners[i].position;
            let sp = this.naturalAxesSnaping(point, cornerPosition, controls.cornerPointBoxSize).multiplyByFloats(1, 1, 0);

            if (cornerPosition.x == sp.x && cornerPosition.y == sp.y) {
                id = i;
                return { point: floorplan.corners[i], id };
            }
        }
        return false;
    }

    placingWallFixture(pick) {
        let controls = this.controls;
        let fixture = controls.selectedItem;
        controls.mode = 'moveFixture';
        controls.mouse.lastSafePosition = pick.pickedPoint;
        controls.mouse.lastSafePosition.z = 0;

        let closestPoint = this.getClosestSegmentData(controls.mouse.lastSafePosition);

        if (closestPoint.closestId === -1) {
            //ERROR NO SLOTS LEFT!
            return;
        }

        let isFlipped = mouseInside(
            [controls.mouse.lastSafePosition.x, controls.mouse.lastSafePosition.y],
            this.floorplan.roomPolygon
        );
        closestPoint.isFlipped = isFlipped;

        fixture.setFromSegmentData(closestPoint);

        let last = false;
        if (!controls.lastSegmentData) {
            this.floorplan.segments[closestPoint.closestId].update();
        } else {
            if (controls.lastSegmentData.closestId != closestPoint.closestId) {
                last = this.floorplan.segments[controls.lastSegmentData.closestId];
            }
        }

        controls.lastSegmentData = closestPoint;
        this.floorplan.segments[closestPoint.closestId].update();

        if (last) {
            last.update();
        }
    }

    placeWallFixture() {
        let fixture = this.controls.selectedItem;
        let controls = this.controls;
        controls.mode = 'none';

        if (controls.lastSegmentData.closestId === -1) {
            //ERROR NO SLOTS LEFT!
            return;
        }

        let segment = this.floorplan.segments[controls.lastSegmentData.closestId];
        segment.placeInSlot(fixture, controls.lastSegmentData);

        controls.lastSegmentData = null;
        segment.update();
        controls.selectedItem = null;
        this.controls.save2D();
    }

    placeFloorFixture() {
        const fixture = this.controls.selectedItem;
        const controls = this.controls;

        const closestPoint = this.getClosestSegmentData(controls.mouse.lastSafePosition.multiplyByFloats(1, 1, 0), true);
        const segment = this.floorplan.segments[closestPoint.closestId];
        const root = fixture.getRoot();
        const point = segment
            .valueTo3dPosition(closestPoint.value)
            .subtract(segment.getUp().scale(this.floorplan.wallThickness * 0.5));

        if (controls.mouse.lastSafePosition) {
            fixture.positionFor3D = this.position2Snap(controls.mouse.lastSafePosition);
            fixture.setPosition(this.position2Snap(controls.mouse.lastSafePosition));
            const distance = Vector3.Distance(point, root.position);
            let fixtureDimension = fixture.dimensions.depth;
            if (segment.findSegmentOrientation() === SegmentOrientation.VERTICAL) {
                fixtureDimension = fixture.dimensions.width;
            }
            const uDimensions = unitAsBjsUnit(fixtureDimension) * 0.5;
            if (distance <= uDimensions) {
                fixture.positionFor3D = this.position2Snap(
                    controls.mouse.lastSafePosition.subtract(segment.getUp().scale(-distance))
                );
                fixture.setPosition(
                    this.position2Snap(controls.mouse.lastSafePosition.subtract(segment.getUp().scale(uDimensions - distance)))
                );
                //fixture.setRotation(segment.mesh.rotationQuaternion);
            } else {
                // fixture.setRotation(Quaternion.Identity());
            }
        }

        fixture.selected = false;
        fixture.updateTextureParts();

        controls.lastSegmentData = null;
        controls.selectedItem = null;
        this.controls.save2D();
    }

    placingFloorFixture(pick) {
        let controls = this.controls;
        let fixture = controls.selectedItem;
        controls.mode = 'moveFixture';

        let isInside = mouseInside([pick.pickedPoint.x, pick.pickedPoint.y], this.floorplan.roomPolygon);
        let closestPoint = this.getClosestSegmentData(pick.pickedPoint.clone().multiplyByFloats(1, 1, 0), true);
        let segment = this.floorplan.segments[closestPoint.closestId];

        if (fixture.type !== 'fixedColumn') {
            fixture.rotation = segment.angle;
        }

        let point = segment
            .valueTo3dPosition(closestPoint.value)
            .subtract(segment.getUp().scale(this.floorplan.wallThickness * 0.5));

        let hitFixture = false;

        let root = fixture.getRoot();

        let rootPosition = root.position.clone();
        root.position = pick.pickedPoint.clone();

        for (let i = 0; i < this.floorplan.fixtures.length; i++) {
            let sMesh = this.floorplan.fixtures[i].getRoot();

            if (sMesh == root || this.floorplan.fixtures[i].type !== 'fixedColumn') {
                continue;
            }

            const firstRectangle = rectangleFromCenterPoint(
                unitAsBjsUnit(fixture.dimensions.width),
                unitAsBjsUnit(fixture.dimensions.depth),
                root.position
            );
            const secondRectangle = rectangleFromCenterPoint(
                unitAsBjsUnit(this.floorplan.fixtures[i].dimensions.width),
                unitAsBjsUnit(this.floorplan.fixtures[i].dimensions.depth),
                sMesh.position
            );

            if (rectanglesOverlap(firstRectangle[0], firstRectangle[1], secondRectangle[0], secondRectangle[1])) {
                hitFixture = true;
                break;
            } else {
                hitFixture = false;
            }
        }

        root.position = rootPosition;

        if (!isInside) {
            controls.mouse.lastSafePosition = point;
        } else if (!hitFixture) {
            controls.mouse.lastSafePosition = pick.pickedPoint;
        } else {
            controls.mouse.lastSafePosition = rootPosition;
        }
        controls.mouse.lastSafePosition.z = 0;
        if (controls.mouse.lastSafePosition) {
            fixture.positionFor3D = this.position2Snap(controls.mouse.lastSafePosition);
            fixture.setPosition(this.position2Snap(controls.mouse.lastSafePosition));
            let distance = Vector3.Distance(point, root.position);
            let uD = unitAsBjsUnit(fixture.dimensions.depth) * 0.5;

            // if (distance <= uD) {
            //     fixture.positionFor3D = this.position2Snap(
            //         controls.mouse.lastSafePosition.subtract(segment.getUp().scale(-distance))
            //     );
            //     fixture.setPosition(
            //         this.position2Snap(controls.mouse.lastSafePosition.subtract(segment.getUp().scale(uD - distance)))
            //     );
            //     fixture.setRotation(segment.mesh.rotationQuaternion);
            // } else {
            //     fixture.setRotation(Quaternion.Identity());
            // }
        }
    }

    drawingWallsMouseMove(pick) {
        let controls = this.controls;
        let floorplan = this.floorplan;
        let inputLength = unitAsBjsUnit(Number(store.state.fixtures.typedWallDim));

        switch (controls.mode) {
            case 'none':
                //Hover Highlight Controls
                if (pick.pickedMesh !== controls.mouse.lastHoverItem && pick.pickedMesh.isPoint) {
                    pick.pickedMesh.material = this.materials['corners-highlight'];
                    controls.mouse.lastHoverItem = pick.pickedMesh;
                    //Hide Last widget
                    floorplan.measurementWidgets[floorplan.measurementWidgets.length - 1].setEnabled(false);
                }

                if (controls.mouse.lastHoverItem && pick.pickedMesh.isGround) {
                    controls.mouse.lastHoverItem.material = this.materials['corners'];
                    controls.mouse.lastHoverItem = null;
                    //UnHide Last widget
                    floorplan.measurementWidgets[floorplan.measurementWidgets.length - 1].setEnabled(true);
                }

                //If one corner is already placed
                if (controls.segmentPreviewLine.points.length > 1) {
                    pick.pickedPoint = this.testAllPointsForSnap(pick.pickedPoint.clone()).multiplyByFloats(1, 1, 0);

                    //Point Snapping
                    if (inputLength > 0) {
                        //Distance Snapping
                        pick.pickedPoint = this.getFixedLengthPosition(
                            pick.pickedPoint,
                            floorplan.getLastCorner().position,
                            inputLength
                        );
                    }
                }

                break;

            case 'dragPoint':
                //One Point
                if (floorplan.corners.length == 1) {
                    controls.segmentPreviewLine.points.splice(0, 1);
                    pick.pickedPoint = this.position2Snap(pick.pickedPoint.clone()).multiplyByFloats(1, 1, 0);
                    controls.selectedItem.setPosition(pick.pickedPoint);

                    return this.updateSegmentPreviewLine(pick);
                    //Two Points
                } else if (floorplan.corners.length == 2) {
                    let snapPosition;
                    //First Point
                    if (controls.selectedItem.id == 0) {
                        snapPosition = this.naturalAxesSnaping(
                            pick.pickedPoint.clone(),
                            floorplan.corners[1].position.clone(),
                            controls.cornerPointBoxSize
                        );

                        controls.segmentPreviewLine.points.splice(0, 1);

                        if (inputLength > 0) {
                            //Distance Snapping
                            pick.pickedPoint = this.getFixedLengthPosition(
                                snapPosition,
                                floorplan.getLastCorner().position,
                                inputLength
                            );
                        }

                        //Second Point
                    } else {
                        snapPosition = this.naturalAxesSnaping(
                            pick.pickedPoint.clone(),
                            floorplan.corners[0].position.clone(),
                            controls.cornerPointBoxSize
                        );

                        controls.segmentPreviewLine.points.splice(controls.segmentPreviewLine.points.length - 2, 1);

                        if (inputLength > 0) {
                            //Distance Snapping
                            pick.pickedPoint = this.getFixedLengthPosition(
                                snapPosition,
                                floorplan.getLastCorner(2).position,
                                inputLength
                            );
                        }
                    }

                    controls.selectedItem.setPosition(pick.pickedPoint.multiplyByFloats(1, 1, 0));

                    //Three or More
                } else {
                    //First Point
                    pick.pickedPoint = this.testAllPointsForSnap(pick.pickedPoint).multiplyByFloats(1, 1, 0);

                    if (controls.selectedItem.id == 0) {
                        controls.segmentPreviewLine.points.splice(0, 1);

                        if (inputLength > 0) {
                            //Distance Snapping
                            pick.pickedPoint = this.getFixedLengthPosition(
                                pick.pickedPoint,
                                floorplan.corners[1].position,
                                inputLength
                            );
                        }
                    } else {
                        controls.segmentPreviewLine.points.splice(controls.segmentPreviewLine.length - 2, 1);
                    }

                    controls.selectedItem.setPosition(pick.pickedPoint);
                }
                break;
        }
        //Update Measurement Widgets
        if (controls.mode != 'dragPoint') {
            floorplan.measurementWidgets[floorplan.measurementWidgets.length - 1].setAB(
                floorplan.getLastCorner().position,
                pick.pickedPoint.multiplyByFloats(1, 1, 0)
            );
        } else {
            if (floorplan.measurementWidgets[controls.selectedItem.id - 1]) {
                floorplan.measurementWidgets[controls.selectedItem.id - 1].setAB(
                    floorplan.corners[controls.selectedItem.id - 1].position,
                    this.position2Snap(pick.pickedPoint).multiplyByFloats(1, 1, 0)
                );
            }
            if (floorplan.corners[controls.selectedItem.id + 1]) {
                floorplan.measurementWidgets[controls.selectedItem.id].setAB(
                    floorplan.corners[controls.selectedItem.id + 1].position,
                    this.position2Snap(pick.pickedPoint).multiplyByFloats(1, 1, 0)
                );
            }
        }
        this.updateSegmentPreviewLine(pick, false);
    }

    getFixedLengthPosition(a, b, d) {
        let lengthPosition = new Vector2(b.x, b.y);
        let n = new Vector2(a.x, a.y).subtract(lengthPosition).normalize();
        return new Vector3(lengthPosition.x, lengthPosition.y, lengthPosition.z).add(new Vector3(n.x, n.y, 0).scale(d));
    }

    getUnitText() {
        return store.getters['core/selectedUnit'] == 'mm' ? 'mm' : '"';
    }

    position2Snap(point) {
        let unit = store.getters['core/selectedUnit'];
        if (unit == 'mm') {
            point.x = +point.x.toFixed(3);
            point.y = +point.y.toFixed(3);
        } else {
            point.x = +point.x.toFixed(9);
            point.y = +point.y.toFixed(9);
        }
        return point;
    }

    value2Snap(value) {
        let unit = store.getters['core/selectedUnit'];
        if (unit == 'mm') {
            value = value.toFixed(3);
        } else {
            value = value.toFixed(9);
        }
        return value;
    }

    getClosestSegmentData(point, ignoreFixture = false) {
        let closestId = -1;
        let slotId = 0;
        let distance = Number.POSITIVE_INFINITY;
        let value = null;
        let position = null;
        let segments = this.floorplan.segments;
        let controls = this.controls;
        let width = unitAsBjsUnit(controls.selectedItem.dimensions.width);

        let _p = [point.x, point.y];

        for (let i = 0; i < segments.length; i++) {
            for (let j = 0; j < segments[i].slots.length; j++) {
                if ((segments[i].slots[j].fixture && !ignoreFixture) || segments[i].slots[j].b - segments[i].slots[j].a < width) {
                    continue;
                }

                let subSegment = segments[i].slotToSubSegment(j);
                let sp = closestPointBetween2D(_p, subSegment.a, subSegment.b);
                //console.log(ss, sp)
                let d = Vector3.Distance(point, new Vector3(sp[0], sp[1], 0));

                if (d < distance) {
                    distance = d;
                    closestId = i;
                    slotId = j;
                    position = new Vector3(sp[0], sp[1], 0);
                    value = Vector3.Distance(segments[i].a, position);
                }
            }
        }

        let hs = width * 0.5;
        let lo = segments[closestId].slots[slotId].a - (value - hs);
        let ro = segments[closestId].slots[slotId].b - (value + hs);

        if (lo > 0) {
            value += lo;
        }

        if (ro < 0) {
            value += ro;
        }
        //console.log(lo, ro);
        return { closestId, slotId, value, position };
    }

    inchFractionText(v) {
        v = bjsUnit2inch(v);
        let pv = Math.floor(v);

        const delta = 0.01;

        let pvf = v - pv;

        if (Math.abs(pvf) < delta) {
            pvf = 0;
        }

        if (pvf === 0) {
            return pv + '';
        }

        let f = reduce(pvf, 32);
        f[0] = Math.floor(pvf * 32);
        f[1] = 32;

        if (f[0] < delta) {
            return pv + '';
        }

        return pv + ' ' + f[0] + '/' + f[1];
    }

    dispose() {
        this.engine.stopRenderLoop();
        this.engine.clear(Color3.Black(), false, false);
        this.scene.dispose();
        this.engine.dispose();
        delete this.scene;
        delete this.engine;
    }

    clearScene() {
        this.controls.step = 'initialCorner';

        let floorplan = this.floorplan;
        floorplan.segments.forEach((segment) => {
            segment.widget.lines.dispose();
            segment.widget.numberWidgets.forEach((widget) => {
                widget.parts.rect.dispose();
                widget.parts.textBlock.dispose();
            });
            segment.mesh.dispose();
        });

        floorplan.cornerCaps.forEach((cornerCap) => {
            cornerCap.widget.parts.rect.dispose();
            cornerCap.widget.parts.textBlock.dispose();
            cornerCap.mesh.dispose();
        });

        floorplan.measurementWidgets.forEach((measurementWidget) => {
            measurementWidget.parts.rect.dispose();
            measurementWidget.parts.textBlock.dispose();

            if (measurementWidget.sector) {
                measurementWidget.sector.dispose();
            }

            if (measurementWidget.angleTextplaneWidget) {
                measurementWidget.angleTextplaneWidget.parts.textBlock.dispose();
                measurementWidget.angleTextplaneWidget.parts.rect.dispose();
            }
        });

        floorplan.fixtures.forEach((fixture) => fixture.purge(true));
        floorplan.corners.forEach((corner) => corner.mesh.dispose());
        if (store.state.core.projectData) {
            store.state.core.projectData.cabinets = [];
            store.state.core.projectData.appliances = [];
        }
        this.controls.segmentPreviewLine.mesh?.dispose();
        this.controls.segmentPreviewLine.points = [];

        floorplan.segments = [];
        floorplan.corners = [];
        floorplan.fixtures = [];
        floorplan.cornerCaps = [];
        this.controls.showCursorBox = true;
        this.controls.cursorBox.dispose();
        this.controls.createCursorBox(this);

        store.commit('core/setResetProject', false);
        floorplan.isClosed = false;
        this.controls.save2D();
    }
}
