import { Constants } from '../../Tools/constants';
import { Section } from './Section';

export class CornerCabinet extends Section {
    cornerIndex;
    fillerSize;
    connectionWidth;
    constructor() {
        super();
        this.type = Constants.section.type.CORNER_SECTION;
    }

    dispose() {
        this.meshComponent.getMesh().dispose();
        this.measurementLine.dispose();
    }

    setBuildingBlocks(buildingBlocks) {
        this.buildingBlocks = buildingBlocks;
    }
}
