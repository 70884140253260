import { ActionManager, ExecuteCodeAction, Vector3 } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { BaseKitchenObject } from '../BaseKitchenObject';
import { BlockedCornerArea } from './CabinetComponents/BlockedCornerArea';
import { DistanceMeasurements } from './CabinetComponents/DistanceMeasurements';
import { ExtendedCabinetManager } from './CabinetComponents/ExtendedCabinetManager';
import { HandleComponent } from './CabinetComponents/HandleComponent';
import { MeasurementLine } from './CabinetComponents/MeasurementLine';
import { MovementManager } from './CabinetComponents/MovementManager';
import { ObstacleManager } from './CabinetComponents/ObstacleManager';
import { ResizingManager } from './CabinetComponents/ResizingManager';
import { Utilities } from '../../Tools/utilities';
import { BaseAppliance } from '../Appliances/BaseAppliance';
import store from '@/store/index.js';

export class LayoutUnit extends BaseKitchenObject {
    heightFromFloor;
    state;

    dimensions = {
        width: null,
        height: null,
        depth: null,
    };

    baseWidth;
    baseHeight;
    baseDepth;
    baseHeightFromFloor;

    rotation;

    isInCorner;
    connectedCabinet;
    secondConnectedCabinet;

    handles;
    pickedHandle;
    assignedWall;

    blockingCabinet;
    cornerCabinetInfo;

    sections = [];
    sectionsInformation = [];

    countertop;
    countertopCustomization = {};

    skirting;
    skirtingCustomization = {};

    isSectioned;

    baseCabinetUnderneath;
    wallCabinetOnTop;

    fillerData = {
        areDynamic: false,
        startingFillerSize: 0,
        endingFillerSize: 0,
    };

    filledWidth = 0;

    replacementData = {
        direction: null,
        startingPoint: null,
    };

    constructor() {
        super();
        this.movementManager = this.registerComponent(MovementManager);
        this.obstacleManager = this.registerComponent(ObstacleManager);
        this.resizingManager = this.registerComponent(ResizingManager);
        this.blockedArea = this.registerComponent(BlockedCornerArea);
        this.handles = this.registerComponent(HandleComponent);
        this.extendedCabinetManager = this.registerComponent(ExtendedCabinetManager);
        this.measurementLines = this.registerComponent(MeasurementLine);
        this.distanceMeasurements = this.registerComponent(DistanceMeasurements);
    }

    setActions() {
        this.meshComponent.mesh.actionManager = new ActionManager(this.editor.sceneComponent.get());
        this.meshComponent.mesh.actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPointerOverTrigger,
                },
                () => {
                    this.highlight();
                    if (this.editor.selectedItem instanceof BaseAppliance) {
                        this.editor.selectedItem.assignedWall = this.assignedWall;
                    }
                }
            )
        );

        this.meshComponent.mesh.actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPointerOutTrigger,
                },
                () => {
                    this.unhighlight();
                    this.meshComponent.isHighlighted = false;
                }
            )
        );

        this.meshComponent.mesh.actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPickDownTrigger,
                },
                () => {
                    if (!this.editor.resizing && !this.editor.resizingIsland) {
                        this.state = 'moving';
                        this.select();
                        this.editor.selectedItem = this;
                    }
                }
            )
        );
    }

    select() {
        if (this.meshComponent.getMesh().isExtendedCabinet) {
            this.meshComponent.getMesh().isExtendedCabinet = false;
        }
        if (store.state.appliances.selectedAppliance) {
            store.state.appliances.selectedAppliance.deselect();
        }
        this.measurementLines.update();
        if (this.type === Constants.cabinet.type.ISLAND) {
            this.distanceMeasurements.update();
        }
        this.handles.show(true);
        this.highlight();
    }

    deselect(disposeExtendedCabinet = true) {
        this.handles.show(false);
        this.isHighlighted = false;
        this.unhighlight();
        if (this.getComponentByType(MeasurementLine)) {
            this.measurementLines.dispose();
        }

        if (this.getComponentByType(DistanceMeasurements)) {
            this.distanceMeasurements.dispose();
        }
        if (this.connectedCabinet) {
            if (this.connectedCabinet.meshComponent.getMesh().isExtendedCabinet && disposeExtendedCabinet) {
                this.connectedCabinet.dispose();
                this.connectedCabinet = null;
                this.blockedArea.dispose('meshForCornerA');
            }
        }

        if (this.secondConnectedCabinet) {
            if (this.secondConnectedCabinet.meshComponent.getMesh().isExtendedCabinet && disposeExtendedCabinet) {
                this.secondConnectedCabinet.dispose();
                this.secondConnectedCabinet = null;
                this.blockedArea.dispose('meshForCornerB');
            }
        }

        this.state = 'none';
        this.editor.sceneComponent.save3D();
    }

    dispose() {
        if (this.connectedCabinet) {
            this.connectedCabinet.sectionsInformation = [];
        }

        if (this.secondConnectedCabinet) {
            this.secondConnectedCabinet.sectionsInformation = [];
        }

        this.meshComponent.getMesh().dispose();
        if (this.getComponentByType(MeasurementLine)) {
            this.measurementLines.dispose();
        }
        if (this.getComponentByType(DistanceMeasurements)) {
            this.distanceMeasurements.dispose();
        }
        this.blockedArea.dispose();
        if (this.meshComponent.getMesh().visibility === Constants.cabinet.transparency.FULLY_VISIBLE && !this.isClone) {
            this.extendedCabinetManager.disconnectExtendedCabinet('connectedCabinet');
            this.extendedCabinetManager.disconnectExtendedCabinet('secondConnectedCabinet');
            store.commit('cabinets/setSelectedCabinet', null);
        }

        if (!this.isClone) {
            this.editor.cabinets.splice(Utilities.getIndexInArray(this, this.editor.cabinets), 1);
        }
        if (this.type === Constants.cabinet.type.ISLAND) {
            this.disconnectPeninsula();
        } else {
            if (this.peninsulaConnection.peninsula) {
                this.peninsulaConnection.peninsula.disconnectPeninsula();
            }
        }
        if (this.editor.step !== 4) {
            this.editor.sceneComponent.save3D();
        }
    }

    disposeSections() {
        for (let index = 0; index < this.sections.length; index++) {
            this.sections[index].dispose();
        }
        if (this.countertop) {
            this.countertop.dispose();
        }
        if (this.skirting) {
            this.skirting.dispose();
        }

        if (this.endPanel) {
            this.endPanel.dispose();
        }
        this.sections = [];
    }

    getAppliances() {
        const appliances = [];
        for (let index = 0; index < this.editor.appliances.length; index++) {
            if (this.editor.appliances[index].currentCabinet?.id === this.id) {
                appliances.push(this.editor.appliances[index]);
            }
        }
        return appliances;
    }

    getEdgesAfterReplacement() {
        if (this.sections.length !== 0) {
            const edges = this.getEdgesOnWall();

            let firstSectionEdges = this.sections[0].getEdgesOnWall();
            if (this.sections[0].type === Constants.section.type.CORNER_SECTION) {
                firstSectionEdges = this.sections[1].getEdgesOnWall();
            }

            let lastSectionEdges = this.sections[this.sections.length - 1].getEdgesOnWall();
            let secondPoint = firstSectionEdges.secondPoint;
            let firstPoint = lastSectionEdges.firstPoint;
            if (
                Vector3.Distance(edges.firstPoint, this.sections[0].position) <
                Vector3.Distance(edges.secondPoint, this.sections[0].position)
            ) {
                firstPoint = firstSectionEdges.firstPoint;
                secondPoint = lastSectionEdges.secondPoint;
            }

            return {
                firstPoint,
                secondPoint,
            };
        }
    }

    shouldAdaptToPreviousCabinet(obstacle) {
        if (obstacle) {
            const distanceFromObstacle =
                Vector3.Distance(this.meshComponent.getPosition(), obstacle.meshComponent.getPosition()) -
                this.width / 2 -
                obstacle.width / 2;
            const MARGIN = 0.3;

            if (distanceFromObstacle < MARGIN && obstacle.type !== Constants.appliance.type.HOOD) {
                return true;
            }
        }
    }

    highlight() {
        this.meshComponent.getMesh().visibility = 0.4;
    }

    unhighlight() {
        this.meshComponent.getMesh().visibility = 0.1;
    }

    hideSections(enable) {
        for (let index = 0; index < this.sections.length; index++) {
            this.sections[index].meshComponent.getMesh().setEnabled(enable);
            this.sections[index].buildingBlocks.doorBlock.meshComponent.getMesh().setEnabled(enable);
            if (enable) {
                this.sections[index].measurementLine.update();
            } else {
                this.sections[index].measurementLine.dispose();
            }
        }
        if (this.countertop && this.skirting) {
            this.countertop.meshComponent.getMesh().setEnabled(enable);
            this.skirting.meshComponent.getMesh().setEnabled(enable);
        } else if (this.skirting) {
            this.skirting.meshComponent.getMesh().setEnabled(enable);
        }
    }

    toggleEdgesRendering(enable) {
        for (let index = 0; index < this.sections.length; index++) {
            this.sections[index].showBoundingBox(enable);
        }
    }

    buildMeasurementLinesForPrint() {
        for (let index = 0; index < this.sections.length; index++) {
            this.sections[index].measurementLine.updateForPrint();
        }
    }

    disposeSectionMeasurementLines() {
        for (let index = 0; index < this.sections.length; index++) {
            this.sections[index].measurementLine.dispose();
        }
    }
}
