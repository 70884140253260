<template>
    <canvas id="renderCanvas" touch-action="none"></canvas>
</template>

<script>
import { mapGetters } from 'vuex';
import { render } from '@/services/editor/render';
import { render3D } from '@/services/editor/3D/render3D';
import { Editor3D } from '@/services/editor/3D/Editor3D/Editor3D';
import { Editor2D } from '@/services/editor/2D/Editor2D';

export default {
    methods: {
        init() {
            render();
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters('core', ['editorEngine', 'step', 'projectData']),
    },
};
</script>

<style scoped>
#renderCanvas {
    height: 100%;
    width: 100%;
    touch-action: none;
}
</style>
