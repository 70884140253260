<template>
    <v-stepper-content step="2" class="pa-2">
        <!-- Add Cabinet Buttons -->
        <v-row v-if="!selectedCabinet && !selectedAppliance" class="pa-2">
            <v-col cols="8" md="9">
                <v-row class="blue-grey lighten-4 rounded-lg">
                    <v-slide-group show-arrows class="mx-auto">
                        <v-slide-item
                            v-for="(cabinetType, key) in cabinets.type"
                            :key="key"
                        >
                            <v-hover v-slot:default="{ hover }">
                                <v-card
                                    :elevation="hover ? 16 : 2"
                                    class="pa-1 ma-2"
                                    :width="
                                        $vuetify.breakpoint.smAndUp ? 150 : 70
                                    "
                                    @click="addObj(cabinetType.id)"
                                >
                                    <v-card-text
                                        class="
                                            py-2
                                            text-caption
                                            text-center
                                            text-subtitle-2
                                        "
                                    >
                                        <v-img
                                            :src="cabinetType.img"
                                            height="60"
                                            contain
                                            class="pa-2"
                                        ></v-img>
                                    </v-card-text>

                                    <v-card-subtitle
                                        class="text-center py-1"
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        >{{ cabinetType.name }}</v-card-subtitle
                                    >
                                </v-card>
                            </v-hover>
                        </v-slide-item>
                    </v-slide-group>
                </v-row>
            </v-col>

            <StepsButtons></StepsButtons>
        </v-row>

        <!-- Edit Cabinet -->
        <v-container v-else-if="selectedCabinet">
            <CabinetEdit></CabinetEdit>
        </v-container>

        <!-- Edit Cabinet -->
        <v-container fluid v-else-if="selectedAppliance" class="pa-0">
            <ApplianceEdit></ApplianceEdit>
        </v-container>
    </v-stepper-content>
</template>

<script>
import CabinetEdit from './CabinetEdit';
import StepsButtons from './StepsButtons';
import { mapGetters, mapState } from 'vuex';
import ApplianceEdit from '../Step3AddAppliances/ApplianceEdit.vue';

export default {
    components: {
        StepsButtons,
        CabinetEdit,
        ApplianceEdit
    },
    computed: {
        ...mapState('appliances', ['selectedAppliance']),
        ...mapState('cabinets', ['cabinets', 'selectedCabinet']),
        ...mapGetters('core', ['selectedUnit', 'editorEngine']),
    },
    methods: {
        addObj(type) {
            const cabinetProps = this.cabinets.type[type];
            this.$store.dispatch('cabinets/addObj', cabinetProps);
        },
    },
};
</script>
