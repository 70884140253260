import { StandardMaterial, Color3 } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { Utilities } from '../../Tools/utilities';
import { BaseKitchenObject } from '../BaseKitchenObject';
import { SceneComponent } from '../../Components/SceneComponent';

export class FloatingFixtureObject extends BaseKitchenObject {
    parts = {
        frameBottom: null,
        frameTop: null,
        frameLeft: null,
        frameRight: null,
        cornerBottomLeft: null,
        cornerTopLeft: null,
        cornerTopRight: null,
        cornerBottomRight: null,
        glassInside: null,
        glassOutside: null,
        sill: null,
        handle: null,
    };

    glassWidth;
    glassHeight;

    constructor() {
        super();
    }

    setDimensions(width, height) {
        this.width = width;
        this.height = height;
        this.depth = Constants.room.dimensions.WIDTH + 0.1;
    }

    setHeightFromFloor(heightFromFloor) {
        this.heightFromFloor = heightFromFloor;
        this.position.y = heightFromFloor;
    }

    setMaterial() {
        this.mesh.material = new StandardMaterial('doorMaterial', this.object.getComponentByType(SceneComponent).get());
        this.mesh.material.diffuseColor = new Color3(125, 0, 0);
        // this.mesh.material.diffuseTexture = new Texture(
        //     "https://localhost/kw-testing/assets/sliding.png",
        //     this.object.getComponentByType(SceneComponent).get()
        // );
        // this.mesh.material.diffuseTexture.hasAlpha = true;
        // this.mesh.material.diffuseTexture.useAlphaFromDiffuseTexture = true;

        // this.mesh.material.diffuseTexture.useAlpha = true;
    }

    resizeGlass() {
        let frameSize = Utilities.getMeshDimensions(this.parts.frameLeft).width;
        const glassDimensions = Utilities.getMeshDimensions(this.parts.glassInside);
        this.glassWidth = this.width - frameSize * 2 + Constants.models3D.window.GLASS_INDENTATION * 2;
        this.glassHeight = this.height - frameSize * 2 + Constants.models3D.window.GLASS_INDENTATION * 2;

        this.parts.glassInside.scaling.x = this.glassWidth / glassDimensions.width;
        this.parts.glassOutside.scaling.x = this.glassWidth / glassDimensions.width;

        this.parts.glassInside.scaling.y = this.glassHeight / glassDimensions.height;
        this.parts.glassOutside.scaling.y = this.glassHeight / glassDimensions.height;
    }

    resizeVerticalFrame(frame) {
        let cornerSize = Utilities.getMeshDimensions(this.parts.cornerTopLeft);
        let dimensions = Utilities.getMeshDimensions(this.parts.frameRight);
        this.parts[frame].scaling.y = (this.height - cornerSize.height * 2 + Constants.models3D.window.GLASS_INDENTATION) / dimensions.height;
        let movement = (Constants.models3D.window.BASE_WIDTH - this.glassWidth) / 2;
        let scaleIdicator = -1;

        if (this.glassWidth > Constants.models3D.window.BASE_WIDTH) {
            movement = (this.glassWidth - Constants.models3D.window.BASE_WIDTH) / 2;
            scaleIdicator = 1;
        }

        if (frame === Constants.models3D.window.FRAME_LEFT) {
            this.parts[frame].position.x += movement * scaleIdicator;
            this.parts.cornerTopLeft.position.x += movement * scaleIdicator;
            this.parts.cornerBottomLeft.position.x += movement * scaleIdicator;
            this.parts.handle.position.x += movement * scaleIdicator;
        } else {
            this.parts[frame].position.x -= movement * scaleIdicator;
            this.parts.cornerTopRight.position.x -= movement * scaleIdicator;
            this.parts.cornerBottomRight.position.x -= movement * scaleIdicator;
        }
    }

    resizeHorizontalFrame(frame) {
        let cornerSize = Utilities.getMeshDimensions(this.parts.cornerTopLeft);
        let dimensions = Utilities.getMeshDimensions(this.parts.frameTop);
        this.parts[frame].scaling.x = (this.width - cornerSize.width * 2 + Constants.models3D.window.GLASS_INDENTATION) / dimensions.width;
        let movement = (Constants.models3D.window.BASE_HEIGHT - this.glassHeight) / 2;
        let scaleIdicator = -1;

        if (this.glassWidth > Constants.models3D.window.BASE_HEIGHT) {
            movement = (this.glassHeight - Constants.models3D.window.BASE_HEIGHT) / 2;
            scaleIdicator = 1;
        }

        if (frame === Constants.models3D.window.FRAME_BOTTOM) {
            this.parts[frame].position.y -= movement * scaleIdicator;
            this.parts.cornerBottomLeft.position.y -= movement * scaleIdicator;
            this.parts.cornerBottomRight.position.y -= movement * scaleIdicator;

            let sillDimensions = Utilities.getMeshDimensions(this.parts.sill);
            this.parts.sill.scaling.x = (this.glassWidth + 2 * dimensions.height) / sillDimensions.width;
            this.parts.sill.position.y -= movement * scaleIdicator;
        } else {
            this.parts[frame].position.y += movement * scaleIdicator;
            this.parts.cornerTopLeft.position.y += movement * scaleIdicator;
            this.parts.cornerTopRight.position.y += movement * scaleIdicator;
        }
    }

    setParts() {
        this.parts.frameBottom = this.meshComponent.getChildByName(Constants.models3D.window.FRAME_BOTTOM);
        this.parts.frameTop = this.meshComponent.getChildByName(Constants.models3D.window.FRAME_TOP);
        this.parts.frameRight = this.meshComponent.getChildByName(Constants.models3D.window.FRAME_RIGHT);
        this.parts.frameLeft = this.meshComponent.getChildByName(Constants.models3D.window.FRAME_LEFT);

        this.parts.cornerBottomLeft = this.meshComponent.getChildByName(Constants.models3D.window.CORNER_BOTTTOM_LEFT);
        this.parts.cornerBottomRight = this.meshComponent.getChildByName(Constants.models3D.window.CORNER_BOTTTOM_RIGHT);
        this.parts.cornerTopLeft = this.meshComponent.getChildByName(Constants.models3D.window.CORNER_TOP_LEFT);
        this.parts.cornerTopRight = this.meshComponent.getChildByName(Constants.models3D.window.CORNER_TOP_RIGHT);

        this.parts.glassInside = this.meshComponent.getChildByName(Constants.models3D.window.GLASS_INSIDE);
        this.parts.glassOutside = this.meshComponent.getChildByName(Constants.models3D.window.GLASS_OUTSIDE);

        this.parts.sill = this.meshComponent.getChildByName(Constants.models3D.window.SILL);
        this.parts.handle = this.meshComponent.getChildByName(Constants.models3D.window.HANDLE);
    }

    resize() {
        this.setParts();
        this.resizeGlass();
        this.resizeHorizontalFrame(Constants.models3D.window.FRAME_BOTTOM);
        this.resizeHorizontalFrame(Constants.models3D.window.FRAME_TOP);
        this.resizeVerticalFrame(Constants.models3D.window.FRAME_LEFT);
        this.resizeVerticalFrame(Constants.models3D.window.FRAME_RIGHT);

        Object.keys(this.parts).forEach((key) => {
            this.parts[key].convertToFlatShadedMesh();
            this.parts[key].type = 'fixture';
            this.parts[key].parentFixture = this;
        });
    }

    buildMesh() {
        super.buildMesh();
        this.positionMesh();
        this.meshComponent.setType('fixture');
    }
}
