import { MeshBuilder, Vector3 } from '@babylonjs/core';
import { BuildingBlock } from '../../../BuildingBlocks/BuildingBlock';
import { Skirting } from '../../../BuildingBlocks/Skirting';
import { CornerSection } from './CornerSection';

export class PeninsulaCornerSection extends CornerSection {
    constructor() {
        super();
    }

    mergeCounterTopLHF(parentMesh) {
        let meshes = [];

        meshes = [
            this.parts.cornerCabinetLHF.countertopLHF.countertopLHF1.mesh,
            this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF2.mesh,
        ];
        this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF1.mesh.visibility = 0;
        meshes.push(this.parts.cornerCabinetLHF.islandLHF.countertopLHFIslandBack.mesh);

        const countertopBlock = this.editor.addObject(BuildingBlock);
        countertopBlock.setParentSection(this.currentParentSection);
        countertopBlock.enableSelection();
        countertopBlock.create(meshes, 'countertop');
        countertopBlock.meshComponent.setParent(parentMesh);
        return countertopBlock;
    }

    mergeSkirtingBlockLHF(side, parentMesh) {
        let parts = [];
        if (side === 'rightSide') {
            parts = [
                this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF1.mesh,
                this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF2.mesh,
                this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF5.mesh,
                this.parts.cornerCabinetLHF.islandLHF.skirtingIslandLHF.mesh,
            ];
            this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF3.mesh.visibility = 0;
            this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF4.mesh.visibility = 0;
        } else if (side === 'leftSide') {
            parts = [
                this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF1.mesh,
                this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF2.mesh,
                this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF3.mesh,
                this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF4.mesh,
                this.parts.cornerCabinetLHF.skirtingLHF.skirtingLHF5.mesh,
                this.parts.cornerCabinetLHF.islandLHF.skirtingIslandLHF.mesh,
            ];
            this.parts.cornerCabinetLHF.countertopLHF.backsplashLHF1.mesh.visibility = 0;
        }

        const skirtingBlock = this.editor.addObject(Skirting);
        skirtingBlock.setParentSection(this.currentParentSection);
        skirtingBlock.create(parts, 'skirting');
        skirtingBlock.meshComponent.setParent(parentMesh);
        return skirtingBlock;
    }

    mergeBackPanel(parentMesh) {
        const backPanelBlock = this.editor.addObject(BuildingBlock);
        backPanelBlock.setParentSection(this.currentParentSection);
        backPanelBlock.create([this.parts.cornerCabinetLHF.islandLHF.panelIslandLHF.mesh], 'cabinets');
        backPanelBlock.meshComponent.setParent(parentMesh);
        return backPanelBlock;
    }

    mergeModel(sectionData) {
        const cornerCabinetMesh = MeshBuilder.CreateBox('base-cabinet-model', { width: 0.1, depth: 0.1, height: 0.1 });
        cornerCabinetMesh.visibility = 0;

        const cornerCabinet = { mesh: cornerCabinetMesh, buildingBlocks: {} };

        this.placeHandle(cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.innerStructure = this.mergeInnerTextureBlock(cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.doorBlock = this.mergeOuterTextureLHF(cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.countertopBlock = this.mergeCounterTopLHF(cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.frontPanelBlock = this.mergeFrontPanelBlockLHF(cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.skirtingBlock = this.mergeSkirtingBlockLHF(sectionData.side, cornerCabinet.mesh);
        cornerCabinet.buildingBlocks.fillerBlock = this.mergeFillerLHF(cornerCabinet.mesh);

        //  cornerCabinet.buildingBlocks.backpanel = this.mergeBackPanel(cornerCabinet.mesh);

        cornerCabinet.mesh.rotation = Vector3.Zero();
        // if (!(this.parentCabinet.peninsulaData?.connectedCabinet?.peninsulaConnection.edgeIndex === 'secondPoint')) {
        //     cornerCabinet.mesh.scaling.x = -1;
        // }

        this.meshComponent.getMesh().setEnabled(false);
        return cornerCabinet;
    }

    rotateToFitIslandOrientation(side, type, model) {
        if (side === 'rightSide' && type === 'oneRow') {
            console.log('one row cabinet, right side of base cabinet');
            model.scaling.x = -1;
        } else if (side === 'leftSide' && type === 'oneRow') {
            console.log('one row cabinet, left side of base cabinet');
        } else if (side === 'rightSide' && type === 'twoRow') {
            console.log('two row cabinet, right side of base cabinet');
            model.scaling.x = -1;
        } else if (side === 'leftSide' && type === 'twoRow') {
            console.log('two row cabinet, left side of base cabinet');
        }
    }
}
