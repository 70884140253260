import { Color3, MeshBuilder, Vector3 } from '@babylonjs/core';
import { RoomObject } from '../../Room/RoomObject';
import { Constants } from '../../../Tools/constants';
import { Utilities } from '../../../Tools/utilities';
import { BaseMeasurementLine } from '../../../Components/Base/BaseMeasurementLine';

export class MeasurementLine extends BaseMeasurementLine {
    constructor() {
        super();
    }

    build(value) {
        let lineCoordinates;
        if (this.object.type === Constants.cabinet.type.ISLAND) {
            lineCoordinates = this.calculateLineSystemCoordinatesForIsland();
        } else {
            const slots = this.getMeasurementLinesSlots();
            lineCoordinates = this.calculateLineSystemCoordinates(slots);
        }

        if (this.linkedMeshes.length === 0) {
            this.buildLinkedMesh(lineCoordinates);
        }
        this.updateLength(lineCoordinates, value);

        if (!this.lineSystem) {
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        } else {
            this.lineSystem.dispose();
            this.lineSystem = MeshBuilder.CreateLineSystem('lineSystem', { lines: lineCoordinates });
        }
        this.lineSystem.color = new Color3(0, 0, 0);
        this.mesh = this.lineSystem;
    }

    calculateLineSystemCoordinatesForIsland() {
        let lineCoordinates = [];
        let width = this.object.width;
        let depth = this.object.depth;
        if (this.object.rotation === 90 || this.object.rotation === 270) {
            width = this.object.depth;
            depth = this.object.width;
        }
        const position = this.object.meshComponent.getMesh().position.clone();

        const rightCorner = new Vector3(position.x + width / 2, Constants.cabinet.measurementLine.MARGIN, position.z + depth / 2);
        const topCorner = new Vector3(position.x + width / 2, Constants.cabinet.measurementLine.MARGIN, position.z - depth / 2);
        const bottomCorner = new Vector3(position.x - width / 2, Constants.cabinet.measurementLine.MARGIN, position.z - depth / 2);

        const shiftedRightCorner = rightCorner.clone();
        shiftedRightCorner.x += Constants.cabinet.measurementLine.SHIFTING;
        const shiftedTopCorner = topCorner.clone();
        shiftedTopCorner.x += Constants.cabinet.measurementLine.SHIFTING;
        const shiftedBottomCorner = bottomCorner.clone();
        shiftedBottomCorner.z -= Constants.cabinet.measurementLine.SHIFTING;
        const shiftedLeftCorner = topCorner.clone();
        shiftedLeftCorner.z -= Constants.cabinet.measurementLine.SHIFTING;

        lineCoordinates.push(
            [shiftedRightCorner, shiftedTopCorner],
            [rightCorner, shiftedRightCorner],
            [topCorner, shiftedTopCorner],
            [shiftedLeftCorner, shiftedBottomCorner],
            [bottomCorner, shiftedBottomCorner],
            [topCorner, shiftedLeftCorner]
        );

        return lineCoordinates;
    }

    calculateLineSystemCoordinates(slots) {
        let lineCoordinates = [];
        const roomObject = this.object.editor.getObjectByType(RoomObject);
        const roomCorners = roomObject.baseOuterCorners.concat(roomObject.baseOuterCorners[0]);

        for (let index = 0; index < slots.length; index++) {
            let firstPointClone = slots[index].firstPoint.clone();
            let secondPointClone = slots[index].secondPoint.clone();
            let firstPoint = slots[index].firstPoint.clone();
            let secondPoint = slots[index].secondPoint.clone();
            if (slots[index].fixture !== null) {
                firstPointClone = Utilities.calculateShiftValue(
                    firstPointClone,
                    -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                    roomCorners,
                    this.object.assignedWall
                );
                secondPointClone = Utilities.calculateShiftValue(
                    secondPointClone,
                    -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                    roomCorners,
                    this.object.assignedWall
                );
                if (this.object.type === Constants.cabinet.type.WALL) {
                    firstPoint.y += this.object.heightFromFloor + this.object.height;
                    secondPoint.y += this.object.heightFromFloor + this.object.height;
                    firstPointClone = firstPoint.clone();
                    secondPointClone = secondPoint.clone();
                    firstPointClone.y += 0.2;
                    secondPointClone.y += 0.2;
                } else {
                    firstPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                    secondPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                    firstPoint.y += Constants.cabinet.measurementLine.MARGIN;
                    secondPoint.y += Constants.cabinet.measurementLine.MARGIN;
                }
            } else {
                firstPointClone = Utilities.calculateShiftValue(
                    firstPointClone,
                    -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                    roomCorners,
                    this.object.assignedWall
                );
                secondPointClone = Utilities.calculateShiftValue(
                    secondPointClone,
                    -this.object.depth - Constants.cabinet.measurementLine.SHIFTING,
                    roomCorners,
                    this.object.assignedWall
                );
                if (this.object.type === Constants.cabinet.type.WALL) {
                    firstPoint.y += this.object.heightFromFloor + this.object.height;
                    secondPoint.y += this.object.heightFromFloor + this.object.height;

                    firstPointClone = firstPoint.clone();
                    secondPointClone = secondPoint.clone();
                    firstPointClone.y += 0.2;
                    secondPointClone.y += 0.2;
                } else {
                    firstPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                    secondPointClone.y += Constants.cabinet.measurementLine.MARGIN;
                    firstPoint.y += Constants.cabinet.measurementLine.MARGIN;
                    secondPoint.y += Constants.cabinet.measurementLine.MARGIN;
                }
            }

            lineCoordinates.push([firstPointClone, secondPointClone], [firstPoint, firstPointClone], [secondPoint, secondPointClone]);
        }
        return lineCoordinates;
    }
}
