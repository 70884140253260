import store from '@/store/index.js';
import {
    Vector2,
    Vector3,
    PointerEventTypes,
    KeyboardEventTypes,
    Matrix,
    StandardMaterial,
    MeshBuilder,
    Color4,
    Color3,
} from '@babylonjs/core';
import { unitAsBjsUnit, CORNER_POINT_BOX_SIZE, lerp } from '../Tools/Utilities';
import { Mode } from '../Tools/Mode';
import { SegmentOrientation } from '../Tools/SegmentOrientation';

export class EditorControls {
    mode = Mode.NONE;
    step = 'initialCorner';
    isSceneLoading = false;
    editor;
    mouse = {
        lastPosition: null,
        lastSafePosition: null,
        currentPosition: null,
        lastDown: null,
        clickTollerance: 250,
        lastHoverItem: null,
    };

    selectedItem = null;

    lastSegmentData = null;
    lastMovementPoint = null;

    camera = {
        zoom: { min: -0.325, max: -15 },
        accel: new Vector3(3, 3, 0.1),
        decel: new Vector3(0.96, 0.96, 0.92),
        inerta: new Vector3(0, 0, 0),
        max: new Vector3(10, 10, 10),
        discard: 0.001,
        panSmoothing: 0.94 /* The closer to 1 for more effect, the closer to 0 for sharper movement or less effect*/,
    };

    cornerPointBoxSize = CORNER_POINT_BOX_SIZE;
    cursorBox;
    showCursorBox = true;
    segmentPreviewLine = {
        points: [],
        mesh: null,
    };

    horizontalAxisLine = [new Vector3(-screen.width / 2, -1, 0), new Vector3(0, 0, 0), new Vector3(screen.width / 2, 0, 0)];

    verticalAxisLine = [new Vector3(0, screen.height / 2, 0), new Vector3(0, 0, 0), new Vector3(0, -screen.height / 2, 0)];
    horizontalAxisMesh = MeshBuilder.CreateLines('lines', {
        points: this.horizontalAxisLine,
        updatable: true,
    });
    verticalAxisMesh = MeshBuilder.CreateLines('lines', {
        points: this.verticalAxisLine,
        updatable: true,
    });

    isSnapping;
    zoomRatio;

    constructor(editor) {
        this.editor = editor;
        this.createCursorBox(this.editor);
        this.horizontalAxisMesh.color = new Color3.Gray();
        this.verticalAxisMesh.color = new Color3.Gray();
        this.horizontalAxisMesh.setEnabled(false);
        this.verticalAxisMesh.setEnabled(false);

        this.bindings();
    }

    showVerticalAxisLine(newPosition) {
        this.verticalAxisMesh.setEnabled(true);

        this.verticalAxisLine[0].x = newPosition.x;
        this.verticalAxisLine[0].z = newPosition.z;

        this.verticalAxisLine[1] = newPosition;

        this.verticalAxisLine[2].x = newPosition.x;
        this.verticalAxisLine[2].z = newPosition.z;

        this.verticalAxisMesh = MeshBuilder.CreateLines('lines', {
            points: this.verticalAxisLine,
            instance: this.verticalAxisMesh,
        });
    }

    showHorizontalAxisLine(newPosition) {
        this.horizontalAxisMesh.setEnabled(true);

        this.horizontalAxisLine[0].y = newPosition.y;
        this.horizontalAxisLine[0].z = newPosition.z;

        this.horizontalAxisLine[1] = newPosition;

        this.horizontalAxisLine[2].y = newPosition.y;
        this.horizontalAxisLine[2].z = newPosition.z;

        this.horizontalAxisMesh = MeshBuilder.CreateLines('lines', {
            points: this.horizontalAxisLine,
            instance: this.horizontalAxisMesh,
        });
    }

    createCursorBox(editor) {
        this.cursorBox = MeshBuilder.CreatePlane(
            'box',
            { width: CORNER_POINT_BOX_SIZE, height: CORNER_POINT_BOX_SIZE },
            this.editor.scene
        );
        this.cursorBox.convertToFlatShadedMesh();
        this.cursorBox.isPickable = false;
        this.cursorBox.isBlocker = false;
        this.cursorBox.id = 'initialBox';
        let material = new StandardMaterial(this.editor.scene);
        material.diffuseColor = new Color4(1.0, 0.2, 0.7, 1);
        material.emissiveColor = new Color4(1.0, 0.2, 0.7, 1);
        this.cursorBox.material = material;
        this.editor.scene.lights[0].excludedMeshes.push(this.cursorBox);
        this.cursorBox.outlineWidth = 1;
        this.cursorBox.outlineColor = new Color3(1.0, 0.2, 0.7);
        this.cursorBox.material = material;
        this.cursorBox.material.wireframe = true;
    }

    cameraZoom() {
        let camera = this.editor.scene.activeCamera;
        let zoom = camera.position.z;
        this.zoomRatio = -4.5 / zoom;
        let width = zoom * this.editor.ratio;

        camera.orthoTop = zoom;
        camera.orthoLeft = -width;
        camera.orthoRight = width;
        camera.orthoBottom = -zoom;

        this.updateFontSizeOnCameraZoom();
    }

    updateFontSizeOnCameraZoom() {
        const floorplan = this.editor.floorplan;
        floorplan.segments.forEach((segment) => {
            segment.widget.numberWidgets.forEach((widget) => {
                widget.updateTextPlaneWidgetSize();
            });
        });

        floorplan.fixtures.forEach((fixture) => {
            if (fixture.widget.nameWidget) {
                fixture.widget.nameWidget.updateTextPlaneWidgetSize();
            }
            if (fixture.widget.numberWidget) {
                fixture.widget.numberWidget.updateTextPlaneWidgetSize();
            }
        });
    }

    loadPolygon(data) {
        const scene = this.editor.scene;
        const camera = scene.activeCamera;
        const floorplan = this.editor.floorplan;
        this.isSceneLoading = true;
        floorplan.corners = [];
        if (!data.corners || data.corners.length == 0) {
            return;
        }
        data.corners.forEach((c) => floorplan.addCornerPoint(new Vector3(c.x, c.y, 0)));
        this.editor.closePolygon();
    }

    loadFixtures(data) {
        const scene = this.editor.scene;
        const camera = scene.activeCamera;
        const floorplan = this.editor.floorplan;

        if (!data.fixtures || data.fixtures.length == 0) {
            return;
        }
        data.fixtures.forEach((f) => {
            floorplan.addFixture(f);
            const screenXY = Vector3.Project(
                new Vector3(f.position.x, f.position.y, f.position.z),
                Matrix.Identity(),
                camera.getTransformationMatrix(),
                camera.viewport.toGlobal(this.editor.engine.getRenderWidth(true), this.editor.engine.getRenderHeight(true))
            );
            if (f.type !== 'fixedColumn') {
                this.editor.placingWallFixture(scene.pick(screenXY.x, screenXY.y, () => true));
                this.editor.placeWallFixture();
            } else {
                this.editor.placingFloorFixture(scene.pick(screenXY.x, screenXY.y, () => true));
                this.editor.placeFloorFixture();
            }

            this.selectedItem = null;
            store.commit('fixtures/setSelectedFixture', null);
        });
    }

    loadScene(data) {
        this.cursorBox.setEnabled(false);
        this.loadPolygon(data);
        this.loadFixtures(data);
        this.isSceneLoading = false;
        this.cursorBox.dispose();
    }

    checkForRotationAngle(fixture) {
        if (fixture.assignedSegment !== -1) {
            return this.getSegmentFromId(fixture.assignedSegment).angle;
        } else {
            return fixture.rotation;
        }
    }

    save2D() {
        const floorplan = this.editor.floorplan;
        store.commit('core/setProjectData', {
            corners: floorplan.corners.map((c) => ({
                x: c.position.x,
                y: c.position.y,
            })),
            fixtures: floorplan.fixtures.map((f) => ({
                type: f.type,
                subType: f.subType,
                flipped: f.flipped,
                dimensions: f.dimensions,
                currentUnit: f.currentUnit,
                position: {
                    x: f.getPosition().x,
                    y: f.getPosition().y,
                    z: 0,
                },
                columnPosition: {
                    x: f.positionFor3D ? f.positionFor3D.x : null,
                    y: f.positionFor3D ? f.positionFor3D.y : null,
                    z: f.positionFor3D ? f.positionFor3D.z : null,
                },
                rotation: this.checkForRotationAngle(f),
            })),
            cabinets: store.state.core.projectData ? store.state.core.projectData.cabinets : null,
            appliances: store.state.core.projectData ? store.state.core.projectData.appliances : null,
            canvasLoaded: store.state.core.canvasLoaded,
            startedDesigning: store.state.core.startedDesigning,
            step: store.state.core.step,
            unit: store.state.core.unit,
        });
    }

    getSegmentFromId(segment) {
        if (typeof segment === 'number') {
            let segmentIdx = segment;
            return (segment = this.editor.floorplan.segments.filter((filteredSegment) => {
                return filteredSegment.idx === segmentIdx;
            })[0]);
        } else {
            return segment;
        }
    }

    clearScene() {
        this.step = 'initialCorner';

        let floorplan = this.editor.floorplan;
        floorplan.segments.forEach((segment) => {
            segment.widget.lines.dispose();
            segment.widget.numberWidgets.forEach((widget) => {
                widget.parts.rect.dispose();
                widget.parts.textBlock.dispose();
            });
            segment.mesh.dispose();
        });

        floorplan.cornerCaps.forEach((cornerCap) => {
            cornerCap.widget.parts.rect.dispose();
            cornerCap.widget.parts.textBlock.dispose();
            cornerCap.mesh.dispose();
        });

        floorplan.measurementWidgets.forEach((measurementWidget) => {
            measurementWidget.parts.rect.dispose();
            measurementWidget.parts.textBlock.dispose();

            if (measurementWidget.sector) {
                measurementWidget.sector.dispose();
            }

            if (measurementWidget.angleTextplaneWidget) {
                measurementWidget.angleTextplaneWidget.parts.textBlock.dispose();
                measurementWidget.angleTextplaneWidget.parts.rect.dispose();
            }
        });

        floorplan.fixtures.forEach((fixture) => fixture.purge(true));
        floorplan.corners.forEach((corner) => corner.mesh.dispose());
        store.state.core.projectData.cabinets = [];
        store.state.core.projectData.appliances = [];

        this.segmentPreviewLine.mesh?.dispose();
        this.segmentPreviewLine.points = [];

        floorplan.segments = [];
        floorplan.corners = [];
        floorplan.fixtures = [];
        floorplan.cornerCaps = [];
        this.showCursorBox = true;
        this.cursorBox.dispose();
        this.createCursorBox(this.editor);

        store.commit('core/setResetProject', false);
        floorplan.isClosed = false;
        this.save2D();
    }

    onKeyUp(keyInfo) {
        const scene = this.editor.scene;

        if (keyInfo.event.key === 's' && this.step === 'placeFixtures') {
            // save data
            this.save2D();
        }
        if (keyInfo.event.key === 'l') {
            // load data
            const data = {
                corners: [
                    { x: 4.032, y: -3.012 },
                    { x: -1.5031967734883842, y: -3.012 },
                    { x: -1.5031967734883842, y: -0.024 },
                    { x: -5.028, y: -0.024 },
                    { x: -5.028, y: 3 },
                    { x: 4.032, y: 3 },
                ],
                fixtures: [],
                canvasLoaded: true,
                startedDesigning: true,
                step: 1,
                unit: 'mm',
            };
            this.clearScene();
            this.loadScene(data);
        }
        if (keyInfo.event.key === 'r') {
            this.clearScene();
        }
        if (keyInfo.event.key === 'g') {
            this.dispose();
        }
    }

    onHandleMovingSegment(pick) {
        if (!this.lastMovementPoint) {
            this.lastMovementPoint = pick.pickedPoint.clone();
        }

        let movementX = pick.pickedPoint.x - this.lastMovementPoint.x;
        let movementY = pick.pickedPoint.y - this.lastMovementPoint.y;

        let isBlockedHorizontally = this.selectedItem.blockSegmentMovement(true, SegmentOrientation.VERTICAL);
        let isBlockedVertically = this.selectedItem.blockSegmentMovement(true, SegmentOrientation.HORIZONTAL);

        if (isBlockedHorizontally) {
            this.selectedItem.move(
                this.selectedItem.a.add(new Vector3(movementX, 0, 0)),
                this.selectedItem.b.add(new Vector3(movementX, 0, 0)),
                false
            );
        } else if (isBlockedVertically) {
            this.selectedItem.move(
                this.selectedItem.a.add(new Vector3(0, movementY, 0)),
                this.selectedItem.b.add(new Vector3(0, movementY, 0)),
                false
            );
        } else {
            this.selectedItem.move(
                this.selectedItem.a.add(new Vector3(movementX, movementY, 0)),
                this.selectedItem.b.add(new Vector3(movementX, movementY, 0)),
                false
            );
        }

        this.lastMovementPoint = pick.pickedPoint.clone();
    }

    onHandleMovingCorner(pick) {
        if (!this.lastMovementPoint) {
            this.lastMovementPoint = pick.pickedPoint.clone();
        }

        let movementX = pick.pickedPoint.x - this.lastMovementPoint.x;
        let movementY = pick.pickedPoint.y - this.lastMovementPoint.y;

        this.selectedItem.move(new Vector3(movementX, movementY, 0));

        this.lastMovementPoint = pick.pickedPoint.clone();
    }

    bindings() {
        const scene = this.editor.scene;
        const camera = scene.activeCamera;
        const floorplan = this.editor.floorplan;

        this.keyboardOb = scene.onKeyboardObservable.add((keyInfo) => {
            switch (keyInfo.type) {
                case KeyboardEventTypes.KEYUP:
                    this.onKeyUp(keyInfo);
                    break;
            }
        });

        this.mouseOb = scene.onPointerObservable.add((pointerInfo) => {
            const pick = scene.pick(scene.pointerX, scene.pointerY, (m) => {
                if (m.ignorePick == true) {
                    return false;
                }
                return true;
            });

            switch (pointerInfo.type) {
                case PointerEventTypes.POINTERDOWN:
                    this.mouse.lastDown = Date.now();
                    if (pick.hit) {
                        if (
                            pick.pickedMesh.isGround ||
                            pick.pickedMesh.id.includes('meshAnchor') ||
                            pick.pickedMesh.id.includes('initialBox')
                        ) {
                            this.mode = 'panning';
                            this.mouse.lastPosition = pick.pickedPoint.clone();
                            this.mouse.currentPosition = pick.pickedPoint.clone();
                        } else if (pick.pickedMesh.isPoint && this.step == 'drawingWalls') {
                            this.mode = 'dragPoint';
                            floorplan.measurementWidgets[floorplan.measurementWidgets.length - 1].setEnabled(false);
                            this.selectedItem = floorplan.corners[pick.pickedMesh.core.id];
                        }
                    }
                    break;

                case PointerEventTypes.POINTERUP: {
                    // let n = Date.now();
                    this.verticalAxisMesh.setEnabled(false);
                    this.horizontalAxisMesh.setEnabled(false);

                    if (this.mode == 'panning') {
                        this.mode = 'none';
                        this.mouse.lastPosition = null;
                        this.mouse.currentPosition = null;
                    }

                    if (this.step == 'drawingWalls' && this.mode == 'dragPoint') {
                        this.mode = 'none';
                        this.selectedItem = null;

                        this.editor.cleanAllInlineCorners();
                        this.editor.updateSegmentPreviewLine(pick);
                    }

                    if ([Mode.MOVING_SEGMENT, Mode.MOVING_CORNER].includes(this.mode)) {
                        this.save2D();
                        this.mode = Mode.NONE;
                        this.selectedItem = null;
                    }

                    this.mouse.lastDown = null;
                    break;
                }

                case PointerEventTypes.POINTERMOVE:
                    if (pick.hit) {
                        //If we are panning we do not need to do anything else but update the currentPosition
                        if (this.mode == 'panning') {
                            this.mouse.currentPosition = pick.pickedPoint.clone();
                            this.cursorBox.position.x = pick.pickedPoint.clone().x;
                            this.cursorBox.position.y = pick.pickedPoint.clone().y;
                            return;
                        }

                        if (this.step == 'drawingWalls') {
                            this.editor.drawingWallsMouseMove(pick);
                            return;
                        }

                        if (this.mode == Mode.MOVING_SEGMENT) {
                            this.onHandleMovingSegment(pick);
                            return;
                        }

                        if (this.mode == Mode.MOVING_CORNER) {
                            this.onHandleMovingCorner(pick);
                            return;
                        }

                        if (this.step == 'placeFixtures') {
                            if (this.selectedItem) {
                                if (this.mouse.lastHoverItem) {
                                    this.mouse.lastHoverItem.isHover = false;
                                    this.mouse.lastHoverItem.updateTextureParts();
                                    this.mouse.lastHoverItem = null;
                                }

                                switch (this.selectedItem.type) {
                                    case 'window':
                                    case 'door':
                                    case 'openSpace':
                                        this.editor.placingWallFixture(pick);
                                        break;
                                    case 'fixedColumn':
                                        this.editor.placingFloorFixture(pick);
                                        break;
                                }
                            } else {
                                let fPick = scene.pick(scene.pointerX, scene.pointerY, (m) => {
                                    if (m.isFixture == true) {
                                        return true;
                                    }
                                    return false;
                                });
                                if (fPick.hit) {
                                    if (!this.mouse.lastHoverItem) {
                                        this.mouse.lastHoverItem = fPick.pickedMesh.root.parent;
                                        this.mouse.lastHoverItem.isHover = true;
                                        this.mouse.lastHoverItem.updateTextureParts();
                                    } else {
                                        if (fPick.pickedMesh.root.uID != this.mouse.lastHoverItem.uID) {
                                            this.mouse.lastHoverItem.isHover = false;
                                            this.mouse.lastHoverItem.updateTextureParts();
                                            this.mouse.lastHoverItem = fPick.pickedMesh.root.parent;
                                            this.mouse.lastHoverItem.isHover = true;
                                            this.mouse.lastHoverItem.updateTextureParts();
                                        }
                                    }
                                } else {
                                    if (this.mouse.lastHoverItem) {
                                        this.mouse.lastHoverItem.isHover = false;
                                        this.mouse.lastHoverItem.updateTextureParts();
                                        this.mouse.lastHoverItem = null;
                                    }
                                }
                            }
                        }
                    }

                    break;

                case PointerEventTypes.POINTERWHEEL: {
                    let delta = Math.sign(pointerInfo.event.wheelDelta);
                    this.camera.inerta.z = Math.max(
                        Math.min(
                            lerp(this.camera.inerta.z + delta * this.camera.accel.z, this.camera.inerta.z, 0.5),
                            this.camera.max.z
                        ),
                        this.camera.max.z * -1
                    );
                    break;
                }

                case PointerEventTypes.POINTERPICK:
                    this.mouseAction('click', pick);
                    break;

                case PointerEventTypes.POINTERTAP:
                    break;

                case PointerEventTypes.POINTERDOUBLETAP:
                    break;
            }
        });

        this.renderOb = scene.onBeforeRenderObservable.add(() => {
            //Panning
            let inerta = Vector2.Zero();
            if (this.mode == 'panning') {
                let d = this.mouse.currentPosition.subtract(this.mouse.lastPosition);

                if (d.length() != 0) {
                    inerta = Vector2.Lerp(
                        new Vector2(d.x, d.y).multiplyByFloats(this.camera.accel.x, this.camera.accel.y),
                        new Vector2(this.camera.inerta.x, this.camera.inerta.y),
                        this.camera.panSmoothing
                    );
                    inerta.x = Math.max(Math.min(inerta.x, this.camera.max.x), this.camera.max.x * -1);
                    inerta.y = Math.max(Math.min(inerta.y, this.camera.max.y), this.camera.max.y * -1);

                    this.mouse.lastPosition = this.mouse.currentPosition.clone();
                }
            }

            camera.position.x -= inerta.x;
            camera.position.y -= inerta.y;
            inerta.multiplyInPlace(new Vector2(this.camera.decel.x, this.camera.decel.y));
            this.camera.inerta.x = inerta.x;
            this.camera.inerta.y = inerta.y;

            //Zoom
            this.camera.inerta.z *= this.camera.decel.z;
            //Update any dirty widgets
            floorplan.measurementWidgets.forEach((measurementWidget) => {
                if (!measurementWidget.dirty) {
                    return false;
                }
                measurementWidget.update();
            });

            if (inerta.length < this.camera.discard * 0.5) {
                inerta.x = 0;
                inerta.y = 0;
            }

            if (Math.abs(this.camera.inerta.z) < this.camera.discard) {
                this.camera.inerta.z = 0;
            }

            camera.position.z = Math.max(
                Math.min(lerp(camera.position.z + this.camera.inerta.z, camera.position.z, 0.65), this.camera.zoom.min),
                this.camera.zoom.max
            );

            this.cameraZoom();
            this.cursorControl();
        });

        this.runOb = this.editor.engine.runRenderLoop(() => {
            this.editor.scene.render();
        });

        window.addEventListener('resize', () => {
            this.fixCanvas();
        });
    }

    fixCanvas() {
        if (this.editor.scene) {
            let camera = this.editor.scene.activeCamera;

            camera.mode = 1;
            this.editor.ratio = this.editor.canvas.clientWidth / this.editor.canvas.clientHeight;

            let zoom = camera.position.z;
            let width = zoom * this.editor.ratio;

            camera.orthoTop = zoom;
            camera.orthoLeft = -width;
            camera.orthoRight = width;
            camera.orthoBottom = -zoom;

            camera.getProjectionMatrix(true);
            this.editor.engine.resize();
            this.editor.widgetUI.scaleTo(this.editor.canvas.clientWidth, this.editor.canvas.clientHeight);
            this.editor.canvas.width = this.editor.canvas.clientWidth;
            this.editor.canvas.height = this.editor.canvas.clientHeight;
        }
    }

    mouseAction(action, pick) {
        const floorplan = this.editor.floorplan;

        switch (action) {
            case 'click':
                if (pick.hit) {
                    switch (this.step) {
                        case 'initialCorner': {
                            this.step = 'drawingWalls';
                            floorplan.addCornerPoint(
                                this.editor.position2Snap(pick.pickedPoint.clone().multiplyByFloats(1, 1, 0))
                            );
                            this.editor.updateSegmentPreviewLine(pick);
                            console.log('Place Initial Corner');

                            floorplan.addMeasurementWidget(
                                floorplan.corners[0].position.clone(),
                                this.editor.position2Snap(pick.pickedPoint.clone()),
                                {
                                    type: 'no-lines',
                                }
                            );
                            this.showCursorBox = false;
                            break;
                        }
                        case 'drawingWalls': {
                            let inputLength = unitAsBjsUnit(Number(store.state.fixtures.typedWallDim));
                            pick.pickedPoint = this.editor
                                .testAllPointsForSnap(pick.pickedPoint.clone())
                                .multiplyByFloats(1, 1, 0);

                            if (inputLength > 0) {
                                pick.pickedPoint = this.editor.getFixedLengthPosition(
                                    pick.pickedPoint,
                                    floorplan.getLastCorner().position,
                                    inputLength
                                );
                            }

                            //CHECK ALL POINTS!
                            let selectPointData = this.editor.checkCornerPointSelect(pick.pickedPoint.clone());
                            if (selectPointData.id >= 1) {
                                return;
                            }

                            if (floorplan.corners.length < 2) {
                                floorplan.addCornerPoint(pick.pickedPoint);
                                floorplan.addMeasurementWidget(
                                    floorplan.getLastCorner().position,
                                    this.editor.position2Snap(pick.pickedPoint),
                                    {
                                        type: 'no-lines',
                                    }
                                );

                                floorplan.measurementWidgets[floorplan.measurementWidgets.length - 2].setAB(
                                    floorplan.getLastCorner(2).position,
                                    pick.pickedPoint.multiplyByFloats(1, 1, 0)
                                );
                            } else {
                                if (selectPointData.id == 0) {
                                    let isInlinePointsFirstCase = this.editor.checkForInlineCorners(
                                        floorplan.getLastCorner(2).position,
                                        floorplan.getLastCorner().position,
                                        pick.pickedPoint
                                    );
                                    let isInlinePointsSecondCase = this.editor.checkForInlineCorners(
                                        floorplan.getLastCorner().position,
                                        floorplan.corners[0].position,
                                        floorplan.corners[1].position
                                    );

                                    if (isInlinePointsFirstCase && isInlinePointsSecondCase) {
                                        floorplan.corners.splice(floorplan.corners.length - 1, 1)[0].dispose();
                                        floorplan.corners.splice(0, 1)[0].dispose();
                                    } else if (isInlinePointsFirstCase && !isInlinePointsSecondCase) {
                                        floorplan.corners.splice(floorplan.corners.length - 1, 1)[0].dispose();
                                    } else if (!isInlinePointsFirstCase && isInlinePointsSecondCase) {
                                        floorplan.corners.splice(0, 1)[0].dispose();
                                    }

                                    return this.editor.closePolygon();
                                } else {
                                    let inlinePoints = this.editor.checkForInlineCorners(
                                        floorplan.getLastCorner(2).position,
                                        floorplan.getLastCorner().position,
                                        pick.pickedPoint
                                    );
                                    if (inlinePoints) {
                                        floorplan.corners.splice(floorplan.corners.length - 1, 1)[0].dispose();
                                        this.segmentPreviewLine.points.splice(this.segmentPreviewLine.points.length - 2, 1);
                                    }

                                    floorplan.addCornerPoint(pick.pickedPoint);

                                    if (!inlinePoints) {
                                        floorplan.addMeasurementWidget(
                                            floorplan.getLastCorner().position.clone(),
                                            pick.pickedPoint.clone(),
                                            {
                                                type: 'no-lines',
                                            }
                                        );
                                    } else {
                                        floorplan.measurementWidgets[
                                            floorplan.measurementWidgets.length - 1
                                        ].angleTextplaneWidget.meshAnchor.position = pick.pickedPoint;
                                    }
                                    floorplan.measurementWidgets[floorplan.measurementWidgets.length - 2].setAB(
                                        floorplan.getLastCorner(2).position,
                                        pick.pickedPoint.multiplyByFloats(1, 1, 0)
                                    );
                                }
                            }

                            this.editor.updateSegmentPreviewLine(pick);

                            break;
                        }
                        case 'placeFixtures': {
                            if ([Mode.MOVING_SEGMENT, Mode.MOVING_CORNER].includes(this.mode)) {
                                return;
                            }

                            if (this.selectedItem) {
                                if (this.selectedItem.type === 'fixedColumn') {
                                    this.editor.placeFloorFixture();
                                } else {
                                    this.editor.placeWallFixture(pick);
                                }
                                store.commit('fixtures/setSelectedFixture', null);
                            } else {
                                const scene = this.editor.scene;
                                const fPick = scene.pick(scene.pointerX, scene.pointerY, (m) => {
                                    if (m.isFixture == true) {
                                        return true;
                                    }
                                    return false;
                                });

                                if (fPick.hit) {
                                    let fixture = fPick.pickedMesh.root.parent;
                                    if (fixture.type !== 'fixedColumn') {
                                        let segment = fixture.assignedSegment;

                                        if (typeof segment === 'number') {
                                            let segmentIdx = segment;
                                            segment = this.editor.floorplan.segments.filter((segment) => {
                                                return segment.idx === segmentIdx;
                                            })[0];
                                        }

                                        segment.grabSlot(fixture.assignedSlot);
                                    }
                                    fPick.pickedMesh.root.parent.startDrag();
                                }
                            }
                            break;
                        }
                    }
                }
                break;
        }
    }

    squaredCursor() {
        if (this.editor.scene) {
            let scene = this.editor.scene;
            let pickResult = scene.pick(scene.pointerX, scene.pointerY);
            this.cursorBox.position.x = pickResult.pickedPoint.x;
            this.cursorBox.position.y = pickResult.pickedPoint.y;
            if (!this.showCursorBox) {
                this.cursorBox.dispose();
            }
        }
    }

    cursorControl() {
        if (this.mode == 'panning') {
            document.body.style.cursor = 'grab';
            return;
        } else if (this.step == 'drawingWalls' && this.mode == 'dragPoint') {
            document.body.style.cursor = 'move';
            this.cursorBox.dispose();
        } else {
            document.body.style.cursor = 'crosshair';
            this.squaredCursor();
        }

        if (this.mouse.lastHoverItem) {
            document.body.style.cursor = 'pointer';
            this.cursorBox.dispose();
        }
        if ([Mode.MOVING_SEGMENT, Mode.MOVING_CORNER].includes(this.mode)) {
            document.body.style.cursor = 'grab';
            return;
        }
    }
}
