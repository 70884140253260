<template>
    <v-stepper-header>
        <v-stepper-step
            class="ma-0 py-0 px-2"
            :complete="step > 1"
            step="1"
            :editable="step > 1"
            >Draw Room
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
            class="ma-0 py-0 px-2"
            :complete="step > 2"
            :editable="step > 2"
            step="2"
            >Draw Cabinets
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
            :complete="step > 3"
            :editable="step > 3"
            class="ma-0 py-0 px-2"
            step="3"
            >Add Appliances
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step class="ma-0 py-0 px-2" step="4"
            >Final Design
        </v-stepper-step>
    </v-stepper-header>
</template>

<script>
export default {
    computed: {
        step() {
            return this.$store.state.core.step;
        },
    },
};
</script>
