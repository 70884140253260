import { Constants } from '../../Tools/constants';
import { Section } from './Section';

export class SingleCabinet extends Section {
    appliance;
    buildingBlocks;
    constructor() {
        super();
        this.type = Constants.section.type.SIMPLE_SECTION;
    }

    setBuildingBlocks(buildingBlocks) {
        this.buildingBlocks = buildingBlocks;
    }
}
