import { StandardMaterial, Color3, Vector3, MeshBuilder, Mesh, CSG } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import store from '@/store/index.js';
import { RoomObject } from '../Room/RoomObject';
import { Utilities } from '../../Tools/utilities';
import { LayoutUnit } from './LayoutUnit';
import { Countertop } from '../BuildingBlocks/Countertop';
import { Skirting } from '../BuildingBlocks/Skirting';

export class BaseUnit extends LayoutUnit {
    constructor() {
        super();
        this.peninsulaConnection = {
            peninsula: null,
            connectionEdge: null,
            edgeIndex: null,
        };
    }

    setFillerData(areDynamic, startingFillerSize, endingFillerSize) {
        this.fillerData.areDynamic = areDynamic;
        this.fillerData.startingFillerSize = startingFillerSize;
        this.fillerData.endingFillerSize = endingFillerSize;
    }

    setPosition(position) {
        this.position = new Vector3(position.x, position.y, position.z);
    }

    setDimensions(width, height, depth, heightFromFloor, dataType) {
        this.width = width;
        this.height = height;
        this.depth = depth;

        this.baseWidth = this.width;
        this.baseHeight = this.height;
        this.baseDepth = this.depth;

        //for vue
        if (this.editor.unit === 'mm') {
            this.dimensions = {
                width: width * Constants.MM_TO_BJS_COEFF,
                height: height * Constants.MM_TO_BJS_COEFF,
                depth: depth * Constants.MM_TO_BJS_COEFF,
            };
            if (dataType === Constants.cabinet.type.WALL) {
                this.dimensions.heightFromFloor = heightFromFloor * Constants.MM_TO_BJS_COEFF;
            }
        } else if (this.editor.unit === 'in') {
            this.dimensions = Utilities.convertToInches({ width, depth, height });
        }

        if (dataType === Constants.cabinet.type.WALL) {
            this.heightFromFloor = parseFloat(heightFromFloor);
            this.position.y += parseFloat(this.heightFromFloor);
        }
    }

    addCabinet(data) {
        this.type = data.type;
        this.name = data.name;
        this.setDimensions(
            data.dimensions.width,
            data.dimensions.height,
            data.dimensions.depth,
            data.dimensions.heightFromFloor,
            this.type
        );
        this.buildMesh();
        this.meshComponent.getMesh().visibility = Constants.cabinet.transparency.VISIBLE;

        if (this.type === Constants.cabinet.type.ISLAND) {
            this.meshComponent.getMesh().type = 'island';
        } else {
            this.meshComponent.getMesh().type = 'cabinet';
        }

        this.position.y += this.height / 2;
        if (data.id) {
            this.id = data.id;
            this.editor.lastCabinetId = this.id;
        } else {
            this.id = this.editor.lastCabinetId + 1;
            this.editor.lastCabinetId++;
        }
        this.editor.cabinets.push(this);
        this.meshComponent.getMesh().position = this.position;
        this.meshComponent.getMesh().id = this.id;
        this.meshComponent.getMesh().name = data.name;

        if (store.state.cabinets.selectedCabinet) {
            //when adding new corner cabinet
            if (!store.state.cabinets.selectedCabinet.connectedCabinet && data.cornerIndex === 'cornerA') {
                this.extendedCabinetManager.connectWithCabinet(store.state.cabinets.selectedCabinet, Constants.corner.CORNER_B);
            } else if (!store.state.cabinets.selectedCabinet.secondConnectedCabinet && data.cornerIndex === 'cornerB') {
                this.extendedCabinetManager.connectWithCabinet(store.state.cabinets.selectedCabinet, Constants.corner.CORNER_A);
            }
            // }
        } else {
            store.commit('cabinets/setSelectedCabinet', this);
        }
        this.handles.build();
        if (this.type !== Constants.cabinet.type.ISLAND) {
            this.rotateCabinet();
        }
        this.setActions();
        this.setMaterial();
    }

    createCabinetClone() {
        const meshClone = this.meshComponent.getMesh().clone('meshClone');
        meshClone.id = this.id + 'meshClone';
        meshClone.isClone = true;
        const cabinetObject = store.state.core.editorEngine.addObject(BaseUnit);
        cabinetObject.position = this.position;
        cabinetObject.width = this.width;
        cabinetObject.height = this.height;
        cabinetObject.depth = this.depth;
        cabinetObject.baseWidth = this.baseWidth;
        cabinetObject.meshComponent.mesh = meshClone;
        cabinetObject.isClone = true;
        cabinetObject.assignedWall = this.assignedWall;
        cabinetObject.id = meshClone.id;
        cabinetObject.type = this.type;
        cabinetObject.handles.rightHandle = meshClone.getChildren()[0];
        cabinetObject.handles.leftHandle = meshClone.getChildren()[1];
        cabinetObject.handles.rightHandle.name = 'rightHandle';
        cabinetObject.handles.leftHandle.name = 'leftHandle';
        cabinetObject.rotation = this.rotation;
        if (this.pickedHandle) {
            cabinetObject.pickedHandle = cabinetObject.handles[this.pickedHandle.name];
        }

        return cabinetObject;
    }

    rotateCabinet() {
        if (this.type !== Constants.cabinet.type.ISLAND) {
            this.rotation = Math.PI - this.assignedWall.rotationAngle;
            this.meshComponent.mesh.rotation.y = this.rotation;
        }
    }

    shiftCabinet() {
        const roomObject = this.editor.getObjectByType(RoomObject);
        const roomCorners = roomObject.cornerPositions.concat(roomObject.cornerPositions[0]);
        if (this.assignedWall.findWallOrientation() === Constants.wall.orientation.VERTICAL) {
            this.meshComponent.mesh.position = Utilities.calculateShiftValue(
                this.meshComponent.mesh.position.clone(),
                -this.depth / 2,
                roomCorners,
                this.assignedWall
            );
        } else {
            this.meshComponent.mesh.position = Utilities.calculateShiftValue(
                this.meshComponent.mesh.position.clone(),
                -this.depth / 2,
                roomCorners,
                this.assignedWall
            );
        }
        return this;
    }

    setMaterial() {
        this.meshComponent.mesh.material = new StandardMaterial('baseUnitMaterial', this.editor.sceneComponent.get());
        this.meshComponent.mesh.material.diffuseColor = new Color3(0, 0, 128);
        this.meshComponent.getMesh().showBoundingBox = true;
    }

    calculateAllowedSegment(considerFiller = true) {
        //segment coordinates without the blocked area
        this.getPositionInfo();
        const currentCabinetBlockedSegment = this.getEdgesOnWall();

        if (this.connectedCabinet && this.secondConnectedCabinet) {
            currentCabinetBlockedSegment.firstPoint = this.considerBlockedArea('cornerA', 'subtract', considerFiller);
            currentCabinetBlockedSegment.secondPoint = this.considerBlockedArea('cornerB', 'add', considerFiller);
        } else if (this.connectedCabinet) {
            const newPoint = this.considerBlockedArea('cornerA', 'subtract', considerFiller);
            if (
                Vector3.Distance(this.assignedWall.baseInnerCornerA, currentCabinetBlockedSegment.firstPoint) <=
                this.blockedArea.blockedWidthForCornerA
            ) {
                currentCabinetBlockedSegment.firstPoint = newPoint;
                if (this.peninsulaConnection.peninsula) {
                    currentCabinetBlockedSegment.secondPoint = this.considerBlockedArea('peninsula', 'add', considerFiller);
                }
            } else {
                currentCabinetBlockedSegment.secondPoint = newPoint;
                if (this.peninsulaConnection.peninsula) {
                    currentCabinetBlockedSegment.firstPoint = this.considerBlockedArea('peninsula', 'add', considerFiller);
                }
            }
        } else if (this.secondConnectedCabinet) {
            const newPoint = this.considerBlockedArea('cornerB', 'add', considerFiller);
            if (
                Vector3.Distance(this.assignedWall.baseInnerCornerB, currentCabinetBlockedSegment.firstPoint) <=
                this.blockedArea.blockedWidthForCornerB
            ) {
                currentCabinetBlockedSegment.firstPoint = newPoint;
                if (this.peninsulaConnection.peninsula) {
                    currentCabinetBlockedSegment.secondPoint = this.considerBlockedArea('peninsula', 'subtract', considerFiller);
                }
            } else {
                currentCabinetBlockedSegment.secondPoint = newPoint;
                if (this.peninsulaConnection.peninsula) {
                    currentCabinetBlockedSegment.firstPoint = this.considerBlockedArea('peninsula', 'add', considerFiller);
                }
            }
        }
        return currentCabinetBlockedSegment;
    }

    considerBlockedArea(cornerIndex, operation, considerFiller = true) {
        let currentWallDirection = this.assignedWall.getDirection();
        let wallCorner = 'baseInnerCornerB';
        let blockedAreaWidth;
        let edgePoint;
        if (cornerIndex !== 'peninsula') {
            blockedAreaWidth = this.blockedArea.blockedWidthForCornerB;
            if (!considerFiller) {
                blockedAreaWidth = this.secondConnectedCabinet?.depth;
            }
            if (cornerIndex === Constants.corner.CORNER_A) {
                wallCorner = 'baseInnerCornerA';
                blockedAreaWidth = this.blockedArea.blockedWidthForCornerA;
                if (!considerFiller) {
                    blockedAreaWidth = this.connectedCabinet.depth;
                }
            }
            edgePoint = this.assignedWall[wallCorner].clone();
        } else {
            const peninsula = this.peninsulaConnection.peninsula;
            const peninsulaInnerRow = peninsula.peninsulaData.innerRow;
            blockedAreaWidth =
                this.blockedArea.blockedWidthForPeninsulaConnection + peninsula.depth - peninsula.rowDepths[peninsulaInnerRow];
            if (!considerFiller) {
                blockedAreaWidth = peninsula.depth;
            }
            if (this.peninsulaConnection.edgeIndex === 'firstPoint') {
                currentWallDirection.negateInPlace();
            }
            const connectionEdge = this.peninsulaConnection.connectionEdge;
            edgePoint = new Vector3(connectionEdge._x, connectionEdge._y, connectionEdge._z);
        }
        currentWallDirection.scaleInPlace(blockedAreaWidth);

        if (operation === 'add') {
            return edgePoint.add(currentWallDirection);
        } else {
            return edgePoint.subtract(currentWallDirection);
        }
    }

    calculateSpaceNeededForFiller() {
        const positionInfo = this.getPositionInfo();
        let spaceLeftForFiller = 0;

        if (this.connectedCabinet && this.secondConnectedCabinet) {
            spaceLeftForFiller = 2 * Constants.STANDARD_FILLER_SIZE[this.editor.unit];
        } else if (positionInfo.cornerA && positionInfo.cornerB && !this.connectedCabinet && !this.secondConnectedCabinet) {
            spaceLeftForFiller = 2 * Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
        } else if (this.connectedCabinet || this.secondConnectedCabinet) {
            spaceLeftForFiller = Constants.STANDARD_FILLER_SIZE[this.editor.unit];
            if (
                (this.connectedCabinet && positionInfo.cornerB) ||
                (this.secondConnectedCabinet && positionInfo.cornerA) ||
                this.adaptToPreviousCabinet ||
                this.peninsulaConnection.peninsula
            ) {
                spaceLeftForFiller = 2 * Constants.STANDARD_FILLER_SIZE[this.editor.unit];
            }
        } else if (positionInfo.cornerA || positionInfo.cornerB) {
            spaceLeftForFiller = Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
            if (this.peninsulaConnection.peninsula) {
                spaceLeftForFiller = 2 * Constants.SMALLEST_FILLER_SIZE[this.editor.unit];
            }
        }

        return spaceLeftForFiller;
    }

    getOneRowThreshold() {
        let threshold = Constants.BIGGEST_ONE_ROW_ISLAND;
        if (this.editor.sceneInfo.unit === 'in') {
            threshold = Constants.BIGGEST_ONE_ROW_ISLAND_INCHES;
        }
        return threshold;
    }

    calculateStartingFillerForSymmetry() {
        const unit = this.editor.unit;
        const positionInfo = this.getPositionInfo();
        if (this.connectedCabinet || this.secondConnectedCabinet) {
            return Constants.STANDARD_FILLER_SIZE[unit];
        } else if (positionInfo.cornerA || positionInfo.cornerB) {
            return Constants.SMALLEST_FILLER_SIZE[unit];
        }
        return 0;
    }

    calculateCornerCabinetDoorForSymmetry() {
        this.cornerCabinetDoorSize = Constants.baseCornerCabinet.DOOR_SIZE[this.editor.unit];
    }

    getCornerCabinetDoor() {
        if (!this.cornerCabinetDoorSize) {
            this.calculateCornerCabinetDoorForSymmetry();
        }
        return this.cornerCabinetDoorSize;
    }

    calculateStartingPointAndReplacementDirection() {
        const cabinetPositionInfo = this.getPositionInfo();
        //this is the segment where we are allowed to put sections on
        const allowedSegment = this.calculateAllowedSegment(false);

        const wallDirection = this.assignedWall.getDirection();
        const wallReplacementDirection = this.assignedWall.getReplacementDirection();
        let startingPoint = Constants.corner.CORNER_B;
        let obstacle = this.obstacleManager.getObstacleNearCornerB();
        if (!wallDirection.equals(wallReplacementDirection)) {
            startingPoint = Constants.corner.CORNER_A;
            obstacle = this.obstacleManager.getObstacleNearCornerA();
        }
        if (!this.shouldAdaptToPreviousCabinet(obstacle)) {
            obstacle = null;
        }

        this.replacementData.direction = wallReplacementDirection;

        if (this.cornerCabinetInfo) {
            if (this.cornerCabinetInfo.cornerIndex === Constants.corner.CORNER_B) {
                this.replacementData.startingPoint = this.assignedWall.baseInnerCornerB;
                allowedSegment.secondPoint = this.replacementData.startingPoint;
                this.replacementData.direction = this.assignedWall.getDirection();
            } else if (this.cornerCabinetInfo.cornerIndex === Constants.corner.CORNER_A) {
                this.replacementData.startingPoint = this.assignedWall.baseInnerCornerA;
                allowedSegment.firstPoint = this.replacementData.startingPoint;
                this.replacementData.direction = this.assignedWall.getDirection().negate();
            }
            if (obstacle) {
                //WRITE THIS BETTER
                this.adaptToPreviousCabinet = true;
            }
        } else if (this.secondConnectedCabinet) {
            this.replacementData.startingPoint = allowedSegment.secondPoint;
            this.replacementData.direction = this.assignedWall.getDirection();
            if (obstacle) {
                //WRITE THIS BETTER
                this.adaptToPreviousCabinet = true;
            }
        } else if (this.connectedCabinet) {
            this.replacementData.startingPoint = allowedSegment.firstPoint;
            this.replacementData.direction = this.assignedWall.getDirection().negate();

            if (obstacle) {
                //WRITE THIS BETTER
                this.adaptToPreviousCabinet = true;
            }
        } else if (cabinetPositionInfo.cornerB) {
            this.replacementData.startingPoint = allowedSegment.secondPoint;
            this.replacementData.direction = this.assignedWall.getDirection();
            if (obstacle) {
                //WRITE THIS BETTER
                this.adaptToPreviousCabinet = true;
            }
        } else if (cabinetPositionInfo.cornerA) {
            this.replacementData.direction = this.assignedWall.getDirection().negate();
            this.replacementData.startingPoint = allowedSegment.firstPoint;
            if (obstacle) {
                //WRITE THIS BETTER
                this.adaptToPreviousCabinet = true;
            }
        } else if (obstacle) {
            const edgesAfterReplacement = obstacle.getEdgesAfterReplacement();
            let nearestEdge = edgesAfterReplacement.firstPoint;
            if (
                Vector3.Distance(this.meshComponent.getPosition(), edgesAfterReplacement.firstPoint) >
                Vector3.Distance(this.meshComponent.getPosition(), edgesAfterReplacement.secondPoint)
            ) {
                nearestEdge = edgesAfterReplacement.secondPoint;
            }

            this.replacementData.startingPoint = nearestEdge;
        } else {
            this.replacementData.startingPoint = allowedSegment.secondPoint;
            this.replacementData.direction = this.assignedWall.getDirection();
        }

        return [this.replacementData.direction, this.replacementData.startingPoint, allowedSegment];
    }

    mergeCountertop() {
        const meshes = [];
        for (let index = 0; index < this.sections.length; index++) {
            if (
                this.sections[index].type !== Constants.section.type.CORNER_SECTION &&
                this.sections[index].buildingBlocks.countertopBlock.meshComponent.getMesh().visibility !== 0
            ) {
                meshes.push(this.sections[index].buildingBlocks.countertopBlock.meshComponent.getMesh());
            }
        }
        this.countertop = this.editor.addObject(Countertop);
        this.countertop.parentCabinet = this;
        let mergedCountertop = Mesh.MergeMeshes(meshes);

        this.countertop.meshComponent.setMesh(mergedCountertop);
        mergedCountertop = this.createSinkHole();
        this.countertop.setActions();
        this.countertop.setInfo('countertop');
        this.countertop.subtype = this.type;
        this.countertop.addTexture();
    }

    mergeSkirting() {
        const meshes = [];
        for (let index = 0; index < this.sections.length; index++) {
            if (
                this.sections[index].type !== Constants.section.type.CORNER_SECTION &&
                this.sections[index].buildingBlocks.skirtingBlock.meshComponent.getMesh().visibility !== 0
            ) {
                meshes.push(this.sections[index].buildingBlocks.skirtingBlock.meshComponent.getMesh());
            }
        }
        if (meshes.length === 0) {
            return;
        }
        this.skirting = this.editor.addObject(Skirting);
        this.skirting.parentCabinet = this;
        const mergedSkirting = Mesh.MergeMeshes(meshes);
        this.skirting.meshComponent.setMesh(mergedSkirting);

        this.skirting.setActions();
        this.skirting.setInfo('skirting');
        this.skirting.subtype = this.type;
        this.skirting.addTexture();
    }

    createSinkHole() {
        let appliancesOnUnit = this.getAppliances();
        for (let index = 0; index < appliancesOnUnit.length; index++) {
            const appliance = appliancesOnUnit[index];
            if (appliance.type === 'sink') {
                this.countertop.meshComponent.getMesh().visibility = 0;

                let modelWidth = 0;
                switch (appliance.subtype) {
                    case 'oneBowl':
                        modelWidth = 0.58;
                        break;

                    case 'twoBowls':
                        modelWidth = 0.84;
                        break;

                    case 'oneBowlSide':
                        modelWidth = 0.86;
                        break;
                }

                const subtractionBox = MeshBuilder.CreateBox('subtractionBox', {
                    width: modelWidth,
                    depth: appliance.depth,
                    height: appliance.height,
                });
                subtractionBox.rotation.y = this.meshComponent.getMesh().rotation.y;
                subtractionBox.position = appliance.meshComponent.getMesh().position.clone();
                subtractionBox.position.y += 0.01;
                const subtractionMeshCSG = CSG.FromMesh(subtractionBox);
                let counterTopCSG = CSG.FromMesh(this.countertop.meshComponent.getMesh());
                counterTopCSG.copyTransformAttributes(counterTopCSG);

                const result = counterTopCSG.subtract(subtractionMeshCSG);
                this.countertop.meshComponent.mesh = result.toMesh(
                    'countertopBlock',
                    new StandardMaterial('material', this.editor.sceneComponent.get()),
                    this.editor.sceneComponent.get()
                );
                subtractionBox.dispose();
            }
        }

        this.countertop.meshComponent.getMesh().type = 'countertop';
        return this.countertop.meshComponent.getMesh();
    }

    addEndPanel(edgePosition) {
        if (
            this.sections[this.sections.length - 1]?.appliance &&
            ['dishwasher', 'washer'].includes(this.sections[this.sections.length - 1]?.appliance?.type) &&
            this.type !== Constants.cabinet.type.TALL
        ) {
            this.endPanel = MeshBuilder.CreateBox(
                'endPanel',
                { width: 0.018, depth: this.depth, height: this.height },
                this.editor.sceneComponent.get()
            );

            const roomObject = this.editor.getObjectByType(RoomObject);
            const roomCorners = roomObject.baseOuterCorners.concat(roomObject.baseOuterCorners[0]);
            let shiftedPosition = Utilities.calculateShiftValue(
                edgePosition.clone(),
                -this.depth / 2,
                roomCorners,
                this.assignedWall
            );
            this.endPanel.position = shiftedPosition;
            this.endPanel.position.y = this.height / 2;
        }
    }
}
