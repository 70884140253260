export default {
    sink: {
        id: 'sink',
        name: 'Sink',
        img: require('@/assets/icons/appliances/sink/sink.svg'),
        subtype: [
            {
                id: 'oneBowl',
                name: 'One Bowl',
                img: require('@/assets/icons/appliances/sink/oneBowl.svg'),
                direction: {},
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },

                format: {
                    value: 'topMount',
                    items: ['topMount'],
                },
                dimensions: {},
            },
            {
                id: 'twoBowls',
                name: 'Two Bowls',
                img: require('@/assets/icons/appliances/sink/twoBowls.svg'),
                direction: {},
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },

                format: {
                    value: 'topMount',
                    items: ['topMount'],
                },
                dimensions: {},
            },
            {
                id: 'oneBowlSide',
                name: 'One Bowl w/ Side',
                img: require('@/assets/icons/appliances/sink/oneBowlSide.svg'),
                direction: {
                    value: 'left',
                    items: ['left', 'right'],
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },

                format: {
                    value: 'topMount',
                    items: ['topMount'],
                },
                dimensions: {},
            },
            {
                id: 'twoBowlsSide',
                name: 'Two Bowl w/ Side',
                img: require('@/assets/icons/appliances/sink/twoBowlsSide.svg'),
                direction: {
                    value: 'left',
                    items: ['left', 'right'],
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },

                format: {
                    value: 'topMount',
                    items: ['topMount'],
                },
                dimensions: {},
            },
            {
                id: 'twoBowlsOneSmallSide',
                name: 'Two Bowls One Small w/ Side',
                img: require('@/assets/icons/appliances/sink/twoBowlsOneSmallSide.svg'),
                direction: {
                    value: 'left',
                    items: ['left', 'right'],
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },

                format: {
                    value: 'topMount',
                    items: ['topMount'],
                },
                dimensions: {},
            },
        ],
    },
    dishwasher: {
        id: 'dishwasher',
        name: 'Dishwasher',
        img: require('@/assets/icons/appliances/dishwasher/dishwasher.svg'),
        subtype: [
            {
                id: 'freestanding',
                name: 'Freestanding',
                img: require('@/assets/icons/appliances/dishwasher/dishwasherFreestanding.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 450,
                            in: 18,
                        },
                        items: {
                            mm: [450, 600],
                            in: [18, 24],
                        },
                        min: {
                            mm: 400,
                            in: 17,
                        },
                        max: {
                            mm: 630,
                            in: 25,
                        },
                    },
                    height: {
                        value: {
                            mm: 850,
                            in: 35,
                        },
                        items: {
                            mm: [850],
                            in: [35],
                        },
                        min: {
                            mm: 840,
                            in: 33,
                        },
                        max: {
                            mm: 900,
                            in: 36,
                        },
                    },
                    depth: {
                        value: {
                            mm: 610,
                            in: 24,
                        },
                        items: {
                            mm: [610],
                            in: [24],
                        },
                        min: {
                            mm: 600,
                            in: 23,
                        },
                        max: {
                            mm: 620,
                            in: 25,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'freestanding',
                    items: ['freestanding'],
                },
            },
            {
                id: 'builtin',
                name: 'Built-In',
                img: require('@/assets/icons/appliances/dishwasher/dishwasherBultin.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 450,
                            in: 18,
                        },
                        items: {
                            mm: [450, 600],
                            in: [18, 24],
                        },
                        min: {
                            mm: 400,
                            in: 17,
                        },
                        max: {
                            mm: 630,
                            in: 25,
                        },
                    },
                    height: {
                        value: {
                            mm: 850,
                            in: 35,
                        },
                        items: {
                            mm: [850],
                            in: [35],
                        },
                        min: {
                            mm: 840,
                            in: 33,
                        },
                        max: {
                            mm: 900,
                            in: 36,
                        },
                    },
                    depth: {
                        value: {
                            mm: 610,
                            in: 24,
                        },
                        items: {
                            mm: [610],
                            in: [24],
                        },
                        min: {
                            mm: 600,
                            in: 23,
                        },
                        max: {
                            mm: 620,
                            in: 25,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'builtin',
                    items: ['builtin'],
                },
            },
        ],
    },
    oven: {
        id: 'oven',
        name: 'Oven',
        img: require('@/assets/icons/appliances/oven/ovenRange.svg'),
        subtype: [
            {
                id: 'ovenSignle',
                name: 'Built-In Single',
                img: require('@/assets/icons/appliances/oven/ovenSingle.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 24,
                        },
                        items: {
                            mm: [600],
                            in: [24, 30, 36],
                        },
                        min: {
                            mm: 500,
                            in: 23,
                        },
                        max: {
                            mm: 630,
                            in: 37,
                        },
                    },
                    height: {
                        value: {
                            mm: 850,
                            in: 35,
                        },
                        items: {
                            mm: [850],
                            in: [35],
                        },
                        min: {
                            mm: 840,
                            in: 33,
                        },
                        max: {
                            mm: 900,
                            in: 36,
                        },
                    },
                    depth: {
                        value: {
                            mm: 610,
                            in: 24,
                        },
                        items: {
                            mm: [610],
                            in: [24],
                        },
                        min: {
                            mm: 600,
                            in: 23,
                        },
                        max: {
                            mm: 620,
                            in: 25,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'builtin',
                    items: ['builtin'],
                },
            },
            {
                id: 'ovenDouble',
                name: 'Built-In Double',
                img: require('@/assets/icons/appliances/oven/ovenDouble.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 18,
                        },
                        items: {
                            mm: [600],
                            in: [24, 30, 36],
                        },
                        min: {
                            mm: 500,
                            in: 23,
                        },
                        max: {
                            mm: 630,
                            in: 37,
                        },
                    },
                    height: {
                        value: {
                            mm: 850,
                            in: 35,
                        },
                        items: {
                            mm: [850],
                            in: [35],
                        },
                        min: {
                            mm: 840,
                            in: 33,
                        },
                        max: {
                            mm: 900,
                            in: 36,
                        },
                    },
                    depth: {
                        value: {
                            mm: 610,
                            in: 24,
                        },
                        items: {
                            mm: [610],
                            in: [24],
                        },
                        min: {
                            mm: 600,
                            in: 23,
                        },
                        max: {
                            mm: 620,
                            in: 25,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'builtin',
                    items: ['builtin'],
                },
            },
            {
                id: 'ovenMicrowave',
                name: 'Built-In Oven/Microwave',
                img: require('@/assets/icons/appliances/oven/ovenMicrowave.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 18,
                        },
                        items: {
                            mm: [600],
                            in: [24, 30, 36],
                        },
                        min: {
                            mm: 500,
                            in: 23,
                        },
                        max: {
                            mm: 630,
                            in: 37,
                        },
                    },
                    height: {
                        value: {
                            mm: 850,
                            in: 35,
                        },
                        items: {
                            mm: [850],
                            in: [35],
                        },
                        min: {
                            mm: 840,
                            in: 33,
                        },
                        max: {
                            mm: 900,
                            in: 36,
                        },
                    },
                    depth: {
                        value: {
                            mm: 610,
                            in: 24,
                        },
                        items: {
                            mm: [610],
                            in: [24],
                        },
                        min: {
                            mm: 600,
                            in: 23,
                        },
                        max: {
                            mm: 620,
                            in: 25,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'builtin',
                    items: ['builtin'],
                },
            },
            {
                id: 'range',
                name: 'Range',
                img: require('@/assets/icons/appliances/oven/ovenRange.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 24,
                        },
                        items: {
                            mm: [500, 600, 750, 900],
                            in: [20, 24, 30, 36],
                        },
                        min: {
                            mm: 490,
                            in: 19,
                        },
                        max: {
                            mm: 910,
                            in: 37,
                        },
                    },
                    height: {
                        value: {
                            mm: 900,
                            in: 36,
                        },
                        items: {
                            mm: [900],
                            in: [36],
                        },
                        min: {
                            mm: 800,
                            in: 32,
                        },
                        max: {
                            mm: 1000,
                            in: 39,
                        },
                    },
                    depth: {
                        value: {
                            mm: 635,
                            in: 25,
                        },
                        items: {
                            mm: [635],
                            in: [25],
                        },
                        min: {
                            mm: 600,
                            in: 24,
                        },
                        max: {
                            mm: 700,
                            in: 27.5,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'freestanding',
                    items: ['freestanding'],
                },
            },
        ],
    },
    cooktop: {
        id: 'cooktop',
        name: 'Cooktop',
        img: require('@/assets/icons/appliances/cooktop/hobStandard.svg'),
        subtype: [
            {
                id: 'hobXSmall',
                name: 'X-Small',
                img: require('@/assets/icons/appliances/cooktop/hobSmall.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 300,
                            in: 12,
                        },
                        items: {
                            mm: [300],
                            in: [12],
                        },
                        min: {
                            mm: 290,
                            in: 11,
                        },
                        max: {
                            mm: 350,
                            in: 13,
                        },
                    },
                },
                format: {
                    value: 'builtin',
                    items: ['builtin'],
                },
            },
            {
                id: 'hobStandard',
                name: 'Standard',
                img: require('@/assets/icons/appliances/cooktop/hobStandard.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 24,
                        },
                        items: {
                            mm: [600],
                            in: [24],
                        },
                        min: {
                            mm: 560,
                            in: 22,
                        },
                        max: {
                            mm: 800,
                            in: 32,
                        },
                    },
                },
                format: {
                    value: 'builtin',
                    items: ['builtin'],
                },
            },
            {
                id: 'hobLarge',
                name: 'Large',
                img: require('@/assets/icons/appliances/cooktop/hobLarge.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 900,
                            in: 36,
                        },
                        items: {
                            mm: [750, 900],
                            in: [30, 36],
                        },
                        min: {
                            mm: 700,
                            in: 29,
                        },
                        max: {
                            mm: 950,
                            in: 37,
                        },
                    },
                },
                format: {
                    value: 'builtin',
                    items: ['builtin'],
                },
            },
            {
                id: 'hobXLarge',
                name: 'X-Large',
                img: require('@/assets/icons/appliances/cooktop/hobLarge.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 1100,
                            in: 45,
                        },
                        items: {
                            mm: [1100],
                            in: [45],
                        },
                        min: {
                            mm: 1000,
                            in: 44,
                        },
                        max: {
                            mm: 1200,
                            in: 45,
                        },
                    },
                },
                format: {
                    value: 'builtin',
                    items: ['builtin'],
                },
            },
        ],
    },
    hood: {
        id: 'hood',
        name: 'Hood',
        img: require('@/assets/icons/appliances/hood/hood.svg'),
        subtype: [
            {
                id: 'builtin',
                name: 'Built-In',
                img: require('@/assets/icons/appliances/hood/builtin.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 24,
                        },
                        items: {
                            mm: [600, 750, 900],
                            in: [24, 30, 36],
                        },
                        min: {
                            mm: 550,
                            in: 23,
                        },
                        max: {
                            mm: 950,
                            in: 37,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'builtin',
                    items: ['builtin'],
                },
            },
            {
                id: 'freestanding1',
                name: 'Freestanding 1',
                img: require('@/assets/icons/appliances/hood/freestanding1.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 24,
                        },
                        items: {
                            mm: [600, 750, 900],
                            in: [24, 30, 36],
                        },
                        min: {
                            mm: 550,
                            in: 23,
                        },
                        max: {
                            mm: 950,
                            in: 37,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'freestanding',
                    items: ['freestanding'],
                },
            },
            {
                id: 'freestanding2',
                name: 'Freestanding 2',
                img: require('@/assets/icons/appliances/hood/freestanding2.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 24,
                        },
                        items: {
                            mm: [600, 750, 900],
                            in: [24, 30, 36],
                        },
                        min: {
                            mm: 550,
                            in: 23,
                        },
                        max: {
                            mm: 950,
                            in: 37,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'freestanding',
                    items: ['freestanding'],
                },
            },
            {
                id: 'freestanding3',
                name: 'Freestanding 3',
                img: require('@/assets/icons/appliances/hood/freestanding3.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 24,
                        },
                        items: {
                            mm: [600, 750, 900],
                            in: [24, 30, 36],
                        },
                        min: {
                            mm: 550,
                            in: 23,
                        },
                        max: {
                            mm: 950,
                            in: 37,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'freestanding',
                    items: ['freestanding'],
                },
            },
        ],
    },
    refrigerator: {
        id: 'refrigerator',
        name: 'Refrigerator',
        img: require('@/assets/icons/appliances/refrigerator/refrigerator.svg'),
        subtype: [
            {
                id: 'sideBySide',
                name: 'Side by Side',
                img: require('@/assets/icons/appliances/refrigerator/sideBySideFreestanding.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 920,
                            in: 32.25,
                        },
                        items: {
                            mm: [820, 920],
                            in: [32.25, 35.75],
                        },
                        min: {
                            mm: 800,
                            in: 32,
                        },
                        max: {
                            mm: 950,
                            in: 36,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'freestanding',
                    items: ['freestanding', 'builtin'],
                },
            },
            {
                id: 'frenchDoor',
                name: 'French Door',
                img: require('@/assets/icons/appliances/refrigerator/frenchDoorFreestanding.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 920,
                            in: 35.75,
                        },
                        items: {
                            mm: [920],
                            in: [35.75],
                        },
                        min: {
                            mm: 900,
                            in: 35,
                        },
                        max: {
                            mm: 950,
                            in: 36,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'freestanding',
                    items: ['freestanding', 'builtin'],
                },
            },
            {
                id: 'bottomFreezer',
                name: 'Bottom Freezer',
                img: require('@/assets/icons/appliances/refrigerator/bottomFreezerFreestanding.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 700,
                            in: 27.5,
                        },
                        items: {
                            mm: [600, 700, 750],
                            in: [23.75, 27.5, 29.5],
                        },
                        min: {
                            mm: 550,
                            in: 23,
                        },
                        max: {
                            mm: 800,
                            in: 30,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'freestanding',
                    items: ['freestanding', 'builtin'],
                },
            },
            {
                id: 'undercounter',
                name: 'Undercounter',
                img: require('@/assets/icons/appliances/refrigerator/undercounterFreestanding.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 23.75,
                        },
                        items: {
                            mm: [550, 600],
                            in: [21.75, 23.75],
                        },
                        min: {
                            mm: 500,
                            in: 21,
                        },
                        max: {
                            mm: 650,
                            in: 24,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'freestanding',
                    items: ['freestanding', 'builtin'],
                },
            },
        ],
    },
    washer: {
        id: 'washer',
        name: 'Washer',
        img: require('@/assets/icons/appliances/washer/washer.svg'),
        subtype: [
            {
                id: 'freestanding',
                name: 'Freestanding',
                img: require('@/assets/icons/appliances/washer/washerFreestanding.svg'),
                dimensions: {
                    width: {
                        value: {
                            mm: 600,
                            in: 26,
                        },
                        items: {
                            mm: [450, 600],
                            in: [24, 26],
                        },
                        min: {
                            mm: 400,
                            in: 23,
                        },
                        max: {
                            mm: 650,
                            in: 27,
                        },
                    },
                },
                color: {
                    value: 'light',
                    items: ['light', 'dark'],
                },
                format: {
                    value: 'freestanding',
                    items: ['freestanding'],
                },
            },
        ],
    },
};
