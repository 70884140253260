import { StandardMaterial, Color3 } from '@babylonjs/core';
import { Constants } from '../../Tools/constants';
import { BaseKitchenObject } from '../BaseKitchenObject';
import { SceneComponent } from '../../Components/SceneComponent';
import { ResizingManager } from './FixtureComponents/DoorComponents/ResizingManager';

export class DoorObject extends BaseKitchenObject {
    constructor() {
        super();
        this.resizingManager = this.registerComponent(ResizingManager);
    }

    parts = {
        doorPanel: null,
        frameTop: null,
        frameLeft: null,
        frameRight: null,

        lockInside: null,
        handleOutside: null,
        lockInsideLeft: null,
        handleOutsideLeft: null,

        frameCornerRight: null,
        frameCornerLeft: null,
        slidingDoorHandle: null,
        slidingDoorHandleLeft: null,
    };

    balconyParts = {
        innerDoor1: {
            innerFrameRight: null,
            innerFrameLeft: null,
            innerFrameTop: null,
            innerFrameBottom: null,
            innerFrameCornerBottomRight: null,
            innerFrameCornerBottomLeft: null,
            innerFrameCornerTopLeft: null,
            innerFrameCornerTopRight: null,
            glassLeft: null,
        },
        innerDoor2: {
            innerFrameRight2: null,
            innerFrameLeft2: null,
            innerFrameTop2: null,
            innerFrameBottom2: null,
            innerFrameCornerBottomRight2: null,
            innerFrameCornerBottomLeft2: null,
            innerFrameCornerTopLeft2: null,
            innerFrameCornerTopRight2: null,
            glassLeft2: null,
        },
        outerFrame: {
            outerCornerTopLeft: null,
            outerCornerTopRight: null,
            outerCornerFrameRight: null,
            outerCornerFrameLeft: null,
            outerFrameTop: null,
        },
    };

    doorPanelWidth;
    doorPanelHeight;

    balconyGlassWidth;
    balconyGlassHeight;

    balconyDoorWidth;

    setDimensions(width, height) {
        this.width = width;
        this.height = height;
        this.depth = Constants.room.dimensions.WIDTH + Constants.fixture.margin;
    }

    setParts() {
        this.parts.doorPanel = this.meshComponent.getChildByName(Constants.models3D.door.DOOR_PANEL);
        const frame = this.meshComponent.getChildByName('frame');
        const frameChildren = frame.getChildren();

        for (let index = 0; index < frameChildren.length; index++) {
            this.parts[frameChildren[index].name] = frameChildren[index];
        }

        this.parts.lockInside = this.meshComponent.getChildByName(Constants.models3D.door.LOCK_INSIDE);
        this.parts.handleOutside = this.meshComponent.getChildByName(Constants.models3D.door.HANDLE_OUTSIDE);
        this.parts.slidingDoorHandle = this.meshComponent.getChildByName(Constants.models3D.door.SLIDING_DOOR_HANDLE);

        this.parts.lockInsideLeft = this.meshComponent.getChildByName(Constants.models3D.door.LOCK_INSIDE_LEFT);
        this.parts.handleOutsideLeft = this.meshComponent.getChildByName(Constants.models3D.door.HANDLE_OUTSIDE_LEFT);
        this.parts.slidingDoorHandleLeft = this.meshComponent.getChildByName(Constants.models3D.door.SLIDING_DOOR_HANDLE_LEFT);

        if (this.subType === Constants.fixture.subType.SLIDING) {
            this.parts.lockInside.dispose();
            this.parts.handleOutside.dispose();
            this.parts.lockInsideLeft.dispose();
            this.parts.handleOutsideLeft.dispose();
        } else {
            this.parts.slidingDoorHandle.dispose();
            this.parts.slidingDoorHandleLeft.dispose();
        }

        Object.keys(this.parts).forEach((key) => {
            this.parts[key].convertToFlatShadedMesh();
            this.parts[key].type = 'fixture';
            this.parts[key].parentFixture = this;
        });
    }

    setBalconyParts() {
        const innerDoor1Parts = this.meshComponent.getChildByName('innerDoor1').getChildren();
        const innerDoor2Parts = this.meshComponent.getChildByName('innerDoor2').getChildren();
        const outerFrameParts = this.meshComponent.getChildByName('outerFrame').getChildren();

        for (let index = 0; index < innerDoor1Parts.length; index++) {
            this.balconyParts.innerDoor1[innerDoor1Parts[index].name] = innerDoor1Parts[index];
        }
        for (let index = 0; index < innerDoor2Parts.length; index++) {
            this.balconyParts.innerDoor2[innerDoor2Parts[index].name] = innerDoor2Parts[index];
        }
        for (let index = 0; index < outerFrameParts.length; index++) {
            this.balconyParts.outerFrame[outerFrameParts[index].name] = outerFrameParts[index];
        }
        Object.keys(this.balconyParts.innerDoor1).forEach((key) => {
            this.balconyParts.innerDoor1[key].convertToFlatShadedMesh();
            this.balconyParts.innerDoor1[key].type = 'fixture';
            this.balconyParts.innerDoor1[key].parentFixture = this;
        });
        Object.keys(this.balconyParts.innerDoor2).forEach((key) => {
            this.balconyParts.innerDoor2[key].convertToFlatShadedMesh();
            this.balconyParts.innerDoor2[key].type = 'fixture';
            this.balconyParts.innerDoor2[key].parentFixture = this;
        });
        Object.keys(this.balconyParts.outerFrame).forEach((key) => {
            if (this.balconyParts.outerFrame[key]) {
                this.balconyParts.outerFrame[key].convertToFlatShadedMesh();
                this.balconyParts.outerFrame[key].type = 'fixture';
                this.balconyParts.outerFrame[key].parentFixture = this;
            }
        });
    }

    resize() {
        if (this.subType === Constants.fixture.subType.BALCONY) {
            this.setBalconyParts();
            this.resizingManager.resizeBalconyGlass();
            this.resizingManager.resizeBalconyVertically();
            this.resizingManager.resizeBalconyHorizontally();
        } else {
            this.setParts();
            this.resizingManager.resizeDoorPanel();
            this.resizingManager.resizeVerticalFrames(Constants.models3D.door.FRAME_LEFT);
            this.resizingManager.resizeVerticalFrames(Constants.models3D.door.FRAME_RIGHT);
            this.resizingManager.resizeTopFrame();
        }
    }

    setMaterial() {
        this.meshComponent.mesh.material = new StandardMaterial(
            'doorMaterial',
            this.object.getComponentByType(SceneComponent).get()
        );
        this.meshComponent.mesh.material.diffuseColor = new Color3(0, 125, 0);
    }

    buildMesh() {
        super.buildMesh();
        this.positionMesh();
        this.meshComponent.setType('fixture');
    }
}
